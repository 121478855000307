import React, {
  ButtonHTMLAttributes,
  FunctionComponent,
  ReactElement,
} from "react";
import styled, { css } from "styled-components";

import { Color, Size } from "../tokens";

export type StyleTypes =
  | "default"
  | "primary"
  | "home"
  | "destructive"
  | "small";

const ButtonStyles = css<ButtonProps>`
  border: none;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 6px;
  transition: background-color 0.3s;
  font-weight: 700;
  font-size: 16px;
  white-space: normal;
  word-wrap: break-word;

  -webkit-tap-highlight-color: transparent;

  background: none;
  color: ${Color.ACCENT};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ buttonType, small, primary, secondary }) =>
    buttonType === "default" &&
    !primary &&
    small &&
    css`
      background-color:${Color.NEUTRAL_100}};
       color: ${Color.ACCENT}};
       

      &:hover {
        
      background-color: ${Color.NEUTRAL_200}};
      }
    `}

  ${({ secondary }) =>
    secondary &&
    css`
      background-color: transparent;
      padding: ${Size.XS}px;
    `}

  ${({ home }) =>
    home &&
    css`
    background-color: ${Color.NEUTRAL_100}};
 
    `}

  ${({ primary }) =>
    primary &&
    css`
      background-color: ${Color.NEUTRAL_800};
      color: #ffffff;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
        0px 2px 4px rgba(50, 60, 74, 0.09);

      &:hover {
        background-color: ${Color.ACCENT};
      }
    `}

 

  ${({ small }) =>
    small &&
    css`
      font-size: 14px;
      padding: 6px 12px;
      border-radius: 50vw;
      background-color: ${Color.NEUTRAL_200};
      color: ${Color.NEUTRAL_600};
    `}

    ${({ circle }) =>
    circle &&
    css`
    height:fit-content;
    background-color: ${Color.NEUTRAL_200}};
 color: #ffffff;
      padding: 8px 9px;
      border-radius: 50vw;
      &:hover {
        background-color: ${Color.NEUTRAL_800};
      }
    `}

    

    ${({ buttonType }) =>
    buttonType === "destructive" &&
    css`
      color: #dc3545;
    `}
`;

interface ButtonProps {
  small?: boolean;
  buttonType?: StyleTypes;
  title?: string;
  onPress?: () => void;
  primary?: boolean;
  secondary?: boolean;
  home?: boolean;
  icon?: string | ReactElement;
  circle?: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  ${ButtonStyles}
`;

const Button: React.FC<ButtonProps> = ({
  buttonType = "default",
  small,
  title,
  icon,
  primary,
  onPress,
  circle,
  ...props
}) => {
  return (
    <StyledButton
      primary={primary}
      small={small}
      circle={circle}
      buttonType={buttonType}
      {...props}
      onClick={(e) => {
        if (onPress) onPress();
      }}
    >
      {icon &&
        (typeof icon === "string" ? <img src={icon} alt={title} /> : icon)}
      {title}
    </StyledButton>
  );
};

export default Button;
