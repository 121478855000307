import styled, { css } from "styled-components";
import { Color, Size } from "../../tokens";

interface Props {
  type?: BlockType;
  children: JSX.Element[] | JSX.Element;
}

export enum BlockType {
  DEFAULT,
  WARNING,
  SUCCESS,
  ERROR,
  INFO,
}

const BlockStyles = {
  [BlockType.DEFAULT]: css`
    background-color: ${Color.NEUTRAL_300};
  `,
  [BlockType.WARNING]: css`
    background-color: ${Color.YELLOW};
  `,
  [BlockType.ERROR]: css`
    background-color: ${Color.DESTRUCTIVE};
  `,
  [BlockType.SUCCESS]: css`
    background-color: ${Color.GREEN_LIGHT};
  `,
  [BlockType.INFO]: css`
    background-color: ${Color.BLUE};
  `,
};

const StyledBlock = styled.div<Props>`
  padding: ${Size.M}px;
  border-radius: ${Size.S}px;
  background-color: ${Color.NEUTRAL_100};
  margin: ${Size.S}px 0;

  ${({ type = BlockType.DEFAULT }) => BlockStyles[type]}
`;

export default function Block({ children, type = BlockType.DEFAULT }: Props) {
  return <StyledBlock type={type}>{children}</StyledBlock>;
}
