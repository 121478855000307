import './assets/styles/colors.css';
import './assets/styles/typography.css';
import './assets/styles/main.css';
import './assets/styles/desktop.css';
import React from "react";
import {initTranslations} from "./services/translations";

import {Route, Routes} from "react-router";

import {BrowserRouter, useLocation} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import * as Fathom from 'fathom-client';



import EzoApp from './app/features/navigation/EzoApp';

// Polyfill to add smooth scrolling in Safari
import smoothscroll from 'smoothscroll-polyfill';
import { useEffect } from 'react';
import { FATHOM_SITE_ID } from './constants/fathom.constants';
import { REDIRECT_ROUTE } from './app/connectors/ehealth/api/constants';
import AuthRedirect from './app/features/navigation/AuthRedirect';

//import { createClient, get, getAll } from '@vercel/edge-config';




smoothscroll.polyfill();


initTranslations();

function App() {
  
  useEffect(()=>{
    Fathom.load(FATHOM_SITE_ID)

  },[])

  


  return (
    <React.Suspense fallback="Loading...">
      <BrowserRouter>
        <div className="app">
       <Header/> 
     
          <Routes>
              <Route path="/app/*"  element={<EzoApp/>} />


              <Route path={REDIRECT_ROUTE}  element={<AuthRedirect/>} />

                </Routes>  
                        </div>
      </BrowserRouter>
      </React.Suspense>
  );
}

export default App;
