import React from "react";
import styled, { keyframes } from "styled-components";
import { Color } from "../../ui/tokens";

const Root = styled.div`
  font-family: "Poppins", sans-serif;
  background-color: ${Color.NEUTRAL_100};
`;

export default Root;
