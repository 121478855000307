import { useSearchParams } from "react-router-dom";

export default function useStep(step: number = 1) {
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedParams = new URLSearchParams(searchParams.toString());
  const index = parseInt(searchParams.get("screen") || "0");
  if (index + step < 0) {
    return () => setSearchParams();
  } else {
    updatedParams.set("screen", (index + step).toString());
  }
  return () => setSearchParams(updatedParams);
}
