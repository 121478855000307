import { useNavigate } from "react-router-dom";
import getMedicationShortName from "../../hooks/useMedicationShortName";
import { MedicationModel } from "../../models/MedicationModel";
import { Icons } from "../../ui/assets";
import Avatar from "../../ui/components/Avatar/Avatar";
import Icon from "../../ui/components/Icon";
import { Tints } from "../../ui/tokens";

/*
medication: {
  default_xs,
  comp_xs,
  pill_xs,
  cream_xs,
  syringe_xs,
  drop_xs,
  bottle_xs,
},
*/
const getIconFromMed = (med?: MedicationModel) => {
  const mode = med?.administrationMode?.toLowerCase();
  const name = med?.name?.toLowerCase();
  if (mode?.includes("inject") || name?.includes("inject"))
    return Icons.medication.syringe_xs;
  if (mode?.includes("sirop") || name?.includes("sirop"))
    return Icons.medication.bottle_xs;

  if (mode?.includes("comp") || name?.includes("comp"))
    return Icons.medication.comp_xs;
  if (mode?.includes("crème") || name?.includes("crème"))
    return Icons.medication.cream_xs;
  if (mode?.includes("pomm") || name?.includes("pomm"))
    return Icons.medication.cream_xs;
  if (mode?.includes("sol") || name?.includes("sol"))
    return Icons.medication.bottle_xs;

  return Icons.medication.pill_xs;
};

export const MedicationItemRenderer = {
  icon: (item: MedicationModel) => (
    <Avatar
      square
      tint={Tints.MEDICATION}
      icon={<Icon asset={getIconFromMed(item)} color="#fff" />}
    />
  ),
  title: (item: MedicationModel) => getMedicationShortName(item.name!),
  details: (item: MedicationModel) => item.instruction!,
};
