import ScreenFlow from "../../ui/components/ScreenFlow/ScreenFlow";
import AppointmentDetailsScreen from "../agenda/AppointmentDetailsScreen";
import { useParams } from "react-router-dom";
import HomeScreen from "./HomeScreen";
import ViewDocumentScreen from "../documents/ViewDocumentScreen";
import HomeButton from "../../ui/components/HomeButton";
import Button from "../../ui/components/Button";
import FilterButton from "../../ui/components/FilterButton";
import { useSelector } from "react-redux";
import { selectAllPatients } from "../login/AuthSlice";
import MedicationListScreen from "../medication/MedicationListScreen";
import VaccinationListScreen from "../vaccination/VaccinationListScreen";
import DocumentsListScreen from "../documents/DocumentsListScreen";
import MedicationDetailsScreen from "../medication/MedicationDetailsScreen";
import AvatarGroup from "../../ui/components/Avatar/AvatarGroup";
import AppointmentsListScreen from "../agenda/AppointmentsListScreen";
import { useTranslation } from "react-i18next";
import ProvidersListScreen from "./providers/ProvidersListScreen";
import { useCancelAppointmentMutation } from "../../connectors/medispring/api/medispring";
import useConfirmation from "../../hooks/useConfirmation";
import useOptions from "../../hooks/useOptions";
import { FlowContext, FlowContextProvider } from "../../ui/components/ScreenFlow/FlowContext";
import { useFlowContext } from "../../ui/components/ScreenFlow/FlowContext";

export enum Fragments {
  HOME = "home",
  AGENDA = "agenda",
  DOCUMENTS = "documents",
  RESULTS = "results",
  VACCINATION = "vaccination",
  MEDICATION = "medication",
  SETTINGS = "settings",
  PROVIDERS = "providers",
}


const HomeSection = () => {
  const { resourceId, section } = useParams();

  /*const { data: appointment } = useResource<AgendaItemModel>(
    ResourceType.APPOINTMENTS,
    resourceId,
    section != Fragments.APPOINTMENTS
  );

  
*/
  //const cancelAppointment = () => {}
  const { t } = useTranslation();

  // const cancelAppointment = useCancelAppointment();
  const [cancelAppointment] = useCancelAppointmentMutation();

  const cancelAppointmentAlert = useConfirmation({
    data: resourceId,
    alertTitle: "Annuler ce rendez-vous?",
    alertMessage:
      "En annulant ce rendez-vous, vous libérez un créneau pour un autre patient.",
    onConfirm: () => cancelAppointment(resourceId!),
    confirmationMessage:
      "Votrerendez-vous a bien été annulé, merci de nous avoir prévenu à temps, bonne journée !",
    confirmationTitle: "Rendez-vous annulé",
  });


  
  return (
    <FlowContextProvider>  
    <ScreenFlow
      activeIndex={getScreen(section ?? "", resourceId)}
    
      title={
        section ? (t(`menu.${section}`) as string) : (t("menu.home") as string)
      }
      leftButtons={<HomeButton back={!!section} />}
         >
      <HomeScreen name={Fragments.HOME}  />

      <MedicationListScreen name={Fragments.MEDICATION} />
      <MedicationDetailsScreen />

      <VaccinationListScreen />

      <DocumentsListScreen name={Fragments.DOCUMENTS} />
      <ViewDocumentScreen />

      <AppointmentsListScreen name={Fragments.AGENDA} />
      <AppointmentDetailsScreen name="eventDetails" />

      <ProvidersListScreen name={Fragments.PROVIDERS} />
    </ScreenFlow>

    
    </FlowContextProvider>
  );
};

const getScreen = (section: string, resourceId?: string) => {
  switch (section) {
    case Fragments.MEDICATION:
      return resourceId ? 2 : 1;
    case Fragments.VACCINATION:
      return 3;
    case Fragments.DOCUMENTS:
      return resourceId ? 5 : 4;

    case Fragments.AGENDA:
      return resourceId ? 7 : 6;


    case Fragments.PROVIDERS:
      return 8;

    default:
      return 0;
  }
};

export default HomeSection;
