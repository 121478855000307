import { ResourceType } from "../types/ResourceType";
import { useGetResourcesQuery } from "../api/healthdata";

export default function useResources<U>(type: ResourceType, fetch?: boolean) {
  const { data, isLoading, error, refetch } = useGetResourcesQuery(type, {
    skip: !fetch,
  });

  return { data: data as U[], isLoading, error, refetch };
}
