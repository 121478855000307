import { DocumentResource } from "./DocumentResource";
import { HCPModel } from "./HCPModel";

export interface PrescriptionModel extends DocumentResource {
  medicationId?: string;
  documentId?: string;
  rId?: string;
  prescriber?: HCPModel;
  status?: PrescriptionRequestStatus;
}

export enum PrescriptionRequestStatus {
  ACTIVE = "active",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  DRAFT = "draft",
}
