import TimeslotGroup from "./TimeslotGroup";
import { Size } from "../../tokens";
import styled from "styled-components";

interface TimeslotGroupListProps {
  slots: number[];
  onSlotSelected?: (slot: number) => void;
}

interface GroupedTimeslots {
  day: string;
  timeslots: number[];
}
const getYYYMMDDFromTime = (time: number): string =>
  String(time).substring(0, 8);

const List = styled.div``;

const getGroupedTimeslots = (slots: number[]): GroupedTimeslots[] => {
  return slots.reduce((groupedTs: GroupedTimeslots[], slot) => {
    const slotDay: string = getYYYMMDDFromTime(slot);
    let dayGroup: GroupedTimeslots | undefined = groupedTs.find(
      ({ day }) => day === slotDay
    );
    if (!dayGroup) {
      dayGroup = {
        day: slotDay,
        timeslots: [],
      };
      groupedTs = [...groupedTs, dayGroup];
    }
    dayGroup.timeslots = [...dayGroup.timeslots, slot];
    return groupedTs;
  }, []);
};

export default function TimeslotGroupList({
  slots,
  onSlotSelected = () => {},
}: TimeslotGroupListProps) {
  const groupedSlots = getGroupedTimeslots(slots);
  return (
    <List>
      {groupedSlots.map((group) => (
        <TimeslotGroup
          key={group.day}
          day={group.day}
          slots={group.timeslots}
          onSlotSelected={onSlotSelected}
        />
      ))}
    </List>
  );
}
