import { Spinner } from "../../../../ui/components/Loading/Spinner";

const LoadingScreen = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner />
    </div>
  );
};

export default LoadingScreen;
