import { useLocation } from "react-router-dom";

export default function useURLQuery() {
  const location = useLocation();
  return location.search
    .substring(1)
    .split("&")
    .map((value) => value.split("="))
    .map(([key, value]) => ({ [key]: value }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});
}
