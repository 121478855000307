import React from "react";
import styled from "styled-components";
import { Color, Size } from "../tokens";
import Typography from "./Typography/Typography";

interface Props {
  label?: string;
  property?: string;
  value?: string;
  onChange?: (value: string) => void;
  type?: string;
  options?: { label: string; value: any }[];
}

const StyledDropdown = styled.select`
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: 600;
  color: ${Color.ACCENT};
  outline: none;
  -webkit-appearance: none;
`;

const Dropdown: React.FC<Props> = ({
  label,
  property,
  value,
  onChange,
  type,
  options = [],
}) => {
  return (
    <StyledDropdown
      value={value}
      onChange={(e) => onChange && onChange(e.target.value)}
    >
      {options.map((option) => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </StyledDropdown>
  );
};

export default Dropdown;
