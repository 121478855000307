import { ReactElement } from "react";
import styled, { css } from "styled-components";
import { Size } from "../../tokens";
import { Spinner } from "../Loading/Spinner";

interface Props {
  horizontal?: boolean;
  isLoading?: boolean;
  children: ReactElement | Array<ReactElement | undefined | boolean>;
}
const ButtonGroupWrapper = styled.div<Props>`
  display: flex;
  ${({ horizontal }) =>
    css`
      flex-direction: ${horizontal ? "row" : "column"};
      justify-content: ${horizontal ? "space-around" : "flex-start"};
    `}
  gap: ${Size.S}px;
  margin: ${Size.M}px 0 0;
`;

const ButtonGroup = ({ isLoading, children, horizontal }: Props) => {
  return (
    <ButtonGroupWrapper horizontal={horizontal}>
      {isLoading ? <Spinner /> : children}
    </ButtonGroupWrapper>
  );
};

export default ButtonGroup;
