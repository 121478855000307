import {useDispatch, useSelector} from "react-redux";
import {logout, selectAllPatients} from "../login/AuthSlice";
import {LANGUAGE_VALUES,} from "../navigation/DisplaySlice";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import DisplayOptions from "../navigation/DisplayOptions";
import useDisplayOption from "../../hooks/useDisplayOption";
import {useEffect} from "react";
import Button from "../../ui/components/Button";
import {ConnectorProvider} from "../../connectors/ConnectorProvider";
import {MedispringConnector} from "../../connectors/medispring/connector/MedispringConnector";
import {triggerFileDownload} from "../../connectors/medispring/utils/utils";

const Settings = () => {
  const patients = useSelector(selectAllPatients);

  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useDisplayOption("language");

  const dispatch = useDispatch();

  useEffect(() => {
    if (language.value !== dayjs.locale()) {
      dayjs.locale(language.value as string);
      i18n.changeLanguage(language.value as string);
    }
  }, [language]);

  const getKeys = async () => {
    let i: number = 1;
    const medispringConnectors: MedispringConnector[] = ConnectorProvider.getAll<MedispringConnector>();
    for (const medispringConnector of medispringConnectors) {
      const hexadecimalPrivateKeys: string[] = (await medispringConnector.getHexadecimalPrivateKeys()).filter(Boolean);

      for (const hexadecimalPrivateKey of hexadecimalPrivateKeys) {
        if (!hexadecimalPrivateKey) continue;
        triggerFileDownload(
            hexadecimalPrivateKey,
            "application/octet-stream",
            `${medispringConnector.getIcurePatient().id}-icc-priv.2048-${i}.key`,
        );
        i++;
      }
    };
  };

  return (
    <>
      <DisplayOptions
        label={t("settings.language") as string}
        options={[
          {
            value: language,
            action: setLanguage,
            options: LANGUAGE_VALUES.map((lang) => {
              return { id: lang, label: t(`settings.${lang}`), value: lang };
            }),
          },
        ]}
      >
        <Button
          title={t("settings.downloadPrivateKey") as string}
          buttonType="small"
          onPress={() => getKeys()}
        />

        <Button
          title={t("settings.logout") as string}
          buttonType="home"
          onPress={() => dispatch(logout())}
        />
      </DisplayOptions>

{/*
     
      <Button
          title={t("settings.download") as string}
          onPress={getKeys}
        />
*/}
    </>
  );
};

export default Settings;
