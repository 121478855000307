import { FunctionComponent } from "react";
import styled from "styled-components";
import { Color, Size } from "../tokens";

interface Props {
  asset: string | React.FunctionComponent;
  color?: string;
  large?: boolean;
  illustration?: boolean;
}

const IconContainer = styled.img`
  fill: red;
`;
const SVGContainer = styled.div<{
  color?: string;
  large?: boolean;
  illustration?: boolean;
}>`
width:${(props) =>
  props.large
    ? `${Size.XXL}px`
    : props.illustration
    ? "100%"
    : `${Size.M + Size.S}px`} ;
height:${(props) =>
  props.large
    ? `${Size.XXL}px`
    : props.illustration
    ? `${Size.XXL * 3}px`
    : `${Size.M + Size.S}px`};
display:flex;
align-items:center;
justify-content:center;
 * {
width:${(props) => (props.illustration ? "100%" : undefined)} ;
height:${(props) => (props.illustration ? "100%" : undefined)};
    fill:${(props) => (props.color ? props.color : undefined)}};
    stroke:undefined;
}

`;

const Icon = ({ asset, color, large, illustration }: Props) => {
  if (typeof asset === "string") {
    return <IconContainer src={asset} />;
  } else {
    const Asset = asset;
    return (
      <SVGContainer color={color} large={large} illustration={illustration}>
        <Asset />
      </SVGContainer>
    );
  }
};

export default Icon;
