import DisplayOptions from "../navigation/DisplayOptions";
import useDisplayOption from "../../hooks/useDisplayOption";
import { t } from "i18next";

const AgendaOptions = () => {
  const [showBooked, toggleBooked] = useDisplayOption("agenda.booked");
  const [showScheduled, toggleScheduled] = useDisplayOption("agenda.scheduled");
  const [showPast, togglePast] = useDisplayOption("agenda.past");

  return (
    <DisplayOptions
    label={t("settings.display") as string}
      options={[
        {
          label: "Rendez-vous planifiés",
          value: showBooked,
          action: toggleBooked,
        },
        { label: "A prévoir", value: showScheduled, action: toggleScheduled },
        { label: "Rendez-vous passés", value: showPast, action: togglePast },
      ]}
    />
  );
};

export default AgendaOptions;
