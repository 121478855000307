import { useTranslation } from "react-i18next";
import { formatName } from "../../utils/formatting";
import { MedicationModel, RegimenModel } from "../../models/MedicationModel";
import useResource from "../../hooks/useResource";
import { HCPModel } from "../../models/HCPModel";
import { ResourceType } from "../../types/ResourceType";
import Typography from "../../ui/components/Typography/Typography";
import styled from "styled-components";
import { Size } from "../../ui/tokens";
import RegimenItem from "./RegimenItem";
import Avatar from "../../ui/components/Avatar/Avatar";
import KeyValue from "../../ui/components/KeyValue";

interface RegimenByPrescriberProps {
  hcpId: string;
  regimen: RegimenModel[];
  medication: MedicationModel;
}

const BlockContainer = styled.div`
  padding: ${Size.S}px;
`;

const RegimenList = styled.div``;

const RegimenByPrescriber = ({
  hcpId,
  regimen,
  medication,
}: RegimenByPrescriberProps) => {
  const { t } = useTranslation();
  //const { data: hcp } = useResource<HCPModel>(ResourceType.PROVIDERS, hcpId);
  return (
    <BlockContainer>
      <RegimenList>
        {regimen.map((r, index) => (
          <KeyValue
            key={index}
            label={t("contacts." + r.dayPeriod!)}
            value={`${r.administratedQuantity} ${
              medication.administrationMode?.toLowerCase() ?? ""
            }`}
          />
        ))}
      </RegimenList>
    </BlockContainer>
  );
};

export default RegimenByPrescriber;
