import { ReactElement, useCallback, useState } from "react";
import { ToastContext, ToastProps } from "./ToastContext";

import { v4 as uuidv4 } from "uuid";

type ToastProviderProps = {
  children: React.ReactNode;
};
const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const addToast = useCallback((toast: ToastProps) => {
    const id = new Date().getTime().toString();
    const t = { ...toast, id };
    setToasts((currentToasts) => [...currentToasts, t]);

    setTimeout(() => {
      setToasts((currentToasts) =>
        currentToasts.map((t) => (t.id !== id ? t : { ...t, active: false }))
      );
    }, 3000);
    setTimeout(() => {
      setToasts((currentToasts) => currentToasts.filter((t) => t.id !== id)); // currentToasts.map(t => t.id !== toast.id ? t : {...t,active:false}));
    }, 3300);
  }, []);

  return (
    <ToastContext.Provider value={{ toasts, addToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
