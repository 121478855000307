import { createContext, ReactNode, useContext } from 'react';

import { useState } from 'react';


interface FlowContextType {
  activeScreen?: number;
  setActiveScreen?: (screen: number) => void;

  setActions: (screen: string | undefined, items: React.ReactNode) => void;
  getActions: (screen: string | null) => React.ReactNode | React.ReactNode[];
}

export const FlowContext = createContext<FlowContextType>({

  setActions: () => {},
  getActions: () => [],
});

const _rightItemsByScreen: Record<string, React.ReactNode | React.ReactNode[]> = {};


export const useFlowContext = () => {
  const context = useContext(FlowContext);

  if (!context) {
    throw new Error('useFlowContext must be used within a FlowContextProvider');
  }
  return context;
};

export const FlowContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [activeScreen, setActiveScreen] = useState<number | undefined>(undefined);


  const setActions = (screen: string | undefined, items: React.ReactNode) => {
    if (screen) _rightItemsByScreen[screen] = items;

    
  };
  const getActions = (screen: string | null) => {
    return screen ? _rightItemsByScreen[screen] : [];
  };



  return (
    <FlowContext.Provider value={{  activeScreen, setActiveScreen, setActions, getActions }}>
      {children}
    </FlowContext.Provider>
  );
};

