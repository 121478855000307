import styled from "styled-components";
import { Size } from "../../tokens";

const Spacing = styled.div<Props>`
  height: ${(props) =>
    props.small
      ? Size.S
      : props.large
      ? Size.L
      : props.xlarge
      ? Size.XL
      : Size.M}px;
`;

interface Props {
  small?: boolean;
  large?: boolean;
  xlarge?: boolean;
}

export default Spacing;
