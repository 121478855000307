import {useEffect, useState} from "react";
import Button from "../../ui/components/Button";

import Typography from "../../ui/components/Typography/Typography";
import ButtonGroup from "../../ui/components/Layout/ButtonGroup";
import {useDispatch} from "react-redux";
import {hasRecoveryKey, TokenProvider} from "./AuthSlice";
import {useConnectMutation} from "../../api/authentication";
import Spacing from "../../ui/components/Layout/Spacing";
import {useTranslation} from "react-i18next";
import {getToken, launchIAMConnect} from "../../connectors/ehealth/api/iam";
import {useSearchParams} from "react-router-dom";
import Icon from "../../ui/components/Icon";
import {Icons} from "../../ui/assets";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
`;

const LoginScreen = () => {
  const dispatch = useDispatch();

  const [connect, { isLoading }] = useConnectMutation();
  const [isFetchingToken, setIsFetchingToken] = useState(false);

  const [params] = useSearchParams();
  const recoveryKey = params.get("key");

  // const [email, setEmail] = useState("jerome.cordiez+billcloud@gmail.com");
  // const [email, setEmail] = useState("jerome.cordiez+colombe@gmail.com");
  // const [email, setEmail] = useState("jerome.cordiez+ad@gmail.com");
  // const [password, setPassword] = useState("tiredaile");

  const [email, setEmail] = useState("fabien.zimmer+ezo.002@medispring.be");
  const [password, setPassword] = useState("Ztf993pf");

  useEffect(() => {
    if (recoveryKey) {
      dispatch(hasRecoveryKey(recoveryKey));
    }
  }, [recoveryKey]);


  const retrieveTokens = async (code: string) => {
    console.log("retrieving tokens");
    setIsFetchingToken(true);
    const authToken = {...await getToken(code),tokenProvider:TokenProvider.BE_FAS};
    /*
    const authToken = {
      token:
        "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJCcC1xTHJEaExvODc0ZXJRU09QbGtGUklqTm9jRjN3UHkwcl9oWTdzYWswIn0.eyJleHAiOjE3MDkyMjEyMjAsImlhdCI6MTcwOTIyMDkyMCwiYXV0aF90aW1lIjoxNzA5MjE5NDczLCJqdGkiOiI3ZWZiMDYyZS0yMzY4LTRmNTYtOWM0ZC1kMWIwN2RlYzZlMGEiLCJpc3MiOiJodHRwczovL2FwaS1hY3B0LmVoZWFsdGguZmdvdi5iZS9hdXRoL3JlYWxtcy9oZWFsdGhjYXJlIiwic3ViIjoiYmMzZjUzMDEtZGViZC00NTFhLWIxZjItZDM2NGQzMTkzZTljIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoibWVkaXNwcmluZy1wYXRpZW50YXBwIiwibm9uY2UiOiIzMTI2Iiwic2Vzc2lvbl9zdGF0ZSI6ImViZjc5OTgzLTEwMjEtNDJiOC1hNjU1LTA2NzlhN2Q5ZmFmMiIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL2FjYy1wYXRpZW50Lm1lZGlzcHJpbmcuYmUiXSwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBzc2luIiwic2lkIjoiZWJmNzk5ODMtMTAyMS00MmI4LWE2NTUtMDY3OWE3ZDlmYWYyIiwic3NpbiI6Ijc5MDcwMjM0OTM4IiwibmFtZSI6IkplcsO0bWUgQ29yZGlleiIsInByZWZlcnJlZF91c2VybmFtZSI6Ijc5MDcwMjM0OTM4IiwibG9jYWxlIjoiZnIiLCJnaXZlbl9uYW1lIjoiSmVyw7RtZSIsImZhbWlseV9uYW1lIjoiQ29yZGlleiIsInVzZXJQcm9maWxlIjp7Imxhc3ROYW1lIjoiQ29yZGlleiIsImZpcnN0TmFtZSI6IkplcsO0bWUiLCJzc2luIjoiNzkwNzAyMzQ5MzgifX0.U4m8IPS38h3Pzg8PEloSLiNNJZ86inpBxyEvOsKK9AtL3Rlubssaxb9NI_AFmwgMEeh_VYgGiW3X49vr6Rtgl8zM9n3tZMEmWFhIYtIjMDwkm7YZKNNWZmxlQWiyfmAD_KXcgfapA1oAGUxgLbDCNxNlcCkLUaCggDH8th_d2HeRrYvaYTizJ8-w24_sg8-CJ5fRtbzDTLoV0TdWMRQZuR9NLhNbRk-RGaoYbzY-TOZcFYJmD13YoGHd69Tf2QfZqvYeuOpVNn7AmrVdzvbbxqo4pzZq9Zss7i_i-MrC6iY3CxOG0vjh2X_ZS6oNR9vxY5-XmJJLVtFp1BQmQWSV6g",
      tokenProvider: TokenProvider.BE_FAS,
    };*/
    setIsFetchingToken(false);
    if (authToken.token) {

      connect(authToken);
      // login({token:tokenResponse.access_token, tokenProvider:"be.fas"})
    }
  };

  const code = params.get("code");
  useEffect(() => {
    if (code) retrieveTokens(code); 
  }, [code]);
  

  const { t } = useTranslation();

  return (
    <Container>
       
<div>
<Icon illustration asset={Icons.illustrations.launch} />
<Spacing xlarge />

      <Typography.body align="center">Bienvenue sur E·ZO, l’application santé qui vous connecte à votre équipe soignante et à votre dossier de santé</Typography.body>
     
        <ButtonGroup isLoading={isLoading || isFetchingToken}>
          <Button
            primary
            title={t("login.connect") as string}
            onPress={launchIAMConnect}
          />

          <Button title="Demo login" onPress={() => connect({ userName: email, password, })} />
        </ButtonGroup>
        </div>

      <Typography.small align="center">
        By pressing the button above, you agree to our <a href="/terms">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>.
      </Typography.small>
    </Container>
  );
};

export default LoginScreen;
