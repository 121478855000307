import React, {useContext, useEffect, useRef, useState,} from "react";

import styled, {css, keyframes} from "styled-components";
import {Color, Elevation, Size} from "../../tokens";
import Typography from "../Typography/Typography";
import {ToastContext, ToastProps, ToastTypeEnum} from "./ToastContext";

const translateIn = keyframes`
  from {
   opacity: 0;
  }
  to {
    opacity: .9;
    
  }
`;
const translateOut = keyframes`
  from {
   opacity: .9;
  }
  to {
    opacity: 0;
  }
`;

const ToastContainer = styled.div<ToastProps>`
  box-sizing: border-box;

  background-color: ${(props) =>
    props.type === ToastTypeEnum.SUCCESS
      ? Color.GREEN_DARK
      : props.type === ToastTypeEnum.ERROR
      ? Color.DESTRUCTIVE
      : Color.NEUTRAL_800};

  max-width: 480px;
  box-shadow: ${Elevation.L};
  border-radius: 8px;
  padding: ${Size.M}px;

  margin: ${Size.S}px ${Size.S}px ${Size.L}px ${Size.S}px;
  transition: all 0.2s ease-in-out;

  animation: 0.3s ${translateIn} ease-in-out;

  ${({ active }) =>
    !active &&
    css`
      opacity: 0;
    `}
`;

const ToastListContainer = styled.div<{ offset: number }>`
  position: absolute;
  right: 0;
  z-index: 99999;
  top: 100%;
  width: 100%;
  transform: translateY(${(props) => -props.offset}px);
  transition: transform 0.3s ease-in-out;
`;

const DISPLAY_TIME = 3000;

const ToastList = () => {
  const { toasts } = useContext(ToastContext);
  const listRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (listRef.current) {
      setOffset(listRef.current.offsetHeight);
    }
  }, [toasts.length]);

  return (
    <ToastListContainer ref={listRef} offset={offset}>
      {toasts.map((toast, index) => (
        <ToastContainer
          key={index}
          active={toast.active}
          className="popup"
          type={toast.type ?? ToastTypeEnum.DEFAULT}
        >
          <Typography.strong color={Color.WHITE}>
            {toast.title}
          </Typography.strong>
          <div style={{ height: Size.S }}></div>
          <Typography.body color={Color.WHITE}>{toast.message}</Typography.body>
        </ToastContainer>
      ))}
    </ToastListContainer>
  );
};

export default ToastList;
