import { AgendaItemModel } from "../../../models/AgendaItemModel";
import { DiagnosticReportModel } from "../../../models/DiagnosticReportModel";
import { DocumentContent } from "../../../models/DocumentReference";
import { DocumentResource } from "../../../models/DocumentResource";
import { HCPModel } from "../../../models/HCPModel";
import { MedicationModel } from "../../../models/MedicationModel";
import { PrescriptionModel } from "../../../models/PrescriptionModel";
import { VaccinationModel } from "../../../models/VaccinationModel";
import { ConnectorOptions } from "../../ConnectorProvider";
import { DataConnectorInterface } from "../../DataConnectorInterface";
import { UserCredentials } from "../../../models/UserCredentials";
import { awellActivityToAgendaItemModel, awellActivityToDocumentResource } from "../awellMappers";
import client from "../clients/awellOrchestrationGraphQlClient";
import { usePathwayActivities } from "../hooks/awell-orchestration";
import { MESSAGE } from "../hooks/awell-orchestration/useMessage/graphql/message.graphql";
import { PATHWAY_ACTIVITIES } from "../hooks/awell-orchestration/usePathwayActivities/graphql/GetPathwayActivities.graphql";
import { GET_PATIENT_PATHWAYS } from "../hooks/awell-orchestration/usePatientPathways/graphql/GetPatientPathways.graphql";
import { GET_STAKEHOLDERS } from "../hooks/awell-orchestration/useStakeholders/graphql/getStakeholders.graphql";
import { Activity, ActivityObjectType, ActivityStatus, PathwayStatus } from "../types/generated/api.types";

const API_KEY = "wBgnMtwxAFqLNGKzhpmlA0EtssDio8x1";

/*
curl -X POST \
  'https://api.sandbox.awellhealth.com/orchestration/m2m/graphql' \
  -H "Content-Type: application/json" \
  -H 'apiKey: wBgnMtwxAFqLNGKzhpmlA0EtssDio8x1' \
  -d '{"query":"query GetPublishedPathwayDefinitions{publishedPathwayDefinitions{publishedPathwayDefinitions{id title}}}"}'
*/


const PATIENT_ID= "yDDZvKwY9uBaIj9umJxqS";

export class AwellConnector {
    public id: string;
    private constructor() {
        this.id = "AWELL";
        
      }

    static async init() {
        const c = new AwellConnector( );    
        
        return c;
      }

      private async  __getPathways(id:string):Promise<string[]>{
        const r = await client.query({query:GET_PATIENT_PATHWAYS, fetchPolicy: 'network-only', variables:{patient_id:id,status:[PathwayStatus.Active]}})   
        return r.data.patientPathways.patientPathways.map((p:any) => p.id);
    }

    static async getAvailableConnections(credentials:UserCredentials): Promise<ConnectorOptions[]> {
        return [];//{id:"Chirec",info:{id:credentials.userName},credentials:{userId:credentials.userName!,groupId:credentials.groupId!,password:credentials.password!}}]

    }
     

    async appointments(): Promise<AgendaItemModel[]> {
        const events:AgendaItemModel[] = [];

        const p = await this.__getPathways(PATIENT_ID);

       p.forEach(async (p:string) => {
            const r = await client.query({query:PATHWAY_ACTIVITIES, fetchPolicy: 'network-only', variables:{pathway_id:p}})
            const activities = r.data.pathwayActivities.activities.filter((a:Activity) => a.status == ActivityStatus.Active && a.object.type === ActivityObjectType.Form);
            activities.forEach((a:Activity) => events.push(awellActivityToAgendaItemModel(a)))
        })

        return events;
    }
    async medications(): Promise<MedicationModel[]> {
        return []
   }
   async prescriptions(): Promise<PrescriptionModel[]> {
       return []
    }
    async  vaccinations(): Promise<VaccinationModel[]> {
        return []
    }
    async  documents(): Promise<DocumentResource[]> {
        const docs:DocumentResource[] = [];

        
        const p = await this.__getPathways(PATIENT_ID);
       
        p.forEach(async (p:string) => {
            const r = await client.query({query:PATHWAY_ACTIVITIES, fetchPolicy: 'network-only', variables:{pathway_id:p}})
            const activities = r.data.pathwayActivities.activities.filter((a:Activity) => a.object.type === ActivityObjectType.Message);
           activities.forEach(async (a:Activity) =>{
                const doc = awellActivityToDocumentResource(a);
                const message = await this.__messageBody(a.object.id);
                doc.comment = message;
                docs.push(doc)
            })  

        })
        
        return docs;
    }
    private async __messageBody(id:string):Promise<string>{
        const r = await client.query({query:MESSAGE, variables:{id}})
        return r.data.message.message.body;
    }
    async  providers(): Promise<HCPModel[]> {
        const definitionId = "g7y4Hg6WkQ9v";
        const r = await client.query({query:GET_STAKEHOLDERS, variables:{stakeholder_definition_ids:[definitionId]}})
        return []
    }
    async  provider(id: string | undefined): Promise<HCPModel> {

        return {id:""};
    }
    async  appointment(id?: string | undefined): Promise<AgendaItemModel | undefined> {
        throw new Error("Method not implemented.");
    }
    async  medication(id?: string | undefined): Promise<MedicationModel | undefined> {
        throw new Error("Method not implemented.");
    }
    async  prescription(id?: string | undefined): Promise<PrescriptionModel | undefined> {
        throw new Error("Method not implemented.");
    }
    async  document(id?: string | undefined): Promise<DocumentResource | undefined> {
        throw new Error("Method not implemented.");
    }
    async vaccination?(id?: string | undefined): Promise<VaccinationModel | undefined> {
        return {id:""};
    }
    async labResults(): Promise<DiagnosticReportModel[]> {
        return []
    }
    async labResult(id?: string | undefined): Promise<DiagnosticReportModel | undefined> {
        throw new Error("Method not implemented.");
    }
    async  media(id?: string | undefined): Promise<DocumentContent | undefined> {
        throw new Error("Method not implemented.");
    }
    async  medias(): Promise<DocumentContent[]> {
        return [];
    }
    

}

