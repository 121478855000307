import Typography from "../../ui/components/Typography/Typography";
import styled from "styled-components";
import { Color, Size } from "../../ui/tokens";
import Dropdown from "./Dropdown";

interface KeyValueProps {
  label: string;
  value: string;
  options?: any[];
  onPress?: () => void;
  onChange?: (value: any) => void;
}

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid ${Color.NEUTRAL_200};
  padding: ${Size.S + Size.XS}px 0;
  gap: ${Size.M}px;

  &:last-child {
    border-bottom: none;
  }
  > span:first-child {
    flex: 1;
  }
  > span:last-child {
    flex: 1;
  }
`;

const KeyValue = ({
  label,
  value,
  onPress,
  onChange,
  options,
}: KeyValueProps) => {
  return (
    <ItemContainer onClick={onPress}>
      <Typography.body>{label}</Typography.body>

      {options ? (
        <Dropdown options={options} onChange={onChange} value={value} />
      ) : (
        <Typography.strong
          align="right"
          color={onPress ? Color.ACCENT : Color.NEUTRAL_800}
        >
          {value}
        </Typography.strong>
      )}
    </ItemContainer>
  );
};

export default KeyValue;
