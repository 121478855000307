// eHealth constants
const BASE_URL_ACC = "https://api-acpt.ehealth.fgov.be";
const BASE_URL_PROD = "https://api.ehealth.fgov.be";
const REALM_ID = "healthcare";

export const CLIENT_ID = "medispring-patientapp";
//export const CLIENT_ID = "medispring-caregiver-dev";

const BASE_URL = `${BASE_URL_ACC}`;
const ENDPOINT = `${BASE_URL}/auth/realms/${REALM_ID}/protocol/openid-connect`;

// Medispring environment
const ROOT_URL = window.location.origin;
//const ROOT_URL = "https://localhost:4200";

export const REDIRECT_ROUTE = "/app/auth"
//  "/Authentication/Authenticate/FederatedAuthentication";
//export const REDIRECT_ROUTE = "/private/iamENC";

const MS_BASE_URL = ROOT_URL;
export const REDIRECT_URI = `${ROOT_URL}${REDIRECT_ROUTE}`;
export const AUTH_ENDPOINT = `${ENDPOINT}/auth`;
export const TOKEN_ENDPOINT = `${ENDPOINT}/token`;

export const INFO_ENDPOINT = `${ENDPOINT}/userinfo`;

/*
https://api.ehealth.fgov.be/auth/realms/healthcare/broker/saml/login?session_code=pimb8Fr-z_VI814rtwwN849-DiNcnXQzooerreboB1s&client_id=healthconnect-helena&tab_id=Ce3Gehgr3Ho

https://api.ehealth.fgov.be/auth/realms/healthcare/broker/saml/login?session_code=Mn8hKjv6R03yucAmiT2uLniVTg1aZA6yBhHnxy-xHBI&client_id=fpshealth-phv&tab_id=tbBjTjZLcTE
*/
