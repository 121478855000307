import styled from "styled-components";

import { Breakpoint } from "../tokens";
import { ReactElement } from "react";

interface MobileButtonProps {
  breakpoint?: Breakpoint;
  visible?: boolean;
  children?: ReactElement;
  onPress?: () => void;
}

const ButtonContainer = styled.div<MobileButtonProps>`
  display: none;
  pointer-events: none;
  @media (max-width: ${(props) => props.breakpoint}px) {
    display: ${(props) => (props.visible ? "block" : "none")};
    pointer-events: all;
  }
`;

const MobileButton = ({
  children,
  visible = true,
  onPress,
  breakpoint = Breakpoint.MOBILE,
}: MobileButtonProps) => {
  return (
    <ButtonContainer
      onClick={onPress}
      visible={visible}
      breakpoint={breakpoint}
    >
      {children}
    </ButtonContainer>
  );
};
export default MobileButton;
