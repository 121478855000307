import styled, { keyframes, css } from "styled-components";

import { Size, Color, Breakpoint, Easing } from "../tokens";
import Button, { StyleTypes } from "./Button";
import Typography from "./Typography/Typography";
import ButtonGroup from "./Layout/ButtonGroup";
import ReactDOM from "react-dom";

//  ${(props) => props.active ? 'flex' : 'none'};

const fadeIn = keyframes`
from {
  opacity:0;
}
to {
  opacity:1;
}
`;

// TODO: container and background should be shared with modal sheet

const AlertContainer = styled.div<AlertProps>`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 99999;

  pointer-events: ${(props) => (props.active ? "auto" : "none")};
`;

const AlertBackground = styled.div<{ visible?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  background-color: ${Color.MODAL_BACKGROUND};
  backdrop-filter: blur(8px);

  transition: opacity 0.3s ${Easing.IN_OUT};
`;

const Popup = styled.div<{ visible?: boolean }>`
overflow: scroll;
    position:relative;
    box-sizing: border-box;
   width:480px;
   margin:auto;
    background-color:#fff;
    border-radius: 8px;
  padding:${Size.L}px;
  border:1px solid ${Color.NEUTRAL_200};
  box-shadow: 0px 6px 80px rgba(0, 0, 0, 0.07), 0px 2.50666px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 1.34018px 17.869px rgba(0, 0, 0, 0.0417275), 0px 0.751293px 10.0172px rgba(0, 0, 0, 0.035), 0px 0.399006px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.166035px 2.21381px rgba(0, 0, 0, 0.0196802);
 
  ${({ visible }) =>
    css`
      opacity: ${visible ? 1 : 0};
    `}

  @media (max-width: ${Breakpoint.TABLET}px) {
      margin:auto ${Size.L}px;    
`;

export interface AlertAction {
  label: string;
  type?: StyleTypes;
  action?: () => void;
}

interface AlertProps {
  active?: boolean;
  title?: string;
  message?: string;
  actions?: AlertAction[];
}

const Alert = ({ active = true, title, message, actions }: AlertProps) => {
  return (
    <AlertContainer active={active}>
      <AlertBackground visible={active} onClick={Alert.close} />
      <Popup visible={active}>
        <Typography.h3>{title}</Typography.h3>
        <Typography.body>{message}</Typography.body>
        {actions && (
          <ButtonGroup horizontal>
            {actions?.map((action, index) => (
              <Button
                key={index}
                buttonType={action.type ?? "small"}
                title={action.label}
                onPress={action.action}
              />
            ))}
          </ButtonGroup>
        )}
      </Popup>
    </AlertContainer>
  );
};

Alert.show = (title: string, message: string, actions: AlertAction[]) => {
  let container = document.getElementById("alert");
  if (!container) {
    container = document.createElement("div");
    container.id = "alert";
    document.body.appendChild(container);
  }
  ReactDOM.render(
    <Alert title={title} message={message} actions={actions} />,
    container
  );
};
Alert.close = () => {
  const container = document.getElementById("alert");
  if (container) ReactDOM.unmountComponentAtNode(container);
};

export default Alert;
