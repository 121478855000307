import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../../ui/components/Button";
import NavBar from "../../../../ui/components/ScreenFlow/NavBar";
import Typography from "../../../../ui/components/Typography/Typography";
import useNext from "../../../../hooks/useNext";
import useBack from "../../../../hooks/useBack";
import Section from "../../../../ui/components/Layout/Section";
import EzoList from "../../../../ui/components/List/EzoList";
import { useGetAppointmentTypesQuery } from "../../api/medispring";
import useURLQuery from "../../../../hooks/useURLQuery";
import { getUniquePlacesFromAppointmentTypes } from "../../utils/utils";
import Assets, { Icons } from "../../../../ui/assets";
import { formatAddress } from "../../../../utils/formatting";
import { icureAddressToAddressModel } from "../../mappers";
import useUpdateSearchParams from "../../../../hooks/useUpdateSearchParams";
import SectionShimmer, {
  ListShimmer,
} from "../../../../ui/components/Loading/SectionShimmer";
import ModalSheetNavigationButton from "../../../../ui/components/ModalSheetNavigationButton";
import { Size } from "../../../../ui/tokens";
import Icon from "../../../../ui/components/Icon";

const LocationSelectionScreen = () => {
  const { hcp } = useURLQuery();
  const { data, isLoading } = useGetAppointmentTypesQuery(hcp);
  const updateParam = useUpdateSearchParams();

  return (
    <>
      {isLoading ? (
        <SectionShimmer />
      ) : (
        <EzoList
          data={getUniquePlacesFromAppointmentTypes(data)}
          icon={(place) => <Icon asset={Icons.icons.pin_xs} />}
          title={(place) => place.name ?? "Yooo"}
          details={(place) =>
            formatAddress(icureAddressToAddressModel(place.address!))
          }
          onItemSelection={(place) => {
            updateParam("place", place.id!);
          }}
        />
      )}{" "}
    </>
  );
};

export default LocationSelectionScreen;
