import {
  Address,
  CalendarItem,
  Contact,
  HealthcareParty,
  MedicalLocation,
  Patient,
  Place,
  Service,
  Telecom,
  UserAndHealthcareParty,
} from "@icure/api";
import {AgendaItemModel, AgendaItemStatusEnum,} from "../../models/AgendaItemModel";
import {MedicationAdministrationRate, MedicationModel} from "../../models/MedicationModel";
import {PatientModel} from "../../models/PatientModel";
import {HCPModel} from "../../models/HCPModel";
import {AddressModel, AddressUseEnum} from "../../models/AddressModel";
import {ContactPoint, ContactPointUse} from "../../models/ContactPoint";
import {ObservationModel} from "../../models/ObservationModel";
import {OrgModel} from "../../models/OrgModel";
import {DiagnosticReportModel} from "../../models/DiagnosticReportModel";
import {VaccinationModel} from "../../models/VaccinationModel";
import {PrescriptionModel, PrescriptionRequestStatus} from "../../models/PrescriptionModel";
import {DocumentType} from "../../models/DocumentResource";
import {capitalizeFirstLetter, formatName} from "../../utils/formatting";
import {VIEWED_ON_TAG_TYPE} from "../../constants/common";

export function iCureMedicationServiceToMedicationModel(
  icureService: Service,
  patientId: string
): MedicationModel {
  const medicationValue = icureService?.content?.medication?.medicationValue;
  // medicationValue?.frequency.code
  return {
    id: icureService.id!, // medicationMapping.new(icureService as any, DataSource.ICURE),
    viewedOn: getViewedOnTstampByResource(icureService),
    updated: icureService.openingDate,
    patientId,
    contactId: icureService.contactId,
    name: medicationValue?.medicinalProduct?.intendedname,
    instruction: medicationValue?.instructionForPatient,
    hcp: icureService.responsible,
    administrationMode: (medicationValue as any)?.options?.administrationMode
      ?.stringValue,
    isChronic: medicationValue?.temporality === "chronic",
    regimen:
      medicationValue?.regimen?.map((r) => ({
        medicationId: icureService.id!,
        administratedQuantity: r.administratedQuantity?.quantity,
        dayPeriod: r.dayPeriod?.code,
        prescriber: icureService.responsible,
      })) ?? [],
    endMoment: medicationValue?.endMoment,
    posology: medicationValue?.posology,
    commentForDelivery: medicationValue?.commentForDelivery,
    
    intendedcds:
      medicationValue?.medicinalProduct?.intendedcds?.map((cd) => ({
        code: cd.code,
        type: cd.type,
      })) ?? [],
    periodicity: medicationValue?.frequency?.code,
    frequency: frequencyCodeToAdminRate(medicationValue?.frequency?.code),
    // FIXME : we already store the CDs above
    atc: medicationValue?.medicinalProduct?.intendedcds?.find(
      (cd) => cd.type === "CD-ATC"
    )?.code,
    cnk: medicationValue?.medicinalProduct?.intendedcds?.find(
      (cd) => cd.type === "CD-DRUG-CNK"
    )?.code,
    vmp: medicationValue?.medicinalProduct?.intendedcds?.find(
      (cd) => cd.type === "CD-VMPGROUP"
    )?.code,
    //      tag.code === "pendingPrescription"
  };
}

export function icureServiceToObservationModel(
  service: Service
): ObservationModel {
  return {
    id: service.id!,
    viewedOn: getViewedOnTstampByResource(service),
    index: service.index,
    issued: service.valueDate,
    code: service.label,
    interpretation: service.codes?.find((code) => code.type === "CD-SEVERITY")
      ?.code,
    value: extractValueFromService(service),
    referenceRange: service.content?.fr?.measureValue
      ? {
          low: service.content?.fr.measureValue.referenceRanges?.find(r=>true)?.low,
          high: service.content?.fr.measureValue.referenceRanges?.find(r=>true)?.high,
        }
      : undefined,
  };
}

function extractValueFromService(service: Service) {
  // todo : localize this
  const content = service.content?.fr;
  if (content?.measureValue) {
    return {
      valueQuantity: {
        unit: content.measureValue?.unit,
        value: content.measureValue?.value,
      },
    };
  } else if (content?.stringValue !== "" && content?.stringValue !== " ") {
    return {
      valueString: content?.stringValue!,
    };
  }
  return undefined;
}

function frequencyCodeToAdminRate(code?: string) {
  switch (code) {
    case "D":
      return MedicationAdministrationRate.DAILY;
    case "W":
      return MedicationAdministrationRate.WEEKLY;
    case "M":
      return MedicationAdministrationRate.MONTHLY;
  }

  return undefined;
}

export function icureAddressToAddressModel(address: Address): AddressModel {
  return {
    text: address.descr,
    streetName: address.street,
    houseNumber: address.houseNumber,
    zip: address.postalCode,
    city: address.city,
    country: address.country,
    use: address.addressType as AddressUseEnum,
  };
}

export function icurePlaceToAddressModel({ address }: Place): AddressModel {
  return {
    text: address?.descr,
    streetName: address?.street,
    houseNumber: address?.houseNumber,
    zip: address?.postalCode,
    city: address?.city,
    country: address?.country,
    use: address?.addressType as AddressUseEnum,
  };
}

/*
export function iCurePrescriptionServiceToPrescriptionModel(
  service: Service
): PrescriptionModel {
  //if (!service) return { id: "pr", issued:0 };
  return {
    id: service.id!,
    issued:service.valueDate!,
    medicationId: service.formId!,
    documentId: service.content?.documentId.documentId,
    rId: service.content?.rid?.stringValue,
    hcp: service.responsible!,
  };
}
*/
export function iCurePrescriptionServiceToPrescriptionModel(
  service: Service
): PrescriptionModel {
  //console.log(service.content)
  return {
    id: service.id!,
    viewedOn: getViewedOnTstampByResource(service),
    hcp: service.responsible,
    type: DocumentType.MEDICATION_PRESCRIPTION,
    issued: service.openingDate!,
    rId: service.content?.rid?.stringValue,
    status: service.tags?.some((tag) => tag.code === "pendingPrescription")
      ? PrescriptionRequestStatus.DRAFT
      : PrescriptionRequestStatus.COMPLETED,

    title:
      service.content?.medication?.medicationValue?.medicinalProduct
        ?.intendedname!,
    media: [
      {
        type: DocumentType.MEDICATION_PRESCRIPTION,
        id: service.id!,
        created: service.openingDate!,
        attachment: service?.content?.documentId?.documentId,
      },
    ],
    //description: service.content?.rid?.stringValue,
    /*content: {
      attachment: service.content?.documentId.documentId,
      format: "pdf",
    },*/
  };
}

export function icureCalendarItemToAgendaItemModel({
  ci,
  patient,
  hcp,
}: {
  ci: CalendarItem;
  patient: PatientModel;
  hcp: HCPModel;
}): AgendaItemModel {
  return {
    id: ci.id!, // agendaMapping.new(ci as any, DataSource.ICURE),
    viewedOn: getViewedOnTstampByResource(ci),
    description: "Consultation", // ci.calendarItemTypeId,
    created: ci.created,
    location: ci.address ? icureAddressToAddressModel(ci.address) : undefined,
    start: ci.startTime!,
    end: ci.endTime,
    appointmentType: ci.calendarItemTypeId,
    supportingInformation: { identifier: ci.agendaId!, type: "Agenda" },

    hcp: ci.responsible,
    // participantIds:[patient.id, ci.responsible!],
    // patientId: ci.responsible,
    participants: hcp ? [patient, hcp] : [patient],
    status: ci.cancellationTimestamp ? AgendaItemStatusEnum.CANCELLED : AgendaItemStatusEnum.BOOKED,
  };
}
export function icureServiceWithHCPToAgendaItemModel({
  service,
  hcp,
  patient,
}: {
  service: Service;
  hcp: HCPModel | null;
  patient: PatientModel;
}): AgendaItemModel {
  const reason = service.content!.updateNoteForPatient?.stringValue
    ? `${service.content!.updateNoteForPatient?.stringValue}`
    : "";
  return {
    id: service.id!, // agendaMapping.new(ci as any, DataSource.ICURE),
    viewedOn: getViewedOnTstampByResource(service),
    title: capitalizeFirstLetter(service.content!.descr.stringValue ?? ""),
    created: service.created,
    description: reason,
    start: service.valueDate!,
    requester: service.content!.requester?.stringValue,
    specialtyRequester: service.content!.specialityRequester?.stringValue,
    participants: hcp ? [patient, hcp] : [patient],
    hcp: service.content!.requester?.stringValue,
    procedureId: service.id,
    procedureCode: service.codes?.find(
      (code) => code.type === "BE-THESAURUS-PROCEDURES"
    )?.code,
    status: AgendaItemStatusEnum.PROPOSED,
  };
}

function __getMostRecentServiceForTag(services: Service[], tagCode: string) {
  return services
    .filter((service) => service.tags?.some((tag) => tag.code === tagCode))
    .reduce((mostRecent: Service | null, service) => {
      const serviceDate = service.valueDate!;
      if (!mostRecent || serviceDate > mostRecent.valueDate!) {
        return service;
      }
      return mostRecent;
    }, null);
}

export function icureReportToDiagnosticReport(
  id: string,
  patient: PatientModel,
  services: Service[],
  hcp?: HCPModel,
  lab?: HCPModel
): DiagnosticReportModel {
  const mostRecentComment = __getMostRecentServiceForTag(
    services,
    "contactreport-note-for-patient"
  );
  const specialty = services?.find((service) =>
    service.tags?.some((tag) => tag.code === "speciality")
  )?.content?.descr.stringValue;
  const procedure = services?.find((service) =>
    service.tags?.some((tag) => tag.code === "prodecure")
  )?.content?.descr_fr?.stringValue;

  const attachment = services?.find((service) =>
    service.tags?.some((tag) => tag.code === "document")
  );

  const documentService: Service | undefined = getDocumentService(services);

  //console.log(attachment)
  return {
    id,
    type: DocumentType.REPORT,
    viewedOn: getViewedOnTstampByResource(documentService ?? services?.[0]),
    hcp: hcp,
    performer: lab ?? hcp,
    subject: patient,
    issued: services[0].openingDate!,
    comment: mostRecentComment?.content?.note.stringValue,
    commented: mostRecentComment?.valueDate!,
    specialty,
    procedure,
    //context: { related: report },
    //title: t(`specialties.${specialty}`) ?? "Rapport",
    conclusion:
      services?.find((service) =>
        service.tags?.some((tag) => tag.code === "conclusion")
      )?.content?.descr.stringValue ?? "",

    media: [
      {
        type: DocumentType.REPORT,
        id,
        created: services[0].valueDate!,
        description: attachment?.content?.fr?.stringValue ?? "",
        attachment: attachment?.content?.documentId?.documentId,
      },
    ],
    documentService: documentService,
  };
}

export function icureLabResultsToReportModel(
  id: string,
  patient: PatientModel,
  services: Service[],
  ctcs: Contact[],
  hcp?: HCPModel,
  lab?: HCPModel
): DiagnosticReportModel {
  const mostRecentComment = __getMostRecentServiceForTag(
    services,
    "labresult-note-for-patient"
  );

  const documentService: Service | undefined = getDocumentService(services);

  return {
    id,
    viewedOn: getViewedOnTstampByResource(documentService ?? services?.[0]),
    type: DocumentType.LAB_RESULT,
    issued: ctcs[0]!.closingDate!,
    hcp: hcp,
    resultsInterpreter: hcp,
    subject: patient,
    result: services
      .filter(
        (s) => !s.tags?.some((tag) => tag.code === "labresult-note-for-patient")
      )
      .map(icureServiceToObservationModel),
    performer: lab,
    title: formatName(lab),
    // procedure: services[0].label,
    comment: mostRecentComment?.content?.note.stringValue,
    conclusion: mostRecentComment?.content?.note.stringValue, //services.find(s=> s.tags?.some(tag=>tag.code === "labresult-note-for-patient" ))?.content?.note.stringValue,
    // specialty: Specialty.CARDIOLOGY,
    documentService: documentService,
  };
}

export function getDocumentService(services: Service[]): Service | undefined {
  return services.find(svc => svc.content?.documentId);
}

// this should replace the above

export function icureLabResultsContactToReportModel(
  contact: Contact
): DiagnosticReportModel {
  const mostRecentComment = __getMostRecentServiceForTag(
    contact.services!,
    "labresult-note-for-patient"
  );

  return {
    id: contact.id!,
    viewedOn: getViewedOnTstampByResource(contact?.services?.[0]),
    type: DocumentType.LAB_RESULT,
    issued: contact.closingDate!,
    hcp: { id: mostRecentComment?.responsible! },
    //subject: patient,
    result: contact
      .services!.filter(
        (s) => !s.tags?.some((tag) => tag.code === "labresult-note-for-patient")
      )
      .map(icureServiceToObservationModel),
    performer: { id: contact.responsible! },
    title: "Résultats d'analyyyyyse",
    // procedure: services[0].label,
    comment: mostRecentComment?.content?.note.stringValue,
    conclusion: mostRecentComment?.content?.note.stringValue,

    // specialty: Specialty.CARDIOLOGY,
  };
}

// TODO: move this to utils
export function getImageURI(b: ArrayBuffer | undefined) {
  if (!b) return undefined;
  const uint8Array = new Uint8Array(b);
  const binaryData = uint8Array.reduce(
    (data, byte) => data + String.fromCharCode(byte),
    ""
  );

  const img = btoa(binaryData);
  return "data:image/jpg;base64," + img;
}

export function iCurePatientToPatientModel(
  icurePatient: Patient
): PatientModel {
  return {
    id: icurePatient.ssin ?? icurePatient.id!,
    birthdate: icurePatient.dateOfBirth,
    firstName: icurePatient.firstName ?? "",
    lastName: icurePatient.lastName ?? "",
    picture: getImageURI(icurePatient.picture), // 'data:image/jpg;base64,' + icurePatient.picture //getImageURI(icurePatient.picture),
  };
}

export function iCureVaccinationServiceToVaccinationModel(
  service: Service
): VaccinationModel {
  return {
    id: service.id!,
    viewedOn: getViewedOnTstampByResource(service),
    name: service.content?.descr_fr?.stringValue,
    indications: service.codes
      ?.filter((code) => code.type === "CD-VACCINEINDICATION")
      ?.map((code) => code.code)
      .join(", "),
    date: service.openingDate,
  };
}

export function iCureLocationToOrgModel(location: MedicalLocation): OrgModel {
  return {
    id: location.id!,
    name: location.name,
    address: icureAddressToAddressModel(location.address!),
  };
}

export function icureUserHcpToHCPModel(hcp: UserAndHealthcareParty): HCPModel {
  return {
    id: hcp.healthcareParty?.id!, // hcpMapping.new(hcp as any, DataSource.ICURE),
    firstName: hcp.healthcareParty?.firstName,
    lastName: hcp.healthcareParty?.lastName,

    name: `${hcp.healthcareParty?.firstName} ${hcp.healthcareParty?.lastName}`,
    specialty: hcp.healthcareParty?.speciality,
    // picture: 'data:image/jpg;base64,' +((hcp.healthcareParty?.picture as unknown) as string),//getImageURI(hcp.healthcareParty?.picture),
    presentation: hcp.healthcareParty?.descr?.fr,
    address: hcp.healthcareParty?.addresses?.map(icureAddressToAddressModel),

    telecom: hcp.healthcareParty?.addresses
      ?.filter((a) => a.telecoms)
      .flatMap((a) => a.telecoms!)
      .map(icureTelecomToContactPoint),
  };
}

export function icureHcpToHCPModel(hcp: HealthcareParty): HCPModel {
  return {
    id: hcp.id!, // hcpMapping.new(hcp as any, DataSource.ICURE),
    firstName: hcp.firstName,
    lastName: hcp.lastName,
    parentId: hcp.parentId,
    //  'data:image/jpg;base64,' +((hcp?.picture as unknown) as string),

    name: `${hcp.firstName} ${hcp.lastName}`,
    specialty: hcp.speciality,
    presentation: hcp.descr?.fr,
    address: hcp.addresses?.map(icureAddressToAddressModel),

    telecom: hcp.addresses
      ?.filter((a) => a.telecoms)
      .flatMap((a) => a.telecoms!)
      .map(icureTelecomToContactPoint),
  };
}

function icureTelecomToContactPoint(telecom: Telecom): ContactPoint {
  let use = ContactPointUse.WORK;
  switch (telecom.telecomType) {
    case Telecom.TelecomTypeEnum.Mobile:
      use = ContactPointUse.MOBILE;
      break;
    case Telecom.TelecomTypeEnum.Email:
      use = ContactPointUse.EMAIL;
      break;
  }
  return {
    value: telecom.telecomNumber,
    use,
  };
}

export function getViewedOnTstampByResource(resource: Service | CalendarItem | undefined): number {
  return Number(((resource as any)?.documentService?.tags ?? resource?.tags ?? []).find((it:any) => it.type === VIEWED_ON_TAG_TYPE)?.code ?? 0);
}