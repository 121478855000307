import "../../i18n/i18n";
import styled, { keyframes } from "styled-components";
import { Breakpoint, Size } from "../../ui/tokens";

const AppContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  box-sizing: border-box;
  justify-content: stretch;

  @media (min-width: ${Breakpoint.MOBILE}px) {
    width: 100%;
    justify-content: center;
    padding: ${Size.L}px;
  }
`;

export default AppContainer;
