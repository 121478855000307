import { EzoResource } from "./EzoResource";

/**
 * TODO: change name and interface to match FHIR Medication dispense resource:
 * https://hl7.org/fhir/medicationdispense.html
 */
export interface MedicationModel extends EzoResource {
  patientId: string;
  name?: string;
  instruction?: string;
  administrationMode?: string;
  isChronic?: boolean;
  regimen: RegimenModel[];
  posology?: string;
  commentForDelivery?: string;
  intendedcds: Array<{ type?: string; code?: string }>;
  frequency?: MedicationAdministrationRate;
  periodicity?: string;
  endMoment?: number;
  atc?: string;
  cnk?: string;
  vmp?: string;
  contactId?: string;

  updated?: number;
}

export interface RegimenModel {
  medicationId?: string;
  prescriber?: string;
  administratedQuantity?: number;
  dayPeriod?: string; // values from https://www.ehealth.fgov.be/standards/kmehr/en/tables/dayperiod
}


export enum MedicationAdministrationRate {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

interface Frequency {
  value: string;
  periodType: PeriodEnum;
  periodicityCode: string;
}

enum PeriodEnum {
  _TYPE = "CD-TIMEUNIT",
  DAY = "d",
  WEEK = "wk",
  MONTH = "mo",
  YEAR = "a",
}

const frequencies: Frequency[] = [
  { value: "1", periodType: PeriodEnum.DAY, periodicityCode: "D" },
  { value: "2", periodType: PeriodEnum.DAY, periodicityCode: "DT" },
  { value: "3", periodType: PeriodEnum.DAY, periodicityCode: "DD" },
  { value: "4", periodType: PeriodEnum.DAY, periodicityCode: "DV" },
  { value: "5", periodType: PeriodEnum.DAY, periodicityCode: "DQ" },
  { value: "6", periodType: PeriodEnum.DAY, periodicityCode: "DZ" },
  { value: "7", periodType: PeriodEnum.DAY, periodicityCode: "W" },
  { value: "8", periodType: PeriodEnum.DAY, periodicityCode: "DA" },
  { value: "9", periodType: PeriodEnum.DAY, periodicityCode: "DN" },
  { value: "10", periodType: PeriodEnum.DAY, periodicityCode: "DX" },
  { value: "11", periodType: PeriodEnum.DAY, periodicityCode: "DE" },
  { value: "12", periodType: PeriodEnum.DAY, periodicityCode: "DW" },
  { value: "1", periodType: PeriodEnum.WEEK, periodicityCode: "W" },
  { value: "2", periodType: PeriodEnum.WEEK, periodicityCode: "WT" },
  { value: "3", periodType: PeriodEnum.WEEK, periodicityCode: "WD" },
  { value: "4", periodType: PeriodEnum.WEEK, periodicityCode: "WV" },
  { value: "5", periodType: PeriodEnum.WEEK, periodicityCode: "WQ" },
  { value: "6", periodType: PeriodEnum.WEEK, periodicityCode: "WZ" },
  { value: "7", periodType: PeriodEnum.WEEK, periodicityCode: "WS" },
  { value: "8", periodType: PeriodEnum.WEEK, periodicityCode: "WA" },
  { value: "9", periodType: PeriodEnum.WEEK, periodicityCode: "WN" },
  { value: "10", periodType: PeriodEnum.WEEK, periodicityCode: "WX" },
  { value: "11", periodType: PeriodEnum.WEEK, periodicityCode: "WE" },
  { value: "12", periodType: PeriodEnum.WEEK, periodicityCode: "WW" },
  { value: "24", periodType: PeriodEnum.WEEK, periodicityCode: "WP" },
  { value: "1", periodType: PeriodEnum.MONTH, periodicityCode: "M" },
  { value: "2", periodType: PeriodEnum.MONTH, periodicityCode: "MT" },
  { value: "3", periodType: PeriodEnum.MONTH, periodicityCode: "MD" },
  { value: "4", periodType: PeriodEnum.MONTH, periodicityCode: "MV" },
  { value: "5", periodType: PeriodEnum.MONTH, periodicityCode: "MQ" },
  { value: "6", periodType: PeriodEnum.MONTH, periodicityCode: "MZ2" },
  { value: "7", periodType: PeriodEnum.MONTH, periodicityCode: "MS" },
  { value: "8", periodType: PeriodEnum.MONTH, periodicityCode: "MA" },
  { value: "9", periodType: PeriodEnum.MONTH, periodicityCode: "MN" },
  { value: "10", periodType: PeriodEnum.MONTH, periodicityCode: "MX" },
  { value: "11", periodType: PeriodEnum.MONTH, periodicityCode: "ME" },
  { value: "12", periodType: PeriodEnum.MONTH, periodicityCode: "J" },
  { value: "18", periodType: PeriodEnum.MONTH, periodicityCode: "MC" },
  { value: "0.5", periodType: PeriodEnum.YEAR, periodicityCode: "JH2" },
  { value: "1", periodType: PeriodEnum.YEAR, periodicityCode: "J" },
  { value: "2", periodType: PeriodEnum.YEAR, periodicityCode: "JT" },
  { value: "3", periodType: PeriodEnum.YEAR, periodicityCode: "JD" },
  { value: "4", periodType: PeriodEnum.YEAR, periodicityCode: "JV" },
  { value: "5", periodType: PeriodEnum.YEAR, periodicityCode: "JQ" },
  { value: "6", periodType: PeriodEnum.YEAR, periodicityCode: "JZ" },
];
