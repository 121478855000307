import { Tint, Tints } from "../ui/tokens";

export default function useTint(id: string | undefined, light = false) {
  if (!id) return Tints.NEUTRAL;
  const source = light ? _lightTintsById : _tintsById;
  if (_tintsById[id]) {
    return source[id];
  } else {
    ++index;
    if (index >= tints.length) index = 0;
    const l = lightTints[index];
    const d = tints[index];
    _tintsById[id] = d;
    _lightTintsById[id] = l;
    return light ? l : d;
  }
}

const _tintsById: { [id: string]: Tint } = {};
const _lightTintsById: { [id: string]: Tint } = {};

const tints = [Tints.PROVIDER, Tints.GREEN];
const lightTints = [Tints.PROVIDER, Tints.GREEN];
let index = -1;
