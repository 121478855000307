import { ReactNode, useCallback, useState } from "react";

import { ModalContext } from "./ModalContext";

type ModalProviderProps = {
  children: React.ReactNode;
};
const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [active, setActive] = useState(false);
  const [content, setContent] = useState<ReactNode>();

  const toggle = useCallback((component: ReactNode) => {
    setContent(component);
    setActive(!!component);
  }, []);

  return (
    <ModalContext.Provider value={{ active, content, toggle }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
