export enum ResourceType {
  APPOINTMENTS = "appointment",
  MEDICATIONS = "medication",
  DOCUMENTS = "document",
  PROVIDERS = "provider",
  VACCINATIONS = "vaccination",
  PRESCRIPTIONS = "prescription",
  LAB_RESULTS = "labResult",
  MEDIA = "media",
}

export enum IcureResourceType {
  CALENDARITEM = "CalendarItem",
  CONTACT = "Contact",
}
