import { gql } from "@apollo/client";

export const GET_STAKEHOLDERS = gql`
  query GetStakeholdersByDefinitionIds(
    $stakeholder_definition_ids: [String!]!
  ) {
    stakeholdersByDefinitionIds(
      stakeholder_definition_ids: $stakeholder_definition_ids
    ) {
      stakeholders {
        id
        label {
          en
        }
        release_id
        clinical_app_role
        definition_id
      }
    }
  }
`;
