import {AppointmentTypeAndPlace, Place} from "@icure/api";

export const getUniquePlacesFromAppointmentTypes = (
  appointmentTypes: AppointmentTypeAndPlace[] | undefined
) => {
  // if (appointmentTypes.find(type => type.placeId && type.address)){
  if (!appointmentTypes) return [];
  return appointmentTypes
    .map(getPlaceFromAppointmentType)
    .filter(uniquePlacesOnly);
  // }
};

const uniquePlacesOnly = (place: Place, index: number, self: Place[]) =>
  self.findIndex((p) => p.id === place.id) === index;

const getPlaceFromAppointmentType = (type: AppointmentTypeAndPlace) => {
  return new Place({
    id: type.placeId,
    address: type.address,
    name: type.address?.descr,
  });
};

export const groupBy = (list: [], key: string) => {
  return list.reduce(function (rv, x) {
    ((rv[x[key]] = rv[x[key]] || []) as []).push(x);
    return rv;
  }, {});
};

export const triggerFileDownload = (
    fileRawContent: ArrayBuffer | string,
    mimeType: string,
    downloadFileName: string,
    appendTarget: HTMLElement | undefined = undefined,
) => {
  if (!fileRawContent || !('' + mimeType).trim() || !('' + downloadFileName).trim()) return;

  const urlObject = window && window.URL && window.URL.createObjectURL(new Blob([fileRawContent], { type: mimeType }));
  const linkObject = Object.assign(document.createElement('a'), {
    style: 'display: none',
    href: urlObject,
    download: downloadFileName,
  });

  try {
    (appendTarget || document.body).appendChild(linkObject);
    linkObject.click();
    window.URL.revokeObjectURL(urlObject);
  } catch (e) {
    window.open(('' + urlObject).trim());
  }
}