import Section from "../../ui/components/Layout/Section";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import RadioButtonGroup from "../../ui/components/RadioButtonGroup";
import DisplayOptions, {
  MedicationDisplayModes,
} from "../navigation/DisplayOptions";
import useDisplayOption from "../../hooks/useDisplayOption";
import { useTranslation } from "react-i18next";

const MedicationOptions = () => {
  const [grouping, setGrouping] = useDisplayOption("medication.grouping");
  const { t } = useTranslation();
  return (
    <DisplayOptions
      label={t("settings.display") as string}
      options={[
        {
          label: "Grouper par",
          value: grouping,
          action: setGrouping,
          options: Object.values(MedicationDisplayModes).map((mode) => {
            return { id: mode, label: t(`treatment.${mode}`), value: mode };
          }),
        },
      ]}
    />
  );
};

export default MedicationOptions;
