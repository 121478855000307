import styled from "styled-components";
import Section from "./Layout/Section";
import {
  Breakpoint,
  Color,
  Elevation,
  ResourceColors,
  Size,
  Tint,
  Tints,
} from "../tokens";
import Avatar from "./Avatar/Avatar";
import Icon from "./Icon";
import Badge from "./Badge";
import { Fragment, ReactNode } from "react";
import Spacing from "./Layout/Spacing";
import Typography from "./Typography/Typography";
import { Icons } from "../assets";
import { useTranslation } from "react-i18next";
import SectionHeading from "./Layout/SectionHeading";
import { Fragments } from "../../features/home/HomeSection";
import { useNavigate } from "react-router-dom";
import { ResourceType } from "../../types/ResourceType";

const Container = styled.div`
  @media (min-width: ${Breakpoint.MOBILE}px) {
    display: none;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Size.S}px;
  flex: 1;
  flex-shrink: 1;

  margin: 0 -${Size.XS}px;

  @media (min-width: ${Breakpoint.MOBILE}px) {
    display: block;
    width: 200px;
  }
`;

const GridItemContainer = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  border-radius: ${Size.M}px;
`;

/*
 ${(props)=>props.tint.light};
 */

const GridItemLayout = styled.div`
  flex: 1;
  flex-direction: column;
  padding: ${Size.M}px;
  align-items: flex-start;
  justify-content: flex-start;

  overflow: hidden;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export interface MenuItem {
  color?: string;
  title: string;
  icon: any;
  subtitle?: string;
  link?: string;
  count?: number;
}

interface GridItemProps {
  icon: string;
  title?: string;
  subtitle?: string;
  color?: string;
  large?: boolean;
  link?: string;

  height?: "tall" | "medium" | "short";
  count?: number;
  onPress?: () => void;
  children?: ReactNode;
  data?: [];
}

interface GridProps {
  items: MenuItem[];
}

const GridItem = ({
  icon,
  title,
  subtitle,
  color = Color.NEUTRAL_800,
  link,
  count,
  onPress,
}: GridItemProps) => {
  const navigate = useNavigate();
  return (
    <GridItemContainer onClick={() => navigate(link!)} color={color}>
      <GridItemLayout onClick={onPress}>
        <Row>
          <Icon asset={icon} color={Color.WHITE} />
          {!!(count && count > 0) && <Badge label={count.toString()} />}
        </Row>
        <Spacing small />

        <Typography.h3 color={Color.WHITE}>{title}</Typography.h3>

        <Row>
          <Typography.small color={Color.WHITE}>{subtitle}</Typography.small>
        </Row>
      </GridItemLayout>
    </GridItemContainer>
  );
};

const MainNavigation = ({ items }: { items: MenuItem[] }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <SectionHeading title={t("home.data") as string} />
      <GridContainer>
        {items.map((item, index) => (
          <GridItem key={index} {...item} />
        ))}
      </GridContainer>
    </Container>
  );
};

export default MainNavigation;
