import { ReactNode, useEffect, useState } from "react";
import { getToken } from "../../connectors/ehealth/api/iam";
import { useNavigate, useSearchParams } from "react-router-dom";
//import { useLoginMutation } from "../../api/authentication";

import { DotLottiePlayer, Controls } from "@dotlottie/react-player";
import "@dotlottie/react-player/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useModalSheet } from "../../ui/components/ModalSheet/useModalSheet";
import { TokenProvider, tokenAdded } from "../login/AuthSlice";
import { useConnectMutation } from "../../api/authentication";
import AuthRedirect from "./AuthRedirect";

export default function AuthCallback() {
  const [params] = useSearchParams();
  // const [login] = useLoginMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setModal = useModalSheet();

  /*
  
    useEffect(() => {
      if (connectorError){
        console.log("ERROR",connectorError)
        //setModal(screensByError[`${connectorError.data}.${connectorError.error.name}`])
        setModal(screensByConnector[`${connectorError.data}`])
      } else {
       // setModal(null);
      }
    }, [connectorError]);
  
  
    const screensByError:{[errorName:string]:ReactNode} = {["MedispringConnector.MissingKeyError"]  : <SetupFlow/>}
  */

    const [connect] = useConnectMutation()

  const retrieveTokens = async (code: string) => {
    console.log("retrieving tokens");
    const authToken = {...await getToken(code),provider:TokenProvider.BE_FAS};
    
    /*const authToken = {
      token:
        "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJCcC1xTHJEaExvODc0ZXJRU09QbGtGUklqTm9jRjN3UHkwcl9oWTdzYWswIn0.eyJleHAiOjE3MDkyMjEyMjAsImlhdCI6MTcwOTIyMDkyMCwiYXV0aF90aW1lIjoxNzA5MjE5NDczLCJqdGkiOiI3ZWZiMDYyZS0yMzY4LTRmNTYtOWM0ZC1kMWIwN2RlYzZlMGEiLCJpc3MiOiJodHRwczovL2FwaS1hY3B0LmVoZWFsdGguZmdvdi5iZS9hdXRoL3JlYWxtcy9oZWFsdGhjYXJlIiwic3ViIjoiYmMzZjUzMDEtZGViZC00NTFhLWIxZjItZDM2NGQzMTkzZTljIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoibWVkaXNwcmluZy1wYXRpZW50YXBwIiwibm9uY2UiOiIzMTI2Iiwic2Vzc2lvbl9zdGF0ZSI6ImViZjc5OTgzLTEwMjEtNDJiOC1hNjU1LTA2NzlhN2Q5ZmFmMiIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL2FjYy1wYXRpZW50Lm1lZGlzcHJpbmcuYmUiXSwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBzc2luIiwic2lkIjoiZWJmNzk5ODMtMTAyMS00MmI4LWE2NTUtMDY3OWE3ZDlmYWYyIiwic3NpbiI6Ijc5MDcwMjM0OTM4IiwibmFtZSI6IkplcsO0bWUgQ29yZGlleiIsInByZWZlcnJlZF91c2VybmFtZSI6Ijc5MDcwMjM0OTM4IiwibG9jYWxlIjoiZnIiLCJnaXZlbl9uYW1lIjoiSmVyw7RtZSIsImZhbWlseV9uYW1lIjoiQ29yZGlleiIsInVzZXJQcm9maWxlIjp7Imxhc3ROYW1lIjoiQ29yZGlleiIsImZpcnN0TmFtZSI6IkplcsO0bWUiLCJzc2luIjoiNzkwNzAyMzQ5MzgifX0.U4m8IPS38h3Pzg8PEloSLiNNJZ86inpBxyEvOsKK9AtL3Rlubssaxb9NI_AFmwgMEeh_VYgGiW3X49vr6Rtgl8zM9n3tZMEmWFhIYtIjMDwkm7YZKNNWZmxlQWiyfmAD_KXcgfapA1oAGUxgLbDCNxNlcCkLUaCggDH8th_d2HeRrYvaYTizJ8-w24_sg8-CJ5fRtbzDTLoV0TdWMRQZuR9NLhNbRk-RGaoYbzY-TOZcFYJmD13YoGHd69Tf2QfZqvYeuOpVNn7AmrVdzvbbxqo4pzZq9Zss7i_i-MrC6iY3CxOG0vjh2X_ZS6oNR9vxY5-XmJJLVtFp1BQmQWSV6g",
      tokenProvider: TokenProvider.BE_FAS,
    };*/
    if (authToken.token) {
      connect(authToken);
      navigate("/app")
      // login({token:tokenResponse.access_token, tokenProvider:"be.fas"})
    }
  };

  const code = params.get("code");

  useEffect(() => {
    if (code) retrieveTokens(code); 
  }, [code]);
  return (
    <>...</>
  )
}
