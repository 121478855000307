import Typography from "./Typography/Typography";
import { Breakpoint, Color, Size } from "../tokens";
import { ObservationModel } from "../../models/ObservationModel";
import styled from "styled-components";
import Section from "./Layout/Section";
import Icon from "./Icon";
import Assets, { Icons } from "../assets";

interface Props {
  observations: ObservationModel[];
  onSelect: (o: ObservationModel) => void;
}

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: ${Size.S}px 0;
`;
export default function ObservationsTable({ observations, onSelect }: Props) {
  if (!observations || observations.length === 0) return <></>;
  const groupedObservations = groupObservations(observations);

  return (
    <>
      {groupedObservations.map((o, i) => (
        <Section title={o.title} key={i}>
          <StyledTable>
            <tbody>
              {o.observations.map((item) => (
                <ObservationRow
                  key={item.id}
                  observation={item}
                  onPress={() => onSelect(item)}
                />
              ))}
            </tbody>
          </StyledTable>
        </Section>
      ))}
    </>
  );
}

interface GroupedObservations {
  title: string;
  observations: ObservationModel[];
}

function groupObservations(o: ObservationModel[]) {
  const observations = [...o].sort((a, b) => (a.index! > b.index! ? 1 : -1));

  return observations
    .reduce((groups, observation) => {
      if (!observation.value) {
        // When a new group is encountered, we create a new group
        groups.push({ title: observation?.code!, observations: [] });
      } else if (observation.code != "" && observation.code != "untitled") {
        groups[groups.length - 1].observations.push(observation);
      }
      return groups;
    }, [] as GroupedObservations[])
    .filter((g) => g.observations.length > 0);
}

interface rowProps {
  observation: ObservationModel;
  onPress: () => void;
}

const Row = styled.tr`
  border-bottom: 1px solid ${Color.NEUTRAL_200};
  cursor: pointer;
  &:nth-child(even) {
    /* background-color: ${Color.NEUTRAL_100}; */
  }
`;

const Cell = styled.td<{
  expand?: boolean;
  mobile?: boolean;
  fixedWidth?: number;
  span?: number;
}>`
  padding: ${Size.S}px ${Size.S}px;
  width: ${(props) => (props.fixedWidth ? `${props.fixedWidth}px` : "auto")};
  text-overflow: ellipsis;
  line-break: none;

  @media (max-width: ${Breakpoint.MOBILE}px) {
    display: ${(props) =>
      props.mobile || props.expand ? "table-cell" : "none"};
  }
`;

const getColor = (observation: ObservationModel) => {
  return observation.interpretation === "abnormal"
    ? Color.DESTRUCTIVE
    : Color.NEUTRAL_800;
};

function ObservationRow({ observation, onPress }: rowProps) {
  return (
    <Row onClick={onPress}>
      <Cell expand>
        <Typography.body>{observation.code}</Typography.body>
        <Typography.small style={{ color: getColor(observation) }}>
          ({observation.referenceRange?.low} -{" "}
          {observation.referenceRange?.high})
        </Typography.small>
      </Cell>
      <Cell mobile fixedWidth={80}>
        <Typography.strong color={getColor(observation)} align="center">
          {observation.value?.valueQuantity?.value}
        </Typography.strong>
        <Typography.small color={getColor(observation)} align="center">
          {observation.value?.valueQuantity?.unit}
        </Typography.small>
      </Cell>

      <Cell fixedWidth={100}>
        <Typography.small style={{ color: getColor(observation) }}>
          ({observation.referenceRange?.low} -{" "}
          {observation.referenceRange?.high})
        </Typography.small>
      </Cell>

      {/*
      <Cell mobile fixedWidth={35}>
        {observation.interpretation === "abnormal" ? <Icon asset={Assets.icons!.warning}/> : <Icon asset={Icons.icons!.info}/>}
      </Cell>
      * */}
    </Row>
  );
}
