import ScreenFlow from "../../ui/components/ScreenFlow/ScreenFlow";
import LoginScreen from "./LoginScreen";
import { AppState, selectAppState } from "./AuthSlice";
import { useSelector } from "react-redux";

import styled from "styled-components";

import ConnectionScreen from "./ConnectionScreen";

const ConnectionFlowContainer = styled.div`
  width: 400px;
  position: relative;
  height: 700px;
`;

export enum ConnectionFlowSteps {
  LOGIN = 0,
  CALLBACK = 1,
  TOU = 2
}

const ConnectionFlow = () => {
  const appState = useSelector(selectAppState);

  return (
    <ConnectionFlowContainer>
      <ScreenFlow activeIndex={appState === AppState.AUTHENTICATED ? 1 : 0}>
        <LoginScreen />
        <ConnectionScreen />
      </ScreenFlow>
    </ConnectionFlowContainer>
  );
};

export default ConnectionFlow;
