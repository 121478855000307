import { CSSProperties } from "react";
import { Color, Size } from "../../tokens";
const defaultStyle = {
  width: Size.XL,
  height: Size.XL,
  borderRadius: Size.S,
  backgroundColor: Color.NEUTRAL_200,
  transition: "all 0.3s ease",

  alignContent: "center",
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
  justifySelf: "center",

  /* shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: .1,
    shadowRadius: 1,
    elevation: 1,
    */
};

export default {
  default: defaultStyle,
  small: {
    ...defaultStyle,
    width: Size.L,
    height: Size.L,
  },
  large: {
    ...defaultStyle,
    width: Size.XL,
    height: Size.XL,
  },
  xxl: {
    ...defaultStyle,
    width: Size.XL * 2,
    height: Size.XL * 2,
  },
  label: {
    color: Color.NEUTRAL_600,
    fontSize: 11,
    textAlign: "center",
    fontWeight: "800",
    textTransform: "uppercase",
    display: "block",
  } as CSSProperties,
  labelLarge: {
    color: Color.NEUTRAL_600,
    fontSize: 13,
    textAlign: "center",
    fontWeight: "800",
    textTransform: "uppercase",
    display: "block",
  } as CSSProperties,
  icon: {
    resizeMode: "center",
  } as CSSProperties,
};
