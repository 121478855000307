export default function getMedicationShortName(longName: string = "") {
  const regex =
    /[a-zA-Z0-9_àáâãäåæçèéêëìíîïðñòóôõöøùúûüýÿ\s+]+\s\d+(\.\d+)?\smg/;
  const match = longName.match(regex);
  if (match) {
    return match[0];
  } else {
    return longName;
  }
}
