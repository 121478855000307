import { Size } from "../tokens";
import RadioButton from "./RadioButton";

interface Props {
  options: any[];
  selected?: string;
  onChange?: (value: string) => void;
}

const RadioButtonGroup = ({ options, selected, onChange }: Props) => {
  return (
    <fieldset style={{ border: "none", padding: 0, margin: `${Size.S}px 0` }}>
      {options.map((option) => (
        <RadioButton
          key={option.value}
          selected={selected === option.value}
          label={option.label || option}
          onChange={() => onChange && onChange(option.value)}
        />
      ))}
    </fieldset>
  );
};
export default RadioButtonGroup;
