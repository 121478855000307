import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import * as contactsTranslations from "@icure/api/icc-x-api/rsrc/contact.i18n";
import {en} from "./en";
import {fr} from "./fr";
import {nl} from "./nl";

require("dayjs/locale/fr");
require("dayjs/locale/nl");

// eslint-disable-next-line import/no-named-as-default-member
i18next.use(initReactI18next).init(
  {
    fallbackLng: "en",
    compatibilityJSON: "v3",
    react: {
      useSuspense: false,
    },
    resources: {
      en: {
        translation: {
          ...en,
          menu: {
            appointments: "Appointments",
            team: "Care Team",
            medication: "Medication",
            settings: "Settings",
            vaccination: "Vaccination",
            prevention: "Prevention",
            agenda: "Agenda",
            results: "Results",
            prescriptions: "Prescriptions",
            documents: "Documents",
          },
          login: {
            title: "Log in",
            email: "Email Address",
            password: "Password",
            connect: "Log in",
            no_password: "Don't have a password or forgot it?",
            error:"Missing key error. We couldn't import your private key. Click here to import it.",
            connected:"Connected successfully",
          },
          home: {
            more: "more appointments...",
            hello: "Hello",
            team: "Your Care Team",
            prevention_task: "Procedure(s) to be scheduled",
            maj: "Upd",
            appointments: "Upcoming appointments",
            todo: "To do",
            last_docs: "Last documents received",
            add: "Add",
            data: "Your data",
          },
          agenda: {
            scheduled: "Scheduled",
            proposed: "To be scheduled",
            add: "New",
            select_hcp: "Schedule with...",
            select_type: "Select appointment type",
            select_location: "Select location",
            select_slot: "Time slot",
            confirm: "Confirmation",
            confirmation_message_intro:
              "You are about to schedule an appointment with {{hcp}} on {{datetime}}.",
            confirmation_message_details:
              "If these details are correct, press the Confirm button below to schedule your appointment.",
            accept_policy:
              "By clicking Confirm, you consent to the processing of your data as described in the privacy policy.",
            confirm_btn: "Confirm",
            details_title: "Appointment Details",
            confirmed:
              "Your appointment is confirmed. Please find all the details below.",
            cancel: "Cancel this appointment",
            cancel_appointment_email_subject: "Appointment cancellation with {{name}}",
          },
          treatment: {
            acute: "Temporary",
            daily: "Take daily",
            chronic: "Take at a different frequency",
            refill: "Request a prescription",
            refill_request_received:
              "Thank you! Your prescription refill request has been received and will be processed as soon as possible during our opening hours.",
            breakfast: "At wake-up",
            morning: "Around 10 AM",
            lunch: "At noon",
            afternoon: "In the afternoon",
            evening: "In the evening",
            night: "At bedtime",
            prescribed_by: "Prescribed by",
            mixed: "Conflicting dosages",
            other: "As needed",
            chronological: "Day view",
            logical: "Schematic view",
          },
          refill: {
            confirmation_title: "Review & confirm",
            confirmation_message:
              "You're about to request a refill for {{list}}. Are you sure?",
            confirm_btn: "Confirm",
            cancel_btn: "Cancel",
          },
          team: {
            presentation: "Presentation",
            appointments: "Appointments",
            refill: "Prescriptions",
            messages: "Messages",
            providers: "Providers",
            locations: "Organizations",
            add: "Add",
          },
          documents: {
            report: "Report",

            result:"Result",
            reports: "Reports",
            results: "Results",
            prescriptions:"Prescriptions"
          },
          settings: {
            display:"Display",
            sort:"Sort by",
            examDate:"Examination date",
            shareDate:"Share date",
            language: "Interface Language",
            logout: "Sign out",
            fr: "Français",
            en: "English",
            nl: "Nederlands",
            signout: "Sign Out",
            prefs: "Preferences",
            security: "Security",
            password: "Password",
            change: "Change",
            pki: "Security Key",
            download: "Download",
            pki_instructions:
              "To ensure the security of your data, it is encrypted in such a way that only your care team and yourself can access it from devices that have the decryption key stored. If you wish to access your medical record from another device, you will need to import the private key above. We recommend storing it in a safe place.",
          },
          downloadPrivateKey: "Download your access key",
          contacts: contactsTranslations.default.en,
          specialties: en["hcp-form"].SPECIALITIES,
          helpers: {
            on: "on",
            until: "→",
          },
        },
      },
      fr: {
        translation: {
          ...fr,
          menu: {
            appointments: "Agenda",
            team: "Soignants",
            medication: "Médicaments",
            settings: "Réglages",
            vaccination: "Vaccination",
            prevention: "Prévention",
            results: "Résultats",
            prescriptions: "Prescriptions",
            documents: "Documents",
            agenda: "Activités",
            home: "Accueil",
          },
          login: {
            title: "Connectez vous",
            email: "Adresse email",
            password: "Mot de passe",
            connect: "Se connecter",
            no_password:
              "Vous n'avez pas encore de mot de passe ou vous l'avez oublié ?",

            error:"Clé de déchiffrement manquante. Votre clé privée n'a pas pu être importée. Cliquez ici pour l'importer",
            uninitialized:"Connexion en cours...",
            connected:"Connexion réussie"
          },
          home: {
            more: "autres rendez-vous...",
            hello: "Bonjour",
            team: "Vos soignants",
            prevention_task: "tâche(s) à prévoir",
            maj: "Màj",
            appointments: "Prochains rendez-vous",
            todo: "A faire",
            last_docs: "Derniers documents reçus",
            add: "Ajouter",
            data: "Votre dossier",
          },
          agenda: {
            scheduled: "Planifiés",
            proposed: "A organiser",
            add: "Nouveau",
            select_hcp: "Prendre rendez-vous avec...",
            select_type: "Choisir un type de rendez-vous",
            select_location: "Choisir un lieu de consultation",
            select_slot: "Plage horaire",
            confirm: "Vérification",
            confirmation_message_intro:
              "Vous êtes sur le point de prendre rendez-vous avec {{hcp}}, le  {{datetime}}. ",
            confirmation_message_details:
              "Si ces informations sont correctes, appuyez sur le bouton Confirmer ci-dessous pour valider votre rendez-vous.",
            accept_policy:
              "En cliquant sur confirmer, vous consentez à ce que vos données soient traitées conformément à ce qui est décrit dans la politique de confidentialité.",
            confirm_btn: "Confirmer",
            details_title: "Détails de votre rendez-vous",
            confirmed:
              "Votre rendez-vous est confirmé. Veuillez retrouver tous les détails ci-dessous.",
            cancel: "Annuler ce rendez-vous",
            cancel_appointment_email_subject: "Annulation de votre rendez-vous avec {{name}}",
          },
          treatment: {
            acute: "Temporaire",
            daily: "A prendre tous les jours",
            chronic: "A prendre à une autre fréquence",
            refill: "Demander une ordonnance",
            refill_request_received:
              "Merci ! Votre demande d'ordonnance a bien été transmise et sera traitée dès que possible, endéns nos heures d'ouverture.",
            breakfast: "Au réveil",
            morning: "Vers 10h",
            lunch: "A midi",
            afternoon: "L'après-midi",
            evening: "Le soir",
            night: "Au coucher",
            prescribed_by: "Prescrit par",
            mixed: "Conflits de posologies",
            other: "Autre(s)",
            chronological: "Vue par jour",
            logical: "Vue schématique",
          },
          refill: {
            confirmation_title: "Review & confirm",
            confirmation_message:
              "You're about to ask for a refill for {{list}}, are you sure?",
            confirm_btn: "Confirmer",
            cancel_btn: "Confirm",
          },
          team: {
            presentation: "Présentation",
            appointments: "Rendez-vous",
            refill: "Ordonnances",
            messages: "Messages",
            providers: "Médecins",
            locations: "Organisations",
            add: "Ajouter",
          },
          documents: {
            report: "Rapport",
            result:"Resultat d'analyses",
            prescriptions: "Prescriptions",
            reports: "Rapports",
            results: "Résultats",
          },
          settings: {
            display:"Afficher",
            sort:"Trier par",
            examDate:"Date d'examen",
            shareDate:"Date de partage",
            language: "Langue de l'interface",
            logout: "Déconnecter",
            fr: "Français",
            en: "English",
            nl: "Nederlands",
            signout: "Se déconnecter",
            prefs: "Préférences",
            security: "Sécurité",
            password: "Mot de passe",
            change: "Modifier",
            pki: "Clé de sécurité",
            download: "Télécharger",
            pki_instructions: "Pour garantir la sécurité de vos données, celles-ci sont chiffrées de telle sorte que seuls vos soignants et vous-même puissiez y accéder, depuis des appareils sur lesquels est enregistré la clef de déchiffrement. Si vous souhaitez accéder à votre dossier médical depuis un autre appareil, vous aurez besoin d'y importer la clef privée ci-dessus. Nous vous conseillons de stocker celle-ci en lieu sûr",
            downloadPrivateKey: "Télécharger votre clef d'accès"
          },
          contacts: contactsTranslations.default.fr,
          specialties: fr["hcp-form"].SPECIALITIES,
          helpers: {
            on: "le",
            until: "→",
          },
        },
      },
      nl: {
        translation: {
          ...nl,
          menu: {
            appointments: "Afspraken",
            team: "Zorgteam",
            medication: "Medicatie",
            settings: "Instellingen",
            vaccination: "Vaccinatie",
            prevention: "Preventie",
            results: "Resultaten",
            prescriptions: "Voorschriften",
            documents: "Dokumenten",
            agenda: "Agenda",
          },
          login: {
            title: "Inloggen",
            email: "E-mailadres",
            password: "Wachtwoord",
            connect: "Inloggen",
            no_password: "Heeft u nog geen wachtwoord of bent u het vergeten?",
            connected:"Connected successfully",
          },
          home: {
            more: "meer afspraken...",
            hello: "Hallo",
            team: "Uw Zorgteam",
            prevention_task: "Te plannen procedure(s)",
            maj: "Upd.",
            add: "Nieuw",
            todo: "To-do's",
            appointments: "Afspraken",
            last_docs: "Laatst ontvangen documenten",
            data: "Uw gegevens",
          },
          agenda: {
            scheduled: "Gepland",
            proposed: "Te plannen",
            add: "Nieuw",
            select_hcp: "Afspraak maken met...",
            select_type: "Selecteer afspraaktype",
            select_location: "Selecteer locatie",
            select_slot: "Tijdsperiode",
            confirm: "Bevestiging",
            confirmation_message_intro:
              "U staat op het punt een afspraak te maken met {{hcp}} op {{datetime}}.",
            confirmation_message_details:
              "Als deze gegevens juist zijn, drukt u op de Bevestig-knop hieronder om uw afspraak te plannen.",
            accept_policy:
              "Door op Bevestigen te klikken, stemt u in met de verwerking van uw gegevens zoals beschreven in het privacybeleid.",
            confirm_btn: "Bevestigen",
            details_title: "Afspraakgegevens",
            confirmed:
              "Uw afspraak is bevestigd. Hieronder vindt u alle details.",
            cancel: "Deze afspraak annuleren",
            cancel_appointment_email_subject: "Afspraakannulering met {{name}}",
          },
          treatment: {
            acute: "Tijdelijk",
            daily: "Dagelijks innemen",
            chronic: "Op een andere frequentie innemen",
            refill: "Herhaalrecept aanvragen",
            refill_request_received:
              "Bedankt! Uw aanvraag voor een herhaalrecept is ontvangen en wordt zo snel mogelijk verwerkt tijdens onze openingstijden.",
            breakfast: "Bij het ontwaken",
            morning: "Rond 10:00 uur",
            lunch: "Bij de lunch",
            afternoon: "In de middag",
            evening: "In de avond",
            night: "Bij het slapengaan",
            prescribed_by: "Voorgeschreven door",
            mixed: "Tegenstrijdige doseringen",
            other: "Indien nodig",
            chronological: "Dagweergave",
            logical: "Schematische weergave",
          },
          refill: {
            confirmation_title: "Controleren en bevestigen",
            confirmation_message:
              "U staat op het punt een herhaalrecept aan te vragen voor {{list}}. Weet u het zeker?",
            confirm_btn: "Bevestigen",
            cancel_btn: "Annuleren",
          },
          team: {
            presentation: "Presentatie",
            appointments: "Afspraken",
            refill: "Voorschriften",
            messages: "Berichten",
            providers: "Zorgverleners",
            locations: "Organisaties",
            add: "Toevoegen",
          },
          documents: {
            report: "Rapport",
            result:"Resultaat",
            prescriptions: "Voorschriften",
            reports: "Rapporten",
            results: "Resultaten",
          },
          settings: {
            

            display:"Weergeven",
            sort:"Ordenen op",
            examDate:"Datum van onderzoek",
            shareDate:"Datum van delen",
            language: "Interfacetaal",
            logout: "Uitloggen",
            fr: "Français",
            en: "English",
            nl: "Nederlands",
            signout: "Uitloggen",
            prefs: "Voorkeuren",
            security: "Beveiliging",
            password: "Wachtwoord",
            change: "Wijzigen",
            pki: "Beveiligingssleutel",
            download: "Downloaden",
            pki_instructions:
              "Om de beveiliging van uw gegevens te waarborgen, worden deze versleuteld opgeslagen. Alleen uw zorgteam en uzelf hebben toegang tot deze gegevens vanaf apparaten waarop de decoderingssleutel is opgeslagen. Als u toegang wilt tot uw medische gegevens vanaf een ander apparaat, moet u de bovenstaande privésleutel importeren. Wij raden u aan deze op een veilige plaats op te slaan.",
          },
          downloadPrivateKey: "Download uw toegangssleutel",
          contacts: contactsTranslations.default.nl,
          specialties: nl["hcp-form"].SPECIALITIES,
          helpers: {
            on: "op",
            until: "→",
          },
        },
      },
      interpolation: {},
    },
  },
  () => {}
);
