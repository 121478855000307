import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function AuthRedirect() {
  const n = useNavigate();
  const p = useSearchParams();

  useEffect(() => {
    n("/app?" + p.join("&"));
  }, []);

  return <></>;
}
