import { Route, Routes, useSearchParams } from "react-router-dom";
import Root from "./Root";
import AppContainer from "./AppContainer";
import MainContent from "./MainContent";
import Navigation from "./Navigation";
import useURLQuery from "../../hooks/useURLQuery";
import { Provider, useSelector } from "react-redux";
import store, { RootState } from "../../store";
import { selectAllPatients, selectIsPatientLogged } from "../login/AuthSlice";
import HomeSection from "../home/HomeSection";
import AllergiesListScreen from "../allergies/AllergiesListScreen";
import ScreenOptions from "./ScreenOptions";
import AgendaOptions from "../agenda/AgendaOptions";
import MedicationOptions from "../medication/MedicationOptions";
import DocumentsDisplayOptions from "../documents/DocumentsDisplayOptions";
import Settings from "../settings/Settings";
import AuthCallback from "./AuthCallback";
import { useEffect, useState } from "react";
import { selectConnections } from "../login/AuthSlice";
import { useModalSheet } from "../../ui/components/ModalSheet/useModalSheet";
import NoConnector from "./NoConnector";
import ConnectionFlow from "../login/ConnectionFlow";
import { RECOVERY_KEY } from "../../connectors/medispring/api/constants";

const TIME_OUT = 5 * 60 * 1000;
const PROMPT_BEFORE_IDLE = 15 * 1000;

const Main = () => {
  const isLogged = useSelector(selectIsPatientLogged);
  const noConnectionAvailable = useSelector(
    (state: RootState) => state.auth.noConnectionAvailable
  );
  const setModal = useModalSheet();

  const [params] = useSearchParams();
  const recoveryKey = params.get("key");


  useEffect(() => {
    const usedKeys = JSON.parse(localStorage.getItem("usedKeys") || "[]");
    if (recoveryKey && (!usedKeys || !usedKeys.includes(recoveryKey))) {
      localStorage.setItem(RECOVERY_KEY, recoveryKey);
      localStorage.setItem("usedKeys", JSON.stringify(usedKeys.concat(recoveryKey)));
    }
  }, [recoveryKey]);

  /*
  useEffect(() => {
    if (connections?.length && !isLogged){
      console.log("connections",connections)
      setModal(<SetupFlow/>)
    } else {
      setModal(null);
    }
  }, [connections, isLogged]);
*/

  /*
  const [remaining, setRemaining] = useState(TIME_OUT)

  const dispatch = useDispatch();


  const onIdle = () => {
    if (patients) {
      Alert.close()
      dispatch(logout());
    }

  }

  const onPrompt = () => {
    if (patients) {
      Alert.show("Etes vous encore là?", "Pour des raisons de sécurité, en l'absence d'action de votre part vous serez automatiquement déconnecté(e) dans " + Math.round(PROMPT_BEFORE_IDLE/1000) + " secondes.",
        [{ label: "Poursuivre ma session", type:"primary", action: () => { activate(); Alert.close() } }]
      )
    }

  }

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const { getRemainingTime, activate } = useIdleTimer({ onIdle, onPrompt, timeout: TIME_OUT, promptBeforeIdle: PROMPT_BEFORE_IDLE })

*/

  /*
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "agenda/:resourceId?",
        element: <AgendaFlow/>,
      },
    ],
  },
],{basename: "/app"});
*/

  return (
    <Provider store={store}>
      <Root>
        <AppContainer className="main">
          {isLogged && <Navigation />}
          {isLogged ? (
            <Routes>
              <Route
                path="/:section?/:resourceId?"
                element={
                  <MainContent className="content">
                    <HomeSection />
                  </MainContent>
                }
              />
            </Routes>
          ) : noConnectionAvailable ? (
            <NoConnector />
          ) : (
            <Routes>
              <Route path="auth" element={<AuthCallback />} />
              <Route path="*" element={<ConnectionFlow />} />
            </Routes>
          )}

          {isLogged && (
            <ScreenOptions>
              <Routes>
                <Route path="agenda" element={<AgendaOptions />} />
                <Route path="documents" element={<DocumentsDisplayOptions />} />
                <Route path="medication" element={<MedicationOptions />} />
                <Route path="" element={<Settings />} />
              </Routes>
            </ScreenOptions>
          )}
        </AppContainer>
        {/*patients && (
          <ModalSheet active={action != undefined}>
            {action === "add" && <CreateAppointmentFlow />}
            {action === "modify" && <ModifyAppointmentFlow />}
            {action === "search" && <SearchHCPFlow />}
          </ModalSheet>
        )*/}
        {/*
        <ModalSheet active={showSetup}>
          <SetupFlow/>
        </ModalSheet>
        */}
      </Root>
    </Provider>
  );
};

export default Main;
