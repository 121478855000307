import {DocumentReference} from "./DocumentReference";
import {EzoResource} from "./EzoResource";
import {PatientModel} from "./PatientModel";
import {Service} from "@icure/api";

export interface DocumentResource extends EzoResource {
  type?: DocumentType;
  title?: string;
  comment?: string;
  issued: number;
  commented?: number;
  specialty?: string;
  subject?: PatientModel;
  media?: DocumentReference[];
  documentService?: Service;
}

export enum DocumentType {
  MEDICATION_PRESCRIPTION = "57833-6",
  LAB_RESULT = "11502-2",
  REPORT = "11488-4",
  OTHER = "OTH",
  MESSAGE = "MESSAGE",
}
