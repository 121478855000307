import { useSelector } from "react-redux";
import { RootState } from "../store";
import { toggleOption, setOption } from "../features/navigation/DisplaySlice";
import { AnyAction } from "@reduxjs/toolkit";

type OptionKey = keyof RootState["display"]["options"];

export default function useDisplayOption(
  id: OptionKey
): [{ id: OptionKey; value: boolean | string }, AnyAction] {
  const value = useSelector((state: RootState) => state.display.options[id]);
  //const action = toggleOption(id);
  const action = setOption({ id });

  return [{ id, value }, action];
}
