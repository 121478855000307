
import {
  AUTH_ENDPOINT,
  CLIENT_ID,
  INFO_ENDPOINT,
  REDIRECT_URI,
  TOKEN_ENDPOINT,
} from "./constants";

export const launchIAMConnect = () => {

  console.log("launchIAMConnect");
  console.log(   `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&response_type=code&scope=openid&nonce=${Math.round(
    Math.random() * 10000
  )}&state=login&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`);

// https://api-acpt.ehealth.fgov.be/auth/realms/healthcare/protocol/openid-connect/auth?client_id=medispring-patientapp&response_type=code&scope=openid&nonce=3227&state=login&redirect_uri=https%3A%2F%2Flocalhost%3A4200%2Fapp%2Fauth
  window.open(
    `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&response_type=code&scope=openid&nonce=${Math.round(
      Math.random() * 10000
    )}&state=login&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`,
    "_self"
  );
};

export const getToken = async (code: string) => {
  const data = {
    grant_type: "authorization_code",
    code: code,
    redirect_uri: REDIRECT_URI,
    client_id: CLIENT_ID,
  };

  try {
    const response = await fetch("https://cors-anywhere.herokuapp.com/"+TOKEN_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: new URLSearchParams(data).toString()
    });

    const responseData = await response.json();
   
    const info = await fetch("https://cors-anywhere.herokuapp.com/"+INFO_ENDPOINT, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${responseData.access_token}`,
      },
    });

    const infoData = await info.json();

   
    return {token:responseData.access_token,info:{firstName:infoData.given_name,lastName:infoData.family_name,id:infoData.ssin}};
  } catch (error) {
    console.error(error);
  }
};

/*
Authentication/Authenticate/FederatedAuthentication?state=login&session_state=ee4360ad-4a5f-4663-8f93-74e0c0575013&code=cf7a4c7a-e274-4303-875a-c32c09e1549e.ee4360ad-4a5f-4663-8f93-74e0c0575013.da1d21e6-0f06-491d-a7e3-737e49a41898
*/
