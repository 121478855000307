import React, { CSSProperties } from "react";
import Assets from "../assets";
import { Color, Size } from "../tokens";
import Typography from "./Typography/Typography";

interface EmptyStateProps {
  title?: string;
  image?: string;
}

export default function EmptyState(props: EmptyStateProps) {
  return (
    <div style={styles.state}>
      <img src={props.image || Assets.empty!.default} style={styles.illu} />
      <Typography.body>{props.title || ""}</Typography.body>
    </div>
  );
}

const styles = {
  state: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    justifyContent: "center",
    marginVertical: "50%",
    textAlign: "center",
    justifySelf: "center",
    alignItems: "center",
    justifyItems: "center",
    width: "100%",
  } as CSSProperties,
  illu: {
    width: `${Size.XXL * 2}px`,
    height: `${Size.XXL * 2}px`,
    resizeMode: "center",
    margin: Size.L,
  },
};
