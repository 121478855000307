import styled from "styled-components";
import Typography from "./Typography/Typography";
import { Size, Color } from "../tokens";

const Container = styled.div<{ small?: boolean }>`
  display: flex;
  border-radius: 50vh;
  padding: ${(props) => (props.small ? `6px 6px` : `${Size.XS}px ${Size.S}px`)};
  background-color: ${(props) => props.color ?? Color.NEUTRAL_800};
  color: ${Color.WHITE};
  align-items: center;
  justify-content: center;
  > strong {
    font-size: 12px;
    line-height: 12px;
  }
`;

interface Props {
  label?: string;
  color?: string;
}
const Badge = ({ label, color }: Props) => {
  return (
    <Container color={color} small={!label}>
      <strong>{label}</strong>
    </Container>
  );
};

export default Badge;
