export const fr = {
  home: {
    MEDISPRING: "Medispring",
    DESCRIPTION:
      "Le DMI au coeur de votre pratique médicale, géré par des médecins",
    UPDATE: {
      TITLE: "Mise à jour disponible",
      RESTART: "Redémarrer Medispring.",
      OR: "ou",
      LATER: "Mettre à jour plus tard.",
    },
    ADMIN: "Admin",
  },
  menu: {
    ITEM: {
      DASHBOARD: "Tableau de bord",
      PATIENTS: "Liste des patients",
      MAILING: "Réception courrier / eHealthBox",
      CALENDAR: "Agenda",
      MANAGEMENT: "Outils",
      INVOICING: "Facturation",
      BEOOGO: "Beoogo",
      PENDING_PRESCRIPTIONS: "Ordonnances en attente",
      REPORTING: "Reporting",
    },
  },
  passwordStrength: {
    title: "Pour être sécurisé, votre mot de passe devrait contenir:",
    details: {
      length: "au moins {{expectedLength}} caractères",
      uppercase: "des majuscules",
      lowercase: "des minuscules",
      number: "des chiffres",
      symbol: "des symboles: #@%€* ...",
    },
  },
  login: {
    SUCCESS: "Vous êtes connecté",
    FAILURE: "Echec de connexion",
    WEAK_PASSWORD_CONTINUE: "Continuer",
    WEAK_PASSWORD_TITLE: "Mot de passe trop faible",
    WEAK_PASSWORD_CONTENT:
      "Nous vous conseillons de vous rendre dans votre profil afin de le modifier.",

    LOGIN: "Login",
    USER: "Utilisateur",
    PASSWORD: "Mot de passe",
    PASSWORD_EH_HEALTH: "Mot de passe eHealth",
    CONNECTION: "Se connecter",
    SELECT_GROUP: "Veuillez choisir votre entité :",
    LANGUAGE: "Langue",
    OPTIONS: {
      MORE_OPTIONS: "Plus d'options",
      FHC: "FHC",
      ICURE: "iCure",
      LOGIN_TYPE: "Type de connexion",
      OFFICE: "Cabinet",
      MMF: "Maison médicale forfaitaire",
      GUARDPOST: "PMG",
      "1733": "Flux 1733",
      RLM: "RLM",
    },
    WRONG_ATTEMPTS_EXCEEDED:
      "Vous avez entré le mauvais mot de passe {{attempts}} fois. La prochaine tentative est disponible après {{minutes}} minutes.",
    CUSTOM_HOST: "Serveur spécifique",
    CUSTOM_HOST_URL: "https://",
    CUSTOM_HOST_PORT: "16043",
  },
  datepicker: {
    year: "année",
    month: "mois",
    day: "jour",
  },
  "contact-compatibility": {
    OMISSION_OF_MEDICAL_DATA:
      "Sur demande du patient, certaines données médicales n’ont pas été reprises dans cette transaction : ",
    authorize: "oui.",
    refuse: "non.",
  },
  "calendar-datepicker": {
    DATE_NOT_VALID: "La date que vous avez entrée n'est pas valide.",
  },
  "private-home": {
    AUTO_CLOSE_KNOWN:
      "Le patient {{name}} s'est fermé automatiquement car trop de patients étaient ouverts",
    AUTO_CLOSE_UNKNOWN:
      "Un patient s'est fermé automatiquement car trop de patients étaient ouverts",
    PRELOAD: {
      TITLE: "Chargement de l'application",
      CONFIGURATION: "Chargement de la configuration",
      BEOOGO: "Connexion à Beoogo",
      EHEALTH: "Connexion à eHealth",
      ERROR: "Erreur",
    },
    PMF_IMPORT_DISABLED_ON_ICURE_DBS:
      "L'import PMF est momentanément indisponible pour les bases de données iCure et Topaz.",
    SUMEHR: {
      NEW_SUMEHR_DETECTED: "Mise à jour du SUMEHR",
      ASK_CREATION_NEW_SUMEHR:
        "Aucun SUMEHR n’existe pour ce patient. Voulez-vous l’envoyer ?",
      ASK_UPGRADE_SUMEHR:
        "Voulez-vous envoyer une nouvelle version du SUMEHR ?",
      REJECT: "Ne pas envoyer",
      CREATION_CONFIRM: "Prévisualiser",
      CONFIRM: "Envoyer",
      SEND_SUMEHR: {
        SUCCESS: "Envoi du SUMEHR réussi pour le patient {{patient}}",
        GENERATION_ERROR:
          "Erreur lors de la génération du SUMEHR pour le patient {{patient}}",
      },
    },
  },
  "management-home": {
    TABS: {
      SETTINGS: "Paramétrages",
      DATABASE: "Base de données",
      DOCUMENTS: "Documents",
      TOOLS: "Outils",
    },
    HCPS: "Intervenants",
    USERS: "Utilisateurs",
    MAINTENANCES: "Maintenances",
    SECURITY: "Sécurité",
    PRINTERS: "Impression",
    CLASSIFICATION_TEMPLATES: "Classification templates",
    MAGISTRAL: "Magistrales",
    TEMPLATING: "Documents type",
    KEYWORDS: "Mots-clés",
    KEYWORD_AUTOCOMPLETE: "Auto-complétion",
    INVOICING: "Attestations",
    EFAC: "Facturation eFact",
    MYCARENET: "MyCareNet Conversation",
    E_DMG: "eDMG",
    E_DMG_PATIENT_LIST: "Liste des patients",
    E_DMG_OTHERS: "Renouvellements et clotures",
    SCAN_IMPORT: "Import des scans",
    ACCESS_LOG: "Journal des accès",
    CONNECTIONS: "Connexions",
    ACCESS_LOG_ALL: "Tous les acces",
    ACCESS_LOG_USER: "Par utilisateur",
    ACCESS_LOG_PATIENT: "Par dossier patient",
    STATISTICS: "Statistiques",
    DASHBOARD: "tableau de bord",
    PRIVATE_MEDICATION: "Médicaments privés",
    IMPORT: "Importer",
    IMPORT_EXCEL: "Excel",
    SUGGESION_HE: "Aide à la création d'ES",
    REPORTS: "Rapports",
    FLATRATE: "eForfait",
    LABORATORIES: "Laboratoires",
    EVENTS_COVID: "Suivi COVID",
  },
  "flatrate-report": {
    title: "Eforfait",
    type: "Type de rapport",
    yes: "Oui",
    no: "Non",
    subscription: {
      title: "Export",
    },
    btn: {
      generate: "Générer",
      generating: "En cours de génération",
    },
    "report-type": {
      SUBSCRIPTION: "Export des abonnements",
    },
    sheetHeaders: {
      title: {
        subscription: "Forfait",
      },
      active: "Actif",
      firstName: "Nom",
      lastName: "Prénom",
      ssin: "Niss",
      dateOfDeath: "Date de décès",
      insuranceCode: "Code",
      insuranceContractId: "N° contrat",
      insuranceStartDate: "Date de début d'assu",
      insuranceEndDate: "Date de fin d'assu",
      insuranceTc1: "Ct1",
      insuranceTc2: "Ct2",
      insurancePreferentialStatus: "Statut préférentiel",
      mhcIsValid: "Contrat valide",
      mhcContractId: "Id du contrat",
      mhcStartOfContract: "Début du contrat",
      mhcEndOfContract: "Fin du contrat",
      mhcStartCoverage: "Début de la couverture",
      mhcEndCoverage: "Fin de la couverture",
      mhcSignatureType: "Type de signature",
      lastInvoiced: "N° dernier envoi",
      errorNoSubscriptionResponse: "Erreur lors de l'inscirption",
    },
  },
  mycarenet: {
    STUCK:
      "Vous êtes bloqués? Téléchargez ce fichier et envoyez-le au helpdesk.",
  },
  "efact-status": {
    NOT_EFACT: "Pas une eFact",
    ERRORS_IN_PRELIMINARY_CONTROL: "Erreur détectée",
    ERROR: "En erreur",
    WARNING: "Partiellement accepté",
    SUCCESS: "Accepté",
    REJECTED: "Rejeté",
    ACCEPTED: "Accepté pour traitement",
    TREATMENT: "En traitement",
    SUBMITTED: "Transmis",
    RECEIVED: "Reçu",
    SENT: "Envoyé",
    WAITING: "En attente",
    ARCHIVED: "Archivé",
    UNKNOWN: "Statut inconnu",
  },
  "invoicing-summary": {
    INVOICING: "Attestations",
    PENDING_INVOICES: "Attestations à clôturer",
    EFAC: "Facturation eFact",
    EFAC_TO_BE_SENT: "À envoyer",
    EFAC_PENDING: "En attente",
    EFAC_TO_BE_CORRECTED: "À corriger",
    EFAC_TREATED: "Traitées",
    EFAC_ARCHIVED: "Archivées",
    EFAC_INVOICES: "Factures eFact",
    EFAC_BATCH: "Envois eFact (Lots)",
  },
  "invoicing-excel": {
    EXPORT_PATIENTS: "Exporter les patients",
    EXPORT: "Export Excel",
    EH_NOT_CONNECTED:
      "Connectez vous à eHealth pour inclure les patients dans l'export",
    GEN_0:
      "Génération du fichier Excel en cours. Veuillez patienter, cela peut prendre beaucoup de temps.",
    GEN_50: "Génération du fichier Excel 50%",
    GEN_100: "Génération du fichier Excel 100%",
    GEN_ERR:
      "Erreur lors de la génération du fichier Excel. Le générer sur une période plus courte pourrait éviter l'erreur",
    DETAILS: "Détails",
    GENINS_FAIL:
      "L'assurabilité du patient {{name}} à échoué. La mutuelle ne sera pas présente dans l'export pour ce patient.",
    CERTIFICATES: "Attestations",
  },
  "patients-export": {
    EXCEL_NAME: "ListeDesPatients.xlsx",
    EXCEL_GEN_0:
      "Génération du fichier Excel en cours. Veuillez patienter, cela peut prendre beaucoup de temps.",
    EXCEL_GEN_100: "Génération du fichier Excel 100%",
    EXCEL_GEN_ERR: "Erreur lors de la génération du fichier Excel.",
    fields: {
      FIRST_NAME: "Prénom",
      LAST_NAME: "Nom",
      DATE_OF_BIRTH: "Date de naissance",
      SSIN: "Numéro national",
      STREET: "Rue",
      HOUSE_NUMBER: "Numéro",
      POSTAL_CODE: "Code postal",
      CITY: "Ville",
      MOBILE: "Téléphone portable",
      PHONE: "Téléphone fixe",
      EMAIL: "E-mail",
      GENDER: "Genre",
      AGE: "Âge",
      PREVENTION_STATUS: "Statut de prévention",
      PREVENTION_DATE: "Échéance de la prévention",
      EXTERNAL_ID: "Identifiant externe",
    },
    TITLE: "Type d'export",
    EXPORT: "Exporter",
    CANCEL: "Annuler",
    FORMAT: "Format",
    MULTIPLE_EXCEL: {
      LABEL: "Exporter la liste des patients sélectionnés",
      DESCRIPTION:
        "La liste des patients que vous avez sélectionnés sera exportée dans un tableau Excel.",
      FORMAT: "Excel (XSLX)",
    },
    MULTIPLE_PMF: {
      LABEL: "Exporter les dossiers des patients sélectionnés",
      DESCRIPTION:
        "Le dossier de chaque patient sera enregistré dans un fichier placé dans le dossier de votre choix.",
      FORMAT: "PMF",
    },
    ALL_JSON: {
      LABEL: "Exporter tous les dossiers",
      DESCRIPTION: "L ensemble de vos dossiers sont exportes",
      FORMAT: "JSON",
    },
    SINGLE_CSV: {
      LABEL: "Exporter la liste des contacts du patient",
      DESCRIPTION:
        "La liste des contacts du patient sera enregistrée dans un fichier où les informations sont séparées par des virgules.",
      FORMAT: "CSV",
    },
    SINGLE_PMF: {
      LABEL: "Exporter le dossier du patient",
      DESCRIPTION:
        "Le dossier du patient sera enregistré dans un fichier placé dans le dossier de votre choix.",
      FORMAT: "PMF",
    },
    SINGLE_SELECTED_EXPORT: "Export du patient sélectionné",
    MULTIPLE_SELECTED_EXPORT: "Export de vos patients sélectionnés",
    ALL_EXPORT: "Export de tous vos patients",
    SINGLE_SELECTED_EXCEL: {
      LABEL: "Export au format Excel",
      DESCRIPTION: "Le patient sera exportée dans un tableau Excel.",
    },
    SINGLE_SELECTED_JSON: {
      LABEL: "Export Json",
      DESCRIPTION:
        "Les données décryptées du patient seront exportées au format Json.",
    },
    SINGLE_SELECTED_PMF: {
      LABEL: "Export PMF",
      DESCRIPTION: "Le patient sera exporté dans un format PMF.",
    },
    MULTIPLE_SELECTED_EXCEL: {
      LABEL: "Export au format Excel",
      DESCRIPTION: "La liste de patient sera exportée dans un tableau Excel.",
    },
    MULTIPLE_SELECTED_JSON: {
      LABEL: "Export Json",
      DESCRIPTION:
        "La liste de toutes vos données décryptées seront exportées au format Json.",
      WARNING:
        "Attention: Ceci peut prendre énormement de place sur votre disque dur. Veillez à libérer suffisamment de place.",
    },
    MULTIPLE_SELECTED_PMF: {
      LABEL: "Export PMF",
      DESCRIPTION:
        "La liste de tous les patients sera exportée au format PMF dans un fichier .zip.",
      ZIP_NAME: "Exportation-de-plusieurs-PMF.zip",
    },
    SUCCESS: "Données exportées avec succès.",
    FAILURE: "Erreur lors de l'exportation.",
    EXPORT_STARTED: "Un export de dossiers patient est en cours.",
    EXPORT_FINISHED: "Export de dossiers patient terminé.",
    INVCUDE_ACTIVE_PATIENTS: "Inclure les patients inactifs",
  },
  "patients-home": {
    INCLUDE_INACTIVES: "Inclure les patients inactifs",
    BACK: "Retour",
    TITLE: "Liste des patients",
    NO_RECORDS:
      "Aucun résultat ne correspond à votre recherche ou votre recherche n'est pas assez précise (3 caractères sont nécessaires au minimum)",
    PATIENTS: "Patients",
    MERGE_PATIENTS: "Fusionner les patients",
    MERGE_PATIENTS_WARNING:
      "Veuillez confirmer la fusion de {{patient}} avec {{duplicate}}.",
    MERGE_PATIENTS_ALERT: "Attention: cette action est irréversible.",
    UNEXPECTED_ERROR_DURING_MERGE:
      "Une erreur s'est produite durant la fusion des patients.",
    CONFIRM: "Confirmer la fusion",
    CANCEL: "Annuler",
    FIND_BY_NAME: "Trouver par nom",
    EXTERNAL_ID: "Numéro de dossier",
    PREVENTIONS_DESCRIPTIONS: "Critère de sélection",
    LAST_NAME: "Nom",
    FIRST_NAME: "Prénom",
    DATE_OF_BIRTH: "Date de naissance",
    CREATION_DATE: "Date de création",
    CREATED_BY: "Créé par",
    SSIN: "Numéro national",
    ADDRESS: "Domicile",
    CONTACT: "Contact",
    DUPLICATES: "Doublons",
    EXPORT: "Export",
    EXPORT_ALL: "Tous",
    GENDER: "Genre",
    AGE: "Âge",
    STREET: "Rue",
    HOUSENUMBER: "Numéro",
    POSTALCODE: "Code postal",
    CITY: "Ville",
    COUNTRY: "Pays",
    ADDRESS_HOME_PHONE: "Téléphone",
    ADDRESS_HOME_MOBILE: "Gsm",
    ADDRESS_HOME_MAIL: "Email",
    PREVENTION_STATUS: "Statut de prévention",
    MERGE_ERROR_PATIENT_OPENED:
      "Veuillez fermer l'onglet '{{tabName}}' avant de fusionner",
    PREVENTION_DATE: "Échéance de la prévention",
    PREVENTION: {
      All: "Toutes",
      ToDo: "A faire",
      InOrder: "En ordre",
      Refused: "Refusée par le patient",
      Cancelled: "Annulée par le médecin",
      NonIndicated: "Non-indiquée",
      NotActivated: "Non activée",
      None: "",
      Action: {
        activate: "Activer",
        refuse: "Refuser",
        nonIndicated: "Non-indiquée",
        plan: "Planifier une tâche de prévention",
      },
      PLAN_PREVENTIVE_ACT: {
        Title: "Planifier une tâche de prévention",
        Warning:
          "La prévention va être activée chez tous les patients sélectionnés",
        Cancel: "Annuler",
        Save: "Sauver",
      },
      STATUS_UPDATED_SUCCESSFULLY: "Préventions mises à jour",
    },
    VACCINATION_LAST_DATE: "Date de dernière vaccination",
    MAX_DUE_DATE: "Échue avant",
    STATUS_DATE: "Date du changement de statut",
    MAILING: "Envoyer courrier",
    MAILING_TITLE: "Envoyer un courrier à {{ count }} patients",
    ADVANCED_SEARCH: "Recherche avancée",
    BIOMETRY: "Biométrie",
    SERVICES: "Services",
    DISPLAY_MODE: {
      MODE: "Mode",
      NORMAL: "Normal",
      PREVENTION: "Prévention",
      BIOMETRY: "Biométrie",
      SERVICES: "Services",
      BREAST_CANCER_PREVENTION: "Prévention du cancer du sein",
      CERVIX_CANCER_PREVENTION: "Prévention du cancer du col de l'utérus",
      COLORECTAL_CANCER_PREVENTION: "Prévention du cancer du colon",
      INFLUENZA_FLU_PREVENTION: "Prévention de la grippe",
      AORTIC_ANEURYSM_PREVENTION:
        "Prévention de l’anévrysme de l’aorte abdominale",
      LUNG_CANCER_PREVENTION: "Prévention du cancer du poumon",
      HEALTH_ELEMENT: "Élément de soin",
      COVID19_QUERY: "Patients à risque Covid-19",
      VACCINATION_COVID: "Suivi des vaccinations Covid",
      NOT_VACCINATED_COVID19_RISK: "Patients à risque Covid-19 non vaccinés",
    },
    CONSULTATION: "Consultation associée",
    VIEW_CONTACT: "Accéder",
    ACTIVE: "Actif",
    YES: "Oui",
    NO: "Non",
    REFRESH: "Actualiser",
    PRINT_TAG: "Imprimer étiquettes",
    PRINT_TAG_ERROR:
      "{{name}} ne dispose pas de toutes les informations nécessaires pour imprimer son étiquette de mutuelle.",
    VACCINATION_COVID: {
      GET_EVENTS: "Recevoir les derniers informations",
      MEETING_DATE: "Date du prochain rendez-vous",
      STATUS: "Statut",
      FIRST_DOSE_DATE: "Date de la première dose",
      SECOND_DOSE_DATE: "Date de la seconde dose",
      EXTRA_DOSE: "Extra dose",
      EXTRA_DOSE_ACTIVE: "Activé",
      EXTRA_DOSE_GET: "Reçu",
      OTHER_INFO: "Autre information",
      NO_INFO: "Aucune information",
      CNK_ERROR: "CNK manquant: statut à vérifier dans le dossier",
      ACTIVATED: "Activé",
      NOT_ACTIVATED: "Pas activé",
      TOTALY_VACCINE: "Totalement vacciné",
      PARTIAL_VACCINE: "Partiellement vacciné",
      NO_VACCINE: "Non vacciné",
    },
    COVID19: {
      SEND: "Envoyez la liste",
      SEND_OK: "Liste envoyée",
      SEND_ERROR: "Erreur d'envoi de la liste",
      SEND_NOT_AVAILABLE:
        "Service vaccination covid non disponible. Veuillez réessayer plus tard.",
      OVER65: "Plus de 65 ans",
      NO_PRIORITY: {
        "1844": "Non à risque de 18-44 ans",
        "4564": "Non à risque de 45-64 ans",
      },
      PRIORITY: {
        "1&3": {
          "1844": "A risques - 18-44 ans",
          "4564": "A risques - 18-64 ans",
        },
        "1": {
          "1864": "Priorité 1 - 18-64 ans",
          "4564": "Priorité 1 - 45-64 ans",
        },
        "3": {
          "1844": "Priorité 3 - 18-44 ans",
        },
      },
    },
  },
  "patients-duplicates": {
    TITLE: "Liste des patients en double",
    BY_SSIN: "Doublons basés sur NISS",
    BY_NAME: "Doublons basés sur le nom",
    SSIN: "NISS:",
    MERGE: "Fusionner",
    NO_DUPLICATE: "Marquer comme non doublons",
    MARKED_NO_DUPLICATE: "Patients marqués comme non dupliqués",
    NO_SSIN_DUPLICATES_MESSAGE: "Il n'y a pas de doublons par NISS",
    NO_NAME_DUPLICATES_MESSAGE: "Il n'y a pas de doublons par nom",
    WARNING:
      "Attention: Veuillez vous assurer qu'aucune autre action n'est effectuée sur les patients fusionnés",
    MERGED: "Fusionné",
    NOT_DUPLICATE: "Non doublons",
    MERGE_ERROR_PATIENT_OPENED:
      "Veuillez fermer l'onglet '{{tabName}}' avant de fusionner",
    VIEW_MERGED_PROFILE: "Accéder au profil fusionné",
    LOAD_MORE: "Plus de résultats",
    PATIENTS_MERGING: "Fusion des patients",
    EDIT: "Éditer manuellement",
    SAVE: "Sauver",
    CANCEL: "Annuler",
  },
  "patient-conflicts": {
    MANAGE_CONFLICTS: "Gérer les conflits",
    FIRST_NAME: "Prénom",
    LAST_NAME: "Nom de famille",
    CHRONICAL_DISEASE: "",
    RESPONSIBLE: "",
    AUTHOR: "",
    END_OF_LIFE: "",
    SSIN: "NISS",
    CIVILITY: "Civilité",
    GENDER: "Genre",
    MAIDEN_NAME: "Nom de jeune fille",
    SPOUSE_NAME: "Nom de l'époux",
    PARTNER_NAME: "Nom du conjoint",
    PERSONAL_STATUS: "Statut personnel",
    DATE_OF_BIRTH: "Date de naissance",
    DATE_OF_DEATH: "Date de décès",
    PLACE_OF_BIRTH: "Lieu de naissance",
    PLACE_OF_DEATH: "Lieu du décès",
    EDUCATION: "Éducation",
    PROFESSION: "Profession",
    NOTES: "Notes",
    NATIONALITY: "Nationalité",
    PICTURE: "Image",
    EXTERNAL_ID: "Identifiant externe",
    ALIAS: "Alias",
    ADMINISTRATIVE_NOTE: "Note administrative",
    WARNING: "Attention",
    NEXT: "Suivant",
    DEACTIVATION_REASON: "Motif de désactivation",
    EDIT_CONFLICTS: "Modifier les conflits",
    SAVE: "Sauver",
    CANCEL: "Annuler",
    ADDRESSES: {
      STREET: "Adresse [{{ number }}] > Rue",
      CITY: "Adresse [{{ number }}] > Ville",
      COUNTRY: "Adresse [{{ number }}] > Pays",
      DESCR: "Adresse [{{ number }}] > Description",
      HOUSE_NUMBER: "Adresse [{{ number }}] > Numéro",
      POSTAL_NUMBER: "Adresse [{{ number }}] > Code postal",
      POSTBOX_NUMBER: "Adresse [{{ number }}] > Boîte",
    },
    INSURABILITIES: {
      END_DATE: "Assurabilité [{{ number }}] > Fin de validité",
      IDENTIFICATION_NUMBER:
        "Assurabilité [{{ number }}] > Numéro d'identification",
      DENTAL: "Assurabilité [{{ number }}] > Dentaire",
      AMBULATORY: "Assurabilité [{{ number }}] > Ambulatoire",
      HOSPITALISATION: "Assurabilité [{{ number }}] > Hospitalisation",
      TITULARY_ID: "Assurabilité [{{ number }}] > ID titulaire",
    },
    PATIENT_HCP: {
      REFERRAL: "Intervenants [{{ number }}] > Référence",
      REFERRAL_PERIODS_END_DATE: "Intervenants [{{ number }}] > Date de fin",
      REFERRAL_PERIODS_COMMENT: "Intervenants [{{ number }}] > Commentaire",
    },
    PARTNERSHIPS: {
      TYPE: "Partenariat [{{ number }}] > Type",
      STATUS: "Partenariat [{{ number }}] > Statut",
      PARTNERSHIP_DESCRIPTION: "Partenariat [{{ number }}] > Description",
    },
    MEDICAL_HOUSE_CONTRACTS: {
      CHANGE_TYPE: "Maison médicale [{{ number }}] > Changer le type",
      CHANGED_BY: "Maison médicale [{{ number }}] > Changé par",
      END_OF_CONTRACT: "Maison médicale [{{ number }}] > Fin de contrat",
      END_OF_COVERAGE: "Maison médicale [{{ number }}] > Fin de couverture",
      END_OF_SUSPENSION: "Maison médicale [{{ number }}] > Fin de suspension",
      FORCED_SUSPENSION: "Maison médicale [{{ number }}] > Suspension forcée",
      GP: "Maison médicale [{{ number }}] > GP",
      HCP_ID: "Maison médicale [{{ number }}] > L'intervenant Id",
      KINE: "Maison médicale [{{ number }}] > Kiné",
      NO_GP: "Maison médicale [{{ number }}] > Pas de GP",
      NO_KINE: "Maison médicale [{{ number }}] > Pas de kiné",
      NO_NURSE: "Maison médicale [{{ number }}] > Pas d'infirmière",
      NURSE: "Maison médicale [{{ number }}] > Infirmière",
      START_OF_CONTRACT: "Maison médicale [{{ number }}] > Début de contrat",
      START_OF_COVERAGE: "Maison médicale [{{ number }}] > Début de couverture",
      START_OF_SUSPENSION:
        "Maison médicale [{{ number }}] > Début de suspension",
      SUSPENSION_REASON: "Maison médicale [{{ number }}] > Motif de suspension",
      SUSPENSION_SOURCE:
        "Maison médicale [{{ number }}] > Source de suspension",
      VALID_FROM: "Maison médicale [{{ number }}] > Valide du",
      VALID_TO: "Maison médicale [{{ number }}] > Valide au",
    },
    FINANCIAL_INFO: {
      NAME: "L'information financière [{{ number }}] > Nom",
      KEY: "L'information financière [{{ number }}] > Clé",
      BIC: "L'information financière [{{ number }}] > BIC",
      PROXY_BANK_ACCOUNT:
        "L'information financière [{{ number }}] > Proxy IBAN",
      PROXY_BIC: "L'information financière [{{ number }}] > Proxy BIC",
    },
  },
  "access-log": {
    LATEST_PATIENTS: "Derniers patients ouverts",
    LAST_DATE: "Dernière date d'accès",
    OPEN_PATIENT: "Ouvrir le dossier du patient",
    PATIENT_STATS: {
      TITLE: "Statistiques Sumehrs - {{ name }}",
      DMG_COUNT: "Nombre de DMG m'appartenant",
      SUMEHR_SENT_COUNT: "Nombre de Sumehrs envoyés",
      DMG_WITH_SUMEHR_SENT_COUNT:
        "Nombre de DMG m'appartenant pour lesquels j'ai envoyé un sumehr",
      WARNING:
        "Les chiffres présentés ci-dessus couvrent uniquement les patients stockés dans la base de données actuelle et peuvent différer du compte officiel eHealth.",
    },
  },
  academic: {
    "mail-login": {
      content:
        "Bonjour {{hcpLastName}}, {{hcpFirstName}},<br/><br/>Vos identifiants sont les suivants : <br/>Login :  {{userLogin}} <br/>Matricule : {{studentNumber}} <br/>Mot de passe : {{password}} <br/>Vous n’avez pas de mot de passe eHealth.</br></br>",
      subject:
        "Bienvenue dans Medispring {{hcpLastName}} {{hcpFirstName}} ({{studentNumber}})",
    },
    "mail-key": {
      content: "Bonjour,<br/> Voici votre clé de cryptage.",
      subject:
        "Votre clé Medispring {{hcpLastName}} {{hcpFirstName}} ({{studentNumber}})",
    },
    error: {
      delegationConfig:
        "Création impossible, l'information sur les délégations est manquante dans les APPSETTING, veuillez contacter le helpdesk",
    },
  },
  "guardPost-access-log": {
    LAST_VISITS: "Dernières visites",
    CONNECT: "Connectez-vous à eHealth pour envoyer les rapports de garde.",
    X_REPORT_OK: "{{ c }} rapports prêts à être envoyés",
    "1_REPORT_OK": "1 rapport prêt à être envoyé",
    X_SEND: "Envoyer les {{ c }} rapports",
    "1_SEND": "Envoyer le rapport",
    FILTER_BY: "Filtrer par",
    PMG_FILTER: "PMG",
    PATIENT: "Patient",
    GUARD_HCP: "Médecin de garde",
    DEST_HCP: "Destinataire",
    CONSULT_DATE: "Date de visite",
    STATUS: "Statut",
    STATUT_WAIT_DEST: "En attente d'un destinataire",
    STATUT_WAIT_REPORT: "En attente du rapport",
    STATUT_WAIT_SEND: "En attente d'envoi",
    SEND: "Envoyer",
    SENT: "Envoyé",
    NO_DOCUMENT_IN_CONTACT:
      "Aucun document n'est présent dans ce contact. Contactez le médecin de garde afin qu'il prépare le rapport de garde.",
    ERROR_MARK_SENT: "Impossible de marquer le rapport comme envoyé",
    ERROR_NOT_SENT: "Le message n'a pas été envoyé",
    ERROR: "Une erreur est survenue",
    ERROR_CONTACT_GET: "Impossible de récupérer les contacts",
    ERROR_PATIENTGET: "Impossible d'obtenir les données d'un patient",
    ERROR_REF_HCP_GET:
      "Impossible d'obtenir les données du médecin triatant du patient",
    ERROR_RESP_HCP_GET:
      "Impossible d'obtenir les données du responsable du contact",
    ERROR_NO_DEST_NIHII:
      "L'intervenant destinatire {{hcp}} n'a pas de numéro inami défini dans sa fiche",
    ERROR_UPDATE_PATIENT: "Impossible de mettre le patient à jour",
    PATIENT_UPDATED: "Patient modifié",
    AUTO_MAIL_SUBJECT: "RAPPORT DE GARDE",
    ALL: "Tous",
    TYPE: "Type de consultation",
    TYPES: {
      homevisit: "Visite à domicile",
      telephonicconsultation: "Avis/divers",
      consult: "Consultation",
    },
    NO_REPORT: "Pas de rapport",
  },
  "user-management": {
    EXPORT_FILENAME: "export_utilisateurs",
    CURRENT_USER_EDIT_WARNING:
      "Veuillez éditer votre profil à partir de l'icône située en bas à gauche de Medispring",
    ALL_USERS: "Tous les utilisateurs",
    FIND_USER_BY_NAME: "Trouver un utilisateur par nom",
    NEW_USER: "Nouvel utilisateur",
    NEW_USER_LIST: "Importer une liste d'utilisateurs (.csv)",
    EDIT: "Modifier",
    CREATE_USER: "Créer un utilisateur",
    EDIT_USER: "Modifier un utilisateur",
    DISABLE_USER: "Désactiver l'utilisateur",
    EXPIRED: "[Expiré]",
    NAME: "Nom",
    LOGIN: "Login",
    ROLE: "Rôle(s)",
    DEACTIVATED: "Désactivé",
    EH_CERT_VALIDITY: "Expiration du certificat eHealth",
    INCLUDE_INACTIVES: "Inclure les inactifs",
    bulk: {
      TITLE: "Importation d'une liste d'utilisateurs",
      FILE_TEMPLATE: "Télécharger la template .csv",
      IMPORT_FILE: "Importer le fichier .csv",
      CREATE_USERS: "Créer les utilisateurs",
      CLOSE: "Fermer",
      columName: {
        type: "Type",
        firstName: "Prénom",
        lastName: "Nom",
        email: "Email",
        password: "Mot de passe",
        registrationNumber: "Matricule",
        startYear: "Année",
        admin: "Admin",
        roles: "Rôle(s)",
        ssin: "Niss",
        nihii: "Inami",
      },
      "user-type": {
        teacher: "Prof",
        student: "Etudiant",
        practician: "Praticien",
        secretary: "Secrétaire",
      },
      "user-preview": "Aperçu du contenu du fichier uploadé",
      "user-preview-loading":
        "Aperçu du contenu du fichier en cours de chargement",
      "user-creating": "Utilisateurs en cours de création, veuillez patienter",
    },
    export: {
      columnName: {
        name: "Nom",
        login: "Login",
        nihii: "Inami",
        ssin: "Niss",
        active: "Actif",
        roles: "Rôle(s)",
        hub: "Hub",
        ehealthExpiration: "Date expiration du certificat",
        registrationNumber: "N° de matricule",
        startYear: "Commencement des études",
        street: "Rue",
        houseNumber: "N°",
        postalBox: "Boîte",
        locality: "Localité",
        country: "Pays",
        email: "Email",
        tel: "Téléphone",
        gsm: "Gsm",
        stageMaster: {
          firstName: "Prénom",
          lastName: "Nom",
          nihii: "Inami",
          ssin: "Niss",
        },
      },
      hub: {
        WAL: "Wallonie",
        FLA: "Flandres",
        BRU: "Bruxelles",
      },
      status: {
        ACTIVE: "Actif",
        INACTIVE: "Inactif",
      },
    },
    EXPORT_USER_LIST: "Exporter la liste des utilisateurs",
    EXPORTING_USER_LIST: "Exportation des utilisateurs en cours",
  },
  "hcp-management": {
    HCPS: "Intervenants",
    FIND_BY_NAME: "Trouver par nom",
    NEW_HCP: "Nouvel intervenant",
    DELETE: "Supprimer",
    CONFIRM: "Voulez vous supprimer cet intervenant ?",
    PROCESSING: "Suppression de l'intervenant...",
    DONE: "Intervenant supprimé avec succès.",
    NAME: "Nom",
    SPECIALITY: "Spécialité",
    LAST_NAME: "Nom de famille",
    FIRST_NAME: "Prénom",
    CREATE_HCP: "Créer un intervenant",
    EDIT_HCP: "Modifier l'intervenant",
    EMAIL: "E-mail",
    LANDLINE: "Téléphone fixe",
    MOBILE: "Téléphone portable",
    ADRESSE: "Adresse",
    PMG_MISSING_FIELD: "Le champ {{field}} est manquant pour votre PMG.",
    PMG_MISSING_FIELDS: "Les champs {{field}} sont manquants pour votre PMG.",
    PRACTICIAN_MISSING_FIELD:
      "Le champ {{field}} du praticien est manquant dans votre fiche utilisateur.",
    PRACTICIAN_MISSING_FIELDS:
      "Les champs {{field}} du praticien sont manquants dans votre fiche utilisateur.",
    ASSISTANTS_MISSING_FIELD:
      "Le champ {{field}} des assistants est manquant dans votre fiche utilisateur.",
    ASSISTANTS_MISSING_FIELDS:
      "Les champs {{field}} des assistants sont manquants dans votre fiche utilisateur.",
    DELETION_NOT_ALLOWED:
      "Cet intervenant ne peut pas être supprimé car il est lié à un autre intervenant.",
  },
  "he-management": {
    CONFIRMATION: "Confirmation",
    SUGGESTED_ITEM: "Elément(s) suggéré(s)",
    CRITERIA_FOUND: "Critère(s) trouvé(s)",
    SSIN: "Niss",
    PATIENT: "Patient",
    SUGGESTION_HE: "Suggestion d'éléments de soin à ajouter aux dossiers",
    TYPE: "Problème potentiel",
    AGE: "Age",
    SAVE: "Créer les élements de soin",
    SAVING: "Création des élements en cours",
    OR: "ou",
    CREATION_OK: "Elements de soin créés.",
    EXCLUDE: "Exclusion",
    DMG: "Détenteur du DMG",
    FIND_BY_DMG_OWNER: "Rechercher par détenteur du DMG",
    criteria: {
      DRUG_CHRON_CLASS: "Médicament(s) chronique(s) de la classe",
      DRUG_CLASS: "Médicament(s) de la classe",
      GLY: "Glycémie à jeûn",
      NGSP: "Hémoglobine glycosylée (ngsp)",
    },
    type: {
      cardiovascularProblems: "Problèmes cardiovasculaires chroniques",
      diabetes: "Diabète",
      obesityProblems: "Obésité",
      respiratoryProblems: "Problèmes respiratoires chroniques",
      renalFailure: "Insuffisance rénale",
    },
  },
  "scan-import-management": {
    TITLE: "Importation de scans",
    LAST_IMPORT: "Dernière import",
    SEARCH_AND_IMPORT: "Rechercher et importer",
    PATH: "Chemin",
    NO_PATH_SET: "Pas de Chemin",
    EDIT: "Modifier",
    NO_IMPORT_YET: "Aucune import pour le moment",
    FILES_RETRIEVED_FROM_DISK: "Fichiers récupérés du disque",
    MODIFY_RESPONSIBLE: "Modifier le responsable",
    MODIFY_DATE: "Modifier la date",
    DO_NOT_IMPORT: "Ne pas importer",
    IMPORT: "Importer",
    MISSING_PATIENT: "Patient manquant",
    READY_FOR_IMPORT: "Prêt pour l'import",
    DUPLICATES_AND_FAILED: "Échoué et doublons",
    NO_FILES_TO_IMPORT: "Aucun fichier à importer",
    ONLY_AVAILABLE_ON_DESKTOP_APP:
      "Seulement disponible sur l'application de bureau",
    SELECT_DATE: "Choisir la date",
  },
  "mycarenet-management": {
    TITLE: "Conversations MyCareNet",
    SEARCH: "Rechercher par références",
  },
  "statistics-management": {
    TITLE: "Statistiques",
    RASH: "RASH",
    RLM: "RLM",
    PMG: "PMG",
    PMGCSV: "PMG (CSV)",
    PMGAGENDA: "PMG Agenda",
    PMGAGENDACSV: "PMG Agenda (CSV)",
    SELECT_DATE_RANGE: "Sélectionner une periode",
    SELECT_TYPE: "Sélectionner le genre",
    FROM: "Du",
    TO: "au",
    DOWNLOAD: "Télécharger",
    LOADING_HCP: "Chargement des intervenants actifs",
    HCP_STATISTICS_WILL_BE_GENERATED_FOR:
      "Intervenants pour lesquelles les statistiques seront générées",
    SHOULD_USE_EHEALTH: "Utilisez eHealth",
  },
  "current-user": {
    USER_DETAILS: "Détails de l'utilisateur",
    CONNECTED: "Vous êtes connecté aux services eHealth.",
    DISCONNECTED: "Vous n'êtes pas connecté aux services eHealth.",
  },
  "form-selector": {
    USER: "Utilisateur",
    HCP: "Intervenant",
    PRIVATE_KEY: "Clé privée",
    CERTIFICATE: "Certificat",
    DMG: "eDMG",
    LOGOUT: "Déconnexion",
    CLEAN_DATA_ON_LOGOUT: "Effacer mes données privées",
    E_HEALTH: "Connexion eHealth",
    E_DMG: "eDmg",
    EFACT: "eFact",
    LICENCE: "Licence",
    UNSAVED_CHANGES:
      "Le formulaire actuel contient des données non sauvegardées.",
    E_LABO: "eLabo",
    FLATRATE: "eForfait",
  },
  "eh-menu": {
    TITLE_MAIN: "eHealth",
    CONNEXION: "Connexion",
    E_DMG: "eDMG",
  },
  "eh-cert-import": {
    CERTIFICATE: "Certificat eHealth",
    STATUS: {
      IMPORTED: "Importé",
      MISSING: "Manquant",
    },
    IMPORT_YOUR_CERTIFICATE: "Importez votre certificat eHealth\u00A0:",
    SELECT_FILE: "Choisir un fichier",
    CONNECTION: "Connexion eHealth",
    CONNECTION_BUTTON: "Se connecter à eHealth",
    PASSWORD: "Mot de passe eHealth",
    CERT_UPLOAD_SUCCESS: "Certificat eHealth envoyé avec succès.",
    CERT_UPLOAD_FAILURE: "L'envoi du certificat eHealth a échoué.",
    CONNECTED: "Connecté",
    NOT_CONNECTED: "Non connecté",
    INVALID_CERTIFICATE: "Certificat invalide.",
    ERROR_SIZE:
      "La taille de votre certificat ne semble pas raisonnable. Sa taille (actuellement {{actual}} bytes) doit être comprise entre {{min}} et {{max}}.",
    ERROR_DATE:
      "La date de votre certificat n'est pas dans les {{y}} dernières années",
    ERROR_SSIN:
      "Le NISS du certificat ({{p12Ssin}}) ne correspond pas au votre ({{hcpSsin}})",
    GUARDPOST_MODE: "Connexion en tant que poste de garde",
    VALIDITY: "Validité",
    CERTIFICATE_NAME: "Nom du certificat",
    END_OF_VALIDITY: "Fin de validité",
    INSTRUCTIONS: "Instructions avant la connexion",
    VALIDITY_WARNING_TEXT:
      "Attention, votre certificat eHealth expire dans moins d'un mois.",
    VALIDITY_ERROR_TEXT:
      "Votre certificat eHealth est expiré, vous devez le renouveler.",
    CERTIFICATE_RENEW_LINK_TEXT: "Demander un nouveau certificat eHealth.",
    CERTIFICATE_RENEW_LINK_VALUE:
      "https://www.ehealth.fgov.be/fr/esante/professionnels-de-la-sante/gestion-des-certificats-ehealth/presentation-generale",
  },
  "private-key-import": {
    PRIVATE_KEY: "Clé privée",
    STATUS: {
      IMPORTED: "Clé importée : {{hcpId}}",
      MISSING: "Clé privée manquante",
      CRYPTED: "Clé privée cryptée",
    },
    IMPORT_YOUR_PRIVATE_KEY: "Importez votre clé privée ({{hcpId}})\u00A0:",
    SELECT_FILE: "Choisir un fichier",
    QR_IMPORT: {
      START: "Démarrer le scan",
      CANCEL: "Annuler",
      RESTART: "Recommencer",
      FAILED: "Le scan a échoué.",
    },
    DOWNLOAD_KEY: "Télécharger la clé privée",
    DELETE_KEY: "Supprimer la clé privée",
    KEY_FILES_IMPORTED: "Clés importées avec succès.",
    KEY_FILE_INVALID: "Fichier clé invalide.",
    CANCEL_IMPORT: "Annuler",
    EXPORT_ALL: "Tout exporter",
    DOWNLOAD_FILE: "Télécharger le fichier",
  },
  "key-import-qr-code": {
    NUMBER_OF_CODES: "Nombre de QR codes",
    UNKNOWN_NUMBER: "Inconnu",
    SCANNED_CODES: "QR codes récupérés",
    ERROR: {
      NO_DEVICE: "Pas de caméra disponible.",
      SCAN_FAILED: "Le scan du code QR a échoué",
      SCAN_ERRORED: "Une erreur s'est produite lors du scan",
    },
  },
  "key-export-qr-code": {
    PRINT_KEY: "Imprimer la clé au format QRCode",
    DOWNLOAD_KEY: "Télécharger la clé au format QRCode",
    PDF: {
      KEY_FOR: "Clé privée pour {{hcpId}}",
      PART_N: "Partie {{n}}",
      INSTRUCTIONS:
        "Ce document contient des informations sensibles. À stocker dans un endroit sûr et privé.",
    },
  },
  "key-pair-generation": {
    TITLE: "Bienvenue dans l'application Medispring",
    GENERATE_KEYS: "Générer ma paire de clés",
    CLOSE: "Fermer",
    SUCCESS_MESSAGE:
      "Votre paire de clés publique et privée a été générée avec succès et téléchargée dans le fichier ",
    SUCCESS_MESSAGE_ENCRYPTED:
      "Votre paire de clés publique et privée a été générée avec succès et sauvée dans votre profil.",
    FAILURE_MESSAGE_ENCRYPTED:
      "L'enregistrement de votre paire de clés dans votre profil a échoué !",
    FAILURE_GENERATION: "La génération de votre paire de clés a échoué.",
  },
  help: {
    TITLE: "Aide",
    ALERT_INDEXATION: "Une indexation est en cours, veuillez patienter.",
    TITLE_INDEXATION: "Indexation en cours",
    TITLE_NEW_VERSION: "Nouvelle mise à jour",
    HELPDESK: "HelpDesk",
    PHONE: "Téléphone",
    MAIL: "Email",
    PHONE_VALUE: "078/077050 (gratuit)",
    PMG: {
      PHONE_VALUE_OFF: "078/481.086",
      PHONE_VALUE_OFF_HOURS: "(sem: 18h-22h, WE: 8h-20h)",
      PHONE_VALUE_BUSINESS: "078/077.050",
      PHONE_VALUE_BUSINESS_HOURS: "(sem: heures de bureau)",
    },
    MAIL_VALUE: "support@medispring.be",
    FAQ: "FAQ",
    FAQ_VALUE: "http://support.medispring.be",
    FAQ_VIEW: "Voir les questions/réponses",
    YOUTUBE: "Consultez les vidéos de notre chaîne Youtube",
    YOUTUBE_VALUE: "https://www.youtube.com/channel/UCDMPh0v-Kq76Ph0KVDk2hIA",
    YOUTUBE_VIEW: "Voir la chaine youtube",
    VERSION: "Version de medispring",
    VERSION_REF: "Référence de version",
    VERSION_VIEW: "Voir les nouveautés",
    LINK: "Lien",
    LOCAL: "Local",
    REMOTE: "Remote",
    STATUS: "Statut",
    DOC_COUNT: "# Documents",
    LATEST_REPLICATION: "Dernière réplication",
    UPDATE: "Mise à jour",
    UPDATE_VIEW: "Voir les mises à jour",
    MANUAL: "Manuel d'aide Medispring",
    MANUAL_VALUE:
      "https://medispring-banner.s3-eu-west-1.amazonaws.com/fr/documents-annonces/Manuel_utilisateur.pdf",
    "ADMIN-TOOLS": "Outils d'administration",
    RELEASE_NOTES_VALUE:
      "https://medispring.zendesk.com/hc/fr/categories/360004554991-Notes-de-version",
    academic: {
      VERSION: "Version académique de medispring",
    },
  },
  "hcp-form": {
    PAYMENT_TYPE: "Type de perception : ",
    PAYMENT_TYPE_UNDEFINED: "Non définie",
    PAYMENT_TYPE_INDIVIDUAL: "Individuelle",
    PAYMENT_TYPE_CENTRALIZED: "Centralisée",
    ASSISTANTS_BANK_ACCOUNT: "Compte bancaire partagé des assistants",
    FIND_HCP: "Rechercher un intervenant",
    BASIC_INFO: "Informations générales",
    CONTACT: "Contact",
    FIRST_NAME: "Prénom",
    LAST_NAME: "Nom de famille",
    GENDER: "Genre",
    PROFESSION: "Profession",
    SPECIALITY: "Spécialité",
    SSIN: "Numéro national",
    NIHII: "Numéro INAMI",
    OTHER: "Autres",
    NOTES: "Notes",
    MASTER: "Maître de stage",
    ADDRESSES: "Adresses",
    ADDRESS: {
      TYPE: "Type",
      DESCRIPTION: "Description",
      STREET: "Rue",
      HOUSE_NUMBER: "Numéro",
      POSTBOX_NUMBER: "Boîte",
      POSTAL_CODE: "Code postal",
      CITY: "Ville",
      COUNTRY: "Pays",
      TELECOMS: "Télécoms",
      ADDRESS: "Adresse",
      PHONE: "Téléphone",
      EMAIL: "e-mail",
    },
    CREATE_HCP: "Créer l'intervenant",
    EDIT_HCP: "Modifier l'intervenant",
    EDIT_SUCCESS: "Intervenant mis à jour.",
    EDIT_FAILURE: "La mise à jour de l'intervenant a échoué.",
    CREATE_SUCCESS: "Intervenant créé.",
    CREATE_FAILURE: "La création de l'intervenant a échoué.",
    CREATE_TELECOM: "Nouveau télécom",
    CREATE_ADDRESS: "Nouvelle adresse",
    INSURABILITY: "Assurabilité",
    SOCIETY_NAME_ABR: "Nom de la société (abbréviation)",
    SOCIETY_NAME: "Nom de la société",
    MEDECIN_CONVENTION: "Convention",
    YES: "Oui",
    NO: "Non",
    PARTIAL: "Partiellement",
    IBAN: "IBAN",
    BIC: "BIC",
    CBE: "BCE",
    BANK_ACCOUNT: "IBAN",
    FACTURATION: "Facturation",
    HCP_LIST: "Liste des soignants",
    CBE_REQUIRED: "Le BCE est nécessaire pour envoyer des lots eFact.",
    IBAN_REQUIRED: "L'IBAN est nécessaire pour envoyer des lots eFact.",
    ASSISTANTS_IBAN_REQUIRED: "IBAN des assistants non spécifié.",
    ASSISTANTS_BIC_REQUIRED: "BIC des assistants non spécifié.",
    BIC_REQUIRED: "Le BIC est nécessaire pour envoyer des lots eFact.",
    GUARDPOST_SSIN: "Numéro national du PMG",
    GUARDPOST_NIHII: "Numéro INAMI du PMG",
    GUARDPOST_NAME: "Nom du PMG",
    GUARDPOST_IBAN: "Numero IBAN du PMG",
    GUARDPOST_BIC: "Numero BIC du PMG",
    GUARDPOST_CBE: "Numero CBE du PMG",
    VACCINNET_GROUPID: "Id Vaccinnet de la pratique de groupe",
    SPECIALITIES: {
      ADMINISTRATION: "Administration",
      CARE_GIVER: "Aide soignant",
      ANATOMOPATHOLOGY: "Anatomopathologie",
      ANESTHESIOLOGY: "Anesthésiologie",
      SOCIAL_WORKER: "Assistant(e) sociale",
      PHARMA_ASSISTANT: "Assistant pharmaceutico-technique",
      AUDIOLOGIST: "Audiologue",
      BACTERIOLOGY: "Bactériologie",
      TRUSS_ORTHOTIST: "Bandagiste-orthésiste",
      CLINICAL_BIOLOGY: "Biologie clinique",
      CARDIOLOGY: "Cardiologie",
      GENERAL_SURGERY: "Chirurgie générale",
      MAXILLOFACIAL_SURGERY: "Chirurgie Maxillo-faciale",
      PLASTIC_SURGERY: "Chirurgie plastique",
      STOMATOLOGICAL_SURGERY: "Chirurgie stomatologique",
      CARDIOVASCULAR_SURGERY: "Chirurgie cardio-vasculaire",
      ABDOMINAL_SURGERY: "Chirurgie abdominale",
      DENTISTRY: "Dentisterie",
      DERMATOLOGY: "Dermatologie",
      DIETETIC: "Diététique",
      ENDOCRINOLOGY: "Endocrinologie",
      OCCUPATIONAL_THERAPIST: "Ergothérapeute",
      GASTROENTEROLOGY: "Gastroentérologie",
      GENETIC: "Génétique",
      GERIATRICS: "Gériatrie",
      GYNECOLOGY: "Gynécologie / Obstétrique",
      HEMATOLOGY: "Hématologie",
      NURSE: "Infirmier(e)",
      NURSE_EDUCATOR: "Infirmier(e) éducatrice",
      PHYSIOTHERAPY: "Kinésithérapie",
      SPEECH: "Logopédie",
      ACUTE_MEDICINE: "Médecine Aigue",
      GENERAL_MEDICINE: "Médecine générale",
      persphysician: "Médecine générale",
      INTERNAL_MEDICINE: "Médecine interne",
      NUCLEAR_MEDICINE: "Médecine nucléaire",
      PHYSICAL_MEDICINE: "Médecine physique / Physiothérapie",
      EXPERTISE_MEDICINE: "Médecine d'expertise",
      INSURANCE_MEDICINE: "Médecine d'assurance",
      TRAVEL_MEDICINE: "Médecine du voyage",
      NEONATOLOGY: "Néonatalogie",
      NEPHROLOGY: "Néphrologie",
      NEUROSURGERY: "Neurochirurgie",
      NEUROLOGY: "Neurologie",
      NEUROPEDIATRICS: "Neuropédiatrie",
      NEUROPSYCHIATRY: "Neuropsychiatrie",
      NUTRITIONIST: "Nutritioniste",
      ONCOLOGY: "Oncologie",
      OPHTHALMOLOGY: "Ophtalmologie",
      ORTHOPEDICS: "Orthopédie",
      ORTHOPTISTE: "Orthoptiste",
      OTORHINOLARYNGOLOGY: "Oto-Rhino-Laryngologie",
      PEDIATRICS: "Pédiatrie",
      PHARMACOLOGY: "Pharmacologie",
      RESPIRATORY: "Pneumologie",
      PODIATRIST: "Podologue",
      PUBLIC_HEALTH_PREVENTION: "Prévention Santé Publique",
      PROSTHETIST: "Prothésiste",
      PSYCHIATRY: "Psychiatrie",
      MEDICAL_PSYCHOLOGY: "Psychologie médicale",
      RADIODIAGNOSTICS: "Radiodiagnostic / Imagerie médicale",
      RADIOTHERAPY: "Radiothérapie",
      RESUSCITATION: "Réanimation",
      RHEUMATOLOGY: "Rhumatologie",
      MIDWIFE: "Sage femme",
      STOMATOLOGY: "Stomatologie",
      EMERGENCY: "Urgences",
      UROLOGY: "Urologie",
      VIROLOGY: "Virologie",
      TOXICOLOGY: "Toxicologie",
      TRANSPORT: "Transport de malade",
      ALGOLOGY: "Algologie",
      GUARDHOUSE: "Poste de Garde",
      REVALIDATION: "Revalidation",
      SENOLOGY: "Sénologie",
      DIABETOLOGY: "Diabétologie",
      INFECTIOLOGY: "Infectiologie",
      KINESIOLOGY: "Kinésiologie",
      PHYSIOTHERAPYMICRO: "Kinésithérapie: microkiné",
      OSTHEAOPATIA: "Ostéophatie",
      PSYCHOTHERAPY: "Psychothérapie",
      OTHER: "Autre",
      HYPNOSIS: "Hypnose",
      PERSPHYSICIAN: "Médecin",
      NO_SPECIALITY: "Sans spécialité",
      HEPATOLOGY: "Hépatologie",
      WORKMEDECINE: "Médecine du travail",
      SCHOOLMEDECINE: "Médecine scolaire",
      PALLIATIVECARE: "Soins palliatifs",
      "NEUROPSYCHOLOGY": "Neuropsychologie",
      "PAYING_AGENCY": "Organisme payeur",
      "RECEPTION": "Accueil",
      "MOBILITY_IMPROVER": "Technologue en orthopédie (Améliorateur de mobilité)",
      "BANDAGIST_ORTHOSIOLOGIST": "Technologue en orthopédie (Orthosiologue bandagiste)",
      "SHOE_TECHNOLOGIST": "Technologue en orthopédie (Technicien en chaussures)",
      "ORAL_DENTAL_HYGIENIST": "Hygiéniste bucco-dentaire",
      "ORTHOPEDIST": "Orthopédiste",
      "BANDAGIST": "Bandagiste",
      "OPTICIAN": "Opticien",
      "AUDICIAN": "Audicien",
      "PHARMACIST_BIOLOGIST": "Pharmacien-biologiste clinicien",
      "PSYCHOLOGY": "Psychologie",
      "GERONTOLOGY_MASTER": "Master en gérontologie",
      "ORTHOPEDAGOGY_MASTER": "Master en orthopédagogie",
      "FAMILY_SCIENCE_BACHELOR": "Bachelier en sciences de la famille",
      "REHABILITATION_BACHELOR": "Bachelier en kinésithérapie et réadaptation",
      "PSYCHOMOTOR_THERAPY_MASTER": "Master en thérapie psychomotrice",
      "CLINICAL_ORTHOPEDAGOGIST": "Orthopédagogue clinicien",
      "PERSCAREGIVER": "Soignant",
      "PERSNURSE": "Infirmier",
      "APPLICATION": "Application",
      "DEPTPHYSIOTHERAPY": "Physiotherapie",
      "PERSADMINISTRATIVE": "Personnel admistratif",
      "PERSSOCIALWORKER": "Travailleur social",
      "DEPTEMERGENCY": "Urgences",
      "DEPTORTHOPEDY": "Orthopédie",
      "DEPTGASTROENTEROLOGY": "Gastroentérologie",
      "PERSEDUCATOR": "Educateur",
      "ORGPREVENTION": "Service de prévention",
      "SLEEP_MEDICINE": "Médecine du sommeil",
      "CHILD_AND_ADOLESCENT_PSYCHIATRY": "Pédopsychiatre / Psychiatrie de l'enfant et de l'adolescent",
      "PSYCHOGERIATRY": "Psychogériatrie",
      "CLINICAL_PSYCHOLOGIST": "Psychologue clinicien.ne",
      "INTENSIVE_CARE": "Soins intensifs",
      "MAJOR_BURNS": "Grands brûlés",
      "SHELTERED_LIVING": "Habitation protégée",
      "HOSPITAL": "Hôpital",
      "RETIRED_HOME": "Maison de repos",
      "PSYCHIATRIC_CARE_HOME": "Maison de soins psychiatriques",
      "PRIMARY_HEALTH_CARE_CENTER": "Maison médicale",
      "SPORTS_MEDICINE": "Médecine du sport",
      "TROPICAL_MEDICINE": "Médecine tropicale",
      "OBSTETRICS": "Obstétrique",
      "PATIENT": "Patient",
      "POLYCLINIC": "Polyclinique",
      "CARDIAC_SURGERY": "Chirurgie cardiaque",
      "HAND_SURGERY": "Chirurgie de la main",
      "PEDIATRIC_SURGERY": "Chirurgie pédiatrique",
      "THORACIC_SURGERY": "Chirurgie thoracique",
      "VASCULAR_SURGERY": "Chirurgie vasculaire",
      "EDUCATOR": "Educateur.trice",
      "PSYCHOMOTRICITY": "Psychomotricité",
      "INFORMAL_CAREGIVER": "Soignant informel"
    },
    INSURANCE_CLAIM: "Dossier d'Assurance",
  },
  "telecom-form": {
    NUMBER: "Télécom",
    TYPE: "Type",
    DESCRIPTION: "Description",
  },
  "user-form": {
    NAME: "Nom",
    LAST_NAME: "Nom de famille",
    FIRST_NAME: "Prénom",
    STUDENT_NUMBER: "Matricule étudiant",
    START_YEAR: "Année de démarrage",
    LOGIN: "Login",
    NAME_PLACEHOLDER: "Nom de l'utilisateur",
    EMAIL: "E-mail",
    EMAIL_PLACEHOLDER: "E-mail de l'utilisateur",
    ROLES: "Rôles",
    ROLES_PLACEHOLDER: "Rôles de l'utilisateur",
    PASSWORD: "Mot de passe",
    CONFIRM_PASSWORD: "Confirmation du mot de passe",
    ACTIVE: "Actif",
    CREATE_USER: "Créer l'utilisateur",
    EDIT_USER: "Modifier l'utilisateur",
    EDIT_SUCCESS: "Utilisateur mis à jour.",
    EDIT_FAILURE: "La mise à jour de l'utilisateur a échoué.",
    CREATE_SUCCESS: "Utilisateur créé.",
    CREATE_FAILURE: "La création de l'utilisateur a échoué.",
    INVALID_ROLES_REMOVED:
      "Des rôles inconnus de l'utilisateur ont été supprimés ({{roles}}).",
    SAME_ADR_SEARCH: {
      title: "Activer la recherche sous le même toit",
    },
    tooltip: {
      MS_ADMIN:
        "Le rôle d’administrateur donne droit à la création et à la modification des utilisateurs ainsi qu'un accès aux statistiques et à la comptabilité de tous les utilisateurs.",
      MS_PRACTICIAN:
        "Le rôle de praticien donne accès aux données médicales et à la boîte de réception.",
      MS_SECRETARY:
        "Le rôle de secrétaire donne accès aux fiches administratives des patient et à la boîte de réception.",
      MS_USER_MANAGER:
        "Le rôle de gestionnaire des utilisateurs donne droit à la création et à la modification des utilisateurs.",
      MS_PRESCRIPTOR:
        "Le rôle de prescripteur donne accès aux traitements actifs des patients et à la création d’ordonnances en attente.",
      MS_STUDENT_PRACTICIAN:
        "Le rôle de praticien étudiant donne accès aux données médicales des patients créés par l'étudiant et à la boîte de réception.",
    },
    HUB_REGION: "Région Hubs",
    WAL: "Wallonie",
    FLA: "Flandres",
    BRU: "Bruxelles",
    RSW: "RSW",
    RSB: "RSB",
    COZ: "Cozo + Vitalink",
    AUTOMATIC_SUMEHR:
      "Envoi automatique des nouveaux SUMEHR à la fermeture des patients",
    YES_DONT_SHOW: "Oui, sans demander confirmation",
    YES_SHOW: "Oui, toujours demander confirmation",
    No: "Non",
  },
  "location-selector": {
    CHOOSE_LOCATION: "Sélectionnez votre entité",
    RESPONSIBLE: "Responsable",
    VALIDATE: "Valider",
    CANCEL: "Annuler",
  },
  "eh-dmg": {
    ALL: "Toutes",
    INSCRIPTION: "Inscription",
    IBAN: "IBAN",
    BIC: "BIC",
    DATE_ACTIVATION: "Date d'activation",
    VALIDATE: "Demande d'inscription",
    CHECK: "Vérifier l'inscription",
    SEVEN_MUTUALITY: "J'utilise le même compte bancaire pour les 7 mutuelles",
    COND_TITLE: "Conditions d'utilisation de MyCareNet et DMG",
    READ_AND_ACCEPTED:
      "J'ai lu et j'accepte les conditions d'utilisation de MyCareNet et DMG.",
    CONDA:
      "Dès qu'il fait le choix d'utiliser MyCareNet pour la gestion des DMG, le prestataire de soins s'engage à\u00A0:",
    CONDA1:
      "Ne pas envoyer de notification MycareNet pour un DMG qu'il a déjà attesté au patient (via le code nomenclature 102771).",
    CONDA2:
      "Ne plus attester de DMG via le code nomenclature 102771 à partir du lendemain de la date d'inscription, ni pour les ouvertures, ni pour les prolongations.",
    CONDA3:
      "Utiliser l'envoi des notifications MyCareNet pour toute ouverture d'un nouveau DMG.",
    CONDA4:
      "Envoyer les notifications MyCareNet dans les 7 jours ouvrables maximum après la prestation (consultation ou visite) donnant droit au DMG.",
    CONDB:
      "Pour un prestataire de soins inscrit dans MyCareNet pour la gestion du DMG, l'organisme assureur s'engage à\u00A0:",
    CONDB1:
      "Tenir compte de la notification d'inscription envoyée par un prestataire de soins comme engagement du prestataire à utiliser le système MyCareNet pour la gestion de tous ses DMG.",
    CONDB2:
      "À partir de la date d'inscription à MyCareNet\u00A0: payer dans les 30 jours toutes les prolongations administratives pour l'année en cours et l'année précédente, lorsque les conditions sont remplies.",
    CONDB3:
      "Faire une prolongation automatique accélérée pour tous les DMG pour lesquels les conditions sont remplies, dans les 30 jours à partir de la réception de l'attestation de la prestation (consultation/visite).",
    CONDB4:
      "Payer les ouvertures de DMG dans les 30 jours à partir de la réception de la notification MyCareNet si les conditions sont remplies.",
    CONDB5:
      "Refuser les attestations DMG relatives à des prestations dont la date est ultérieure à la date d'inscription dans MyCareNet.",
    CONDB6: "Envoyer les messages de clôture et de prolongation à bon escient.",
  },
  "eh-auth": {
    MISSING_USER: "Utilisateur courant manquant.",
    MISSING_EH_CERTIFICATE: "Certificat eHealth manquant.",
    MISSING_NO_HCP: "Aucun intervenant.",
    CONNECTION_SUCCESS: "Connexion aux services eHealth réussie.",
    MISSING_KEY_STORE_ID: "Session expirée.",
    NOT_CONNECTED:
      "Vous n'êtes pas connecté aux services eHealth.</br>{{errorMessage}}",
    CONNECTED: "Vous êtes connecté aux services eHealth.",
    CONNECTED_NO_NIHII:
      "Vous êtes connecté aux services eHealth. Aucun n° inami renseigné dans votre fiche utilisateur",
    SOMETHING_WRONG_HAPPENED:
      "Vérifiez votre certificat et/ou mot de passe.</br>Vérifiez votre connexion internet.",
    BAD_PASSWORD: "Mot de passe incorrect.",
    MISSING_EH_PASSWORD: "Le mot de passe est vide.",
    ERROR_CERT_INFO: "Impossible d'obtenir les informations du certificat.",
    CERTIFICATE_ALMOST_EXPIRED:
      "Attention, votre certificat eHealth expire dans moins d'un mois.",
    CERTIFICATE_EXPIRED:
      "Votre certificat eHealth est expiré, vous devez le renouveler.",
    NOT_ALLOWED:
      "Le certificat eHealth actuel ne permet pas cette action. Login mode: '{{mode}}'; Guard post flow: '{{guardFlow}}'",
    NOT_ALLOWED_SECTOR: "Non disponible pour ce secteur",
  },
  "pdf-export": {
    EXPORT: "Télécharger le pdf",
    EXPORT_FILE: "Télécharger les documents (.zip)",
    EXPORT_INP: "Export en cours",
    TITLE: "Export du dossier patient au format PDF",
    NO_DATA: "Pas de données",
    note: "Note",
    "vaccination-date": "Date de vaccination",
    "start-date": "Date de début",
    "end-date": "Date de fin",
    "value-date": "Date valeur",
    "general-data": "Informations générales",
    address: "Adresse(s)",
    status: "statut",
    "insurability-data": "Données d'assurabilité",
    "last-modification": "Dernière modification",
  },
  _ENUM_: {
    "patient-blood-type": {
      "blood-type": {
        A: "A",
        B: "B",
        O: "O",
        AB: "AB",
        UNDETERMINED: "Non déterminé",
      },
      "sub-blood-type": {
        CCDee: "CCDee",
        CcDee: "CcDee",
        ccDEE: "ccDEE",
        CcDEe: "CcDEe",
        ccDee: "ccDee",
        Ccdee: "Ccdee",
        ccDEe: "ccDEe",
        UNDETERMINED: "Non déterminé",
      },
      rhesus: {
        PLUS: "Rh +",
        MINUS: "Rh -",
        UNDETERMINED: "Non déterminé",
      },
    },
    telecom: {
      type: {
        mobile: "Téléphone portable",
        phone: "Téléphone fixe",
        email: "E-mail",
        fax: "Fax",
        skype: "Skype",
        im: "Messagerie instantanée",
        medibridge: "MediBRIDGE",
        ehealthbox: "eHealthBox",
        apicrypt: "APICRYPT",
        web: "Web",
        print: "Imprimante",
        disk: "Disque",
      },
    },
    address: {
      type: {
        temporary: "Temporaire",
        postal: "Postale",
        diplomatic: "Diplomatique",
        reference: "Référence",
        home: "Domicile",
        work: "Lieu de travail",
        vacation: "Vacances",
        hospital: "Hôpital",
        clinic: "Clinique",
        hq: "Quartier général",
        other: "Autre",
      },
    },
    hcparty: {
      gender: {
        male: "Homme",
        female: "Femme",
        unknown: "Inconnu",
        indeterminate: "Indéterminé",
        changed: "Changé",
        changedToMale: "Changé vers homme",
        changedToFemale: "Changé vers femme",
        m: "Homme",
        f: "Femme",
      },
    },
    "health-element": {
      nature: {
        medical: "Problème médical",
        surgical: "Problème chirurgical",
        gynecological: "Problème gynéco-obstétrical",
        allergy: "Allergie",
        familyrisk: "Antécédent familial",
        risk: "Facteur de risque médical",
        socialrisk: "Facteur de risque social",
        adr: "Intolérance",
        null: "Inconnue",
      },
      status: {
        "active-relevant": "Actif (pertinent)",
        "active-irrelevant": "Actif (sans pertinence)",
        inactive: "Antécédent",
        archived: "Archivé",
      },
      certainty: {
        probable: "Probable",
        proven: "Prouvé",
        unprobable: "Douteux",
        excluded: "Exclu",
        undefined: "Indéfini",
      },
      severity: {
        normal: "Normale",
        verylow: "Très faible",
        low: "Faible",
        high: "Haute",
        veryhigh: "Très haute",
      },
      temporality: {
        chronic: "Chronique",
        subacute: "Subaigu",
        acute: "Aigu",
        remission: "En rémission",
      },
      laterality: {
        left: "Gauche",
        right: "Droit",
        bilateral: "Bilatéral",
      },
      carepath: {
        "carepath-renalfailure": "Insuffisance rénale",
        "carepath-prediabetes": "Pré-trajet diabète",
        "carepath-diabetes2": "Diabète de type 2",
      },
      confidentiality: {
        "not-confidential": "Non confidentiel",
        "no-export": "Pas d'export (visible à ma pratique de groupe)",
      },
    },
    role: {
      MS_ADMIN: "Administrateur",
      MS_PRACTICIAN: "Praticien",
      MS_SECRETARY: "Secrétaire",
      MS_USER_MANAGER: "Gestionnaire des utilisateurs",
      MS_PRESCRIPTOR: "Prescripteur",
      MS_STUDENT_PRACTICIAN: "Praticien étudiant",
    },
    prescription: {
      SPE: "Spécialités",
      OTC: "OTC",
      MAP: "Magistrales personnelles",
      MAF: "Magistrales FTM",
      DCI: "DCI",
      CLA: "Classe",
      PER: "Personnel",
    },
    period: {
      d: "Jours",
      wk: "Semaines",
      mo: "Mois",
      a: "Années",
      age: "Âge",
    },
    period_simple: {
      d: "jour",
      wk: "semaine",
      mo: "mois",
      a: "an",
    },
    period_simple_posology_1: {
      d: "tous les jours",
      wk: "toutes les semaines",
      mo: "tous les mois",
      a: "chaque année",
    },
    period_simple_posology_n: {
      d: "tous les {{n}} jours",
      wk: "toutes les {{n}} semaines",
      mo: "tous les {{n}} mois",
      a: "tous les {{n}} ans",
    },
    filter: {
      planOfAction: "Plan d'action\u00A0:",
      healthElement: "Élément de soin\u00A0:",
      medication: "Traitement\u00A0:",
      diagnosis: "Diagnostic\u00A0:",
      speciality: "Spécialité\u00A0:",
      date: "Dates\u00A0:",
      vaccine: "Vaccin\u00A0:",
      classification: "Classification\u00A0:",
    },
    health_element_search: {
      ATC: "Atc",
      MEDICATION: "Spécialité",
      PROBLEM: "Problème méd.",
      PROCEDURE: "Procédure chir.",
    },
    health_element_history_filter: {
      ACTIVE: "Actif",
      INACTIVE: "Antécédents",
      RISK: "Facteurs de risque",
      PREVENTION: "Prévention",
      ARCHIVED: "Archivés",
      ALL: "Tous",
    },
    task_time_unit: {
      day: "jours",
      week: "semaines",
      month: "mois",
      year: "ans",
    },
    task_time_unit_singular: {
      day: "jour",
      week: "semaine",
      month: "mois",
      year: "an",
    },
    patient: {
      deactivation: {
        deceased: "Décédé",
        moved: "A déménagé",
        other_doctor: "A changé de médecin",
        retired: "En maison de retraite",
        no_contact: "Plus de contact depuis longtemps",
        unknown: "Inconnue",
        none: "Aucune",
      },
      thirdpartytypes: {
        cpas: "CPAS",
        police: "Police",
        nationaldefense: "Défense nationale",
        luxembourgnationalsecurity: "N° de sécurité nationale luxembourgeoise",
        other: "Autre",
      },
      healthcareparty: {
        doctor: "Docteur",
        referral: "Référence",
        medicalhouse: "Maison médicale",
        retirementhome: "Maison de retraite",
        hospital: "Hôpital",
        other: "Autre",
        referringphysician: "Médecin traitant",
      },
      medicalhousestop: {
        dead: "Décès",
        end_inscr_by_patient_moved:
          "Fin inscription par le patient - Déménagement",
        end_inscr_by_patient_abroad:
          "Fin inscription par le patient - Départ étranger",
        end_inscr_by_patient_home: "Fin inscription par le patient - Home",
        end_inscr_by_patient_transferal_other_mh:
          "Fin inscription par le patient - Transfert autre MM",
        end_inscr_by_patient_not_happy:
          "Fin inscription par le patient - Conflit-Mecontent",
        end_inscr_by_patient_other: "Fin inscription par le patient - Divers",
        end_insrc_by_mh_radiation: "Fin inscription par la MM - Radiation",
        end_insrc_by_mh_other: "Fin inscription par le MM - Autre",
        end_agreement_denounced_by_non_registered_mutual:
          "Fin accord dénoncé par la mutuelle - Non inscrit",
        end_agreement_denounced_by_non_registered_mh:
          "Fin accord dénoncé par la MM - Non inscrit",
      },
    },
    guardPost_access_log: {
      "24h": "Dernières 24h",
      "48h": "Dernières 48h",
      "72h": "Dernières 72h",
      custom: "Date",
      all: "Tous",
      ready: "Prêt à être envoyé",
      action_required: "Action nécessaire",
      sent: "Déjà envoyé",
    },
  },
  "patient-form": {
    PRINT: "Imprimer étiquette",
    RESET: "Réinitialiser",
    CLOSE: "Annuler",
    CHOOSE: "Choisir ce patient",
    CREATE_NEW_PATIENT: "Créer un nouveau patient",
    CREATE_SUCCESS: "Patient crée avec succès.",
    FOUND_PATIENT: "Patient(s) trouvé(s)",
    NO_PATIENT: "Ce patient n'existe pas encore.",
    WARNING_DUPLICATE:
      "Attention : un patient ayant des données semblables ou identiques existe déjà.",
    ERROR_DUPLICATE:
      "Un patient existe déjà avec cette combinaison de nom, prénom et date de naissance, ouvrez le dossier du patient",
    READ_DATA_FROM_EID: "Données lues depuis la carte eID",
    _comment: "concerns both patient-edit-form and patient-create-form",
    FIRST_NAME: "Prénom",
    LAST_NAME: "Nom de famille",
    GENDER: "Genre",
    SSIN: "Numéro national",
    CARDNUMBER: "Carte ISI+/eID",
    SSIN_TAKEN: "Un patient avec ce numéro national existe déjà",
    SSIN_WRONG_FORMAT: "Le NISS est mal formaté",
    SSIN_IS_NOT_IN_USE: "Le NISS ne correspond à aucun patient",
    SSIN_UNKNOWN_ERROR: "Une erreur inconnue s'est produite",
    REFERRING_PHYSICIAN: "Médecin traitant",
    PREFERRED_USER: "Utilisateur gestionnaire",
    EID: "eID",
    EID_EXPIRATION_DATE: "eID date d'expiration",
    ACTIVE: "Actif",
    DEACTIVATION: "Raison de désactivation",
    NOTES: "Notes",
    ADDRESSES: "Adresses",
    AN_ADDRESS: "Adresse",
    ADDRESS: {
      TYPE: "Type",
      DESCRIPTION: "Description",
      STREET: "Rue",
      HOUSE_NUMBER: "Numéro",
      POSTBOX_NUMBER: "Boîte",
      POSTAL_CODE: "Code postal",
      CITY: "Ville",
      COUNTRY: "Pays",
      TELECOMS: "Télécoms",
    },
    WORK_ADDRESS: "Adresse de travail",
    DATE_OF_BIRTH: "Date de naissance",
    DECEASED: "Décédé",
    PLACE_OF_BIRTH: "Lieu de naissance",
    DATE_OF_DEATH: "Date de décès",
    PLACE_OF_DEATH: "Lieu du décès",
    NATIONALITY: "Nationalité",
    LANGUAGE: "Langue parlée",
    EXTERNAL_ID: "Identifiant externe",
    PROFESSIONS: "Professions",
    SPOUSE_NAME: "Nom de l'époux",
    PARTNER_NAME: "Nom du conjoint",
    CREATE_PATIENT: "Créer le patient",
    EDIT_PATIENT: "Modifier le patient",
    SUBMIT: "Soumettre le Formulaire",
    NO_CODE: "Le code que vous avez entré ne correspond à aucune mutuelle.",
    INVALID_CODE: "Sans code valide, les eFact et eAttest seront désactivées.",
    UNKNOWN_OA_NUMBER: "Mutuelle inconnue",
    INSURABILITY: {
      INSURABILITY_TITLE: "Assurabilité",
      ID_NUMBER: "Numéro d'identification",
      TITULARY_ID: "ID titulaire",
      START_DATE: "Début de validité",
      END_DATE: "Fin de validité",
      STATUS: "Statut",
      TC1: "CT1",
      TC2: "CT2",
      PAYMENT_APPROVAL: "Approbation de paiement",
      BIM: "Status Preferentiel (BIM)",
      PAYMENT_BY_IO: "Tiers Payant",
      CHOOSE: "Choix assurance",
      AMU: "AMU",
    },
    MEDICAL_HOUSE: {
      TITLE: "Maison médicale",
      NAME: "Nom",
      START_DATE: "Date de début",
      END_DATE: "Date de fin",
      BILL_START_DATE: "1ère facture",
      BILL_END_DATE: "Fin de facture",
      DOCTOR: "Médecin",
      PHYSIOTHERAPIST: "Kiné",
      NURSE: "Infirmière",
      IS_MEDICAL_HOUSE: "Inscrit en maison médicale",
      IS_TRIAL: "En période d'essai",
    },
    HOSPITALIZATION: {
      TITLE: "Hospitalisation",
      HOSPITAL: "Hôpital",
      ADMISSIONS_DEPARTMENT: "Service d'admission",
      ADMISSIONS_DATE: "Date d'admission",
    },
    THIRD_NUMBER: "Numéro tiers",
    THIRD_PARTY_TYPE: "Type du tiers",
    THIRD_PARTY_NUMBER: "Numéro",
    NAME: "Nom",
    INAMI: "Inami",
    SPECIALITY: "Spécialité",
    TYPE: "Type",
    PHCP: "Soignant",
    ADD_PHCP: "Ajouter un soignant",
    NO_PHCP: "Il n'y a aucun soignant associé à ce patient.",
    DELETE: "Supprimer",
    HCP_ALREADY_PRESENT:
      "Ce soignant est déja présent dans la liste des soignants",
    INSURANCE_CLAIM: {
      PRIVATE: "Privé",
      PROFESSIONAL: "Professionnel",
      TITLE: "Dossier d'Assurance",
      RETURN: "Retourner",
      ADD: "+ Dossier ",
      TYPE: "Type d'Assurance",
      PATIENT_IDENTIFICATION: "N° de client du patient",
      RECORD_NUMBER: "N° de Dossier",
      OPENING_DATE: "Date d'Ouverture",
      CLOSING_DATE: "Date de Clôture",
      ADDITIONAL_INFORMATION: "Note",
      INSURANCE_COMPANY: {
        TITLE: "Compagnie d'Assurance",
        NAME: "Nom",
        ADDRESS_INFORMATION: {
          TITLE: "Information d'Adresse",
          STREET: "Rue",
          CITY_CODE: "Code Postal",
          CITY: "Ville",
          COUNTRY: "Pays",
        },
      },
      CONTACT_PERSON: {
        TITLE: "Personne de Contact ",
        NAME: "Nom",
        EMAIL_ADDRESS: "Adresse Email ",
        TEL: "N° de Téléphone",
        FAX: "N° de Fax",
        ADDRESS_INFORMATION: {
          TITLE: "Information d'Adresse",
          STREET: "Rue",
          CITY_CODE: "Code Postal",
          CITY: "Ville",
          COUNTRY: "Pays",
        },
      },
    },
    WRONG_SSIN_CARD_NUMBER_COMBINATION:
      "La combinaison SSIN/Numéro de carte est invalide. Veuillez vérifier la validité de la carte.",
    PATIENT_DECEASED: "Le patient est décédé",
    SSIN_ALREADY_PRESENT:
      "Ce NISS figure déjà dans votre base de données patients",
    SSIN_NOT_IN_REGISTRY: "Ce NISS ne figure pas dans le registre national",
    SSIN_REPLACED: "Ce NISS a été mise à jour. Nouveau NISS: ",
    REPLACE_SSIN: "Utiliser ce niss.",
    PATIENT_SEARCH: "Rechercher le patient",
    OPEN_PATIENT: "Ouvrir le dossier patient",
    NO_EHEALTH_CONNECTION:
      "Vous devez être connecté aux services eHealth pour effectuer une recherche du patient sur base de son NISS",
    SEARCH_NISS_BTN: "Rechercher le NISS",
    TO_MANY_SEARCH_RESULTS:
      "trop de résultats, veuillez affiner votre recherche",
    CHECK_NISS_BTN: "Vérifier le NISS",
    NISS_UP_TO_DATE: "Aucune modification détectée.",
    BAD_COUNTRY_NOTIFICATION: {
      PART1: "Le pays de l'adresse : ",
      PART2: " du patient est mal enregistré.",
      NOTYPE: "Le pays d'une des adresses du patient est mal enregistré.",
    },
    BAD_NATIONALITY_NOTIFICATION:
      "La nationalité du patient est mal enregistré. \n",
  },
  "rn-consult": {
    "phonetic-search": {
      title: "Recherche d'un NISS",
      search: "Rechercher",
      tolerance: "Tolérance",
      cancel: "Annuler",
      "change-search": "Modifier la recherche",
      OPEN_BIS: "Demander un numéro BIS",
      "skip-step": "Passer cette étape",
      "refine-your-search": "Veuillez affiner votre recherche",
      error: "Erreur lors de la demande",
      MONTH: "mois",
      YEAR: "année(s)",
      OK: "Ok",
      NO_RESULT: "Pas de résultat trouvé",
    },
    SSIN_EXISTS: "Ce NISS est déjà utilisé pour un patient dans Medispring",
    OK: "Ok",
    country: "Pays",
    city: "Ville",
    RN: "Niss",
    RAD: "Niss Radié",
    RAN: "Niss annulé",
    BIS: "Niss biss",
    NR: "",
    CONFIRMATION_MODAL: {
      TITLE: "La dernière verification du NISS est trop récente.",
      CONFIRM: "Voulez-vous forcer la vérification ?",
      YES: "Oui",
      NO: "Non",
    },
  },
  "request-bis-form": {
    TITLE: "Demande d'un numéro BIS ",
    IDENTITY: "Identité",
    GENDER: "Genre",
    FIRST_NAME: "Prénom",
    LAST_NAME: "Nom de famille",
    DATE_OF_BIRTH: "Date de naissance",
    NATIONALITY: "Nationalité",
    BUSINESS_ERROR: "Erreur fatale ({{ERROR_CODE}}: {{ERROR_DESCRIPTION}})",
    address: {
      STREET: "Rue",
      POSTAL_CODE: "Code postal",
      CITY: "Ville",
      COUNTRY: "Pays",
      TYPE: "Type d'adresse",
    },
    "date-info": {
      p1: "Lorsque vous effectuez une demande de création de numéro BIS, si le patient ne connait pas sa date de naissance exacte, vous pouvez saisir une date de naissance incomplète.",
      p2: "Une date incomplète est une date qui ne contient que l'année ou le mois et l'année.",
      p3: " Attention, si vous indiquez une date incomplète, il vous faudra compléter l'entièreté des informations concernant le lieu de résidence, pays, ville, code postal si le lieu est en Belgique ainsi que le nom de la rue.",
    },
    BIRTH_MID: "Lieu de naissance",
    RES_MID: "Lieu de résidence",
    ACCEPT: "Accepter",
    SAVE: "Enregistrer",
    CANCEL: "Annuler",
    REGISTRATION_TYPE: {
      BIRTH_DATE: "Basée sur la date de naissance",
      ADDRESS_OUTSIDE_BELGIUM:
        "Basée sur une adresse de résidence hors Belgique",
      ADDRESS_IN_BELGIUM: "Basée sur une adresse de résidence en Belgique",
    },
    ADDRESS_TYPE: {
      TEMPORARY_STAY: "Séjour temporaire",
      PUBLIC_CENTER_FOR_SOCIAL_ACTION: "Centre Public d’Action Sociale",
      HOST_FAMILY: "Famille d'accueil",
      INSTITUTION_RECEPTION:
        "Institution/accueil (soin/personnes âgées/détention/demandeur d’asile)",
      HOSPITAL: "Hôpital",
      FRIENDS_FAMILY: "Amis/Famille",
      EMPLOYER: "Employeur",
      UNKNOWN: "Inconnu",
    },
    PATIENT_GENERATED_BIS_MODAL: {
      OK: "OK",
      GIVE_BIS_TO_PATIENT:
        "Numéro BIS à communiquer au patient : <b>{{BIS_NUMBER}}</b>. Veuillez prendre note des documents qui vous ont été montrés  par  le  patient  (par  exemple :  numéro  de  passeport  et  lieu  de  délivrance  du  passeport) dans la note administrative du patient.",
    },
  },
  "request-bis-modal": {
    TITLE: "Qu’est-ce qu’un numéro BIS ?",
    LIST_LABEL:
      "Un NISS (numéro d’identification unique à la Sécurité sociale) peut-être",
    NATIONAL_REGISTRATION_NUMBER: "un numéro de registre national",
    BIS_NUMBER:
      "un numéro Bis (attribué aux travailleurs étrangers qui ne résident pas en Belgique)",
    WARNING_HEADING: "Attention",
    WARNING_TEXT:
      "La création d’un numéro BIS n’est pas un acte anodin car ce numéro sera ensuite distribué avec de nombreux autres acteurs (de la santé, de la sécurité sociale, SPF Finances, notaires,...).",
    REQUIRE_PATIENT_PRESENCE:
      "Ne créer un numéro BIS qu’en présence du patient, <b>jamais par téléphone, par mail ni par sms.</b> Il est aussi nécessaire de transmettre ce numéro BIS au patient",
    IDENTIFICATION_DATA:
      "Soyez particulièrement attentif aux données d’identifications que vous allez saisir, si possible, <b>basez-vous sur des documents fournis par le patient.</b> <b>Si vous disposez d'un document d'identité lié à ce dossier</b> (par exemple : passeport, carte d'identité étrangère, acte de naissance, permis de conduire,..), <b>vous êtes invités à en transmettre une copie par mail</b> à l'adresse <a target='_blank', href='mailto:identification@ksz-bcss.fgov.be'>identification@ksz-bcss.fgov.be</a> en faisant référence dans le mail au numéro BIS attribué et en indiquant qu'il s'agit de document d'identité.",
    CONTACT:
      "En cas de problème, contactez la « cellule identification de la BCSS » :<a target='_blank', href='mailto:identification@ksz-bcss.fgov.be'>identification@ksz-bcss.fgov.be</a>",
  },
  "patient-search": {
    FIND_PATIENT: "Trouver un patient",
    SPOUSE_NAME: "Nom de l'époux",
    DEFAULT_FILTER: "Défaut",
    EXTERNAL_ID_FILTER: "N° Dossier",
    FILTER: "Filtre",
  },
  "hcparty-search": {
    FIND_HCPARTY: "Sélectionner un OP",
  },
  kendo: {
    dialog: {
      closeTitle: "Fermer",
    },
    dropdownlist: {
      noDataText: "Aucun élément",
    },
    multiselect: {
      noDataText: "Aucun élément",
    },
    grid: {
      groupPanelEmpty: "TODO (groupPanelEmpty)",
      pagerFirstPage: "Première page",
      pagerLastPage: "Dernière page",
      pagerPreviousPage: "Page précédente",
      pagerNextPage: "Page suivante",
      pagerItems: "éléments",
      pagerOf: "de",
      pagerItemsPerPage: "éléments par page",
      pagerPage: "Page",
      filter: "Filtrer",
      filterEqOperator: "est égal à",
      filterNotEqOperator: "est différent de",
      filterIsNullOperator: "est nul",
      filterIsNotNullOperator: "n'est pas nul",
      filterIsEmptyOperator: "est vide",
      filterIsNotEmptyOperator: "n'est pas vide",
      filterStartsWithOperator: "commence par",
      filterContainsOperator: "contient",
      filterNotContainsOperator: "ne contient pas",
      filterEndsWithOperator: "finit par",
      filterGteOperator: "est plus grand ou égal à",
      filterGtOperator: "est plus grand que",
      filterLteOperator: "est plus petit ou égal à",
      filterLtOperator: "est plus petit que",
      filterIsTrue: "Oui",
      filterIsFalse: "Non",
      filterBooleanAll: "Tous",
      filterAfterOrEqualOperator: "est après ou égal à ",
      filterAfterOperator: "est après",
      filterBeforeOperator: "est avant",
      filterBeforeOrEqualOperator: "est avant ou égal à",
      filterFilterButton: "Filtrer",
      filterClearButton: "Effacer",
      filterAndLogic: "ET",
      filterOrLogic: "OU",
      isLoading: "Chargement",
      columnMenu: "Menu de la colonne",
      columns: "Colonnes",
      lock: "Verouiller",
      unlock: "Déverouiller",
      sortAscending: "Trier par ordre croissant",
      sortDescending: "Trier par ordre décroissant",
      sortable: "Triable",
      sortedAscending: "Trié par ordre croissant",
      sortedDescending: "Trié par ordre décroissant",
      sortedDefault: "Trié par défaut",
      columnsApply: "Appliquer",
      columnsReset: "Effacer",
      noRecords: "Aucun résultat",
    },
    calendar: {
      today: "Aujourd'hui",
    },
    datepicker: {
      today: "Aujourd'hui",
      toggle: "Sélectionner une date",
    },
    multiviewcalendar: {
      today: "Aujourd'hui",
    },
    dateinput: {
      decrement: "TODO",
      increment: "TODO",
    },
    timepicker: {
      now: "Maintenant",
      cancel: "Annuler",
      accept: "Sélectionner",
      acceptLabel: "Sélectionner",
      cancelLabel: "Annuler",
      nowLabel: "Maintenant",
      toggle: "Sélectionner une heure",
    },
    upload: {
      invalidFileExtension: "Extension de fichier invalide!",
      cancel: "Annuler",
      clearSelectedFiles: "Supprimer les fichiers sélectionés",
      dropFilesHere: "Glissez des fichiers ici",
      headerStatusUploaded: "Envoi terminé",
      headerStatusUploading: "Envoi en cours",
      invalidFiles: "Fichiers invalides",
      invalidMaxFileSize: "Taille du fichier trop grande",
      invalidMinFileSize: "Taille du fichier trop petite",
      remove: "Supprimer",
      retry: "Réessayer",
      uploadSelectedFiles: "Envoyer les fichiers sélectionés",
      select: "Choisir un fichier",
    },
    slider: {
      decrement: "Diminuer",
      increment: "Augmenter",
      dragHandle: "TODO",
      gerPage: "TODO",
      gerItemsPerPage: "TODO",
      gerOf: "TODO",
      gerItems: "TODO",
    },
    combobox: {
      noDataText: "Pas de résultats trouvés.",
    },
    numerictextbox: {
      decrement: "Diminuer de 1",
      increment: "Augmenter de 1",
    },
  },
  "patient-information": {
    ADDRESS: "Adresse",
    PHONE: "Téléphone",
  },
  "payment-types": {
    cash: "Liquide",
    wired: "Virement",
    debitcard: "Bancontact",
  },
  "patient-insurability": {
    MISSING_SSIN: "Le patient n'a pas de N° national",
    MISSING_DATA_MEDICAL_HOUSE: "Pas de maison médicale",
    BAD_SSIN: "N° national invalide",
    MDA_ERROR:
      "Nous n'avons pas reçu de réponse dans le délai imparti (erreur code 500).Essayez d'envoyer une nouvelle demande ou attendez jusqu'à ce que le service soit disponible à nouveau.",
    SSIN: "N° national",
    IO: "N° de mutuelle",
    IO_MEMBERSHIP: "N° d'affiliation mutualiste",
    HOSPITALIZED: "Hospitalisation",
    MEDICAL_HOUSE: "Maison médicale",
    ON: "le",
    CHECK: "Vérifier l'assurabilité",
    CHECK_DATE: "À la date du",
    CHECK_START_PERIOD: "Période du",
    CHECK_END_PERIOD: "au",
    CHECK_BUTTON: "Vérifier",
    ADDITIONAL_INFO: "Informations supplémentaires",
    BEWARE: "Attention",
    ADMISSION_DATE: "Date d'admission\u00A0: ",
    ADMISSION_SERVICE: "Service d'admission\u00A0: ",
    HOPITAL: "Hôpital\u00A0: ",
    CONTRAT_START: "Admis(e) le",
    CONTRAT_END: "jusqu'au",
    MEDICAL_HOUSE_CONTRAT: "Contrat en maison médicale",
    CLOSED_BEFORE:
      "Dossier clôturé avant la date de consultation {{date}} (décès, changement d'affiliation,…)",
    DEATH_DATE: " - Patient décédé en date du {{date}}",
    BIRTH_DATE: " - Patient né le {{date}}",
    START_AFTER:
      "Dossier débute après la date de consultation {{date}} (nouveau né, nouvel affilié,…)",
    NOT_CONFORM: "Non conforme (pas de médecin)",
    MKI: "MKI (médécin + kiné + infirmier)",
    MNI: "MNI (médécin + infirmier)",
    MKN: "MKN (médécin + kiné)",
    MNN: "(prise en charge médicale)",
    CONTRAT_TYPE: "Type de contrat",
    ETENDUE: "Étendue\u00A0: ",
    UNKNOW: "Inconnu",
    ERROR_CODE: "Code d'erreur\u00A0: ",
    ERROR_MESSAGE: "Message d'erreur\u00A0:",
    ERROR_SOURCE: "Source de l'erreur\u00A0: ",
    CHECK_SUCCESS: "Données d'assurabilité obtenues avec succès",
    ERROR_CHECK: "Les données du membre n'ont pas pu être consultées",
    ERROR_CHECK_2: "Erreur lors de la vérification d'assurabilité",
    INSURABILITY_TITLE: "Données du membre",
    LABELS: {
      NAME: "Nom",
      MUT_NAME: "Mutuelle",
      TITULARY_ID: "ID titulaire",
      START_DATE: "Début de validité",
      END_DATE: "Fin de validité",
      INSURABILITY_DATE: "Date d'assurabilité",
      TC: "CT1 / CT2",
      BIM: "BIM",
      THIRD_PARTY_PAYER: "Tiers payant",
    },
    LOCAL_UPDATE: "Mise à jour automatique des données d'assurances.",
    LOCAL_UPDATE_ERROR:
      "Mise à jour automatique des données d'assurances échouée. Quelqu'un d'autre a peut être modifié le patient, essayez de recharger le patient.",
    PATIENT_DATA: "Données patient",
    PATIENT_DATA_LABELS: {
      SSIN: "NISS",
      LAST_NAME: "Nom",
      FIRST_NAME: "Prénom",
      BIRTHDATE: "Date de naissance",
      GENDER: "Genre",
      GENDER_VALUES: {
        male: "Homme",
        female: "Femme",
        undefined: "Non Défini",
        unknown: "Ignoré",
        changed: "Modifié",
        null: "",
      },
      DECEASED_DATE: "Date de décès",
    },
    PERIOD: "Période",
    PERIOD_LABELS: {
      REGISTRATION_NUMBER: "Numéro d'affilation mutualiste",
      MUTUALITY: "Mutualité",
      CB1_CB2: "CT1 / CT2",
      COMMUNICATION_DATE: "Date de communication",
      START_DATE: "Début de validité",
      END_DATE: "Fin de validité",
      PAYMENT_APPROVAL: "Engagement de paiement",
    },
    PAYMENT: "Paiement",
    PAYMENT_LABELS: {
      THIRD_PARTY_PAYMENT: "Tiers-payant",
      MISSING_DATA: "Pas de tiers-payant",
    },
    TYPE: "Type",
    START_DATE: "Date de début",
    END_DATE: "Date de fin",
    KINE: "Kiné",
    MEDICAL: "Medical",
    MEDICAL_HOUSE_ID: "N° inami",
    NURSE: "Infirmière",
    HOSPITALIZATION: "Hospitalisation",
    HOSPITALIZATION_LABELS: {
      HOSPITAL_ID: "ID Hôpital",
      SERVICE: "Service",
      ADMISSION_DATE: "Date d'admission",
      MISSING_DATA: "Pas d'hospitalisation",
    },
    GENERAL_SITUATION: "Situation générale",
    GENERAL_SITUATION_LABELS: {
      EVENT: "Evènement",
      TRANSFER_DIRECTION: "Transfert Direction",
      TRANSFER_IO: "Transfert IO",
      TRANSFER_DATE: "Date de transfert",
      MISSING_DATA: "Pas d'évènement",
    },
    CARE_PATHWAY: "Trajet de soins",
    CARE_PATHWAY_LABELS: {
      PHYSICIAN: "Médecin",
      MEDICAL_HOUSE: "Maison Médicale",
      SPECIALIST: "Spécialiste",
      CONTRACT_END_DATE: "Date de fin de contrat",
      MISSING_DATA: "Pas de trajet de soins",
      DIABETES: "Diabète",
      RENALINSUFFICIENCY: "Insuffisance renale",
    },
    CHRONIC_CONDITION: "Statut affection chronique",
    CHRONIC_CONDITION_LABELS: {
      YEAR: "Année",
      MISSING_DATA: "Pas de statut d'affection chronique",
    },
    REFERENCE_PHARMACY: "Pharmacie de référence",
    REFERENCE_PHARMACY_LABELS: {
      PHARMACY: "Pharmacie",
      MISSING_DATA: "Pas de pharmacie de référence",
    },
    GLOBAL_MEDICAL_FILE: "Dossier Médical Global",
    GLOBAL_MEDICAL_FILE_LABELS: {
      PHYSICIAN: "DMG",
      MISSING_DATA: "Pas de détenteur du DMG",
    },
    PALLIATIVESTATUS: "Statut palliatif",
    PALLIATIVESTATUS_LABELS: {
      STARTDATE: "Date de début",
      MISSING_DATA: "Pas de statut palliatif",
    },
    LAST_ASSURABILITY_CHECK: "Dernière vérification de l'assurabilité le",
    MDA_STATUS_SUCCESS:
      "La requête a abouti mais certaines informations manquent.",
    MEDISPRING_ISSUE: "Erreur au niveau de Médispring",
    IO_ISSUE: "Erreur au niveau de l'organisme assureur",
    BO_INVALID_REGNBR:
      "N° d'affiliation mutualiste inconnu à l'organisme assureur",
    BO_UNKNOWN_REGNBR:
      "N° d'affiliation mutualiste inconnu à l'organisme assureur",
    MISMATCHED_CONTACTTYPE:
      "Le patient n'est pas hospitalisé à la date consultée",
    UNKNOWN_NISS_ROUTING: "Le NISS est inconnu des mutuelles",
    INVALID_PERIOD: "La période demandée est invalide",
    SEND_TO_IM_EXCEPTION: "Problème de connection avec la mutuelle",
    FACET_EXCEPTION: {
      insurability: "Erreur Inattendue",
      REFERENCEPHARMACY: "Information momentanément indisponible",
      CHRONICCONDITION: "Information momentanément indisponible",
      CAREPATH: "Information momentanément indisponible",
      GLOBALMEDICALFILE: "Information momentanément indisponible",
      PALLIATIVESTATUS: "Information momentanément indisponible",
    },
    SAML_REQUEST_ERROR: "Erreur dans la requête SAML",
    BUSINESS_ERROR: "Erreur business (facettes ou dimensions)",
    BUSINESS_VALIDATION_FAILED:
      "La validation business de la requête a échouée",
    TECHNICAL_ERROR: "Erreur technique",
    INFO_MISSING:
      "Certaines informations sont manquantes (plusieurs facettes ont été demandées mais seulement une partie est disponible)",
    NOT_CONNECTED_EHEALTH: "Vous n'êtes pas connecté aux services eHealth.",
  },
  "patient-layout": {
    BORN_ON: "Né(e) le",
    YEARS: "ans",
    YEAR: "an",
    MONTHS: "mois",
    MONTH: "mois",
    FAMILY: "Famille",
    INVALID_SSIN: "N° de registre national invalide",
    MISSING_SSIN: "N° de registre national manquant",
    SUBMITTED_PENDING_CONTACTS:
      "{{count}} contact(s) en édition sauvegardé(s).",
    DISCARDED_PENDING_CONTACTS:
      "{{count}} contact(s) en édition abandonné(s) (fenêtre d'édition dépassée).",
    TITLE: "Création d'une ordonnance",
    PATIENT_LIVE_WITH: "Patients habitant sous le même toit...",
  },
  "patient-layout-buttons": {
    INSURABILITY: "Assurabilité",
    NO_ASSURABILITY: "Le patient n'est pas en ordre de mutuelle",
    CONSENT: "Consentement du patient",
    THERLINK: "Liens thérapeutiques",
    DMG: "DMG",
    BIM_PATIENT: "Le patient est BIM",
    CREATE_PRESCRIPTION: "Créer une ordonnance",
    LAST_DMG_CONSULTATION: "Dernière consultation du DMG :",
    "dmg-type": {
      DMG_UNKNOW: "DMG inconnu",
      NO_DMG: "Il n'y a pas de DMG",
      DMG_TO_MYSELF: "Le DMG m'appartient",
      DMG_COLLEAGUE: "Le DMG appartient à un médecin du cabinet",
      DMG_OUTSIDE: "Le DMG appartient à un médecin extérieur",
    },
    SUMEHR: "Sumehr",
    HUB: "Hub",
    ATTESTATION: "Attestation",
    MEDICAL_NOTE: "Note médicale",
    ADMINISTRATIVE_NOTE: "Note administrative",
    INVOICING: "Facturation",
    BEOOGO: "Beoogo",
    OPEN_BEOOGO: "Ouvrir dans Beoogo",
    BEOOGO_AUTHENTIFICATION_ERROR: "Erreur lors de l'authentification beoogo",
    BEOOGO_PATIENT_NOT_FOUND_ERROR: "Patient non présent dans Beoogo",
    COVID_VACCINATION: "Statut de la vaccination covid",
    SUBSCRIPTION: "Gestion de l'abonnement MM",
    LABORATORY: "Labo",
  },
  "patient-layout-tabs": {
    JOURNAL: "Journal",
    SYNTHESIS: "Synthèse",
    FILE: "Fiche",
    SYNTHESE: "Hubs",
    FINANCE: "Finance",
  },
  "patient-layout-labresult": {
    NOTE: "Note",
    PARTIAL_NOTE: "Note récupérée du résultat partiel",
    SAVE: "Enregistrer",
    NOTE_UPDATED_SUCCESS: "Note mise à jour.",
    NOTE_UPDATED_ERROR: "Erreur lors de la mise à jour de la note.",
    LABEL: "Libellé",
    DATE: "Date",
    VALUE: "Valeur",
    REFERENCE_VALUE: "Valeurs de référence",
    REFERENCE_VAL: "Val. de référence",
    UNIT: "Unité",
    FILTER: "Filtre",
    MORE_THAN_X: "Plus de {{value}}",
    LESS_THAN_X: "Moins de {{value}}",
    DOWNLOAD_BIOLOGY: "Télécharger la biologie",
    PRINT_BIOLOGY: "Imprimer la biologie",
    SEND_BIOLOGY: "Envoyer la biologie",
    BIOLOGY: "Biologie",
    PRINT_TITLE: "Résultat de laboratoire de {{hcpName}} du {{date}}",
    ABNORMAL: "Anormal",
    REFERENCE: "Référence labo",
    REF: "Réf.",
    COMPLETE: "Complet",
    PARTIAL: "Partiel",
    SENDER: "Labo",
  },
  "labresult-dialog": {
    TITLE: "Résultats de l'analyse : {{analysis}}",
  },
  "labresult-table": {
    NO_SERIES_DATA: "Les données ne permettent pas d'afficher un graphe.",
  },
  "telephonic-consultation": {
    DESCRIPTION: "Avis/Divers",
  },
  "multidisciplinary-consult": {
    DESCRIPTION: "Concertation multidisciplinaire",
  },
  "patient-file-content": {
    MEDICAL_NOTE_SAVED: "Note médicale enregistrée",
    EDIT_SUCCESS: "Patient mis à jour.",
    EDIT_FAILURE: "La mise à jour du patient a échoué.",
    CREATE_SUCCESS: "Patient créé.",
    CREATE_FAILURE: "La création du patient a échoué.",
    FILTERS: "Filtres",
    CANCEL_FILTER: "Annuler le filtre",
    BEOOGO_SYNC_ERROR: "Problème de synchronisation avec BEOOGO",
  },
  "patient-consent": {
    EXPIRED_EID_CARD: "Carte EID expirée",
    ERROR_CHECK:
      "Une erreur est survenue lors de la consultation du consentement: {{error}}",
    ERROR_SUBMIT:
      "Une erreur est survenue en soumettant le consentement: {{error}}",
    INCOMPLETE:
      "Il semblerait qu'un type de consentement soit manquant. Les deux sont requis pour le bon fonctionnement de Medispring. Veuillez recréer les consentements.",
    NOTHING_TO_REVOKE:
      "Le patient n'a aucun consentement à révoquer ou aucun consentement n'a été trouvé.",
    ERROR_REVOKE:
      "Une erreur est survenue en révoquant le consentement: {{error}}",
    MISSING_SSIN: "Le patient n'a pas de NISS.",
    CONSENT_TITLE: "Consentement du patient",
    EID_CARD_NUMBER: "Numéro de carte d'identité (eID)",
    ISI_CARD_NUMBER: "Numéro de carte ISI+",
    REVOKE_CONSENT: "Révoquer le consentement",
    REGISTER_CONSENT: "Enregistrer le consentement",
    REGISTER_NATIONAL_OK: "Consentement national enregistré avec succès",
    REGISTER_HUB_OK: "Consentement hub enregistré avec succès",
    REGISTER_HUB_FAILED: "Consentement hub échoué",
    REVOKED_OK: "Consentement révoqué avec succès",
    PLEASE_CHECK_BOX:
      "Veuillez cocher la demande ci-dessous afin d'enregistrer le consentement.",
    INFO_TITLE:
      "Merci de donner à votre patient les informations suivantes\u00A0: ",
    CREATE_FORM_HEADER:
      "Complétez les champs suivants pour créer les consentements du patient :",
    INFO1:
      "Je demande librement à m'inscrire aux réseaux de santé afin de permettre aux professionnels de la santé impliqués dans les soins qui me sont prodigués, d'échanger des données de santé qui me concernent.",
    INFO2:
      "Cette inscription vaut consentement à l'échange des données au niveau national dans le cadre de la continuité des soins.",
    REFUSE: "Enregistrer le refus du patient",
    CLOSE: "Fermer",
    EDIT: "Modifier le consentement",
    CONSENT_REFUSED: "Le patient refuse de donner son consentement",
    CONSENT_REFUSE_SAVED: "Consentement refusé",
    CONSENT_REFUSE_UNSAVED: "Consentement refusé annulé",
    CONSENT_REFUSE_ERROR: "La mise à jour du consentement a échoué",
    REASON_FOR_MANUAL_INPUT:
      "Choisissez une justification pour la saisie manuelle",
    BAD_SSIN: "N° national invalide",
    NOT_CONNECTED_TO_EHEALTH: "Vous n'êtes pas connecté au réseau eHealth",
    ERRORS: {
      "MH2.INPUT.2": "Le demandeur de la requête est invalide",
      "MH2.INPUT.19": "L'identification du patient est invalide",
      "MH2.ACCESS.8": "Le consentement existe déjà",
      "IDS2.INPUT.53": "Format invalide",
      "IDS2.INPUT.70": "Format inconnu",
      "IDS2.INPUT.80": "Aucun résultat pour ce numéro",
      "CO.UPDATE.01":
        "Le consentement d'une personne décédée ne peut pas être changé",
      "CO.INPUT.30":
        "Le numéro de carte support du patient INSS est obligatoire",
    },
  },
  "patient-therlink": {
    ERROR_CHECK:
      "Une erreur est survenue lors de la consultation du lien therapeutique: {{error}}",
    ERROR_SUBMIT:
      "Une erreur est survenue en soumettant le lien thérapeutique: {{error}}",
    ERROR_SUBMIT_NATIONAL:
      "Une erreur est survenue en soumettant le lien thérapeutique national",
    ERROR_SUBMIT_HUB:
      "Une erreur est survenue en soumettant le lien thérapeutique du hub",
    INCOMPLETE:
      "Il semblerait qu'un type de lien thérapeutique soit manquant. Les deux sont requis pour le bon fonctionnement de Medispring. Veuillez recréer les liens thérapeutiques.",
    NOTHING_TO_REVOKE:
      "Le patient n'a aucun lien thérapeutique à révoquer ou aucun lien thérapeutique n'a été trouvé.",
    ERROR_REVOKE:
      "Une erreur est survenue en soumettant le lien thérapeutique : {{error}}",
    MISSING_SSIN: "Le patient n'a pas de NISS.",
    THERLINK_TITLE: "Liens thérapeutiques",
    EID_CARD_NUMBER: "Numéro de carte d'identité (eID)",
    ISI_CARD_NUMBER: "Numéro de carte ISI+",
    ALL_TYPES: "National + Hub",
    REVOKE: "Révoquer",
    NAT_ONLY: "Seulement sur le réseau national",
    HUB_ONLY: "Seulement sur le réseau hub",
    LINK_TO_CREATE: "Lien(s) à créer",
    REVOKE_THERLINK: "Révoquer le lien thérapeutique",
    REGISTER_THERLINK: "Enregistrer le lien thérapeutique",
    REGISTER_OK: "Lien thérapeutique enregistré avec succès",
    REVOKED_OK:
      "Révoquation du lien thérapeutique réalisée avec succès, son traitement peut prendre plusieurs minutes. ",
    NAME: "Nom",
    NIHII: "INAMI",
    DATE_START: "Date de début",
    DATE_END: "Date de fin",
    STATUS: "Statut",
    TYPE: "Type",
    LEVEL: "Niveau",
    NATIONAL: "National",
    HUB: "Hub",
    REASON_FOR_MANUAL_INPUT:
      "Choisissez une justification pour la saisie manuelle",
  },
  "health-element-form": {
    HEALTH_ELEMENT: "Élément de soin",
    NEW_HEALTH_ELEMENT: "Nouvel élément de soin",
    EDIT_HEALTH_ELEMENT: "Modifier l'élément de soin",
    SEARCH_IN: "Rechercher dans",
    DESCRIPTION: "Description",
    NATURE: "Nature",
    STATUS: "Statut",
    CHARACTERIZATION: "Caractérisé comme",
    CERTAINTY: "Certitude",
    SEVERITY: "Sévérité",
    TEMPORALITY: "Temporalité",
    LATERALITY: "Latéralité",
    CONFIDENTIALITY: "Confidentialité",
    NOTE: "Note",
    PLANS_OF_ACTION: "Plan d'action (Démarche)",
    OPENING_DATE: "Date de début",
    CLOSING_DATE: "Date de fin",
    EXTRA_OPTIONS: "Plus de choix",
    HISTORY: "Historique",
    EPISODE: "Épisode",
    EPISODES: "Épisodes",
    HISTORY_COLUMN: {
      DESCRIPTION: "Description",
      NATURE: "Nature",
      STATUS: "Statut",
      MODIFIED_DATE: "Modification",
      RESPONSIBLE: "Utilisateur",
      AUTHOR: "Intervenant",
    },
    ERROR: {
      CODE_FETCH_FAILURE: "Echec de la recherche de code",
      MISSING_DESCRIPTION: "Description manquante.",
      INACTIVE_MISSING_CLOSING_DATE: "La date de fin doit être présente",
      FUTURE_DATE: "La date ne peut pas être dans le futur.",
      REQUIRED_START_DATE: "Une date de début est obligatoire.",
      UNORDERED_DATES: "La date de début est postérieure à la date de fin.",
      FAMILY_RISK_AND_ACTIVE: "Un antécédent familial ne peut pas être actif.",
    },
    WARNING: {
      REQUIRED_FIELD: "Champ obligatoire",
    },
    ADD: "Ajouter",
    CANCEL: "Annuler",
    MODIFY: "Modifier",
    SAVED_THE: "Enregistré le",
    BY: "par",
    EVIDENCE_LINKER: "Evidence Linker",
  },
  "evidence-linker-dialog": {
    SELECT: "Sélectionner",
    CANCEL: "Annuler",
  },
  "code-service": {
    FETCH_FAILURE: "La recherche de code a échoué pour {{code}}.",
  },
  "code-search": {
    FIND_HEALTH_ELEMENT: "Trouver un élément de soin",
  },
  "insurability-search": {
    FIND_INSURABILITY: "Trouver une assurance",
  },
  "patient-synthesis-controls": {
    GENERAL: "Synthèse Générale",
    VASCULAR: "TA / Pouls",
    WEIGHT_HEIGHT: "Poids / Taille",
    BLOOD: "Glycémie, Température et saturation O2",
    BIOLOGIES: "Dernière biologie en date du ",
  },
  "patient-journal-controls": {
    UPDATED_HEALTH_ELEMENTS:
      "{{count}} éléments de soin automatiquement mis à jour.",
    EXPAND: "Déplier les éléments de soin",
    COLLAPSE: "Replier les éléments de soin",
    CATEGORY: {
      PROBLEM: "Eléments de soin actifs",
      ARCHIVE: "Archives",
      INACTIVE: "Antécédents",
      ALLERGY: "Allergies médicamenteuses",
      INTOLERANCE: "Intolérances médicamenteuses",
      FAMILY_RISK: "Antécédents familiaux",
      SOCIAL_RISK: "Facteurs de risque sociaux",
      MEDICAL_RISK: "Facteurs de risque médicaux",
      INACTIVE_SURGICAL: "Antécédents chirurgicaux",
      INACTIVE_MEDICAL: "Antécédents médicaux",
      INACTIVE_GYNECOLOGICAL: "Antécédents gynéco-obstétricaux",
      RISK: "Facteurs de risque",
      CURRENT_TREATMENTS: "Traitements actifs",
      DIAGNOSIS: "Diagnostics non pertinents",
      PREVENTION: "Prévention",
      VACCINE: "Vaccins",
    },
    NEW_EPISODE_DIALOG: {
      TITLE: "Activation de l'élément: {{name}}",
      CONTENT:
        "Souhaitez-vous créer un nouvel épisode ou reprendre l'épisode précédent?",
      CANCEL: "Annuler",
      ACTIVATE: "Reprendre l'épisode précédent",
      CREATE: "Créer un nouvel épisode",
    },
    EPISODE: "Épisode",
    RECURRENCE: "récidive",
    NO_HEALTH_ELEMENT: "PAS DE {{descr}}",
    EDIT: "Éditer",
    EDIT_OR_CARE_PATH: "Éditer/Trajet de soins",
    EVIDENCE_LINKER: "Evidence Linker",
    ARCHIVE: "Archiver",
    DEACTIVATE: "Antécédents",
    ACTIVATE: "Activer",
    REMOVE: "Supprimer",
    ADD: "Ajouter",
    TO_PLAN: "Planifier",
    FILTER: "Filtrer",
    SHOW_RLM: "Afficher le module RLM",
    CANCEL_FILTER: "Annuler le filtre",
    NO_ACTION: "Aucune action disponible",
    PRESET: {
      ALCOHOL: {
        MENU_ITEM: "Alcool",
        DESCRIPTION: "Alcool",
      },
      TOBACCO: {
        MENU_ITEM: "Tabac",
        DESCRIPTION: "Tabagisme",
      },
      DIABETES2: {
        MENU_ITEM: "Trajet de soins : Diabète de type 2",
        DESCRIPTION: "Diabète de type 2",
      },
      PREDIABETES: {
        MENU_ITEM: "Trajet de soins : Pré-trajet diabète",
        DESCRIPTION: "Pré-trajet diabète",
      },
      RENAL_FAILURE: {
        MENU_ITEM: "Trajet de soins : Insuffisance rénale",
        DESCRIPTION: "Insuffisance rénale",
      },
    },
    CREATE_DIAGNOSIS: {
      PROBLEM: "Créer un problème médical",
      MEDICAL_ANTECEDENT: "Créer un antécédent médical",
      SURGICAL_ANTECEDENT: "Créer un antécédent chirurgical",
      GYNECOLOGICAL_ANTECEDENT: "Créer un antécédent gynéco-obstétricaux",
    },
    PREVENTION: {
      CERVIX_UTERIC_CANCER: "Prévention du cancer du col de l'utérus",
      COLORECTAL_CANCER: "Prévention du cancer colorectal",
      BREAST_CANCER: "Prévention du cancer du sein",
      PROSTATE_CANCER: "Prévention du cancer de la prostate",
      OSTEOPOROSIS: "Prévention de l'ostéoporose",
      INFLUENZA: "Prévention de la grippe saisonnière",
      seasonalinfluenza: "Prévention de la grippe saisonnière",
      AORTIC_ANEURYSM: "Prévention de l’anévrysme de l’aorte abdominale",
      LUNG_CANCER: "Prévention du cancer du poumon",
      ACTION: {
        PLAN_TASK: "Créer une nouvelle tâche de prévention",
        REFUSED: "Refusée par le patient",
        NON_INDICATED: "Marquer la prévention comme non-indiquée",
      },
      TOOLTIP: {
        NO_OR_OVERDUE_TASK: "Pas de tâche associée ou bien elle est échue.",
        NEXT_TASK: "Prochaine tâche de prévention planifiée le {{date}}.",
        REFUSED_DATE: "Prévention refusée le {{date}}.",
        MARKED_NON_INDICATED_DATE:
          "Prévention marquée comme non-indiquée le {{date}}.",
      },
    },
    CREATE_SUCCESS: '"{{descr}}" créé.',
    CREATE_ELEMENT_SUCCESS: 'Élément de soin "{{descr}}" créé.',
    CREATE_ELEMENT_FAILURE: "La création de l'élément de soin a échoué.",
    EDIT_SUCCESS: '"{{descr}}" modifié.',
    ARCHIVE_SUCCESS: '"{{descr}}" archivé.',
    DEACTIVATE_SUCCESS: '"{{descr}}" marqué comme antécédent.',
    ACTIVATE_SUCCESS: '"{{descr}}" marqué comme actif.',
    REMOVE_SUCCESS: '"{{descr}}" supprimé.',
    REMOVE_FAILURE: 'La suppression de "{{descr}}" a échoué.',
    CANNOT_REMOVE_ATTACHED:
      '"{{descr}}" ne peut pas être supprimé: il est attaché à un contact.',
    HEALTH_ELEMENT: "Élément de soin",
    NEW_HEALTH_ELEMENT: "Ajouter un élément de soin",
    CLASSIFICATION: "Classification",
    ATTACHED_N: "Élément de soin attaché à {{n}} sous-contacts.",
    FAILED_TO_ATTACH_N:
      "L'association de l'élément de soin a échoué pour {{n}} sous-contacts.",
  },
  "health-element-tree-service": {
    PREVENTION_CODE_NOT_FOUND: "Codification de la prévention introuvable.",
    AUTO_CREATED_PREVENTIONS: "Préventions créées automatiquement.",
    NOT_INDICATED_PREVENTIONS:
      '"{{prevention}}" a été modifiée en "non indiquée"',
  },
  "patient-journal-content": {
    TYPE_FILTER_PREFIX: "Filtrer par",
    EXPORT: "Exporter",
    EXPORT_CSV: "Export csv",
    EXPORT_ERROR: "Erreur lors de l'exportation",
    CONTACTS: "Contacts",
    FILTERS: "Filtres",
    REFRESH: "Réinitialiser",
    CREATED_BEFORE: "Créé avant",
    CREATED_AFTER: "Créé après",
    CREATED_BY: "Saisi par",
    AUTHOR: "Auteur",
    SEARCH_CONTACTS: "Rechercher",
    TEXT: "Texte",
    SORT: "Tri",
    ANTICHRONOLOGICAL: "Antéchronologique",
    CHRONOLOGICAL: "Chronologique",
    CANCEL: "Annuler",
    APPLY: "Appliquer",
    SERVICES: "Services",
    ALL: "Toutes",
    SERVICE_GROUP_TYPES: {
      PLAN_TEXT: "Plan texte",
      PLAN_TREATMENT: "Prescription",
      PLAN_VACCINE: "Vaccin",
      PLAN_INCAPACITY: "ITT",
      PLAN_DOCUMENT: "Documents",
      PLAN_INCAPACITY_MEDICAL: "ITT pour médecin conseil",
      PLAN_INCAPACITY_MEDEX: "ITT générique",
      PLAN_PHYSIOTHERAPY: "Demande kiné",
      PLAN_MEDICAL_CARE: "Demande infi",
      PLAN_RADIOGRAPHY: "Demande RX",
      PLAN_PROCEDURE: "Procédure",
      S_PAIN_SCALE: "Échelle de douleur subjectif",
      O_TEXT: "Texte objectif",
      O_PAIN_SCALE: "Échelle de douleur objectif",
      O_KATZ_SCALE: "Échelle de Katz",
      O_BIOMETRY: "Biométrie",
    },
    FILTER: {
      CONTACT: "contacts",
      "PHONE-CONTACT": "avis/divers",
      LAB: "laboratoires",
      REPORT: "rapports",
      SPECIALITY: "Filtrer par spécialité",
      DATE: "Filtrer par date",
      "MULTI-DISCIPLINARY": "contacts multi-discipl.",
      CONSULTATION_DATE: "Filtrer sur une période de soin",
    },
    TASKS: "Liste des tâches",
    TASKS_LATE: "Vous avez des tâches dépassées",
    SUMMARY: "Résumé",
    SEARCH: "Rechercher",
    CLOSE: "Clôturer",
    CONTACT: "Consultation",
    CONTACT_COUNT: "Nombre de contacts",
    NEW_CONTACT: "Nouvelle consultation",
    CONTACT_TYPE_OPTIONS: "Nouveau contact d'un autre type",
    CURRENT_CONTACT: "Consultation du jour",
    NO_CONTACT: "Il n'y a pas de contact à afficher.",
    EDITING_CONTACT: "Contact en cours d'édition",
    REPORT: "Rapport",
    NEW_REPORT: "Nouveau rapport",
    error: {
      MOTIVATION_AND_ASSESSMENT_ARE_MANDATORY:
        "Le motif et le diagnostic sont obligatoires pour clôturer une consultation.",
      MOTIVATION_ASSESSMENT_ORIENTATION_AND_REPORT_ARE_MANDATORY:
        "Le motif, diagnostic, l'orientation patient et rapport de garde sont obligatoires pour clôturer une consultation.",
      REPORT_MOTIVATION_ASSESSMENT_AND_ORIENTATION_ARE_MANDATORY:
        "Le motif, diagnostic et l'orientation patient sont obligatoires pour générer un rapport de garde.",
      COULD_NOT_CREATE_CONTACT: "La création du contact a échoué.",
      COULD_NOT_ATTACH_HEALTH_ELEMENT:
        "L'élément de soin sélectionné n'a pas pu être attaché au nouveau contact.",
    },
    warning: {
      CANNOT_CLOSE_FALLING_BACK_TO_SAVE:
        "Le contact ne peut pas être clôturé. Sauvegarde du contact en cours.",
    },
    "windows-close-confirmation": {
      TITLE: "Confirmation",
      WARNING:
        "Voulez-vous fermer les fenêtres de consultation en cours et annuler tous vos encodages?",
      CANCEL: "Annuler",
      CONFIRM: "Confirmer",
    },
  },
  "patient-dmg": {
    DMG_TITLE: "DMG",
    INSCR: "Inscrivez-vous au DMG.",
    INSCR_OK: "Vous êtes inscrit(e) au DMG.",
    DMG_VALIDATE_OK: "Demande envoyeé",
    INFO1:
      "La demande d'un nouveau DMG doit être faite dans les 7 jours du code associé.",
    LAST_CONSULTATION_DATE: "Date de dernière consultation",
    VALIDATE: "Valider",
    DATE: "Date",
    NOMENCLATURE: "Nomenclature",
    STATE_INSCR: "État de votre inscription au DMG",
    MISSING_SSIN: "Le patient n'a pas de NISS",
    NO_INSURABILITY: "Le patient n'a pas de données d'assurabilité",
    NO_MUTUALITY_CODE: "Le patient n'a pas de code de mutuelle",
    NO_TITULAIRE_ID: "Le patient n'a pas de titulaire ID",
    ACTUAL_STATE: "État actuel du DMG du patient",
    RESPONSIBLE: "Responsable : ",
    FROM: "Du",
    TO: "au",
    NOTIFY: "Demander le DMG",
    ERR_NOTIFY: "Le DMG n'a pas pu être demandé.",
    CONSULT: "Consulter",
    REJECT: "Demande rejetée",
    ACCEPT: "Demande acceptée",
    FROM_ACC: "à partir du",
    PAYMENT_OK: "Dossier médical global déjà payé pour cette année",
    PAYMENT_NOK:
      "Le dossier médical global n'a pas encore été payé pour cette année",
    YES: "Oui",
    NO: "Non",
    ASSISTANT_ALONE:
      "Un assistant ne peut pas notifier un DMG sans maître de stage valable",
    ASSITANT_WITHOUT_MASTER_HCP:
      "Afin de notifier un DMG en tant qu'assistant, vous devez au préalable définir votre maître de stage dans votre fiche signalétique.",
    DOCTOR_NOT_REGISTERED:
      "Afin de notifier un DMG, vous devez au préalable être en ordre d'inscription. Veuillez consulter vos inscriptions depuis votre écran eDmg.",
    REFERRAL_HISTORY: "Historique",
  },
  "patient-hub": {
    UNKNOWN_ALLERGY: "Allergie inconnue",
    CDHCPARTY: {
      deptanatomopathology: "anatomopathologie",
      deptanesthesiology: "anesthésiologie",
      deptbacteriology: "bactériologie",
      deptcardiology: "cardiologie",
      deptdermatology: "dermatologie",
      deptdietetics: "diététique",
      deptemergency: "services d'urgence",
      deptgastroenterology: "gastroentérologie",
      deptgeneralpractice: "médecine générale",
      deptgenetics: "génétique",
      deptgeriatry: "gériatrie",
      deptgynecology: "gynécologie",
      depthematology: "hématologie",
      deptintensivecare: "soins intensifs",
      deptkinesitherapy: "kinésithérapie",
      deptlaboratory: "Laboratoire",
      deptmedicine: "médicine interne",
      deptmolecularbiology: "biologie moléculaire",
      deptneonatalogy: "néonatologie",
      deptnephrology: "néphrologie",
      deptneurology: "neurologie",
      deptnte: "oto-rhino-laryngologie",
      deptnuclear: "médecine nucléaire",
      deptoncology: "oncologie",
      deptophtalmology: "ophtalmologie",
      deptpediatry: "pédiatrie",
      deptpharmacy: "Pharmacie",
      deptphysiotherapy: "physiothérapie",
      deptpneumology: "pneumologie",
      deptpsychiatry: "psychiatrie",
      deptradiology: "radiologie",
      deptradiotherapy: "radiothérapie",
      "deptrhumatology (deprecated)": "rhumatologie",
      deptstomatology: "stomatologie",
      deptsurgery: "chirurgie",
      depttoxicology: "toxicologie",
      depturology: "urologie",
      orghospital: "hôpital",
      orginsurance: "assurance",
      orglaboratory: "laboratoire indépendant",
      orgpublichealth: "service public de santé",
      persbiologist: "technicien de laboratoire médical",
      persdentist: "dentiste",
      persnurse: "infirmier",
      persparamedical: "personnel paramédical",
      perspharmacist: "pharmacien",
      persphysician: "médecin",
      perssocialworker: "travailleur social",
      perstechnician: "Technicien d'imagerie médicale",
      persadministrative: "personnel admistratif",
      persmidwife: "sage-femme",
      application: "application logicielle",
      deptorthopedy: "orthopédie",
      orgpractice: "cabinet",
      orgpharmacy: "Pharmacie indépendante",
      deptalgology: "algologie",
      deptcardiacsurgery: "chirurgie cardiaque",
      depthandsurgery: "chirurgie de la main",
      deptmaxillofacialsurgery: "chirurgie maxillofaciale",
      deptpediatricsurgery: "chirurgie pédiatrique",
      deptplasticandreparatorysurgery: "chirurgie esthétique et réparatrice",
      deptthoracicsurgery: "chirurgie thoracique",
      deptvascularsurgery: "chirurgie vasculaire",
      deptvisceraldigestiveabdominalsurgery:
        "chirurgie abdominale, digestive et viscérale",
      deptdentistry: "dentisterie",
      deptdiabetology: "diabétologie",
      deptendocrinology: "endocrinologie",
      deptoccupationaltherapy: "ergothérapie",
      deptmajorburns: "grands brûlés",
      deptinfectiousdisease: "maladie infectieuse",
      deptspeechtherapy: "logopédie",
      deptsportsmedecine: "médecine des sports",
      deptphysicalmedecine: "médecine physique",
      depttropicalmedecine: "médecine tropicale",
      deptneurosurgery: "neurologie",
      deptnutritiondietetics: "diététique nutritionnelle",
      deptobstetrics: "ergothérapie",
      deptchildandadolescentpsychiatry:
        "psychiatrie de l'enfant et de l'adolescent",
      deptpodiatry: "podiatrie",
      deptpsychology: "psychologie",
      deptrevalidation: "rééducation",
      deptsenology: "sénologie",
      deptsocialservice: "service social",
      deptpediatricintensivecare: "soins intensifs pédiatriques",
      deptpalliativecare: "soins palliatifs",
      deptrheumatology: "rhumatologie",
      persambulancefirstaid: "ambulance premier secours",
      persaudician: "audicien",
      persaudiologist: "audiologue",
      perscaregiver: "soignant informel",
      persdietician: "diététicien",
      perseducator: "éducateur",
      perslogopedist: "logopède",
      persoccupationaltherapist: "ergothérapeute",
      "persorthopedist (deprecated)": "orthopédiste",
      persorthoptist: "orthoptiste",
      persoptician: "opticien",
      perspharmacyassistant: "assistant en pharmacie",
      persphysiotherapist: "kinésithérapeute",
      perspodologist: "podologue",
      perspracticalnurse: "aide-soignante",
      perspsychologist: "psychologue",
      orgprimaryhealthcarecenter: "Maison médicale",
      guardpost: "poste de garde",
      groupofphysicianssameaddress: "regroupement de médecins même adresse",
      groupofphysiciansdifferentaddress:
        "Regroupement de médecins adresse différente",
      groupofnurses: "Regroupement d'infirmiers",
      certificateholder: "Titulaire du certificat",
      perstrussmaker: "Bandagiste",
      patient: "Patient",
      persorthotist: "Orthésiste/Prothésiste",
      perspatientdriver: "Transporteur de patient (non urgent)",
      orgprevention: "Service de pévention",
      perspharmacistclinicalbiologist: "pharmacien-biologiste clinicien",
      hub: "hub",
      deptnursing: "soins infirmiers",
      persoraldentalhygienist: "Hygiéniste bucco-dentaire",
      persclinicalpsychologist: "Psychologue clinicien",
      persclinicalorthopedagogist: "Orthopédagogue clinicien",
      unknown: "inconnu",
    },
    ACCESS: {
      yes: "oui",
      no: "Libérable par le médecin traitant",
      by_author: "Libérable par l'auteur",
      unknown: "Inconnu",
      never: "Jamais",
    },
    CDPATIENTWILL: {
      ntbr: "Ne pas réanimer",
      bloodtransfusionrefusal: "Refus de transfusion sanguine",
      intubationrefusal: "Refus d'intubation",
      euthanasiarequest: "Requête d'euthanasie",
      vaccinationrefusal: "Refus de vaccination",
      organdonationconsent: "Consentement au don d'organe",
      datareuseforclinicalresearchconsent:
        "Réutilisation des données pour des recherches cliniques",
      datareuseforclinicaltrialsconsent:
        "Réutilisation des données pour des essais cliniques",
      clinicaltrialparticipationconsent:
        "Consentement de participation aux essais cliniques",
      omissionofmedicaldata:
        "Des données ont été omises à la demande du patient",
    },
    "CD-PATIENTWILL-HOS": {
      undefined: "?",
      hos0: "Pas de limitations concernant l'hospitalisation",
      hos1: "Pas d’hospitalisation",
      hos2: "Hospitalisation uniquement dans des situations spécifiques",
    },
    "CD-PATIENTWILL-RES": {
      undefined: "?",
      dnr0: "Pas de limitations thérapeutiques",
      dnr1: "Pas de réanimation",
      dnr2: "Pas de réanimation et ne pas étendre la thérapie en cours",
      dnr3: "Pas de réanimation et diminuer progressivement la thérapie en cours",
    },
    STATE: {
      title: "État",
      LOADING: "Chargement",
      COMPLETED: "OK",
      ERROR:
        "Une erreur s'est produite. Veuillez réessayer plus tard ou contactez le support.",
    },
    STATUS: {
      ABORTED: "Avorté",
      ACTIVE: "Actif",
      ADDED: "Ajouté",
      ADMINISTRATED: "Administré",
      CANCELLED: "Annulé",
      COMPLETED: "Terminé",
      CORRECTED: "Corrigé",
      DELIVERED: "Livré",
      SUBSTITUTED: "Substitué",
      INACTIVE: "Inactif",
      PLANNED: "Prévu",
      PRESCRIBED: "Prescrit",
      REPORTED: "Signalé",
      REFUSED: "Refusé",
      SWITCHED: "Commuté",
      SUSPENDED: "Suspended",
      STOPPED: "Arrêté",
      EXCLUDED: "Exclu",
      NOTPRESENT: "Pas présent",
      ORDERED: "Commandé",
      SCHEDULED: "Prévu",
      TODO: "Faire",
      POSTPONED: "Reporté",
      PENDING: "En attente",
      EXPECTED: "Attendu",
      OBTAINED: "Obtenu",
      PROPOSED: "Proposé",
      RETRACTED: "Rétracté",
    },
    HUB_TITLE: "Hub",
    LOADING: "Vérification des données, veuillez patienter",
    SELECT_A_HUB: "Sélectionnez un hub à interroger",
    DATE_FILTER: "Filtrer par date",
    DATE_FILTER_START: "Du",
    DATE_FILTER_END: "au",
    DOCUMENTS: {
      TAB_TITLE: "Documents",
      CONTENT_NAME: "Contenu",
      AUTHOR: "Auteur",
      NAME: "Nom",
      VALUE: "Valeur",
      ATTACHMENTS: "Pièces jointes",
      FILE: "Fichier",
      OPEN_FILE: "Ouvrir le fichier",
      IMPORT_PATIENT_FOLDER: "Importer dans le Journal",
      NO_DATA: "Aucun document disponible pour ce patient",
      NO_TRANSACTION_DATA:
        "Aucun document n'est (encore) disponible pour cet élément.",
    },
    TABS: {
      PREVIEW: "Aperçu général",
      ALLERGIES: "Allergies",
      ADR: "Intolérances",
      RISKS: "Facteurs de risques",
      DIAGNOSIS: "Diagnostics",
      MEDICATIONS: "Médications",
      VACCINES: "Vaccins",
      CONTACTHCPARTY: "Soignants",
      CONTACTPERSON: "Contacts",
      TREATMENTS: "Traitements",
    },
    SUMEHR: {
      TAB_TITLE: "Sumehrs",
      SUMHER_TITLE: "Sumehrs",
      TITLE: "Liste des Sumehrs",
      REVOKE: "Révoquer Sumehr",
    },
    SEND_SUMEHR: {
      NEW_SUMEHR: "Nouveau Sumehr",
      INFO_1:
        "Les informations ont été automatiquement completées sur base du dossier du patient.",
      INFO_2: 'Cliquez sur "Envoyer" ou éditez le contenu manuellement.',
      DATE: "Date : ",
      NO_ELEMENT: "Pas d'élément dans le dossier",
      ERROR_GET_DATA: "Impossible d'obtenir les éléments du dossier",
      ERROR_SAVE: "La modification de statut n'a pas pu être enregistrée",
      SEND_SUMEHR: "Envoyer un Sumehr",
      ENTER_PASSWORD:
        "Entrez le mot de passe pour générer et envoyer le Sumehr. Ce mot de passe doit être entré par le help desk.",
      BACK: "Retour",
      SEND: "Envoyer",
      SEND_OK: "Sumehr envoyé",
      SEND_ERROR: "Erreur lors de l'envoi du Sumehr",
      GENERATION_ERROR: "Erreur lors de la génération du Sumehr",
      INVALID_PASSWORD: "Mot de passe invalide",
      MEDICATION: "Traitements",
      WILL: "Volontés",
      DOWNLOAD_XML: "Télécharger le XML",
      CONFIDENTIAL: "Confidentiel",
    },
    SEND_DIARY_NOTE: {
      NEW_DIARY_NOTE: "Nouvelle note",
      DATE: "Date: ",
      BACK: "Retour",
      SEND: "Envoyer",
      CHOOSE_DIARY: "Choisir une catégorie : ",
      SEND_OK: "Note de journal envoyée",
      SEND_ERROR: "Erreur d'envoi du journal",
      antibiotherapy: "Antibiothérapie",
      diabetes: "Diabète",
      medication: "Médication",
      movement: "Mouvement",
      nutrition: "Nutrition",
      oncology: "Oncologie",
      renalinsufficiency: "Insuffisance rénale",
      woundcare: "Soins de plaie",
    },
    DOCUMENT: {
      TYPE: "Type",
      SERVICE: "Service",
      HOSPITAL: "Hopital",
      DATE: "Date",
      HCP_LASTNAME: "Nom du médecin",
      HCP_FIRSTNAME: "Prénom du médecin",
      CONTENT: "Contenu textuel",
      LINKS: "Liens",
      LINK: "Lien",
      FILES: "Fichiers",
    },
    TRANSACTIONS: {
      DATE: "Date",
      AUTHOR: "Auteur",
      PROFILE: "Profil",
      SERVICE: "Service",
      SITE: "Site",
      ACCESSIBILITY: "Accessible patient",
      ACCESS_DATE: "Date accès patient",
      IMPORT_TRANSACTION: "Import de la transaction : Action requise",
      CATEGORIES: "Catégories",
      VERSION: "Version",
      VALIDITY: "Validité",
    },
    MEDICATION_SCHEMA: {
      TAB_TITLE: "Schéma de médications",
      SEND_MED_SCHEME: "Synchroniser le schéma",
      LOADING: "Chargement du schéma de médications",
      SYNCHRONIZE: "Synchronisation du schéma en cours",
    },
    VACCINATION: {
      VACCINATION: "Vaccination",
      encounterlocation: "Le pays où le vaccin a été administré : ",
    },
    PATIENT_NOTES: {
      TAB_TITLE: "Notes patient",
      SEND_NOTES: "Envoyer note",
    },
    HCP_NOTES: {
      TAB_TITLE: "Journal",
    },
    VACCINATIONS: {
      TAB_TITLE: "Vaccinations",
    },
    LAB_RESULTS: {
      TAB_TITLE: "Laboratoire",
    },
    SPECIALITIES: {
      TAB_TITLE: "Autres",
    },
    WHATSAPPVIEW: {
      TABLE: "Liste",
      JOURNAL: "Journal",
    },
    PATIENT: {
      title: "Patient",
      ssin: "NISS",
      lastName: "Nom",
      firstName: "Prénom",
      sex: "Sexe",
      birthDate: "Date de naissance",
      wills: "Volontés",
      hos: "Hospitalisation",
      res: "Réanimation",
    },
    GMD_ADMINISTRATION: {
      title: "Administrateur DMG",
      nihii: "INAMI",
      lastName: "Nom",
      firstName: "Prénom",
      date: "Date",
    },
    AUTHOR: {
      title: "Auteur",
      nihii: "INAMI",
      profile: "Profil",
      lastName: "Nom",
      firstName: "Prénom",
      address: "Adresse",
      phone: "Téléphone",
      mobile: "GSM",
      UNKNOWN: "Inconnu",
    },
    PERSON: {
      birthdate: "Date de naissance",
      ssin: "Numéro national",
      sex: "Sexe",
      relationship: "Relation",
    },
    WRITER: {
      title: "Rédacteur",
    },
    ERRORS: {
      CANNOT_DECODE_MEDICATION:
        "Les schémas de médication ne sont pas encore visibles au sein de Medispring. Nous vous invitons à vous rendre sur l'application de votre hub.",
      NO_SSIN: "Le patient n'a pas de numéro de registre national enregistré",
      NO_EHEALTH: "Vous n'êtes pas connecté au réseau eHealth",
      NO_CONSENT:
        "Le patient n'a pas donné son consentement pour le partage de ses données",
      NO_THERLINK: "Vous n'avez pas de lien thérapeutique avec le patient",
      SERVER_ERROR: "Impossible de récuperer la ressource demandée",
      NO_WORK_ZIP_CODE:
        'Vous devez remplir une adresse "Lieu de travail" dans votre profil avant de pouvoir envoyer un Sumehr.',
      DISABLED: "La fonctionnalité du hub est désactivée pour cette connexion",
    },
    IMPORT: {
      SUCCESS: "Document importé avec succès",
      CANCELED: "Import de la transaction annulé",
      LAST_NAME: "Nom",
      FIRST_NAME: "Prénom",
      INAMI: "Numéro INAMI",
      NATIONAL: "Numéro national",
      NEW_HCP_LABEL: "Sélectionnez un auteur dans votre liste d'intervenants",
      NEW_HCP: "Créer un nouvel intervenant",
    },
    DIALOG: {
      AUTHOR_IN_TRANSACTION:
        "Informations trouvées sur l'auteur dans la transaction :",
      REASONS: {
        no_author:
          "L'auteur de la transaction est introuvable, veuillez le spécifier.",
        no_match:
          "L'auteur de la transaction n'a pas pu être trouvé dans Medispring. Sélectionnez ou créez un prestataire.",
        multiple_match:
          "Plusieurs prestataires ont été trouvés pour cette transaction. Veuillez en sélectionner un",
      },
    },
    VALIDATE: "Valider",
    CANCEL: "Annuler",
    DESCRIPTION: "Description",
    START: "Début",
    END: "Fin",
    STATUS_TITLE: "Statut",
    CERTAINTY: "Certitude",
    COMMENT: "Commentaire",
    POSOLOGY: "Posologie",
    CNKATCCODE: "Code Cnk/Atc",
    TEMPORALITY: "Temporalité",
    DATE: "Date",
    INDICATIONS: "Indications",
  },
  "sumehr-import": {
    IMPORT_IN_PATIENT_FILE: "Importer dans le dossier du patient",
    IMPORT_SUMEHR: "Importer les données du Sumehr",
    SELECT_ALL: "Sélectionner tout",
    DESELECT_ALL: "Désélectionner tout",
    CANCEL: "Annuler",
    IMPORT: "Importer",
    MEDISPRING: "Medispring",
    SUMEHR: "Sumehr",
    STATUS: "Statut",
    WILLS: "Volontés",
    HEALTH_PROBLEMS: "Eléments de soin actifs",
    CURRENT_TREATMENTS: "Traitements actifs",
    ANTECEDENTS: "Antécédents",
    ALLERGIES: "Allergies",
    INTOLERANCE: "Intolérences",
    RISK_FACTORS: "Facteurs de risque",
    VACCINES: "Vaccins",
    CONTACT_HC_PARTIES: "Soignants",
    ANTECEDENT_TYPES: {
      MEDICAL: "Médical",
      SURGICAL: "Chirurgical",
      FAMILY_RISK: "Familial",
      GYNECOLOGICAL: "Gynéco-obstétricaux",
    },
    RISK_FACTOR_TYPES: {
      MEDICAL: "Médical",
      SOCIAL: "Social",
    },
    VACCINE_TYPES: {
      ADULT: "Adulte",
      CHILD: "Enfant",
    },
    VACCINE_TIMELINE: {
      DURING_CHILDHOOD: "Dans l'enfance",
      DURING_ADULTHOOD: "À l'âge adulte",
    },
    NO_DATA_AVAILABLE: "Aucune donnée disponible",
    TREATMENT_TYPES: {
      CHRONIC_TREATMENTS: "Traitements chroniques",
      ACUTE_TREATMENTS: "Traitements aigus",
      ONESHOT_TREATMENTS: "Traitements one-shot",
    },
    WILLS_STATUSES: {
      authorize: "Accepté",
      refuse: "Refusé",
      undefined: "Non spécifié",
    },
    SUCCESS: "Sumehr données importé avec succès",
    FAILURE: "Échec de l'importation de données Sumehr",
  },
  "patient-invoicing": {
    TITLE: "Attestation",
    LOADING_DATA: "Chargement de vos données",
    PATIENT_NO_INSURABILITY:
      "Pas de données d'assurabilité trouvées pour le patient.",
    PATIENT_NO_VALID_DATA:
      "Le code OA du patient est vide ou invalide, les options eFact et eAttest sont désactivées.",
    paper: "Papier",
    eattest: "eAttest",
    efact: "eFact",
    stat: "stat",
    payingagency: "Organisme payeur",
    patient: "Patient",
    mutualfund: "Mutuel",
    undefined: "Inconnu",
    COBRHA_ERROR: "Impossible d'effectuer une recherche Cobrha pour le moment.",
    PATIENT_NO_SSIN:
      "Le patient n'a pas de NISS enregistré : la facturation électronique est indisponible.",
    UNKNOW_MUT: "Mutuelle inconnue",
    UNKNOW_HCP: "Praticien inconnu",
    PATIENT: "Patient",
    EHEALTH_NOT_CONNECTED: "Vous n'êtes pas connecté aux services eSanté.",
    JUSTIFICATIONS: {
      NONE: "Aucune",
      OTHER: "Autres",
      DEATH: "Décès, coma",
      FINANCIAL_DISTRESS: "Détresse financière",
      BIM: "BIM",
      FORFAIT:
        "Prestation dispensées dans le cadre d'un accord prévoyant le paiement forfaitaire de certaines prestations",
      MENTAL_CENTER:
        "Centre de santé mentale, planning familial, centre de toxicomanie",
      CHILD_OLD_HANDICAP_CARE_CENTER:
        "Etablissement de soins aux enfants, personnes agées ou handicapées",
      CARE_SERVICE: "Service de garde en médecine générale",
      HOME_PALIATIVE_SERVICE: "Soins palliatifs à domicile",
      FORBIDDEN: "Interdiction de facturer en tiers-payant via eFact",
      CHRONICAL: "Maladie chronique",
    },
    ROUNDING: {
      HALF_EURO: "Arrondi au demi-euro",
      EURO_UP: "Arrondi à l'euro supérieur",
    },
    PAYMENT_METHOD: {
      CASH: "Cash",
      BANCOTACT: "Bancontact",
      VIREMENT: "Virement bancaire",
    },
    NETWORK_ERROR: "Echec de connection. Réessayez",
    INVOICE_CREATION: {
      ERROR: "Erreur durant l'envoi eAttest",
      SUCCESS: "L'attestation a été créée avec succès",
      FAILURE: "La sauvegarde de la facture a échoué",
    },
    PREFERENCE_UPDATE_FAILURE: {
      PATIENT: "Erreur lors de la mise à jour des préférences du patient.",
      HCP: "Erreur lors de la mise à jour de vos préférences",
    },
    ETAR: {
      SUCCESS: "Mise à jour des données eTar",
      EHEALTH_CONNECTION_ISSUE: "Service eHealth non connecté",
      MISSING_DATA: "Données eTarification non récupérées",
      ERROR: "Erreur eTar: {{ error }}",
      JUSTIFICATION: "Justification: {{justification}}",
      ACCEPTED: "Engagement de paiement accepté",
      FINANCIAL_CONTRACT: "Numéro de contrat: {{contract}}",
      NO_FINANCIAL_CONTRACT: "Pas d'engagement de paiement",
    },
    PATIENT_INSURABILITY_INVALID:
      "Données d'assurabilité obsolètes depuis le {{date}}, la facture pourrait être refusée par la caisse d'assurance",
  },
  "patient-invoicing-details": {
    SUPPLEMENT_WARNING: "Ce code pourrait être accompagné d’un supplément!",
    MESSAGE1:
      "Le code 469814 a déjà été utilisé cette année et ne peut plus être utilisé pour l'année courante. Utilisez plutôt le code 469630 ou 469652.",
    MESSAGE2:
      "Le code 475856 a déjà été utilisé 2 fois cette année et ne peut plus être utilisé pour l'année courante.",
    MESSAGE3:
      "Le code 475871 a déjà été utilisé 2 fois cette année et ne peut plus être utilisé pour l'année courante.",
    MESSAGE4:
      "Le code 475893 a déjà été utilisé 3 fois cette année et ne peut plus être utilisé pour l'année courante.",
    MESSAGE5:
      "Le code 476210 a déjà été utilisé lors des 12 derniers mois. Ce code n'est utilisable qu'une seule fois tous les 12 mois.",
    MESSAGE6:
      "Le code 149612 a déjà été utilisé lors des 3 dernières années. Ce code n'est utilisable qu'une seule fois tous les 3 ans.",
    MESSAGE7:
      "Le code 101135 a déjà été utilisé plus de 5 fois lors des 30 derniers jours.",
    COVID_CODES_COME_ALONE: "Les codes COVID-19 doivent être attestés seuls.",
    COVID_CODES_REMOVE_SUPPLEMENTS:
      "Les codes COVID-19 vont supprimer les supplements.",
    MISSING_EHEALTH_DATA: "Données d'assurabilité eHealth manquantes.",
    HEALTHCARE_PARTY: "Dispensateur de soins",
    SEARCH_CAREGIVER: "Rechercher un prestataire de soin",
    HEALTHCARE_PARTY_ASSISTANT: "Stagiaire dispensateur de soins",
    DONE_UNDER_AUTHORITY:
      "Fait par {{ firstName }} {{ lastName}} sous l'autorité de son maitre de stage.",
    SEARCH_FOR_YOUR_MASTER:
      "Recherchez votre maître de stage ou vous-même si vous souhaitez facturer en votre nom.",
    ASSISTANT_INVOICING:
      "Si vous ne sélectionnez pas votre maître de stage, vous facturerez en votre nom.",
    ASSISTANT_INVOICING_HELP:
      "Si vous ne trouvez pas votre maître de stage, définissez le dans votre fiche d'intervenant.",
    PRESCRIBER: "Médecin prescripteur",
    HISTORY: "Historique",
    PRESTATION_CODES: "Codes de prestations",
    SEARCH_PRESTATION_CODES: "Rechercher le code de consultation",
    NO_HISTORY_YET: "Pas encore d'historique",
    PRESTATIONS: "Prestations",
    NO_PRESTATION_CODES_YET: "Pas encore de code dans la prestation.",
    SEARCH_CUSTOM_CODES: "Rechercher le code hors INAMI",
    SEARCH_TECHNICAL_CODE: "Rechercher le code acte technique",
    SUPPLEMENTS: "Suppléments",
    NO_SUPPLEMENT: "Pas de supplément",
    EVENING: "Soirée",
    WEEKEND: "Weekend",
    NIGHT: "Nuit",
    GUARD: "Garde (18-21h)",
    CONFIRM: "Valider",
    NEXT: "Suivant",
    PENDING_NEXT: "En attente",
    DELETE_PENDING_INVOICE: "Archiver",
    MODIFY_PENDING_INVOICE: "Sauver",
    FAILED_SUPPLEMENT_RETRIEVE:
      "Impossible de récupérer les suppléments. Vous ne pouvez pas aller plus loin.",
    ONE_CONSULTATION_LIMIT:
      "Un seul code de consultation peut être attesté. Le code précédent est remplacé.",
  },
  "patient-invoicing-pricing": {
    STATISTICS: "Stats",
    DISTANCE:
      '(Distance <span class="font-weight-bold">aller-retour</span> en km)',
    MANDATED_BY_PHYSICIAN: "Envoyé par le médecin traitant",
    WARNING_SPECIFY_PRESCRIBER:
      'vous devez préciser le médecin traitant qui vous a adressé ce patient, en remplissant le champ "Prescripteur" dans le détail de votre code de consultation',
    MISSING_BCE_EXCEPTION:
      "Impossible d'envoyer une eAttest sans que {{ firstName }} {{ lastName }} n'indique son BCE dans son profil.",
    COVID_19_EATTEST_EXCEPTION:
      "Les codes COVID-19 ne peuvent faire l'object d'une eAttest.",
    PRICE_CHOICE: "Choix du prix",
    HISTORY: "Historique",
    OFFICIAL_PRICING: "Prix officiel",
    NO_TM_ASKED: "Pas de ticket moderateur",
    ROUNDED_PRICE: "Prix arrondi",
    CUSTOM_PRICE: "Prix personnalisé",
    SENDING_METHOD_CHOICE: "Choix de la méthode d'envoi",
    CODE: "Code",
    ERROR_CODE: "Erreur code",
    ERROR_DESCRIPTION: "Description",
    ERRORS: "Erreur(s)",
    DATE: "Date",
    EFACT: "eFact",
    EATTEST: "eAttest",
    ETAR: "eTar",
    STATUS: "Statut",
    PAPER: "Papier",
    HONORAIRE: "Honoraire",
    INTERVENTION: "Intervention",
    TICKET_MODERATEUR: "TM",
    ETAR__STATUS: "Statut eTar",
    SPECIALIST: "eAttest n'est pas disponible pour les spécialistes",
    TOTAL: "TOTAL",
    MEDICAL_CARE_CHOICE: "Prise en charge par un organisme payeur tiers",
    NO_MEDICAL_CARE: "Pas de prise en charge",
    FULL_MEDICAL_CARE: "Totalité",
    ONLY_TM: "Ticket modérateur seul",
    ONLY_TM_WARNING:
      "Ticket modérateur seul pour les éléments en eFact ou eAttest",
    PAYMENT_SUMMARY: "Montant payé par",
    PATIENT_FEE: "Le patient",
    MEDICAL_CARE_FEE: "L'organisme payeur",
    EFACT_FEE: "Mutualité - eFact",
    PRINT: "Imprimer le reçu",
    ACCEPTED: "Ok",
    REJECTED: "Pas OK",
    FAILED: "Failed",
    CANCEL: "Annuler",
    BACK: "Retour",
    CONFIRM: "Valider",
    NOT_APPLICABLE_WARNING: "Pas applicable aux éventuels éléments en eAttest.",
    CHECK_ETAR: "Consulter eTar",
    SELECT_OP: "Veuillez sélectionner un organisme payeur.",
    EFACT_101032_101076_WARNING:
      "Une consultation 101032 ou 101076 pour un patient BIM devrait être envoyée en eFact.",
    SELECT_IO_PAYMENT_JUSTIFICATION:
      "Choisissez une justification de tiers-payant",
    SELECT_GMD_MANAGER_INAMI: "Gestionnaire du DMG",
    SEND_DMG_INFOS: "Appliquer le tarif avec DMG",
    SELECT_GMD_MANAGER_INAMI_PLACEHOLDER: "Numéro INAMI",
    EID_NUMBER: "Numéro de carte d'identité",
    MORE_INFO: "Plus d'infos",
    EID_INFOS: "Données de carte d'identité",
    CARD_NUMBER: "Numéro de carte",
    EID_HOUR: "Heure de lecture de la carte",
    DETAILS: "Détails",
    RELATED_CODE: "Prestation relative",
    UNITS: "Unité",
    LOCATION: "Lieu de prestation",
    PRESCRIBER: "Prescripteur",
    PRESCRIPTION_DATE: "Date de prescription",
    OVERRIDE_REIMBURSEMENT: "Intervention",
    OVERRIDE_PATIENT_INTERVENTION: "TM",
    OVERRIDE_REIMBURSEMENT_AND_PATIENT_INTERVENTION:
      "Intervention et TM (valeur officielle)",
    LONG_DELAY_JUSTIFICATION: "Levée de prescription",
    EATTEST_DIFFERENT_THAN_TODAY:
      "Vous ne pouvez pas envoyer une eAttest contenant une prestation qui n'est pas en date d'aujourd'hui",
    EATTEST_MISSING_KHMER_OR_XADES:
      "La réponse eAttest ne continent pas de xades ou de khmerDocument.",
    EATTEST_INCOHERENT_RESPONSE_CODES:
      "La réponse eAttest ne contient pas le même nombre de codes que ceux envoyés.",
    EATTEST_CODE_MAPPING_ERROR:
      "Impossible de lier les codes eAttest locaux et ceux de la réponse",
    NO_OP_PROVIDED: "L'organisme payeur n'est pas fourni ou est invalide.",
    SEND_SUPPLEMENT: "Envoyer les suppléments à l'OA",
    DEROGATION_MAX_NUMBER: "Code prestation identique",
    DEROGATION: {
      NO_DEROGATION: "Pas de dérogation",
      OTHER: "Autre cas",
      DEROGATION_MAX_NUMBER: "Dérogation au nombre maximal",
      OTHER_PRESCRIPTION: "Autre séance/prescription",
      SECOND_PRESTATION_DAY: "2e prestation identique de la journée",
      THIRD_AND_NEXT_PRESTATION_DAY:
        "3e ou suivante prestation identique de la journée",
    },
    PRESCRIBER_NORM: "Norme prescripteur",
    PRESC_NORM: {
      NONE: "Pas de prescripteur",
      ONE_PRESCRIBER: "La prestation peut être attribuée à un prescripteur",
      SELF_PRESCRIBER:
        "Les prestations sont effectueées pour ses propres patients.",
      REFERRING_PHYSICIAN: "Envoyé par le médecin traitant",
      ADDED_CODE: "Il s'agit de prestations ajoutées",
      REBATE: "Facturation à 50%",
      MANY_PRESCRIBERS:
        "La prestation est prescrite par différents prescripteurs. Le numéro d'identification de l'un d'entre eux est enregistré dans la zone du prescripteur.",
    },
    SIDE_CHOICE: "Latéralité",
    SIDE: {
      NONE: "Aucun",
      LEFT: "Gauche",
      RIGHT: "Droite",
    },
    SELECT_THIRD_PARTY_JUSTIFICATION:
      "Choisissez une justification pour le 1/3 payant",
    EATTEST_BIM_PATIENT_ONLY_SEND_WITH_EXCEPTION_CODE:
      "Un patient BIM ne peut pas envoyer d'eAttest si un code d'exception n'est pas inclus",
    EATTEST_CANT_BE_SELECTED: "eAttest ne peut pas être sélectionné",
    EATTEST_WITH_SAME_CODE_SAME_DAY_NOT_ALLOWED:
      "Vous ne pouvez pas envoyer une eAttest pour un même patient avec le même code le même jour",
    Chimiotherapy: "Traitement chimiothérapie ambulant",
    ProfessionalDisease: "Maladies professionnelles",
    WorkAccident: "Accidents de travail",
    Accident: "Accidents - droits communs (responsabilité de tiers)",
    Other: "Autres accidents",
    OTHER: "Autre",
    "hospital-services": {
      "190": "Section des soins néonatals non-intensifs",
      "200": "Service de traitement de la TBC",
      "210": "Service de diagnostic et de traitement chirurgical",
      "220": "Service de diagnostic et de traitement médical",
      "230": "Service de pédiatrie",
      "240": "Service d'hospitalisation simple",
      "250": "Service des maladies contagieuses",
      "260": "Service de maternité",
      "270": "Service de soins néonatals intensifs",
      "290": "Unité de traitement de grands brûlés (créée le 01/01/94)",
      "300": "Service de gériatrie et revalidation",
      "320": "Hôpital chirurgical de jour",
      "340": "Service de neuropsychiatrie infantile (jour et nuit)",
      "350": "Hospitalisation de jour en service K",
      "360": "Hospitalisation de nuit en service K",
      "370": "Service neuropsychiatrique (jour et nuit)",
      "380": "Hospitalisation de jour en service A",
      "390": "Hospitalisation de nuit en service A",
      "410": "Service psychiatrique (jour et nuit)",
      "420": "Hospitalisation de jour en service T",
      "430": "Hospitalisation de nuit en service T",
      "440": "Placement familial Tf dans un hôpital (à partir du 01/04/2000)",
      "450": "Placement familial Tp dans une famille (à partir du 01/04/2000)",
      "480": "Service de traitement intensif des patients psychiatriques",
      "490": "Fonction ou service de soins intensifs",
      "002":
        "Prestations effectuées à des patients hospitalisés dans un autre établissement",
      "710": "Salle de plâtre",
      "720":
        "Soins urgents ou perfusion intraveineuse (ancien miniforfait)\n+ forfait soins de base oncologiques",
      "730": "Maxiforfait + maxiforfait oncologie",
      "750": "Dialyse rénale",
      "760": "Journée forfaitaire en psychiatrie + postcure rééducation",
      "770": "Rééducation",
      "840":
        "Forfait groupe 1, 2, 3, 4, 5, 6 ou 7 + forfait douleur chronique 1, 2, 3 + forfait manipulation cathèter à chambre",
      "990": "Autres patients ambulants ou dialyse rénale ambulatoire",
      "610":
        "Service spécialisé pour le traitement et la réadaptation fonctionnelle destinée à des patients atteints d'affections cardio-pulmonaires",
      "620":
        "Service spécialisé pour le traitement et la réadaptation fonctionnelle destinée à des patients atteints d'affections locomotrices",
      "630":
        "Service spécialisé pour le traitement et la réadaptation fonctionnelle destinée à des patients atteints d'affections neurologiques",
      "640":
        "Service spécialisé pour le traitement et la réadaptation fonctionnelle destinée à des patients atteints d'affections chroniques nécessitant des soins palliatifs",
      "650":
        "Service spécialisé pour le traitement et la réadaptation fonctionnelle destinée à des patients atteints de polypathologies chroniques nécessitant des soins médicaux prolongés",
      "660":
        "Service spécialisé pour le traitement et la réadaptation fonctionnelle destinée à des patients atteints d'affections psychogériatriques",
    },
    HOSPITAL_SERVICE: "Service hospital",
    NO_INVOICE_OF_0: "Attention certains codes ont un montant = 0",
    TREATMENT_REASON: "Raison du traitement",
    BIM_EXCEPTION: "eAttest non-autorisé (patient BIM)",
    REGIONAL_EXCEPTION: "eAttest non-autorisé (code regional)",
    FORBIDDEN_EXCEPTION: "eAttest non-autorisé pour ce code",
    MEDICAL_HOUSE_WARNING:
      "Votre patient est affilié en maison médicale au forfait de",
  },
  "patient-invoicing-summary": {
    FAILED_TO_SEND_TO_EBOX:
      "L'envoi du justificatif eAttest à l'eBox du patient a échoué.",
    EBOX_SENDING_SUCCESS:
      "Le justificatif eAttest a été envoyé à l'eBox du patient.",
    PATIENT_PAID: "Montant payé par le patient",
    PATIENT_REMAINING_AMOUNT: "Montant restant dû par le patient",
    PAYMENT_SUMMARY: "Montant dû par",
    TOTAL_SUMMARY: "Totaux des montants pour",
    PRINT: "Imprimer",
    SEND_TO_EBOX: "Envoyer à l'eBox du patient",
    FINISH: "Clôturer",
    CLOSE: "Fermer",
    CANCEL_EATTEST: "Annuler ou modifier eAttest",
    NOT_APPLICABLE: "Non applicable",
    PATIENT_NOT_FOUND: "Le patient n'a pas été récupéré.",
    ERROR_PRINT_DATE_UPDATED:
      "Impossible de mettre à jour les dates d'impression de l'attestation.",
    DOCUMENT_TITLE: "Justificatif patient",
    INVOICE_NUMBER: "Numéro de suite",
    CARE_PROVIDER: "Médecin superviseur",
    INTERNSHIP: "Médecin stagiaire",
    GMD_MANAGER: "Gestionnaire du DMG",
    PATIENT: "Patient",
    REFUNDABLE_PRESTATIONS: "Prestations remboursables",
    TYPE: "Type",
    NC: "N.C.",
    STATUS: "Statut",
    DATE: "Date",
    CODE: "Code",
    TOTAL_PRICE: "Honoraire porté en compte",
    REIMBURSEMENT: "Intervention",
    PATIENT_INTERVENTION: "Ticket modérateur",
    CONVENTION_AMOUNT: "Honoraire convention",
    DIFF_AND_SUPPLEMENTS: "Différences et suppléments",
    NOT_DISCLOSED: "Non communiqué",
    TOTAL: "Total",
    PAYMENT: "Paiement",
    PRINTED_FOR_PATIENT: "Le justificatif a été remis au patient",
    CANCELLED: "Annulé",
  },
  "patient-invoicing-information": {
    BIM: "Patient BIM",
    PAYMENT_BY_IO: "Tiers Payant",
    INSURABILITY_DATE: "Date d'assurabilité",
    YES: "Oui",
    NO: "Non",
    DMG: "DMG",
    EID_NUMBER: "Numéro de carte d'identité",
    UNKNOWN: "Inconnu",
    DMG_DATA: {
      NO_DMG: "Il n'y a pas de DMG.",
      DMG_UNKNOW: "DMG inconnu.",
    },
  },
  "patient-invoicing-edit": {
    CREDIT_NOTE_TITLE: "Édition de note de crédit",
    DIALOG_TITLE: "Édition de facture",
    INVOICE_EDITION: "Édition de facture",
    CREDIT_NOTE_EDITION: "Édition de la note de crédit",
    PAYMENT_INFORMATIONS: "Informations de paiement",
    PAYMENT_MODE: "Mode de paiement",
    CANCEL: "Annuler",
    SET_CREDIT_NOTE: "Établir une note de crédit",
    ARCHIVE: "Archiver",
    REACTIVATE: "Réactiver",
    APPLY_CORRECTION: "Corriger",
    SAVE: "Enregistrer",
    ERROR_INVOICE_MODIFY: "Impossible de mettre à jour la facture.",
  },
  "patient-invoicing-journal": {
    CANCELED: "Annulée",
    INVOICING: "Attestations",
    REFRESH: "Actualiser",
    DATE: "Date",
    PRINTED: "Remis au patient",
    ATTESTATION_NUMBER: "N° d'attestation",
    TOTAL: "Total",
    TOTAL_PATIENT: "Total patient",
    TOTAL_MUTUALITY: "Total mutuelle",
    TOTAL_OA: "Total organisme payeur",
    EFACT: "eFact",
    EATTEST: "eAttest",
    PAPER: "Papier",
    STAT: "Stat",
    PAYED: "Payé",
    PREVIEW: "Aperçu",
    PAID_AMOUNT: "Montant payé",
    PAID_AMOUNT_LIQUID: "Montant payé liquide",
    PAID_AMOUNT_BANCONTACT: "Montant payé bancontact",
    PAID_AMOUNT_VIREMENT: "Montant payé virement",
    CANCEL_EATTEST: "Annuler une eAttest",
    CLOSE: "Fermer",
    PATIENT: "Patient",
    INVOICING_SUMMARY: "Résumé de l'attestation",
    INVOICE_LOADING_ERROR:
      "Une erreur est survenue durant le chargement des attestations",
    INVOICE_TREATMENT_ERROR:
      "Une erreur est survenue durant le traitement des attestations",
    VIEW_INVOICING: "Voir l'attestation",
    SEARCH_EATTEST_BY_INVOICE: "Recherche d'une attestation par facture",
    SUPERVISOR: "Dispensateur",
    INTERN: "Assistant",
    DOCTOR_INVOICES: "Mes factures",
    ALL_INVOICES: "Toutes les factures",
    OTHER_DOCTOR_INVOICES: "Les factures d'un autre praticien",
    ALL_ASSISTANTS_INVOICES: "Tous les assistants",
    OTHER_ASSISTANTS_INVOICES: "Choisir un assistant",
  },
  "medical-location-filter": {
    ALL_LOCATIONS: "Toutes les entités",
  },
  "patient-pending-invoices": {
    PENDING_INVOICING: "Attestations à clôturer",
    FETCH_ERROR: "Erreur lors de la récupération des attestations",
    INVOICE_ID: "ID",
    INVOICE_DATE: "Date",
    INVOICE_CODES: "Code(s)",
    INVOICE_AUTHOR: "Auteur",
    INVOICE_RECIPIENT: "Patient",
    INVOICE_MEDICAL_LOCATION: "Entité médicale",
    ALL: "Toutes les factures",
    OTHER: "Les factures des autres praticiens",
    MINE: "Mes factures",
  },
  "patient-invoicing-eattest-cancel": {
    WRONG_PATIENT: "Erreur de patient",
    WRONG_CARE_PROVIDER: "Erreur de prestataire",
    MISTAKE_IN_ELEMENTS: "Erreur dans un des éléments de l'ASD",
    DOUBLE_ENCODING: "Double encodage",
    REPLACE_BY_EFACT: "e-Attest remplacée par e-Fact",
    REPLACE_BY_PAPER: "e-Attest remplacée par ASD papier",
    SITUATION_DID_NOT_REQUIRE:
      "Situation ne permettant pas l'établissement d'une e-Attest",
    WRONG_OR_NO_PAYMENT_CHANNEL: "Erreur ou omission sur le mode de paiement",
    WRONG_OR_AMOUNT_ALREADY_PAID:
      "Erreur ou omission sur le montant déjà payé par le patient",
    RECEIPT_DOCUMENT_NOT_PRINTEND:
      "Document Justificatif non remis/non imprimé",
    CANCEL_THE_EATTEST: "Annuler la facture eAttest",
    PRINT_THE_EATTEST: "Imprimer la facture eAttest",
    NOT_CONNECTED_CANCEL:
      "Connectez-vous à eHealth pour annuler la facture eAttest",
    EDIT_THE_EATTEST: "Modifier la facture eAttest",
    CANCEL_SUCCESS: "Annulation de la facture réussie",
    CLOSE: "Fermer",
  },
  "patient-invoicing-confirm": {
    TITLE: "Confirmer le paiement du patient",
    PAYMENT_BY_PATIENT: "Paiement par le patient",
    PAYMENT_MODE: "Mode de paiement",
    ARCHIVE: "Archiver",
    BACK: "Annuler",
    CONFIRM: "Confirmer",
    REACTIVATE: "Réactiver",
    AMOUNT_PATIENT: "Montant à payer par le patient",
    RECEIVED_AMOUNT: "Montant reçu",
    MONEY_BACK: "Montant à rendre",
    WARNINGS: {
      EATTEST:
        "Attention, il est impossible d'annuler une attestation électronique une fois soumise.",
      INSUFFISANT_AMOUNT_EATTEST:
        "Le montant n'est pas suffisant pour payer l'attestation électronique.",
      INSUFFISANT_AMOUNT:
        "Le montant n'est pas suffisant. La prestation est partiellement payée. Voir détails ci-dessous.",
      WARNING_CODE_USED:
        "Les codes suivants ont déjà été utilisés ce jour pour le même patient",
    },
  },
  "invoices-details": {
    ID: "ID",
    DATE: "Date",
    TOTAL: "Total",
    AUTHOR: "Auteur",
    PATIENT: "Patient",
    SSIN: "Numéro national",
    MUTUALITY: "Mutuelle",
    INTERVENTION: "Intervention",
    INVOICING_CODES: "Prestations",
    TICKET_MODERATEUR: "TM",
    INVOICE_TO: "Facturé à",
    INVOICE_TYPE: "Type de facture",
    INVOICE_NUMBER: "N° de facture",
    CREDIT_NOTE: "N.C.",
    PAYMENT_TYPE: "Type de paiement",
    PAYED: "Payé",
    EDIT: "Modifier",
    PAID_AMOUNT: "Montant payé",
    STATUS: "Statut",
    ASSISTANT: "Assistant",
  },
  "base-search-combobox": {
    SEARCH_FAILED: "La recherche a échoué",
  },
  "hcp-search-combobox": {
    SEARCH: "Rechercher un intervenant",
  },
  "user-search-combobox": {
    SEARCH: "Rechercher un utilisateur",
  },
  "country-search-combobox": {
    SEARCH: "Rechercher un pays",
  },
  "nationality-search-combobox": {
    SEARCH: "Rechercher une nationalité",
  },
  "talk-language-search-combobox": {
    SEARCH: "Langue",
  },
  "patient-sumehr": {
    SUMEHR_TITLE: "Sumehr",
    WARNING:
      "Avertissement : Les données présentées dans un SUMEHR ne peuvent pas être considérées comme exhaustives. Ceci est en concordance avec les principes du RSW / RSB / Vitalink. Ces données sont présentes à titre purement indicatif et ne dispensent jamais d'une amnèse circonstanciée.",
  },
  "contact-pdf": {
    PATIENT: "Patient",
  },
  beoogo: {
    AUTHENTICATION_ERROR: "Impossible de se connecter au service Beoogo",
    MISSING_CONFIGURATION: "Aucune configuration du service Beoogo disponible",
  },
  "patient-subscription": {
    TITLE: "Gestion de l'abonnement maison médicale",
    LOADING: "Chargement des abonnements en cours",
    NOT_AVAILABLE: "Ce service n'est pas disponible pour votre qualification",
    SEND_BTN: "Envoyer la demande",
    CLOSURE_BTN: "Envoyer la notification de fin",
    CANCEL_BTN: "Envoyer l'annulation",
    CLOSE_BTN: "Fermer",
    CLOSURE_BTN_MANUALLY: "Clôturer l'abonnement manuellement",
    OPTIONS_BTN: "Modifier",
    NO_CONTRACT: "Pas de contrat",
    manualInputOfContractId: "Encoder le numéro de contrat manuellement",
    successfullyModified: "Modification enregistrée avec succès",
    TABSTRIP: {
      closeSubscription: "Clôturer l'abonnement",
      cancelSubscription: "Annuler l'abonnement ",
      createSubscription: "Créer l'abonnement",
      listOfSubscription: "Liste des abonnements",
      subscriptionOptions: "Options pour la facturation",
    },
    ERROR: {
      title: "Erreur(s)",
      keystoreId: "L'id du keystore est manquant",
      ehPassword: "Le mot de passe ehealth est manquant",
      tokenId: "Le token est manquant",
      nihii: "Le numéro inami est manquant ou invalide",
      firstName: "Le nom du prestataire est manquant",
      patientFirstName: "Le prénom du patient est manquant",
      patientLastName: "Le nom du patient est manquant",
      patientGender: "Le genre du patient est manquant",
      startSubscriptionDate: "La date de début du forfait est manquante",
      isTrial: "La période d'essais est manquante",
      signatureType: "Le type de signature est manquant",
      patientSsin: "Le niss du patient est manquant ou invalide",
      patientIo: "Le n° de la mutuelle est manquant",
      patientIoMembership:
        "Le n° d'inscription mutuelle du patient est manquant",
      subscriptionReference: "Le n° de contrat est manquant",
      subscriptionEndDate: "La date de fin d'abonnement est manquante",
      subscriptionClosureJustification:
        "La justification de cloture est manquante",
      subscriptionClosureType: "L'initiateur de la cloture est manquant",
      errorNoSubscriptionResponse:
        "Numéro de contrat non retourné par l'OA. L'abonnement fut malgré tout créé.",
      noMedicalHouseContract: "Pas de numéro de contrat",
      unSubscriptionUnavailable1:
        "Vous ne pouvez pas désinscrire ce patient car son numéro de contrat est manquant.",
      unSubscriptionUnavailable2:
        "Veuillez s'il vous plait contacter l'OA pour obtenir le numéro de contrat, afin de le mettre à jour.",
      WARNING: "Attention",
    },
    INFO: {
      title: "Informations sur l'abonnement",
      medicalHouse: "Maison médicale",
      startSubscriptionDate: "Date de signature",
      startCoverageDate: "Début de couverture",
      endSubscriptionDate: "Fin de contrat",
      endCoverageDate: "Fin de couverture",
      signatureType: "Type de signature",
      trialPeriod: "Période d'essais",
      type: "Type de forfait",
      status: "Statut",
      closureReason: "Raison de la clôture",
      receipts: "Télécharger les preuves d'abonnement",
      TRIAL: {
        YES: "Oui",
        NO: "Non",
      },
    },
    RECEIPT: {
      xadesCreation: "Création: Xades",
      soapCreation: "Création: SoapResponse",
      requestCreation: "Création: Requête",
      responseCreation: "Création: Réponse",
      soapRequestCreation: "Création SoapRequest",
      xadesCancel: "Annulation: Xades",
      soapRequestCancel: "Annulation: SoapRequest",
      responseCancel: "Annulation: Réponse",
      requestCancel: "Annulation: Requête",
      soapCancel: "Annulation: SoapResponse",
      xadesClosure: "Clôture: Xades",
      soapResponseClosure: "Clôture: SoapResponse",
      soapRequestClosure: "Clôture: SoapRequest",
      responseClosure: "Clôture: Réponse",
      requestClosure: "Clôture: Requête",
    },
    MDA: {
      title:
        "Informations sur le contrat maison médicale obtenues via la consultation données du membre",
      inami: "Inami",
      medicalHouse: "Maison médicale",
      type: "Type de forfait",
      startOfContract: "Date de début",
      endOfContract: "Date de fin",
      address: "Adresse",
      postalCode: "Cp",
      city: "Ville",
      telephoneNumber: "Tél",
    },
    PATIENT: {
      GENDER: {
        male: "Homme",
        female: "Femme",
        unknown: "Indéfini",
        "": "",
        null: "",
        undefined: "",
      },
      title: "Information sur le patient",
      lastName: "Nom",
      firstName: "Prénom",
      gender: "Genre",
      inss: "Niss",
      mutuality: "Mutuelle",
      mutualityNumber: "N° inscription mut.",
    },
    SUBSCRIPTION: {
      STATUS: {
        manual: "Encodage manuel",
        onGoing: "En cours",
        cancelled: "Annulé",
        closed: "Clôturé",
      },
      SIGNATURE: {
        holderPaper: "Signature du document papier",
        holderEid: "Signature via la carte EID",
        legalRepresentativeEid:
          "Signature du document papier par le représentent légal",
        legalrepresentativeEid:
          "Signature du document papier par le représentent légal",
        legalRepresentativePaper:
          "Signature via la carte EID du représentant légal",
        legalrepresentativePaper:
          "Signature via la carte EID du représentant légal",
        unknown: "Inconnu",
      },
    },
    CREATION: {
      title: "Informations sur l'abonnement",
      signature: "Signature",
      subscriptionType: "Type d'abonnemment",
      startOfContract: "Date de signature",
      trialPeriod: "Période d'essais",
      startOfCoverage: "Début de couverture",
      coverageType: "Service(s) disponible(s) dans votre MM",
      coverage: "Service(s) facturé(s) auprès de l'OA",
      signatureType: "Type de signature",
      SIGNATURE_TYPE: {
        holderEid: "Signature via la carte EID",
        holderPaper: "Signature du document papier",
        representativeEid: "Signature via la carte EID du représentant légal",
        representativePaper:
          "Signature du document papier par le représentent légal",
      },
      COVERAGE_TYPE: {
        m: "M",
        k: "K",
        i: "I",
      },
      COVERAGE_INFO: {
        title: "Informations sur la couverture du patient",
        info: "Cette option vous permet uniquement de définir quels services seront facturés auprès de l'OA, le patient lui sera inscrit à tous les services proposés par votre MM",
        subTitle: "Peut-on faire une inscription partielle ?",
        subInfo:
          "Conformément à la réglementation, il n’est pas possible de faire des inscriptions partielles, même si le patient est, par exemple, en MRS. Lorsqu’un patient s’inscrit à une Maison Médicale (MM), il s’inscrit à tous les services offerts par la MM. Il n’y a donc pas la possibilité d’inscrire un patient à une partie des services offerts et ce, quel que soit les circonstances. Aussi, dans le nouveau système, l’inscription est individuelle. En d’autres termes, elle n’implique pas l’inscription automatique des autres membres de la famille. Si le patient a besoin de soins spécifiques, de kiné par exemple, et que la MM n’est pas en mesure d’y répondre, la MM devra assumer le paiement des prestations à l’acte que le patient devrait payer au prestataire de soins et/ou au centre de revalidation.",
        mrsTitle:
          "Qu’en est-il des patients qui sont dans une structure de soins (MRS, MRPA, CSJ, MSP) ?",
        mrsInfo:
          "Au niveau de l’inscription, cela ne change rien. Par contre, au niveau de la facturation, la MM devra facturer les soins de médecin et éventuellement les soins qui ne sont pas offerts par l’institution (voir le tableau ci-dessous).",
        invoiceTitle:
          "Quid si la MM n’est pas au courant que son patient se trouve dans une de ces institutions (MRS, MRPA, CSJ, MSP) ?",
        invoiceInfo:
          "La MM facturera les 3 forfaits et se fera refusé les forfaits en fonction du tableau ci-dessus. Exemple : le patient est en MRS et la MM offre MKI, la MM a facturé les 3 forfaits, les forfaits infirmier et kiné seront rejetés",
      },
      failed: "La creation de l'abonnement n'a pas réussi.",
    },
    CLOSURE: {
      TYPE: {
        "101":
          "101 - Déménagement: le patient n'habite plus dans la zone d'activité",
        "102":
          "102 - Conflit avec le patient ou un membre de la famille du patient",
        "103":
          "103 - Admission dans une institution (soins pédiatriques, soins pour personnes âgées ou autre) en dehors de la zone géographique",
        "104": "104 - Non-respect des règles de la maison médicale",
        "105": "105 - Absence prolongée de contact avec le patient",
        "106":
          "106 - Le patient consulte régulièrement et sans accord des dispensateurs de soins extérieurs à la maison médicale et réclame le remboursement de ces frais",
        "107":
          "107 - Continuité des soins trop difficile à assumer pour la maison médicale",
        "201":
          "201 - Déménagement: le patient n'habite plus dans la zone d'activité",
        "202":
          "202 - Conflit avec un dispensateur de soins ou un membre du personnel de la MM",
        "203":
          "203 - Admission dans une institution (soins pédiatriques, soins pour personnes âgées ou autre)",
        "204": "204 - Désaccord avec les règles de la MM",
        "205":
          "205 - Le patient souhaite consulter des dispensateurs de soins autres que ceux de la MM",
        "206":
          "206 - Prise en charge alternée où l'un des parents n'habite pas dans la zone d'activité",
        "207":
          "207 - Le patient souhaite un dispensateur de soins attitré au sein de la MM mais ne parvient pas à l'obtenir",
        "208": "208 - Raisons personnelles",
      },
      INITIATOR: {
        healthcarepartydecision: "Maison médicale",
        patientdecision: "Patient",
      },
      endOfCoverage: "Fin de couverture",
      justification: "Justification",
      initiator: "Initiateur",
      endOfContract: "Fin de contrat",
      title: "Raison de la cloture de l'abonnement",
      failed: "La clôture de l'abonnent n'a pas réussi.",
    },
    CANCEL: {
      title: "Raison de l'annulation",
      failed: "L'annulation de l'abonnement n'a pas réussi.",
    },
  },
  "patient-beoogo": {
    TITLE: "Inscription MMF",
    START_DATE_MUST_BE_BEFORE_PACKAGE_START_DATE:
      "La date de début du nouveau statut administratif doit précéder la date de début du forfait",
    SUBSCRIPTION_DATE: "Date d'inscription",
    UNSUBSCRIPTION_DATE: "Date de désinscription",
    UNSUBSCRIPTION_REASON: "Motif de désinscription",
    SUBSCRIPTION_TYPE: "Type d'abonnement",
    SUBSCRIPTION_M: "M",
    SUBSCRIPTION_K: "K",
    SUBSCRIPTION_I: "I",
    TRIAL_PERIOD: "Période d'essai",
    SUBMISSION_WARNING:
      "En cliquant sur Envoyer, l'information sera mise à jour dans Beoogo",
    SEND_TO_BEOOGO: "Envoyer",
    CANCEL: "Annuler",
    PATIENT_UNSUBSCRIBED: "Patient désinscrit",
    UNSUBSCRIPTION_REASON_1: "Motif 1",
    UNSUBSCRIPTION_REASON_2: "Motif 2",
    UNSUBSCRIPTION_REASON_3: "Motif 3",
    INSURANCE_0: "Aucun organisme",
    INSURANCE_1: "Organisme hors mutuelle",
    INSURANCE_2: "Mutuelle",
    ACT: "Patient à l'acte",
    SUBSCRIBED: "Patient au forfait",
    AWAITING_SUBSCRIPTION: "En attente de forfait",
    SUBSCRIPTION_NOT_ALLOWED:
      "En attente de forfait et provenant d'une autre maison médicale",
    AWAITING_CLOSURE:
      "En attente de fin de forfait et ayant toujours droit au forfait",
    CLOSED: "Désinscrit du forfait",
    SUBSCRIBE: "Inscrire",
    SUBSCRIBE_AGAIN: "Inscrire à nouveau",
    UNSUBSCRIBE: "Désinscrire",
    NOT_IN_ORDER: "Le patient n'est pas en ordre.",
    SET_ORDER_STATUS: "Mise en ordre du statut",
    SELECT_AT_LEAST_ONE: "Sélectionnez au moins une option.",
    NO_PATIENT:
      " Le patient n'est pas encore inscrit. Pour l'inscrire, remplissez le formulaire ci-dessous.",
    CLOSUREDECISION: "Décision de clôture",
    PATIENT_DECISION: "Décision du patient",
    HEALTHCARE_PARTY_DECISION: "Décision du médecin",
    SIGNATURE_TYPE: "Type de signature",
    PAPER_HOLDER: "Signature Papier",
    LEGALREPRESENTATIVE_HOLDER: "Signature électronique",
    CANCEL_SUBSCRIPTION: "Annuler la souscription",
    TRIALPERIOD: "Période d'essai",
    CLOSUREJUSTIFICATION: {
      "101":
        "101 - Déménagement: le patient n'habite plus dans la zone d'activité",
      "102":
        "102 - Conflit avec le patient ou un membre de la famille du patient",
      "103":
        "103 - Admission dans une institution (soins pédiatriques, soins pour personnes âgées ou autre) en dehors de la zone géographique",
      "104": "104 - Non-respect des règles de la maison médicale",
      "105": "105 - Absence prolongée de contact avec le patient",
      "106":
        "106 - Le patient consulte régulièrement et sans accord des dispensateurs de soins extérieurs à la maison médicale et réclame le remboursement de ces frais",
      "107":
        "107 - Continuité des soins trop difficile à assumer pour la maison médicale",
      "201":
        "201 - Déménagement: le patient n'habite plus dans la zone d'activité",
      "202":
        "202 - Conflit avec un dispensateur de soins ou un membre du personnel de la MM",
      "203":
        "203 - Admission dans une institution (soins pédiatriques, soins pour personnes âgées ou autre)",
      "204": "204 - Désaccord avec les règles de la MM",
      "205":
        "205 - Le patient souhaite consulter des dispensateurs de soins autres que ceux de la MM",
      "206":
        "206 - Prise en charge alternée où l'un des parents n'habite pas dans la zone d'activité",
      "207":
        "207 - Le patient souhaite un dispensateur de soins attitré au sein de la MM mais ne parvient pas à l'obtenir",
      "208": "208 - Raisons personnelles",
    },
    DISPLAY: {
      TITLE: "Afficher",
      PATIENT_DATA: "Données du membre",
      LEGENDS: "Légendes",
      DATA: "Les données",
      HOLDER: "Titulaire",
      START_DATE: "Date de début",
      END_DATE: "Date de fin",
      INSURANCE_TYPE: "Type d'assurance",
      NOTE: "Remarque",
      INSURANCE_CODE: "Code des assurances",
      INSURANCE_REFERENCE: "Référence d'assurance",
      HOLDER_ID: "ID du titulaire",
      HOLDER_CODE: "Code du titulaire",
      PACKAGE_ENABLED_CODES: "Paquets activés",
      STATUS: "Statut",
    },
    DISPLAY_SUBSCRIPTION: {
      THIRD_PARTY: "En provenance d'une autre maison médicale",
      SUBSCRIPTION_DATE: "Date du jour",
      PACKAGE_START_DATE: "Date de début du forfait",
      START_DATE: "Date de début du nouveau statut administratif",
    },
    DISPLAY_UNSUBSCRIPTION: {
      DECEASED: "Patient décédé",
      DECEASED_DATE: "Date du décès",
      DECEASED_CAUSE: "Cause du décès",
      PACKAGE_START_DATE: "Date de début du nouveau package",
      START_DATE: "Date de début du nouveau statut administratif",
    },
    DISPLAY_ORDER_STATUS: {
      STATUS: "Patient déclaré en ordre",
    },
    DISPLAY_EXCLUSION: {
      EXCLUSION_DATE: "Date d'exclusion",
    },
    ERROR: {
      ILLEGAL_START_DATE:
        "La date de début du forfait doit être au minimum le premier du mois prochain. La date de début du forfait doit être postérieure à la date de début du nouveau statut administratif.",
      ILLEGAL_UNSUBSCRIPTION_DATE:
        "La date de désinscription doit être postérieure à la date de souscription au forfait. Veuillez consulter Beoogo.",
    },
  },
  "patient-covid-vaccination": {
    TITLE: "Statut de vaccination covid",
    NOT_AVAILABLE: "Le service n'est pas disponible pour votre qualification",
    SEND: "Activer le patient",
    CANCEL: "Fermer",
    NO_DATA: "Pas de donnée trouvée pour ce patient",
    SYNCHRONIZE: "Récupération des données",
    DATA: {
      title: "Données d'identification",
      lastName: "Nom",
      firstName: "Prénom",
      birthDate: "Date de naiss.",
      ssin: "Niss",
    },
    EXTRA_DOSE_1: {
      TOOLTIP: "Extra dose, e.a pour patients immuno-déprimés",
      TITLE:
        "CRITÈRES DE SÉLECTION : personnes âgées de 12 ans ou plus au 1/8/2021 :",
      TEXT: "<ul><li>Patients atteints de troubles immunitaires congénitaux qui ne sont pas traités par des immunostimulants ou dont le traitement a commencé après le 31/03/2021.</li><li>Patients séropositifs dont le nombre de cellules CD4 est actuellement (= 2021) inférieur à 200 par mm³ de sang.</li><li>Patients atteints leucémie ou d'autres tumeurs malignes, qui ont commencé leur traitement après le 31/3/2021</li><li>Patients ayant commencé la dialyse rénale après le 31/03/2021</li><li>Patients ayant subi une greffe de cellules souches ou d'organes ou patients en attente de greffe diagnostiqués comme tels par les spécialistes après le 31/03/2021.</li><li>Patients atteints de maladies inflammatoires qui sont traités par des immunosuppresseurs et dont le traitement a commencé après le 31/03/2021.</li></ul>",
      CONFIRM: "Confirmer",
      CANCEL: "Annuler",
    },
    VACCINATION: {
      tab: "Rendez-vous",
      title: "Rendez-vous",
      creationDate: "Date de création",
      desactivationDate: "Date de désactivation",
      deactivationReason: "Raison de désactivation",
      vaccineType: "Type de vaccin",
      status: "Statut",
      source: "Source",
      desactivated: "Désactiver le code de vaccination",
      activated: "Activer le code de vaccination",
      alreadyVaccined: "Indiquer que le patient a déjà été vacciné",
      patientRefusal: "Indiquer que le patient refuse la vaccination",
      error: "Erreur lors de la récupération du patient",
      pregnancy: "modifier la priorité pour femme enceinte",
      campaign: "campagne",
      "validity-date": "Date de validité",
      UPDATE: {
        success: "La vaccination a été mise à jour",
      },
      DEACTIVATION: {
        CITIZEN_REFUSE_VACCINATION: "Refus de vaccination",
        CITIZEN_ALREADY_VACCINATED: "Vaccination déjà effectuée",
        "": "",
        null: "",
        success: "La vaccination a bien été désactivée",
        error: "Erreur lors de la désactivation",
        undefined: "",
      },
      ACTIVATION: {
        success: "La vaccination a bien été réactivée",
        error: "Erreur lors de la réactivation",
      },
      STATUS: {
        ACTIVATED: "Activé",
        NOT_ACTIVATED: "Non activé",
        DEACTIVATED: "Désactivé",
        UNKNOWN_SSIN: "Niss inconnu",
        "": "",
        null: "",
        undefined: "",
      },
      SOURCE: {
        RESERVATION_TOOL: "Outil de réservation",
        CIN_NIC: "Mutuelle",
        GP: "Médecin",
        COBRHA: "Entités fédérées",
        CITIZEN: "Citoyen",
        MEDIPRIO1: "Enceinte",
        "": "",
        null: "",
        undefined: "",
      },
      CAMPAIGN: {
        EXTRA_DOSE_1: "Extra dose 1",
        INITIAL: "Initiale",
      },
    },
    INJECTION: {
      tab: "Vaccination(s)",
      title: "Vaccination",
      vaccineDescription: "Description",
      vaccinatorTypeDescription: "Type de vaccinateur",
      vaccineAdministrationDate: "Date",
      vaccineDosesAdministration: "Dose",
      batchNbr: "N° de lot",
      vaccinet: "Lien vers vaccinet",
      vaccineTypeCode: "Code",
      vaccinatorTypeVaccinnetId: "Vaccinateur",
      DOSE_ADMINISTRATION: {
        A: "1/2",
        B: "2/2",
        C: "1/1",
        EXTRA_DOSE_1: "Extra",
      },
    },
    EVENT: {
      title: "Convocation(s)",
    },
    SCHEDULE: {
      title: "Rendez-vous",
      doseNumber: "N° de la dose",
      scheduleDate: "Date de rendez-vous",
      vaccineManufacturer: "Firme pharmaceutique",
      MODE: {
        LETTER: "Lettre",
        SMS: "Sms",
        EMAIL: "Email",
        EBOX: "Ebox",
        "": "",
        null: "",
        undefined: "",
      },
    },
    NOTIFICATION: {
      tab: "Convocation(s)",
      title: "Convocation",
      target: "Cible",
      channel: "Mode",
      date: "Date d'envoi",
      code: "Code vaccination",
    },
    ERROR: {
      ssin: "Le niss du patient est manquant",
    },
    REGISTER: {
      success: "Le patient a bien été inscrit pour la vaccination",
      warning: "Attention, le niss du patient n'est pas connu du service",
      error: "Erreur durant l'envoi de l'inscription ",
    },
    VACCINET: {
      notAvailable: "La connexion vaccinet n'est pas encore active",
    },
  },
  "contact-display": {
    AUTHOR: "Utilisateur",
    INTEGRATOR: "Intervenant",
    AT_TIME: "à {{time}}",
    CHANGE_OPENING_DATE: "Modifier la date d'ouverture",
    EDIT: "Modifier",
    SAVE: "Enregistrer",
    CLOSE: "Clôturer",
    CANCEL: "Annuler",
    DELETE: "Supprimer",
    LAST_SAVE: "Mis à jour",
    PLACEHOLDER: {
      TRANSACTIONREASON: "Motifs",
      SUBJECTIVE: "Subjectif",
      ASSESSMENT: "Diagnostics",
      NOTE: "Notes",
    },
    EDIT_WINDOW_ENDED: "La fenêtre d'édition est dépassée.",
    ADD_SUB_CONTACT: "Ajouter un sous-contact",
    DELETE_CONTACT: "Suppression du contact",
    DELETE_CONTACT_WARNING:
      "Aucun retour en arrière ne sera possible et vous risquez de perdre des données importantes présentes dans ce contact.",
    DELETE_THIS_CONTACT: "Supprimer ce contact",
    CANCEL_PRESCRIPTION:
      "Voulez vous également annuler la ePrescription présente dans ce contact?",
    PRESCRIPTION_CANNOT_BE_REVOKED:
      "L’ordonnance ne peut pas être  révoquée sur Recip-e",
  },
  "contact-type-display": {
    CHANGE_CONTACT_TYPE: "Modifier le type de contact",
    TYPES: {
      consult: "Consultation",
      labresult: "Résultat de laboratoire",
      contactreport: "Rapport",
      telephonicconsultation: "Avis/Divers",
      multidisciplinaryconsult: "Concertation multidisciplinaire",
      homevisit: "Visite à domicile",
      contactfile: "Contact dossier",
      electronicconsultation: "Consultation électronique",
      missedappointment: "Absent au rendez-vous",
      administrativecontact: "Contact administratif",
    },
  },
  "subcontact-health-elements": {
    HEALTH_ELEMENT_ASSOCIATION_SUCCEEDED:
      "L'élément de soin a été associé au sous-contact.",
    HEALTH_ELEMENT_ASSOCIATION_FAILED:
      "L'association de l'élément de soin au sous-contact a échoué.",
    HEALTH_ELEMENT_DISSOCIATION_SUCCEEDED:
      "L'élément de soin a été dissocié du sous-contact.",
    HEALTH_ELEMENT_DISSOCIATION_FAILED:
      "La dissociation de l'élément de soin du sous-contact a échoué.",
    LINK_HEALTH_ELEMENT: "Lier le sous-contact à un élément de soin",
  },
  "health-element-popup": {
    NEW_HEALTH_ELEMENT: "Nouvel élément de soin",
    HEALTH_ELEMENT_CREATION_SUCCEEDED: "L'élément de soin a été créé.",
    HEALTH_ELEMENT_CREATION_FAILED:
      "La création de l'élément de soin a échoué.",
    SEARCH: "Rechercher",
    CREATE_FROM_SUB_CONTACT: "Créer à partir du sous-contact",
  },
  "patient-eid": {
    UPDATE_SUCCESS:
      "Mise à jour des données patient sur base de l'eID réussie.",
    UPDATE_ERROR: "Mise à jour des données patient sur base de l'eID échouée.",
    CREATE_ERROR: "Création d'un patient sur base de l'eID échouée.",
    EID_READ_ERROR: "La lecture de l'eID a échouée",
    EID_TITLE: "Sélectionner un patient",
    PATIENT_SSIN_ALREADY_EXISTS:
      "Un patient ayant le même numéro national que celui lu sur l'eID a été trouvé.",
    CHOOSE_PATIENT: "Veuillez choisir un patient ci-dessous.",
    WRONG_CARD_NUMBER_COMBINATION:
      "De combinatie SSIN / kaartnummer is ongeldig. Controleer de geldigheid van de kaart.",
  },
  "appointment-tags": {
    tags: "Tags",
    IN_WAITING_ROOM: "Présent en salle d'attente",
    CANCELED_BY_PATIENT: "Annulé par le patient",
    CANCELED_BY_PATIENT_TARDILY: "Annulé par le patient tardivement",
    CANCELED_BY_DOCTOR: "Annulé par le médecin",
    DID_NOT_COME: "Pas venu",
    LATE: "En retard",
    BEING_CONSULTED: "En cours de consultation",
    CLOSED: "Clôturé",
    CONFIRM_DOCTOR: "Confirmer médecin",
    CONFIRM_DRIVER: "Confirmer chauffeur",
    CONFIRM_PATIENT: "Confirmer patient",
  },
  "patient-events-search": {
    PATIENT_DOES_NOT_EXISTS: "Le patient n'existe pas ou n'existe plus",
    APPOINTMENT_TYPE: "Type de rendez-vous",
    DATE: "Date",
    STARTTIME: "Heure de début",
    DURATION: "Durée (min)",
    REASON: "Motif",
    STATUS: "Statut",
  },
  "ms-calendar": {
    NO_ACCESS_TO_AGENDA: "Vous n'avez accès à aucun agenda.",
    HIDE_SIDEBAR: "Masquer le panneau de gauche",
    SHOW_SIDEBAR: "Afficher le panneau de gauche",
    HIDE_SIDEBAR_R: "Masquer le panneau de droite",
    SHOW_SIDEBAR_R: "Afficher le panneau de droite",
    NO_ACCESS_TO_EVENTS:
      "Impossible de récupérer les évènements dans vos agendas",
    SEARCH_PATIENT_EVENTS: "Rechercher un rendez-vous",
    GUARDPOST_LOAD_AGENDA_ERROR: "Impossible de trouver l'agenda {{id}}",
    filters: {
      PRINT: "Imprimer",
      DISPONIBILITY: "Disponibilité praticiens",
      SYNC: "Récupérer la liste de rendez-vous",
      MANAGE_CALENDAR_ITEM_TYPES: "Types de RDV",
      MANAGE_CALENDAR_ITEM_PLACES: "Lieux de RDV",
      MANAGE_AGENDA: "Gestion agenda",
      OPEN_KEYWORD_AUTOCOMPLETE: "Afficher l'auto-complétion de mots clés",
      AGENDAS: "Agendas",
      FIRST_NAME: "Prénom",
      LAST_NAME: "Nom",
      DISPLAY_CANCELED_EVENTS: "Afficher les rendez-vous annulés",
      ONLY_ACTIVE:
        "N’afficher que les agendas qui ont une plage de rendez-vous active ce jour",
      EVENT_TYPES: "Types de rendez-vous",
      MIN: "min",
      NAME: "Nom",
      CHOOSE_SPECIALITY: "Choisir une spécialité",
    },
    "allow-overlap-dialog": {
      TITLE: "Plage horaire occupée",
      OTHER_APPOINTMENT:
        "Un autre rendez-vous occupe déjà cette plage horaire.",
      KEEP_YOUR_CHOICE: "Voulez-vous encore y ajouter un second rendez-vous?",
      YES: "Oui",
      NO: "Non",
    },
    scheduler: {
      ZOOM: "Zoom: ",
      RESET: "Réinitialiser",
      TODAY: "Aujourd'hui",
      WEEK: "Semaine",
      DAY: "Jour",
      MY_SCHEDULE: "Mon planning",
      ALL_DAY: "All day",
      messages: {},
    },
    "time-table-list": {
      TITLE: "Liste des horaires",
      AGENDA: "Agenda",
      ADD_TIMETABLE: "Ajouter un horaire",
      NAME: "Nom",
      START: "Début",
      END: "Fin",
      messages: {
        CREATE_TIMETABLE_SUCCESS: "Création de l'horaire effectuée.",
        CREATE_TIMETABLE_ERROR:
          "Une erreur est survenue lors de la création de l'horaire.",
        UPDATE_TIMETABLE_SUCCESS: "Mise à jour de l'horaire effectuée.",
        UPDATE_TIMETABLE_ERROR:
          "Une erreur est survenue lors de la mise à jour de l'horaire.",
        DELETE_TIMETABLE_SUCCESS: "Suppression de l'horaire effectuée.",
        DELETE_TIMETABLE_ERROR:
          "Une erreur est survenue lors de la suppression de l'horaire.",
      },
    },
    "time-table": {
      TITLE: "Édition d'un horaire",
      NAME: "Nom",
      START: "Début",
      END: "Fin",
      ADD_TIMETABLE_ITEM: "Ajouter un élément de l'horaire",
      DAYS: "Jour(s)",
      HOURS: "Heure(s)",
      RECURRENCE: "Récurrence",
      EVERY_WEEK: "Toutes les semaines",
      OTHER: "Autre",
      EVENT_TYPE: "Type de rendez-vous",
      EVENT_PLACE: "Lieu de rendez-vous",
      VISIT: "Visite",
      CONSULTATION: "Consultation",
      SAVE_TIMETABLE: "Enregistrer l'horaire",
      WARNING: {
        REQUIRED_FIELD: "Champ obligatoire",
      },
      days: {
        MONDAY: "Lundi",
        TUESDAY: "Mardi",
        WEDNESDAY: "Mercredi",
        THURSDAY: "Jeudi",
        FRIDAY: "Vendredi",
        SATURDAY: "Samedi",
        SUNDAY: "Dimanche",
      },
    },
    "calendar-item-type-list": {
      TITLE: "Liste des types de rendez-vous",
      ADD_CALENDAR_ITEM_TYPE: "Ajouter un type de rendez-vous",
      NAME: "Nom",
      COLOR: "Couleur",
      DURATION: "Durée (minutes)",
      MINUTE: "minute",
      MINUTES: "minutes",
      messages: {
        CREATE_CALENDAR_ITEM_TYPE_SUCCESS:
          "Création du type de rendez-vous effectuée.",
        CREATE_CALENDAR_ITEM_TYPE_ERROR:
          "Une erreur est survenue lors de la création du type de rendez-vous.",
        UPDATE_CALENDAR_ITEM_TYPE_SUCCESS:
          "Mise à jour du type de rendez-vous effectuée.",
        UPDATE_CALENDAR_ITEM_TYPE_ERROR:
          "Une erreur est survenue lors de la mise à jour du type de rendez-vous.",
        DELETE_CALENDAR_ITEM_TYPE_SUCCESS:
          "Suppression du type de rendez-vous effectuée.",
        DELETE_CALENDAR_ITEM_TYPE_ERROR:
          "Une erreur est survenue lors de la suppression du type de rendez-vous.",
      },
    },
    "calendar-item-place-list": {
      TITLE: "Liste des lieux de rendez-vous",
      ADD_CALENDAR_ITEM_PLACE: "Ajouter un lieu",
      NAME: "Nom",
      messages: {
        CREATE_CALENDAR_ITEM_PLACE_SUCCESS:
          "Création du lieu de rendez-vous effectuée.",
        CREATE_CALENDAR_ITEM_PLACE_ERROR:
          "Une erreur est survenue lors de la création du lieu de rendez-vous.",
        UPDATE_CALENDAR_ITEM_PLACE_SUCCESS:
          "Mise à jour du lieu de rendez-vous effectuée.",
        UPDATE_CALENDAR_ITEM_PLACE_ERROR:
          "Une erreur est survenue lors de la mise à jour du lieu de rendez-vous.",
        DELETE_CALENDAR_ITEM_PLACE_SUCCESS:
          "Suppression du lieu de rendez-vous effectuée.",
        DELETE_CALENDAR_ITEM_PLACE_ERROR:
          "Une erreur est survenue lors de la suppression du lieu de rendez-vous.",
      },
    },
    "calendar-item-place": {
      TITLE: "Édition d'un lieu",
      NAME: "Nom",
      SAVE_PLACE: "Enregistrer le lieu",
    },
    "agenda-management": {
      TITLE: "Gestion agenda",
      NAME: "Nom",
      RIGHTS: "Droits",
      SAVE_AGENDA: "Sauvegarder l'agenda",
      rights: {
        AGENDA_READ: "Lecture",
        AGENDA_WRITE: "Ecriture",
        AGENDA_ADMINISTRATION: "Administration",
      },
      messages: {
        SAVE_AGENDA_SUCCESS:
          "Sauvegarde des droits d'accès à l'agenda effectuée.",
        SAVE_AGENDA_ERROR:
          "Une erreur est survenue lors de la sauvegarde des droits d'accès à l'agenda.",
      },
    },
    "guardPost-service": {
      NEW_ITEMS_AVAILABLE:
        "De nouveaux éléments sont disponibles dans le flux 1733",
      UNABLE_TO_DETERMINE_MEDICAL_LOCATION:
        "Impossible de determiner votre entité, les flux ne seront pas chargés.",
      FOUND_NO_AUTH_TOKEN:
        "L'authentification au serveur de flux 1733 n'est pas configurée.",
      PROBLEM_ENCRYPTION:
        "Il y a un problème avec votre utilisateur et le service du poste médicale de garde du 1733 ne sera bientôt plus disponible. Veuillez contacter le support pour résoudre le problème.",
      DECRYPTED_CALL_FAILED:
        "Une erreur est survenue lors de la récupération du flux 1733. Veuillez mettre à jour Medispring et contacter le support si l'erreur persiste.",
    },
    "event-recurrence-form": {
      REPEAT: "Répéter",
      FREQUENCY: {
        DAILY: "jours",
        WEEKLY: "semaines",
        MONTHLY: "mois",
        YEARLY: "ans",
      },
      WEEKDAYS: {
        MO: "Lundi",
        TU: "Mardi",
        WE: "Mercredi",
        TH: "Jeudi",
        FR: "Vendredi",
        SA: "Samedi",
        SU: "Dimanche",
      },
      EVERY: "Tou(te)s les",
      ON_WEEKDAYS: "Jours de la semaine",
      WEEK_NUMBER_OF_DAY: {
        "1": "premiers",
        "2": "seconds",
        "3": "troisièmes",
        "4": "quatrièmes",
      },
      THE: "Les ",
      OF_THE_MONTH: " du mois",
      APPOINTMENT_COUNT: "Nombre de rendez-vous",
      UNTIL_DATE: "Jusqu'à date",
      WARNING_MAXIMUM_REACHED: "Maximum {{max}} rendez-vous autorisés.",
      APPOINTMENTS: "rendez-vous:",
    },
    "calendar-event-form": {
      TITLE_CREATE_EVENT: "Création d'un rendez-vous",
      TITLE_EDIT_EVENT: "Édition d'un rendez-vous",
      TITLE_CREATE_EVENT_FOR_X: "Création d'un rendez-vous pour {{practitian}}",
      TITLE_EDIT_EVENT_FOR_X: "Édition d'un rendez-vous pour {{practitian}}",
      EVENT_TITLE: "Titre",
      EVENT_TYPE: "Type",
      PATIENT: "Patient",
      CREATE_NEW_PATIENT: "Créer un nouveau patient",
      OPEN_PATIENT: "Ouvrir le dossier patient",
      CLOSE_PATIENT: "Fermer",
      CANCEL_PATIENT: "Annuler",
      PATIENT_DOES_NOT_EXISTS: "Le patient n'existe pas ou n'existe plus",
      IMPORTANT: "Important",
      VISIT_TYPE: "Type de visite",
      VISIT: "Visite",
      CONSULTATION: "Consultation",
      PHONE_NUMBER: "Téléphone de contact de ce rendez-vous",
      PLACE: "Lieu",
      MAP: "Afficher sur une carte",
      GPS: "Envoi vers GPS",
      ADDRESS: "Adresse",
      EVENT_DATE: "Date",
      START_TIME: "Début",
      END_TIME: "Fin",
      DURATION: "Durée (minutes)",
      ALL_DAY: "Toute la journée",
      DETAILS: "Motif",
      DELETE_EVENT: "Supprimer le RDV",
      DELETE_ALL_EVENT: "Supprimer tous les rendez-vous de cette série",
      SAVE_EVENT: "Enregistrer",
      SINCE: "Depuis le",
      MEDICAL_HOUSE_TITLE: "Maison Médicale",
      CREATED_BY: "Créé par",
      THE: "le",
      flow: {
        REASON: "Motif",
        COMMENT: "Commentaire",
        PHONE: "Téléphone",
        TYPE: "Type",
        ADDRESS: "Adresse",
        GPS_COORDINATES: "Coordonnées GPS",
        LATITUDE: "Latitude",
        LONGITUDE: "Longitude",
        ACTIVATE_NOTIFICATION_SOUND: "Activer le son de notification",
        DEACTIVATE_NOTIFICATION_SOUND: "Désactiver le son de notification",
        LAST_UPDATE_DATE: "Dernière mise à jour",
        MARKED_AS_TREATED: "Flux marqueé comme traité",
        DESCR: "Description",
      },
      messages: {
        ERROR_APPOINTMENT_OVERLAP:
          "Ce rendez-vous ne peut être enregistré parce que sa plage horaire est déjà occupée.",
        CREATE_EVENT_SUCCESS: "Création de {{total}} rendez-vous effectuée.",
        CREATE_EVENT_ERROR:
          "Une erreur est survenue lors de la création du rendez-vous.",
        UPDATE_EVENT_SUCCESS: "Mise à jour du rendez-vous effectuée.",
        UPDATE_EVENT_ERROR:
          "Une erreur est survenue lors de la mise à jour du rendez-vous.",
        DELETE_EVENT_SUCCESS: "{{total}} rendez-vous supprimé(s)",
        DELETE_EVENT_SERVER_ERROR:
          "Une erreur est survenue lors de la suppression.",
      },
    },
    "patient-appointment-history": {
      TITLE: "Historique des rendez-vous",
      APPOINTMENT_CREATOR: "Créé par: ",
      CREATION_DATE: "Date de création: ",
    },
    tooltip: {
      DATE_AND_DURATION: "Date et durée",
      MINUTES: "minutes",
    },
    flow: {
      FLOW: "Flux",
      DISPLAY_ALL_FLOW_ITEMS: "Afficher tous les éléments",
      ERROR_GET: "Erreur durant le chargement des éléments du flux 1733",
      item: {
        CANCEL_ELEMENT: "Annuler l'élément",
        PROCESSED_BY: "Traité par",
        CANCELED_BY: "Annulé par",
        PROCESSED_THE: "le",
        CANCELED_THE: "le",
      },
      delete: {
        TITLE: "Annuler un élément du flux",
        REASON: "Motif",
        NOTE: "Note",
        CONFIRM_CANCELLATION: "Confirmer l'annulation",
      },
    },
    "flow-item": {
      VISIT: "Visite",
      CONSULTATION: "Consultation",
      OPINION: "Report de la demande",
      TESTCOVID19: "Test Covid-19",
      COVID19: "Covid-19",
      UNKNOW: "Inconnu",
    },
    "flow-treated-conflict": {
      WARNING_MESSAGE:
        "Le message a été traité par un autre utilisateur, veuillez annuler les actions prises pour ce message pour éviter les événements dupliqués.",
      PATIENT_FIRSTNAME: "Prénom du patient",
      PATIENT_LASTNAME: "Nom du patient",
      TITLE: "Titre",
      STREET: "Rue",
      I_UNDERSTAND: "J'ai compris",
    },
    messages: {
      OUT_OF_BUSINESS_HOURS_WARNING:
        "La période sélectionnée se trouve en dehors des heures de disponibilité.",
      NO_PATIENT_LINKED_TO_EVENT: "Aucun patient lié au rendez-vous.",
      PATIENT_OR_DETAIL_MANDATORY:
        "Il faut renseigner au minimum le patient ou le motif.",
      PATIENT_DOES_NOT_EXISTS:
        "Le patient associé au rendez-vous n'existe pas ou n'existe plus.",
    },
    actions: {
      ACTIONS: "Actions",
      EDIT: "Éditer",
      REMOVE: "Supprimer",
      ADD: "Ajouter",
      UPDATE: "Mettre à jour",
      CANCEL: "Annuler",
    },
  },
  "keywords-editor-wrapper": {
    UNSUPPORTED_FORMAT: {
      WARNING: "Le format de ce document n'est pas supporté.",
      CONSEQUENCE: "Cela peut provoquer une mise en page inattendue.",
    },
  },
  keyword: {
    "keyword-management": {
      ADD_KEYWORD: "Ajouter un mot-clé",
      KEYWORDS: "Mots-clés",
      SUBWORD: "Mot enfant",
      SHORTCUTS: "Raccourcis",
      VALUES: "Valeurs",
    },
    "keyword-autocomplete": {
      TITLE: "Mots clés - auto complétion",
    },
    actions: {
      ACTIONS: "Actions",
      EDIT: "Modifier",
      REMOVE: "Supprimer",
      REMOVE_KEYWORD: "Supprimer le mot-clé",
      ADD: "Ajouter",
      UPDATE: "Enregistrer",
      CANCEL: "Annuler",
      YES: "Oui",
      NO: "Non",
    },
    "toolbar-actions": {
      ADD_SUBWORD: "Ajouter un mot enfant",
      ADD_WORD: "Ajouter un mot",
      DELETE: "Supprimer",
      EDIT: "Modifier",
    },
    messages: {
      CREATE_KEYWORD_SUCCESS: "Création du mot-clé effectuée.",
      CREATE_KEYWORD_ERROR:
        "Une erreur est survenue lors de la création mot-clé.",
      UPDATE_KEYWORD_SUCCESS: "Mise à jour du mot-clé effectuée.",
      UPDATE_KEYWORD_ERROR:
        "Une erreur est survenue lors de la mise à jour du mot-clé.",
      DELETE_KEYWORD_REASK:
        "La suppression du mot-clé supprimera également tous ses raccourcis.",
      DELETE_KEYWORD_SUCCESS: "Suppression du mot-clé effectuée.",
      DELETE_KEYWORD_ERROR:
        "Une erreur est survenue lors de la suppression du mot-clé.",
      DELETE_KEYWORD_ILLEGAL:
        "Vous n'êtes pas le créateur de ce mot-clé et ne pouvez donc pas le supprimer.",
    },
  },
  "patient-posology-scheme-print": {
    MED_CHRONIC_DAILY: "Médicament chroniques journalier",
    NAME: "Nom",
    REMARK: "Remarque",
    MED_CACUTE_DAILY: "Médicament temporaires journalier",
    END_DATE: "Date de fin",
    MED_OTHER_FREQUENCY: "Médicament avec une autre fréquence",
    POSOLOGY: "Posologie",
    TREATMENTS_OF: "Traitements de",
    PAGE: "Page",
  },
  "patient-medication": {
    POSOLOGY: "S/",
    QUANTITY: "Dt/",
    ADMINISTRATION_MODE: "Mode d'administration",
    BOXES: "Boite(s)",
    SAMV2_VERSION: "SAMv2 Version",
    CANNOT_ADD_WHILE_EDITING:
      "Vous ne pouvez pas ajouter un traitement en cours d'édition",
    pdf_prescription: {
      DELIVRANCE_DATE: "Date de fin pour l'exécution",
      INSS: "NISS",
      NIHII: "N° INAMI",
      electronic: {
        PROOF: "PREUVE DE PRESCRIPTION ELECTRONIQUE",
        INFOTOP:
          "Veuillez présenter ce document à votre pharmacien pour scanner le code-barres et vous délivrer les médicaments prescrits",
        PRACTITIONER: "Prescripteur",
        PATIENT: "Bénéficiaire",
        CONTENT: "Contenu de la prescription électronique",
        INFOBOTTOM:
          "Attention : Aucun ajout manuscrit à ce document ne sera pris en compte",
        DATE: "Date",
      },
      paper: {
        PRACTITIONER: "Nom et prénom du prescripteur",
        BITRH_DATE: "Date de naissance",
        PRACTITIONER_FILL: "A REMPLIR PAR LE PRESCRIPTEUR:",
        PATIENT: "Nom et prénom\n du bénéficiaire",
        VIGNETTE: "Réservé à la vignette\ndu conditionnement",
        STAMP: "Cachet du prescripteur",
        DATE_SIGNATURE: "Date et signature du\n prescripteur",
        MEDICATION:
          "PRESCRIPTION DE MEDICAMENTS D'APPLICATION A PARTIR DU 1er Novembre 2019",
      },
    },
    TITLE: "Traitements actifs",
    MEDICATION: "Médication",
    HISTORIQUE: "Historique",
    TREATMENT_HISTORY: "Historique des traitements",
    CHAPITRE_IV: "Chapitre IV",
    E_RECIPE: "Historique des ordonnances",
    CLOSE: "Fermer",
    PRINT_POSOLOGIE: "Imprimer schéma posologique",
    SAVE_POSOLOGIE: "Télécharger schéma posologique",
    CANCEL: "Annuler",
    RESET: "Réinitialiser",
    REGISTER: "Enregistrer",
    REGISTER_AND_PRESCRIBE: "Enregistrer et prescrire",
    CHOOSE_MEDICAMENT: "Choisir un médicament",
    CHOOSE_MAGISTRAL_PERSO: "Choisir une magistrale personelle",
    CHOOSE_DCI: "Choisir une DCI",
    JEUN: "A jeun",
    MORNING: "Mat.",
    PRINT: "Imprimer",
    "10H": "10h",
    LUNCH: "Midi",
    "16H": "16h",
    EVENING: "Soir",
    NIGHT: "Couch.",
    REPAS: "Repas",
    BEFORE: "Av",
    DURING: "Pd",
    AFTER: "Ap",
    "TOUS LES": "Tous les",
    REMARQUES: "Remarques",
    JOURS: "Jours",
    CHRONIQUE: "Chronique",
    ONESHOT: "One-shot",
    FREEPOSOLOGY: "Posologie texte libre",
    DRUGROUTE: "Voie d'administration",
    FROM: "du",
    TO: "au",
    LINK_HEALTH_ELEM: "Lien avec l'élément de soin\u00A0: ",
    PRESCRIPTION_EN_COURS: "Prescription en cours",
    NOTE: "Note",
    TR_DURING: "Traitement en cours",
    TR_AIGU: "Traitement Aigu",
    TR_CHRONIQUE: "Traitement Chronique",
    N_COMP_X_PER_PERIOD: "{{n_comp}} comp {{x_time}}x par {{period}}",
    CBIP_DETAILS: "Plus de détails via le cbip",
    CBIP: "CBIP",
    SAVE: "Enregistrer",
    SAVE_PRINT: "Enregistrer et Imprimer",
    ERECIPE_AND_PRINT: "Envoyer via eRecipe et imprimer",
    ERECIPE_AND_EMAIL: "Envoyer via eRecipe et email",
    EMPTY: "Ordonnance vide",
    ADD_TREATMENT: "Ajouter ce traitement",
    ADD_AND_PRESCRIBE_TREATMENT: "Ajouter et prescrire ce traitement",
    ADD_ALL_TREATMENT: "Ajouter tous les traitements chroniques",
    DELETE_TREATMENT: "Supprimer le traitement",
    STOP_TREATMENT: "Arrêter un traitement",
    DELETE_ORDER: "Supprimer l'ordonnance",
    PRESCRIBER: "Prescripteur",
    ORDER: "Ordonnance",
    BEFORE_LUNCH: "avant le repas",
    DURING_LUNCH: "pendant le repas",
    AFTER_LUNCH: "après le repas",
    A_JEUN: "à jeun",
    A_MORNING: "le matin",
    A_10H: "à 10h",
    A_LUNCH: "à midi",
    A_16H: "à 16h",
    A_EVENING: "le soir",
    A_NIGHT: "au coucher",
    NO_POSOLOGY: "Aucune posologie indiquée",
    PRESCRIBE: "Prescrire sur l'ordonnance",
    TAKE_DURING_X_DAYS_1: "À prendre pendant 1 jour",
    TAKE_DURING_X_DAYS_N: "À prendre pendant {{n}} jours",
    UNITIES: "Unités",
    CHECK_REASON:
      "Votre patient est allergique (ou intolérant) à cette classe de médicament pour la raison suivante :",
    PRESCRIBE_ANYWAY: "Prescrire quand même",
    NOT_PRESCRIBE: "Ne pas prescrire",
    CHAPTER_IV_AVAILABLE: "Remboursement chapitre IV disponible",
    EDIT_TREATMENT: "Modifier ce traitement",
    EDIT_TREATMENT_IN_PROLONG_MODE:
      "Vous pouvez seulement modifier le nombre de jour de ce traitement car il est sur une ordonnance",
    ARCHIVE: "Archiver",
    EDITION_OF: "Edition de : ",
    ADD: "Ajouter",
    NOT_ADD: "Ne pas ajouter",
    ERROR_ATC_CHECK: "Impossible de vérifier les codes ATC",
    ERROR_CNK_CHECK: "Impossible de récupérer le code CNK",
    ERROR_UPLOAD: "L'envoi de l'ordonnance à échoué",
    ERROR_GET_MEDICATION_LIST: "Impossible d'obtenir la liste des médicaments.",
    ERROR_GET_MAP_LIST: "Impossible d'obtenir la liste de vos magistrales.",
    ERROR_GET_PER_LIST:
      "Impossible d'obtenir la liste de vos médicaments personels.",
    ERROR_GET_MORE_INFO_MED:
      "Impossible d'obtenir des informations complémentaires sur le médicament.",
    ERROR_UPLOAD_ERECEIP: "La prescription n'a pas pu être envoyée à Recip-e",
    ERECEIP_CREATIPON_OK: "Prescription envoyée à Recip-e.",
    DELIVRANCE_END_DATE: "Date de fin d'éxécution",
    END_THE: "Fini le",
    CHOSE_PRESCRIPTOR: "Veuillez choisir un prescripteur",
    PRINT_SUCCESS: "Les documents ont été envoyés à votre imprimante",
    PRINT_ERROR: "Erreur lors de l'impression des documents",
    INVALID_REASON:
      "Ce traitement n'a pas pu être importé depuis la migration. Nous vous conseillons d'archiver ce traitement et de le recréer.",
    INVALID_REASON_PRESCRIBR:
      "Vous essayez de prescrire un traitement invalide (affiché en rouge dans la liste des traitements). Veillez archiver ce traitement et le recréer.",
    ORDONNANCE_N_TOO_LONG:
      "Attention : l'ordonnance n°{{n}} est longue et risque d'être mal imprimée.",
    START: "Début",
    DECREASE_TREATMENT: "Retirer ce traitement de la prescription",
    RRSSTATE: {
      R: "Pas bon marché, avec supplément de prix.",
      G: "Bon marché",
      B: "Pas bon marché, pas de supplément de prix.",
    },
    PRICE_EVALUATION: {
      NOT_CHEAP_WITH_PRICE_SUPPLEMENT:
        "Pas bon marché, avec supplément de prix.",
      CHEAP: "Bon marché",
      NOT_CHEAP_NO_PRICE_SUPPLEMENT:
        "Pas bon marché, pas de supplément de prix.",
    },
    SEND_EMAIL: "Email",
    SUBJECT: "Votre prescription",
    BODY: "Bonjour,\nLa prescription que vous avez demandée à bien été générée.\nUne copie de la preuve de prescription est attachée à ce mail. Vous pouvez vous présenter dans la pharmacie de votre choix muni de votre carte d'identité ou du code-barre figurant sur la preuve de prescription.\nIl n'est pas nécessaire d'imprimer ce document.\nBien à vous,",
    SAVING_MEDICATION: "Enregistrement des médicaments",
    DONE_SAVING: "Sauvegarde terminée",
  },
  date: {
    FROM: "Du",
    TO: "au",
  },
  "attachment-service": {
    ERROR_OPEN_TAB: "Imposible de récupérer le fichier.",
  },
  "healthcare-party-service": {
    ERROR_GET_HCPS: "Impossible de récupérer la liste des intervenants",
  },
  "mediction-service": {
    ERROR_NEW_ES:
      "Une erreur est survenue lors de la création d'un élément de soin du à l'archivage du traitement.",
    ERROR_SAVE_CONTACT: "Une erreur est survenue en sauvegardant le contact.",
    ERROR_NEW_CONTACT: "Une erreur est survenue en créant un nouveau contact.",
    PENDING_PRESCRIPTION_SAVED: "L'ordonnance a été sauvée et est en attente.",
  },
  "filter-date": {
    TITLE: "Filtrer sur une période de soin",
    DU: "Du",
    AU: "au",
    OK: "Appliquer le filtre",
    CANCEL: "Annuler",
    LAST_6_MONTHS: "Les 6 derniers mois",
    LAST_MONTH: "Le mois dernier",
    LAST_WEEK: "La semaine passée",
  },
  "information-update": {
    TITLE: "Mise à jour des informations du patient",
    DESC: "De nouvelles informations sont disponibles pour le patient",
    ACTUAL_TITLE: "Informations actuelles",
    NEW_TITLE: "Nouvelles informations",
    SSIN: "NISS",
    NAME: "Nom",
    BIRTH_DATE: "Date de naissance",
    DEATH_DATE: "Date de décès",
    CIVIL_STATUS: "État civil",
    ADDRESS: "Adresse",
    OK: "Mettre à jour les informations",
    CANCEL: "Conserver les informations",
    NATIONALITY: "Nationalité",
    CIVILITY: "Civilité",
    GENDER: "Genre",
    BIRTHPLACE: "Lieu de naissance",
    DEATHPLACE: "Lieu de décès",
    "TEMPORARY-ADDRESS": "Adresse temporaire",
    "POST-ADDRESS": "Adresse postale",
    "DIPLOMATIC-ADDRESS": "Adresse diplomatique",
    "DIPLOMATIC-POST-ADDRESS": "Adresse du post diplomatique",
    "REFERENCE-ADDRESS": "Adresse de référence",
    "RESIDENTIAL-ADDRESS": "Adresse résidentielle",
  },
  "service-search": {
    FIND_CODE: "Rechercher un code",
  },
  "msoap-o": {
    ADD_OBJECTIVE: "Ajouter une entrée",
    SELECTION: {
      BIOMETRY: "Biométrie",
    },
    OBJECTIVE_PLACEHOLDER: "Objectif",
    BOX: {
      HEIGHT: "Taille\u00A0:",
      WEIGHT: "Poids\u00A0:",
      BMI: "IMC\u00A0:",
      HEAD_CIRC: "PC\u00A0:",
      HIP_CIRC: "PA\u00A0:",
      BLOOD_PRESSURE: "TA\u00A0:",
      HEART_RATE: "Pouls\u00A0:",
      GLYCEMIA: "Glyc\u00A0:",
      TEMPERATURE: "T°\u00A0:",
      SATURATION_O2: "SatO²\u00A0:",
      INR: "INR\u00A0:",
      PAIN: "Échelle de douleur: niveau",
    },
    hide: {
      TITLE: "Confirmation",
      CONFIRM: "Êtes-vous sûr de vouloir supprimer cette biometrie ?",
      YES: "Oui",
      NO: "Non",
      HIDE: "Supprimer cette biometrie",
    },
  },
  "msoap-p": {
    PLAN_PLACEHOLDER: "Plan d'action",
    ADD_PLAN: "Ajouter une entrée",
    NEW_PATIENT_INCAPACITY: "ITT",
    NEW_PATIENT_PHYSIOTHERAPY: "Demande kiné",
    NEW_PATIENT_MEDICAL_CARES: "Demande infi",
    NEW_PATIENT_RX: "Demande RX",
    NEW_PATIENT_VACCINE: "Vaccin",
    NEW_PATIENT_PROCEDURE: "Procédure",
    NEW_PATIENT_DOCUMENT: "Documents",
    NEW_PATIENT_HANDICARE: "Formulaire Handicare",
    NEW_PATIENT_COVID_LAB_REQUEST:
      "COVID-19: Demande d’analyse de Laboratoire pour suspicion d’infection au SARS-COV-2",
    NEW_PATIENT_COVID_CONFIRMATION:
      "COVID-19: Signalement d’une suspicion de contamination pour résultats négatifs de Laboratoire SARS-COV-2",
    NEW_PATIENT_COVID_SUSPECTED_CASE:
      "COVID-19: Signaler une suspicion d’infection au SARS-COV-2 sans test",
    NEW_PATIENT_COVID_RAPID_TEST_RESULT:
      "COVID-19: Envoi du résultat du test rapide",
    NEW_PATIENT_MEDICATION: "Prescription",
    "NEW_PATIENT_ECONSULT-DERMATOLOGY": "Application de télédermatologie",
    "REFERRAL-MARIA-MIDDELARES": "Référence AZ Maria Middelares",
    "REFERRAL-UZ-GENT": "Référence hospitalisation d'urgence UZ Gent",
    "REFERRAL-ST-LUCAS-GENT":
      "Référence hospitalisation d'urgence AZ St Lucas Gent",
    "REFERRAL-AZ-JAN-PALFIJN":
      "Lettre de recommandation admission d'urgence AZ Jan Palfijn Gand",
    "PREOP-E17": "Preop E17",
    "KG-ZORG": "Détermination allocation de soins de santé Kind & Gezin",
    "COVID-19-FOLLOW-UP-LOCAL": "COVID-19: COVID-19-FOLLOW-UP-LOCAL",
    "FAGG-COVID-19-VACCINE-SIDE-EFFECTS":
      "COVID-19: Notification d'un effet indésirable d'un vaccin Covid-19",
    "FAGG-MEDICATION-SIDE-EFFECTS":
      "Notification d'un effet indésirable d'un médicament à usage humain",
    COVID_TRACKING: "Suivi tracking covid",
    "DIABETES-DRIVING-CERTIFICATE-G1":
      "Certificat d'aptitude à la conduite pour diabétique type 1",
    "DIABETES-DRIVING-ADVICE-G2":
      "Conseils sur l'aptitude à la conduite pour diabétique type 2",
    FILE: "Pièces jointes",
    ERECEIP_PRESCRIPTION: "Prescription électronique",
    PAPER_PRESCRIPTION: "Prescription papier",
    TASK: "Tâche",
    NEW_PATIENT_TASK: "Tâche",
    TASK_DEADLINE: "dans {{deadline}}",
    TREATMENT: "Traitement",
    SUMMARY: "Résumé",
    ERROR_SAVE: "La sauvegarde à échouée",
    PRINT: "Imprimer",
    MODAL_FILE_TITLE: "Pièce jointe",
    TREATMENT_MODIFIED: "Changé",
    TREATMENT_EXTENDED: "Prolongé",
    TREATMENT_STOPPED: "Stoppé",
  },
  "patient-biometry-dialog": {
    BIOMETRY: "Biométries",
    GLOBAL_NOTES_AND_TASKS: "Notes et tâches globales",
    PRESSURE: "Tension",
    HEART_RATE: "Pouls",
    WEIGHT: "Poids",
    HEIGHT: "Taille",
    BODY_WEIGHT_INDEX: "BMI",
    CRANIAL_CIRCUMFERENCE: "Périmètre crânien",
    ABDOMINAL_CIRCUMFERENCE: "Périmètre abdominal",
    GLYCEMIA: "Glycémie",
    TEMPERATURE: "Température",
    SATURATED_OXYGEN: "Sat. O₂",
    INR: "INR",
    NOTE: "Note",
    FOLLOW_UP: "Suivi",
    VALUE: "Valeur",
    TYPE: "Type",
    REQUESTED_BY: "Demandé par",
    TO_BE_CARRIED_OUT_BY: "À réaliser par",
    TASKS: "Tâches",
    CANCEL: "Annuler",
    SAVE: "Enregistrer",
    UNKNOWN_GENDER:
      "On ne sait pas montrer les données référentielles si le sexe du patient est inconnu.",
    GLOBAL_TASKS: "Tâches globales",
    EXPAND_ME:
      "Aucun résultat. Cliquez sur l'un des éléments pour afficher les détails.",
    TASK_PRESET: {
      FREE_TEXT: "(libellé libre)",
      VASCULAR: "contrôle TA/pouls",
      SATURATION: "contrôle saturation",
      HEIGHT_WEIGHT: "contrôle poids/taille",
      CRANIAL_PERIMETER: "contrôle périmètre crânien",
      ABDOMINAL_PERIMETER: "contrôle périmètre abdominal",
      GLYCEMIA: "contrôle glycémie",
      TEMPERATURE: "contrôle température",
    },
    PANNING_AND_ZOOMING:
      'Pour faire défiler, faites glisser votre souris tout en maintenant le bouton gauche enfoncé. Pour zoomer, utilisez la molette de votre souris. Pour zoomer sur une section spécifique, maintenez la touche "shift" enfoncée, puis cliquez et faites glisser la souris pour sélectionner la section à zoomer.',
    FULLSCREEN_TOOLTIP: {
      OPEN: "Ouvrez le graphique en mode plein écran.",
      CLOSE: "Quitter le mode plein écran.",
    },
    ERRORS: {
      FORM_DISABLED:
        "Une note ou une tâche ne peut être enregistrée sans l’enregistrement une nouvelle valeur",
    },
    WARNING:
      "Attention, si vous fermez ou annulez sans cliquer sur le bouton Enregistrer vos changement ne seront pas pris en compte.",
  },
  "patient-incapacity": {
    CREATION_DATE: "Date de création",
    DAYS: "jour(s)",
    TITLE: "Certificat d'interruption d'activité",
    TITLE_DIXIT: "Attestation d'interruption d'activité",
    TITLE_COVID19: "CERTIFICAT D'INCAPACITE D'ACTIVITE",
    TITLE_MEDICAL_EMP:
      "Certificat d'incapacité de travail salarié - Confidentiel",
    TITLE_MEDICAL_IND:
      "Certificat d'incapacité de travail indépendant - Confidentiel",
    TITLE_MEDEX: "Medex Certificat médical",
    GENERIC: "Générique",
    MEDICAL: "Pour médecin conseil",
    ITT_GENERIC: "ITT générique",
    ITT_MEDICAL: "ITT pour médecin conseil",
    INCAPACITY: "ITT",
    INCAPACITY_MEDICAL: "ITT Med. conseil",
    INCLUDE_MEDICAL: "Inclure l'ITT pour médecin conseil",
    OTHER: "Autre",
    DIXIT: "Certificat Dixit",
    COVID19: "Covid-19 téleconsultation",
    TO: "à",
    MENTION_NOTE:
      "La personne susmentionnée a été mise en incapacité de travail suite à un examen médical",
    template: {
      EMPLOYEE: "Mutuelle employé",
      INDEPENDANT: "Mutuelle indépendant",
      MEDEX: "Medex",
      MEDCONSULT: "Medconsult",
    },
    INCAPACITY_VALUE: "Déclaration d'incapacité à:",
    CHOOSE_INCAPACITY_VALUE:
      "(*Veuillez sélectionner ou encoder au moins une incapacité.)",
    value: {
      WORK: "Travailler",
      SCHOOL: "Fréquenter les cours",
      NURSERY: "Fréquenter la crèche",
      SPORT: "Suivre les cours de gymnastique",
      SWIM: "Suivre les cours de natation",
      EFFORT: "Faire des efforts violents",
      HOMEQUIT:
        "Ne peut quitter son domicile pour des raisons médicales ou sociales",
      OTHER: "Autre",
    },
    INCAPACITY_REASON: "Motif de l'incapacité",
    reason: {
      ILLNESS: "Maladie",
      SURGERY: "Intervention chirurgicale",
      HOSPITALISATION: "Hospitalisation",
      ACCIDENT: "Accident",
      WORKACCIDENT: "Accident de travail",
      FAMILY: "Présence indispensable suite à l'état de santé de",
      OCCUPATIONALDISEASE: "Maladie professionnelle",
      PREGNANCY: "Maladie due à la grossesse",
      ACCIDENT_OCCURED_ON: "survenu le",
      HOSPI_OCCURED_ON: "survenue le",
      OCCUP_DISEASE_DECLARED_ON: "déclarée le",
      family: {
        MOTHER: "Sa mère",
        FATHER: "Son père",
        SON: "Son fils",
        DAUGHTER: "Sa fille",
        HUSBAND: "Son époux",
        WIFE: "Son épouse",
      },
    },
    INCAPACITY_OUTING: "Sortie",
    outing: {
      ALLOWED: "Autorisée",
      FORBIDDEN: "Interdite",
      NOTRECOMMENDED: "Déconseillée",
    },
    INCAPACITY_TYPE: "Type",
    type: {
      GENERIC: "Générique",
      GENERIC_MEDICAL: "Générique + médecin conseil",
      MEDEX: "Medex",
    },
    date: {
      FROM: "du",
      TO: "au",
      NB_DAYS: "jour(s)",
      THAT_IS: "soit",
      INCLUDED: "inclus",
    },
    extension: {
      EXTENSION: "Prolongation",
      BEGINNING: "1ère incapacité",
    },
    INCAPACITY_RESUMPTION: "Reprise",
    resumption: {
      PLANNED_ON: "prévue le",
    },
    INCAPACITY_DIAGNOSIS:
      "Diagnostic (symptôme, trouble fonctionnel, difficulté sociale)",
    INCAPACITY_DIAGNOSIS_ONLY: "Diagnostic",
    INCAPACITY_JOB: "Profession",
    INCAPACITY_STATUS:
      "Situation professionnelle au moment du début de l'incapacité de travail",
    status: {
      WORKER: "Ouvrier",
      EMPLOYEE: "Employé",
      UNEMPLOYED: "Chômeur",
      COMPLSELFEMPLOYED: "Indépendant complémentaire",
      INDEPENDANT: "Indépendant",
      HELPER: "Conjoint aidant",
    },
    INCAPACITY_HOSPI_DATE: "Est ou sera hospitalisé(e) à partir du",
    INCAPACITY_HOSPI_DATE_ONLY: "Date d'hospitalisation",
    INCAPACITY_DELIVERY_DATE:
      "En cas de grossesse, date présumée de l'accouchement",
    INCAPACITY_DELIVERY_DATE_ONLY: "Date présumée de l'accouchement",
    HISTORY: "Historique des incapacités",
    NO_HISTORY: "Aucun historique à afficher",
    error: {
      REQUIRED: "Ce champ est obligatoire",
      POSITIVE_NB: "Valeur minimale acceptée",
    },
    pdf: {
      HEADER:
        "Je soussigné, Docteur en médecine, certifie avoir examiné et interrogé : Mr/Mme <b>{{ patientName }}</b> qui s'est présenté(e) à la consultation ce jour",
      HEADER_DIXIT:
        "Je soussigné, Docteur en médecine, atteste avoir entendu : Mr/Mme <b>{{ patientName }}</b> né(e) le <b>{{ patientBirthDate }}</b>, Il(elle) estime être en incapacité.",
      HEADER_COVID19:
        "Je soussigné, Docteur en médecine, certifie avoir interrogé ce jour par téléphone/vidéoconférence dans le cadre de la pandémie COVID-19",
      INCAPACITY_VALUE: "Incapacité de",
      INCAPACITY_VALUE_DIXIT: "Qui a estimé être incapable de",
      INCAPACITY_REASON: "Pour cause de",
      INCAPACITY_DATES: "Pour une durée de",
      NAME_PATIENT: "Nom, prénom du patient",
      SSIN_PATIENT: "Numéro d'identification du Registre national du patient",
      LEAVE: "Sortie",
      IDENTIFICATION_DOCTOR_NISS: "Identification du médecin avec numéro INAMI",
      DATE: "Date",
      ITT_CONCERNS: "Ce certificat d'incapacité concerne",
    },
    SSIN_REQUIRED:
      "Le numéro de registre natiuonal du patient est nécessaire pour faire une eMediAtt.",
    ONLY_ONE_DIAGNOSIS:
      "Seul un diagnostique est possible avec Medex. Seul le premier figurera dans l'eMediAtt.",
    MISSING_CODIFICATION_DIAGNOSIS:
      "Le premier diagnostique choisis n'est pas compatible avec eHealth (pas de code ICPC et ICD) et ne peux être envoyé à Medex.",
    EH_CONNECTION_REQUIRED:
      "Connectez vous à eHealth pour envoyer une eMediAtt.",
    PRINT_FOR_PATIENT: "Imprimer une copie pour le patient",
    SEND_TO_MEDEX: "Envoyer à Medex",
    E_MEDI_ATT_SENT:
      "Attestation envoyée à Medex. Vous pouvez la consulter via la eHealthBox dans la section 'Messages envoyés'",
    E_MEDI_ATT_SEND_ERR:
      "L'attestation n'a pas été envoyée à Medex (erreur d'envoi)",
    E_MEDI_ATT_GEN_ERR:
      "L'attestation n'a pas été envoyée à Medex (erreur de génération)",
    E_MEDI_ATT_UNKNOW_ERR:
      "L'attestation n'a pas été envoyée à Medex (erreur inconnue)",
    SEND_BY_MAIL: "Envoyer via email",
    SUBJECT: "Votre certificat d'incapacité",
    BODY: "Bonjour, voici en annexe votre certificat d'incapacité demandé",
  },
  "patient-biometry-vascular": {
    SYSTOLIC_BP: "Tension art. sys.",
    DIASTOLIC_BP: "Tension art. dias.",
    SYSTOLIC_DIASTOLIC_BP: "Tension art. sys. / dias.",
    HEART_RATE: "Pouls",
  },
  "patient-biometry-weight-height": {
    WEIGHT: "Poids",
    HEIGHT: "Taille",
    HIP_CIRCUMFERENCE: "Périmètre abdo",
    HEAD_CIRCUMFERENCE: "Périmètre cranien",
    BMI: "IMC",
  },
  "patient-biometry-blood": {
    GLYCEMIA: "Glycémie",
    TEMPERATURE: "T°",
    SATURATIONO2: "Sat O²",
    INR: "INR",
  },
  "patient-biometry-table": {
    DATE: "Date",
    SHOW_VALUES: "Afficher les valeurs sur le graphe",
  },
  "patient-msoap-p-services-common": {
    CLOSE: "Fermer",
    SAVE: "Enregistrer",
    PRINT: "Imprimer",
    SAVE_AND_PRINT: "Enregistrer et imprimer",
    CANCEL: "Annuler",
    EXTENSION: "Prolongation",
    DATE: "Date",
    OTHER_TREATMENT: "Autres traitements",
    MISTER: "Mr",
    MISS: "Mme",
    MISTER_MISS: "Mr/Mme",
    DIAGNOSIS: "Diagnostic",
    DIAGNOSIS_LOCALIZATION: "Localisation",
    ADD_DIAGNOSIS: "Diagnostic du SOAP",
    HISTORY_OTHER_ITEMS: "et {{ number }} autre(s)",
    CHANGE_LANG: "Changer de langue",
    SEARCH_MAX_REACHED:
      "Vous pouvez ajouter au plus {{ maxNumber }} élément(s)",
    SEND_TO_HEALTHBOX: "Envoyer via eHealthBox",
    pdf: {
      PATIENT_LASTNAME: "Nom",
      PATIENT_FIRSTNAME: "Prénom",
      PATIENT_BIRTHDATE: "Date de naissance",
      PATIENT_SSIN: "N° national",
      PATIENT_INSURABILITY: "Mutuelle",
    },
    delete: {
      TITLE: "Confirmation",
      CONFIRM: "Êtes-vous sûr de vouloir supprimer ce service ?",
      YES: "Oui",
      NO: "Non",
    },
    sentTo: "Envoyé à",
  },
  "patient-physiotherapy": {
    TITLE: "Demande de prescription de kiné",
    box: {
      PHYSIOTHERAPY: "Kiné",
      SESSIONS: "Séances",
      FREQUENCY: "sem",
    },
    PHYSIOTHERAPY_SESSIONS: "Séances",
    PHYSIOTHERAPY_NUMBER_SESSIONS: "séance(s) de kinésithérapie",
    PHYSIOTHERAPY_FREQUENCY_SESSIONS: "fois par",
    PHYSIOTHERAPY_TREATMENT: "Traitements complémentaires proposés",
    treatment: {
      PHYSIOTHERAPY: "Physiothérapie",
      SOPHROLOGY: "Sophrologie",
      PRENATALGYM: "Gymnastique prénatale",
      RESPIRATORY: "Kinésithérapie respiratoire",
      WALKING: "Rééducation à la marche",
      LYMPHDRAINAGE: "Drainage lymphatique",
      VESTIBULAR: "Kinésithérapie vestibulaire",
      URODYNAMIC: "Kinésithérapie uro-dynamique",
      SPINALMANIP: "Manipulation vertébrale",
      OTHER: "Autres traitements",
    },
    PHYSIOTHERAPY_NOTE: "Note",
    note: {
      CHOOSE_S: "Contenu de S",
      CHOOSE_O: "Contenu de O",
      S_ADDED: "Anamnèse",
      O_ADDED: "Examen clinique",
    },
    PHYSIOTHERAPY_EXTENSION: "Prolongation",
    PHYSIOTHERAPY_DATE: "Date",
    PHYSIOTHERAPY_MOTIVE: "Motif",
    PHYSIOTHERAPY_MOTIVE_DESC:
      "Pourquoi le patient nécessite plus d'une séance par jour ?",
    PHYSIOTHERAPY_INTERVENTION_DATE: "Date de l'intervention",
    PHYSIOTHERAPY_INAMI: "Code INAMI",
    PHYSIOTHERAPY_PATHOLOGY_TYPE: "Type de pathologie",
    pathologyTypes: {
      HEAVY: "Pathologie lourde (liste E-F)",
      COMMON: "Pathologie courante",
    },
    PHYSIOTHERAPY_NO_MOVE:
      "Ne peut se déplacer pour des raisons médicales ou sociales",
    PHYSIOTHERAPY_NO_MOVE_PRINT_TEXT:
      "Le patient ne peut se déplacer pour des raisons médicales ou sociales",
    REQUEST_REPORT_PRINT_TEXT:
      "Je souhaiterais recevoir un rapport en fin de traitement",
    HISTORY: "Historique",
    NO_HISTORY: "Aucun historique à afficher",
    history: {
      OTHER_DIAGNOSIS: "et {{ number }} autre(s)",
      NO_DIAGNOSIS: "Pas de diagnostic",
      SESSIONS: "séance(s)",
    },
    pdf: {
      TITLE: "Prescription de kinésithérapie",
    },
    POST_TRAUMATIC:
      "Séance de kinésithérapie post-traumatiques ou post-chirurgicales",
    REQUEST_REPORT: "Demander un rapport en fin de traitement",
  },
  "patient-medical-cares": {
    TITLE: "Demande de soins infirmiers",
    box: {
      MEDICAL_CARES: "Infi",
      SESSIONS: "Séances",
      FREQUENCY: "sem",
    },
    MEDICAL_CARES_NUMBER_SESSIONS: "Nombre de séances",
    MEDICAL_CARES_FREQUENCY_SESSIONS: "séances par",
    MEDICAL_CARES_NOTE: "Informations complémentaires",
    MEDICAL_CARES_TREATMENT: "Soins",
    treatment: {
      MEDICATION: "Préparation et administration de médicaments",
      medication: {
        MEDICATION: "Médicaments",
        ADMINISTRATION: "Mode d'administration",
        DOSAGE: "Posologie",
        MIXING: "Mélange autorisé (si injection de plusieurs produits)",
      },
      WOUNDCARE: "Soins de plaie",
      woundcare: {
        TYPE: "Type de plaie",
        ADVICE: "Conseils éventuels",
      },
      HYGIENE: "Toilette et soins d'hygiène",
      COMPRESSION: "Thérapie de compression",
      compression: {
        TYPE: "Type de compression",
        MODE: "Modalité de compression",
        TARGET: "Objectifs à atteindre",
        STOCKINGS: "Bas",
        BANDAGE: "Bande",
        PLACEMENT: "Placement",
        PLACEMENTREMOVAL: "Placement et retrait",
      },
      MATERIALREMOVAL: "Enlèvement de matériel de suture, de mèche ou cathéter",
      removal: {
        TYPE: "Type de soins",
        DATE: "Date d'enlèvement",
      },
      CATHETERS: "Sondes",
      catheters: {
        TYPE: "Type",
        ADVICE: "Conseils éventuels",
        BLADDER: "Surveillance d'une sonde vésicale à demeure",
        INOUT: "Sondage in out",
        PUBIC: "Cathéter sus pubien",
      },
      OSTOMY: "Stomies : surveillance et soins",
      WEEKLYCALENDAR: "Élaboration du semainier",
      DAILYADMINISTRATION:
        "Administration quotidienne de médicaments (patient psychiatrique)",
      OTHER: "Autres soins",
    },
    HISTORY: "Historique",
    NO_HISTORY: "Aucun historique à afficher",
    history: {
      OTHER_TREATMENTS: "et {{ number }} autre(s)",
      NO_TREATMENT: "Pas de traitement",
    },
    pdf: {
      TITLE: "Prescription de soins infirmiers",
    },
  },
  "patient-x-ray": {
    TITLE: "Demande de radiographie",
    box: {
      X_RAY: "RX",
    },
    SEARCH: "Examens",
    search: {
      PLACEHOLDER: "Choisir un examen",
    },
    CLINICAL_INFO: "Informations cliniques pertinentes",
    "clinical-info": {
      CHOOSE_S: "Contenu de S",
      CHOOSE_O: "Contenu de O",
      S_ADDED: "Anamnèse",
      O_ADDED: "Examen clinique",
    },
    DIAGNOSIS: "Explication de la demande de diagnostic",
    OTHER_INFO: "Informations supplémentaires pertinentes",
    "other-info": {
      ALLERGY: "Allergie",
      DIABETES: "Diabète",
      PREGNANT: "Grossesse",
      IMPLANT: "Implant",
      RENALFAILURE: "Insuffisance rénale",
      OTHER: "Autre",
    },
    PREVIOUS_EXAMS: "Examen(s) précédant la demande",
    "previous-exams": {
      MRI: "IRM",
      XRAY: "RX",
      CTSCAN: "CT-Scan",
      ECHO: "Echo",
    },
    HISTORY: "Historique",
    NO_HISTORY: "Aucun historique à afficher",
    history: {
      NO_RADIOGRAPHY: "Pas d'examen",
      NO_DIAGNOSIS: "Pas de diagnostic",
    },
    pdf: {
      TITLE: "Formulaire de demande pour un examen en imagerie médicale",
    },
  },
  "patient-procedure": {
    TITLE: "Procédure",
    box: {
      PROCEDURE: "Procédure",
    },
    NOTE: "Note",
    SEARCH: "Procédure",
    search: {
      PLACEHOLDER: "Choisir une procédure",
    },
  },
  "magistral-management": {
    MAGISTRAL_MANAGEMENT: "Gestion des magistrales",
    RESET: "Réinitialiser",
    SAVE: "Enregistrer",
    DELETE: "Supprimer",
    EDIT: "Modifier",
    EDIT_NOT_MINE: "Ce médicament privé a été créé par un autre utilisateur.",
    PRINT: "Imprimer",
    CLASS: "Classe",
    NAME: "Nom",
    QUANTITY: "Dt/",
    UNITY: "Unité",
    SHAPE: "Pf/",
    NOTE: "Note",
    FORMULA: "R/",
    POSOLOGY: "S/",
    NEW_CLASS: "Nouvelle classe",
    NEW_CLASS_TITLE: "Créer une nouvelle classe",
    SEARCH: "Trouver des magistrales",
    ERROR_SAVE: "Impossible d'enregistrer vos magistrales",
    ERROR_GET: "Impossible de récupérer vos magistrales",
    ERROR_GET_PERSONAL: "Impossible de récupérer vos médicaments personels",
    actions: {
      REMOVE: "Supprimer",
      REMOVE_MAGISTRAL: "Supprimer la magistrale",
      CANCEL: "Annuler",
    },
    messages: {
      DELETE_MAGISTRAL_REASK:
        "Etes-vous sûr de vouloir supprimer la magistrale ?",
      DELETE_MAGISTRAL_SUCCESS: "Suppression de la magistrale effectuée.",
      DELETE_MAGISTRAL_ERROR:
        "Une erreur est survenue lors de la suppression de la magistrale.",
    },
  },
  "patient-history": {
    SORT_TREATMENT: " Trier les traitements par :",
    DATE: "Date",
    HEALTH_ELEMENT: "Élément de soin",
    BEGIN: "Début",
    END: "Fin",
    TREATMENT: "Traitement",
    POSOLOGY: "Posologie",
    MOTIF_STOP: "Motif d'arrêt",
    PRESCRIPTOR: "Prescripteur",
    CNK: "CNK code",
    UNSPECIFIED: "Non spécifié",
    OUTDATED: "Historique",
    NOTE: "Remarque",
    BOXES: "{{boxes}} boîtes",
    RENEW: "Prescrire de nouveau",
    "date-filter": {
      PERIOD: "Période",
      LAST_6_MONTH: "Les 6 derniers mois",
      ALL_TIME: "Pas de filtre",
      BEFORE_YEAR: "Avant {{year}}",
    },
    atc_filter: {
      ATC_CLASS: "ATC Class",
      ALL_CLASSES: "All classes",
    },
    "text-filter": {
      SEARCH: "Rechercher",
    },
    MEDICATION_LINES: "Lignes de médications",
  },
  "stop-treatment": {
    STOP_TREATMENT: "Arrêter un traitement",
    REASON: "Motif",
    COMMENT: "Commentaire",
    OPTIONAL: "Optionnel",
    OK: "Enregistrer",
    CANCEL: "Annuler",
    reasons: {
      ALLERGY: "Allergie",
      INTOLERANCE: "Intolérance",
      UNEXPECTED_SIDE_EFFECTS: "Effets indésirables",
      INEFFICIENT: "Inefficacité",
      REFUSAL_PATIENT: "Refus du patient",
      endOfTreatment: "Fin de traitement",
      packagingChange: "Changement de conditionnement",
      other: "Autre",
    },
  },
  diagnostic: {
    SCIENSANO: "Covid 19: Procédures Sciensano",
  },
  scales: {
    SERVICE_EXIST: "Le service existe",
    pain: {
      TITLE: "Échelle de douleur",
      QUESTION:
        "Sur une échelle de 0 à 10, quel est le niveau de douleur du patient?",
      CANCEL: "Annuler",
      PROCESS: "Terminer l'évaluation",
      ANSWER_COMMENTS: {
        "0": "Aucune douleur",
        "1": "Douleur très légère",
        "2": "Douleur légère",
        "3": "Douleur légère",
        "4": "Douleur moyenne",
        "5": "Douleur moyenne",
        "6": "Douleur moyenne",
        "7": "Douleur sévère",
        "8": "Douleur sévère",
        "9": "Douleur très sévère",
        "10": "Douleur insupportable",
      },
    },
    belrai: {
      TITLE: "Échelle Belrai",
      OPEN_BELRAI: "ouvrir Belrai",
      SCALE: "Échelle",
      SCORE: "Score",
      REPORT: "Rapport",
      CHOOSE_FILE: "choisir un fichier",
      CANCELED: "Annuler",
      SAVE: "Enregistrer",
      SCALE_WITH_TYPE_EXIST: "l'échelle de ce type existe déjà",
      COPY_SSIN: "Copier le NISS du patient",
      PATIENT_SSIN: "NISS du Patient",
      COPY_SUCCESS: "Copie du NISS réussie",
      COPY_FAILED: "Copie du NISS échoué",
      DROP_REPORT_HERE: "Déposer le rapport ici",
      OR: "ou",
      DROP_HERE: "Déposer ici",
      types: {
        BELRAI_SCREENER: "Belrai screener",
        HOME_CARE: "Home Care",
        LONG_TERM_CARE_FACILITIES: "Long Term Care Facilities",
        PALLIATIVE_CARE: "Palliative care",
        MENTAL_HEALTH: "Mental Health",
        COMMUNITY_MENTAL_HEALTH: "Community mental Health",
        PALLIATIVE_SCREENER: "Palliative Screener",
        AC_ADMISSION_ASSESSMENT: "Acute Care - Admission Assessment",
        AC_DISCHARGE_ASSESSMENT: "Acute Care - Discharge Assessment",
        AC_CGA_ADMISSION_ADMISSION:
          "Acute Care - Comprehensive Geriatric Assessment Admission",
        AC_CGA_ADMISSION_DISCHARGE:
          "Acute Care - Comprehensive Geriatric Assessment Discharge",
        AC_CGA_ADMISSION_PREMORBID:
          "Acute Care - Comprehensive Geriatric Assessment Pre-morbid",
        AC_CGA_ADMISSION_REVIEW:
          "Acute Care - Comprehensive Geriatric Assessment Review",
      },
    },
    katz: {
      TITLE: "Échelle de Katz",
      CANCEL: "Annuler",
      NEXT: "Suivant",
      PREV: "Précédent",
      FINISH: "Terminer l’évaluation",
      PRINT: "Imprimer",
      FORM: "Formulaire",
      RESULTS: "Résultats",
      SCORE_KATZ: "Score Katz",
      CONCLUSION: "Conclusion",
      questions: {
        wash_yourself: {
          question: "Se laver",
          answers: {
            "0": "est capable de se laver complètement sans aucune aide",
            "1": "a besoin d'une aide partielle pour se laver au dessus ou en dessous de la ceinture",
            "2": "a besoin d'une aide partielle pour se laver tant au dessus qu'en dessous de la ceinture",
            "3": "doit être entièrement aidé pour se laver tant au dessus qu'en dessous de la ceinture",
          },
        },
        get_dressed: {
          question: "S'habiller",
          answers: {
            "0": "est capable de s'habiller et se déshabiller complètement sans aucune aide",
            "1": "a besoin d'une aide partielle pour s'habiller au dessus ou en dessous de la ceinture (sans tenir compte des lacets)",
            "2": "a besoin d'une aide partielle pour s'habiller tant au dessus qu'en dessous de la ceinture",
            "3": "doit être entièrement aidé pour s'habiller tant au dessus qu'en dessous de la ceinture ",
          },
        },
        transfer_relocation: {
          question: "Transfert et déplacement",
          answers: {
            "0": "est autonome pour le transfert et se déplace de façon entièrement indépendante, sans auxiliaire(s) mécanique(s), ni aide de tiers",
            "1": "est autonome pour le transfert et ses déplacements moyennant l'utilisation d'auxiliaire mécanique (béquille(s), chaise roulante, ...)",
            "2": "a absolument besoin de l'aide de tiers pour au moins un des transferts et/ou ses déplacements",
            "3": "est grabataire ou en chaise roulante et dépend entièrement des autres pour se déplacer",
          },
        },
        go_toilet: {
          question: "Aller à la toilette",
          answers: {
            "0": "est capable d'aller seul à la toilette, de s'habiller ou de s'essuyer",
            "1": "a besoin d'aide pour un des trois items : se déplacer ou s'habiller ou s'essuyer",
            "2": "a besoin d'aide pour deux des trois items : se déplacer et/ou s'habiller et/ou s'essuyer",
            "3": "a besoin d'aide pour les trois items : se déplacer et s'habiller et s'essuyer",
          },
        },
        continence: {
          question: "Continence",
          answers: {
            "0": "est continent pour les urines et les selles",
            "1": "est accidentellement incontinent pour les urines ou les selles (sonde vésicale ou anus artificiel compris)",
            "2": "est incontinent pour les urines (y compris exercices de miction) ou les selles",
            "3": "est incontinent pour les urines et les selles",
          },
        },
        incontinence: {
          question:
            "Incontinence combinée: une combinaison de l'incontinence d'urine nocturne et de l'incontinence d'urine occasionnelle durant la journée a été constatée chez le bénéficiaire",
          answers: {
            "0": "oui",
            "1": "non",
          },
        },
        eat: {
          question: "Manger",
          answers: {
            "0": "est capable de manger et de boire seul",
            "1": "a besoin d'une aide préalable pour manger ou boire",
            "2": "a besoin d'une aide partielle pendant qu'il mange ou boit",
            "3": "le patient est totalement dépendant pour manger et boire",
          },
        },
        is_dementia: {
          question:
            "Le patient a été diagnostiqué comme souffrant de démence à la suite d'un bilan diagnostique spécialisé",
          answers: {
            "0": "oui",
            "1": "non",
          },
        },
      },
      results: {
        forfait_c: "Forfait C",
        forfait_b: "Forfait B",
        forfait_a: "Forfait A",
        toilets0: "Toilettes 0x/semaine",
        toilets2: "Toilettes 2x/semaine",
        toilets7: "Toilettes 7x/semaine",
        incontinence: {
          true: "Avec incontinence combinée",
          false: "Sans incontinence combinée",
        },
        is_dementia: {
          true: "Avec démence",
          false: "Sans démence",
        },
      },
      DOC_FOOTER: "Donne connaissance au",
      PDF: {
        TITLE: "Echelle Katz à Domicile",
        SUB_TITLE:
          "A envoyer sous enveloppe fermée au médecin-conseilSoins infirmiers - Echelle d'évaluation",
        SUB_TITLE_1:
          "- justifiant la demande d'honoraires forfaitaires par journée de soins (1)",
        SUB_TITLE_2: "- notifiant des soins de toilette (1)",
        TITLE_PATIENT_INFO: "Données d’identification du bénéficiaire",
        NAME: "Nom et prénom",
        SSIN: "Niss",
        DATE_OF_BIRTH: "Date de naissance",
        ADDRESS: "Adresse",
        INSURANCE: "MUTUELLE",
        ESTABLISHED_BY: "Etabli par",
        NIHII: "Inami",
        THE: "Le",
        SCORE: "Score",
      },
    },
  },
  "soap-templating": {
    PATIENT_ORIENTATION: "Orientation patient",
    TERMINATE: "Rapport de garde",
    SAVE: "Enregistrer",
    NO_REFERRING_PHY:
      "Le patient n'a pas de médecin traitant défini dans sa fiche.",
    RAPPORT: "Rapport",
    ERROR_TEMPLATING: "Erreur de génération du template",
    SERVER_URL: "L'url du serveur ne repond pas",
    SERVER_ERROR: "Erreur interne au serveur",
    PATIENT_ORIENTATION_OPTIONS: {
      HOME: "Retour à domicile",
      HOSPITALIZATION: "Hospitalisation",
      EMERGENCY: "Envoi vers le service des urgences",
      DECEASED: "Patient décédé",
      REFERRING_PHYSICIAN: "Médecin traitant",
      CONTRACTOR: "Autre prestataire",
    },
    NO_REFERRING_PHY_NIHII:
      "Le médecin traitant du patient n'a pas de numéro inami.",
  },
  "templating-view": {
    TERMINATE: "Clôturer",
    TERMINATE_SEND: "Valider et envoyer via eHealth",
    TERMINATE_PRINT: "Imprimer",
    SEND_TO_HEALTHBOX_PDF: "Envoyer via eHealthBox (PDF)",
    SEND_TO_HEALTHBOX_H1: "Envoyer via eHealthBox (H1)",
    SEND_TO_HEALTHBOX_MEDIDOC: "Envoyer via eHealthBox (Medidoc)",
    VALIDATE: "Envoyer",
  },
  "templating-tree-view": {
    child: {
      referring: {
        label: "Destinataire du courrier",
        child: {
          "lastName.label": "Nom",
          "firstName.label": "Prénom",
          "adresse.label": "Adresse",
          "houseNumber.label": "Numéro de maison",
          "postboxNumber.label": "Numéro de boite",
          "town.label": "Ville",
          "townCode.label": "Code postal",
          "tel.label": "Téléphone",
          "gsm.label": "GSM",
          "mail.label": "eMail",
        },
      },
      doctor: {
        label: "Utilisateur",
        child: {
          "lastName.label": "Nom",
          "firstName.label": "Prenom",
          "adresse.label": "Adresse",
          "houseNumber.label": "Numéro de maison",
          "postboxNumber.label": "Numéro de boite",
          "town.label": "Ville",
          "townCode.label": "Code Postal",
          "tel.label": "Téléphone",
          "gsm.label": "GSM",
          "mail.label": "eMail",
          "inami.label": "Inami",
          "bank.label": "Banque",
        },
      },
      patient: {
        label: "Patient",
        child: {
          "lastName.label": "Nom",
          "firstName.label": "Prenom",
          "gender.label": "Genre",
          "dateOfBirth.label": "Date de naissance",
          "dateOfDeath.label": "Date de mort",
          "placeOfBirth.label": "Lieu de naissance",
          "placeOfDeath.label": "Lieu de mort",
          "adresse.label": "Adresse",
          "houseNumber.label": "Numéro de maison",
          "town.label": "Ville",
          "townCode.label": "Code postal",
          "tel.label": "Téléphone",
          "gsm.label": "GSM",
          "mail.label": "eMail",
          "ssin.label": "N° de registre national",
          "mutualityNum.label": "Numéro de mutuelle",
          "titularCode1.label": "Code titulaire mutuelle 1",
          "titularCode2.label": "Code titulaire mutuelle 2",
          "titularyId.label": "Numéro d'inscription à la mutuelle",
        },
      },
      soaps: {
        label: "Contact",
        child: {
          "contactDate.label": "Date de Contact",
          "motifs.label": "Motifs",
          "subjectif.label": "Sujet",
          "objectif.label": "Objectif",
          "diagnostic.label": "Diagnostique",
          "prescriptions.label": "Plan d'action (texte libre)",
          "note.label": "Note",
          "orientation.label": "Orientation",
          itt: {
            label: "Itt",
            child: {
              "dixit.label": "Dixit",
              "startDate.label": "Date de début",
              "endDate.label": "Date de fin",
              "extension.label": "Prologation",
              "resumption.label": "Date de Reprise",
              "reason.label": "Motif",
              "value.label": "Incapacité",
              "outing.label": "Sortie",
            },
          },
          kine: {
            label: "Kiné",
            child: {
              "seanceNumber.label": "Nbe de séances",
              "numberByWeek.label": "Combien de fois semaine",
              "diagnosis.label": "Diagonstic",
              "note.label": "Demandes complémentaires",
            },
          },
          rx: {
            label: "Rx",
            child: {
              "radiography.label": "Examens de RX",
              "clinicalInfo.label": "Informations cliniques pertinentes de RX",
              "otherInfo.label": "Informations supplémentaire pertinente",
              "otherOtherInfo.label": "Informations supplémentaire",
              "previousExams.label": "Examen précedants",
              "diagnosis.label": "Diagnostic de RX",
              "date.label": "Date de RX",
            },
          },
          infi: {
            label: "Infi",
            child: {
              "seanceNumber.label": "Nbe de séances",
              "frequency.label": "Fréquence",
              "treatment.label": "Type de soins",
            },
          },
          medication: {
            label: "Médication",
            child: {
              "treatment.label": "Médicaments prescrits",
            },
          },
          biometrie: {
            label: "Biometrie",
            child: {
              "weight.label": "Poids",
              "height.label": "Taille",
              "sbp.label": "Systole",
              "dbp.label": "Siastole",
              "heartrate.label": "Pouls",
              "bmi.label": "IMC",
              "hipcircumference.label": "Circonférence abdominale",
              "headcircumference.label": "Circonférence craniale",
              "glycemia.label": "Glycémie",
              "temperature.label": "Temperature",
              "saturationO2.label": "Saturation O2",
              "inr.label": "INR",
            },
          },
        },
      },
      summary: {
        label: "Données du sommaire",
        child: {
          "problems.label": "Problèmes",
          "administrativeNote.label": "Note administrative",
          "medicalBackground.label": "Antécédents médicaux",
          "surgicalHistory.label": "Antécédents chirurgicaux",
          "gynecologicalHistory.label": "Antécédents gynéco-obstétricaux",
          "familyHistory.label": "Antécédents familiaux",
          "socialRiskFactors.label": "Facteurs de risque social",
          "medicalRiskfactor.label": "Facteur de risque médical",
          "allergy.label": "Allergie",
          "intolerances.label": "Intolérances",
          "vaccines.label": "Vaccins",
          "preventions.label": "Préventions",
          "chronicTreatment.label": "Traitement chronique",
          "gs.label": "Groupe sanguin",
          "rh.label": "Rhésus",
          "sgs.label": "Sous groupe sanguin",
          "antec.label": "Note médicale : antécédents",
        },
      },
      info: {
        label: "Information",
        child: {
          "date.label": "Date",
          "hours.label": "Heure",
        },
      },
    },
    menu: {
      ADD: "Ajouter",
    },
  },
  "rlm-templating-tree-view": {
    child: {
      administrative: {
        label: "Données administratives",
        child: {
          "patientManager.label": "Gestionnaire du trajet de soins",
          "rlmsecretary.label": "Secrétaire RLM",
          "activecarepath.label": "Trajet de soins actif",
          "diabetesconvention.label": "Convention diabete",
          "typeofpathology.label": "Type de pathologie",
          "startdate.label": "Date de début",
          "enddate.label": "Date de fin",
          "endreason.label": "Motif d'arrêt",
          "patientsigndate.label": "Signature patient",
          "generalphysiciansigndate.label": "Signature médecin généraliste",
          "specialistphysiciansigndate.label": "Signature médecin spécialiste",
          "mutualitysigndate.label": "Signature mutuelle",
          "note.label": "Note administrative",
        },
      },
      healthcareparties: {
        label: "Intervenants",
        child: {
          "healthcareparties.label": "Intervenants",
        },
      },
      treatments: {
        label: "Traitements",
        child: {
          "inclusion.label": "A l'inclusion",
          "current.label": "Actuellement",
        },
      },
      objective: {
        label: "Objectif",
        child: {
          "objective.label": "Objectif",
        },
      },
      prescriptions: {
        label: "Prescriptions",
        child: {
          "prescriptions.label": "Prescriptions",
        },
      },
    },
  },
  "patientMail-templating-tree-view": {
    child: {
      patient: {
        label: "Patient",
        child: {
          lastName: {
            label: "Nom de famille",
          },
          firstName: {
            label: "Prénom",
          },
          adresse: {
            label: "Adresse",
          },
          houseNumber: {
            label: "Numéro de maison",
          },
          town: {
            label: "Ville",
          },
          townCode: {
            label: "Code Postal",
          },
          age: {
            label: "Âge",
          },
        },
      },
      doctor: {
        label: "Médecin",
        child: {
          lastName: {
            label: "Nom de famille",
          },
          firstName: {
            label: "Prénom",
          },
          adresse: {
            label: "Adresse",
          },
          houseNumber: {
            label: "Numéro de maison",
          },
          town: {
            label: "Ville",
          },
          townCode: {
            label: "Code Postal",
          },
          tel: {
            label: "Numéro de téléphone",
          },
          gsm: {
            label: "Numéro de portable",
          },
          mail: {
            label: "Email",
          },
          inami: {
            label: "Inami",
          },
          bank: {
            label: "Numéro de compte",
          },
        },
      },
      info: {
        label: "Info",
        child: {
          date: {
            label: "Date",
          },
          hours: {
            label: "Heures",
          },
        },
      },
    },
  },
  "hcp-signature": {
    DOCTOR: "Médecin",
    DOCTOR_ABBR: "Dr.",
    PHONE_ABBR: "Tel.",
    NIHII: "Inami",
    SIGNATURE: "Signature",
    DATE: "Date",
    CBE: "N° BCE",
  },
  "templating-generator": {
    CLOSE: "Fermer",
    PREVIEW: "Prévisualisation",
    EXPORT: "Exporter",
    SAVE: "Sauver",
    UPDATE: "Modifier",
    BACK: "Retour",
    CANCEL: "Annuler",
    EDIT: "Modifier",
    REMOVE: "Supprimer",
    SHOW: "Voir",
    form: {
      TITLE: "Titre",
      GROUP: "Type",
    },
  },
  "templating-management": {
    FIND_BY_NAME: "Trouver par nom",
    TITLE: "Documents type",
    NAME: "Nom",
    USER: "Utilisateur",
    TYPE: "Type",
    GROUP: "Groupe",
    ADD: "Ajouter un document type",
    IMPORT: "Importer des documents type",
    EXPORT_ALL: "Exporter tous les documents",
    IMPORT_TITLE: "Import de nouveaux documents type",
    actions: {
      ACTIONS: "Actions",
    },
    IMPORT_DOCUMENTS: {
      name: "Nom du document",
      content: "Aperçu",
      import: "Importer les documents sélectionnés",
      import_from_file: "Importer un document depuis un fichier",
    },
  },
  "efac-management": {
    EFAC_TO_BE_SENT: "Factures à envoyer",
    EFAC_TO_BE_CORRECTED: "Factures à corriger",
    EFAC_SENT: "Lots de factures",
    SELECT_ALL: "Tout sélectionner",
    UNSELECT_ALL: "Tout déselectionner",
    EFAC_LIST: "Liste des factures eFact",
    ALERT_INFO_SEND_ASSISSTANT:
      "Pour que les attestations de vos assistants soient payées sur leur compte en banque partagé,",
    ALERT_INFO_SEND_ASSISSTANT_FILTER:
      'veuillez spécifier les informations bancaires des assistants dans la fenêtre "Détails de l\'utilisateur". Cliquez ensuite sur le filtre',
    ALERT_INFO_SEND_ASSISSTANT_PROCESS:
      "de la colonne nommée “Assistant”, filtrez les assistants et cliquez finalement sur le bouton “Envoyer vers eFact”",
    REFRESH: "Actualiser",
    BANK_INFO_MANDATORY: "Ceci est nécessaire pour envoyer des lots eFacts",
    SEND_TO_EFACT: "Envoyer vers eFact",
    CORRECT_EFACT: "Corriger les factures",
    MAINTENANCE: "eFact est temporairement en maintenance",
    INVOICE_DATE: "Date de facturation",
    INVOICE_NUMBER: "N° de facture",
    VIEW_HISTORY: "Afficher l'historique eFact",
    PATIENT: "Patient",
    MUTUALITY: "Mutuelle",
    ACCEPTED_AMOUNT: "Montant accepté",
    PAID_AMOUNT: "Montant payé",
    AMOUNT: "Montant",
    TO_BE_SENT: "À envoyer",
    TO_BE_CORRECTED: "À corriger",
    TREATED: "Traitées",
    ARCHIVED: "Archivées",
    ARCHIVED_CODE: "Archivé",
    PENDING: "En traitement",
    STATUS: "Statut",
    ACCEPTED: "Accepté",
    CANCELED: "Refusé",
    LOST: "Perdu",
    DATE: "Date",
    CODE: "Code",
    EDIT: "Modifier",
    ERRORS: "Erreurs",
    IO_FEDERATION_CODE: "Numéro de l'OA",
    ASSISTANTS_BANK_ACCOUNT: "(compte bancaire des assistants)",
    PMG_BANK_ACCOUNT: "(compte bancaire du PMG)",
    MY_BANK_ACCOUNT: "(mon compte bancaire)",
    EXTERNAL_REF: "Numéro de l'envoi",
    INVOICE_MONTH: "Mois de facturation",
    NUMERICAL_REF: "Référence de l'envoi",
    EDIT_INVOICING: "Modifier la facture",
    VIEW_INVOICING: "Voir la facture",
    MY_EFACTS: "Mes eFacts",
    REJECTED_PRESTATIONS: "Prestations rejetées",
    MISSING_PATIENT: "Impossible de charger le patient",
    ASSISTANT: "Assistant",
    "ASSISTANT-TITLE": "Facture réalisée par un de vos assistants",
    CANNOT_LOAD_EFACT_HCP:
      "Impossible de charger les factures liées à votre intervenant",
    "efac-sent": {
      TITLE: "Liste des envois eFact",
      REFRESH: "Actualiser",
      FETCH_MESSAGES: "Récupérer mes messages",
      FILTER: "Filtrer",
      OA: "OA",
      CONTRACTOR: "Prestataire",
      REFERENCE: "Référence",
      SENDER_NUMBER: "N° Envoi",
      INVOICE_MONTH: "Mois de facturation",
      SENT: "Date d'envoi",
      TOTAL_AMOUNT: "Montant",
      STATUS: "Statut",
      ACCEPTED_AMOUNT: "Montant accepté",
      REJECTED_AMOUNT: "Montant refusé",
      REASON_FOR_REJECTION: "Motif rejet",
      PAYMENT_REF: "Ref. paiement",
      PAYED: "Payé",
      IMPOSSIBLE_TO_RELOAD_BATCH:
        "Impossible de charger les factures envoyées.",
      HELPDESK_INFO: "Helpdesk info",
      EFACT_MESSAGES_FETCHED_LESS_THAN_ONE_HOUR_AGO:
        "Vous avez déjà récupéré vos messages. Veuillez réessayer {{time}}",
      NO_MESSAGES_FOUND:
        "Aucun message eFact trouvé, veuillez réessayer plus tard.",
    },
  },
  "efact-reset-confirm": {
    WARNING: "Attention !",
    WARNING_TEXT:
      "Réinitialiser un message eFact est un processus irréversible, pouvant causer des doubles envois.",
    RESET_MESSAGE: "Réinitialiser les factures pour envoi",
    CANCEL: "Annuler",
    CONFIRM: "Confirmer",
  },
  "helpdesk-info": {
    HCP_NAME: "Nom du tiers facturant",
    HCP_NIHII: "Numéro INAMI du tiers facturant",
    HCP_PHONE_NUMBER: "Numéro de téléphone du tiers facturant",
    MONTH_AND_YEAR_INVOICING: "Mois et année de facturation",
    SENT_NUMBER: "Numéro d'envoi",
    SENT_REF: "Référence d'envoi",
    SENT_DATE: "Date d'envoi",
    LAST_RESPONSE_TYPE: "Type de la dernière réponse reçue",
    LAST_RESPONSE_DATE: "Date de la dernière réponse reçue",
    TOTAL_INVOICE_AMOUNT: "Montant total facturé",
    SOFTWARE_NAME: "Nom du logiciel",
    TECHNICAL_SHEETS: "Fichiers techniques",
    DOWNLOAD: "Télécharger",
    RESET_MESSAGE: "Réinitialiser les factures pour envoi",
    CHANGE_STATUS_TITLE:
      "Forcer le changement de status pour un envoi sans réponse",
    MANUAL_STATUS_CHANGE: "Opération manuelle",
    MANUAL_STATUS_CHANGE_OPERATOR: "Opérateur ayant effectué l'opération",
    MANUAL_STATUS_CHANGE_DATE: "Date de l'opération manuelle",
    MANUAL_STATUS_CHANGE_BUTTON: "Changer le status manuellement",
  },
  "efact-change-status-modal": {
    NOT_OWNED_BY_USER:
      "Cette eFact a été établie par {{firstName}} {{lastName}}. Vous n'avez pas le droit de la modifier.",
    WRONG_PASSWORD: "Mauvais mot de passe",
    MESSAGE_NOT_FOUND: "Impossible de trouver le message correspondant.",
    NO_INVOICE_IN_MESSAGE: "Le batch n'a pas de facture associée",
    MISSING_INVOICES:
      "Impossible de trouver toutes les factures associées au batch",
    UNABLE_TO_UPDATE_INVOICE: "Impossible de mettre à jour la facture",
    MANUAL_REJECT_INVOICE: "Rejet manuel de la facture",
    UNKNOWN_STATUS_UPDATE:
      "Votre choix pour le changement de statut est inconnu.",
    SUCCESSFUL_BATCH_STATUS_UPDATE:
      "Mise à jour du statut du batch avec succès",
    OPERATOR_MODIFICATION_ONLY:
      "Cette opération est réservée aux opérateurs du support Medispring lorsque la réponse de l'organisme assureur a été perdue.",
    OPERATOR_NAME: "Nom de l'opérateur",
    OPERATOR_PASSWORD: "Mot de passe",
    VALIDATE_PASSWORD_BUTTON: "Valider le mot de passe",
    SELECT_STATUS_CHANGE_CHOICE: "Sélectionner l'action à effectuer :",
    STATUS_CHANGE_CHOICE_FULL_SUCCESS: "Mettre en succès complet",
    STATUS_CHANGE_CHOICE_FULL_REJECT: "Mettre en rejet complet",
    ACKNOWLEDGE_EFACT_CHANGE_ACTION:
      "Je suis conscient du changement de status que je vais effectuer et de l'impossibilité de revenir en arrière.",
    FORCE_CORRECTION_OVERWRITE:
      "(Fortement déconseillé) Forcer la création de NOUVELLES factures de correction si lot déjà rejeté",
    VALIDATE_STATUS_CHANGE: "Effectuer le changement",
    INVOICE_ALREADY_CORRECTED_IDS: "IDs factures déjà corrigées",
    INVOICE_CORRECTED_IDS: "IDs factures corrigées",
    INVOICE_FAILED_UPDATE_IDS:
      "IDs factures en échec (vous devez réeffectuer l'opération)",
    SOME_INVOICES_FAILED_WARNING:
      "La correction de certaines factures a échoué",
  },
  "efac-settings": {
    PARAM_EFAC: "Paramètres eFact",
    VALIDATE: "Valider",
    NEXT_SENDING_NUMBERS: "Prochains numéros d'envoi eFact",
    ALERT_EDIT_NUMBERS:
      "Attention : modifier les numéros d'envoi eFact peut créer des comportements inattendus. Ne modifiez ceux-ci que si vous utilisez d'autres logiciels de médecine pour envoyer des factures eFact.",
    MUT_NAME: "Mutuelle",
    ERROR_CANT_FETCH:
      "Impossible de récupérer la dernière valeur utilisée par Medispring.",
    VALUE: "La valeur",
    ERROR_TOO_SMALL: "est trop petite.",
    ERROR_CANT_UPDATE: "Mise à jour impossible",
    UPDATE: "Mettre à jour",
  },
  "efac-history": {
    TITLE: "Historique des eFacts",
    DATE: "Date",
    INVOICE_NUMBER: "N° de facture",
    STATUS: "Statut",
    LOT_NUMBER: "N° du lot eFact",
    LOADING: "Chargement en cours",
  },
  "patient-chapteriv": {
    REFUND_REQUEST: "Demande de remboursement : ",
    COMPLETE: "Demande complète",
    SEND: "Envoyer",
    OPENING_DATE: "Date de début",
    CLOSING_DATE: "Date de fin",
    MEDICATIONS: "Medicament(s)",
    PARAGRAPH: "Paragraphe",
    QUANTITY: "Quantité",
    EHEALTH_CONNECTION_KO:
      "Connectez-vous à eHealth pour consulter et créer des demandes de chapitre IV.",
    AGREEMENT_CONSULTATION_ERROR:
      "Impossible de consulter vos demandes de chapitre IV.",
    CURRENT_STATE: "Vos accords",
    NO_ACCORD: "Aucun accord trouvé",
    IN_TREATMENT: "En traitement",
    ACCEPTED: "Accepté",
    REFUSED: "Refusé",
    CANCELED: "Annulé",
    CONSULT: "Consulter",
    MODEL: "Model (Version)",
    STATUS: "Statut",
    TRANSACTION_DATE: "Date de transaction",
    QUANTITIES: "Quantitées",
    COVERTURE: "Couverture",
    DECISION: "Décision",
    REFERENCE: "Référence",
    VERSES: "Versets",
    REFUSAL_JUSTIFICATION: "Justification de refus",
    APPENDICES: "Annexe(s)",
    PARAGRAPH_NOT_EXIST:
      "Ce paragraphe ne semble plus exister dans la version actuelle de la base de donnée SAM.",
    VERSE_VERSESEQ: "Verset (verseSeq)",
    APPENCIDE_NEEDED_NEWREQUEST:
      "Une annexe est necessaire pour cette nouvelle demande",
    APPENCIDE_NEEDED_PROLONGATION:
      "Une annexe est necessaire pour cette prolongation",
    APPENCIDE_NEEDED_VERSE: "Une annexe est necessaire pour ce verset",
    UNCONTINIOUS_ALERT:
      "En sélectionnant une date plus grande que la date de fin de l'accord en cours + 1 jour, la seule prolongation possible sera discontinue. Veuillez confirmer le type de prolongation desiré",
    UNCONTINUOUS: "Discontinue",
    CONTINUE: "Continue",
    CANCEL: "Annuler",
    ONE_APPENCIDE_NEEDED_PROLONGATION:
      "annexe est nécessaire à la demande de prolongation",
    ONE_APPENCIDE_NEEDED_NEWREQUEST:
      "annexe est nécessaire pour faire la nouvelle demande",
    X_APPENCIDE_NEEDED_PROLONGATION:
      "annexes sont nécessaires à la demande de prolongation",
    X_APPENCIDE_NEEDED_NEWREQUEST:
      "annexes sont nécessaires pour faire la nouvelle demande",
    NEWREQUEST: "Demande",
    PROLONGATION: "Prolongation",
    ADD_APPENDICES: "Ajout d'annexe(s)",
    CLOSURE: "Cloture",
    CANCELLATION: "Annulation",
    SEND_NEWREQUEST: "Envoyer la demande",
    SEND_CLOSURE: "Clôturer l'accord",
    SEND_EXTENSION: "Prolonger l'accord",
    SEND_APPENCIDE: "Envoyer",
    SEND_CANCEL: "Annuler l'accord",
    UNEXPECTED_ERROR: "Une erreur est survenue",
    NO_TRANSACTION: "Une erreur est survenue : aucune transaction reçue.",
    SAVE_APPENDICES_ERROR:
      "Vos annexes n'ont pas pu être sauvées localement. Elles ont cependant été envoyé correctement mais vous ne saurez pas les consulter dans vos accord en cours ci-dessus.",
    ERROR_GET_NOT_SELECTED: "Aucun accord n'est sélectionné",
    ERROR_GET_P: "Impossible de récupérer les paragraphes.",
    ERROR_GET_V: "Impossible de récupérer les versets.",
    APPENDICE_NOT_USEFUL:
      "Si ce n'est pas nécessaire, n'envoyez pas d'annexes afin de ne pas ralentir le processus de demande.",
    CLOSE_OK: "Accord cloturé avec succès",
    MAX_SIZE_REACHED:
      "Les fichiers que vous avez sélectionnés sont trop volumineux. La taille maximum de l'ensemble des fichiers est de {{ maxTotalFileSize }} KB or, vos fichiers pèsent {{ totalFileSize }} KB.",
    YOU_CAN_0: "Vous pouvez :",
    YOU_CAN_1:
      "- Effectuer plueieurs ajout d'annexes indépendants afin d'envoyer les fichiers un par un.",
    YOU_CAN_2: "- Réduire la taille de vos fichiers.",
    MIME_TYPE_ERROR_0:
      "Vous avez sélectionné des fichiers de type {{ mimeTypeErrors }}.",
    MIME_TYPE_ERROR_1:
      "Nous ne pouvons garantir que ces fichiers seront envoyé correctement.",
    MIME_TYPE_ERROR_2:
      "Nous vous conseillons de n'envoyer que des fichiers de type pdf, image (.png, .jpg, …) et texte (.txt).",
    SUPPLEMENTARY_INFO: "Informations complémentaires",
    VERSE_TYPE: "Type de verset",
    "request-type": {
      "0": "",
      P: "Prolongation",
      N: "Nouvelle demande",
    },
    ERROR_1:
      "Vous avez coché des versets de nouvelle demande et de prolongation en même temps.",
    ERROR_2:
      "Vous avez coché un verset de prolongation alors que vous faite une nouvelle demande.",
    ERROR_3:
      "Vous avez coché un verset de nouvelle demande alors que vous faite une demande de prolongation.",
    EXT_WARN_1:
      "La date de début d'une prolongation ne peut pas être dans le passé.",
    EXT_WARN_2:
      "Vous ne pouvez prolonger un accord que durant les 3 derniers mois de cet accord.",
    LAST_CONSULT: "Dernière connexion en date du {{date}}, à {{time}}",
  },
  "patient-erecipe": {
    E_RECIPE_ID: "Recip-e",
    ERR_GET_OPEN_PRESC:
      "La liste des prescriptions ouvertes sur Recip-e n'a pas pu être obtenue.",
    PRESC_NOT_FOUND: "Aucune prescription éléctronique trouvée",
    TREATMENTS: "Traitements",
    POSOLOGY: "Posologie",
    START_DATE: "Délivrable a-p-d",
    END_DATE: "Fin",
    PRESCRIBER: "Prescripteur",
    HCP_NO_PHONE:
      "L'intervenant prescripteur n'a pas de numéro de téléphone (mobile ou fixe).",
    NOT_CONNECTED_CANCEL:
      "Connectez-vous à eHealth pour annuler vos ordonnances Recip-e",
    CANCEL_OK: "Message d'annulation correctement envoyé à Recip-e",
    ERROR_CANCEL:
      "Une erreur est survenue en annulant votre ordonnance. Ceci peut arriver si votre ordonnance a déjà été annulée",
    ERROR_CANCEL_SAVE: "Une erreur est survenue en enregistrant l'annulation",
    CANCEL_SUCCESS: "Annulation envoyée à Recip-e",
    CANCEL_BTN: "Annuler l'ordonnance Recip-e",
    ACTIVE_INGREDIENT: "Principe actif",
    PRESCRIBED_DATE: "Prescrit le",
    PRESCRIBE_NEW: "Prescrire de nouveau",
    NOT_SENT: "Ordonnance non envoyée à Recip-e",
    NO_PHONE_WARNING:
      "Attention, la precription ne sera pas éléctronique! Pour envoyer une ePrescription, vous devez obligatoirement encoder un N° de téléphone et un type de téléphone dans votre fiche. Modifiez votre fiche en cliquant sur vos initiales en bas à gauche de l'écran",
  },
  "patient-contact-document-selector": {
    APPENDICE_COMPUTER: "Choisir des fichiers présents sur votre ordinateur",
    CHOOSE_DOCUMENT: "Choisir des fichiers depuis le dossier du patient",
    CHOOSE_FILES: "Choisir des fichiers...",
    FROM_PATIENT_FOLDER: "Depuis le dossier du patient",
    FROM_COMPUTER: "Depuis votre ordinateur",
    SELECTED_FILES: "Fichiers sélectionnés",
    NO_SELECTED_FILES: "Aucun fichier à afficher.",
    PLACEHOLDER_PLEASE_SELECT:
      "Veuillez sélectionner ici à gauche un ou plusieurs fichiers depuis votre ordinateur ou depuis le dossier du patient.",
    DELETE_SELECTED_FILES: "Supprimer",
    DOCUMENT_SEQ: "Document seq",
    VERSE_SEQ: "Verse seq",
    FILE_TOO_BIG:
      "Le fichier est trop volumineux. La taille maximum est de 1000 KB.",
    ERROR_REPORT_GET_FILE:
      "Certains document n'ont pas pu être obtenue d'un contact de type raport.",
    APPENDICES_AVAILABLE_FOR_DL:
      "Annexes-type disponibles au téléchargement depuis le site de l'INAMI",
    ONCE_COMPLETED:
      "Une fois completée, n'oubliez pas de mentionner les valeurs",
    AND: "et",
    OF_EACH_APPENDICE:
      "de chaque annexe ajoutée à votre demande ou ajout d'annexe complementaire.",
    DESCRIPTION: "Description",
    DOWNLOAD: "Télécharger",
    CHOOSE: "Choisir",
  },
  "patient-medication-archive": {
    ALLERGY: "Allergie",
    INTOLERANCE: "Intolérance",
    PATIENT_REQUEST: "Demande du patient",
    OTHER: "Autre",
    NOTE: "Note",
    CANCEL: "Annuler",
    ARCHIVE: "Archiver",
  },
  "contactreport-subcontact": {
    PREVIEW_OF: "Aperçu de",
    PRINT_FULL_REPORT: "Imprimer le rapport complet",
    CONSLUSION: "Conclusion",
    ERROR_UPLOAD: "Le document n'a pas pu être envoyé au serveur.",
    ERROR_SAVE_CONTACT: "Erreur lors de la sauvegarde du rapport.",
    ERROR_GET: "Un document n'a pas pu être chargé.",
    EXT_NOT_AUTHORIZED:
      "Les fichiers {{ext}} ne sont pas autorisés. Fichiers autorisés : {{autorized}}",
    FILE_TOO_BIG:
      "Le fichier {{filename}} est trop volumineux. La taille maximum autorisée est de {{size}}",
    ERR_FILE_READ: "Impossible de lire le fichier",
    FIND_PROCEDURE: "Trouver une procédure",
    ATTACHMENT: "Pièce jointe",
    PROCEDURE: "Procédure",
    SPECIALITY: "Spécialité",
    CHOOSE: "Choisir",
    PRINT: "Imprimer",
    DOWNLOAD: "Télécharger",
    DELETE: "Supprimer",
    TEXT: "Texte",
    LINKS: "Liens",
    LINK: "Lien",
    "new-message": {
      TITLE: "Envoi du rapport via eHealthBox",
    },
    REPORT_S: "Rapport ",
    DE_: "de ",
    DU_: "du ",
    APPENDICES: "Annexes",
    APPENDICE: "Annexe",
    DOCS_AS_APPENDICE: " documents en annexe",
    DOC_AS_APPENDICE: " document en annexe",
    SAVE_REPORT: "Enregistrer",
    REPORT_UPDATED_SUCCESS: "Rapport mis à jour.",
    OPEN_PATIENT: "Ouvrir le dossier",
  },
  "text-templating": {
    enums: {
      template_group_type: {
        SOAPS: "soaps",
      },
    },
  },
  "messages-box-manager": {
    NO_ACCESS_TO_ALL_MAIL_FEATURES_IF_NOT_LOGGED_TO_EHEALTH:
      "Vous n'avez pas accès à toutes les fonctionnalités du module mail sans être connecté aux services eHealth.",
  },
  "messages-box-controls": {
    MANUAL_IMPORT: "Import manuel",
    AUTO_IMPORT: "Import automatique",
    UPDATE: "Mise à jour",
    MARK_READ: "Marquer lu",
    MARK_UNREAD: "Marquer non lu",
    CONFIRM_DIALOG: {
      TITLE: "Confirmation de la suppression",
      MESSAGE: "Voulez-vous supprimer ce(s) message(s)?",
      YES: "Oui",
      NO: "Non",
    },
    DELETE: "Supprimer",
    CANNOT_DELETE_MESSAGE: "Échec de suppression du message",
    SEARCH: "Rechercher",
  },
  "messages-box-filter-selector": {
    UNTREATED: "Messages non traités",
    TREATED: "Messages traités",
    SELECT_ALL: "Tout sélectionner",
    STATUS: "Statut",
    RECIPIENT: "Destinataire",
    OTHER: "Autre",
  },
  "messages-box-selector": {
    TITLE: "Boite mail eHealth",
    NEW_MESSAGE: "Nouveau message",
    INBOX: "Boite de réception",
    INBOX_ALL: "Tous",
    INBOX_USER_ACTION: "Non traité",
    OUTBOX: "Messages envoyés",
    ARCHIVE: "Archives",
    MANUAL_IMPORT: "Import manuel d'un fichier",
  },
  "message-messages-box": {
    NEW_MESSAGE: "Nouveau Message",
    SEARCH_INPUT:
      "Recherche: nom patient, date naissance, adresse, émetteur, spécialité, procédure",
    CAPACITY: "Espace occupé",
    MESSAGE_QUEUE: "Messages dans la file d'attente :",
    FULL_BOX_ERROR:
      "Votre boite est remplie, effacez des messages afin de continuer à en recevoir",
    FULL_BOX_WARNING:
      "Votre boite est bientôt remplie, effacez des messages afin d'éviter une saturation de votre boite",
    EMPTY_TRASH: "Vider la corbeille sur la eHealthBox",
    ERROR_DELETE_CHUNK: "Une erreur est survenue en supprimant vos messages",
    N_DELETED_MESSAGE: "{{n}} messages ont été supprimés",
    TEXT_ACTIVATE_HEALTHBOX: "Récupération automatique des messages healthbox",
  },
  "editor-message-messages-box": {
    report: "Rapport de garde",
    label: {
      TO: "À",
      TO_PLACEHOLDER:
        "Rechercher des personnes et des organisations dans votre liste d'intervenants.",
      SUBJECT: "Objet",
      SET_AS_IMPORTANT: "Marquer comme important",
      ENCRYPTED: "Crypté",
      METADATA: "Metadata",
      ADD_METADATA: "Ajouter une autre paire de métadonnées",
      CUSTOM_METADATA: "Ajouter des métadonnées personnalisées",
      ADD_RECIPIENT: "Ajout d'un destinataire",
    },
    confirmationType: {
      SEND: "Envoi",
      RECEIVE: "Réception",
      READ: "Lecture",
    },
    button: {
      SEND: "Envoyer",
    },
    NO_HCP: "Aucun HCP correspondant n'a été trouvé pour le NIHII.",
  },
  "add-recipient": {
    TITLE: "Ajout d'un destinataire sur base d'un numéro et d'une qualité",
    IDENTIFIER: "Numéro",
    QUALITY: "Qualité",
    CANCEL: "Annuler",
    OK: "Ajouter",
  },
  "message-list": {
    NO_DATE: "Pas de date",
    MESSAGE_WARNING: "Ce message nécessite votre attention",
    MESSAGE_ERROR: "Erreur lors du traitement automatique",
    MESSAGE_SUCCESS: "Traitement automatique effectué avec succès",
    FROM_DISK: "Importé depuis le disque",
    HAS_ANNEX: "Contient une ou plusieurs annexes",
    MORE_THAN_ONE_PATIENT: "Plusieurs patients",
    NO_PATIENT: "Aucun patient",
    SOURCE_UNKNOWN: "Émetteur inconnu",
    RECIPIENT_UNKNOWN: "Destinataire inconnu",
    IMPORTANT: "important",
    UNKNWON: "Inconnu",
    status: {
      SENT: "Envoyé",
      RECEIVED: "Reçu",
      READ: "Lu",
    },
  },
  "patient-medical-note": {
    TITLE: "Note médicale",
    tab: {
      WILL: "Volontés",
      BLOOD_TYPE: "Groupe sanguin",
      INACTIVE: "Antécédents",
    },
    SAVE: "Enregistrer",
    CANCEL: "Annuler",
    CLOSE: "Fermer",
    EDIT: "Modifier les volontés du patient",
    LOADING: "Veuillez patienter pendant le chargement des contacts",
  },
  "patient-administrative-note": {
    TITLE: "Note administrative",
    OPEN_PATIENT_NOTE: "Afficher à l'ouverture du dossier",
    PLACEHOLDER: "Ajouter une note",
    SAVE: "Enregister",
    CANCEL: "Annuler",
    NOTE_SAVED: "Note administrative enregistrée",
    NOTE_SAVE_ERROR: "La note administrative n'a pas pu être enregistrée",
  },
  "patient-biometry-note": {
    TITLE: "Notes biometrie",
  },
  "patient-will": {
    DATE: "Date",
    NOTE: "Note",
    EMPTY: "Aucune volonté n'a encore été enregitrée pour ce patient",
    HISTORY: "Afficher l'historique de cette volonté",
    NO_HISTORY: "Pas d'historique à afficher",
    LINK_ORGADON: "Link to Orgadon",
    bloodtransfusion: {
      LABEL: "Transfusion de sang",
      AUTHORIZE: "Accepte la transfusion",
      REFUSE: "N'accepte pas la transfusion",
      UNDEFINED: "?",
    },
    intubation: {
      LABEL: "Intubation",
      AUTHORIZE: "Accepte l'intubation",
      REFUSE: "N'accepte pas l'intubation",
      UNDEFINED: "?",
    },
    reanimation: {
      LABEL: "Réanimation",
      AUTHORIZE: "Accepte la réanimation",
      REFUSE: "N'accepte pas la réanimation",
      UNDEFINED: "?",
    },
    organdonation: {
      LABEL: "Don d'organe",
      AUTHORIZE: "Accepte le don d'organe",
      REFUSE: "N'accepte pas le don d'organe",
      UNDEFINED: "?",
    },
    vaccination: {
      LABEL: "Vaccination",
      AUTHORIZE: "Accepte la vaccination",
      REFUSE: "N'accepte pas la vaccination",
      UNDEFINED: "?",
    },
    euthanasia: {
      LABEL: "Euthanasie",
      AUTHORIZE: "A fait une demande d'euthanasie",
      REFUSE: "N'accepte pas l'euthanasie",
      UNDEFINED: "?",
    },
    datareuseforclinicalresearch: {
      LABEL: "Recherches cliniques",
      AUTHORIZE:
        "Accepte l'utilisation des données pour des recherches cliniques",
      REFUSE: "N'accepte pas",
      UNDEFINED: "?",
    },
    datareuseforclinicaltrials: {
      LABEL: "Utilisation des données pour les essais cliniques",
      AUTHORIZE: "Accepte l'utilisation des données pour des essais cliniques",
      REFUSE: "N'accepte pas",
      UNDEFINED: "?",
    },
    clinicaltrialparticipation: {
      LABEL: "Participation aux essais cliniques",
      AUTHORIZE: "Accepte la participation aux essais cliniques",
      REFUSE: "N'accepte pas",
      UNDEFINED: "?",
    },
    hospitalisation: {
      LABEL: "Hospitalisation",
    },
    resuscitation: {
      LABEL: "Réanimation",
    },
    "MS-FIXME-note": {
      LABEL: "Note",
    },
  },
  "patient-inactive": {
    CONTENT: "Antécédents",
  },
  "patient-blood-type": {
    BLOOD_TYPE: "Groupe sanguin",
    SUB_BLOOD_TYPE: "Sous groupe sanguin",
    HISTORY: "Afficher l'historique du groupe sanguin",
    NO_HISTORY: "Pas d'historique à afficher",
  },
  "patients-state": {
    FETCH_FAILED: "La réception du patient {{id}} a échoué.",
  },
  "patient-journal-service": {
    CONTACT_SUBMISSION_FAILED: "La soumission du contact a échoué",
    ATTEMPTED_ICURE_TOPAZ_CONTACT_SUBMISSION:
      "Les données créées avec iCure ou Topaz ne peuvent pour le moment pas être modifiées par Medispring.",
  },
  "unsupported-services": {
    SERVICE: {
      LABEL: "Libellé",
      VALUE: "Valeur",
    },
    UNSUPPORTED_SERVICES: "Services supplémentaires",
    BINARY_DATA: "Données binaires",
    COMPOUND_VALUE: "Valeur composée",
  },
  "file-viewer": {
    DISPLAY_IMPOSSIBLE: "Ce type de fichier ne peut être affiché",
    DOWNLOAD: "Télécharger la pièce jointe",
  },
  "message-display": {
    DELETED_LAB: "Ce labo a été supprimé",
    DELETED_REPPORT: "Ce rapport a été supprimé",
    SEARCH_PATIENT: "Rechercher le patient",
    EDIT_FROMHCP: "Éditer l'émetteur du message",
    EDIT_TOHCP: "Éditer le destinataire du message",
    RETRY_PROCESSING: "Réessayer",
    TRY_PROCESSING: "Traiter le message",
    WAITING_MESSAGE:
      "Ce message n'a pas encore été traité. Des informations peuvent être manquantes.",
    PROCESSING_FAILED: "Le message n'a pas pu être traité automatiquement.",
    PREVIEW_NOT_AVAILABLE: "Aperçu non disponible.",
    CANNOT_HANDLE_FILE: "Le fichier n'est pas interprétable.",
    CANNOT_DELETE_MESSAGE: "Échec de suppression du message",
    CANNOT_UPDATE_MESSAGE: "Échec de mise à jour du message.",
    CANNOT_UPDATE_INFO: "Échec de mise à jour de l'info.",
    HCP_EDITION_MODAL_TITLE_SENDER: "Édition de l'émetteur du message",
    HCP_EDITION_MODAL_TITLE_RECEIVER: "Édition du destinataire du message",
    AUTHOR_UNKNOWN: "Émetteur inconnu",
    RECIPIENT_UNKNOWN: "Destinataire inconnu",
    FROM_PREFIX: "À",
    SENDER_PREFIX: "De",
    ERROR_RECIPIENT: "La détermination du destinataire a échoué.",
    SUBJECT_PREFIX: "Sujet : ",
    NO_PREVIEW_MESSAGE: "Il n'y a pas de messages à afficher.",
    SELECT_MESSAGE: "Veuillez sélectionner un message.",
    PREVIEW: "Prévisualisation des pièces jointes",
    CUSTOM_METADATA: "Custom metadata",
    data: {
      REPLY: "Répondre",
      REPLY_ALL: "Répondre à tous",
      TRANSFER: "Transférer",
      ARCHIVE: "Archiver",
      DELETE: "Supprimer",
      MARK_READ: "Marquer lu",
      MARK_UNREAD: "Marquer non lu",
      MOVE_TO_TRASH: "Vers la corbeille",
    },
    annex: {
      DOWNLOAD: "Télécharger",
      PATIENT_LINK: "Associer à un patient",
      AUTHOR_LINK: "Associer à un auteur",
    },
    dialog: {
      reply: "Répondre à l'expéditeur du message",
      replyAll: "Répondre à tous les destinataires du message",
      forward: "Réexpédier le message",
    },
  },
  "message-display-body": {
    UNSUPPORTED_BODY_TYPE: "Format de message non supporté: {{type}}",
    DOWNLOAD: "Télécharger le corps du message",
  },
  "import-result-summary": {
    AUTHOR_UNKNOWN: "Source inconnue",
  },
  "contact-task-form": {
    DESCRIPTION: "Description",
    DEADLINE: "Délai",
    REQUESTER: "Demandé par...",
    OWNER: "À effectuer par...",
    SEARCH_OWNER: "Rechercher un utilisateur",
    CREATE_TASK: "Ajouter une tâche",
    REMOVE_TASK: "Supprimer la tâche",
    VALUE: "Valeur",
    TYPE: "Type",
    PROCEDURE: "Procédure",
  },
  "health-one-import": {
    success: {
      DOCUMENT_SUCCESSFULLY_IMPORTED: "Le document a été importé avec succès.",
      X_MESSAGE_PROCESSED: "{{value}} message traité.",
      X_MESSAGES_PROCESSED: "{{value}} messages traités.",
    },
    error: {
      ERROR_MORE_THAN_ONE_DOCUMENT_IN_MESSGAE:
        "Le message possède plusieurs documents.",
      ERROR_NO_DOCUMENT_IN_MESSGAE: "Le message ne possède pas de document.",
      ERROR_DOC_HAS_NO_TYPE: "Impossible d'identifier le type du document.",
      ERROR_DOCUMENT_CANT_BE_LINKED_TO_PATIENT:
        "Le document ne peut être associé à un patient.",
      ERROR_WHILE_ADDING_CONTACT_TO_PATIENT:
        "Erreur de l'ajout du contact au patient ({{lastName}} {{firstName}}{{dateOfBirth}}).",
      ERROR_GET_HCP: "Erreur lors de la récupération de l'intervenant.",
      PATIENT_SSIN_AMBIGUOUS:
        "Plusieurs patients sont associés au n° NISS récupéré ({{ssin}}).",
      PATIENT_NAME_BIRTH_DATE_NOT_FOUND:
        "Aucun patient pour le nom et la date de naissance récupérés ({{lastName}} {{firstName}} {{dateOfBirth}}).",
      PATIENT_NAME_BIRTH_DATE_AMBIGUOUS:
        "Plusieurs patients trouvés pour le nom et la date de naissance récupérés ({{lastName}} {{firstName}} {{dateOfBirth}}).",
      PATIENT_INFORMATION_MISSING:
        "Aucune information sur le patient dans le document.",
      CONTACT_CREATION_FAILED:
        "Erreur lors de la création du contact ({{lastName}} {{firstName}}{{dateOfBirth}}).",
      PARSING_FAILED:
        "Erreur lors de l'interprétation du document ({{lastName}} {{firstName}}{{dateOfBirth}}).",
      NO_AUTHOR:
        "Le message ne contient pas d'auteur. Le laboratoire/confrère aurait dû vous communiquer cette information. Vous avez également la possibilité de choisir manuellement l'auteur de cette information.",
      UNKNOWN: "Erreur inconnue.",
    },
  },
  "pricare-document-import": {
    MEDIDOC_FROM_PRICARE_WITHOUT_CONTENT:
      "Document medidoc venant de Pricare sans contenu",
    UNKNOWN_FILE_NAME_IN_PRICARE: "Nom de fichier inconnu dans Pricare",
  },
  "patient-tasks": {
    TITLE: "Tâches",
    PLACEHOLDER_NO_TASK: "Il n'y a pas de tâches à afficher.",
    CATEGORY: {
      CURRENT: "Tâches en cours",
      LATE: "Tâches dépassées",
      ENDED: "Historique des tâches",
    },
    EDIT: "Modifier la tâche",
    TYPES: {
      DIAGNOSTIC_ACT: "Acte diagnostique",
      THERAPEUTIC_ACT: "Acte thérapeutique",
      VACCINATIONS: "Vaccination",
      ADMINISTRATIVE_TASK: "Tâche médico-administrative",
      PREVENTION: "Prévention",
      IMAGERY: "Demande d'imagerie",
      LAB_ANALYSIS: "Demande de labo",
      OPINION_REQUESTS: "Demande d'avis",
      EMPTY: "Sans type",
      ALL: "Tous les types",
    },
    TABS: {
      OPENED: "Tâches ouvertes",
      CLOSED: "Historique des tâches",
    },
    "export-columns": {
      DATE: "Date",
      DESCRIPTION: "La description",
      DEADLINE: "Date limite",
      E_HEALTH: "eHealth",
      TYPE: "Type",
      STATUS: "Statut",
      REQUESTER: "Demandeur",
    },
  },
  "patient-free-task-dialog": {
    TITLE: "Tâche",
  },
  "patient-selection": {
    TITLE: "Sélection d'un patient",
    INFO_FROM_H1:
      "Informations trouvées dans le fichier : {{lastName}} {{firstName}} {{dateOfBirth}}",
    CREATE_NEW_PATIENT: "Créer un nouveau patient",
    LAST_NAME: "Nom de famille",
    FIRST_NAME: "Prénom",
    DATE_OF_BIRTH: "Date de naissance",
    NO_PATIENT: "Pas de patient",
    NEXT: "Suivant",
    PATIENT_LINK: "Associer au patient",
    CREATE_FROM_FILE_INFO: "Créer depuis les infos du fichier",
  },
  "hcparty-selection-dialog": {
    TITLE: "Sélection d'un prestataire",
    FIND_PLACEHOLDER: "Trouver par nom",
    CREATE_NEW: "Créer un nouveau prestataire",
    HCP: "Prestataire",
    ACCEPT: "Sélectionner",
  },
  "ms-mailing": {
    NO_ACCESS_TO_FEATURE_FOR_GUARDPOST:
      "Vous n'avez pas accès à cette fonctionnalité en mode PMG.",
    NO_LOG_EHEALTH: "Vous n'êtes pas connecté aux services eHealth.",
  },
  "messages-box-service": {
    NO_LOG_EHEALTH: "Vous n'êtes pas connecté aux services eHealth.",
    SEND_SUCCESS: "L'envoi du message a été effectué",
    SEND_FAILED: "L'envoi du message a échoué ",
  },
  "date-period-selector": {
    FROM: "Du",
    TO: "Au",
    SELECT: "Sélectionner",
    PREVIOUS: "Précédent",
    NEXT: "Suivant",
  },
  "task-update-popup": {
    TITLE: "Mise à jour de la tâche : {{label}}",
    NOTE: "Note",
    COMPLETION_DATE: "Date",
    BY_HCP: "Par",
    RENEWAL_DATE: "Renouvellement",
    STATUS: {
      COMPLETED: "Effectuée",
      COMPLETED_RENEW: "Effectuée et à renouveler",
      CANCELLED: "Non effectuée",
      REFUSED_BY_PATIENT: "Refusée par le patient",
    },
    UPDATE_FAILED: "La mise à jour de la tâche a échoué.",
    CANCEL: "Annuler",
    OK: "Valider",
  },
  "ms-html-dialog": {
    SAVE_AS_PDF: "Télécharger sous PDF",
  },
  "ms-eh-dmg": {
    GET_PATIENT_LIST: "Consulter la listes de vos patients",
    GET_DMG_MESSAGES: "Récupérer mes messages",
    REQUEST_DMG_LIST: "Demander la liste de mes patients",
    REQUEST_DMG_LIST_DATE: "Date de requête de liste",
    CONSULT_DMG_MESSAGES: "Afficher mes messages",
    CLEAR_DMG_MESSAGES: "Effacer les messages",
    CONFIRM: "Confirmer",
    REQUESTS_LIST: "Liste des requêtes",
    PATIENTS_LIST: "Liste des patients",
    DATE: "Date",
    REQUEST_DATE: "Date de requête",
  },
  "eh-e-dmg": {
    PATIENT_LIST: "Liste de mes patients",
    MESSAGES: "Messages",
  },
  "measure-form": {
    VALUE: "Valeur",
  },
  "manual-h1-import": {
    HCP: "Intervenant",
    PATIENT: "Patient",
    FILE: "Fichier",
    DIALOG_TITLE: "Import manuel d'un fichier Health One",
    IMPORT: "Importer",
  },
  "dmg-patient-list": {
    OA: "OA",
    SSIN: "Numéro national",
    LAST_NAME: "Nom",
    FIRST_NAME: "Prénom",
    PAYMENT: "Paiement",
    PAYMENT_DATE: "Date de paiement",
    SUB_TOTAL: "Sous total",
    TOTAL: "Total",
    GROUP_DRAG:
      "Faites glisser un en-tête de colonne et déposez-le ici pour grouper par cette colonne",
    NO_LIST: "Aucune liste disponbile pour l'instant",
    EXPORT: "Export Excel",
    EXPORT_FILE_NAME: "liste_de_patient_",
    MUT: "Mutualité",
    BIRTH: "Date de naissance",
    PAYMENT_REFERENCE: "Reférence",
    START: "Début",
    END: "Fin",
  },
  "ms-dmg-update-management": {
    ERROR_GET_GUID:
      "Impossible d'obtenir vos listes des renouvellements et clôtures",
    NO_MESSAGE_GET: "Aucun message récupéré. Veuillez réessayer plus tard",
    EXTENSION_AND_CLOSURE_LIST: "Liste des renouvellements et clôtures",
    closure: "Clôture",
    extension: "Prolongation",
    TYPE: "Type",
    PATIENT: "Patient",
    DATE: "Date de la demande",
    PREVIOUS_HCP: "Ancien propriétaire du DMG",
    END_OF_PREVIOUS_DMG: "Date de fin de l'ancien DMG",
    NEW_HCP: "Nouveau propriétaire du DMG",
    BEGIN_OF_NEW_DMG: "Date de début du nouveau DMG",
  },
  reporting: {
    patient: "patient(s)",
    beta: "Le module de reporting est en version bêta pour le moment, tous commentaires ou feedback sont les bienvenus",
    searchPH: "Entrez votre requête ensuite cliquez sur rechercher",
    tab: {
      reporting: "Reporting",
      dictionary: "Dictionnaire de requêtes",
    },
    type: {
      mammotest: "Mammotest",
      colorectal_cancer: "Cancer colo-rectal",
      collarSmear: "Frottis de col",
      influenza: "Vaccination grippe",
      mail: "Patients avec mail",
      "external-patient": "Patients hors zone",
      "unregistered-patient": "Patients désinscrits",
      "registered-patient": "Patients inscrits",
      "unregister-reason": "Raison du désabonnement",
      description: {
        mail: "Liste des patients actifs avec une adresse mail",
        "external-patient": "Liste des patients actifs hors zone",
        "unregistered-patient":
          "Liste des patients désinscrits dans une période donnée ",
        "registered-patient":
          "Liste des patients inscrits dans une période donnée",
        "unregister-reason":
          "Compte par mois des motifs de désabonnements dans une période donnée",
      },
    },
    column: {
      firstName: "Prénom",
      lastName: "Nom",
      dateOfBirth: "Date de naissance",
      ssin: "Niss",
      gender: "Genre",
      active: "Actif",
      email: "Email",
      phone: "Tél",
      city: "Ville",
      postalCode: "Code postal",
      addresse: "Adresse",
      houseNumber: "N°",
    },
    btn: {
      search: "Rechercher",
      addFavorite: "Enregistrer votre requête",
      favoriteList: "Liste des requête enregistrées",
      save: "Enregistrer",
      cancel: "Annuler",
      export: "Exporter le résultat",
      helper: "Aide",
      confirm: "Confirmer",
      clean: "Vider le champ",
    },
    error: {
      filterIsEmpty: "Votre requête est vide",
    },
    dialog: {
      save: {
        title: "Enregistrer une nouvelle requête",
        "query-name": "Nom de la requête",
        "query-description": "Description de la requête",
        query: "Requête",
        "query-description-placeholder": "Descriptif du résultat de la requête",
      },
      helper: {
        title: "Aide à la création de requête",
      },
      "query-list": {
        title: "Liste des requêtes disponibles",
        placeholder: "Rechercher une requête dans la liste",
        column: {
          name: "Nom",
          description: "Description",
        },
      },
      delete: {
        title: "Suppression d'une requête",
        content: "Voulez-vous supprimer la requête suivante",
      },
    },
    notification: {
      save: {
        success: "La requête a bien été enregistrée",
        error: "Erreur lors de l'enregistrement de la requête",
        incomplete: "Veuillez nommer votre requête",
      },
    },
    gender: {
      male: "Homme",
      female: "Femme",
      unknown: "Indéfini",
      "": "",
      null: "",
      undefined: "",
    },
    status: {
      active: "Actif",
      inactive: "Inactif",
    },
  },
  "patient-vaccine": {
    TITLE: "Vaccins en cours",
    CANCEL: "Annuler",
    PRINT: "Imprimer",
    SAVE: "Enregistrer",
    SAVE_REGISTER: "Enregistrer + Vaccinnet",
    SAVE_PRINT: "Enregistrer et imprimer",
    SCHEMA: "Schéma de vaccination",
    PRINT_HISTORY: "Imprimer l'historique",
    REGISTRATION_ERROR: "Erreur: Vaccinnet",
    VACCINNET_NOT_ALLOWED:
      "L’enregistrement Vaccinnet est uniquement autorisé pour le vaccin COVID-19",
    prescription: {
      CATEGORY: "Categorie",
      CURRENT_VACCINE: "Vaccin en cours",
      DOSE: "Dose",
      dose: {
        UNIQ: "Dose unique",
        FIRST: "1ère dose",
        SECOND: "2ème dose",
        THIRD: "3ème dose",
        FOURTH: "4ème dose",
        RECALL: "Dose de rappel",
      },
      RECALL: "Rappel",
      age: {
        ADULT: "Adulte",
        CHILD: "Enfant",
      },
      AGE: "Age",
      NOTE: "Note",
      AUTHOR: "Auteur",
      RESPONSIBLE: "Responsable",
      BATCH: "Lot",
      ORIGIN: "Origine",
      REGISTRATION: "Enregistrement",
    },
    code: {
      DIVERS: "Divers",
      ARCHIVE: "Autre vaccins",
      OTHER: "Vaccins supplémentaires",
      CHOLERA: "cholera",
      COVID19: "COVID-19",
      DIPHTERIA: "diphtérie",
      EJ: "encéphalite japonaise",
      HEPATITISA: "hépatite A",
      HEPATITISB: "hépatite B",
      HIB: "infections à Hib (Haemophilus influenzae)",
      MEASLES: "rougeole",
      MENINGITIS: "infections à méningocoques des sérogroupes A, C, W et Y",
      MENINGITISC: "infections à méningocoques du sérogroupe C",
      MUMPS: "oreillons",
      PANDEMICS: "pandémie spécifique",
      PAPILLOMAVIRUS: "papillomavirus humain",
      PERTUSSIS: "coqueluche",
      PNEUMONIA7: "7 types de pneumocoques",
      PNEUMONIA10: "10 types de pneumocoques",
      PNEUMONIA13: "13 types de pneumocoques",
      PNEUMONIA23: "23 types de pneumocoques",
      POLIOMYELITIS: "poliomyélite",
      RABIES: "rage",
      ROTAVIRUS: "rotavirus",
      RUBELLA: "rubéole",
      SEASONALINFLUENZA: "grippe saisonnière",
      TETANUS: "tétanos",
      TICKBORNEENCEPHALITIS: "encéphalite à tiques",
      TUBERCULOSIS: "tuberculose (BCG)",
      TYPHOID: "fièvre typhoïde",
      VARICELLA: "varicelle",
      YELLOWFEVER: "fièvre jaune",
      ZONA: "zona",
    },
    task: {
      TASKS: "Liste des tâches vaccins",
      RECALL: "Rappel pour",
    },
    box: {
      VACCINE: "Vaccin",
    },
    history: {
      ADULT: "Adulte",
      CHILD: "Enfant",
    },
    DELETE: "Voulez-vous supprimer ce vaccin?",
    "PDF-TITLE": "Relevé des vaccinations",
    BORN_ON: "Né(e) le",
    SSIN: "NISS",
    DOMICILED: "Domicilié(e)",
    GENERATED_BY: "Généré par",
  },
  "template-group-type": {
    SOAPS: "SOAPS",
    ADMINISTRATIFS: "Documents administratifs",
    COURRIER: "Modèles de courrier",
    "COURRIER PATIENT": "Modèles de courrier du patient",
    CONSEILS: "Conseils aux patients",
    RLM: "RLM",
  },
  download: {
    "word-download": "Télécharger le document word",
    "no-info": "Télécharger le fichier joint",
  },
  "patient-ritch-text-service": {
    CLOSE: "Fermer",
  },
  "patient-document-dialog": {
    FAILURE: "Erreur lors de la sauvegarde",
    HUB_FAILURE: "Erreur lors de l'envoi",
    HUB_SUCCESS: "Votre document a bien été envoyé",
    FAILURE_REFFERING: "Aucun médecin traitant renseigné pour ce patient",
  },
  "patient-document": {
    TITLE: "Documents",
    VALIDATE: "Validation",
    CANCEL: "Annuler",
    PRINT: "Imprimer",
    SEND_TO_EHB: "Envoyer par eHealthBox",
    SEND_TO_HUB: "Envoyer sur le HUB",
    SAVE: "Enregistrer",
    DOWNLOAD_FOR_PATIENT: "Download medispring scheme",
    EMAIL: "Envoyer via email",
    SUBJECT: "",
    BODY: "",
    SELECT_HCP: "Veuillez choisir un destinataire :",
    SELECTION: {
      NONE: "Aucun",
      ATTENDING_PHYSICIAN: "Médecin traitant",
      OTHER_HCP: "Autre destinataire",
      PATIENT: "Patient",
    },
    EXPORT_FORMAT: {
      medidoc: "Format Medidoc",
      h1: "Format HealthOne",
    },
    NEXT: "Valider",
    BACK: "Retour",
    UNKNOWN_PAGE: "Erreur, page de dialogue inconnue!",
    PLEASE_WAIT:
      "Les modèles sont en cours de chargement, veuillez patienter ...",
    NO_ATTENDING_PHYSICIAN: "Ce patient n'a pas de médecin traitant!",
  },
  "patient-eform": {
    TITLE: "eForm",
    VALIDATE: "Validation",
    CANCEL: "Annuler",
    PRINT: "Imprimer",
    SEND_TO_EHB: "Envoyer par eHealthBox",
    SAVE: "Enregistrer",
  },
  boolean: {
    TRUE: "Oui",
    FALSE: "Non",
  },
  "partial-date": {
    UNKNOWN: "date inconnue",
  },
  "maintenance-module": {
    SHOULD_FIRST_START_CONFLICT_RESOLUTION:
      "Trop de conflits. Veuillez d'abord exécuter la maintenance pour résoudre les conflits de base de données.",
    MAINTENANCES: "Maintenances",
    MAINTENANCE_NAME: "Nom",
    MAINTENANCE_START_DATE: "Date de début",
    MAINTENANCE_END_DATE: "Date de fin",
    MAINTENANCE_LOG_DETAILS: "Informations complémentaires",
    MAINTENANCE_NEED_PASSWORD:
      "Cette maintenance nécessite un mot de passe. Veuillez contacter le support pour obtenir ce mot de passe.",
    PASSWORD: "Mot de passe",
    INCORRECT_PASSWORD: "Mot de passe incorrect",
    STOP_MAINTENANCE: "Arrêter les maintenances",
    VALIDATE: "Valider",
    YOU: "Vous",
    WAITING_FOR_STABILISATION:
      "En attente de la stabilisation de votre base de données...",
    STOP_AND_QUIT: "Arrêter les maintenances et continuer vers Medispring",
    MAINTENANCE_AVAILABLE: "Une ou plusieurs maintenance(s) disponible(s):",
    MAINTENANCE_STOPPED: "Arrêtée",
    MAINTENANCE_FAILED: "A échouée",
    RUN_MAINTENANCE: "Appliquer la maintenance",
    RUN_SELECTED_MAINTENANCES: "Exécuter les maintenances sélectionnées",
    MAINTENANCE_HISTORY_UNAVAILABLE:
      "Impossible d'ouvrir l'historique de cette maintenance",
    MAINTENANCE_RESULTS: "Résultats des maintenances",
    MAINTENANCE_STATUS: "Status",
    MAINTENANCE_DESCRIPTION: "Maintenance",
    MAINTENANCE_DETAILS: "Détails",
    CONTINUE_TO_MEDISPRING: "Continuer vers Medispring",
    MAINTENANCE_NOT_STARTED: "Pas démarrée",
    MAINTENANCE_IN_ERROR: "Erreur",
    MAINTENANCE_STARTED: "Démarrée",
    MAINTENANCE_SUCCESS: "Succès",
    MAINTENANCE_REQUIRED: "Obligatoire",
    MAINTENANCE_HAS_BEEN_RUN: "A été exécutée",
    MAINTENANCE_START_TIME: "Démarré à",
    MAINTENANCE_COMPLETION_TIME: "Terminé à",
    BACK: "Retour",
    PAUSE_MIGRATION: "Mettre en pause",
    INFO_PAUSED_MIGRATION:
      "La migration va bientôt s'arrêter. Veuillez attendre. Vous serez redirigé(e) vers la page d'acceuil.",
    INFO_RESUME_MIGRATION:
      "La migration est mise en pause. Vous pouvez reprendre la migration maintenant ou plus tard en exécutant la migration dans la fenêtre des maintenances une nouvelle fois.",
    TITLE_PAUSED_MIGRATION: "Mise en pause de la migration",
    INFO_FAILED_MIGRATION_PATIENT: "La migration du patient suivant a échoué: ",
    INFO_FAILED_MIGRATION_PATIENT_2:
      "La migration des autres patients continue. Dans le cas où beaucoup de patients échouent la migration, vous pouvez mettre en pause la migration et contacter le support client.",
    INFO_NO_VALID_GROUPID:
      "Aucun group id valide a été trouvé. Sans group id, la migration ne peut pas commencer. Veuillez contacter le support.",
    FAILED_TO_RESOLVE_DB_CONFLICTS:
      "La résolution des conflits en base de données a échoué.",
    INFO_COMPLETED_MIGRATION:
      "La migration des données Topaz/iCure est terminée avec succès.",
    TITLE_COMPLETED_MIGRATION: "Migration terminée",
    INFO_MIGRATION_UNMIGRATED_PATIENTS:
      "Certains patients n'ont pas pu être migrés. Pour résoudre ce problème, s'il vous plaît, relancer la maintenance à nouveau, ou contactez l'équipe de support.",
    INFO_FAILED_MIGRATION:
      "La migration des données Topaz/iCure a échoué. Veuillez contacter l'équipe d'assistance pour obtenir de l'aide.",
    TITLE_FAILED_MIGRATION: "La migration a échoué de manière inattendue",
    INFO_FAILED_TO_LAUNCH:
      "La migration des données Topaz/iCure n'a pas pu être lancée. Veuillez contacter l'équipe de support pour obtenir de l'aide.",
    TITLE_FAILED_TO_LAUNCH: "Erreur lors du lancement de la migration",
    INFO_MIGRATION_ALREADY_STARTED:
      " a déjà commencé la migration. Deux utilisateurs ne peuvent pas faire la migration simultanément.",
    INFO_MIGRATION_IS_LOCKED: "La migration est verrouillée.",
    INFO_MIGRATION_IN_ERROR:
      "La migration ne peut pas être lancée car elle est précédemment terminée en état d'erreur. Veuillez contacter le support.",
    INFO_MIGRATION_ALREADY_SUCCEEDED:
      "La migration est déjà terminée avec succès.",
    PERMANENT_MIGRATION_NOT_ALLOWED:
      "Vous n'êtes pas autorisé à exécuter la migration.",
    MIGRATION_IN_PROGRESS: "Migration en cours ...",
  },
  "printer-service": {
    SENT: "Données envoyées à l'imprimante.",
    ERROR: "Une erreur est survenue durant l'impression.",
    IMPRESSION: "Impression",
    PRINTER_NOT_FOUND:
      "{{ printerName }} n'est pas disponible. Veuillez selectioner une autre imprimante.",
    CONVERT_ERROR: "Impossible de convertir le fichier pour l'impression",
  },
  "late-invoice-dialog": {
    title: "Retard de paiement",
    recall_1:
      "Bonjour, nous avons détecté un retard de paiement sur votre/vos facture(s) suivante(s):",
    recall_2:
      "Afin d'éviter toute désactivation d'accès, merci de bien vouloir régulariser la situation si cela n'avait pas encore été fait.",
    recall_3:
      "N'hésitez pas à nous contacter à l'adresse facture@medispring.be si vous avez la moindre question.",
    recall_4: "Merci",
    recall_5: "du",
    recall_6: "Facture n°",
    cancel: "Fermer",
  },
  rlm: {
    DOCUMENTS: "Documents",
    ADMIN_DATA: "Administratif",
    TREATMENTS: "Traitements",
    HCP: "Intervenants",
    OBJECTIVES: "Objectifs",
    FOLLOW_UP: "Suivi annuel",
    PRESCRIPTIONS: "Prescriptions",
    CAREPATH: "Trajet de soins",
    RENAL_FAILURE: "Insuffisance rénale",
    PREDIABETES: "Pré-trajet diabète",
    DIABETES2: "Diabète de type 2",
    CAREPATH_RENAL_FAILURE: "Trajet de soins : Insuffisance rénale",
    CAREPATH_PREDIABETES: "Trajet de soins : Pré-trajet diabètes",
    CAREPATH_DIABETES2: "Trajet de soins : Diabète de type 2",
    administrative: {
      PATIENT_MANAGER: "Gestionnaire du trajet de soins",
      PATIENT_MANAGER_DOES_NOT_EXISTS: "Pas de gestionnaire.",
      RLM_SECRETARY: "Secrétaire RLM",
      ACTIVE_CAREPATH: "Trajet de soins actif",
      DIABETES_CONVENTION: "Convention diabète",
      RADIO_YES: "oui",
      RADIO_NO: "non",
      TYPE_OF_PATHOLOGY: "Type de pathologie",
      START_DATE: "Date de création",
      END_DATE: "Date de fin",
      END_REASON: "Motif d'arrêt",
      NOTE: "Note administrative",
      CAREPATH_SIGN_DATES: "Signatures du trajet de soins",
      SIGN_DATE: {
        PATIENT: "Patient",
        GENERAL_PHYSICIAN: "Médecin généraliste",
        SPECIALIST_PHYSICIAN: "Médecin spécialiste",
        MUTUALITY: "Mutuelle",
      },
    },
    "hcp-form": {
      ADD_HCP: "Ajouter un intervenant",
      SPECIALITY: "Specialité",
      DATE: "Date du prochain rendez-vous",
      ACTIONS: "Actions",
      actions: {
        DETAIL: "Détail",
        DELETE: "Supprimer",
        APPOINTMENT: "Rendez-vous",
      },
      APPOINTMENT_SUCCESS:
        "La mise à jour des rendez-vous s'est bien effectuée",
    },
    appointment: {
      DATE: "Date",
      APPOINTMENT_NUM: "Numéro de RDV",
      NOTE: "Note",
      ADD_APPOINTMENT: "Ajouter un rendez-vous",
    },
    treatment: {
      grid: {
        INCLUSION: "A l'inclusion",
        CURRENTLY: "Actuellement",
        ADD_TREATMENT: "Ajouter un traitement",
        DESCRIPTION: "Description",
        TYPE: "Type",
        ACTIONS: "Actions",
        EDIT: "Éditer",
        DELETE: "Supprimer",
      },
      dialog: {
        TITLE_ADD: "Ajouter un traitement",
        TITLE_EDIT: "Éditer un traitement",
        TITLE_EDIT_FROM_REPRISE: "Éditer un traitement (venant d'une reprise)",
        TYPE: "Type",
        DESCRIPTION: "Description",
      },
    },
    followup: {
      ADD_FOLLOW_UP: "Ajouter un suivi",
      DESCRIPTION: "Description",
      YEAR_X: "{{value}}° Année",
      COMBO_ANNEE: "Année du suivi",
      COMBO_GLUCOMETRE: "Type de glucomètre",
      EDUCATION: "Éducation",
      DIETETIC: "Diététique",
      PODIATRIST: "Podologue",
      SAI_EDUC_DATE_PLACE: "Date de mise en place",
      SAI_Nom_Educateur: "Nom éducateur",
      COMBO_EDUC_Statut: "Statut",
      SAI_EDUC_DATE_Global1: "1° Départ",
      SAI_EDUC_DATE_Global2: "2° Départ",
      SAI_EDUC_DATE_Global3: "3° Départ",
      SAI_EDUC_DATE_Global4: "4° Départ",
      SAI_EDUC_DATE_Global5: "5° Départ",
      SAI_EDUC_DATE_Rapport1: "Rapport",
      SAI_EDUC_DATE_Indiv1: "6° Départ",
      SAI_EDUC_DATE_Indiv2: "7° Départ",
      SAI_EDUC_DATE_Indiv3: "8° Départ",
      SAI_EDUC_DATE_Indiv4: "9° Départ",
      SAI_EDUC_DATE_Indiv5: "10° Départ",
      SAI_EDUC_DATE_Rapport2: "Rapport",
      SAI_EDUC_DATE_Rapport3: "Rapport",
      SAI_EDUC_DATE_Rapport4: "Rapport",
      SAI_EDUC_DATE_Rapport5: "Rapport",
      SAI_EDUC_DATE_Rapport6: "Rapport",
      SAI_EDUC_DATE_Suivi1: "Suivi 1",
      SAI_EDUC_DATE_Suivi2: "Suivi 2",
      SAI_EDUC_DATE_Rapport11: "Rapport",
      SAI_EDUC_DATE_Rapport12: "Rapport",
      SAI_EDUC_Note: "Note",
      SAI_EDUC_DATE_Compl1: "Complication 1",
      SAI_EDUC_DATE_Compl2: "Complication 2",
      SAI_EDUC_DATE_Compl3: "Complication 3",
      SAI_EDUC_DATE_Compl4: "Complication 4",
      SAI_EDUC_DATE_Rapport7: "Rapport",
      SAI_EDUC_DATE_Rapport8: "Rapport",
      SAI_EDUC_DATE_Rapport9: "Rapport",
      SAI_EDUC_DATE_Rapport10: "Rapport",
      SAI_DIE_DATE_PLACE: "Date de mise en place",
      SAI_Nom_Dietetique: "Nom diététique",
      COMBO_DIE_Statut: "Statut",
      SAI_DIE_DATE_1: "1° Séance 30'",
      SAI_DIE_DATE_2: "2° Séance 30'",
      SAI_DIE_DATE_Rapport1: "Rapport",
      SAI_DIE_DATE_Rapport2: "Rapport",
      SAI_DIE_Note: "Note",
      SAI_POD_DATE_PLACE: "Date de mise en place",
      SAI_Nom_Podologue: "Nom podologue",
      COMBO_POD_Statut: "Statut",
      SAI_POD_DATE_1: "1° Séance 45'",
      SAI_POD_DATE_2: "2° Séance 45'",
      SAI_POD_DATE_Rapport1: "Rapport",
      SAI_POD_DATE_Rapport2: "Rapport",
      SAI_POD_Note: "Note",
      dialog: {
        TITLE: "Suivi annuel diabète",
        TITLE_DOCUMENT: "Document de suivi",
        ERROR_UPLOAD: "Le document n'a pas pu être envoyé au serveur.",
        EXT_NOT_AUTHORIZED:
          "Les fichiers {{ext}} ne sont pas autorisés. Fichiers autorisés : {{autorized}}",
      },
    },
    prescription: {
      grid: {
        ADD_PRESCRIPTION: "Ajouter une prescription",
        DATE: "Date",
        RENEW_DATE: "A renouveler",
        DESCRIPTION: "Prescription",
        NUMBER: "Nombre(s)",
        COMMENT: "Remarques",
        ACTIONS: "Actions",
        EDIT: "Éditer",
        DELETE: "Supprimer",
      },
      dialog: {
        TITLE_ADD: "Ajouter une prescription",
        TITLE_EDIT: "Éditer une prescription",
        CHOICE: "Choix de la prescription",
        DATE: "Date prescription",
        RENEW_DATE: "Prescription à renouveler le",
        NUMBER: "Nombre(s) prescrit",
        COMMENT: "Remarque",
      },
    },
    actions: {
      DELETE_ANNUAL_CONTACT: "Supprimer le suivi annuel",
      DELETE_DOCUMENT: "Supprimer le document",
      CANCEL: "Annuler",
      SAVE: "Enregistrer",
      SAVE_AND_PRINT: "Enregistrer et imprimer",
    },
    message: {
      DELETE_ANNUAL_CONTACT:
        "Êtes-vous sûr de vouloir supprimer le suivi annuel ?",
      DELETE_REPORT_DOCUMENT:
        "Êtes-vous sûr de vouloir supprimer le document ?",
      CONTACT_SAVED: "Contact sauvegardé",
      CONTACT_DELETED: "Contact Supprimé",
      ERROR_WHILE_SAVING_CONTACT:
        "Une erreur est survenue lors de la sauvegarde du contact",
      ERROR_WHILE_DELETING_CONTACT:
        "Une erreur est survenue lors de la suppression du contact",
    },
    "list-code": {
      undefined: "?",
      nodiabetes: "Pas de diabète",
      diabetestypeone: "Diabète de type I",
      diabetestypeoneorgan: "Diabète de type I + atteinte organe",
      diabetestypetwo: "Diabète de type II",
      diabetestypetwoandrisk: "Diabète de type II + au moins un FR",
      no: "Non",
      low: "Faible (>60 ml/min/1.73m²)",
      moderatetostrict: "Modéré à sévère (<60ml/min/1.73m²)",
      moved: "A déménagé",
      convention: "Convention",
      deceased: "Décédé(e)",
      offcriteria: "Hors critères",
      notcompleted: "Non abouti",
      nonrespectobligation: "Non respect obligation",
      refusesfollowup: "Refuse le suivi",
      year: "{{value}}° Année",
      ACCUCHEK_AVIVA: "Accu-chek Aviva",
      ACCUCHEK_COMPACT: "Accu-chek Compact",
      ACCUCHEK_COMPAC: "Accu-chek Compact +",
      ACCUCHEK_MOBILE: "Accu-chek Mobile",
      ACCUCHEK_NANO: "Accu-chek Nano",
      ACCUCHE: "Accu-chek*",
      BGSTAR: "BG star",
      BREEZE2: "Breeze 2",
      CONTOUR: "Contour",
      CONTOUR_XT: "Contour XT",
      FINE_TOUCH: "Fine touch",
      FREESTYLE: "Freestyle",
      GLUCOMEN_GM: "Glucomen GM",
      GLUCOMEN_LX: "Glucomen LX",
      GLUCOME: "Glucomen*",
      ONETOUCH_ULTRA: "One Touch Ultra",
      ONETOUCH_ULTRA2: "One Touch Ultra 2",
      ONETOUCH_ULTRA_EASY: "One Touch Ultra Easy",
      ONETOUCH_ULTRA_VERIO: "One Touch Ultra Verio",
      VITA: "Vita",
      ONETOUCH_ULTR: "One Touch Ultra*",
      PRECISION_EXCEED: "Precision Exceed",
      GLUCOMEN_AREO: "Glucomen Areo",
      FOLLOWED: "Déjà suivi",
      WAITING: "En attente",
      MESSAGE: "Message",
      REFUSAL: "Refus",
      VIA_RML: "Via le RML",
      dietetic: "Séance diététique",
      initialeducation: "Séance éducation de départ",
      followupeducation: "Séance éducation de suivi",
      complementaryeducation: "Séance éducation complémentaire",
      podiatrist: "R / podologue",
      glucometer: "Glucomètre",
      teststrips: "Tigettes",
      oralantidiabetic: "Antidiabétiques oraux",
      incretinomimetics: "Incrétinomimétiques",
      "1insulininjection": "1 injection d'insuline",
      "2insulininjection": "2 injections d'insuline",
    },
  },
  "file-vignettes": {
    SELECT_FILE: "Choisir ce fichier",
  },
  "file-vignette": {
    DOCUMENT: "Document",
  },
  "hcp-selector": {
    NIHII: "INAMI",
    SSIN: "NISS",
    LAST_NAME: "Nom",
    FIRST_NAME: "Prénom",
    SPECIALITY: "Spécialité",
    COORDINATE: "Coordonnées",
    NOTES: "Notes",
    LOADING: "Chargement...",
    CREATE: "Créer le prestataire",
  },
  "guardPost-mode": {
    UNDEFINED_GUARDPOST_MEDICAL_LOCATION:
      "Les informations de votre entité ne sont pas disponibles ou n'ont pas été selectionnées correctement. Veuillez vous reconnecter afin de choisir les informations de votre entité ou contacter le Helpdesk.",
    INVALID_GUARDPOST_MEDICAL_LOCATION:
      "Les informations de votre entité ne sont pas complète. Nom: {{name}} et Inami: {{nihii}}. Veuillez contacter le Helpdesk",
  },
  "printer-management": {
    PRESCRIPTION: "Prescription",
    ITT: "ITT",
    TAG: "Étiquette",
    DOCUMENT: "Document",
    PRINTER: "Imprimante",
    FORMAT: "Format",
    TITLE: "Impression",
    ALWAYS_REQUEST_PRINTER: "Demander à chaque fois",
  },
  "tag-print": {
    "L2.1": "Niss: {{ssin}}  GSM: {{gsm}}",
    "L2.2": "DN: {{date}}  GSM: {{gsm}}",
    L5: "Mutuelle : {{mutuality}}  Status : {{statut}}",
  },
  "security-management": {
    TITLE: "Sécurité",
    TEXT: "Déconnexion automatique après une inactivité de:",
    MINUTES: "minutes.",
  },
  "idle-popup": {
    TITLE: "Déconnexion automatique",
    CONTENT:
      "Vous avez été inactif sur Medispring depuis {{timeout}} minutes. Vous allez être déconnecté dans un instant.",
  },
  "print-document-dialog": {
    CANCEL: "Annuler",
    FORMAT: "Format",
    PRINTER: "Imprimante",
    PRINT: "Imprimer",
    SAVE_MY_CHOICE: "Sauvegarder mon choix",
    ALWAYS_CHANGE_LATER:
      "Vous pourrez toujours modifier vortre choix dans les outils, Impression",
  },
  "document-types": {
    ITT: "ITT",
    PRESCRIPTION: "Prescription",
    TAG: "Etiquette patient",
  },
  SUMEHR: {
    COMMENT: "Sumehr crée par Medispring.",
    SECRET_ELEMENT:
      "Certains élements du dossier n'apparaissent pas car privé ou non relevant.",
    EMPTY_CATEGORY: "La catégorie {{c}} n'a pas d'élément dans le dossier.",
    EMPTY_CATEGORIES:
      "Les catégories {{c}} n'ont pas d'élément dans le dossier.",
    STATUS: {
      UP_TO_DATE: "Dernier sumehr envoyé le {{date}}",
      MISSING: "Pas de sumehr uploadé pour le patient",
      CHANGED:
        "Une nouvelle version du sumehr peut être exportée (dernier export le {{date}})",
    },
  },
  "version-info": {
    RELEASE: "Mise à jour",
    RELEASE_DATE: "Date de la mise à jour",
  },
  "versions-info": {
    BACK: "Retour",
  },
  "scan-import-service": {
    ERROR_SEARCH_AND_IMPORT: "Erreur lors de la recherche et de l'import",
  },
  "progress-bar": {
    ESTIMATED_TIME_LEFT: "Temps restant estimé",
  },
  "import-task-row": {
    IMPORT_OF_FILES: "Importation des fichiers",
    IMPORT_COMPLETE: "Importation complète",
    IMPORT_WILL_NOT_STOP_IF_YOU_NAVIGATE_TO_OTHER_PAGE:
      "L'importation ne s'arrêtera pas si vous naviguez en dehors de cette page",
  },
  "scan-file-list": {
    FILE_NAME: "Nom de fichier",
    IMPORT_STATUS: "Statut d'import",
    PATIENT: "Patient",
    CONTACT_DATE: "Date de contact",
    RESPONSIBLE: "Responsable",
    CONCLUSION: "Conclusion",
  },
  "scan-file-row": {
    READY_FOR_IMPORT: "Prêt pour l'import",
    IMPORTED: "Importé",
    MISSING_INFO: "Informations manquantes",
    DUPLICATE: "Doublon",
    IMPORTING: "Importer",
    FAILED: "Échoué",
    SELECT_PATIENT: "Choisir un patient",
    CANCEL: "Annuler",
    SAVE: "Sauvegarder",
    SELECT_RESPONSIBLE: "Choisir un responsable",
    BASED_ON_NAME_FILE: "Basé sur le nom du fichier",
  },
  "select-date-dialog": {
    SAVE: "Sauvegarder",
    CANCEL: "Annuler",
  },
  "efac-to-be-sent": {
    BATCH_ALREADY_SENT_ERROR:
      "un batch a déjà été envoyé aujourd'hui vers l'OA {{ioCode}}",
    NO_FINANCIAL_INFO_FOR_ASSISTANTS:
      "Compte en banque partagé des assistants inconnu",
    COLLECTIVE_SETTING_WHILE_PRACTICIAN:
      "La perception individuelle n'est pas autorisée pour ce PMG.",
    INDIVIDUAL_SETTING_WHILE_GUARDPOST:
      "La perception centralisée n'est pas autorisée pour ce PMG.",
    ONLY_SUPERVISOR_CAN_SEND_ATTESTS:
      "Seul votre maître de stage peut envoyer vos eFacts.",
    INCORRECT_PRESCRIBER_NIHII:
      "Le numéro INAMI du prescripteur est incorrect.",
    INCORRECT_LOCATION_NIHII:
      "Le numéro INAMI du lieu de prestation est incorrect.",
    PATIENT_CT_PARAMS_NOT_THERE:
      "Les données d'assurabilité CT1 et CT2 sont absentes pour le patient.",
  },
  exports: {
    groups: {
      CONTACT: "Contact",
      SUB_CONTACT: "Sous contact",
      HEALTH_ELEMENT: "Élément de soin",
      PLAN_OF_ACTION: "Plan d'action",
      SERVICE: "Service",
    },
    fields: {
      CONTACT_ID: "ID",
      CONTACT_TYPE: "Type",
      CONTACT_SPECIALITY: "Spécialité",
      CONTACT_DATE: "Date",
      CONTACT_AUTHOR_ID: "ID de l'auteur",
      CONTACT_AUTHOR_NAME: "Nom de l'auteur",
      CONTACT_INTEGRATOR_ID: "ID de l'intégrateur",
      CONTACT_INTEGRATOR_NAME: "Nom de l'intégrateur",
      CONTACT_INTEGRATION_DATE: "Date de l'intégration",
      SUB_CONTACT_ID: "ID",
      SUB_CONTACT_HE_NAMES: "Éléments de soin",
      HEALTH_ELEMENT_NAME: "Nom (Éléments de soin)",
      PLAN_OF_ACTION_NAME: "Nom (Plan d'action)",
      SERVICE_ID: "ID",
      SERVICE_NAME: "Nom",
      SERVICE_VALUE: "Valeur",
      SERVICE_UNIT: "Unité",
      SERVICE_TAGS: "Tags",
      SERVICE_CREATION_DATE: "Date de création",
    },
    values: {
      CONTACT: {
        types: {
          consult: "Consultation",
          labresult: "Résultat de laboratoire",
          contactreport: "Rapport",
          telephonicconsultation: "Avis/Divers",
          multidisciplinaryconsult: "Concertation multidisciplinaire",
          homevisit: "Visite à domicile",
        },
      },
      SERVICE: {
        types: {
          PLAN_TEXT: "Plan d'action",
          PLAN_MEDICATION: "Traitement",
        },
      },
    },
  },
  "patients-mailing-dialog": {
    EMAIL: "Email",
    PAPER: "Papier",
    PLACE_DOCUMENT_AS_ATTACH: "Mettre le document en pièce jointe à l'email",
    SEND_CHANNEL: "Type d'envoi",
    DOCUMENT: "Document",
    PRINT_FOR_PATIENTS_WITHOUT_ADDRESS:
      "Imprimer aussi pour les patients sans adresse",
    PRINTER: "Imprimante",
    CANCEL: "Annuler",
    PRINT: "Imprimer",
    SEND_EMAIL: "Envoyer emails",
    EMAIL_BODY: "Contenu du mail",
    EXPORT_CSV: "Export Excel",
    FAILED_SENDING_EMAIL_TO: "Impossible d'envoyer l'email à {{name}}",
    FAILED_PRINTING_DOCUMENT_FOR:
      "Impossible d'imprimer le document pour {{name}}",
    BACK: "Retour",
    SUBJECT: "Sujet",
    NAME: "Nom",
    FROM: "De",
    EXCLUDE_PATIENTS:
      "Exclure les patients à qui un message similaire a déjà été envoyé",
    TIME_UNIT: {
      days: "Jours",
      weeks: "Semaines",
      months: "Mois",
      years: "Ans",
    },
    TIME_FRAME: "Plage de temps",
    EXCLUDED_PATIENTS: "patients à qui un message similaire a déjà été envoyé",
    SELECT_SEND_CHANNEL: "Selectionez un type d'envoi",
    CONFIGURE_PAPER: "Configurez l'impression",
    CONFIGURE_MAILING: "Configurez l'envoi",
    COMPOSE_MAIL: "Composez le courrier à envoyer",
    SELECT_A_DOCUMENT_TYPE: "Sélectionez un document type (optionnel)",
    EXCLUSION_OF_PATIENTS: "Exclusion de certains patients",
    PATIENTS_WITHOUT_EMAIL: "patients sans adresse email",
    PATIENTS_WITHOUT_HOME_ADDRESS: "patients sans adresse de domicile",
    PATIENTS_WITH_MISSING_INFO:
      "patients pour lesqueles des informations sont manquantes",
    EXPORT_LIST_OF_THESE_PATIENTS: "Exporter la liste de ces patients",
    CONFIRMATION_OF_IMPRESSION: "Confirmation de l'impression",
    NUMBER_OF_PATIENTS_SELECTED: "Nombre de patients sélectionnés",
    NUMBER_OF_PATIENTS_AFTER_EXCLUSION: "Nombre de patients après exclusion",
    THE_CONTENT_WILL_BE_SAVED_AS_PDF: "Le courier sera téléchargé en PDF",
    NAME_OF_SENDER: "Nom de l'expéditeur",
    SEND_FROM_EMAIL_ADDRESS: "Envoyer depuis l'adresse email",
    COMPOSE_EDIT_BODY_CONTENT: "Composer/éditer le corps du texte",
    ALL_FIELDS_ARE_REQUIRED: "Tout les champs sont obligatoires",
    PATIENT_HAS_NO_EMAIL: "Patient n'a pas d'adresse email",
  },
  "dropdownlist-filter": {
    ALL: "Tous",
  },
  "medication-scheme": {
    AUTHOR: "Auteur",
    REMARKS: "Remarques",
    FREQUENCY: "Fréquence",
    BEGIN_MOMENT: "Début",
    END_MOMENT: "Fin",
    INNAME_AND_ROUTE: "Mode d'administration",
    groups: {
      ONESHOT: "Médication à la demande",
      ACUTE: "Medications aigues",
      CHRONIC: "Medications chroniques",
    },
    regimen: {
      BREAKFAST: "Matin",
      LUNCH: "Midi",
      DINNER: "Soir",
      MORNING: "à jeun",
      NIGHT: "Au coucher",
      BEFORE: "Avant le repas",
      DURING: "Pendant  le repas",
      AFTER: "Après le repas",
      head: {
        PATIENT: "Patient",
        EDITED_BY: "Dernière modification par",
        RETRIEVED_BY: "Imprimé par",
        EDITED_ON: "Durée",
        RETRIEVED_ON: "Temps de récupération",
        VERSION: "Version no",
      },
    },
    SEND_OK: "Schéma de médications envoyé",
    SEND_ERROR: "Erreur lors de l'envoi du schéma de médications: {{error}}",
    SEND: "Envoyer le schéma",
    DOWNLOAD: "Telecharger le XML",
    IMPORT: "Importer",
    IMPORT_TITLE: "Importer le schéma",
  },
  "access-journal": {
    DATE: "Date",
    USER: "Utilisateur",
    ROLES: "Roles",
    PATIENT_FILE: "Dossier Patient",
    ACCESS_TYPE: "Type",
    type: {
      USER_ACCESS: "Acces aux patients",
      USER_LOGIN: "Connexions",
      ALL: "Tous",
    },
    FILTER_BY_USER: "Filtrer par utilisateur",
  },
  proofs: {
    NO_CARD: "Pas de carte",
    HOUSE_CALL: "Appel à domicile",
    TECH_PROBLEM: "Problème technique",
  },
  "address-book": {
    SEARCH_GLOBAL_DIRECTORY:
      "Rechercher un destinataire depuis le carnet d'adresse eHealth (Cobrha)",
    SEARCH_COBRHA: "Recherche Cobrha",
    SEARCH_BY_NAME:
      "Tapez au moins 2 lettres suivi de * pour rechercher un nom ou entrez un n° INAMI/NISS/BCE/EHP.",
    SEARCH_BY_QUALITY: "Rechercher un type. Défaut: médecin",
    CANCEL: "Annuler",
    OK: "Ajouter",
    TOOLTIP: "Rechercher dans le carnet d'adresse eHealth (Cobrha)",
    LABELS: {
      NAME: "Nom",
      NIHII_NISS_CBE_EHP: "Inami/NISS/CBE/EHP",
      SPECIALTY: "Spécialité",
      QUALITY: "Qualité",
    },
    SELECTED_RECIPIENTS: "Destinataires sélectionnés:",
    ADD_IN_LOCAL_ADDRESS_BOOK: "Ajouter les destinataires au carnet d'adresses",
    QUALITIES: {
      AMBULANCE_RESCUER: "Ambulancier",
      APPLIED_PSYCH_BACHELOR: "Bachelier en psychologie appliquée",
      AUDICIAN: "Audicien",
      AUDIOLOGIST: "Audiologue",
      DENTIST: "Dentiste",
      DIETICIAN: "Diététicien",
      PHYSICIAN: "Médecin",
      FAMILY_SCIENCE_BACHELOR: "Bachelier en science de la famille",
      GERONTOLOGY_MASTER: "Master en gérontologie",
      IMAGING_TECHNOLOGIST: "Technologue en imagerie médicale",
      IMPLANTPROVIDER: "Dispensateur d'implants",
      LAB_TECHNOLOGIST: "Technologue de labo",
      LOGOPEDIST: "Logopède",
      MIDWIFE: "Sage-femme",
      NURSE: "Infirmier/Infirmière",
      OCCUPATIONAL_THERAPIST: "Ergothérapeute",
      OPTICIAN: "Opticien",
      ORTHOPEDAGOGIST_MASTER: "Master en orthopédagogie",
      ORTHOPEDIST: "Orthopédiste",
      ORTHOPTIST: "Orthoptiste",
      PEDIATRIC_NURSE: "Infirmier/Infirmière en pédiatrie",
      PHARMACIST: "Pharmacien",
      PHARMACIST_ASSISTANT: "Assistant pharmacien",
      PHYSIOTHERAPIST: "Kinésithérapeute",
      PODOLOGIST: "Podologue",
      PRACTICAL_NURSE: "Aide-soignant",
      PSYCHOLOGIST: "Psychologue",
      PSYCHOMOTOR_THERAPY: "Master thérapie psychomotrice",
      READAPTATION_BACHELOR: "Bachelier en réadaptation",
      SOCIAL_WORKER: "Assistant social",
      SPECIALIZED_EDUCATOR: "Educateur spécialisé",
      TRUSS_MAKER: "Bandagiste",
      AMBU_SERVICE: "Service ambulancier",
      CONSORTIUM: "Consortium dépistage du cancer",
      CTRL_ORGANISM: "Organisme de contrôle",
      EHP: "Entité spéciale reconnue par la plate-forme eHealth",
      ENTERPRISE: "Entreprise",
      GROUPOFDOCTORS:
        "Groupement de médecins généralistes à différents lieux d'installation",
      GROUPOFNURSES: "Groupement d'infirmiers",
      GUARD_POST: "Poste de garde",
      HOME_SERVICES: "Service de soins intégrés à domicile",
      HOSPITAL: "Hôpital",
      LABO: "Laboratoire",
      MEDICAL_HOUSE: "Maison médicale",
      OF_BAND: "Atelier bandagiste-orthopédiste",
      OFFICE_DOCTORS:
        "Groupement de médecins généralistes au même lieu d'installation",
      OTD_PHARMACY: "Office de tarification",
      PALLIATIVE_CARE: "Soins palliatifs",
      PHARMACY: "Pharmacie",
      PROT_ACC:
        "Initiatives d'habitation protégée pour patients psychiatriques",
      PSYCH_HOUSE: "Maison de soins psychiatrique",
      RETIREMENT: "Maison de repos",
      TREAT_CENTER: "Centre de traitement",
      INSTITUTION: "Institution",
      SORTING_CENTER: "Centre de triage",
      DOCTOR: "Médecin",
      INSTITUTION_EHP: "Institution EHP",
    },
  },
  "e-health-box-errors-by-code": {
    UNKNOWN: "Erreur inconnue.",
    "403_COULD_NOT_RETRIEVE_ETK":
      "Un ou plusieurs destinataires n'ont pas de certificat eHealth valide. Ceci peut arriver si leur certificat a expiré ou bien si un des destinataires entrés n'existe pas.",
    "801": "Le message dépasse la taille maximale autorisée",
    "802":
      "Le destinataire est inconnu; merci de vérifier que l'ID du destinataire est valide (NISS, INAMI, CBE,...)",
    "803":
      "La qualité du destinataire est invalide; merci de vérifier que la qualité est une qualité reconnue par eHealth",
    "804":
      "Le type du destinataire est invalide; merci de vérifier que le type est un type reconnu par eHealth",
    "805":
      "Le sous-type spécifié est invalide; merci de vérifier que le sous-type est un sous-type reconnu par eHealth",
    "810":
      "L'ID de la boite eHealthbox spécifié est invalide; merci de vérifier que vous avez normalement accès à cette eHealthbox",
    "814":
      "Vous n'êtes pas autorisé à publié sur cette qualité; contactez la plateforme eHealth pour en obtenir l'autorisation.",
    "816":
      "La clé de décryption du document ne matche pas la clé fournie. Recalculez le hash SHA-256 du contenu.",
    "826":
      "Un ou plusieurs des destinataires ont un 'out-of-office' activé, veuillez les enlever de la liste des destinataires. ",
    "700": "Erreur technique inconnue",
    "701":
      "Erreur de validation business du message composé. Certains champs techniques sont manquants ou mal remplis.",
    "702":
      "Publication ID existe déjà, choisissez-en un nouveau et renvoyer le message",
    "703": "Un ou plusieurs destinataires sont invalides",
    "806":
      "Le messageID est invalide; merci de vérifier que la Source and le MessageID sont corrects et que vous pouvez y accéder",
    "807":
      "EndIndex doit être plus grand ou égal au StartIndex; merci de corriger StartIndex et EndIndex",
    "808":
      "Un maximum de 100 messages peuvent être retournés par requête; merci de corriger StartIndex et EndIndex",
    "809":
      "Le MessageID spécifié est invalide; merci de vérifier que le MessageID est correct et que vous en êtes l'auteur.",
    "812":
      "Vous ne pouvez pas déplacer un message de votre boite de réception vers votre boite d'envoi (même via la corbeille) et vice versa",
    "813":
      "Tous les messages n'ont pas pu être déplacés. Merci de vérifier pour chaque message que la Source et le MessageID sont corrects. Faites aussi attentoin qu'un message dans la corbeille qui provient de la boite de réception ne peut pas être restauré dans la boite d'envoi et vice versa.",
    "815":
      "Tous les messages n'ont pas pu être supprimés. Merci de vérifier pour chaque message que la Source et le MessageID sont corrects.",
    "820":
      "La période du <startdate> au <enddate> est invalide car elle se superpose à une autre période.",
    "821":
      "La date de fin de la période ne peut pas être plus éloignée qu'une année dans le futur ",
    "822": "La date de début ne peut pas être après la date de fin.",
    "823": "La date de début ne peut pas être dans le passé.",
    "824":
      "Un ou plusieurs substituts ne peuvent être choisis car ils sont absents",
    "825": "Le nombre de substitus ne peut dépasser 5.",
    "827":
      "Un ou plusieurs substituts sont inconnus ou incorrects, merci de les corriger.",
    "828": "L'utilisateur est inconnu ou incorrect, merci de le corriger. ",
    "829":
      "Un substitut valide doit être une personne, pas une organisation. Merci de corriger.",
    "830": "Une personne ne peut être un substitut pour elle-même.",
    "840": "Un ou plusieurs OoOId sont invalides.",
  },
  application: {
    UNKNOWN_ERROR: "Une erreur inconnue s'est produite",
  },
  statistics: {
    PATIENT_ISO_CODE: "Patient dont le pays doit être changé manuellement",
    APPOINTMENT_HOUR: "Heure de rendez-vous",
    APPOINTMENT_DATE: "Date de rendez-vous",
    CALENDAR_ITEM_ID: "Calendar item ID",
    PMG_AGENDA: "Agenda PMG",
    WEEKDAY: {
      "1": "Lundi",
      "2": "Mardi",
      "3": "Mercredi",
      "4": "Jeudi",
      "5": "Vendredi",
      "6": "Samedi",
      "7": "Dimanche",
    },
    STATISTICS: "Statistiques",
    AGE_DISTRIBUTION: "Tranche d'age",
    AGE_NOT_DEFINED: "âge indéterminé",
    YEARS: "ans",
    FILE_FOR_RASH: "Dossier pour RASH",
    PERIOD_FROM: "Période du",
    TO: "au",
    NUMBER_OF_BIM_PATIENTS: "Nombre de patients BIM",
    NUMBER_OF_DMG_PATIENTS: "Nombre de patients DMG",
    NUMBER_OF_AMU_PATIENTS: "Nombre de patients AMU",
    GROUPING_BY_GENDER: "Répartition selon genre",
    GENDER: {
      MEN: "Hommes",
      WOMEN: "Femmes",
      OTHER: "Autres",
      TOTAL: "Total",
    },
    GROUPING_BY_POSTAL_CODE: "Répartition selon la commune du destinataire",
    GROUPING_OF_CONSULATIONS_BY_FUNCTION: "Nombre de consultations/prestations",
    NUMBER_OF_ACTIVE_PATIENTS: "Nombre de patients actifs",
    INAMI: "Au forfait Inami",
    ACT: "A l'acte",
    ERROR_GENERATING_STATISTICS:
      "Une erreur s'est produite lors de la génération des statistiques",
    COMPLETED_GENERATING_STATISTICS:
      "La génération des statistiques est terminé",
    NUMBER_OF_PATIENT_WITH_COLON_CANCER_PREVENTION:
      "Taux de dépistage du cancer du colon",
    "50_TO_75_YEARS": "50 ans à 75 ans",
    NUMBER_OF_PATIENT_WITH_MAMO_PREVENTION:
      "Taux de mammographies de dépistage",
    "50_TO_69_YEARS_WOMEN": "femmes de 50 à 69 ans",
    NUMBER_OF_PATIENT_WITH_FLU_PREVENTION: "Taux de vaccinations antigrippales",
    "65_YEARS_AND_OLDER": "65 ans et plus",
    OTHERS: "Autres",
    CUSTOM_DATES: "Dates personnalisées",
    RENAL_FAILURE: "Insuffisance rénale",
    PRE_DIABETES: "Pré-trajet diabète",
    DIABETES_2: "Diabète de type 2",
    PATIENT_NAME: "Nom du patient",
    CREATION_DATE_TS: "Date de création du TS",
    STOP_DATE: "Date d'arrêt du TS",
    MOTIF_STOP: "Motif d'arrêt",
    POSTAL_CODE: "Code postal",
    M_GENERAL: "Médecin généraliste",
    NEPHRO: "Néphrologue",
    ENDO: "Endocrinologue",
    EDU: "éducateur",
    PODO: "Podologue",
    DIET: "diététique",
    RASH: "RASH",
    RLM: "RLM",
    LOADING: {
      RLM: "Génération des statistiques RLM en cours pour la période de {{start}} au {{end}}",
      RLM_NO_START:
        "Génération des statistiques RLM en cours pour la période avant {{end}}",
      RASH: "Génération des statistiques RASH en cours pour la période de {{start}} au {{end}}",
      RASH_NO_START:
        "Génération des statistiques RASH en cours pour la période avant {{end}}",
      PMG: "Génération des statistiques PMG en cours pour la période de {{start}} au {{end}}",
      PMG_NO_START:
        "Génération des statistiques PMG en cours pour la période avant {{end}}",
      PMGCSV:
        "Génération des statistiques PMG (en format CSV) en cours pour la période avant {{start}} au {{end}}",
      PMGCSV_NO_START:
        "Génération des statistiques PMG (en format CSV) en cours pour la période avant {{end}}",
      PMGAGENDA:
        "Génération des statistiques des rendez-vous de PMG en cours pour la période avant {{start}} au {{end}}",
      PMGAGENDACSV:
        "Génération des statistiques des rendez-vous de PMG (en format CSV) en cours pour la période avant {{start}} au {{end}}",
    },
    LOADING_ACTIVE_PATIENTS: "Chargement des patients actifs",
    FETCHING_CONTACTS: "Récupération des contacts",
    FETCHING_PATIENTS: "Récupération des patients",
    FETCHING_AGENDAS: "Récupération des rendez-vous",
    GENERATING_DATA: "Génération de données",
    FETCHING_INSURANCE_DATA_FOR_BIM:
      "Récupération des données assurance pour les statistiques BIM",
    FETCHING_DMG_DATA: "Récupération des données DMG",
    FETCHING_ACTIVE_PATIENTS: "Récupération des données de patients actifs",
    FETCHING_COLON_CANCER_PREVENTION_DATA:
      "Récupération des données pour taux de dépistage du cancer du colon",
    FETCHING_MAMMOGRAPHY_PREVENTION_fDATA:
      "Récupération des données pour taux de mammographies de dépitagen",
    FETCHING_INFLUENZA_PREVENTION_DATA:
      "Récupération des données pour taux de vaccination antigrippales",
    TREATMENT_INCLUSION: "Traitement à l'inclusion",
    TREATMENT_CURRENT: "Traitement actuel",
    PMG_SHEET: "PMG",
    PATIENT_ID: "Patient id",
    AGENDA_NAME: "Agenda",
    CREATION_WEEKDAY: "Jour",
    CREATION_DATE: "Date de creation",
    CONTACT_TIME: "Heure",
    PATIENT_DATE_OF_BIRTH: "Date de naissance",
    PATIENT_SEX: "Sexe du patient",
    CONSULTATION_TYPE: "Type de consultation",
    PATIENT_ORIENTATION: "Orientation patient",
    PATIENT_POSTAL_CODE: "Code postal",
    MEDICAL_LOCATION_NAME: "PMG Location",
    UNKNOWN_DATE_OF_BIRTH: "Informations manquantes",
    PATIENT_ACT: "Actifs à l'acte",
    PATIENT_FORFAIT: "Actifs au Forfait",
    CONSULT: "Consultation",
    HOME_VISIT: "Visite à domicile",
    ELECTRONIQUE_CONSULT: "Consultation électronique",
    TOTAL: "Totale",
    GROUP_BY_SPECIALITY: "Nombre de contacts par spécialités",
    NUMBER_OF_AMU_PATIENT: "Nombre de patients AMU",
    PATIENT_AMU: "Patients AMU",
    OTHER_PATIENT: "Autres patients",
  },
  EXPORT_COUNTRY_AND_NATIONALITY_MIGRATION: {
    PATIENT_ISO_CODE: "Patient dont le pays doit être changé manuellement",
    FIRSTNAME: "Prénom",
    LASTNAME: "Nom",
    NISS: "Niss",
    EXTERNALID: "Identifiant externe",
    ADDRESS: "Adresse",
    BAD_ENCODE_COUNTRY: "Pays à modifier",
    BAD_ENCODE_NATIONALITY: "Nationalité à modifier",
    EXPORT_COUNTRY_AND_NATIONALITY_MIGRATION: "pays_ou_nationalite_a_corriger",
    ADDRESS_NUMBER: "Adresse n°",
  },
  "patients-pmf": {
    IMPORT_SUFFIX: "Import PMF",
    PMF_LEFT: "Nombre de dossiers restant :",
    IMPORT: {
      STARTED: "Une importation d'un dossier patient est en cours.",
      FINISHED: "L'importation d'un dossier patient est terminée.",
    },
    MULTIPLE_IMPORT: {
      STARTED: "Une importation de plusieurs dossiers patients est en cours.",
      FINISHED: "L'importation de plusieurs dossiers patients est terminée.",
    },
    EXPORT: {
      STARTED: "Un export de dossiers patient est en cours.",
      FINISHED: "L'export d'un dossier patient est terminé.",
    },
    MULTIPLE_EXPORT: {
      STARTED: "L'export de plusieurs dossiers patients est en cours.",
      FINISHED: "L'export de plusieurs dossiers patients est terminé.",
    },
    LOGS_DIALOG: {
      TITLE: "Résumé des erreurs",
      BTN_CLOSE_LABEL: "OK",
      DOWNLOAD_LOGS: "Télécharger les logs",
    },
  },
  "mailing-dialog": {
    CLOSE: "Fermer",
    SEND_EMAIL: "Envoyer l'email",
    TITLE: "Envoyer un email",
    NAME_OF_SENDER: "Nom de l'expéditeur",
    SEND_FROM_EMAIL_ADDRESS: "Envoyer depuis l'adresse email",
    COMPOSE_EDIT_BODY_CONTENT: "Composer/éditer le corps du texte",
    EMAIL_BODY: "Contenu du mail",
    SUBJECT: "Sujet",
    SEND_TO_EMAIL_ADDRESS: "Email du destinataire",
    ATTACHMENTS: "Pièces jointes",
    ERROR_SENDING: "Erreur lors de l'envoi de l'email",
    SUCCESS_SENDING: "Email envoyé avec succès",
    ONLY_AVAILABLE_ON_DESKTOP_APP:
      "Seulement disponible sur l'application de bureau",
  },
  "widget-stats": {
    TITLE: "Statistiques - {{name}}",
    DMG_COUNT: "Nombre de DMG m'appartenant",
    SUMEHR_SENT_COUNT: "Nombre de Sumehrs envoyés",
    DMG_WITH_SUMEHR_SENT_COUNT:
      "Nombre de DMG m'appartenant pour lesquels j'ai envoyé un sumehr",
    WARNING:
      "Les chiffres présentés ci-dessous couvrent uniquement les patients stockés dans la base de données actuelle et peuvent différer du compte officiel eHealth.",
    REFRESH: "Renouveller",
  },
  "widget-pending-prescriptions": {
    TITLE: "Ordonnances en attente",
    OPEN_PRESCRIPTION: "Ouvrir l'ordonnance",
    MY_PENDING_PRESCRIPTIONS: "Mes ordonnances en attente",
    ALL_PENDING_PRESCRIPTIONS: "Toutes les ordonnances en attente",
  },
  "widget-appointments": {
    TITLE: "Prochains rendez-vous de {{name}}",
  },
  "dashboard-management": {
    TITLE: "Configuration du tableau de bord",
    STATS: "Statistiques",
    APPOINTMENTS: "Rendez-vous",
    VISIBLE_WIDGETS: "Choix des widgets",
    PENDING_PRESCRIPTIONS: "Ordonnances en attente",
  },
  "eform-service": {
    ERROR: {
      MISSING_CONFIGURATION: "Aucune configuration du service EForm disponible",
      AUTHENTICATION_ERROR: "Impossible de se connecter au service eForm",
    },
    MESSAGE: {
      SEE_ATTACHMENT: "Voir pièce jointe.",
    },
  },
  "eform-component": {
    EHEALTH_CONNECTION: "Connexion eHealth",
    EFORM_CONNECTION: "Connexion eForm",
    EFORM_CONNECTION_ERROR: "Connexion eForm impossible",
    EHEALTH_CONNECTION_REQUIRED: "Une connexion eHealth est necessaire",
    LOADING_FORM_DEFINITION: "Chargement de la référence du formulaire",
    LOADING_PATIENT_DATA: "Chargement des données du patient",
    LOADING_HANDICARE_FORM: "Création du formulaire",
    LOADING_HANDICARE_FORM_CONTENT: "Pré-remplissage du formulaire",
    SELECT_FILES: "Ajouter des annexes",
    SELECT_FILES_DONE: "Valider la sélection",
    SELECT_RECIPIENTS: "Ajouter des destinataires",
    SELECT_RECIPIENTS_DONE: "Valider la sélection",
    CANCEL: "Annuler",
    SEND: "Envoyer",
    PRINTANDSEND: "Envoyer et imprimer",
    STATUS: {
      LOADING: "Chargement",
      CONNECTED: "Connecté",
      ERROR: "Erreur",
    },
    ERROR: {
      DATE_NOT_ALLOWED:
        "Vous ne pouvez envoyer vos statistiques qu'entre le vendredi et le lundi midi.",
      FORM_GENERATION_VALIDATION_ERROR:
        "Veuillez completer les champs obligatoires et resoumettre",
      FORM_GENERATION_ERROR:
        "Une erreur s'est produite lors de la génération du document. Veuillez ré-essayer ou contacter le support si le problème persiste",
      FORM_SENDING_ERROR:
        "L'e-Form n'a pas pu être envoyée suite à un problème sur un serveur de healtConnect. Veuillez ré-essayer plus tard",
      MISSING_RECIPIENT: "Attention, la liste des destinataires est vide",
      FILE_LIMIT_WARNING:
        "Attention, un maximum de 10 fichiers joints ne depassant pas 9MB cumulés est autorisé",
      NO_MORE_FILE_ALLOWED:
        "Le maximum de 10 fichiers joints ou la limite de 9MB cumulés vous empèche d'ajouter de nouvelles annexes",
      GET_PATIENTS:
        "Erreur lors de l'obtention des patients. Veuillez réessayer.",
    },
    INPUT_STATISTICS: "Statistiques",
    STATS: {
      PROCESSING_PATIENTS:
        "calcul du groupe de patients {{chunk}} sur {{total}}",
      LOADING_PATIENTS: "chargement des patients ...",
      VACCINATION_BAROMETER: "Baromètre de vaccination",
      ACTIVE_PATIENTS: "Patients au cours des deux dernières années",
      ARI_PATIENTS: "N° patients ARI",
      ILI_PATIENTS: "N° patients ILI",
      COVID_SUSPECT_PATIENTS: "N° suspects COVID-19",
      A77_PATIENTS: "N° patients A77",
      COVID_PATIENTS: "N° patients COVID-19",
      RATIO_CODED_DIAGNOSES: "Pourcentage diagnostics codé",
      PATIENTS_ABOVE_65: "Patients de plus de 65 ans",
      PATIENTS_45_TO_65_WITH_RISK: "Patients à risque de 45 à 64 ans",
      PATIENTS_18_TO_44_WITH_RISK: "Patients à risque de 18 à 44 ans",
      PATIENTS_45_TO_64_NO_RISK: "Patients sans risque de 45 à 64 ans",
      PATIENTS_18_TO_44_NO_RISK: "patients sans risque de 18 à 44 ans",
      PATIENTS_INCREASED_COMPENSATION: "Patients BIM",
      PATIENTS_DIABETES: "Patients diabétiques",
      PATIENTS_KIDNEY_CONDITION: "Patients malades des reins",
      PATIENTS_LUNG_CONDITION: "Patients malades des poumons",
      PATIENTS_LIVER_CONDITION: "Patients malades du foie",
      PATIENTS_CARDIOVASCULAR_CONDITION:
        "Patients avec maladie cardiovasculaire",
      PATIENTS_OBESITY: "Patients obèses",
      PATIENTS_NEUROLOGICAL_CONDITION: "Patients avec maladie neurologique",
      PATIENTS_CANCER: "Patients malades du cancer",
    },
    SELECTION: {
      TITLE: "Modifier les statistiques quotidiennes",
      TODAY: "Aujourd'hui",
      YESTERDAY: "Hier",
      TOOLTIP:
        "Le e-form d'hier ne peut toujours être soumis qu'avant 10 heures du matin.",
    },
    MESSAGE: {
      BUILDING_STATISTICS: "Calcul des statistiques...",
      ACTIVE_PATIENTS: "Aller chercher les patients actifs ...",
      ERROR_ACTIVE_PATIENTS:
        "Erreur : Impossible d'obtenir des contacts au cours des deux dernières années.",
      MODIFYING_USER_PROPERTIES:
        "Modifier les propriétés de l'utilisateur ....",
      UNABLE_TO_MODIFY_PROPERTIES:
        "Erreur : Impossible de modifier les propriétés de l'utilisateur.",
      UNABLE_TO_REFRESH:
        "Erreur : Impossible de se rafraîchir : déconnectez-vous et reconnectez-vous pour que les changements prennent effet.",
      BUILDING_DAILY_STATISTICS: "Construire des statistiques quotidiennes ...",
      FETCHING_CONTACTS: "Aller chercher les contacts quotidiens ...",
      UNABLE_TO_FETCH_DAILY_CONTACTS:
        "Erreur : Impossible d'aller chercher les contacts quotidiens.",
      COUNTING_PATIENTS: "Compter les patients ...",
      DIAGNOSTIC_RATIO: "Calculer le ratio de diagnostic ...",
    },
  },
  "recipient-selector": {
    SEARCH_CREATE: "Ajouter un destinataire",
    RECIPIENT_LIST: "Liste des destinataires",
    DELETE_RECIPIENT: "Enlever le destinataire de la liste",
  },
  "eform-services": {
    LABEL: {
      GENERIC: "eForm",
      HANDICARE: "Handicare",
      COVID_LAB_REQUEST: "Covid-19: Demande d'analyse en laboratoire",
      COVID_FALSE_NEGATIVE_OVERRIDE:
        "Covid-19: Confirmation malgré faux-négatif",
      COVID_RAPID_TEST_RESULT: "Covid-19: Résultat du test rapide",
    },
  },
  prescriptions: {
    SAMV2: "SAMv2",
    NEW: "Nouveau",
    "close-confirmation": {
      TITLE: "Confirmation",
      WARNING:
        "Voulez-vous fermer cette fenêtre et annuler tous vos encodages?",
      YES: "Oui",
      NO: "Non",
    },
    tab: {
      MEDICATION: "Médications actives",
      TREATMENT_HISTORY: "Historique",
      CHAPTER_IV: "Chapitre IV",
    },
    SEARCH: {
      RESULTS: "Résultats",
      LABEL: "R/",
      ALTERNATIVES: {
        LABEL: "Alternatives",
        CHEAPEST: "Moins chères",
        ALL_PRICES: "Tout",
        ALL_PHARMACEUTICAL_FORMS: "Tout",
        VMP_GROUP_NAME: "Nom de groupe",
      },
      CHOOSE_MEDICAMENT: "Choisir un médicament",
      SEARCH_PLACEHOLDER: "Rechercher",
      PRICE_EVALUATION: {
        RECOMMENDED: "Bon marché",
        ALTERNATIVE: "Remboursable",
      },
      LISTING_HEADERS: {
        NAME: "Nom",
        STRENGTH: "Dosage",
        PACK_SIZE: "Taille emballage",
        PHARMACEUTICAL_FORM: "Forme pharmaceutique",
        PUBLIC_PRICE: "Prix",
        CO_PAYMENT: "TM",
        PUBLIC_PRICE_INDEX: "Index",
        PRICE_INDEX_SAMV2: "Indice",
        CO_PAYMENT_INDEX: "Index TM",
        PRESCRIPTION: "Prescription",
        REIMBURSEMENT: "Remboursement",
        COMMERCIALIZATION: "Commercialisation",
        DELIVERY_MODUS: "Conditions de délivrance",
      },
      ORDER_BY: {
        CHEAP: "Bon marché",
        PRICE_INDEX_SAMV2: "Indice",
        FAVORITES: "Favoris",
      },
      PRESCRIPTION_REQUIRED: "R",
      NOT_APPLICABLE: "n.a.",
      DELIVERY_CONDITIONS: "Conditions pour la délivrance",
      PRESCRIPTION_CONDITIONS: "Conditions pour la prescription",
      PRESCRIPTION_CONDITIONS_NOT_APPLICABLE: "Pas d’application",
      RECOMMANDATION_EXPLANATION: {
        END_COMMERCIALIZATION: "Prescriptible jusque {{date}}.",
        START_COMMERCIALIZATION: "Prescriptible à partir de {{date}}.",
        END_REIMBURSEMENT: "Remboursé jusque {{date}}.",
        REASON: "Motif:",
        IMPACT: "Impact:",
        ADDITIONAL_INFO: "Informations supplémentaires:",
        UNAVAILABLE: "Indisponible:",
        ANCIENT_NAMES: "Noms précédents:",
      },
      REIMBURSEMENT_CATEGORY_CODE_EXPLANATION: {
        A: "Spécialités d'importance vitale (remboursement en totalité)",
        B: "Spécialités pharmaceutiques importantes sur le plan thérapeutique (remboursement en grande partie)",
        C: "Médicaments destinés au traitement symptomatique (remboursement partiel seulement, de manière décroissante de C à Cs, jusque Cx)",
        Cs: "Médicaments destinés au traitement symptomatique (remboursement partiel seulement, de manière décroissante de C à Cs, jusque Cx)",
        Cx: "Médicaments destinés au traitement symptomatique (remboursement partiel seulement, de manière décroissante de C à Cs, jusque Cx)",
        Fa: "Utilisés qu’en milieu hospitalier (remboursement d’un montant forfaitaire)",
        Fb: "Utilisés qu’en milieu hospitalier (remboursement d’un montant forfaitaire)",
        J: "Pour les contraceptifs, la lettre J signifie qu'une intervention spéciale est prévue dans le prix pour les femmes de moins de 25 ans et, sans limite d'âge, pour les femmes bénéficiaires de “l'intervention majorée”. Lorsqu’il s’agit d’une “pilule du lendemain”, la lettre J signifie qu'il y a une intervention spéciale pour toutes les femmes, sans limite d'âge (Arrêté Royal 16-09-2013)",
      },
    },
    TYPES: {
      SPECIALTY: "Spec.",
      ATC: "ATC",
      MAP: "Mag.",
      DCI: "DCI",
      NON_MEDICINAL_PRODUCT: "NonMed.",
      PRIVATE: "Pers.",
      FREE_TEXT: "Free",
    },
    ATC: {
      LISTING_TITLE: "Classes ATC",
      VALIDATE: "Valider",
    },
    PHARMACEUTICAL_COMPOUNDING: {
      LISTING_HEADERS: {
        NAME: "Nom",
        FORMULA: "Formule",
        FORM: "Forme",
        QUANTITY: "Quantité",
        CLASS: "Classe",
      },
      NAME: "Nom",
      FORMULA: "R/",
      FORM: "Pf/",
      QUANTITY: "Dt/",
      UNIT: "Unité",
    },
    SAVE: "Enregistrer",
    CANCEL: "Effacer",
    PRESCRIBE: "Prescrire",
    PRINT: "Imprimer",
    EMAIL: "Email",
    PRINT_POSOLOGY: "Imprimer schéma posologique",
    SAVE_POSOLOGY: "Télécharger schéma posologique",
    SELECT: "Sélectionner",
    CLOSE_WARNING:
      "Voulez-vous fermer cette fenêtre et annuler tous vos encodages?",
    ADVERSE_EFFECTS_REPORTING:
      "Notifier des effets indésirables des médicaments",
    DCI_NON_PRESCRIBABLE: "La prescription DCI n'est pas recommandée. Raison: ",
    posology: {
      POSOLOGY: "S/",
      BT: "Bt",
      QUANTITY: "Dt/",
      PHARMACEUTICAL_FORM: "Forme",
      JEUN: "àJeun",
      MORNING: "Mat.",
      "10H": "10h",
      LUNCH: "Midi",
      "16H": "16h",
      EVENING: "Soir",
      NIGHT: "Couch.",
      REPAS: "Repas",
      BEFORE: "Av",
      DURING: "Pd",
      AFTER: "Ap",
      "TOUS LES": "Tous les",
      JOURS: "jours",
      CHRONIQUE: "Chronique",
      ONESHOT: "One-shot",
      FREEPOSOLOGY: "Posologie texte libre",
      TEMPLATE_NAME: "Posologie par défaut",
      SAVE_TEMPLATE: "Sauvegarder la posologie",
      TEMPLATE_DELETE_CONFIRMATION: "Confirmation de la suppression",
      TEMPLATE_DELETE_WARNING: "Voulez vous supprimer ce posologie?",
      CONFIRMATION_NO: "Non",
      CONFIRMATION_YES: "Oui",
      ROUTE_OF_ADMINISTRATION: "Voie d'administration",
      FOR: "pour",
      THIRD_PARTY_CHARGE: "TPA",
      periodType: {
        day: "Jours",
        week: "Semaines",
        month: "Mois",
        year: "Années",
      },
      UNITIES: "Unités",
    },
    remark: {
      notes: {
        DIABETES_CARE_PATH: "Trajet de soins diabète",
        RENAL_INSUFFICIANCE_CARE_PATH: "Trajet de soins insuffisance rénale",
        DIABETES_CONVENTION: "Convention diabète",
        THIRD_PARTY_CHARGE: "Tiers payant applicable",
        THIRD_PARTY_NO_CHARGE: "Tiers payant non applicable",
        ON_DEMAND: "À la demande",
        NO_IMPROVEMENTS_AFTER_DAYS: "Si pas d’amélioration après jours",
      },
      REMARK: "Remarques",
      NOTE: "Note",
    },
    PRESCRIPTION: "Prescription",
    ACUTE: "Traitement aigu",
    CHRONIC: "Traitement chronique",
    START_DATE: "Début",
    ACTIONS: {
      EDIT: "Editer",
      STOP: "Arrêter",
      HISTORY: "Historique",
      NOTICE: "Notice",
      RCP: "RCP",
      CBIP: "CBIP",
      INFO_HINT: "Informations",
      SUSP_HINT: "Suspendre un médicament",
      STOP_HINT: "Stopper un médicament {{date}} {{reason}}",
      EDIT_HINT: "Modifier un médicament",
      WADA_INFO: "Info WADA",
    },
    POSOLOGY_LISTING: {
      JEUN: "àj",
      MORNING: "8",
      "10H": "10",
      LUNCH: "12",
      "16H": "16",
      EVENING: "20",
      NIGHT: "22",
      PERIOD_TYPE: {
        DAY: "J",
        WEEK: "S",
        MONTH: "M",
        YEAR: "A",
      },
    },
    ADDITIONAL_INFO: {
      CATEGORY: "Catégorie",
      WADA: "WADA (World Anti-Doping Agency)",
      ADDITIONAL_MONITORING: "Surveillance Supplémentaire",
      RMA: "RMA (Risk Minimisation Activites)",
      DHPC: "DHPC (Dear Healthcare Professional Communication)",
      CBIP: "CBIP",
      NOTICE: "Notice",
      RCP: "RCP",
      UNAVAILABLE: "Indisponible",
      MOTIVE: "Motif",
      IMPACT: "Impact",
      EXPIRED: "Expiré",
    },
    PRESCRIPTIONS_LISTING: {
      TITLE: "Ordonnances en cours de rédaction",
      TITLE_EXTRA_INFO:
        "Depuis la dématérialisation chaque médicament est envoyé à eRecipe sur une ordonnance électronique distincte.",
      LABEL: "Ordonnances",
      PRACTITIONER: "Prescripteur",
      DESCRIPTION: "R/",
      POSOLOGY: "S/",
      QUANTITY: "Dt/",
      UNITIES: "Unités",
      FORM: "Pf/",
      END_VALIDITY: "Date de fin d'exécution",
      PRINT_WARNING:
        "Attention : l'ordonnance n°{{number}} est longue et risque d'être mal imprimée.",
      POSOLOGY_DESCRIPTION: {
        REGIMEN: {
          A_JEUN: "à jeun",
          A_MORNING: "le matin",
          A_10H: "à 10h",
          A_LUNCH: "à midi",
          A_16H: "à 16h",
          A_EVENING: "le soir",
          A_NIGHT: "au coucher",
        },
        MEAL: {
          BEFORE: "avant les repas",
          DURING: "pendant les repas",
          AFTER: "après les repas",
        },
        FREQUENCY: {
          EVERY_ONE: {
            DAY: "tous les jours",
            WEEK: "toutes les semaines",
            MONTH: "tous les mois",
            YEAR: "chaque année",
          },
          EVERY_N: {
            DAY: "tous les {{n}} jours",
            WEEK: "toutes les {{n}} semaines",
            MONTH: "tous les {{n}} mois",
            YEAR: "tous les {{n}} ans",
          },
        },
        ONESHOT: "(Prise unique)",
      },
      VISION_LOCKED_INFO:
        "Confidentiel: visible uniquement par la pharmacie où le patient se présente avec la prescription.",
      VISION_OPEN_INFO:
        "Non confidentiel: visible par n'importe quelle pharmacie où le patient s'identifie.",
      NAME_AND_POSOLOGY: "Nom et posologie",
      EDIT: "Editer",
      DECREASE_QUANTITY: "Diminuer de 1",
      REMOVE: "Supprimer",
    },
    ERROR_SAVE_CONTACT: "Une erreur est survenue en sauvegardant le contact.",
    PENDING_PRESCRIPTION: {
      SEARCH_CAREGIVER: "Rechercher un prestataire de soin",
      SAVED: "L'ordonnance a été sauvée et est en attente.",
      CHOOSE_PRESCRIBER: "Veuillez choisir un prescripteur",
    },
    DCI: {
      ALTERNATIVES: "Alternatives Spécialités",
    },
    STOP_MEDICATION_TREATMENT: {
      TITLE: "Arrêter un traitement",
      MOTIVE: "Motif",
      COMMENT: "Commentaire",
      OPTIONAL: "Optionnel",
      SAVE: "Enregistrer",
      CANCEL: "Annuler",
      UNDO: "Réactiver",
      REASONS: {
        ALLERGY: "Allergie",
        INTOLERANCE: "Intolérance",
        UNEXPECTED_SIDE_EFFECTS: "Effets indésirables",
        INEFFICIENT: "Inefficacité",
        REFUSAL_PATIENT: "Refus du patient",
        END_OF_TREATMENT: "Fin de traitement",
        PACKAGE_CHANGE: "Changement de conditionnement",
        DRUG_NOT_AVAILABLE: "Médicament non disponible",
        OTHER: "Autre",
      },
      SELECT_CLASS: "Etendre à la classe",
      ATC_CLASS: "Classe ATC",
      END_DATE: "Date de fin",
      LAST_MONTH_WARNING:
        "Ceci est le dernier mois où ce traitement est actif. Il sera arrêté le {{date}}.",
      LAST_MONTH_WARNING_FOR_ONE:
        "Ceci est le dernier mois où ce traitement est actif: ",
      LAST_MONTH_WARNING_FOR_MANY:
        "C'est le dernier mois que les traitements suivants sont actifs: ",
      LAST_DAY_WARNING_FOR_ONE:
        "C'est le dernier jour où le traitement suivant est actif. Il ne peut plus être prolongé.",
      LAST_DAY_WARNING_FOR_MANY:
        "C'est le dernier jour où les traitements suivants sont actifs. Ils ne peuvent plus être prolongés",
    },
    SUSPEND_MEDICATION_TREATMENT: {
      TITLE: "Suspendre un traitement",
      MOTIVE: "Motif",
      COMMENT: "Commentaire",
      OPTIONAL: "Optionnel",
      SAVE: "Enregistrer",
      CANCEL: "Annuler",
      START_DATE: "Date de début",
      END_DATE: "Date de fin",
      ADD: "Ajouter suspension",
      REMOVE: "Supprimer",
      SUSPENSIONS: "Suspensions",
    },
    UNAVAILABLE_MEDICATION: {
      TITLE: "{{medicationName}} n'est plus pas valable / disponible",
      ALTERNATIVES: "ALTERNATIVES",
      CANCEL: "Effacer",
      SELECT: "Sélectionner",
    },
    history: {
      types: {
        PRESCRIPTION: "Ordonnances",
        TREATMENT: "Médications",
      },
    },
    treatmentHistory: {
      groups: {
        HISTORY: "Historique",
        UNSPECIFIED: "Not spécifié",
      },
      DATE: "Date",
      HEALTH_ELEMENT: "Élément de soin",
      BEGIN: "Début",
      END: "Fin",
      TREATMENT: "Traitement",
      POSOLOGY: "Posologie",
      MOTIF_STOP: "Motif d'arrêt",
      PRESCRIPTOR: "Prescripteur",
      CNK: "CNK code",
      UNSPECIFIED: "Non spécifié",
      BOXES: "{{boxes}} boîtes",
      dateFilter: {
        PERIOD: "Période",
        LAST_MONTHS: "Les {{months}} derniers mois",
        INDEFINITE: "Pas de filtre",
        BEFORE_YEAR: "Avant {{year}}",
      },
      atc_filter: {
        ATC_CLASS: "Classe ATC",
        ALL_CLASSES: "Toutes les classes",
      },
      "text-filter": {
        SEARCH: "Rechercher",
      },
      MEDICATION_LINES: "Lignes de médications",
      OUTDATED: "Historique",
      NOTE: "Remarque",
      CHRONICAL: "Médicament chronique",
      MODIFIED: "Changé",
      PROLONGATED: "Prolongé",
      STOPPED: "Stoppé",
    },
    RECIPE: {
      NOT_APPLICABLE: "pas d'application",
      NO_PHONE_WARNING:
        "Attention, la precription ne sera pas éléctronique! Pour envoyer une ePrescription, vous devez obligatoirement encoder un N° de téléphone et un type de téléphone dans votre fiche. Modifiez votre fiche en cliquant sur vos initiales en bas à gauche de l'écran",
      NO_UNIQUE_CODE:
        "Attention, le médicament ne contient pas d'identifiant unique",
      ERROR_UPLOAD: "La prescription n'a pas pu être envoyée à Recip-e",
      SUCCESSFUL_UPLOAD: "Prescription envoyée à Recip-e.",
      INVALID_TREATMENTS:
        "La date de début des traitements ({{treatments}}) est postérieure à la date d'expiration de la prescription",
      FAILED_TO_SEND_ALL:
        "Certains médicaments n’ont pas pu être envoyés à eRecipe (problème internet ou eHealth), ils ont donc été notifiés imprimés sur une ordonnance non électronique",
    },
    EMAIL_RECIPE: {
      SUBJECT: "Votre prescription",
      BODY: "Bonjour,\nLa prescription que vous avez demandée à bien été générée.\nUne copie de la preuve de prescription est attachée à ce mail. Vous pouvez vous présenter dans la pharmacie de votre choix muni de votre carte d'identité ou du code-barre figurant sur la preuve de prescription.\nIl n'est pas nécessaire d'imprimer ce document.\nBien à vous,",
    },
    PDF_PRESCRIPTION: {
      EXPIRATION_DATE: "Date de fin pour l'exécution",
      SSIN: "NISS",
      NIHII: "N° INAMI",
      BOXES: "Boite(s)",
      UNITIES: "Unités",
      QUANTITY: "Dt/",
      SHAPE: "Pf/",
      FORMULA: "R/",
      POSOLOGY: "S/",
      TREATMENT_START_DATE: "Date de début de traitement",
      PRINT_ERROR: "Erreur lors de l'impression des documents",
      ONLINE: {
        PROOF: "PREUVE DE PRESCRIPTION ELECTRONIQUE",
        INFO_TOP:
          "Veuillez présenter ce document à votre pharmacien pour scanner le code-barres et vous délivrer les médicaments prescrits",
        PRACTITIONER: "Prescripteur",
        PATIENT: "Bénéficiaire",
        CONTENT: "Contenu de la prescription électronique",
        INFO_BOTTOM:
          "Attention : Aucun ajout manuscrit à ce document ne sera pris en compte",
        DATE: "Date",
      },
      OFFLINE: {
        PRACTITIONER: "Nom et prénom du prescripteur",
        BIRTH_DATE: "Date de naissance",
        PRACTITIONER_FILL: "A REMPLIR PAR LE PRESCRIPTEUR:",
        PATIENT: "Nom et prénom\n du bénéficiaire",
        VIGNETTE: "Réservé à la vignette\ndu conditionnement",
        STAMP: "Cachet du prescripteur",
        DATE_SIGNATURE: "Date et signature du\n prescripteur",
        MEDICATION:
          "PRESCRIPTION DE MEDICAMENTS D'APPLICATION A PARTIR DU 1er Novembre 2019",
      },
    },
    NON_MEDICINAL_PRODUCTS: {
      LISTING_HEADERS: {
        NAME: "Nom",
        PRODUCER: "Producteur",
      },
    },
    prescriptionHistory: {
      TITLE: "Ordonnances",
      headers: {
        START: "Prescrit le: ",
        END: "Date de fin: ",
        PRESCRIBER: "Prescripteur: ",
        ERECIPE: "eRecipe: ",
      },
      QUANTITY: "Dt/",
      CNK: "CNK: ",
      REPRESCRIBE: "Represcrire",
      SPECIALITY: "Spécialité",
      SPECIALITY_FILTER: "Filtrer sur spécialité",
      NOT_SENT: "Non envoyé",
      MEDICATION_UNAVAILABLE:
        "Ce médicament n’est plus disponible, modifiez le depuis la liste des médicaments",
      CHECK_AVAILABILITY: "Dispo",
      CONFIRM: "Confirmer",
      CANCEL: "Annuler",
      status: {
        NOT_DELIVERED: "Envoyée",
        DELIVERED: "Délivrée",
        IN_PROCESS: "Actif",
        REVOKED: "Annulé",
        EXPIRED: "Expiré",
        ARCHIVED: "Archivé",
      },
      CANCEL_SUCCESS: "Annulation envoyée à Recip-e",
      ERROR_CANCEL_SAVE: "Une erreur est survenue en enregistrant l'annulation",
      feedback: {
        TITLE: "Feedback",
        DATE: "Date: ",
        CONTENT: "Contenu: ",
        RETRIEVE_ERROR: "TODO: wait translation --- for all above",
        SEND_ERROR: "TODO: wait translation --- for all above",
        FEEDBACK_RESPONSE: "Response:",
        RESPONSE_TITLE: "Response",
        SEND: "Envoyer",
        CANCEL: "Annuler",
        SENT_BY_HCP: "Sent as response ",
      },
      VISION_UPDATE_SUCCESS:
        "Visibilité pour les pharmacies (“Visi Flag”) modifiée.",
      VISION_UPDATE_ERROR:
        "Erreur lors de la modification de la visibilité par les pharmaciens (“Visi flag”).",
      VISION_LOCKED: "Confidentiel",
      VISION_OPEN: "Non confidentiel",
    },
    filters: {
      START_DATE: "De Date",
      END_DATE: "À Date",
    },
    FREE_TEXT_MEDICATION: {
      NAME: "Nom",
    },
    PRIVATE_MEDICATION: {
      LISTING_HEADERS: {
        NAME: "Nom",
        DOSAGE: "Dosage",
        PHARMACEUTICAL_FORM: "Forme",
        PACK_SIZE: "Emballage",
      },
    },
    INDICATIONS: {
      LINK_INDICATION: "Lier le sous-contact à un élément de soin",
      SEARCH: "Rechercher",
    },
    POSOLOGY_SCHEME_PRINT: {
      POSOLOGY: "Posologie",
      TREATMENTS_OF: "Traitements de",
      PAGE: "Page",
      NAME: "Nom",
      REMARK: "Remarque",
      END_DATE: "Date de fin",
      CHRONIC_TREATMENTS_DAILY: "Médicament chroniques journalier",
      ACUTE_TREATMENTS_DAILY: "Médicament temporaires journalier",
      OTHER_FREQUENCY_TREATMENTS: "Médicament avec une autre fréquence",
      REGIMENS: {
        JEUN: "A jeun",
        MORNING: "Mat.",
        "10H": "10h",
        LUNCH: "Midi",
        "16H": "16h",
        EVENING: "Soir",
        NIGHT: "Couch.",
      },
    },
    ALLERGY_INTOLERANCE_ALERT: {
      CHECK_REASON:
        "Votre patient est allergique (ou intolérant) à cette classe de médicament pour la raison suivante:",
      PRESCRIBE_ANYWAY: "Prescrire quand même",
      NOT_PRESCRIBE: "Ne pas prescrire",
      ADD: "Ajouter",
      NOT_ADD: "Ne pas ajouter",
    },
    MEDICATION_NOT_PRESCRIBABLE: {
      OK: "Ok",
      SINGLE_EXTEND:
        "Ce médicament n'est pas encodé correctement ou n'existe plus et il est impossible de le prescrire. Stoppez le et recherchez en un équivalent.",
      MULTIPLE_EXTEND:
        "Les médicaments suivants n'existent plus et il n'est pas possible de les prescrire. Stoppez les et recherchez un équivalent.",
    },
  },
  "admin-tools": {
    ADMIN_TOOLS: "MSAdmin",
    DB_INDEXATION_CHECKING: "Analyse de l'indexation",
    USER_HCP_CHECKING: "Analyse User/Hcp",
    HCP_GENERATOR: "Générateur d'Hcp",
    DB_SHARING: "Outil de partage",
    DB_PARENTING: "Outil de parentage",
    PLEASE_LOG_IN:
      "Entrez et validez le mot de passe admin pour accéder à plus d'options.",
    PASSWORD: "Mot de passe",
    SUBMIT_PASSWORD: "Valider le mot de passe",
    INVALID_PASSWORD: "Mot de passe invalide",
    WELCOME_TITLE: "Bienvenue dans l'outil d'administration de Medispring!",
    WELCOME_CONTENT:
      "Ceci est l'outil d'administration de Medispring. Dans cette partie du logiciel vous avez accès à plusieurs outils de monitoring, de modification et de maintenance. Attention, certains de ces outils sont capables d'effectuer des modifications irréversibles. L'utilisatiotn de ces outils est donc exclusivement destinée aux membres de l'équipe de support de Medispring.",
    WARNING: "Attention",
    INDEXATION: {
      DESCRIPTION: {
        TITLE: "Instructions",
        BODY: "Cet outil vous permet de controller en détail les indexations courantes de la base de donnée. Les boutons 'Synchoniser' vous permettront de mettre les vues à jour manuellement et de démarrer la mise à jour des index correspondants",
        WARNING_BODY:
          "En fonction de la taille de la base de donnée, une mise à jour de vue peut engendrer de longues indexations. Prévoyez donc ces mises à jour en fin de journée/début de soirée lorsque vous ne travailler pas.",
      },
      SYNCHRONIZE: "Synchroniser",
      SYNCHRONIZE_ALL: "Tout synchroniser",
      ENTITY: "Entité",
      STATUS: "Statut",
      PROGRESS: "Avancement",
      ONGOING: "En cours",
      GLOBAL: "Indexation global:",
    },
    USER_HCP: {
      DESCRIPTION: {
        TITLE: "Instructions",
        BODY: "Cet écran vous permet de controller les utilisateurs (avec leur autoDelegation), leur HCP et leur HCP parent. Pour chaque utilisateur, vous trouverez également combien de patient lui sont délégués (partagés) via son HCP et/ou sont HCP parent. NB: Une ligne verte signifie que l'ensemble des patients partagés avec le HCP parent contient (au moins) l'ensemble des patients délégués (partagés) avec le HCP de l'utlisateur correspondant.",
        WARNING_BODY: "",
      },
      ANALYSIS: "Analyser",
      CURRENT_USER: "Id de l'utilisateur actuel",
      HCP_USER_COUNT: "Nombre d'utilisateur avec HCP",
      BEST_PARENT_HCP: "Id du HCP parent principal",
      UNKNOWN: "Inconnu",
      STATUS: {
        SINGLE_PARENT:
          "Un seul HCP parent détecté commun à tous les utilisateurs",
        MULTIPLE_PARENTS: "Plusieurs HCP parent détectés",
        PARTIAL_SINGLE_PARENT:
          "Un seul HCP parent détecté et des utilisateurs n'en n'ont pas",
        PARTIAL_MULTIPLE_PARENTS:
          "Plusieurs HCP parent détectés et des utilisateurs n'en n'ont pas",
        NO_PARENT: "Aucun HCP parent détecté",
        UNKNOWN: "Analyse en cours...",
      },
      USER_ID_DELEGATION: "Id de l'utilisateur, statut et autoDelegation",
      USER_HCP_ID: "Id du HCP",
      USER_PARENT_HCP_ID: "Id du HCP parent",
      DELEGATION_STATUS: "Etat des partages patient",
      HCP_PATIENT_COUNT: "# patient partagés avec le HCP",
      PARENT_HCP_PATIENT_COUNT: "# patient partagés avec le parent HCP",
      COMPLETE_PARENT_DELEGATIONS:
        "Tous les patients sont partagés avec le parent",
    },
    HCP_TOOL: {
      DESCRIPTION: {
        TITLE: "Instructions",
        BODY: "Cet écran vous permet de générer un Hcp automatiquement ou manuellement.  Une fois le formulaire rempli vous pouvez vérifier que l'id généré ou choisit n'est pas déjà utilisé. De plus, il vous est permis de downloader le Hcp en format Json ou l'enregistrer directement dans la base de donnée.",
      },
      FIRST_NAME: "Prénom",
      LAST_NAME: "Nom de famille",
      SSIN: "Numéro national",
      NIHII: "Numéro INAMI",
      HCP_ID: "ID",
      CRYPTED_AES: "Clé AES encrypté",
      PUBLIC_RSA: "Clé RSA publique",
      CHECK_IF_ID_EXISTS: "Valider l'ID",
      DUMP_HCP: "Afficher",
      AUTOFILL: "Remplissage automatique",
      SAVE: "Enregistrer",
    },
    HCP_PARENTING: {
      ALERT: {
        TITLE: "Attention",
        BODY: "Lorsque vous changez le lien de parenté d'un Hcp, l'Id de l'ancien parent n'est pas conservé. Si besoin, veillez a,à prendre note de celui-ci",
      },
      DESCRIPTION: {
        TITLE: "Instructions",
        BODY: "Cet écran vous permet de changer le lien de parenté d'un Hcp lié à un utilisateur. Les autoDelegation de l'utilisateur seront adaptées en conséquence.",
      },
      USER_ID: "Id de l'utilisateur",
      PARENT_HCP_ID: "Id du Hcp parent",
      LINK_PARENT: "Créer le lien",
      SUCCESS: "Lien créé avec succès",
    },
    HCP_SHARING: {
      DESCRIPTION: {
        TITLE: "Instructions",
        BODY: "Cet écran vous permet de partager les patients (et documents relatifs) accessibles par l'utilisateur courant. Ce partage peut se faire via le Hcp de l'utilisateur ou à partir de son parent (si il existe). Cette action n'est pas facilement reversible mais peut s'effectuer plusieurs fois d'affilé.",
      },
      OWNER: "Id du Hcp propriétaire",
      DELEGATE_ID: "Id du Hcp délégué",
      PRIMARY_BTN: "Partager",
      ONGOING: "En cours...",
      ONGOING_SHARING: "Partage en cours",
      WARNING: "Attention",
      NO_HCP: "L'utilisateur courant n'est pas lié à un Hcp",
    },
  },
  licencing: {
    TITLE: "Licence",
    ENTER_LICENCE_NUMBER: "Entrez votre numéro de licence",
    IN_ORDER: "Licence {{type}} en ordre",
    PROVISIONALLY_ACTIVE: "Licence {{type}} active provisoirement",
    EXPIRED: "Licence {{type}} expirée",
    NOT_VALID: "Licence {{type}} invalide",
    LICENCE: {
      OF: {
        GROUP: "de groupe",
        USER: "de l'utilisateur",
      },
      ENUM: {
        GROUP: "Licence de groupe",
        USER: "Licence de l'utilisateur",
        DEMO: "Licence d'essai",
      },
      YOUR: {
        GROUP: "Votre licence de groupe",
        USER: "Votre licence personnelle",
      },
    },
    INVALID_LICENCE_DATES: "Dates de licence invalides.",
    ERROR_VALIDATING: "Erreur lors de la validation de la licence",
    ERROR_UPDATING_USER_LICENCE:
      "Erreur lors de la mise à jour de la licence de l'utilisateur",
    END_DATE: "Date de fin",
    VALIDATE: "Valider et mettre à jour",
    UPDATE_USER_LICENCE_SUCCESS:
      "Licence de l'utilisateur mise à jour avec succès",
    GROUP_LICENCE: "Licence de groupe",
    USER_LICENCE: "Licence d'utilisateur",
    STATUS: {
      STATUS: "Statut",
      TYPE: "Type",
      ACTIVE: "Actif",
      EXPIRED: "Expiré",
      NOT_VALID: "Invalide",
      PROVISIONALLY_ACTIVE: "Actif provisoirement",
    },
    NIHII: "INAMI",
    EXPIRATION_SOON: "Expiration de la licence",
    EXPIRED_ALREADY:
      "Votre licence a expiré. Medispring n'est accessible qu'en lecture seule. Envoyez un mail à licence@medispring.be afin d'activer votre licence.",
    EXPIRE_IN_DAYS_PROVISIONAL:
      "Votre licence a expiré depuis le {{expirationDate}}. Dans <b>{{daysLeft}}</b> jours, Medispring ne sera accessible qu'en lecture seule. Envoyez un mail à licence@medispring.be afin d'activer votre licence.",
    CONFIRM: "Confirmer",
    LICENCE_HAS_EXPIRED:
      "Votre licence a expiré, veuillez renouveler votre licence pour utiliser cette fonction",
  },
  "private-medication-management": {
    TITLE: "Ajouter un médicament privé",
    NAME: "Nom",
    DOSAGE: "Dosage",
    PHARMACEUTICALFORM: "Forme",
    PACKSIZE: "Emballage",
    NOTE: "Note",
    RESET: "Annuler",
    SAVE: "Enregistrer",
    EDIT: "Éditer",
    EDIT_NOT_MINE: "Ce médicament privé a été créé par un autre utilisateur.",
    ERROR_SAVE: "Impossible d’enregistrer vos médicaments privés",
    SAVE_SUCCESS: "Médicament privé créé avec succès.",
    EDIT_SUCCESS: "Médicament privé mis à jour.",
    ERROR_GET: "Impossible de récupérer vos médicaments privés.",
    DELETE: "Supprimer",
    CONFIRM_DELETE: "Voulez vous supprimer ce médicament privé?",
    YES: "Oui",
    NO: "Non",
    DELETE_SUCCESS: "Suppression du médicament privé effectuée.",
    ERROR_DELETE:
      "Une erreur est survenue lors de la suppression du médicament privé.",
  },
  DATE_PERIOD: {
    DAY: "Jour",
    WEEK: "Semaine",
    MONTH: "Mois",
    QUARTER: "Trimestre",
    YEAR: "Année",
    CUSTOM: "Personnalisé",
  },
  "patient-hub-ms": {
    NO: "Non",
    YES: "Oui",
    legend: "Légende",
    "hub-chron-med": "Médication chronique",
    "hub-sus-med": "Médication suspendue",
    "hub-med-mag": "Prescription magistrale",
    "hub-med-dci": "Prescription DCI",
    "hub-one-shot": "Médication one shot",
    comment: "Commentaires",
    "endDate-short": "Fin",
    "start-date-short": "Début",
    mom_after_short: "Ap",
    mom_during_short: "Pdt",
    mom_before_short: "Av",
    freq: "Fréquence",
    medic: "Médicament",
    dmi: "Dmi",
    hub: "Hub",
    morning: "Repas du matin",
    midday: "Repas du midi",
    evening: "Repas du soir",
    version: "Version",
    accessDate: "Date d'accès",
    patientAccess: "Accès patient",
    lastUpdate: "Dernière mise à jour",
    profil: "Profil",
    author: "Auteur",
    weekDay_1: "Lundi",
    weekDay_2: "Mardi",
    weekDay_3: "Mercredi",
    weekDay_4: "Jeudi",
    weekDay_5: "Vendredi",
    weekDay_6: "Samedi",
    weekDay_7: "Dimanche",
    weeknr: "Semaine #",
    day_0: "Dimanche",
    day_1: "Lundi",
    day_2: "Mardi",
    day_3: "Mercredi",
    day_4: "Jeudi",
    day_5: "Vendredi",
    day_6: "Samedi",
    day_7: "Dimanche",
    daynr: "Jour #",
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche",
    ms_afterbreakfast: "Après le repas du matin",
    ms_afterdinner: "Après le repas du soir",
    ms_afterlunch: "Après le repas de midi",
    ms_beforebreakfast: "Avant le repas du matin",
    ms_beforedinner: "Avant le repas du soir",
    ms_beforelunch: "Avant le repas de midi",
    ms_duringbreakfast: "Pendant le repas du matin",
    ms_duringdinner: "Pendant le repas du soir",
    ms_duringlunch: "Pendant le repas de midi",
    ms_betweenbreakfastandlunch: "Entre le déjeuner et le diner",
    ms_betweendinnerandsleep: "Entre le souper et le coucher",
    ms_betweenlunchanddinner: "Entre le diner et le souper",
    ms_betweenmeals: "Entre les repas",
    ms_evening: "Soir",
    ms_morning: "Matin",
    ms_night: "Nuit",
    ms_thehourofsleep: "Avant le coucher",
    ms_afterwakingup: "Au lever",
    ms_at: "à",
    "hub-upl-fil": "Envoyer le fichier sur les hubs",
    "hub-down-fil-pat": "Importer le fichier dans le dossier du patient",
    "hub-doc-title": "Titre du document",
    "hub-doc-type": "Type de document",
    "hub-medic-susp-res": "Raison de la suspension",
    "hub-med-trt-date": "Date de traitement",
    "hub-med-sus-date": "Date de suspension",
    "hub-med-use": "Utilisation",
    des: "Description",
    pos: "Posologie",
    suspension: "Suspension",
    ms_day: "Jour",
    "ms-export-failure": "Erreur lors de la synchronisation de votre schéma",
    "ms-export-success": "Votre schéma a bien été synchronisé",
    "ms-known-posology": "Posologie connue",
    "ms-local": "local",
    "ms-vault": "coffre fort",
    "ms-winner": "Gagnant",
    "error-loading":
      "Un problème est survenu lors du chargement du schéma de médication, veuillez réessayer.",
    retry: "Réessayer",
    "modif-date": "Modifié le",
    drugroute: "Voie d'administration",
    "administration-unit": "Forme",
    "instructions-for-patient": "Instructions pour le patient",
    compound: "Prescription magistrale",
    "medication-use": "Utilisation",
    "day-number": "# jour",
    "up-new": "Nouvelle local",
    "up-update": "MAJ local",
    "down-new": "Nouvelle vault",
    "down-update": "MAJ vault",
    deleted: "Supprimé",
    stopped: "Stoppé",
    equal: "Identique",
    errors: "Erreurs dans la ligne de médication",
    invalid: "Valeur incorrecte",
    "error-uploading":
      "Une erreur s'est produite lors de l'envoi du schéma de médications.",
    close: "Fermer",
    "treatment-suspension": "Suspension de traitement",
    begincondition: "Condition de début",
    endcondition: "Raison de fin",
    "unit-route": "Unité/Admin.",
    "begin-end": "Début/Fin",
    action: "Action",
    select: "Sélectionner",
    include: "Ajouter",
    exclude: "Enlever",
    status: "Statut",
    "new-line": "Nouvelle ligne de médication",
    "updated-line": "MAJ de la ligne de médication",
    "to-vault": "Envoyer vers coffre-fort",
    "to-local": "Créer en local",
    identical: "La ligne de médication est identique local = coffre-fort",
    "deleted-vault": "La ligne de médication est effacée du coffre-fort",
    "stopped-line": "La ligne de médication est arrêtée",
    "error-title": "Erreurs dans schéma-'coffre-fort'",
    "value-error": "Il y des erreurs dans quelques lignes de médication",
    "remove-vault-scheme": "Effacer schéma de médication du coffre-fort",
    "error-list": "Aperçu des erreurs trouvées",
    "correct-list": "Aperçu des lignes correctes dans ce schéma",
    "acknowledge-errors":
      "Continuer avec seulement les lignes correctes dans ce schéma",
    "removed-from-vault": "Effacée du coffre-fort",
  },
  compatibility: {
    UNKNOWN_SERVICES: "Services inconnus",
  },
  import: {
    excel: {
      TITLE: "Importation Excel",
      IMPORT_YOUR_FILE: "Importez votre fichier Excel :",
      CHOOSE_FILE: "Choisir un fichier",
      ERROR: "Une erreur est survenue durant l'import.",
      ERROR_INVALID: "Le contenu ou le fichier Excel est incompatible.",
      FINISH: "Clôturer",
    },
  },
  "e-labo": {
    TABS: {
      GENERAL: "Informations générales",
    },
    FILE_NAMES: "patientinfo.xml & patient.txt",
    OUTPUT_FOLDER: "Dossier",
    APP_PATH: "Application labo",
    APP_FILE_TYPE: "Application",
    CHOOSE: "Choisir",
    EXPORT: "Créer fichier patient et ouvrir application labo",
    EXPORT_ERRORS: {
      CONTENT_GENERATION: "Erreur à la génération des fichiers eLabo.",
      NO_DESTINATION_FOLDER:
        "Dossier eLabo non configuré dans les détails de l’utilisateur.",
      NON_EXISTENT_DESTINATION_FOLDER:
        "Dossier pour fichiers eLabo n’existe pas.",
      NO_WRITE_PERMISSIONS:
        "Pas de droit d'écriture sur le dossier pour les fichiers eLabo.",
      WRITE_FAILED: "Erreur imprévue à la génération des fichiers eLabo.",
    },
    EXPORT_SUCCESS: "Fichiers eLabo créés.",
    LAUNCH_APP_ERRORS: {
      NON_EXISTENT_APP_PATH: "Application labo non présente.",
      OPEN_FAILED: "Erreur lors du lancement de l’application labo.",
    },
  },
  bcpErrorMessages: {
    GENERAL_ERROR_MESSAGE: "Problème de connexion au service ehealth",
    TARIFICATION:
      "Sur base des informations connues actuellement dans votre logiciel et des informations fournies par votre patient le logiciel va calculer le tarif mais sans engagement de paiement ni engagement au niveau du tarif<br>",
    EATTEST:
      "Le système e-Attest est indisponible, veuillez utiliser une attestation papier<br>",
    EFACT:
      "veuillez  recommencer ultérieurement <br> Pour plus d'informations suivre le lien suivant: <a href='http://www.ejustice.just.fgov.be/cgi_loi/loi_a1.pl?language=fr&la=F&cn=2015091802&table_name=loi&&caller=list&F&fromtab=loi&tri=dd+AS+RANK&rech=1&numero=1&sql=(text+contains+(%2527%2527))#Art.5'>lien réglementaire</a><br>",
    MEMBERDATA: {
      "1": "Le logiciel essayera à nouveau de mettre à jour le dossier de votre patient.<br>",
      "2": "Sur base des informations connues actuellement dans votre logiciel et des informations fournies par votre patient il vous incombe de décider d’appliquer ou non le tiers-payant.<br> La mutualité acceptera l’application du tiers payant si les conditions réglementaires sont remplies.<br> Pour plus d'informations suivre le lien suivant: <a href='http://www.ejustice.just.fgov.be/cgi_loi/loi_a1.pl?language=fr&la=F&cn=2015091802&table_name=loi&&caller=list&F&fromtab=loi&tri=dd+AS+RANK&rech=1&numero=1&sql=(text+contains+(%2527%2527))#Art.9/2'> lien réglementaire </a> <br>",
    },
    DMG: {
      "1": "Le service étant indisponible, veuillez  recommencer ultérieurement.<br>",
      "2": "Le service étant indisponible, veuillez  recommencer ultérieurement endéans les 7 jours <br>",
    },
    CHAPTERIV: {
      "1": "Le service étant indisponible, veuillez-vous baser sur l’historique contenu dans votre logiciel ou sur les informations fournies par votre patient <br>",
      "2": "Le service étant indisponible, veuillez  recommencer ultérieurement   ou  veuillez  faire une demande d’accord papier.<br>",
    },
  },
  "flatrate-settings": {
    title: "Encodage de la tarification des forfaits de soins",
    warning: "Veuillez encoder vos tarifs pour les 24 derniers mois",
    deleteBtn: "Supprimer la tarification",
    addTarificationBtn: "Ajouter une tarification",
    TAB: {
      doctor: "Forfait soins médicaux",
      physio: "Forfait soins kiné",
      nurse: "Forfait soins infirmiers",
      ptd: "Forfait pré-trajet soins diabète",
    },
    COLUMN: {
      code: "Nmcl",
      type: "Description",
      startDate: "Date de début",
      price: "Tarification",
    },
    TYPE: {
      "109616": "Forfait soins médicaux dans les centres de santé",
      "409614": "Forfait soins infirmiers dans les centres de santé",
      "509611": "Forfait soins kiné dans les centres de santé",
      "109594": "Pré-trajet de soins diabète",
    },
    "TARIF-DIALOG": {
      add: "Ajouter",
      cancel: "Annuler",
      title: "Encodage d'une nouvelle tarification",
      startDate: "Date de début",
      amount: "Montant",
    },
  },
  pleaseWait: "Un instant svp",
  manuallyForced: "Forcé manuellement",
  FRI: {
    MENU: {
      title: "Facturation au forfait",
      mda: "Vérifications MDA",
      mhcOverview: "Aperçu contrat MM",
      insurabilityOverview: "Aperçu assurabilité",
      invoicesToCorrect: "Factures à corriger",
      invoicesToSend: "Factures à envoyer",
      lostInvoices: "Factures perdues",
      pastInvoices: "Factures passées",
      sentBatches: "Envois en cours",
      rejectedBatches: "Envois rejetés",
      acceptedBatches: "Envois acceptés",
      archivedBatches: "Envois archivés",
      importTestPatients: "Import test patients",
      getTestPatients: "Get test patients",
      deleteTestPatients: "Delete test patients",
      generateTestInvoicingData: "Generate test data",
      generatePastInvoicingData: "Generate past data",
      getInvoicingData: "Get invoicing data",
      sendTestPastBatches: "Send past batches (TEST)",
      sendPastBatches: "Send past batches (PROD)",
      deleteInvoicingData: "Delete invoicing data",
    },
    "PAGE-TITLE": {
      mda: "Vérification des données du membre (MDA)",
      "mhc-overview": "Aperçu des abonnements Maison Médicale",
      "insurability-overview": "Aperçu des contrats d'assurabilité",
      "invoices-to-correct": "Factures à corriger",
      "invoices-to-send": "Factures à envoyer",
      "lost-invoices": "Factures perdues",
      "generate-past-invoices":
        "Générer des factures dans le passé / récupération",
      "sent-batches": "Envois en cours",
      "rejected-batches": "Envois rejetés",
      "accepted-batches": "Envois acceptés",
      "archived-batches": "Envois archivés",
      "import-test-patients": "Importer des patients de TEST",
      "get-test-patients": "Récupérer les patients de TEST",
      "delete-test-patients": "Supprimer les patients de TEST",
      "generate-past-invoicing-data":
        "Générer des données de facturation passées",
      "get-invoicing-data": "Récupérer les données de facturation",
      "create-test-batches": "Créer des batches de TEST",
      "send-test-batches": "Envoyer des batches du passé (TEST)",
      "send-past-batches": "Envoyer des batches du passé (PROD)",
      "delete-invoicing-data": "Supprimer les données de facturation",
      "reopen-closed-mhcs-upon-transfer": "Reopen closed MHCs upon transfer",
      "rewrite-mhc-signaturetype-to-holderpaper-when-null":
        "Rewrite MHC signatureType to holderPaper when null",
      "update-mhcs-ptd-based-on-ptd-he": "Update MHC PTD based on PTD HE",
      "update-mhcs-ptd-based-on-patient-property":
        "Update MHC PTD based on patient's property",
    },
    LOADING: {
      gettingPatients: "Obtention de la patientelle",
      deletingPatients: "Suppression de la patientelle",
      importingPatients: "Import patient",
      gettingMh: "Obtention de la Maison Médicale",
      gettingEfactResponses: "Obtention des réponses Efact",
      confirmingEfactTacks: "Confirmation Tacks Efact",
      confirmingEfactMessages: "Confirmation Messages Efact",
      gettingRawInvoicingMessages: "Obtention de l'historique de facturation",
      deleteingInvoicingData: "Suppression des données de facturation",
      gettingMhValorisations: "Obtention des valorisation INAMI",
      gettingTarifications: "Obtention des tarifications",
      gettingMdaRequests: "Obtention des requêtes MDA",
      gettingMdaResponses: "Obtention des réponses MDA",
      gettingInsurances: "Obtention des assurances",
      gettingInsurancesOas: "Obtention des organismes assureurs",
      gettingPatientsInvoicingAttributes:
        "Obtention des attributs de facturation",
      creatingInvoices: "Création des factures",
      savingInvoicingData: "Enregistrement de la facturation",
      gettingInvoicingData: "Obtention des données de facturation",
      creatingTestBatch: "Création d'un batch de TEST",
      sendingTestBatch: "Simulation (TEST) d'envoi de batch",
      creatingBatches: "Création des envois",
      batchSentForOa: "Envoi effectué pour l'OA ",
      processingBatchAnswer: "Traitement des réponses de l'OA ",
      savingMdaRequests: "Enregistrement des requêtes MDA",
      savingMdaResponses: "Enregistrement des réponses MDA",
      sendingMdaRequests: "Envoi des requêtes MDA",
      analyzingMdaAnswers: "Analyse des réponses MDA",
      updatingPatsInformation: "Mise à jour des données du membre",
      finalizingProcess: "Finalisation du processus",
    },
    MISC: {
      run: "Run",
      xlsExport: "Export Excel",
    },
    "BATCH-DETAILS": {
      batchDetails: "Détails de l'envoi",
      batchType: "Type d'envoi",
      type: "Type",
      batch: "Envoi",
      context: "Contexte",
      charged: "Facturé",
      accepted: "Accepté",
      rejected: "Refusé",
      payment: "Paiement",
      rejectionReason: "Raison du rejet",
      noTechnicalDetailsFound: "Aucun détail technique n'a pu être trouvé",
      moreThan5pcErrors: "Plus de 5% d'erreurs",
      errorsInPreliminaryControl: "Erreurs lors du contrôle préliminaire",
    },
    GENERAL: {
      oa: "OA",
      ins: "Assurance",
      oaVerbose: "Organisme assureur",
      ref: "Référence",
      month: "Mois",
      invoicedMonth: "Mois facturé",
      sentOn: "Envoyé le",
      amount: "Montant",
      amounts: "Montants",
      chargedAmount: "Montant facturé",
      chargedOaAmount: "Montant OA",
      acceptedAmount: "Montant accepté",
      rejectedAmount: "Montant refusé",
      status: "Statut",
      actions: "Actions",
      getBatchesAnswers: "Récupérer les réponses",
      search: "Saisissez votre recherche",
      invoices: "Factures",
      reason: "Raison",
      bankAccount: "Compte bancaire",
      paymentReference: "Référence de paiement",
      flagAllInvoicesAsCorrected: "Tout marquer comme corrigé",
      flagAllInvoicesAsToBeCorrected: "Tout marquer comme étant à corriger",
      flagAllInvoicesAsLost: "Tout marquer comme perdu",
      ssin: "NISS",
      ssinVerbose: "Numéro de registre national",
      lastName: "Nom",
      firstName: "Prénom",
      invoiceNumber: "N° facture",
      patient: "Patient",
      patientSingularOrPlural: "Patient(s)",
      generateMyInvoices: "Générer mes factures",
      sendMyInvoices: "Envoyer mes factures",
      codeVerbose: "Code / nomenclature",
      flagAsAccepted: "Marquer comme accepté",
      flagAsPartiallyAccepted: "Marquer comme partiellement accepté",
      flagAsRejected: "Marquer comme refusé",
      flagAsArchived: "Archiver les données",
      flagAsUnarchived: "Désarchiver les données",
      flagAsCorrected: "Marquer comme corrigé",
      flagAsLost: "Marquer comme perdu",
      flagAsToBeCorrected: "Marquer comme étant à corriger",
      close: "Fermer",
      patientAmount: "Montant patient",
      additionalAmount: "Montant supplémentaire",
      total: "Total",
      batchsTotal: "Total de l'envoi",
      monthlyTotals: "Totaux mensuel",
      sendBatchAgain: "Renvoyer cet envoi",
      message: "Message",
      feedback: "Feedback",
      downloadTechnicalDetails: "Détails techniques",
      technicalDetails: "Détails techniques",
      nihiiVerbose: "Numéro INAMI",
      notVerified: "Non-vérifié",
      yes: "Oui",
      no: "Non",
      save: "Enregistrer",
      saveAndInvoice: "Enregistrer et facturer",
      date: "Date",
      delete: "Supprimer",
      deleteInvoice: "Supprimer la facture",
      deletionConfirmation: "Confirmation de suppression",
      cancel: "Annuler",
      deleteInvoiceWarning1:
        "Attention, vous êtes sur le point de supprimer une facture à envoyer.",
      deleteInvoiceWarning2: "Cette opération est irréversible.",
      patientCurrentlyActive: "Patient actuellement actif",
      patientCurrentlyInactive: "Patient actuellement inactif",
    },
    "INVOICES-SUMMARY": {
      noInvoicesToSend: "Aucune facture à envoyer",
      dialogTitle: "Récapitulatif avant envoi",
    },
    "MY-PROFILE": {
      flatrateTarifications: "Tarifications forfaitaires",
      "contactPerson-invoicingResponsible":
        "Personne de contact / responsable facturation",
      "contactPerson-invoicingResponsible-required":
        "Le responsable de facturation est requis",
    },
    ERRORS: {
      dialogTitle: "Informations manquantes",
      intro1:
        "Attention, les informations suivantes manquent dans votre fiche utilisateur.",
      intro2:
        "Veuillez s'il vous plait les renseigner avant d'utiliser votre module de facturation.",
      "hcp.nihii": "Numéro INAMI",
      "hcp.cbe": "Numéro d'immatriculation à la BCE",
      "hcp.bankAccount": "Compte bancaire (IBAN)",
      "hcp.bic": "Compte bancaire (BIC)",
      "hcp.contactPersonHcpId": "Responsable de la facturation",
      "hcpContactPerson.firstName": "Prénom du responsable de la facturation",
      "hcpContactPerson.lastName": "Nom du responsable de la facturation",
      "hcpContactPerson.ssin":
        "Numéro de registre national du responsable de la facturation",
      "reimbursement.gp": "Tarification des prestations de médecine générale",
      "reimbursement.kine": "Tarification des prestations de kinésithérapie",
      "reimbursement.nurse": "Tarification des prestations de soins infirmiers",
      "reimbursement.ptd":
        "Tarification des prestations du prétrajet de soin diabète",
      noPatientsFoundForMda: "Aucun patient n'a pu être trouvé",
      requestMessageNotCreated: "Erreur lors de la sauvegarde des requêtes MDA",
      mdaRequestNotSent: "Erreur lors de l'envoi des requêtes MDA",
      technicalErrorWithOa: "Erreur technique chez l'OA",
      ssinNotFound: "Numéro de registre national inconnu",
      dataCouldNotBeVerified: "Les données n’ont pas pu être vérifiées",
      unknownInsuranceRegistrationNumber:
        "Numéro de contrat d'assurance inconnu chez l'OA",
      callToBackendFailed: "Erreur technique chez l'OA (appel backend)",
      proceedWithMdaFirst:
        "Veuillez s'il vous plait finaliser la vérification des données du Membre (MDA) en amont de la facturation.",
      incorrectSsin: "Numéro de registre national incorrect",
    },
    MDA: {
      month_01: "Janvier",
      month_02: "Février",
      month_03: "Mars",
      month_04: "Avril",
      month_05: "Mai",
      month_06: "Juin",
      month_07: "Juillet",
      month_08: "Août",
      month_09: "Septembre",
      month_10: "Octobre",
      month_11: "Novembre",
      month_12: "Décembre",
      memberDataRequest: "Envoi de la demande",
      requestStatus: "Statut de la demande",
      requestResults: "Résultats de la demande",
      invoicesCreation: "Création des factures",
      verifyMemberData: "Vérifier les données du membre",
      verifyData: "Vérifier les données",
      membershipReference: "Numéro de contrat d'assurance",
      noKnownOa: "Pas d'organisme assureur actuellement recensé",
      errorInResponse: "Erreur dans la réponse",
      responseObtained: "Réponse obtenue",
      beingTreated: "En cours de traitement",
      mdaRequestSent: "Envoi des requêtes MDA effectué",
      pleaseVerifyMemberDataToBegin:
        "Veuillez s'il vous plait commencer par vérifier les données de vos membres.",
      bypassMdaResponses: "Outrepasser le délai d'attente",
      patientHasNoMhc:
        "Le patient ne dispose d'aucun contrat avec une Maison Médicale",
      patientIsNowInMhc: "Le patient est désormais dans la Maison Médicale",
      patientHasNoOnGoingMhc:
        "Le patient ne dispose d'aucun contrat en cours / valide avec votre Maison Médicale",
      patientHasNoValidIns:
        "Le patient ne dispose d'aucune assurance en cours de validité",
      patientHasNoIns: "Le patient n'est pas assuré",
      patientIsInOtherMhc: "Le patient a changé de maison médicale",
      invoicable: "Facturable",
      flagAsInvoicable: "Marquer comme facturable",
      flagAsNotInvoicable: "Marquer comme non-facturable",
      flagAllAsInvoicable: "Tout marquer comme facturable",
      flagAllAsNotInvoicable: "Tout marquer comme non-facturable",
      readonlyExplanation:
        "Suite à votre validation des changements en vue de la génération des factures, cette fonctionnalité n'est plus disponible avant le mois prochain.",
    },
    "PAST-INVOICES": {
      firstInsurability: "Première assurabilité connue",
      lastInsurability: "Dernière assurabilité connue",
      noValidInsurability: "Aucune / non-assuré",
      mhcShort: "Contrat MM",
      ongoing: "En cours",
      ongoingSince: "En cours depuis le",
      stoppedSince: "Clôturé depuis le",
      noContract: "Aucun contract",
      workWithThisPatient: "Travailler avec ce patient",
      gpOneLetterAbbreviation: "M",
      kineOneLetterAbbreviation: "K",
      nurseOneLetterAbbreviation: "I",
      backToList: "Retourner à la liste",
      listingNotaBene:
        "Seuls les patients disposant / ayant disposé d'un contract seront (peut-être) facturables / récupérables.",
      verify: "Vérifier",
      insured: "Assuré",
      gotInvoiced: "Fût facturé",
      manually: "manuellement",
      automatically: "automatiquement",
      alreadyInOrder: "D'ores et déjà en ordre",
      createInvoice: "Créer la facture",
      create: "Créer",
      notInOrder: "Pas en ordre",
      noModificationMade: "Aucune modification effectuée",
      invoiceCreated: "Facture créée",
      invoiceAlreadyCreated: "Facture déjà créée",
    },
  },
  "cert-management": {
    connectAsPmg: "Connexion en tant que poste de garde",
    TAB: {
      current: "Certificat actuel",
      outdated: "Ancien(s) certificat(s)",
    },
    GRID: {
      title: "Certificat(s) uploadé(s) dans l'application",
      uploadBtn: "Uploader un certificat",
      CELL: {
        name: "Nom du certificat",
        endOfValidity: "Fin de validité",
        active: "Actif",
        password: "Mdp",
        asPmg: "Connexion PMG",
      },
      BTN: {
        delete: "Supprimer",
        modify: "Modifier",
        active: "Activer",
      },
    },
    DIALOG: {
      MODIFICATION: {
        title: "Modification du mot de passe du certificat",
        password: "Mot de passe",
        BTN: {
          cancel: "Annuler",
          modify: "Modifier",
        },
      },
    },
    ERROR: {
      password: "Le mot de passe du certificat est manquant",
      encrypt: "Erreur lors de l'encryption du mot de passe",
      decrypt: "Erreur lors de la décryption du mot de passe",
    },
  },
  "MDA-SA": {
    before: "Avant",
    after: "Après",
    unauthorizedSector: "Fonctionnalité non autorisée",
    summaryBeforeInvoicing: "Récapitulatif avant facturation",
    verifiedMonth: "Mois vérifié",
    requestDate: "Introduction de la demande le",
    responseLastCheckDate: "Dernière relève des réponses le",
    totalRequestedPatients: "Patients à vérifier",
    totalAnsweredPatients: "Réponses obtenues",
    totalInvoiceablePatientsBasedOnMda:
      "Patients facturables (sur base du MDA)",
    totalNonInvoiceablePatientsBasedOnMda:
      "Patients non-facturables (sur base du MDA)",
    totalPatientsForcedAsInvoiceable: "Patients forcés comme facturables",
    totalPatientsNotToCharge: "Total de patients à ne pas facturer",
    totalPatientsToCharge: "Total de patients à facturer",
  },
  laboratories: {
    management: {
      list: {
        TITLE: "Configuration des laboratoires",
        NAME: "Labo",
        FAVORITE: "Ajouter aux favoris",
        CREDENTIALS: "Ajouter les informations d'identification",
      },
      "credentials-form": {
        TITLE: "Définir les informations d'identification - {{name}}",
        SAVE: "Enregister",
        CANCEL: "Annuler",
        USER: "Utilisateur",
        PASSWORD: "Mot de passe",
        CONFIRM_PASSWORD: "Confirmation du mot de passe",
      },
    },
    "patient-dialog": {
      TITLE: "Labo",
      LABORATORY: "Sélectionnez un laboratoire:",
      REQUEST: "Prescription",
      RESULTS: "Résultats d'analyse",
      NO_CREDENTIALS: "Aucun identifiant défini pour ce laboratoire.",
    },
  },
  PATIENT_INFORMATIONS: {
    postalBox: "bte",
  },
  "medispring-window": {
    TITLE: "{{windowTitle}} de {{fullName}} du {{date}}",
  },
  "events-covid-management": {
    TITLE: "Suivi des derniers évenements COVID",
    GET_LAST_EVENTS: "Obtenir les derniers évènements COVID",
    PATIENT: "Patient",
    PATIENTS: "Patients",
    AGE: "Age",
    TYPE: "Evènement",
    MEETING_DATE: "Date du prochain rendez-vous",
    DOSES: "Doses",
    NIHII: "Numéro INAMI",
    DATE: "à partir du",
    FINISH: "Récupération terminée",
    ERROR: {
      CHECK_EVENT: "Erreur lors de la réception des derniers évenements :",
      CREATION_VACCINE: "Erreur lors de la création des nouveaux vaccins :",
      MODIFY_PROP: "Erreur lors de la mise à jours de l'utilisateur :",
      MODIFY_PATIENT:
        "Erreur lors de la mise à jours des rendez-vous du patient :",
      GET_PATIENTS: "Erreur lors de l'obtention des patients :",
      PATIENT_NOT_FOUND: "Patient non trouvé",
      GET_CONTACTS: "Erreur lors de l'obtention des contacts du patient :",
    },
  },
  GUARD: {
    CANNOT_ACCES_PATIENT_DATA:
      "Vous ne pouvez pas accéder au dossier de ce patient.",
  },
};
