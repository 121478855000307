import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { createLogger } from "redux-logger";
import AuthSlice from "./features/login/AuthSlice";
import { healthDataApi } from "./api/healthdata";

import DisplaySlice from "./features/navigation/DisplaySlice";

// Connectors
import { medispringApi } from "./connectors/medispring/api/medispring";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loggerMiddleware = createLogger();

const rootReducer = combineReducers({
  display: DisplaySlice,
  auth: AuthSlice,
  [healthDataApi.reducerPath]: healthDataApi.reducer,
});

export const createStore = (initialState = {}) => {
  const middlewares = [
    // loggerMiddleware,
    medispringApi.middleware,
    healthDataApi.middleware,
  ];

  return configureStore({
    middleware: (getDefaultMiddleware) => {
      const defaultMiddleware = getDefaultMiddleware({
        serializableCheck: false,
      });

      return defaultMiddleware.concat(middlewares);
    },
    reducer: rootReducer,
    preloadedState: initialState,
  });
};

const store = createStore({});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
