import dayjs from "dayjs";
import { AddressModel } from "./AddressModel";
import { EzoResource } from "./EzoResource";
import { HCPModel } from "./HCPModel";
import { PatientModel } from "./PatientModel";
import { Reference } from "./Reference";
import { API_TIME_FORMAT } from "../../services/constants";

export enum AgendaItemStatusEnum {
  PROPOSED = "proposed",
  PENDING = "pending",
  BOOKED = "booked",
  ARRIVED = "arrived",
  FULFILLED = "fulfilled",
  CANCELLED = "cancelled",
  NO_SHOW = "noshow",
  WAITLIST = "waitlist",
}
/**
 * TODO: change name and interface to FHIR appointment resource: https://hl7.org/fhir/r4/appointment.html
 */
export interface AgendaItemModel extends EzoResource {
  start: number;
  end?: number;
  created?: number;

  title?: string;
  description?: string;
  location?: AddressModel;
  status?: AgendaItemStatusEnum;

  // FIXME : fix this
  participants?: Array<HCPModel | PatientModel>;
  participantIds?: string[];

  patientInstruction?: string;
  appointmentType?: string;

  supportingInformation?: Reference;

  // temporary, to review based on HL7 specs
  requester?: string;
  specialtyRequester?: string;
  procedureId?: string;
  procedureCode?: string;

  type?: string;
}

export const chronologically = (a: AgendaItemModel, b: AgendaItemModel) =>
  a.start > b.start ? -1 : 1;

export const antiChronologically = (a: AgendaItemModel, b: AgendaItemModel) =>
  a.start > b.start ? 1 : -1;

export const isPast = (a: AgendaItemModel) =>
  a.start! < parseInt(dayjs().format(API_TIME_FORMAT));

export const isNotPast = (a: AgendaItemModel) =>
  a.start! >= parseInt(dayjs().format(API_TIME_FORMAT));
