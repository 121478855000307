import useResource from "../../hooks/useResource";
import { ResourceType } from "../../types/ResourceType";
import { DocumentContent } from "../../models/DocumentReference";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styled from "styled-components";
import { Color, Size } from "../../ui/tokens";

import { Fragments } from "../home/HomeSection";
import { DocumentResource } from "../../models/DocumentResource";
import Typography from "../../ui/components/Typography/Typography";
import LabResultsShimmer from "../../ui/components/Loading/LabResultsShimmer";
import {
  formatDate,
  formatDateAndTime,
  formatName,
  getDocumentTitle,
} from "../../utils/formatting";
import Spacing from "../../ui/components/Layout/Spacing";
import UserProfile from "../../ui/components/UserProfile";
import { HCPModel } from "../../models/HCPModel";
import * as DOMPurify from "dompurify";
import Attachment from "../../ui/components/Attachment";
import { DiagnosticReportModel } from "../../models/DiagnosticReportModel";
import { PrescriptionModel } from "../../models/PrescriptionModel";
import Barcode from "react-barcode";
import Section from "../../ui/components/Layout/Section";
import Comment from "../../ui/components/Comment";
import ObservationsTable from "../../ui/components/ObservationsTable";

const RId = styled.div`
  padding: ${Size.M}px;
  background-color: ${Color.WHITE};
  border-radius: ${Size.M}px;
  text-align: center;
  margin-bottom: ${Size.L}px;
`;

const ViewDocumentScreen = () => {
  const { resourceId, section } = useParams();

  const {
    data: doc,
    error,
    isLoading,
  } = useResource<DocumentResource>(
    ResourceType.DOCUMENTS,
    resourceId,
    section != Fragments.DOCUMENTS
  );

  // TODO : updqte this so it can fetch multiple media links
  const { data: media, isLoading: isLoadingMedia } =
    useResource<DocumentContent>(
      ResourceType.MEDIA,
      doc?.media?.[0].attachment,

      !doc || doc.media?.length === 0
    );

  return (
    <>
      <Typography.h1>{doc ? getDocumentTitle(doc) : "..."}</Typography.h1>
      {isLoading ? (
        <LabResultsShimmer />
      ) : (
        <>
          <Typography.h2 color={Color.NEUTRAL_500}>
            {formatName((doc as DiagnosticReportModel)?.performer)}
          </Typography.h2>
          <Typography.small>{formatDate(doc?.issued)}</Typography.small>

          <Spacing large />

          {(doc as PrescriptionModel)?.rId && (
            <RId>
              <Barcode width={1.5} value={(doc as PrescriptionModel)?.rId!} />
            </RId>
          )}

          {!!doc?.comment && (
            <Comment
              text={doc?.comment}
              author={doc?.hcp as HCPModel}
              date={formatDateAndTime(doc?.commented ?? doc?.issued)}
            />
          )}

          {!!doc?.media?.length && !!doc?.media[0].description?.length && (
            <div
              style={{
                whiteSpace: "pre-wrap",
                borderTop: `1px solid ${Color.NEUTRAL_200}`,
                paddingTop: Size.L,
                paddingLeft: Size.M,
                paddingRight: Size.M,
              }}
            >
              <Typography.body>{doc.media[0].description}</Typography.body>
            </div>
          )}

          {doc?.media?.length && doc?.media[0].attachment && (
            <Section title="Attachment(s)">
              <Attachment document={media} isLoading={!media} />
            </Section>
          )}

          {(doc as DiagnosticReportModel)?.result && (
            <ObservationsTable
              observations={(doc as DiagnosticReportModel)?.result!}
              onSelect={(o) => console.log(o)}
            />
          )}
        </>
      )}
    </>
  );
};

export default ViewDocumentScreen;
