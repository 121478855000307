import { Size } from "../../tokens";
import Spacing from "../Layout/Spacing";
import ListItemShimmer from "./ListItemShimmer";
import ShimmerBlock from "./ShimmerBlock";

export default function SectionShimmer({ length = 3 }: ListShimmerProps) {
  return (
    <div
      style={{
        marginTop: `${Size.M}px`,
        marginBottom: `${Size.S}px`,
        padding: `${Size.S}px`,
      }}
    >
      <ShimmerBlock delay={0} styleName="small" />
      <ListShimmer length={length} />
    </div>
  );
}

interface ListShimmerProps {
  length?: number;
}
export function ListShimmer({ length = 3 }: ListShimmerProps) {
  return (
    <>
      <Spacing />
      {Array(length)
        .fill(0)
        .map((a, i) => (
          <ListItemShimmer key={i} delay={0.3 * i} />
        ))}

      <Spacing />
    </>
  );
}
