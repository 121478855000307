import { useState, useEffect, useRef } from "react";

const THRESHOLD = 50;

type CallbackType = () => void;

const usePullToRefresh = (callback: CallbackType): boolean => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const accumulatedDeltaY = useRef<number>(0);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (window.scrollY <= 0 && e.deltaY < 0) {
        // Scrolling up at the top
        accumulatedDeltaY.current = Math.abs(e.deltaY);
        if (accumulatedDeltaY.current > THRESHOLD && !isRefreshing) {
          setIsRefreshing(true);
          callback();

          setTimeout(() => {
            setIsRefreshing(false);
            accumulatedDeltaY.current = 0;
          }, 1000);
          /*.finally(() => {
            
          });*/
        }
      } else {
        accumulatedDeltaY.current = 0; // Reset accumulated scroll if not continuously scrolling up
      }
    };

    window.addEventListener("wheel", handleWheel);

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, [callback]);

  return isRefreshing;
};

export default usePullToRefresh;
