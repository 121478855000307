import { useTranslation } from "react-i18next";
import { MedicationModel } from "../../models/MedicationModel";
import Section from "../../ui/components/Layout/Section";
import EzoList from "../../ui/components/List/EzoList";
import { MedicationItemRenderer } from "./MedicationItemRenderer";
import DisplayModeProps from "./DisplayModeProps";
import { formatDate, shortAdministrationMode } from "../../utils/formatting";
import { PrescriptionModel } from "../../models/PrescriptionModel";
import Avatar from "../../ui/components/Avatar/Avatar";
import Icon from "../../ui/components/Icon";
import Assets, { Icons } from "../../ui/assets";
import { Color, Tints } from "../../ui/tokens";

enum RegimenType {
  MIXED = "mixed",

  AFTERBREAKFAST = "afterbreakfast",
  AFTERDINNER = "afterdinner",
  AFTERLUNCH = "afterlunch",
  AFTERMEAL = "aftermeal",
  AFTERNOON = "afternoon",
  BEFOREBREAKFAST = "beforebreakfast",
  BEFOREDINNER = "beforedinner",
  BEFORELUNCH = "beforelunch",
  BETWEENBREAKFASTANDLUNCH = "betweenbreakfastandlunch",
  BETWEENDINNERANDSLEEP = "betweendinnerandsleep",
  BETWEENLUNCHANDDINNER = "betweenlunchanddinner",
  BETWEENMEALS = "betweenmeals",
  EVENING = "evening",
  MORNING = "morning",
  NIGHT = "night",
  THEHOUROFSLEEP = "thehourofsleep",
  DURINGBREAKFAST = "duringbreakfast",
  DURINGLUNCH = "duringlunch",
  DURINGDINNER = "duringdinner",
}
// aj, 8, 10, 12, 16 20, 22
const filters: { [id: string]: RegimenType[] } = {
  mixed: [RegimenType.MIXED],
  breakfast: [
    RegimenType.AFTERBREAKFAST,
    RegimenType.BEFOREBREAKFAST,
    RegimenType.DURINGBREAKFAST,
    RegimenType.MORNING,
  ],
  morning: [RegimenType.BETWEENBREAKFASTANDLUNCH],
  lunch: [
    RegimenType.BEFORELUNCH,
    RegimenType.DURINGLUNCH,
    RegimenType.AFTERLUNCH,
  ],
  afternoon: [RegimenType.BETWEENLUNCHANDDINNER, RegimenType.AFTERNOON],
  evening: [
    RegimenType.BEFOREDINNER,
    RegimenType.DURINGDINNER,
    RegimenType.AFTERDINNER,
    RegimenType.EVENING,
  ],
  night: [RegimenType.NIGHT, RegimenType.THEHOUROFSLEEP],
  other: [],
};

interface ChronologicalSectionProps {
  prescriptions?: PrescriptionModel[];
  meds: MedicationModel[] | undefined;
  title?: string;
  showDetails: (med: MedicationModel) => void;
  time: string;
}
function ChronologicalSection({
  meds,
  prescriptions,
  title,
  showDetails,
  time,
}: ChronologicalSectionProps) {
  const { t } = useTranslation();

  function getPosologyForMedAtTime(med: MedicationModel, time: string) {
    if (time === "other") {
      return med.instruction ?? "";
    }
    const q = med.regimen.find((r) =>
      filters[time].some((filter) => filter === (r.dayPeriod as RegimenType))
    )?.administratedQuantity;

    let p = "";
    if (q) {
      p = `${q} ${shortAdministrationMode(
        med.administrationMode?.toLowerCase()
      )}`;
    } else if (med.commentForDelivery?.length) {
      p = `${med.commentForDelivery}`;
    } else {
      p = ``;
    }

    return `${p} ${
      med.endMoment
        ? `(${t("helpers.until")} ${formatDate(med.endMoment, true)})`
        : ""
    }`;
  }

  return (
    <Section
      title={title}
      icon={
        time === "mixed" ? (
          <Avatar
            small
            icon={<img src={Assets.icons!.warning} />}
            tint={Tints.YELLOW_LIGHT}
          />
        ) : undefined
      }
    >
      <EzoList
        data={meds}
        {...MedicationItemRenderer}
        iconRight={(item) => {
          const p = prescriptions?.find((p) => {
            return p.medicationId === item.id;
          });
          return p ? <Icon asset={Icons.icons.prescription_xs} /> : undefined;
        }}
        details={(med) =>
          time === "mixed"
            ? "Plusieurs posologies"
            : getPosologyForMedAtTime(med, time)
        }
        onItemSelection={showDetails}
      />
    </Section>
  );
}

export default function ChronologicallModeDisplay({
  meds,
  prescriptions,
  showDetails,
}: DisplayModeProps) {
  const singleRegimen = meds?.filter((med) =>
    med?.regimen?.every((r) => r.prescriber === med.hcp)
  );
  const mixedRegimen = meds?.filter(
    (med) => !med?.regimen?.every((r) => r.prescriber === med.hcp)
  );

  const { t } = useTranslation();

  const list = [
    "mixed",
    "breakfast",
    "morning",
    "lunch",
    "afternoon",
    "evening",
    "night",
    "other",
  ];

  return (
    <>
      {!!mixedRegimen?.length && (
        <ChronologicalSection
          time={"mixed"}
          key={0}
          title={t(`treatment.mixed`) as string}
          meds={mixedRegimen}
          prescriptions={prescriptions}
          showDetails={showDetails}
        />
      )}

      {list.map((time, index) => {
        const items = singleRegimen?.filter(
          (med) =>
            med.regimen.some((r) =>
              filters[time].some(
                (filter) => filter === (r.dayPeriod as RegimenType)
              )
            ) ||
            (filters[time].length === 0 && med.regimen.length === 0)
        );
        if (items?.length! > 0) {
          return (
            <ChronologicalSection
              time={time}
              key={index}
              title={t(`treatment.${time}`) as string}
              meds={items}
              prescriptions={prescriptions}
              showDetails={showDetails}
            />
          );
        }
      })}
    </>
  );
}
