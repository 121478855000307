export enum ContactPointUse {
  HOME = "home",
  WORK = "work",
  MOBILE = "mobile",
  OLD = "old",
  TEMP = "temp",
  EMAIL = "email",
  WEB = "web",
}
export interface ContactPoint {
  use?: ContactPointUse;
  value?: string;
}
