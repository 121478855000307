import React, { ReactElement } from "react";
import styles from "./Avatar.styles";
import { HCPModel } from "../../../models/HCPModel";
import { PatientModel } from "../../../models/PatientModel";
import { Tint, Tints } from "../../tokens";
import useTint from "../../../hooks/useTint";
import { getPatientInitials } from "../../../utils/formatting";
import styled from "styled-components";

export enum AvatarTypes {
  PATIENT = "Patient",
  HCP = "HCP",
}

interface AvatarProps {
  label?: string;
  icon?: string | ReactElement;
  type?: AvatarTypes;
  person?: HCPModel | PatientModel;
  large?: boolean;
  xxl?: boolean;
  small?: boolean;
  square?: boolean;
  tint?: Tint;
  extraStyle?: any;
  tintId?: string;
}

export default function Avatar({
  label,
  type = AvatarTypes.HCP,
  icon,
  person,
  extraStyle,
  large = false,
  xxl = false,
  small = false,
  square = false,
  tint,
  tintId,
}: AvatarProps) {
  const style = small
    ? styles.small
    : xxl
    ? styles.xxl
    : large
    ? styles.large
    : styles.default;
  const labelStyle = large || xxl ? styles.labelLarge : styles.label;
  const shape = square
    ? { borderRadius: style.borderRadius }
    : { borderRadius: (style.width as number) / 2 };

  const tintFromId = useTint(tintId ?? "0", !!tintId);

  const t = tint ?? tintFromId;

  return (
    <div
      className="avatar"
      style={{
        ...style,
        ...extraStyle,
        ...shape,
        backgroundColor: t.light,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: person?.picture
          ? `url("${person?.picture}")`
          : undefined,
        /* backgroundColor:
          type === AvatarTypes.PATIENT ? Color.YELLOW : Color.GREEN, */
      }}
    >
      {icon ? (
        typeof icon === "string" ? (
          <img src={icon} style={styles.icon} />
        ) : (
          icon
        )
      ) : (
        !person?.picture && (
          <div
            style={{
              ...labelStyle,
              color: t.dark,
            }}
          >
            {person ? `${getPatientInitials(person)}` : label ?? "..."}
          </div>
        )
      )}
    </div>
  );
}
