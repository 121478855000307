import { JsonWebKey } from "crypto";
import {
  Apis,
  Device,
  HealthcareParty,
  hex2ua,
  normalizeCode,
  Patient,
  pkcs8ToJwk,
  ShaVersion,
  spkiToJwk,
  ua2hex,
  User,
} from "@icure/api";
import { NotificationTypeEnum } from "@icure/medical-device-sdk/src/models/Notification";

import { KEY_STORAGE_PREFIX } from "../api/constants";
import { t } from "i18next";

export async function initCryptoForUserAndPatientId(
  api: Apis,
  user: User,
  id: string
) {
  // console.log(await AsyncStorage.getAllKeys())

  const patientRaw = await api.patientApi.getPatientRaw(id);
  if (!patientRaw.publicKey) {
    await addFirstKeyPairToPatient(api, user, patientRaw);
    return true;
  }

  const keyPairs = await loadKeyPairsFromDevice(api, patientRaw);
  const hasExistingKeys = keyPairs !== undefined && keyPairs.length > 0;

  if (!hasExistingKeys) {
    /*const { publicKey, privateKey } =
      await api.cryptoApi.addNewKeyPairForOwnerId(
        api.maintenanceTaskApi,
        user,
        patientRaw.id!
      );
      
    await storeAndCacheKeyPair(api, patientRaw, publicKey, privateKey);*/
    return false;
  } else {
    return true;
  }
}

// Returns all currently public keys available to patient
export function getAllPublicKeys(
  owner: Patient | HealthcareParty | Device
): string[] {
  return [
    ...new Set(
      Object.keys(owner.aesExchangeKeys ?? {}).concat(owner.publicKey ?? "")
    ),
  ].filter((k) => k !== "");
}

// loads keypairs available on device
export async function loadKeyPairsFromDevice(
  api: Apis,
  patient: Patient
): Promise<
  | Array<{
      publicKey: globalThis.JsonWebKey;
      privateKey: globalThis.JsonWebKey;
    }>
  | undefined
> {
  const publicKeys = getAllPublicKeys(patient);

  if (publicKeys.length <= 0) {
    return undefined;
  }
  const keypairs: Array<{
    publicKey: globalThis.JsonWebKey;
    privateKey: globalThis.JsonWebKey;
  }> = [];
  for (const publicKey of publicKeys) {
    const location = getKeyPairLocationInStorage(patient.id!, publicKey);
    const keypair = await api.cryptoApi.keyStorage.getKeypair(location);

    if (keypair !== undefined) {
      keypairs.push(keypair);
     // await api.cryptoApi.cacheKeyPair(keypair);
    }
  }
  return keypairs;
}

// creates key pair
async function createStoreAndCacheKeyPair(api: Apis, patient: Patient) {
  const { publicKey: publicKeyHex, privateKey: privateKeyHex } =
    await generateKeyPair(api);
  await storeAndCacheKeyPair(api, patient, publicKeyHex, privateKeyHex);
  // sendNewKeyPairNotification(api,user);
  return { publicKeyHex, privateKeyHex };
}

export async function storeAndCacheKeyPair(
  api: Apis,
  patient: Patient,
  publicKeyHex: string,
  privateKeyHex: string
) {
  const keysLocationInStorage = getKeyPairLocationInStorage(
    patient.id!,
    publicKeyHex
  );
  const keyPairInJwk = {
    publicKey: spkiToJwk(hex2ua(publicKeyHex), ShaVersion.Sha1),
    privateKey: pkcs8ToJwk(hex2ua(privateKeyHex)),
  };
  await api.cryptoApi.keyStorage.storeKeyPair(
    keysLocationInStorage,
    keyPairInJwk
  );
 //await api.cryptoApi.cacheKeyPair(keyPairInJwk);
}

//
async function addFirstKeyPairToPatient(
  api: Apis,
  user: User,
  patient: Patient
) {
  /*
  const { publicKeyHex } = await createStoreAndCacheKeyPair(api, patient);

  const p = {
    ...patient,
    publicKey: publicKeyHex,
    tags: (patient.tags ?? []).concat(
      normalizeCode({
        type: "PATIENT-APP",
        code: "has-key-pair",
        version: "1",
      })
    ),
  };
  
  const u = await api.userApi.getCurrentUser();
  await api.patientApi.modifyPatientRaw(
    {
      ...(await api.patientApi.initDelegationsAndEncryptionKeys(p, u, undefined)),
  
    }
  )

  await sendFirstKeyPairNotification(api, user);
  */
}

export async function addKeyPairToPatient(
  api: Apis,
  user: User,
  patient: Patient
) {
  /*
   api.cryptoApi.emptyHcpCache(patient.id!)
  const { publicKey, privateKey } = await api.cryptoApi.addNewKeyPairForOwnerId(
    api.maintenanceTaskApi,
    user,
    patient.id!
  );
 
  await storeAndCacheKeyPair(api, patient, publicKey, privateKey);

  return privateKey
  */
}

async function sendFirstKeyPairNotification(api: Apis, user: User) {
  const notification = await api.maintenanceTaskApi.newInstance(user, {
    taskType: NotificationTypeEnum.NEW_USER_OWN_DATA_ACCESS,
  });
  await api.maintenanceTaskApi.createMaintenanceTaskWithUser(
    user,
    notification
  );
}

async function generateKeyPair(
  api: Apis
): Promise<{ publicKey: string; privateKey: string }> {
  const { publicKey, privateKey } = await api.cryptoApi.primitives.RSA.generateKeyPair(ShaVersion.Sha1);
  const publicKeyHex = ua2hex(
    await api.cryptoApi.primitives.RSA.exportKey(publicKey, "spki")
  );
  const privKeyHex = ua2hex(
    await api.cryptoApi.primitives.RSA.exportKey(privateKey, "pkcs8")
  );
  return { publicKey: publicKeyHex, privateKey: privKeyHex };
}

export function getKeyPairLocationInStorage(id: string, key: string): string {
  return `${KEY_STORAGE_PREFIX}.${id}.${key.slice(-32)}`;
}
