import { useEffect, useState } from "react";
import styled from "styled-components";
import Assets, { Icons } from "../../assets";
import { Size } from "../../tokens";

const StyledSpinner = styled.div<Props>`
  opacity: ${({ fadeIn }) => (fadeIn ? 1 : 0)};
  animation: ${({ fadeIn }) => (fadeIn ? "fade-in 1s forwards" : "")},
    rotate 1s infinite cubic-bezier(0.47, 1.64, 0.41, 0.8);
  width: ${Size.XXL}px;
  height: ${Size.XXL}px;
  align-self: center;
  > svg {
    width: ${Size.XXL}px;
    height: ${Size.XXL}px;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

interface Props {
  fadeIn: boolean;
}

export function Spinner() {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <StyledSpinner fadeIn={fadeIn}>
      <Icons.navigation.flower />
    </StyledSpinner>
  );
}
