import {AppointmentTypeAndPlace, CalendarItem} from "@icure/api";
import dayjs from "dayjs";

import {API_TIME_FORMAT} from "./constants";
import {MedispringConnector} from "../connector/MedispringConnector";
import {AgendaItemStatusEnum,} from "../../../models/AgendaItemModel";
import {PatientModel} from "../../../models/PatientModel";

import {healthDataApi} from "../../../api/healthdata";
import {MedicationModel} from "../../../models/MedicationModel";
import {ResourceType} from "../../../types/ResourceType";
import {ConnectorProvider} from "../../ConnectorProvider";
//import { patientsAdded } from "../../../features/login/AuthSlice";

// UTILS
const getNow = (): number => Number(dayjs().format(API_TIME_FORMAT));
const getSixMonthsFromNow = (): number =>
  Number(dayjs().add(6, "month").format(API_TIME_FORMAT));

// ICURE API MANAGEMENT
const _userIdsByHcpId: { [id: string]: string } = {};

// EXTENDED MEDISPRING API

export const medispringApi = healthDataApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    // MEDICAL DATA ENDPOINTS

    getPatient: builder.query<PatientModel, string>({
      queryFn: async (id) => {
        const connector = MedispringConnector.getConnectorById(id);

        const patient = await connector.getPatient();
        return { data: patient };
      },
    }),

    // Appointments

    getSlotsForType: builder.query<number[], { hcp: string; type: string }>({
      queryFn: async ({ type, hcp }) => {
        const group = MedispringConnector.getGroupIdByHcpId(hcp);

        const aapi = await MedispringConnector.getAnonymousAccessApi();
        const slots = await aapi.getAvailabilitiesByPeriodAndCalendarItemTypeId(
          group,
          _userIdsByHcpId[hcp],
          type!,
          false,
          getNow(),
          getSixMonthsFromNow(),
          hcp
        );

        return { data: slots };
      },
    }),

    getAppointmentTypes: builder.query<AppointmentTypeAndPlace[], string>({
      queryFn: async (id) => {
        const group = MedispringConnector.getGroupIdByHcpId(id);

        const aapi = await MedispringConnector.getAnonymousAccessApi();
        const parties = await aapi.listHealthcarePartiesInGroup(group); // _groupIdsByHcpId[provider.id]);
        const uh = parties.find((uh) => uh.healthcareParty!.id === id);
        _userIdsByHcpId[id] = uh?.user!.id!;
        console.log(uh);
        const types = await aapi.listAppointmentTypesForUser(
          group,
          uh?.user!.id!,
          getNow(),
          getSixMonthsFromNow()
        );

        return { data: types };
      },
    }),

    /*
    getAppointmentTypeForPatientById: builder.query<
      CalendarItemType,
      {patient: PatientModel; typeId: string}
    >({
      queryFn: async ({typeId, patient}) => {
        const api = getApiForPatient(patient.id); // _calendarItemTypesApiByPatientId[patient.id];
        const types = await api?.calendarItemTypeApi.getCalendarItemTypes();
        const type = await api?.calendarItemTypeApi.getCalendarItemType(typeId);
        return {data: type!};
      },
    }),
*/
    cancelAppointment: builder.mutation<boolean, string>({
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          medispringApi.util.updateQueryData(
            "getResources",
            ResourceType.APPOINTMENTS,
            (draft) => {
              const index = draft.findIndex((i) => i.id === id);
              if (index >= 0) draft.splice(index, 1);
            }
          )
        );

        queryFulfilled.catch(patchResult.undo);
      },
      queryFn: async (appointmentId: string) => {
        const connectors = ConnectorProvider.getAll<MedispringConnector>();
        for (const connector of connectors) {
          await connector.cancelAppointment(appointmentId);
        }

        return { data: true };
      },

      invalidatesTags: [ResourceType.APPOINTMENTS],
    }),

    declineProposedProcedure: builder.mutation<boolean, any>({
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
            medispringApi.util.updateQueryData(
                "getResources",
                ResourceType.APPOINTMENTS,
                (draft) => {
                  const index = draft.findIndex((i) => i.id === id);
                  if (index >= 0) draft.splice(index, 1);
                }
            )
        );

        queryFulfilled.catch(patchResult.undo);
      },
      queryFn: async (serviceId: string) => {
        const connectors = ConnectorProvider.getAll<MedispringConnector>();
        for (const connector of connectors) {
          await connector.declineProposedProcedure(serviceId);
        }

        return { data: true };
      },

      invalidatesTags: [ResourceType.APPOINTMENTS],
    }),

    askForRefill: builder.mutation<
      boolean,
      { hcp: string; medications: MedicationModel[] }
    >({
      queryFn: async ({ medications, hcp }) => {
        for (const medication of medications) {
          const serviceId = medication.id;
          const patientId = medication.patientId;
          const c = MedispringConnector.getConnectorById(hcp);
          await c.askForRefill(serviceId);
        }
        return { data: true };
      },
    }),

    createAppointment: builder.mutation<
      CalendarItem,
      {
        hcp: string;
        patient: PatientModel;
        type: AppointmentTypeAndPlace;
        slot: string;
        procedureId?: string;
        procedureCode?: string;
      }
    >({
      async onQueryStarted(
        { patient, ...patch },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          healthDataApi.util.updateQueryData(
            "getResources",
            ResourceType.APPOINTMENTS,
            (draft) => {
              // FIXME : not sure why this is needed, but if we don't check this the data gets added twice
              if (!draft.some((item) => item.id === "TEMP")) {
                Object.assign(draft, [
                  ...draft,
                  {
                    id: "TEMP",
                    start: patch.slot,
                    participants: [patient, patch.hcp],
                    description: patch.type.name,
                    status: AgendaItemStatusEnum.PENDING,
                  },
                ]);
              }
            }
          )
        );

        queryFulfilled.catch(patchResult.undo);
      },
      queryFn: async ({
        type,
        hcp,
        patient,
        slot,
        procedureId,
        procedureCode,
      }) => {
        const connector = MedispringConnector.getConnectorById(hcp);
        const item = await connector.createAppointment(
          hcp,
          _userIdsByHcpId[hcp],
          type,
          parseInt(slot)
        );

        return { data: item };
      },

      invalidatesTags: [ResourceType.APPOINTMENTS],
    }),
  }),
});

export const {
  useDeclineProposedProcedureMutation,
  useCancelAppointmentMutation,
  useCreateAppointmentMutation,
  useGetSlotsForTypeQuery,
  useGetAppointmentTypesQuery,
  useAskForRefillMutation,
  useGetPatientQuery,
} = medispringApi;
