export const TRANSLATIONS_FR = {
    UI: {
        PLACE: "Lieu",
        LOADING: "Chargement en cours...",
        SAVING: "Enregistrement de votre rendez-vous...",
        HEADER: {
            APP_NAME: "Agenda en ligne",
            POWERED_BY: "Fourni par E·ZO"
        },
        ERROR: {
            TITLE: "Oops",
            SUBTITLE: "Une erreur s'est produite",
            NO_AGENDA: "Pas d'agenda à cette adresse",
            HCPS_IN_GROUP: "Il n'y a pas d'agenda à cette adresse. Peut-être cherchez-vous l'un des médecins suivants?"
        }
    },
    TOOLTIP : {
        REMEMBER_ME: "Si vous cochez cette option, vos informations seront enregistrées sur cet ordinateur de manière à ce que vous n'ayez plus à les ressaisir lors de votre prochaine visite. Ne sélectionnez pas cette option si vous partagez cet ordinateur avec d'autres."
    },
    BUTTONS: {
        START_APPOINTMENT: "Prendre rendez-vous",
        CANCEL: "Annuler",
        NEXT: "Suivant",
        CONFIRM: "Confirmer",
        NEW_APPOINTMENT: "Reprendre rendez-vous",
        OTHER_APPOINTMENT: "Autre rendez-vous",
        CHANGE: "Changer",
        VIEW_ITINERARY: "Voir l'itinéraire",
    },
    FLOW: {
        NEW_APPOINTMENT: "Nouveau rendez-vous",
        NEW_PATIENT_QUESTION: {
            TITLE: "Avez-vous déjà consulté ce praticien?",
            YES: "Oui",
            NO: "Non"
        },
        NEW_PATIENT_REJECTION: {
            TEXT: "<p>Désolé, ce praticien réserve la prise de rendez-vous en ligne aux patients déjà suivis.</p>"
        },
        CALENDAR_ITEM_TYPE_CHOICE: {
            TITLE: "Sélectionnez un type de rendez vous: "
        },
        TIMESLOT_CHOICE: {
            TITLE: "Choisissez un horaire: ",
            NO_TIMESLOTS: "Aucune disponibilité en ligne, veuillez contacter le secrétariat pour prendre rendez-vous."
        },
        IDENTIFICATION: {
            ERROR: "Veuillez remplir correctement: {{missingFields}}.",
            TITLE: "Vos coordonnées: ",
            FIRSTNAME: "Prénom",
            LASTNAME: "Nom",
            EMAIL: "E-mail",
            PHONE: "Téléphone",
            REASON: "Motif de votre visite (facultatif)",
            REMEMBER_ME: "Se souvenir de moi",
            POLICY: "En cliquant sur {{action}}, vous consentez à ce que vos données soient traitées conformément à ce qui est décrit dans la <a href='/privacy' target='_blank'>politique de confidentialité.</a>",
            REVIEW: "Vos informations sont transmises de manière sécurisée. La prise de rendez-vous en ligne ne remplace pas une consultation. En confirmant ce rendez-vous, vous vous engagez à l'honorer. Pensez bien à annuler le plus tôt possible en cas d’imprévu",
            WARNING: "Attention : il restera encore une étape pour confirmer votre rendez-vous."
        },
        VERIFICATION_CODE: {
            CALL_TO_ACTION: "Un code de 6 chiffres vous a été envoyé à <strong>{{email}}</strong>.<br/>Saisissez ce code ci-dessous afin de vérifier votre adresse email et confirmer votre demande de rendez-vous."
        },
        SAVE_APPOINTMENT: {
            FROM_MEDISPRING: "Via Medispring: ",
            APPOINTMENT_CONFIRMATION: "Confirmation de rendez-vous",
            CONFIRMATION: "Votre rendez-vous avec <strong>{{name}}</strong> le <strong>{{time}}</strong> est confirmé. N’oubliez pas vos documents d’identité.</p><p>Merci et à bientôt !"
        },
        CANCEL_APPOINTMENT: {
          HEADER:"Annuler ce rendez-vous?",
          BODY:"Vous êtes sur le point de supprimer votre rendez-vous. Pour annuler votre rendez-vous, cliquez sur le bouton ci-dessous.",
          EMAIL_SUBJECT:"Annulation de votre rendez-vous avec {{name}}",
          CONFIRMATION_HEADER: "Rendez-vous annulé",
          CONFIRMATION_BODY: "Votre rendez-vous a bien été annulé. Merci d’avoir fait les démarches nécessaires pour nous prévenir à temps. Merci de votre confiance."
        },
        REMIND_APPOINTMENT: {
          EMAIL_SUBJECT:"Rappel de rendez-vous : "
        }
    },
    ERRORS: {
        ID_CODE_INVALID: "Le code doit comporter 6 chiffres.",
        ID_CODE_WRONG: "Une erreur s'est produite. Veuillez vérifier si le code que vous avez complété correspond bien au code que vous avez reçu par e-mail.",
        GENERIC: "Une erreur technique s'est produite. Veuillez réessayer.",
        IS_MEDISPRING_USER: "Cette adresse email ne peut être utilisée pour prendre rendez-vous avec ce cabinet car elle est déjà associée à un professionnel de santé utilisateur de Medispring. Merci d'utiliser une autre adresse email."
    },
    FOOTER:{
        LEGAL: "Mentions légales",
        PRIVACY: "Politique de confidentialité",
    },
    SPECIALITIES: {
        "ADMINISTRATION": "Administration",
        "CARE_GIVER": "Aide soignant",
        "ANATOMOPATHOLOGY": "Anatomopathologie",
        "ANESTHESIOLOGY": "Anesthésiologie",
        "SOCIAL_WORKER": "Assistant(e) sociale",
        "PHARMA_ASSISTANT": "Assistant pharmaceutico-technique",
        "AUDIOLOGIST": "Audiologue",
        "BACTERIOLOGY": "Bactériologie",
        "TRUSS_ORTHOTIST": "Bandagiste-orthésiste",
        "CLINICAL_BIOLOGY": "Biologie clinique",
        "CARDIOLOGY": "Cardiologie",
        "GENERAL_SURGERY": "Chirurgie générale",
        "MAXILLOFACIAL_SURGERY": "Chirurgie Maxillo-faciale",
        "PLASTIC_SURGERY": "Chirurgie plastique",
        "STOMATOLOGICAL_SURGERY": "Chirurgie stomatologique",
        "CARDIOVASCULAR_SURGERY": "Chirurgie cardio-vasculaire",
        "ABDOMINAL_SURGERY": "Chirurgie abdominale",
        "DENTISTRY": "Dentisterie",
        "DERMATOLOGY": "Dermatologie",
        "DIETETIC": "Diététique",
        "ENDOCRINOLOGY": "Endocrinologie",
        "OCCUPATIONAL_THERAPIST": "Ergothérapeute",
        "GASTROENTEROLOGY": "Gastroentérologie",
        "GENETIC": "Génétique",
        "GERIATRICS": "Gériatrie",
        "GYNECOLOGY": "Gynécologie / Obstétrique",
        "HEMATOLOGY": "Hématologie",
        "NURSE": "Infirmier(e)",
        "NURSE_EDUCATOR": "Infirmier(e) éducatrice",
        "PHYSIOTHERAPY": "Kinésithérapie",
        "SPEECH": "Logopédie",
        "ACUTE_MEDICINE": "Médecine Aigue",
        "GENERAL_MEDICINE": "Médecine générale",
        "persphysician": "Médecine générale",
        "INTERNAL_MEDICINE": "Médecine interne",
        "NUCLEAR_MEDICINE": "Médecine nucléaire",
        "PHYSICAL_MEDICINE": "Médecine physique / Physiothérapie",
        "EXPERTISE_MEDICINE": "Médecine d'expertise",
        "INSURANCE_MEDICINE": "Médecine d'assurance",
        "TRAVEL_MEDICINE": "Médecine du voyage",
        "NEONATOLOGY": "Néonatalogie",
        "NEPHROLOGY": "Néphrologie",
        "NEUROSURGERY": "Neurochirurgie",
        "NEUROLOGY": "Neurologie",
        "NEUROPEDIATRICS": "Neuropédiatrie",
        "NEUROPSYCHIATRY": "Neuropsychiatrie",
        "NUTRITIONIST": "Nutritioniste",
        "ONCOLOGY": "Oncologie",
        "OPHTHALMOLOGY": "Ophtalmologie",
        "ORTHOPEDICS": "Orthopédie",
        "ORTHOPTISTE": "Orthoptiste",
        "OTORHINOLARYNGOLOGY": "Oto-Rhino-Laryngologie",
        "PEDIATRICS": "Pédiatrie",
        "PHARMACOLOGY": "Pharmacologie",
        "RESPIRATORY": "Pneumologie",
        "PODIATRIST": "Podologue",
        "PUBLIC_HEALTH_PREVENTION": "Prévention Santé Publique",
        "PROSTHETIST": "Prothésiste",
        "PSYCHIATRY": "Psychiatrie",
        "MEDICAL_PSYCHOLOGY": "Psychologie médicale",
        "RADIODIAGNOSTICS": "Radiodiagnostic / Imagerie médicale",
        "RADIOTHERAPY": "Radiothérapie",
        "RESUSCITATION": "Réanimation",
        "RHEUMATOLOGY": "Rhumatologie",
        "MIDWIFE": "Sage femme",
        "STOMATOLOGY": "Stomatologie",
        "EMERGENCY": "Urgences",
        "UROLOGY": "Urologie",
        "VIROLOGY": "Virologie",
        "TOXICOLOGY": "Toxicologie",
        "TRANSPORT": "Transport de malade",
        "ALGOLOGY": "Algologie",
        "GUARDHOUSE": "Poste de Garde",
        "REVALIDATION": "Revalidation",
        "SENOLOGY": "Sénologie",
        "DIABETOLOGY": "Diabétologie",
        "INFECTIOLOGY": "Infectiologie",
        "KINESIOLOGY": "Kinésiologie",
        "PHYSIOTHERAPYMICRO": "Kinésithérapie: microkiné",
        "OSTHEAOPATIA": "Ostéophatie",
        "PSYCHOTHERAPY": "Psychothérapie",
        "OTHER": "Autre",
        "HYPNOSIS": "Hypnose",
        "PERSPHYSICIAN": "Médecin",
        "NO_SPECIALITY": "Sans spécialité"
    }
};