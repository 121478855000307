import { AddressModel } from "./AddressModel";
import { ContactPoint } from "./ContactPoint";
import { PersonModel } from "./PersonModel";

/**
 * TODO: change name and interface to practitioner resource in FHIR https://www.hl7.org/fhir/practitioner.html
 * TODO: change specialty to practitioner role: https://www.hl7.org/fhir/practitionerrole.html
 */
export interface HCPModel extends PersonModel {
  specialty?: string; // CD-HCPARTY
  address?: AddressModel[];
  telecom?: ContactPoint[];
  name?: string;
  presentation?: string;
  groupId?: string;
  parentId?: string;
}

export function isHCPModel(item: any): item is HCPModel {
  return item && item.hasOwnProperty("specialty");
}
