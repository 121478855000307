import DisplayOptions from "../navigation/DisplayOptions";
import useDisplayOption from "../../hooks/useDisplayOption";
import Spacing from "../../ui/components/Layout/Spacing";
import { useTranslation } from "react-i18next";

export enum SortingOptions {
  EXAM_DATE = "EXAM_DATE",
  SHARE_DATE = "SHARE_DATE",
}

const DocumentsDisplayOptions = () => {
  const [showPrescriptions, togglePrescriptions] = useDisplayOption(
    "documents.prescriptions"
  );
  const [showReports, toggleReports] = useDisplayOption("documents.reports");
  const [sortingOrder, setSortingOrder] = useDisplayOption(
    "documents.sortingOrder"
  );
  const [showResults, toggleResults] = useDisplayOption("documents.results");

  const {t} = useTranslation();
  return (
    <>
      <DisplayOptions
        label={t("settings.display") as string}
        options={[
          {
            label:t("documents.prescriptions") as string,
            value: showPrescriptions,
            action: togglePrescriptions,
          },
          {
            label:t("documents.reports") as string,
            value: showReports,
            action: toggleReports,
          },
          {
            label:t("documents.results") as string,
            value: showResults,
            action: toggleResults,
          },
        ]}
      />
      <Spacing />
      <DisplayOptions
        label={t("settings.sort") as string}
        options={[
          {
            value: sortingOrder,
            action: setSortingOrder,
            options: [
              {
                id: SortingOptions.EXAM_DATE,
                
              label:t("settings.examDate") as string,
                value: SortingOptions.EXAM_DATE,
              },
              {
                id: SortingOptions.SHARE_DATE,
                label:t("settings.shareDate") as string,
                value: SortingOptions.SHARE_DATE,
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default DocumentsDisplayOptions;
