import Section from "../../ui/components/Layout/Section";
import Checkbox from "../../ui/components/Checkbox";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";

import { ActionCreator, AnyAction } from "@reduxjs/toolkit";
import RadioButtonGroup from "../../ui/components/RadioButtonGroup";
import { setOption, toggleOption } from "./DisplaySlice";
import Typography from "../../ui/components/Typography/Typography";
import { Color } from "../../ui/tokens";

interface Props {
  options: DisplayOption[];
  label?: string;
  children?: React.ReactNode;
}

export enum MedicationDisplayModes {
  CHRONOLOGICAL = "chronological",
  LOGICAL = "logical",
}

export interface DisplayOption {
  id?: string;
  label?: string;
  value: any;
  action: AnyAction;
  options?: any[];
}

const DisplayOptions = ({ options, label, children }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  return (
    <>
      <Typography.h4 color={Color.NEUTRAL_500}>{label}</Typography.h4>
      <>
        {options.map((option) => {
          if (option.options) {
            return (
              <RadioButtonGroup
              key={option.value}
                options={option.options}
                selected={option.value.value}
                onChange={(value) =>
                  dispatch(setOption({ id: option.value.id, value }))
                }
              />
            );
          }
          return (
            <Checkbox
              key={option.label}
              label={option.label}
              checked={option.value.value}
              onChange={() => dispatch(toggleOption(option.value.id))}
            />
          );
        })}
      </>
      {/*} <>
    {patients.map(patient=><Checkbox key={patient.id} label={patient.firstName + " " +patient.lastName} checked={visiblePatients[patient.id]} onChange={()=>dispatch(togglePatientVisibilty(patient.id))}/>)}
</>*/}
      {children}
    </>
  );
};

export default DisplayOptions;
