import Button from "../../ui/components/Button";
import ButtonGroup from "../../ui/components/Layout/ButtonGroup";
import Typography from "../../ui/components/Typography/Typography";

export default function NoConnector() {
  return (
    <div>
      <Typography.h2>Couldn't connect</Typography.h2>
      <Typography.body>
        We couldn't connect you to any registered providers, would you like to
      </Typography.body>

      <ButtonGroup>
        <Button primary title="I have an invitation code" />
        <Button title="Use the app on my own" />
      </ButtonGroup>
    </div>
  );
}
