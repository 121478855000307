import styled from "styled-components";

import Section from "./Section";
import EzoList, { EzoListRenderingProps } from "../List/EzoList";
import { ListShimmer } from "../Loading/SectionShimmer";
import EmptyState from "../EmptyState";
import { Color } from "../../tokens";
import Typography from "../Typography/Typography";

interface Props<U> {
  data?: U[];
  title?: string;
  accessory?: JSX.Element;
  isLoading?: boolean;
  length?: number;
  renderer?: EzoListRenderingProps<U>;
  empty?: string;
  info?: string;
  onItemSelection?: (item: U) => void;
}
export default function ListSection<U>({
  title,
  data,
  renderer,
  accessory,
  isLoading,
  length = 3,
  onItemSelection,
  empty,
  info,
}: Props<U>) {
  if (isLoading && !empty && !data?.length) return <></>;
  return (
    <Section
      title={title}
      icon={accessory}
      background={Color.WHITE}
      info={info}
    >
      {isLoading ? (
        <ListShimmer length={length} />
      ) : data?.length === 0 ? (
        <EmptyState image={empty ?? ""} />
      ) : (
        <EzoList data={data} {...renderer} onItemSelection={onItemSelection} />
      )}
    </Section>
  );
}
