import { useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { Color, Size } from "../../tokens";

export const SHIMMER_DURATION = 1000;

const shimmer = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const StyledShimmer = styled.div<ShimmerBlockProps>`
  animation-name: ${shimmer};
  animation-iteration-count: infinite;
  background-color: ${Color.NEUTRAL_200};
  border-radius: ${Size.XS}px;
  height: ${Size.M}px;
  width: ${Size.XXL * 3}px;
  display: block;

  ${(props) => css`
    animation-delay: ${props.delay * 600}ms;
    animation-duration: ${SHIMMER_DURATION * 2}ms;
  `}
`;

interface ShimmerBlockProps {
  styleName?: string;
  delay: number;
}

export default function ShimmerBlock({
  delay = 0,
  styleName = "small",
}: ShimmerBlockProps) {
  return <StyledShimmer delay={delay} styleName={styleName} />;
}
