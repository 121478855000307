import TimeslotGroupList from "../../../../ui/components/TimeslotGroup/TimeslotGroupList";
import SectionShimmer from "../../../../ui/components/Loading/SectionShimmer";
import {
  useGetAppointmentTypesQuery,
  useGetSlotsForTypeQuery,
} from "../../api/medispring";
import useURLQuery from "../../../../hooks/useURLQuery";
import useUpdateSearchParams from "../../../../hooks/useUpdateSearchParams";
import { AppointmentTypeAndPlace } from "@icure/api";

const SlotSelectionScreen = () => {
  const query = useURLQuery();
  const updateParam = useUpdateSearchParams();

  const { data: types } = useGetAppointmentTypesQuery(query.hcp ?? "");

  const type =
    query.type ?? types?.length
      ? (types as AppointmentTypeAndPlace[])[0].calendarItemTypeId!
      : "";

  const { data, isLoading } = useGetSlotsForTypeQuery({ type, hcp: query.hcp });
  //{data:[20221024162000,20221024162000,20221024162000,20221024162000,20221024162000], isLoading:false}
  return (
    <>
      {isLoading || !data ? (
        <SectionShimmer />
      ) : (
        <TimeslotGroupList
          slots={data}
          onSlotSelected={(slot) => {
            updateParam("type", type);
            updateParam("slot", slot.toString());
          }}
        />
      )}
    </>
  );
};

export default SlotSelectionScreen;
