export const nl = {
  home: {
    MEDISPRING: "Medispring",
    DESCRIPTION:
      "Het EMD als hart van uw medische praktijk, beheerd door artsen.",
    UPDATE: {
      TITLE: "Update beschikbaar",
      RESTART: "Nu bijwerken.",
      OR: "of",
      LATER: "Later bijwerken.",
    },
    ADMIN: "Beheerder",
  },
  menu: {
    ITEM: {
      DASHBOARD: "DASHBOARD",
      PATIENTS: "Lijst van patiënten",
      MAILING: "Ontvangst mail / eHealthBox",
      CALENDAR: "Agenda",
      MANAGEMENT: "Instellingen",
      INVOICING: "Facturatie",
      BEOOGO: "Beoogo",
      PENDING_PRESCRIPTIONS: "Voorschriften in behandeling",
      REPORTING: "Reporting module",
    },
  },
  passwordStrength: {
    title: "Een veilig wachtwoord moet bevatten:",
    details: {
      length: "minstens {{expectedLength}} karakters",
      uppercase: "hoofdletters",
      lowercase: "kleine letters",
      number: "cijfers",
      symbol: "speciale tekens: #@%€* ...",
    },
  },
  login: {
    SUCCESS: "U bent verbonden",
    FAILURE: "Verbinden mislukt",
    WEAK_PASSWORD_CONTINUE: "Verdergaan",
    WEAK_PASSWORD_TITLE: "Zwak wachtwoord",
    WEAK_PASSWORD_CONTENT:
      "Wij raden u aan om uw wachtwoord in uw profiel te wijzigen.",
    LOGIN: "Gebruikersnaam",
    USER: "Gebruiker",
    PASSWORD: "Wachtwoord",
    PASSWORD_EH_HEALTH: "Wachtwoord eHealth",
    CONNECTION: "Verbinding maken",
    SELECT_GROUP: "Selecteer uw entiteit:",
    LANGUAGE: "Taal",
    OPTIONS: {
      "1733": "Flux 1733",
      MORE_OPTIONS: "Meer opties",
      FHC: "FHC",
      ICURE: "iCure",
      LOGIN_TYPE: "Type verbinding",
      OFFICE: "Solo- of groepspraktijk",
      MMF: "Forfaitaire praktijk",
      GUARDPOST: "Wachtpost",
      RLM: "Lokale Zorgraad",
    },
    WRONG_ATTEMPTS_EXCEEDED:
      "U hebt {{attempts}} keer een verkeerd wachtwoord ingevoerd. De volgende poging is pas beschikbaar na {{minutes}} minuten.",
    CUSTOM_HOST: "Specifieke server",
    CUSTOM_HOST_URL: "https://",
    CUSTOM_HOST_PORT: "16043",
  },
  datepicker: {
    year: "jaar",
    month: "maand",
    day: "dag",
  },
  "contact-compatibility": {
    OMISSION_OF_MEDICAL_DATA:
      "Op verzoek van de patiënt worden sommige medische gegevens niet in deze transactie opgenomen:",
    authorize: "ja.",
    refuse: "nee.",
  },
  "calendar-datepicker": {
    DATE_NOT_VALID: "De ingevoerde datum is niet geldig.",
  },
  "private-home": {
    AUTO_CLOSE_KNOWN:
      "Patiënt {{name}} heeft zichzelf gesloten omdat er te veel patiënten open waren",
    AUTO_CLOSE_UNKNOWN:
      "Een patiënt heeft zichzelf gesloten omdat er te veel patiënten open waren",
    PRELOAD: {
      TITLE: "Applicatie laden",
      CONFIGURATION: "Configuratie laden",
      BEOOGO: "Verbinding met Beoogo",
      EHEALTH: "Verbinding met eHealth",
      ERROR: "Fout",
    },
    PMF_IMPORT_DISABLED_ON_ICURE_DBS:
      "PMF-import is momenteel niet beschikbaar voor iCure- en Topaz-databases.",
    SUMEHR: {
      NEW_SUMEHR_DETECTED: "Nieuwe versie van de SUMEHR verzendbaar",
      ASK_CREATION_NEW_SUMEHR:
        "Er bestaat nog een SUMEHR voor deze patiënt, wilt u er een verzenden?",
      ASK_UPGRADE_SUMEHR:
        "Wilt u de nieuwe versie van de SUMEHR opladen naar de kluis ?",
      REJECT: "Niet verzenden",
      CREATION_CONFIRM: "Voorbeeld",
      CONFIRM: "Verzenden",
      SEND_SUMEHR: {
        SUCCESS: "Verzending SUMEHR geslaagd voor de patiënt {{patient}}",
        GENERATION_ERROR: "Fout bij aanmaak SUMEHR voor de patiënt {{patient}}",
      },
    },
  },
  "management-home": {
    TABS: {
      SETTINGS: "Instellingen",
      DATABASE: "Database",
      DOCUMENTS: "Documenten",
      TOOLS: "Hulpmiddelen",
    },
    HCPS: "Betrokkenen",
    USERS: "Gebruikers",
    MAINTENANCES: "Onderhoud",
    SECURITY: "Beveiliging",
    PRINTERS: "Printers",
    CLASSIFICATION_TEMPLATES: "Classificatie sjablonen",
    MAGISTRAL: "Magistrale bereidingen",
    TEMPLATING: "Type documenten",
    KEYWORDS: "Sleutelwoorden",
    KEYWORD_AUTOCOMPLETE: "Automatisch aanvullen",
    INVOICING: "Bewijsdocumenten",
    EFAC: "Facturatie eFact",
    MYCARENET: "MyCareNet conversatie",
    E_DMG: "eGMD",
    E_DMG_PATIENT_LIST: "Lijst van patiënten",
    E_DMG_OTHERS: "Vernieuwingen en afsluitingen",
    SCAN_IMPORT: "Importeren van scans",
    ACCESS_LOG: "Overzicht van toegangen",
    CONNECTIONS: "Verbindingen",
    ACCESS_LOG_ALL: "Alle toegangen",
    ACCESS_LOG_USER: "Per gebruiker",
    ACCESS_LOG_PATIENT: "Per patiëntendossier",
    STATISTICS: "Statistieken",
    DASHBOARD: "dashboard",
    PRIVATE_MEDICATION: "Eigen geneesmiddelen",
    IMPORT: "Importeren",
    IMPORT_EXCEL: "Excel",
    SUGGESION_HE: "Hulp bij het creëren van zorgelementen",
    REPORTS: "Rapporten",
    FLATRATE: "eForfait ",
    LABORATORIES: "Laboratoria",
    EVENTS_COVID: "COVID Opvolging",
  },
  "flatrate-report": {
    title: "Eforfait ",
    type: "Rapporttype",
    yes: "Ja",
    no: "Nee",
    subscription: {
      title: "Exporteren",
    },
    btn: {
      generate: "Genereren",
      generating: "Tijdens generatie",
    },
    "report-type": {
      SUBSCRIPTION: "Export van abonnementen",
    },
    sheetHeaders: {
      title: {
        subscription: "",
      },
      active: "Actief",
      firstName: "Naam",
      lastName: "Voornaam",
      ssin: "Inss",
      dateOfDeath: "Sterfdatum",
      insuranceCode: "Verzekerings nummer",
      insuranceContractId: "Contract nummer",
      insuranceStartDate: "Startdatum",
      insuranceEndDate: "Einddatum",
      insuranceTc1: "TC1",
      insuranceTc2: "TC2",
      insurancePreferentialStatus: "Voorkeursstatus",
      mhcIsValid: "Geldig contract",
      mhcContractId: "Contract id",
      mhcStartOfContract: "Begin contract",
      mhcEndOfContract: "Einde contract",
      mhcStartCoverage: "Begin van dekking",
      mhcEndCoverage: "Einde van dekking",
      mhcSignatureType: "Type handtekening",
      lastInvoiced: "Laatst verzonden",
      errorNoSubscriptionResponse: "Fout tijdens de inschrijving",
    },
  },
  mycarenet: {
    STUCK:
      "Bent u geblokkeerd? Download dit bestand en stuur het naar de helpdesk.",
  },
  "efact-status": {
    NOT_EFACT: "Geen eFact",
    ERRORS_IN_PRELIMINARY_CONTROL: "Fout gedetecteerd",
    ERROR: "In fout",
    WARNING: "Gedeeltelijk aanvaard",
    SUCCESS: "Aanvaard",
    REJECTED: "Verworpen",
    ACCEPTED: "Aanvaard voor behandeling",
    TREATMENT: "In behandeling",
    SUBMITTED: "Verstuurd",
    RECEIVED: "Overgedragen",
    SENT: "Verstuurd",
    WAITING: "In afwachting",
    ARCHIVED: "Gearchiveerd",
    UNKNOWN: "Status onbekend",
  },
  "invoicing-summary": {
    INVOICING: "Attesten",
    PENDING_INVOICES: "Attesten af te sluiten",
    EFAC: "Facturatie eFact",
    EFAC_TO_BE_SENT: "Te versturen",
    EFAC_PENDING: "In afwachting",
    EFAC_TO_BE_CORRECTED: "Te verbeteren",
    EFAC_TREATED: "Behandeld",
    EFAC_ARCHIVED: "Gearchiveerd",
    EFAC_INVOICES: "Facturen eFact",
    EFAC_BATCH: "Verzendingen eFact (Lots)",
  },
  "invoicing-excel": {
    EXPORT_PATIENTS: "Patiënten exporteren",
    EXPORT: "Export naar Excel",
    EH_NOT_CONNECTED:
      "Verbind u met eHealth om de patiënten op te nemen in de export",
    GEN_0:
      "Genereren van Excel-bestand bezig. Even geduld, dit kan lange tijd duren.",
    GEN_50: "Genereren van Excel-bestand 50%",
    GEN_100: "Genereren van Excel-bestand 100%",
    GEN_ERR:
      "Fout bij genereren van Excel-bestand. Het genereren ervan over een kortere periode kan de fout vermijden.",
    DETAILS: "Details",
    GENINS_FAIL:
      "De verzekerbaarheid van patiënt {{naam}} is mislukt. De mutualiteit zal voor deze patiënt niet aanwezig zijn in de export. ",
    CERTIFICATES: "Attesten",
  },
  "patients-export": {
    EXCEL_NAME: "Patiëntenlijst",
    EXCEL_GEN_0:
      "Genereren Excel-bestand bezig. Even geduld, dit kan lange tijd duren.",
    EXCEL_GEN_100: "Genereren Excel-bestand 100%",
    EXCEL_GEN_ERR: "Fout bij genereren Excel-bestand.",
    fields: {
      FIRST_NAME: "Voornaam",
      LAST_NAME: "Naam",
      DATE_OF_BIRTH: "Geboortedatum",
      SSIN: "Nationaal nummer",
      STREET: "Straat",
      HOUSE_NUMBER: "Nummer",
      POSTAL_CODE: "Postcode",
      CITY: "Stad",
      MOBILE: "GSM",
      PHONE: "Vaste telefoon",
      EMAIL: "E-mail",
      GENDER: "Geslacht",
      AGE: "Leeftijd",
      PREVENTION_STATUS: "Preventiestatus",
      PREVENTION_DATE: "Verstrijkdatum van de preventie",
      EXTERNAL_ID: "Externe identificatie",
    },
    TITLE: "Type export",
    EXPORT: "Exporteren",
    CANCEL: "Annuleren",
    FORMAT: "Formaat",
    MULTIPLE_EXCEL: {
      LABEL: "Lijst van geselecteerde patiënten exporteren",
      DESCRIPTION:
        "De lijst met patiënten die u hebt geselecteerd zal naar een Excel-werkblad worden geëxporteerd.",
      FORMAT: "Excel (XSLX)",
    },
    MULTIPLE_PMF: {
      LABEL: "Dossiers van geselecteerde patiënten exporteren",
      DESCRIPTION:
        "Het dossier van elke patiënt zal worden geregistreerd in een bestand dat in de folder van uw keuze wordt geplaatst.",
      FORMAT: "PMF",
    },
    ALL_JSON: {
      LABEL: "Alle dossiers exporteren",
      DESCRIPTION: "Al uw dossiers werden geëxporteerd",
      FORMAT: "JSON",
    },
    SINGLE_CSV: {
      LABEL: "Lijst met contacten van de patiënt exporteren",
      DESCRIPTION:
        "De lijst met contacten van de patiënt zal worden geregistreerd in een bestand waar de informatie wordt gescheiden door komma's.",
      FORMAT: "CSV",
    },
    SINGLE_PMF: {
      LABEL: "Dossier patiënt exporteren",
      DESCRIPTION:
        "Het dossier van de patient zal worden geregistreerd in een bestand dat in de folder van uw keuze wordt geplaatst.",
      FORMAT: "PMF",
    },
    SINGLE_SELECTED_EXPORT: "Exporteren van de geselecteerde patiënt",
    MULTIPLE_SELECTED_EXPORT: "Exporteren van uw geselecteerde patiënten",
    ALL_EXPORT: "Exporteren van al uw patiënten",
    SINGLE_SELECTED_EXCEL: {
      LABEL: "Exporteren in Excel-formaat",
      DESCRIPTION:
        "De patiënt zal worden geëxporteerd naar een Excel-werkblad.",
    },
    SINGLE_SELECTED_JSON: {
      LABEL: "Exporteren in Json",
      DESCRIPTION:
        "De versleutelde gegevens van de patiënt zullen in Json-formaat worden geëxporteerd.",
    },
    SINGLE_SELECTED_PMF: {
      LABEL: "Exporteren in PMF",
      DESCRIPTION: "De patiënt zal worden geëxporteerd in PMF-formaat.",
    },
    MULTIPLE_SELECTED_EXCEL: {
      LABEL: "Exporteren in Excel-formaat",
      DESCRIPTION:
        "De lijst met patiênten zal worden geëxporteerd naar een Excel-werkblad.",
    },
    MULTIPLE_SELECTED_JSON: {
      LABEL: "Exporteren in Json",
      DESCRIPTION:
        "De lijst met al uw versleutelde gegevens zal in Json-formaat worden geëxporteerd.",
      WARNING:
        "Opgelet: dit kan veel geheugen op uw harde schijf innemen. Zorg ervoor dat u voldoende geheugen vrijmaakt.",
    },
    MULTIPLE_SELECTED_PMF: {
      LABEL: "Exporteren in PMF",
      DESCRIPTION:
        "De lijst met alle patiënten zal in PMF-formaat in een zip-bestand worden geëxporteerd.",
      ZIP_NAME: "Export-van-meerdere-PMF.zip",
    },
    SUCCESS: "Gegevens succesvol geëxporteerd.",
    FAILURE: "Fout bij exporteren.",
    EXPORT_STARTED: "Er is een export van patiëntendossiers bezig.",
    EXPORT_FINISHED: "Export van patiëntendossiers klaar.",
    INVCUDE_ACTIVE_PATIENTS: "Inactieve patiënten opnemen",
  },
  "patients-home": {
    BACK: "Terug",
    TITLE: "Lijst van patiënten",
    NO_RECORDS:
      "Geen enkel resultaat komt overeen met uw zoekopdracht of uw zoekopdracht is niet voldoende precies (minimaal 3 karakters nodig)",
    PATIENTS: "Patiënten",
    MERGE_PATIENTS: "Patiënten samenvoegen",
    MERGE_PATIENTS_WARNING:
      "Bevestig het samenvoegen van {{patient}} met {{duplicate}}.",
    MERGE_PATIENTS_ALERT:
      "Opgelet: deze actie kan niet ongedaan worden gemaakt.",
    UNEXPECTED_ERROR_DURING_MERGE:
      "Er is een fout opgetreden tijdens het samenvoegen van de patiënten.",
    CONFIRM: "Samenvoegen bevestigen",
    CANCEL: "Annuleren",
    FIND_BY_NAME: "Zoeken op naam",
    EXTERNAL_ID: "Dossiernummer",
    PREVENTIONS_DESCRIPTIONS: "Selectiecriterium",
    LAST_NAME: "Naam",
    FIRST_NAME: "Voornaam",
    DATE_OF_BIRTH: "Geboortedatum",
    SSIN: "Rijksregisternummer",
    CREATION_DATE: "Aanmaakdatum",
    CREATED_BY: "Ingevoerd door",
    ADDRESS: "Adres",
    ADDRESS_HOME_PHONE: "Telefoon",
    ADDRESS_HOME_MOBILE: "Gsm",
    ADDRESS_HOME_MAIL: "Mail",
    CONTACT: "Contact",
    INCLUDE_INACTIVES: "Inactieve patiënten opnemen",
    DUPLICATES: "Dubbels",
    EXPORT: "Exporteren",
    EXPORT_ALL: "Alles",
    GENDER: "Geslacht",
    AGE: "Leeftijd",
    STREET: "Straat",
    HOUSENUMBER: "Nummer",
    POSTALCODE: "Postcode",
    CITY: "Stad",
    COUNTRY: "Land",
    PREVENTION_STATUS: "Preventiestatus",
    MERGE_ERROR_PATIENT_OPENED:
      "Sluit de tab '{{tabName}}' vooraleer samen te voegen",
    PREVENTION_DATE: "Verstrijkdatum van de preventie",
    PREVENTION: {
      All: "Alle",
      ToDo: "Te doen",
      InOrder: "In orde",
      Refused: "Geweigerd",
      Cancelled: "Geannuleerd door de arts",
      NonIndicated: "Niet aangewezen",
      NotActivated: "Niet geactiveerd",
      None: "",
      Action: {
        activate: "Activeren",
        refuse: "Weigeren",
        nonIndicated: "Niet aangegeven",
        plan: "Een preventietaak plannen",
      },
      PLAN_PREVENTIVE_ACT: {
        Title: "Een preventietaak plannen",
        Warning:
          "De preventie zal bij alle geselecteerde patiënten worden geactiveerd",
        Cancel: "Annuleren",
        Save: "Bewaren",
      },
      STATUS_UPDATED_SUCCESSFULLY: "Bijgewerkte preventies",
    },
    VACCINATION_LAST_DATE: "Datum van laatste vaccinatie",
    MAX_DUE_DATE: "Max. vervaldatum",
    STATUS_DATE: "Datum van statuswijziging",
    MAILING: "E-mail versturen",
    MAILING_TITLE: "Een e-mail versturen naar {{ count }} patiënten",
    ADVANCED_SEARCH: "Geavanceerde zoekopdracht",
    BIOMETRY: "Biometrie",
    SERVICES: "Diensten",
    DISPLAY_MODE: {
      MODE: "Modus",
      NORMAL: "Normaal",
      PREVENTION: "Préventie",
      BIOMETRY: "Biometrie",
      SERVICES: "Diensten",
      BREAST_CANCER_PREVENTION: "Borstkankerpreventie",
      CERVIX_CANCER_PREVENTION: "Preventie van baarmoederhalskanker",
      COLORECTAL_CANCER_PREVENTION: "Darmkankerpreventie",
      INFLUENZA_FLU_PREVENTION: "Grieppreventie",
      AORTIC_ANEURYSM_PREVENTION: "Abdominaal aorta-aneurysmapreventie",
      LUNG_CANCER_PREVENTION: "Longkankerpreventie",
      HEALTH_ELEMENT: "Zorgelement",
      COVID19_QUERY: "Risicopatiënten Covid-19 vaccinatie",
      VACCINATION_COVID: "Vaccinatiestatus Covid-19",
      NOT_VACCINATED_COVID19_RISK: "Niet gevaccineerde risicopatiënten",
    },
    CONSULTATION: "Geassocieerde consultatie",
    VIEW_CONTACT: "Openen",
    ACTIVE: "Actief",
    YES: "Ja",
    NO: "Nee",
    REFRESH: "Verversen",
    PRINT_TAG: "Etiketten afdrukken",
    PRINT_TAG_ERROR:
      "{{name}} beschikt niet over alle noodzakelijke informatie om zijn mutualiteitsetiket af te drukken.",
    VACCINATION_COVID: {
      GET_EVENTS: "Vaccinatiestatus updaten",
      MEETING_DATE: "Datum van de volgende bijeenkomst",
      STATUS: "Toestand",
      FIRST_DOSE_DATE: "Datum van eerste dosis",
      SECOND_DOSE_DATE: "Datum van tweede dosis",
      EXTRA_DOSE: "Extra dosis",
      EXTRA_DOSE_ACTIVE: "Geactiveerd",
      EXTRA_DOSE_GET: "Ontvangen",
      OTHER_INFO: "Andere informatie",
      NO_INFO: "Geen informatie",
      CNK_ERROR: "CNK ontbreekt, status te controleren in het dossier",
      ACTIVATED: "Actief",
      NOT_ACTIVATED: "Niet actief",
      TOTALY_VACCINE: "Volledig gevaccineerd",
      PARTIAL_VACCINE: "Gedeeltelijk gevaccineerd",
      NO_VACCINE: "Niet gevaccineerd",
    },
    COVID19: {
      SEND: "Lijst versturen",
      SEND_OK: "Lijst verstuurd",
      SEND_ERROR: "Fout bij versturen van lijst",
      SEND_NOT_AVAILABLE:
        "Covid vaccination dienst is niet beschikbaar. Probeer later opnieuw.",
      OVER65: "65 jaar en meer",
      NO_PRIORITY: {
        "1844": "Zonder risicofactor 18-44 jaar",
        "4564": "Zonder risicofactor 45-64 jaar",
      },
      PRIORITY: {
        "1&3": {
          "1844": "Risikopatiënten - 18-44 jaar",
          "4564": "Risikopatiënten - 45-64 jaar",
        },
        "1": {
          "1864": "Prioriteit 1 - 18-64 jaar",
          "4564": "Prioriteit 1 - 45-64 jaar",
        },
        "3": {
          "1844": "Prioriteit 3 - 18-44 jaar",
        },
      },
    },
  },
  "patients-duplicates": {
    TITLE: "Lijst van dubbele patiënten",
    BY_SSIN: "Dubbels op basis van INSZ",
    BY_NAME: "Dubbels op basis van naam",
    SSIN: "INSZ:",
    MERGE: "Samenvoegen",
    NO_DUPLICATE: "Markeren als niet-dubbels",
    MARKED_NO_DUPLICATE: "Patiënten gemarkeerd als niet-gedupliceerd",
    NO_SSIN_DUPLICATES_MESSAGE: "Er zijn geen dubbels op INSZ",
    NO_NAME_DUPLICATES_MESSAGE: "Er zijn geen dubbels op naam",
    WARNING:
      "Opgelet: Zorg ervoor dat er geen verdere actie wordt ondernomen op de samengevoegde patiënten",
    MERGED: "Samengevoegd",
    NOT_DUPLICATE: "Niet-dubbels",
    MERGE_ERROR_PATIENT_OPENED:
      "Sluit de tab '{{tabName}}' alvorens samen te voegen",
    VIEW_MERGED_PROFILE: "Samengevoegd profiel openen",
    LOAD_MORE: "Meer resultaten",
    PATIENTS_MERGING: "Samenvoegen van patiënten",
    EDIT: "Handmatig bewerken",
    SAVE: "Bewaren",
    CANCEL: "Annuleren",
  },
  "patient-conflicts": {
    MANAGE_CONFLICTS: "Conflicten beheren",
    FIRST_NAME: "Voornaam",
    LAST_NAME: "Familienaam",
    CHRONICAL_DISEASE: "",
    RESPONSIBLE: "",
    AUTHOR: "",
    END_OF_LIFE: "",
    SSIN: "INSZ",
    CIVILITY: "Titel",
    GENDER: "Geslacht",
    MAIDEN_NAME: "Meisjesnaam",
    SPOUSE_NAME: "Naam echtgenoot",
    PARTNER_NAME: "Naam partner",
    PERSONAL_STATUS: "Persoonlijke status",
    DATE_OF_BIRTH: "Geboortedatum",
    DATE_OF_DEATH: "Datum van overlijden",
    PLACE_OF_BIRTH: "Plaats van geboorte",
    PLACE_OF_DEATH: "Plaats van overlijden",
    EDUCATION: "Opleiding",
    PROFESSION: "Beroep",
    NOTES: "Opmerkingen",
    NATIONALITY: "Nationaliteit",
    PICTURE: "Foto",
    EXTERNAL_ID: "Externe identificatie",
    ALIAS: "Alias",
    ADMINISTRATIVE_NOTE: "Administratieve opmerking",
    WARNING: "Opgelet",
    NEXT: "Volgende",
    DEACTIVATION_REASON: "Reden voor desactivering",
    EDIT_CONFLICTS: "Conflicten beheren",
    SAVE: "Bewaren",
    CANCEL: "Annuleren",
    ADDRESSES: {
      STREET: "Adres [{{ number }}] > Straat",
      CITY: "Adres [{{ number }}] > Stad",
      COUNTRY: "Adres [{{ number }}] > Land",
      DESCR: "Adres [{{ number }}] > Omschrijving",
      HOUSE_NUMBER: "Adres [{{ number }}] > Nummer",
      POSTAL_NUMBER: "Adres [{{ number }}] > Postcode",
      POSTBOX_NUMBER: "Adres [{{ number }}] > Bus",
    },
    INSURABILITIES: {
      END_DATE: "Verzekerbaarheid [{{ number }}] > Geldigheidsdatum",
      IDENTIFICATION_NUMBER:
        "Verzekerbaarheid [{{ number }}] > Identificatienummer",
      DENTAL: "Verzekerbaarheid [{{ number }}] > Tandheelkundig",
      AMBULATORY: "Verzekerbaarheid [{{ number }}] > Ambulant",
      HOSPITALISATION: "Verzekerbaarheid [{{ number }}] > Hospitalisatie",
      TITULARY_ID: "Verzekerbaarheid [{{ number }}] > ID titularis",
    },
    PATIENT_HCP: {
      REFERRAL: "Betrokkenen [{{ number }}] > Referentie",
      REFERRAL_PERIODS_END_DATE: "Betrokkenen [{{ number }}] > Einddatum",
      REFERRAL_PERIODS_COMMENT: "Betrokkenen [{{ number }}] > Nota",
    },
    PARTNERSHIPS: {
      TYPE: "Partnerschap [{{ number }}] > Type",
      STATUS: "Partnerschap [{{ number }}] > Status",
      PARTNERSHIP_DESCRIPTION: "Partnerschap [{{ number }}] > Omschrijving",
    },
    MEDICAL_HOUSE_CONTRACTS: {
      CHANGE_TYPE: "Wijkgezondheidscentrum [{{ number }}] > Type wijzigen",
      CHANGED_BY: "Wijkgezondheidscentrum [{{ number }}] > Veranderd naar",
      END_OF_CONTRACT: "Wijkgezondheidscentrum [{{ number }}] > Einde contract",
      END_OF_COVERAGE: "Wijkgezondheidscentrum [{{ number }}] > Einde dekking",
      END_OF_SUSPENSION:
        "Wijkgezondheidscentrum [{{ number }}] > Einde opschorting",
      FORCED_SUSPENSION:
        "Wijkgezondheidscentrum [{{ number }}] > Gedwongen opschorting",
      GP: "Wijkgezondheidscentrum [{{ number }}] > Huisarts",
      HCP_ID: "Wijkgezondheidscentrum [{{ number }}] > De betrokkene Id",
      KINE: "Wijkgezondheidscentrum [{{ number }}] > Kine",
      NO_GP: "Wijkgezondheidscentrum [{{ number }}] > Geen huisarts",
      NO_KINE: "Wijkgezondheidscentrum [{{ number }}] > Geen kine",
      NO_NURSE: "Wijkgezondheidscentrum [{{ number }}] > Geen Verpleegkundige",
      NURSE: "Wijkgezondheidscentrum [{{ number }}] > Verpleegkundige",
      START_OF_CONTRACT:
        "Wijkgezondheidscentrum [{{ number }}] > Begin contract",
      START_OF_COVERAGE:
        "Wijkgezondheidscentrum [{{ number }}] > Begin dekking",
      START_OF_SUSPENSION:
        "Wijkgezondheidscentrum [{{ number }}] > Begin opschorting",
      SUSPENSION_REASON:
        "Wijkgezondheidscentrum [{{ number }}] > Reden van opschorting",
      SUSPENSION_SOURCE:
        "Wijkgezondheidscentrum [{{ number }}] > Bron van opschorting",
      VALID_FROM: "Wijkgezondheidscentrum [{{ number }}] > Geldig van",
      VALID_TO: "Wijkgezondheidscentrum [{{ number }}] > Geldig tot",
    },
    FINANCIAL_INFO: {
      NAME: "´Financiële informatie [{{ number }}] > Naam",
      KEY: "´Financiële informatie [{{ number }}] > Sleutel",
      BIC: "´Financiële informatie [{{ number }}] > BIC",
      PROXY_BANK_ACCOUNT: "´Financiële informatie [{{ number }}] > Proxy IBAN",
      PROXY_BIC: "´Financiële informatie [{{ number }}] > Proxy BIC",
    },
  },
  "access-log": {
    LATEST_PATIENTS: "Laatst geopende patiënten",
    LAST_DATE: "Laatste toegangsdatum",
    OPEN_PATIENT: "Dossier patiënt openen",
    PATIENT_STATS: {
      TITLE: "Statistieken Sumehrs - {{ name }}",
      DMG_COUNT: "Aantal GMD die mij toebehoort",
      SUMEHR_SENT_COUNT: "Aantal verzonden Sumehrs",
      DMG_WITH_SUMEHR_SENT_COUNT:
        "Aantal GMD die mij toebehoren voor welke ik een sumehr heb verzonden",
      WARNING:
        "De hierboven voorgestelde cijfers hebben alleen betrekking op patiënten die in de huidige database zijn opgeslagen en kunnen verschillen van het officiële eHealth-account.",
    },
  },
  academic: {
    "mail-login": {
      content:
        "<b>Welkom bij Medispring!</b> <br/>Hallo {{hcpLastName}} {{hcpFirstName}},<br/>In  bijlage vindt u uw inloggegevens. <br/><br/> <b>Login</b>: {{userLogin}}<br/><b>Studentnummer:</b> {{studentNumber}}<br/><b>Wachtwoord:</b> {{password}}<br/> <b>Url:</b> <a href='https://academic.medispring.be'>https://academic.medispring.be</a><br/>Uw encryptiesleutel werd u toegestuurd in een aparte e-mail, deze is noodzakelijk om toegang te krijgen tot de geëncrypteerde gegevens die in Medispring. We raden u aan deze op een veilige plaats op te slaan.<br/><br/>Fijne ontdekking",
      subject:
        "Welkom bij Medispring {{hcpLastName}} {{hcpFirstName}} ({{studentNumber}}",
    },
    "mail-key": {
      content: "Hallo,<br/>Hierbij uw encryptiesleutel.",
      subject:
        "Uw Medispring-encryptiesleutel {{hcpLastName}} {{hcpFirstName}} ({{studentNumber}}",
    },
    error: {
      delegationConfig:
        "Aanmaak onmogelijk, delegatie-informatie ontbreekt in APPSETTING, neem contact op met de helpdesk",
    },
  },
  "guardPost-access-log": {
    LAST_VISITS: "Laatste bezoeken",
    CONNECT: "Verbind met eHealth om de verslagen te versturen.",
    X_REPORT_OK: "{{ c }} rapporten klaar om verzonden te worden",
    "1_REPORT_OK": "1 rapport klaar om verzonden te worden",
    X_SEND: "De {{ c }} rapporten versturen",
    "1_SEND": "Rapport versturen",
    FILTER_BY: "Filteren op",
    PMG_FILTER: "Watchpost",
    PATIENT: "Patiënt",
    GUARD_HCP: "Dokter van wacht",
    DEST_HCP: "Bestemmeling",
    CONSULT_DATE: "Datum van bezoek",
    STATUS: "Status",
    STATUT_WAIT_DEST: "In afwachting van bestemmeling",
    STATUT_WAIT_REPORT: "In afwachting van het rapport",
    STATUT_WAIT_SEND: "In afwachting van verzending",
    SEND: "versturen",
    SENT: "Verzonden",
    NO_DOCUMENT_IN_CONTACT:
      "Er is geen enkel document aanwezig in dit contact. Neem contact op met de dokter van wacht zodat deze het rapport kan voorbereiden.",
    ERROR_MARK_SENT: "Onmogelijk het rapport te markeren als verzonden",
    ERROR_NOT_SENT: "De boodschap is niet verzonden",
    ERROR: "Er is een fout opgetreden",
    ERROR_CONTACT_GET: "Onmogelijk de contacten te recupereren",
    ERROR_PATIENTGET: "Onmogelijk de gegevens van een patiënt te bekomen",
    ERROR_REF_HCP_GET:
      "Onmogelijk de gegevens van de behandelende arts van de patiënt te bekomen",
    ERROR_RESP_HCP_GET:
      "Onmogelijk om de gegevens van de verantwoordelijke van het contact te bekomen",
    ERROR_NO_DEST_NIHII:
      "De betrokken bestemmeling {{hcp}} heeft geen inami-nummer gedefinieerd op zijn fiche",
    ERROR_UPDATE_PATIENT: "Onmogelijk de patiënt te updaten",
    PATIENT_UPDATED: "Patiënt gewijzigd",
    AUTO_MAIL_SUBJECT: "RAPPORT",
    ALL: "Alle",
    TYPE: "Type consultatie",
    TYPES: {
      homevisit: "Bezoek op adres",
      telephonicconsultation: "Raadgeving/divers",
      consult: "Consultatie",
    },
    NO_REPORT: "Geen rapport",
  },
  "user-management": {
    EXPORT_FILENAME: "gebruikers_export",
    CURRENT_USER_EDIT_WARNING:
      "NL Veuillez éditer votre profil à partir de l'icône située en bas à gauche de Medispring",
    ALL_USERS: "Alle gebruikers",
    FIND_USER_BY_NAME: "Gebruiker zoeken op naam",
    NEW_USER: "Nieuwe gebruiker",
    NEW_USER_LIST: "Een lijst met gebruikers importeren (.csv)",
    EDIT: "Wijzigen",
    CREATE_USER: "Gebruiker creëren",
    EDIT_USER: "Gebruiker wijzigen",
    DISABLE_USER: "Gebruiker deactiveren",
    EXPIRED: "[Verstreken]",
    NAME: "Naam",
    LOGIN: "Login",
    ROLE: "Rol(len)",
    DEACTIVATED: "Gedeactiveerd",
    EH_CERT_VALIDITY: "Vervaldatum van het eHealth-certificaat",
    INCLUDE_INACTIVES: "Inactieve tonen",
    bulk: {
      TITLE: "Lijst gebruikers importeren",
      FILE_TEMPLATE: ".csv template downloaden",
      IMPORT_FILE: "Importeer bestand",
      CREATE_USERS: "",
      CLOSE: "Sluiten",
      columName: {
        type: "Type ",
        firstName: "Naam",
        lastName: "Voornaam",
        email: "E-mail",
        password: "Watchwoord",
        registrationNumber: "Registratie nummer",
        startYear: "Start jaar",
        admin: "Beheerder",
        roles: "Rollen",
        ssin: "Inss",
        nihii: "Riziv",
      },
      "user-type": {
        teacher: "Leraar",
        student: "Student",
        practician: "Practicus",
        secretary: "Secretaresse",
      },
      "user-preview": "Overzicht inhoud van het opgeladen bestand",
      "user-preview-loading": "Overzicht inhoud van het bestand wordt geladen",
      "user-creating": "Gebruiker wordt aangemaakt, wacht alsjeblieft",
    },
    export: {
      columnName: {
        name: "Naam",
        login: "Login ",
        nihii: "Riziv",
        ssin: "Inss",
        active: "Actief",
        roles: "Rollen",
        hub: "Hub ",
        ehealthExpiration: "Geldigheidsdatum van certificaat",
        registrationNumber: "Registratie nummer",
        startYear: "start jaar",
        street: "Straat",
        houseNumber: "Huisnummer",
        postalBox: "Bus",
        locality: "Stad",
        country: "Land",
        email: "E-mail",
        tel: "Telefoon",
        gsm: "GSM",
        stageMaster: {
          firstName: "Voornaam",
          lastName: "Naam",
          nihii: "Riziv",
          ssin: "Inss",
        },
      },
      hub: {
        WAL: "Wallonia",
        FLA: "Vlanderen",
        BRU: "Brussels",
      },
      status: {
        ACTIVE: "Actief",
        INACTIVE: "Inactief",
      },
    },
    EXPORT_USER_LIST: "Gebruikerslijst exporteren",
    EXPORTING_USER_LIST: "Huidige gebruikers exporteren",
  },
  "hcp-management": {
    HCPS: "Betrokkenen",
    FIND_BY_NAME: "Zoeken op naam",
    NEW_HCP: "Nieuwe betrokkene",
    DELETE: "Verwijderen",
    CONFIRM: "Wilt u deze betrokkene verwijderen?",
    PROCESSING: "Verwijderen van betrokkene...",
    DONE: "Betrokkene succesvol verwijderd.",
    NAME: "Naam",
    SPECIALITY: "Specialiteit",
    LAST_NAME: "Familienaam",
    FIRST_NAME: "Voornaam",
    CREATE_HCP: "Betrokkene creëren",
    EDIT_HCP: "Betrokkene wijzigen",
    EMAIL: "E-mail",
    LANDLINE: "Vaste telefoon",
    MOBILE: "GSM",
    ADRESSE: "Adres",
    PMG_MISSING_FIELD: "Het veld {{field}} ontbreekt voor uw wachtpost.",
    PMG_MISSING_FIELDS: "De velden {{field}} ontbreken voor uw wachtpost.",
    PRACTICIAN_MISSING_FIELD:
      "Het veld {{field}} van de dokter ontbreekt op uw gebruikersfiche.",
    PRACTICIAN_MISSING_FIELDS:
      "De velden {{field}} van de dokter ontbreken op uw gebruikersfiche.",
    ASSISTANTS_MISSING_FIELD:
      "Het veld {{field}} van de assistenten ontbreekt op uw gebruikersfiche.",
    ASSISTANTS_MISSING_FIELDS:
      "De velden {{field}} van de assistenten ontbreken op uw gebruikersfiche.",
    DELETION_NOT_ALLOWED:
      "Deze betrokkene kan niet worden verwijderd omdat hij verbonden is met een andere betrokkene.",
  },
  "he-management": {
    CONFIRMATION: "Bevestiging",
    SUGGESTED_ITEM: "Gesuggereerd ZE",
    CRITERIA_FOUND: "Criterium gevonden",
    SSIN: "Inss",
    PATIENT: "Patiënt",
    SUGGESTION_HE:
      "Suggesties van zorgelementen aan de dossier toe te voegen voor de covid vaccinatie actualiseren",
    TYPE: "Mogelijk probleem",
    AGE: "Leeftijd",
    SAVE: "Zorgelementen toevoegen",
    SAVING: "Creatie van zorgelementen",
    OR: "or",
    CREATION_OK: "Zorgelementen gecreëerd.",
    EXCLUDE: "Uitsluiting",
    DMG: "Dmg-eigenaar",
    FIND_BY_DMG_OWNER: "Zoek bij dmg eigenaar",
    criteria: {
      DRUG_CHRON_CLASS: "Chronische drug van de klas",
      DRUG_CLASS: "Drug van de klas",
      GLY: "Bloedsuiker vasten",
      NGSP: "Geglycosyleerd hemoglobine (ngsp)",
    },
    type: {
      cardiovascularProblems: "Chronische cardiovasculaire problemen",
      diabetes: "Diabetes ",
      obesityProblems: "Zwaarlijvigheid",
      respiratoryProblems: "Chronische ademhalingsproblemen",
      renalFailure: "Nierfalen",
    },
  },
  "scan-import-management": {
    TITLE: "Scans importeren",
    LAST_IMPORT: "Laatste import",
    SEARCH_AND_IMPORT: "Opzoeken en importeren",
    PATH: "Pad",
    NO_PATH_SET: "Geen pad",
    EDIT: "Wijzigen",
    NO_IMPORT_YET: "Geen enkele import op dit moment",
    FILES_RETRIEVED_FROM_DISK: "Bestand gerecupereerd van schijf",
    MODIFY_RESPONSIBLE: "Verantwoordelijke wijzigen",
    MODIFY_DATE: "Datum wijzigen",
    DO_NOT_IMPORT: "Niet importeren",
    IMPORT: "Importeren",
    MISSING_PATIENT: "Patiënt ontbreekt",
    READY_FOR_IMPORT: "Klaar voor importeren",
    DUPLICATES_AND_FAILED: "Verstreken en dubbels",
    NO_FILES_TO_IMPORT: "Geen enkel bestand voor import",
    ONLY_AVAILABLE_ON_DESKTOP_APP: "Enkel beschikbaar op desktopapplicatie",
    SELECT_DATE: "Datum kiezen",
  },
  "mycarenet-management": {
    TITLE: "Conversaties MyCareNet",
    SEARCH: "Opzoeken op referenties",
  },
  "statistics-management": {
    TITLE: "Statistieken",
    RASH: "RASH",
    RLM: "LZ",
    PMG: "Wachtpost",
    PMGCSV: "Wachtpost (CSV)",
    PMGAGENDA: "Wachtpost Agenda",
    PMGAGENDACSV: "Wachtpost Agenda (CSV)",
    SELECT_DATE_RANGE: "Een periode selecteren",
    SELECT_TYPE: "Type selecteren",
    FROM: "Van",
    TO: "tot",
    DOWNLOAD: "Downloaden",
    LOADING_HCP: "Laden van actieve betrokkenen",
    HCP_STATISTICS_WILL_BE_GENERATED_FOR:
      "Betrokkenen waarvoor statistieken worden gegenereerd",
    SHOULD_USE_EHEALTH: "eHealth gebruiken",
  },
  "current-user": {
    USER_DETAILS: "Details van de gebruiker",
    CONNECTED: "U bent verbonden met de eHealth-diensten.",
    DISCONNECTED: "U bent niet verbonden met de eHealth-diensten.",
  },
  "form-selector": {
    USER: "Gebruiker",
    HCP: "Betrokkene",
    PRIVATE_KEY: "Privésleutel",
    CERTIFICATE: "Certificaat",
    DMG: "eGMD",
    LOGOUT: "Uitloggen",
    CLEAN_DATA_ON_LOGOUT: "Mijn privé-gegevens wissen",
    E_HEALTH: "Verbinding met eHealth",
    E_DMG: "eGMD",
    EFACT: "eFact",
    LICENCE: "Licentie",
    UNSAVED_CHANGES: "Het huidige formlier bevat niet-bewaarde gegevens.",
    E_LABO: "eLabo",
    FLATRATE: "eForfait ",
  },
  "eh-menu": {
    TITLE_MAIN: "eHealth",
    CONNEXION: "Verbinding",
    E_DMG: "eGMD",
  },
  "eh-cert-import": {
    CERTIFICATE: "eHealth-certificaat",
    STATUS: {
      IMPORTED: "Geïmporteerd",
      MISSING: "Ontbrekend",
    },
    IMPORT_YOUR_CERTIFICATE: "Importeer uw eHealth-certificaat:",
    SELECT_FILE: "Kies een bestand",
    CONNECTION: "Verbinding met eHealth",
    CONNECTION_BUTTON: "Verbinden met eHealth",
    PASSWORD: "Wachtwoord eHealth",
    CERT_UPLOAD_SUCCESS: "eHealth-certificaat succesvol verzonden.",
    CERT_UPLOAD_FAILURE:
      "Het versturen van het eHealth-certificaat is mislukt.",
    CONNECTED: "Verbonden",
    NOT_CONNECTED: "Niet verbonden",
    INVALID_CERTIFICATE: "Ongeldig certificaat",
    ERROR_SIZE:
      "De grootte van uw certificaat lijkt niet redelijk. De grootte ervan (momenteel {{actual}} bytes) moet tussen {{min}} en {{max}} liggen.",
    ERROR_DATE:
      "De datum van uw certificaat is niet binnen de laatste {{y}} jaren",
    ERROR_SSIN:
      "Het INSZ van het certificaat ({{p12Ssin}}) komt niet overeen met uw ({{hcpSsin}})",
    GUARDPOST_MODE: "Verbinding als wachtdienst",
    VALIDITY: "Geldigheid",
    CERTIFICATE_NAME: "Naam van het certificaat",
    END_OF_VALIDITY: "Geldigheidsdatum",
    INSTRUCTIONS: "Instructies vóór verbinding",
    VALIDITY_WARNING_TEXT:
      "Opgelet, uw eHealth-certificat verstrijkt in minder dan een maand.",
    VALIDITY_ERROR_TEXT:
      "Uw eHealth-certificaat is verstreken, u moet het vernieuwen.",
    CERTIFICATE_RENEW_LINK_TEXT: "Vraag een nieuw eHealth-certificaat aan.",
    CERTIFICATE_RENEW_LINK_VALUE:
      "https://www.ehealth.fgov.be/nl/egezondheid/beroepsbeoefenaars-in-de-gezondheidszorg/beheer-van-de-ehealth-certificaten",
  },
  "private-key-import": {
    PRIVATE_KEY: "Privé sleutel",
    STATUS: {
      IMPORTED: "Geïmporteerde sleutel: {{hcpId}}",
      MISSING: "Privésleutel ontbreekt",
      CRYPTED: "Geïncrypteerde privésleutel",
    },
    IMPORT_YOUR_PRIVATE_KEY: "Importeer uw privésleutel ({{hcpId}}) :",
    SELECT_FILE: "Bestand kiezen",
    QR_IMPORT: {
      START: "Scan starten",
      CANCEL: "Annuleren",
      RESTART: "Herbeginnen",
      FAILED: "De scan is mislukt.",
    },
    DOWNLOAD_KEY: "Privésleutel downloaden",
    DELETE_KEY: "Privésleutel verwijderen",
    KEY_FILES_IMPORTED: "Sleutels succesvol geïmporteerd.",
    KEY_FILE_INVALID: "Ongeldig sleutelbestand.",
    CANCEL_IMPORT: "Annuleren",
    EXPORT_ALL: "Alles exporteren",
    DOWNLOAD_FILE: "Bestand downloaden",
  },
  "key-import-qr-code": {
    NUMBER_OF_CODES: "Aantal QR-codes",
    UNKNOWN_NUMBER: "Onbekend",
    SCANNED_CODES: "QR-codes gerecupereerd",
    ERROR: {
      NO_DEVICE: "Geen camera beschikbaar.",
      SCAN_FAILED: "De scan van de QR-code is mislukt",
      SCAN_ERRORED: "Er is een fout opgetreden tijdens het scannen",
    },
  },
  "key-export-qr-code": {
    PRINT_KEY: "Sleutel afdrukken in formaat van QR-code",
    DOWNLOAD_KEY: "Sleutel downloaden in formaat van QR-code",
    PDF: {
      KEY_FOR: "Privésleutel voor {{hcpId}}",
      PART_N: "Deel {{n}}",
      INSTRUCTIONS:
        "Dit document bevat gevoelige informatie. Bewaar het op een zekere en veilige plek.",
    },
  },
  "key-pair-generation": {
    TITLE: "Welkom in de Medispring-applicatie",
    GENERATE_KEYS: "Mijn sleutelpaar genereren",
    CLOSE: "Sluiten",
    SUCCESS_MESSAGE_ENCRYPTED:
      "Uw sleutelpaar is met succes gegenereerd en opgeslagen in uw profiel.",
    SUCCESS_MESSAGE:
      "Uw publiek en privé sleutelpaar is met succes gegenereerd en gedownload in het bestand",
    FAILURE_MESSAGE_ENCRYPTED:
      "De registratie van uw sleutelpaar in uw profiel is mislukt!",
    FAILURE_GENERATION: "De generatie van je sleutelpaar is mislukt.",
  },
  help: {
    TITLE: "Hulp",
    ALERT_INDEXATION: "Er is een indexatie bezig, even geduld.",
    TITLE_INDEXATION: "Indexatie bezig",
    TITLE_NEW_VERSION: "Nieuwe update",
    HELPDESK: "HelpDesk",
    PHONE: "Telefoon",
    MAIL: "E-mail",
    PHONE_VALUE: "078/077050 (gratis)",
    PMG: {
      PHONE_VALUE_OFF: "078/481.086",
      PHONE_VALUE_OFF_HOURS: "(week: 18h-22h, WE: 8h-20h)",
      PHONE_VALUE_BUSINESS: "078/077.050",
      PHONE_VALUE_BUSINESS_HOURS: "(week: kantooruren)",
    },
    MAIL_VALUE: "support@medispring.be",
    FAQ: "FAQ",
    FAQ_VALUE: "https://medispring.zendesk.com/hc/nl-be",
    FAQ_VIEW: "Vragen/antwoorden bekijken",
    YOUTUBE: "Raadpleeg de video's op ons Youtube-kanaal",
    YOUTUBE_VALUE: "https://www.youtube.com/channel/UCDMPh0v-Kq76Ph0KVDk2hIA",
    YOUTUBE_VIEW: "Bekijk het Youtube-kanaal",
    VERSION: "Medispring-versie",
    VERSION_REF: "Versiereferentie",
    VERSION_VIEW: "Nieuwigheden bekijken",
    LINK: "Link",
    LOCAL: "Lokaal",
    REMOTE: "OP afstand",
    STATUS: "Status",
    DOC_COUNT: "# Documenten",
    LATEST_REPLICATION: "Laatste replicatie",
    UPDATE: "Update",
    UPDATE_VIEW: "Updates bekijken",
    MANUAL: "Handleiding Medispring",
    MANUAL_VALUE:
      "https://medispring.zendesk.com/hc/nl-be/categories/360001208551-FAQ",
    "ADMIN-TOOLS": "Administratietools",
    RELEASE_NOTES_VALUE:
      "https://medispring.zendesk.com/hc/nl-be/categories/360004554991-Versie-notities-release-notes",
    academic: {
      VERSION: "Academisch versie van Medispring",
    },
  },
  "hcp-form": {
    PAYMENT_TYPE: "Type perceptie: ",
    PAYMENT_TYPE_UNDEFINED: "Niet gedefinieerd",
    PAYMENT_TYPE_INDIVIDUAL: "Individueel",
    PAYMENT_TYPE_CENTRALIZED: "Gecentraliseerd",
    ASSISTANTS_BANK_ACCOUNT: "Gedeelde bankrekening van assistenten",
    FIND_HCP: "Betrokkene zoeken",
    BASIC_INFO: "Algemene informatie",
    CONTACT: "Contact",
    FIRST_NAME: "Voornaam",
    LAST_NAME: "Familienaam",
    GENDER: "Geslacht",
    PROFESSION: "Beroep",
    SPECIALITY: "Specialiteit",
    SSIN: "Rijksregisternummer",
    NIHII: "RIZIV-nummer",
    OTHER: "Andere",
    NOTES: "Opmerkingen",
    MASTER: "Stagemeester",
    ADDRESSES: "Adressen",
    ADDRESS: {
      TYPE: "Type",
      DESCRIPTION: "Omschrijving",
      STREET: "Straat",
      HOUSE_NUMBER: "Nummer",
      POSTBOX_NUMBER: "Bus",
      POSTAL_CODE: "Postcode",
      CITY: "Stad",
      COUNTRY: "Land",
      TELECOMS: "Telecoms",
      ADDRESS: "Adress",
      PHONE: "Telefoon",
      EMAIL: "e-mail",
    },
    CREATE_HCP: "Betrokkene creëren",
    EDIT_HCP: "Betrokkene wijzigen",
    EDIT_SUCCESS: "Betrokkene bijgewerkt.",
    EDIT_FAILURE: "Het bijwerken van de betrokkene is mislukt.",
    CREATE_SUCCESS: "Betrokkene gecreëerd.",
    CREATE_FAILURE: "De creatie van de betrokkene is mislukt.",
    CREATE_TELECOM: "Nieuwe telecom",
    CREATE_ADDRESS: "Nieuw adres",
    INSURABILITY: "Verzekerbaarheid",
    SOCIETY_NAME_ABR: "Naam van de firma (afkorting)",
    SOCIETY_NAME: "Naam van de firma",
    MEDECIN_CONVENTION: "Conventie",
    YES: "Ja",
    NO: "Nee",
    PARTIAL: "Gedeeltelijk",
    IBAN: "IBAN",
    BIC: "BIC",
    CBE: "KBO",
    BANK_ACCOUNT: "IBAN",
    FACTURATION: "Facturatie",
    HCP_LIST: "Lijst van zorgverstrekkers",
    CBE_REQUIRED:
      "Het KBO-nummer is noodzakelijk om de eFact-loten te versturen.",
    IBAN_REQUIRED:
      "Het IBAN-nummer is noodzakelijk om de eFact-loten te versturen.",
    ASSISTANTS_IBAN_REQUIRED: "IBAN van de assistenten niet gespecificeerd.",
    ASSISTANTS_BIC_REQUIRED: "BIC van de assistenten niet gespecificeerd.",
    BIC_REQUIRED: "Het BIC-nummer is noodzakelijk om eFact-loten te versturen.",
    GUARDPOST_SSIN: "Rijksregisternummer van de wachtpost",
    GUARDPOST_NIHII: "RIZIV-nummer van de wachtpost",
    GUARDPOST_NAME: "Naam van de wachtpost",
    GUARDPOST_IBAN: "IBAN-nummer van de wachtpost",
    GUARDPOST_BIC: "BIC-nummer van de wachtpost",
    GUARDPOST_CBE: "KBO-nummer van de wachtpost",
    VACCINNET_GROUPID: "Vaccinnet groepspraktijk id",
    SPECIALITIES: {
      ADMINISTRATION: "Administratie",
      CARE_GIVER: "Zorgverlener",
      ANATOMOPATHOLOGY: "Anatomopatologie",
      ANESTHESIOLOGY: "Anesthesiologie",
      SOCIAL_WORKER: "Sociaal assistent",
      PHARMA_ASSISTANT: "Farmaceutisch-technisch assistent",
      AUDIOLOGIST: "Audioloog",
      BACTERIOLOGY: "Bacterioloog",
      TRUSS_ORTHOTIST: "Bandagist-orthopedisch instrumentmaker",
      CLINICAL_BIOLOGY: "Klinische biologie",
      CARDIOLOGY: "Cardiologie",
      GENERAL_SURGERY: "Algemene chirurgie",
      MAXILLOFACIAL_SURGERY: "Maxillofaciale chirurgie",
      PLASTIC_SURGERY: "Plastische chirurgie",
      STOMATOLOGICAL_SURGERY: "Stomatologische chirurgie",
      CARDIOVASCULAR_SURGERY: "Cardio-vasculaire chirurgie",
      ABDOMINAL_SURGERY: "Abdominale chirurgie",
      DENTISTRY: "Tandheelkunde",
      DERMATOLOGY: "Dermatologie",
      DIETETIC: "Dieetkunde",
      ENDOCRINOLOGY: "Endocrinologie ",
      OCCUPATIONAL_THERAPIST: "Ergotherapeut",
      GASTROENTEROLOGY: "Gastro-enterologie",
      GENETIC: "Genetica",
      GERIATRICS: "Geriatrie",
      GYNECOLOGY: "Gynaecologie / Verloskunde",
      HEMATOLOGY: "Hematologie",
      NURSE: "Verpleegkundige/verpleegster",
      NURSE_EDUCATOR: "Verpleegkundige opvoeder",
      PHYSIOTHERAPY: "Kinesitherapie",
      SPEECH: "Logopedie",
      ACUTE_MEDICINE: "Acute geneeskunde",
      GENERAL_MEDICINE: "Algemene geneeskunde",
      persphysician: "Algemene geneeskunde",
      INTERNAL_MEDICINE: "Interne geneeskunde",
      NUCLEAR_MEDICINE: "Nucleaire geneeskunde",
      PHYSICAL_MEDICINE: "Fysiotherapie",
      EXPERTISE_MEDICINE: "Deskundige geneeskunde",
      INSURANCE_MEDICINE: "Verzekeringsgeneeskunde",
      TRAVEL_MEDICINE: "Reisgeneeskunde",
      NEONATOLOGY: "Neonatologie",
      NEPHROLOGY: "Nefrologie",
      NEUROSURGERY: "Neurochirurgie",
      NEUROLOGY: "Neurologie",
      NEUROPEDIATRICS: "Neuropediatrie",
      NEUROPSYCHIATRY: "Neuropsychiatrie ",
      NUTRITIONIST: "Nutritionist(e)",
      ONCOLOGY: "Oncologie",
      OPHTHALMOLOGY: "Oogheelkunde",
      ORTHOPEDICS: "Orthopedie",
      ORTHOPTISTE: "Orthoptist",
      OTORHINOLARYNGOLOGY: "Otorhinolaryngologie",
      PEDIATRICS: "Pediatrie",
      PHARMACOLOGY: "Farmacologie",
      RESPIRATORY: "Pneumologie",
      PODIATRIST: "Podoloog",
      PUBLIC_HEALTH_PREVENTION: "Preventie van volksgezondheid",
      PROSTHETIST: "Prothesist",
      PSYCHIATRY: "Psychiatrie ",
      MEDICAL_PSYCHOLOGY: "Medische psychologie",
      RADIODIAGNOSTICS: "Radiodiagnostiek / Medische beeldvorming",
      RADIOTHERAPY: "Radiotherapie",
      RESUSCITATION: "Reanimatie",
      RHEUMATOLOGY: "Reumatologie",
      MIDWIFE: "Vroedvrouw",
      STOMATOLOGY: "Stomatologie",
      EMERGENCY: "Urgenties",
      UROLOGY: "Urologie",
      VIROLOGY: "Virologie",
      TOXICOLOGY: "Toxicologie",
      TRANSPORT: "Ziektetransport",
      ALGOLOGY: "Algologie",
      GUARDHOUSE: "Wachtdienst",
      REVALIDATION: "Revalidatien",
      SENOLOGY: "Senologie",
      DIABETOLOGY: "Diabetologie",
      INFECTIOLOGY: "Besmettelijke ziektes",
      KINESIOLOGY: "Kinesiologie",
      PHYSIOTHERAPYMICRO: "Kinesitherapie: micro",
      OSTHEAOPATIA: "Osteophatie",
      PSYCHOTHERAPY: "Psychotherapie",
      OTHER: "Andere",
      HYPNOSIS: "Hypnose",
      PERSPHYSICIAN: "Arts",
      NO_SPECIALITY: "Geen specialiteit",
      HEPATOLOGY: "Hepatologie",
      WORKMEDECINE: "Arbeidsgeneeskunde",
      SCHOOLMEDECINE: "Schoolgeneeskunde",
      PALLIATIVECARE: "Palliatieve zorg ",
      "NEUROPSYCHOLOGY": "Neuropsychologie",
      "PAYING_AGENCY": "Betalingsinstantie",
      "RECEPTION": "Onthaal",
      "MOBILITY_IMPROVER": "Orthopedisch technoloog (mobiliteitsverbeteraar)",
      "BANDAGIST_ORTHOSIOLOGIST": "Orthopedisch technoloog (bandagist orthosioloog)",
      "SHOE_TECHNOLOGIST": "Orthopedisch technoloog (schoentechnicus)",
      "ORAL_DENTAL_HYGIENIST": "Mondhygiënist",
      "ORTHOPEDIST": "Orthopedist",
      "BANDAGIST": "Bandagist",
      "OPTICIAN": "Opticien",
      "AUDICIAN": "Audicien",
      "PHARMACIST_BIOLOGIST": "Apotheker-klinisch bioloog",
      "PSYCHOLOGY": "Psychologie",
      "GERONTOLOGY_MASTER": "Master in de gerontologie",
      "ORTHOPEDAGOGY_MASTER": "Master in de orthopedagogie",
      "FAMILY_SCIENCE_BACHELOR": "Bachelor in de gezinswetenschappen",
      "REHABILITATION_BACHELOR": "Bachelor in de readaptatiewetenschappen",
      "PSYCHOMOTOR_THERAPY_MASTER": "Master in de psychomotorische therapie",
      "CLINICAL_ORTHOPEDAGOGIST": "Klinisch orthopedagoog",
      "PERSCAREGIVER": "Zorgverstrekker",
      "PERSNURSE": "Verpleegkundige",
      "APPLICATION": "Toepassing",
      "DEPTPHYSIOTHERAPY": "Fysiotherapie",
      "PERSADMINISTRATIVE": "Administratieve medewerker",
      "PERSSOCIALWORKER": "Maatschappelijk werker",
      "DEPTEMERGENCY": "Spoedgevallen",
      "DEPTORTHOPEDY": "Orthopedie",
      "DEPTGASTROENTEROLOGY": "Gastro-enterologie",
      "PERSEDUCATOR": "Opvoeder",
      "ORGPREVENTION": "Preventiedienst",
      "SLEEP_MEDICINE": "Slaapgeneeskunde",
      "CHILD_AND_ADOLESCENT_PSYCHIATRY": "Kinder- En jeugdpsychiatrie",
      "PSYCHOGERIATRY": "Psychogeriatrie",
      "CLINICAL_PSYCHOLOGIST": "Klinisch psycholoog",
      "INTENSIVE_CARE": "Intensieve zorgen",
      "MAJOR_BURNS": "Brandwondencentrum",
      "SHELTERED_LIVING": "Initiatief voor beschut wonen",
      "HOSPITAL": "Ziekenhuis",
      "RETIRED_HOME": "Woonzorgcentrum",
      "PSYCHIATRIC_CARE_HOME": "Psychiatrische verzorgingstehuis",
      "PRIMARY_HEALTH_CARE_CENTER": "Medisch huis",
      "SPORTS_MEDICINE": "Sportgeneeskunde",
      "TROPICAL_MEDICINE": "Tropische geneeskunde",
      "OBSTETRICS": "Verloskunde",
      "PATIENT": "Patient",
      "POLYCLINIC": "Polikliniek",
      "CARDIAC_SURGERY": "Hartchirurgie",
      "HAND_SURGERY": "Handchirurgie",
      "PEDIATRIC_SURGERY": "Kinderchirurgie",
      "THORACIC_SURGERY": "Thoraxchirurgie",
      "VASCULAR_SURGERY": "Vasculaire chirurgie",
      "EDUCATOR": "Opvoeder",
      "PSYCHOMOTRICITY": "Psychomotriciteit",
      "INFORMAL_CAREGIVER": "Mantelzorger"
    },
    INSURANCE_CLAIM: "Verzekeringsdossier",
  },
  "telecom-form": {
    NUMBER: "Telecom",
    TYPE: "Type",
    DESCRIPTION: "Omschrijving",
  },
  "user-form": {
    NAME: "Naam",
    LAST_NAME: "Familienaam",
    FIRST_NAME: "Voornaam",
    STUDENT_NUMBER: "Student nummer",
    START_YEAR: "Startjaar",
    LOGIN: "Login",
    NAME_PLACEHOLDER: "Gebruikersnaam",
    EMAIL: "E-mail",
    EMAIL_PLACEHOLDER: "E-mail van de gebruiker",
    ROLES: "Rollen",
    ROLES_PLACEHOLDER: "Rollen van de gebruiker",
    PASSWORD: "Wachtwoord",
    CONFIRM_PASSWORD: "Bevestiging van wachtwoord",
    ACTIVE: "Actief",
    CREATE_USER: "Gebruiker creëren",
    EDIT_USER: "Gebruiker wijzigen",
    EDIT_SUCCESS: "Gebruiker bijgewerkt.",
    EDIT_FAILURE: "Het bijwerken van de gebruiker is mislukt.",
    CREATE_SUCCESS: "Gebruiker gecreëerd.",
    CREATE_FAILURE: "Het creëren van de gebruiker is mislukt.",
    INVALID_ROLES_REMOVED:
      "Onbekende rollen van de gebruiker werden verwijderd ({{roles}}).",
    SAME_ADR_SEARCH: {
      title: "Activeer zoeken 'onder hetzelfde dak wonen'",
    },
    tooltip: {
      MS_ADMIN:
        "De beheerder-rol geeft toegang tot het creëren, het wijzigen en het raadplegen van statistieken en boekhouding   voor alle gebruikers",
      MS_PRACTICIAN:
        "De practicus- rol geeft toegang tot medische gegevens en de mailbox",
      MS_SECRETARY:
        "De secretaresse-rol geeft toegang tot de patiënten administratieve fiches en de mailbo",
      MS_USER_MANAGER:
        "De gebruikersbeheer-rol geeft toegang tot het creëren en wijzigen van gebruikers",
      MS_PRESCRIPTOR:
        "De voorschrijver-rol  geeft toegang tot het creeren van voorschriften in afwachting en patiënten actieve behandelingen",
      MS_STUDENT_PRACTICIAN:
        "De student-zorgverstrekker-rol geeft toegang tot medische patiëntgegevens die door de student zelf zijn aangemaakt en tot de inbox.",
    },
    HUB_REGION: "Regio Hubs",
    WAL: "Wallonië",
    FLA: "Vlaanderen",
    BRU: "Brussel",
    RSW: "RSW",
    RSB: "RSB",
    COZ: "Cozo + Vitalink",
    AUTOMATIC_SUMEHR:
      "Automatische verzenden van SUMEHR bij afsluiten patiënt?",
    YES_DONT_SHOW: "Ja, geen bevestiging vragen",
    YES_SHOW: "Ja, altijd bevestiging vragen",
    No: "Nee",
  },
  "location-selector": {
    CHOOSE_LOCATION: "Selecteer uw entiteit",
    RESPONSIBLE: "Verantwoordelijke",
    VALIDATE: "Valideren",
    CANCEL: "Annuleren",
  },
  "eh-dmg": {
    ALL: "Alle",
    INSCRIPTION: "Inschrijving",
    IBAN: "IBAN",
    BIC: "BIC",
    DATE_ACTIVATION: "Activeringsdatum",
    VALIDATE: "Inschrijvingsaanvraag",
    CHECK: "Inschrijving controleren",
    SEVEN_MUTUALITY: "Ik gebruik dezelfde bankrekening voor de 7 mutualiteiten",
    COND_TITLE: "Gebruiksvoorwaarden voor MyCareNet en GMD",
    READ_AND_ACCEPTED:
      "Ik heb de gebruiksvoorwaarden voor MyCareNet en GMD gelezen en aanvaard deze.",
    CONDA:
      "Zodra hij/zij de keuze maakt om MyCareNet te gebruiken voor het beheer van het GMD, verbindt de zorgverstrekker zich tot het volgende:",
    CONDA1:
      "Geen MycareNet-melding sturen voor een GMD die hij al heeft geattesteerd aan de patiënt (via de nomenclatuurcode 102771).",
    CONDA2:
      "Geen GMD meer verklaren via de nomenclatuurcode 102771 vanaf de dag na de inschrijvingsdatum, noch voor openingen, noch voor verlengingen.",
    CONDA3:
      "Het versturen van MyCareNet-medingen gebruiken voor elke opening van een nieuwe GMD.",
    CONDA4:
      "De MyCareNet-meldingen versturen binnen max. 7 werkdagen na de prestatie (consultatie of bezoek) waarop het GMD recht heeft.",
    CONDB:
      "Voor een zorgprestatie ingeschreven in MyCareNet voor het beheer van het GMD, verbindt de verzekeringsinstantie zich tot het volgende:",
    CONDB1:
      "Rekening houden met de inschrijvingsmelding verstuurd door een zorgverstrekker als engagement van de prestataris om het systeem MyCareNet te gebruiken voor het beheer van al zijn GMD's.",
    CONDB2:
      "Vanaf de datum van inschrijving bij MyCareNet: binnen 30 dagen alle administratieve verlengingen voor het lopende en het vorige jaar gebruiken wanneer de voorwaarden vervuld zijn.",
    CONDB3:
      "Een automatische en versnelde verlenging doen voor alle GMD's waarvoor de voorwaarden vervuld zijn, binnen 30 dagen vanaf ontvangst van de verklaring van de prestatie (consultatie/bezoek).",
    CONDB4:
      "De openingen van GMD's betalen binnen 30 dagen vanaf de ontvangst van de melding van MyCareNet als de voorwaarden voldaan zijn.",
    CONDB5:
      "De GMD-verklaringen met betrekking tot prestaties waarvoor de datum later is dan de inschrijvingsdatum in MyCareNet, weigeren.",
    CONDB6: "Boodschappen ivm sluiting en verlenging verstandig versturen.",
  },
  "eh-auth": {
    MISSING_USER: "Huidige gebruiker ontbreekt.",
    MISSING_EH_CERTIFICATE: "eHealth-certificaat ontbreekt.",
    MISSING_NO_HCP: "Geen enkele betrokkene.",
    CONNECTION_SUCCESS: "Verbinding met eHealth-diensten geslaagd.",
    MISSING_KEY_STORE_ID: "Sessie verstreken.",
    NOT_CONNECTED:
      "U bent niet verbonden met de eHealth-diensten.</br>{{errorMessage}}",
    CONNECTED: "U bent verbonden met de eHealth-diensten.",
    CONNECTED_NO_NIHII:
      "U bent verbonden met de eHealth-diensten. Geen inami-nummer ingevoerd in uw gebruikersbestand",
    SOMETHING_WRONG_HAPPENED:
      "Controleer uw certificaat en/of uw wachtwoord.</br>Controleer uw internetverbinding.",
    BAD_PASSWORD: "Wachtwoord incorrect.",
    MISSING_EH_PASSWORD: "Het wachtwoord is leeg.",
    ERROR_CERT_INFO: "Onmogelijk de informatie van het certificaat te bekomen.",
    CERTIFICATE_ALMOST_EXPIRED:
      "Opgelet, uw eHealth-certificaat verstrijkt binnen minder dan een maand.",
    CERTIFICATE_EXPIRED:
      "Uw eHealth-certificaat is verstreken, u moet dit vernieuwen.",
    NOT_ALLOWED:
      "De huidige eHealth-verbinding ondersteunt deze actie niet. Loginmodus: '{{mode}}'; Wachtdienstflow: '{{guardFlow}}'",
    NOT_ALLOWED_SECTOR: "Niet beschikbaar voor deze sector",
  },
  "pdf-export": {
    EXPORT: "Exporteren",
    EXPORT_FILE: "Exporteren data",
    EXPORT_INP: "Exporteren bezig",
    TITLE: "Export van patiëntendossier naar PDF-formaat",
    NO_DATA: "Geen data",
    note: "Nota",
    "vaccination-date": "Vaccinatie datum",
    "start-date": "Startdatum",
    "end-date": "Einddatum",
    "value-date": "Datum",
    "general-data": "Algemene data",
    address: "adres(sen)",
    status: "toestand",
    "insurability-data": "Verzekerbaarheidsgegevens",
    "last-modification": "Laatste aanpassing",
  },
  _ENUM_: {
    "patient-blood-type": {
      "blood-type": {
        A: "A",
        B: "B",
        O: "O",
        AB: "AB",
        UNDETERMINED: "Onbepaald",
      },
      "sub-blood-type": {
        CCDee: "CCDee",
        CcDee: "CcDee",
        ccDEE: "ccDEE",
        CcDEe: "CcDEe",
        ccDee: "ccDee",
        Ccdee: "Ccdee",
        ccDEe: "ccDEe",
        UNDETERMINED: "Non déterminé",
      },
      rhesus: {
        PLUS: "Rh +",
        MINUS: "Rh -",
        UNDETERMINED: "Onbepaald",
      },
    },
    telecom: {
      type: {
        mobile: "GSM",
        phone: "Vaste telefoon",
        email: "E-mail",
        fax: "Fax",
        skype: "Skype",
        im: "Instant messaging",
        medibridge: "MediBRIDGE",
        ehealthbox: "eHealthBox",
        apicrypt: "APICRYPT",
        web: "Web",
        print: "Printer",
        disk: "Schijf",
      },
    },
    address: {
      type: {
        home: "Thuis",
        work: "Werk",
        vacation: "Vakantie",
        hospital: "Hospitaal",
        clinic: "Kliniek",
        hq: "Hoofdkwartier",
        temporary: "Voorlopig",
        postal: "Postaal",
        diplomatic: "Diplomatiek",
        reference: "Referentie",
        other: "Andere",
      },
    },
    hcparty: {
      gender: {
        male: "Man",
        female: "Vrouw",
        unknown: "Onbekend",
        indeterminate: "Onbepaald",
        changed: "Gewijzigd",
        changedToMale: "Gewijzigd naar man",
        changedToFemale: "Gewijzigd naar vrouw",
        m: "Man",
        f: "Vrouw",
      },
    },
    "health-element": {
      nature: {
        medical: "Medisch probleem",
        surgical: "Chirurgisch probleem",
        gynecological: "Gynaeco-verloskundig probleem",
        allergy: "Allergie",
        familyrisk: "Familiale voorgeschiedenis",
        risk: "Medische risicofactor",
        socialrisk: "Sociale risicofactor",
        adr: "Intolerantie",
        null: "Onbekend",
      },
      status: {
        "active-relevant": "Actief (pertinent)",
        "active-irrelevant": "Actief (zonder pertinentie)",
        inactive: "Antecedent",
        archived: "Gearchiveerd",
      },
      certainty: {
        probable: "Waarschijnlijk",
        proven: "Bewezen",
        unprobable: "Twijfelachtig",
        excluded: "Uitgesloten",
        undefined: "Niet gedefinieerd",
      },
      severity: {
        normal: "Normaal",
        verylow: "Zeer zwak",
        low: "Zwak",
        high: "Hoog",
        veryhigh: "Zeer hoog",
      },
      temporality: {
        chronic: "Chronisch",
        subacute: "Sub-acuut",
        acute: "Acuut",
        remission: "In remissie",
      },
      laterality: {
        left: "Links",
        right: "Rechts",
        bilateral: "Bilateraal",
      },
      carepath: {
        "carepath-renalfailure": "Nierinsufficiëntie",
        "carepath-prediabetes": "Voortraject diabetes",
        "carepath-diabetes2": "Diabetes type 2",
      },
      confidentiality: {
        "not-confidential": "Niet vertrouwelijk",
        "no-export": "Geen export (zichtbaar voor mijn groepspraktijk)",
      },
    },
    role: {
      MS_ADMIN: "Beheerder",
      MS_PRACTICIAN: "Zorgverstrekker",
      MS_SECRETARY: "Onthaalmedewerker",
      MS_USER_MANAGER: "Gebruikersbeheerder",
      MS_PRESCRIPTOR: "Voorschrijver",
      MS_STUDENT_PRACTICIAN: "Student zorgverstrekker",
    },
    prescription: {
      SPE: "Specialiteiten",
      OTC: "OTC",
      MAP: "Magistrale bereidingen",
      MAF: "FTM-magistrale bereidingen",
      DCI: "Op stofnaam",
      CLA: "Klasse",
      PER: "[Uit Epicure]",
    },
    period: {
      d: "Dagen",
      wk: "Weken",
      mo: "Maanden",
      a: "Jaren",
      age: "Leeftijd",
    },
    period_simple: {
      d: "dag",
      wk: "week",
      mo: "maand",
      a: "jaar",
    },
    period_simple_posology_1: {
      d: "alle dagen",
      wk: "alle weken",
      mo: "alle maanden",
      a: "elk jaar",
    },
    period_simple_posology_n: {
      d: "om de {{n}} dagen",
      wk: "om de {{n}} weken",
      mo: "om de {{n}} maanden",
      a: "om de {{n}} jaar",
    },
    filter: {
      planOfAction: "Actieplan:",
      healthElement: "Zorgelement:",
      medication: "Behandeling:",
      diagnosis: "Diagnose:",
      speciality: "Specialiteit:",
      date: "Data:",
      vaccine: "Vaccin:",
      classification: "Classificatie:",
    },
    health_element_search: {
      ATC: "Atc",
      MEDICATION: "Specialiteit",
      PROBLEM: "Med. probleem",
      PROCEDURE: "Chir. procedure",
    },
    health_element_history_filter: {
      ACTIVE: "Actief",
      INACTIVE: "Antecedenten",
      RISK: "Risicofactoren",
      PREVENTION: "Preventie",
      ARCHIVED: "Gearchiveerd",
      ALL: "Alle",
    },
    task_time_unit: {
      day: "dagen",
      week: "weken",
      month: "maanden",
      year: "jaren",
    },
    task_time_unit_singular: {
      day: "dag",
      week: "week",
      month: "maand",
      year: "jaar",
    },
    patient: {
      deactivation: {
        deceased: "Overleden",
        moved: "Is verhuisd",
        other_doctor: "Is veranderd van arts",
        retired: "In rusthuis",
        no_contact: "Geen contact sinds lange tijd",
        unknown: "Onbekend",
        none: "Geen",
      },
      thirdpartytypes: {
        cpas: "OCMW",
        police: "Politie",
        nationaldefense: "Nationale defensie",
        luxembourgnationalsecurity: "Luxemburgs zorgverzekeringsnummer",
        other: "Andere",
      },
      healthcareparty: {
        doctor: "Arts",
        referral: "Referentie",
        medicalhouse: "Wijkgezondheidscentrum",
        retirementhome: "Rusthuis",
        hospital: "Hopitaal",
        other: "Andere",
        referringphysician: "Behandelende arts",
      },
      medicalhousestop: {
        dead: "Overleden",
        end_inscr_by_patient_moved:
          "Einde inschrijving door de patiënt - Verhuizing",
        end_inscr_by_patient_abroad:
          "Einde inschrijving door de patiënt - Vertrek naar het buitenland",
        end_inscr_by_patient_home: "Einde inschrijving door de patiënt - Thuis",
        end_inscr_by_patient_transferal_other_mh:
          "Einde inschrijving door de patiënt - Transfer andere MM",
        end_inscr_by_patient_not_happy:
          "Einde inschrijving door de patiënt - Conflict-Ontevreden",
        end_inscr_by_patient_other:
          "Einde inschrijving door de patiënt - Diverse",
        end_insrc_by_mh_radiation: "Einde inschrijving door de MM - Straling",
        end_insrc_by_mh_other: "Einde inschrijving door de MM - Andere",
        end_agreement_denounced_by_non_registered_mutual:
          "Einde overeenkomst opgezegd door de mutualiteit - Niet ingeschreven",
        end_agreement_denounced_by_non_registered_mh:
          "FEinde overeenkomst opgezegd door de MM - Niet ingeschreven",
      },
    },
    guardPost_access_log: {
      "24h": "Laatste 24u",
      "48h": "Laatste 48u",
      "72h": "Laatste 72u",
      custom: "Datum",
      all: "Alle",
      ready: "Klaar voor versturen",
      action_required: "Actie noodzakelijk",
      sent: "Al verstuurd",
    },
  },
  "patient-form": {
    PRINT: "Etiket afdrukken",
    RESET: "Herinitialiseren",
    CLOSE: "Annuleren",
    CHOOSE: "Deze patiënt kiezen",
    CREATE_NEW_PATIENT: "Een nieuwe patiënt creëren",
    CREATE_SUCCESS: "Patiënt met succes gecreëerd.",
    FOUND_PATIENT: "Patient(en) gevonden",
    NO_PATIENT: "Deze patient bestaat nog niet.",
    WARNING_DUPLICATE:
      "Opgelet: een patient met identieke of gelijkaardige gegevens bestaat al.",
    ERROR_DUPLICATE:
      "Er bestaat al een patiënt met deze combinatie van naam, voornaam en geboortedatum, open het dossier van deze patiënt",
    READ_DATA_FROM_EID: "Gegevens gelezen van de eID-kaart",
    _comment:
      "dit betrreft zowel de patient-edit-form als de patient-create-form",
    FIRST_NAME: "Voornaam",
    LAST_NAME: "Familienaam",
    GENDER: "Geslacht",
    SSIN: "INSZ",
    CARDNUMBER: "ISI+ kaart/eID",
    SSIN_TAKEN: "Er bestaat al een patiënt met dit INSZ",
    SSIN_WRONG_FORMAT: "Het formaat van het INSZ is fout",
    SSIN_IS_NOT_IN_USE: "Het INSZ komt met geen enkele patiënt overeen",
    SSIN_UNKNOWN_ERROR: "Er is een onbekende fout opgetreden",
    REFERRING_PHYSICIAN: "Behandelende arts",
    PREFERRED_USER: "Gebruiker-beheerder",
    EID: "eID",
    EID_EXPIRATION_DATE: "Geldigheidsdatum van eID",
    ACTIVE: "Actief",
    DEACTIVATION: "Reden voor deactivering",
    NOTES: "Opmerkingen",
    ADDRESSES: "Adressen",
    AN_ADDRESS: "Adres",
    ADDRESS: {
      TYPE: "Type",
      DESCRIPTION: "Omschrijving",
      STREET: "Straat",
      HOUSE_NUMBER: "Nummer",
      POSTBOX_NUMBER: "Bus",
      POSTAL_CODE: "Postcode",
      CITY: "Stad",
      COUNTRY: "Land",
      TELECOMS: "Telecoms",
    },
    WORK_ADDRESS: "Werkadres",
    DATE_OF_BIRTH: "Geboortedatum",
    DECEASED: "Overleden",
    PLACE_OF_BIRTH: "Plaats van geboorte",
    DATE_OF_DEATH: "Datum van overlijden",
    PLACE_OF_DEATH: "Plaats van overlijden",
    NATIONALITY: "Nationaliteit",
    LANGUAGE: "Gesproken taal",
    EXTERNAL_ID: "Externe identificatie",
    PROFESSIONS: "Beroep",
    SPOUSE_NAME: "Naam van echtgeno(o)ot(e)",
    PARTNER_NAME: "Naam van partner",
    CREATE_PATIENT: "Patiënt creëren",
    EDIT_PATIENT: "Wijzigingen opslaan",
    SUBMIT: "Formulier indienen",
    NO_CODE:
      "De code die u hebt ingegeven komt met geen enkele mutualiteit overeen.",
    INVALID_CODE:
      "Zonder geldige code zullen eFact en eAttest gedeactiveerd worden.",
    UNKNOWN_OA_NUMBER: "Mutualiteit onbekend",
    INSURABILITY: {
      INSURABILITY_TITLE: "Verzekerbaarheid",
      ID_NUMBER: "Identificatienummer",
      TITULARY_ID: "ID titularis",
      START_DATE: "Begin van geldigheid",
      END_DATE: "Geldigheidsdatum",
      STATUS: "Status",
      TC1: "CT1",
      TC2: "CT2",
      PAYMENT_APPROVAL: "Goedkeuring betaling",
      BIM: "Preferentiële status (RVV)",
      PAYMENT_BY_IO: "Betaling door derde",
      CHOOSE: "Keuze verzekering",
      AMU: "DMH",
    },
    MEDICAL_HOUSE: {
      TITLE: "Wijkgezondheidscentrum",
      NAME: "Naam",
      START_DATE: "Begindatum",
      END_DATE: "Einddatum",
      BILL_START_DATE: "Begindatum factuur",
      BILL_END_DATE: "Einddatum factuur",
      DOCTOR: "Arts",
      PHYSIOTHERAPIST: "Kinesist",
      NURSE: "Verpleegkundige",
      IS_MEDICAL_HOUSE: "Ingeschreven bij wijkgezondheidscentrum",
      IS_TRIAL: "In testperiode",
    },
    HOSPITALIZATION: {
      TITLE: "Hospitalisatie",
      HOSPITAL: "Hospitaal",
      ADMISSIONS_DEPARTMENT: "Toelatingsdienst",
      ADMISSIONS_DATE: "Datum toelating",
    },
    THIRD_NUMBER: "NUMMER DERDE",
    THIRD_PARTY_TYPE: "Type derde",
    THIRD_PARTY_NUMBER: "Nummer",
    NAME: "Naam",
    INAMI: "Riziv",
    SPECIALITY: "Specialiteit",
    TYPE: "Type",
    PHCP: "Zorgverstrekker",
    ADD_PHCP: "Zorgverstrekker toevoegen",
    NO_PHCP: "Er is geen enkele zorgverstrekker gelinkt met deze patiënt.",
    DELETE: "Verwijderen",
    HCP_ALREADY_PRESENT:
      "Deze zorgverstrekker is al aanwezig in de lijst van zorgverstrekkers",
    INSURANCE_CLAIM: {
      PRIVATE: "Privé",
      PROFESSIONAL: "Professioneel",
      TITLE: "Verzekeringsdossier",
      RETURN: "Terugsturen",
      ADD: "+ Dossier ",
      TYPE: "Type verzekering",
      PATIENT_IDENTIFICATION: "Klantnummer van de patiënt",
      RECORD_NUMBER: "Dossiernummer",
      OPENING_DATE: "Openingsdatum",
      CLOSING_DATE: "Sluitingsdatum",
      ADDITIONAL_INFORMATION: "Opmerking",
      INSURANCE_COMPANY: {
        TITLE: "Verzekeringsmaatschappij",
        NAME: "Naam",
        ADDRESS_INFORMATION: {
          TITLE: "Adresinformatie",
          STREET: "Straat",
          CITY_CODE: "Postcode",
          CITY: "Stad",
          COUNTRY: "Land",
        },
      },
      CONTACT_PERSON: {
        TITLE: "Contactpersoon",
        NAME: "Naam",
        EMAIL_ADDRESS: "E-mailadres",
        TEL: "Telefoonnummer",
        FAX: "Faxnummer",
        ADDRESS_INFORMATION: {
          TITLE: "Adresinformatie",
          STREET: "Straat",
          CITY_CODE: "Postcode",
          CITY: "Stad",
          COUNTRY: "Land",
        },
      },
    },
    WRONG_SSIN_CARD_NUMBER_COMBINATION:
      "De combinatie SSIN / kaartnummer is ongeldig. Controleer de geldigheid van de kaart.",
    PATIENT_DECEASED: "De patiënt is overleden",
    SSIN_ALREADY_PRESENT: "Deze INSZ komt al voor in uw patiëntendatabase",
    SSIN_NOT_IN_REGISTRY: "Deze INSZ komt niet voor in het nationaal register",
    SSIN_REPLACED: "Deze INSZ is bijgewerkt. Nieuwe INSZ: ",
    REPLACE_SSIN: "Gebruik deze INSZ.",
    PATIENT_SEARCH: "Patiënt opzoeken",
    OPEN_PATIENT: "Patiëntendossier openen",
    NO_EHEALTH_CONNECTION:
      "U moet verbonden zijn met de eHealth-diensten om een patiënt te zoen op basis van de INSZ/ISI+",
    SEARCH_NISS_BTN: "INSZ opzoeken",
    TO_MANY_SEARCH_RESULTS: "Te veel resultaten, verfijn uw zoekopdracht",
    CHECK_NISS_BTN: "INSZ controleren",
    NISS_UP_TO_DATE: "Geen veranderingen geconstateerd",
    BAD_COUNTRY_NOTIFICATION: {
      PART1: "Het land van het adres : ",
      PART2: " van de patiënt is foutief geregistreerd.",
      NOTYPE:
        "Het land van één van de adressen van de patiënt is foutief geregistreerd.",
    },
    BAD_NATIONALITY_NOTIFICATION:
      "De nationaliteit van de patiënt is foutief geregistreerd. \n",
  },
  "rn-consult": {
    "phonetic-search": {
      title: "Opzoeken van een INSZ",
      search: "Zoeken",
      tolerance: "Tolerantie",
      cancel: "Annuleren",
      "change-search": "Zoekopdracht wijzigen",
      OPEN_BIS: "BIS-nummer aanvragen",
      "skip-step": "Deze stap overslaan",
      "refine-your-search": "Verfijn uw zoekopdracht",
      error: "Fout in het verzoek",
      MONTH: "maand",
      YEAR: "jaar",
      OK: "Ok",
      NO_RESULT: "Geen resultaat gevonden",
    },
    SSIN_EXISTS: "Deze INSZ is al in gebruik voor een patiënt in Medispring",
    OK: "Ok",
    country: "Land",
    city: "Stad",
    BIS: "Inss biss",
    RN: "Inss",
    RAD: "Inss verwijderd",
    RAN: "Inss geannuleerd",
    NR: " ",
    CONFIRMATION_MODAL: {
      TITLE: "De laatste controle van het INSZ is te recent.",
      CONFIRM: "Weet u zeker dat u de controle wil forceren.",
      YES: "Ja",
      NO: "Neen",
    },
  },

  "request-bis-modal": {
    TITLE: "Wat is een BIS_nummer?",
    LIST_LABEL:
      "Een BIS-nummer (uniek identificatienummer voor de sociale zekerheid) kan zijn:",
    NATIONAL_REGISTRATION_NUMBER: "een nationaal registratienummer",
    BIS_NUMBER:
      "een Bis-nummer (toegekend aan buitenlandse werknemers die niet in België wonen)",
    WARNING_HEADING: "Let op:",
    WARNING_TEXT:
      "De creatie van een BIS-nummer moet zorgvuldig gebeuren omdat dit nummer dan met vele andere actoren (uit de gezondheidszorg, de sociale zekerheid, de FOD Financiën, de notarissen,...) gedeeld zal worden.",
    REQUIRE_PATIENT_PRESENCE:
      "Maak alleen een BIS-nummer aan in aanwezigheid van de patiënt, <b>nooit per telefoon, e-mail of SMS.</b> Het is ook nodig om dit BIS-nummer aan de patiënt door te geven",
    IDENTIFICATION_DATA:
      "Let vooral op de identificatiegegevens die u gaat invoeren, indien mogelijk <b>op basis van de door de patiënt verstrekte documenten.</b> <b>Als u een identiteitsdocument hebt dat betrekking heeft op dit dossier</b> (bijvoorbeeld: paspoort, buitenlandse identiteitskaart, geboorteakte, rijbewijs,...), <b>wordt u uitgenodigd een kopie per e-mail</b> te sturen naar <a target='_blank', href='mailto:identification@ksz-bcss.fgov.be'>identification@ksz-bcss.fgov.be</a> en in de e-mail te verwijzen naar het toegekende BIS-nummer en aan te geven dat het om een identiteitsdocument gaat.",
    CONTACT:
      "Neem in geval van problemen contact op met de \"BCSS-identificatie-eenheid\" :<a target='_blank', href='mailto:identification@ksz-bcss.fgov.be'>identification@ksz-bcss.fgov.be</a>",
  },
  "request-bis-form": {
    TITLE: "Aanvraag voor BIS-nummer",
    IDENTITY: "Identiteit",
    GENDER: "Geslacht",
    FIRST_NAME: "Voornaam",
    LAST_NAME: "Familienaam",
    DATE_OF_BIRTH: "Geboortedatum",
    NATIONALITY: "Nationaliteit",
    BUSINESS_ERROR: "Fatale fout ({{ERROR_CODE}}: {{ERROR_Omschrijving}})",
    address: {
      STREET: "Straat",
      POSTAL_CODE: "Postcode",
      CITY: "Stad",
      COUNTRY: "Land",
      TYPE: "Adrestype",
    },
    "date-info": {
      p1: "Wanneer u een aanvroeg voor het aanmaken van een BIS-nummer uitvoert en de patiënt kent zijn exacte geboortedatum niet, dan kunt u een onvolledige geboortedatum ingeven.",
      p2: "Een onvolledige datum is een datum die enkel het jaar of de maand en het jaar bevat.",
      p3: "Opgelet, als u een onvolledige datum opgeeft, dan moet u alle informatie betreffende de woonplaats, stad en postcode toevoegen als deze plaats zich in België bevindt, alsook de naam van de straat.",
    },
    BIRTH_MID: "Plaats van geboorte",
    RES_MID: "Woonplaats",
    ACCEPT: "Aanvaarden",
    SAVE: "Bewaren",
    CANCEL: "Annuleren",
    REGISTRATION_TYPE: {
      BIRTH_DATE: "Gebaseerd op geboortedatum",
      ADDRESS_OUTSIDE_BELGIUM: "Gebaseerd op een verblijfsadres buiten België",
      ADDRESS_IN_BELGIUM: "Gebaseerd op een verblijfsadres binnen België",
    },
    ADDRESS_TYPE: {
      TEMPORARY_STAY: "Tijdelijk verblijf",
      PUBLIC_CENTER_FOR_SOCIAL_ACTION:
        "Openbaar Centrum voor Maatschappelijk Welzijn",
      HOST_FAMILY: "Pleegopvang",
      INSTITUTION_RECEPTION:
        "Instelling/opvang (zorg/ouderen/detentie/asielzoekers)",
      HOSPITAL: "Ziekenhuis",
      FRIENDS_FAMILY: "Vrienden/Familie",
      EMPLOYER: "Werkgever",
      UNKNOWN: "Onbekend",
    },
    PATIENT_GENERATED_BIS_MODAL: {
      OK: "Oke",
      GIVE_BIS_TO_PATIENT:
        "BIS-nummer om aan de patiënt te communiceren: <b>{{BIS_NUMBER}}</b>. Noteer in de administratie van de patiënt de documenten die u van de patiënt krijgt (bijvoorbeeld paspoortnummer en plaats van afgifte van het paspoort)",
    },
  },
  "patient-search": {
    FIND_PATIENT: "Een patiënt vinden",
    SPOUSE_NAME: "Naam van echtgeno(o)ot(e)",
    DEFAULT_FILTER: "Standaard",
    EXTERNAL_ID_FILTER: "Dossiernummer",
    FILTER: "Zoekfilter",
  },
  "hcparty-search": {
    FIND_HCPARTY: "Een OP selecteren",
  },
  kendo: {
    dialog: {
      closeTitle: "Sluiten",
    },
    dropdownlist: {
      noDataText: "Geen enkel element",
    },
    multiselect: {
      noDataText: "Geen enkel element",
    },
    grid: {
      groupPanelEmpty: "TODO (groupPanelEmpty)",
      pagerFirstPage: "Eerste pagina",
      pagerLastPage: "Laatste pagina",
      pagerPreviousPage: "Vorige pagina",
      pagerNextPage: "Volgende pagina",
      pagerItems: "elementen",
      pagerOf: "van",
      pagerItemsPerPage: "élémenten per pagina",
      pagerPage: "Pagina",
      filter: "Filteren",
      filterEqOperator: "is gelijk aan",
      filterNotEqOperator: "is verschillend van",
      filterIsNullOperator: "is nul",
      filterIsNotNullOperator: "is niet nul",
      filterIsEmptyOperator: "is leeg",
      filterIsNotEmptyOperator: "is niet leeg",
      filterStartsWithOperator: "begint met",
      filterContainsOperator: "bevat",
      filterNotContainsOperator: "bevat niet",
      filterEndsWithOperator: "eindigt met",
      filterGteOperator: "is groter dan of gelijk aan",
      filterGtOperator: "is groter dan",
      filterLteOperator: "is kleiner dan of gelijk aan",
      filterLtOperator: "is kleiner dan",
      filterIsTrue: "Ja",
      filterIsFalse: "Nee",
      filterBooleanAll: "Alle",
      filterAfterOrEqualOperator: "is na of gelijk aan",
      filterAfterOperator: "is na",
      filterBeforeOperator: "is voor",
      filterBeforeOrEqualOperator: "is voor of gelijk aan ",
      filterFilterButton: "Filteren",
      filterClearButton: "Wissen",
      filterAndLogic: "EN²",
      filterOrLogic: "OF",
      isLoading: "Laden",
      columnMenu: "Kolommenu",
      columns: "Kolommen",
      lock: "Vergrendelen",
      unlock: "Ontgrendelen",
      sortAscending: "Sorteren in oplopende volgorde",
      sortDescending: "Sorteren in aflopende volgorde",
      sortable: "Sorteerbaar",
      sortedAscending: "Gesorteerd in oplopende volgorde",
      sortedDescending: "Gesorteerd in aflopende volgorde",
      sortedDefault: "Standaard gesorteerd",
      columnsApply: "Toepassen",
      columnsReset: "Wissen",
      noRecords: "Geen resultaat",
    },
    calendar: {
      today: "Vandaag",
    },
    datepicker: {
      today: "Vandaag",
      toggle: "Een datum selecteren",
    },
    multiviewcalendar: {
      today: "Vandaag",
    },
    dateinput: {
      decrement: "TODO",
      increment: "TODO",
    },
    timepicker: {
      now: "Nu",
      cancel: "Annuleren",
      accept: "Selecteren",
      acceptLabel: "Selecteren",
      cancelLabel: "Annuleren",
      nowLabel: "Nu",
      toggle: "Een uur selecteren",
    },
    upload: {
      invalidFileExtension: "Bestandsextensie ongeldig!",
      cancel: "Annuleren",
      clearSelectedFiles: "Geselecteerde bestanden wissen",
      dropFilesHere: "Zet de bestanden hier neer",
      headerStatusUploaded: "Versturen voltooid",
      headerStatusUploading: "Versturen bezig",
      invalidFiles: "Bestanden ongeldig",
      invalidMaxFileSize: "Bestand te groot",
      invalidMinFileSize: "Bestand te klein",
      remove: "Verwijderen",
      retry: "Opnieuw proberen",
      uploadSelectedFiles: "Geselecteerde bestanden uploaden",
      select: "Bestand kiezen",
    },
    slider: {
      decrement: "Verminderen",
      increment: "Verhogen",
      dragHandle: "TODO",
      gerPage: "TODO",
      gerItemsPerPage: "TODO",
      gerOf: "TODO",
      gerItems: "TODO",
    },
    combobox: {
      noDataText: "Geen resultaten gevonden.",
    },
    numerictextbox: {
      decrement: "Verminderen met 1",
      increment: "Verhogen met 1",
    },
  },
  "patient-information": {
    ADDRESS: "Adres",
    PHONE: "Telefoon",
  },
  "payment-types": {
    cash: "Contant",
    wired: "Overschrijving",
    debitcard: "Bancontact",
  },
  "patient-insurability": {
    MISSING_SSIN: "De patiënt heeft geen INSZ",
    MISSING_DATA_MEDICAL_HOUSE: "Geen wijkgezondheidscentrum",
    BAD_SSIN: "INSZ ongeldig",
    MDA_ERROR:
      "We hebben geen antwoord ontvangen binnen de gestelde tijd (foutcode 500. Probeer een nieuw verzoek te sturen of wacht tot de dienst opnieuw beschikbaar is.",
    SSIN: "INSZ",
    IO: "Mutualiteitsnummer",
    IO_MEMBERSHIP: "Lidmaatschapsnummer mutualiteit",
    HOSPITALIZED: "Hospitalisatie",
    MEDICAL_HOUSE: "Wijkgezondheidscentrum",
    ON: "op",
    CHECK: "Verzekerbaarheid controleren",
    CHECK_DATE: "Op datum van",
    CHECK_START_PERIOD: "Periode van",
    CHECK_END_PERIOD: "tot",
    CHECK_BUTTON: "Controleren",
    ADDITIONAL_INFO: "Aanvullende informatie",
    BEWARE: "Opgelet",
    ADMISSION_DATE: "Opnamedatum:",
    ADMISSION_SERVICE: "Opnamedienst:",
    HOPITAL: "Hospitaal:",
    CONTRAT_START: "Opgenomen op",
    CONTRAT_END: "tot",
    MEDICAL_HOUSE_CONTRAT: "Contract en wijkgezondheidscentrum",
    CLOSED_BEFORE:
      "Dossier afgesloten voor de consultatiedatum {{date}} (overlijden, wijziging lidmaatschap,…)",
    DEATH_DATE: " - Patiënt overleden op {{date}}",
    BIRTH_DATE: "Geboortedatum",
    START_AFTER:
      "Dossier gestart na consultatiedatum {{date}} (nieuw geboren, nieuw lid,…)",
    NOT_CONFORM: "Niet conform (geen arts)",
    MKI: "MKI (arts + kine + verpleegkundige)",
    MNI: "MNI (arts + verpleegkundige)",
    MKN: "MKN (arts + kine)",
    MNN: "(medische zorgen)",
    CONTRAT_TYPE: "Type contract",
    ETENDUE: "Omvang: ",
    UNKNOW: "Onbekend",
    ERROR_CODE: "Foutcode: ",
    ERROR_MESSAGE: "Foutboodschap:",
    ERROR_SOURCE: "Bron van fout: ",
    CHECK_SUCCESS: "Gegevens inzake verzekerbaarheid met succes ontvangen",
    ERROR_CHECK: "De gegevens van het lid konden niet worden geraadpleegd",
    ERROR_CHECK_2: "Fout bij controle van verzekerbaarheid",
    INSURABILITY_TITLE: "Gegevens van het lid",
    LABELS: {
      NAME: "Naam",
      MUT_NAME: "Mutualiteit",
      TITULARY_ID: "ID titularis",
      START_DATE: "Begindatum geldigheid ",
      END_DATE: "Einddatum geldigheid",
      INSURABILITY_DATE: "Datum van verzekerbaarheid",
      TC: "CT1 / CT2",
      BIM: "RVV",
      THIRD_PARTY_PAYER: "Betalende derde",
    },
    LOCAL_UPDATE: "Automatisch bijwerken van verzekeringsgegevens.",
    LOCAL_UPDATE_ERROR:
      "Automatisch bijwerken van verzekeringsgegevens mislukt. Iemand anders heeft de patiënt mogelijk gewijzigd, probeer de patiënt opnieuw te laden.",
    PATIENT_DATA: "Gegevens patiënt",
    PATIENT_DATA_LABELS: {
      SSIN: "INSZ",
      LAST_NAME: "Naam",
      FIRST_NAME: "Voornaam",
      BIRTHDATE: "Geboortedatum",
      GENDER: "Geslacht",
      GENDER_VALUES: {
        male: "Man",
        female: "Vrouw",
        undefined: "Niet gedefinieerd",
        unknown: "Onbekend",
        changed: "Gewijzigd",
        null: "",
      },
      DECEASED_DATE: "Datum van overlijden",
    },
    PERIOD: "Periode",
    PERIOD_LABELS: {
      REGISTRATION_NUMBER: "Lidmaatschapsnummer mutualiteit",
      MUTUALITY: "Mutualiteit",
      CB1_CB2: "CT1 / CT2",
      COMMUNICATION_DATE: "Communicatiedatum",
      START_DATE: "Begindatum geldigheid",
      END_DATE: "Einddatum geldigheid",
      PAYMENT_APPROVAL: "Goedkeuring van betaling",
    },
    PAYMENT: "Betaling",
    PAYMENT_LABELS: {
      THIRD_PARTY_PAYMENT: "Betalende derde",
      MISSING_DATA: "Geen betalende derde",
    },
    TYPE: "Type",
    START_DATE: "Begindatum",
    END_DATE: "Einddatum",
    KINE: "Kinesist",
    MEDICAL: "Medisch",
    MEDICAL_HOUSE_ID: "Riziv numer",
    NURSE: "Verpleegkundige",
    HOSPITALIZATION: "Hospitalisatie",
    HOSPITALIZATION_LABELS: {
      HOSPITAL_ID: "ID hospitaal",
      SERVICE: "Dienst",
      ADMISSION_DATE: "Datum van opname",
      MISSING_DATA: "Geen hospitalisatie",
    },
    GENERAL_SITUATION: "Algemene situatie",
    GENERAL_SITUATION_LABELS: {
      EVENT: "Evenement",
      TRANSFER_DIRECTION: "Richting van transfer",
      TRANSFER_IO: "Transfer IO",
      TRANSFER_DATE: "Datum van transfer",
      MISSING_DATA: "Geen evenement",
    },
    CARE_PATHWAY: "Zorgtraject",
    CARE_PATHWAY_LABELS: {
      PHYSICIAN: "Arts",
      MEDICAL_HOUSE: "Wijkgezondheidscentrum",
      SPECIALIST: "Specialist",
      CONTRACT_END_DATE: "Einddatum contract",
      MISSING_DATA: "Geen zorgtraject",
      DIABETES: "Suikerziekte",
      RENALINSUFFICIENCY: "Nierinsufficiëntie",
    },
    CHRONIC_CONDITION: "Status chronische aandoening",
    CHRONIC_CONDITION_LABELS: {
      YEAR: "Jaar",
      MISSING_DATA: "Geen status chronische aandoening",
    },
    REFERENCE_PHARMACY: "Verwijzingsapotheek",
    REFERENCE_PHARMACY_LABELS: {
      PHARMACY: "Apotheek",
      MISSING_DATA: "Geen verwijzingsapotheek",
    },
    GLOBAL_MEDICAL_FILE: "GMD",
    GLOBAL_MEDICAL_FILE_LABELS: {
      PHYSICIAN: "GMD",
      MISSING_DATA: "Geen DMG-houder",
    },
    PALLIATIVESTATUS: "Palliatieve status",
    PALLIATIVESTATUS_LABELS: {
      STARTDATE: "Begin datum",
      MISSING_DATA: "Geen palliatieve status",
    },
    LAST_ASSURABILITY_CHECK: "Laatste controle van verzekerbaarheid op",
    MDA_STATUS_SUCCESS:
      "De zoekopdracht is afgelopen maar er ontbreekt bepaalde informatie.",
    MEDISPRING_ISSUE: "Fout op niveau van Medispring",
    IO_ISSUE: "Fout op niveau van de verzekeringsinstantie",
    BO_INVALID_REGNBR: "Lidmaatschapsnummer mutualiteit ongeldig",
    BO_UNKNOWN_REGNBR:
      "Lidmaatschapsnummer mutualiteit onbekend bij verzekeringsinstantie",
    MISMATCHED_CONTACTTYPE:
      "De patiënt is niet opgenomen op de geraadpleegde datum",
    UNKNOWN_NISS_ROUTING: "Het INSZ is onbekendd bij de mutualiteiten",
    INVALID_PERIOD: "De gevraagde periode is ongeldig",
    SEND_TO_IM_EXCEPTION: "Verbindingsprobleem met de mutualiteit",
    FACET_EXCEPTION: {
      insurability: "Onverwachte fout",
      REFERENCEPHARMACY: "Informatie tijdelijk onbeschikbaar",
      CHRONICCONDITION: "Informatie tijdelijk onbeschikbaar",
      CAREPATH: "Informatie tijdelijk onbeschikbaar",
      GLOBALMEDICALFILE: "Informatie tijdelijk onbeschikbaar",
      PALLIATIVESTATUS: "Informatie tijdelijk onbeschikbaar",
    },
    SAML_REQUEST_ERROR: "Fout in het SAML-verzoek",
    BUSINESS_ERROR: "Bedrijfsfout (facetten of dimensies)",
    BUSINESS_VALIDATION_FAILED:
      "De bedrijfsvalidatie van het verzoek is mislukt",
    TECHNICAL_ERROR: "Technische fout",
    INFO_MISSING:
      "Bepaalde informatie ontbreekt (meerdere facetten werden gevraagd maar er is slechts een gedeelte beschikbaar)",
    NOT_CONNECTED_EHEALTH: "U bent niet verbonden met de eHealth-diensten.",
  },
  "patient-layout": {
    BORN_ON: "Geboren op",
    YEARS: "jaren",
    YEAR: "jaar",
    MONTHS: "maand",
    MONTH: "maand²",
    FAMILY: "familie",
    INVALID_SSIN: "Ongeldige INSZ",
    MISSING_SSIN: "Ontbrekende INSZ",
    SUBMITTED_PENDING_CONTACTS:
      "{{count}} contact(en) onder bewerking bewaard.",
    DISCARDED_PENDING_CONTACTS:
      "{{count}} contact(en) onder bewerking verlaten (bewerkingsvenster verouderd).",
    TITLE: "Voorschrift creëre",
    PATIENT_LIVE_WITH: "Patiënten die onder hetzelfde dak wonen...",
  },
  "patient-layout-buttons": {
    INSURABILITY: "Verzekerbaarheid",
    NO_ASSURABILITY: "De patiënt is niet in orde met de mutualiteit",
    CONSENT: "Akkoord van de patiënt",
    THERLINK: "Therapeutische links",
    DMG: "Watchpost",
    BIM_PATIENT: "De patiënt is RVV",
    CREATE_PRESCRIPTION: "Voorschrift creëre",
    LAST_DMG_CONSULTATION: "Laatste consultatie van GMD :",
    "dmg-type": {
      DMG_UNKNOW: "GMD onbekend",
      NO_DMG: "Er is geen GMD",
      DMG_TO_MYSELF: "Het GMD behoort mij toe",
      DMG_COLLEAGUE: "Het GMD behoort toe aan een arts van het kabinet",
      DMG_OUTSIDE: "Het GMD behoort toe aan een externe arts",
    },
    SUMEHR: "Sumehr",
    HUB: "Hub",
    ATTESTATION: "Attest",
    MEDICAL_NOTE: "Medische nota",
    ADMINISTRATIVE_NOTE: "Administratieve nota",
    INVOICING: "Facturatie",
    BEOOGO: "Beoogo",
    OPEN_BEOOGO: "Openen in Beoogo",
    BEOOGO_AUTHENTIFICATION_ERROR: "Fout bij Beoogo-authenticatie",
    BEOOGO_PATIENT_NOT_FOUND_ERROR: "Patient niet aanwezig in Beoogo",
    COVID_VACCINATION: "Vaccinatiestatus voor covid",
    SUBSCRIPTION: "Beheer van het medische huis-abonnement",
    LABORATORY: "Laboratoria",
  },
  "patient-layout-tabs": {
    JOURNAL: "Journaal",
    SYNTHESIS: "Synthese",
    FILE: "Administratief",
    SYNTHESE: "Hubs",
    FINANCE: "Financiën",
  },
  "patient-layout-labresult": {
    NOTE: "Nota",
    PARTIAL_NOTE: "Nota opgehaald uit partieel resultaat.",
    SAVE: "Bewaren",
    NOTE_UPDATED_SUCCESS: "Nota bijgewerkt.",
    NOTE_UPDATED_ERROR: "Fout tijdens bijwerken van nota.",
    LABEL: "Label",
    DATE: "Datum",
    VALUE: "Waarde",
    REFERENCE_VALUE: "Referentiewaardes",
    REFERENCE_VAL: "Referentiewaarde",
    UNIT: "Eenheid",
    FILTER: "Filter",
    MORE_THAN_X: "Meer dan {{value}}",
    LESS_THAN_X: "Minder dan {{value}}",
    DOWNLOAD_BIOLOGY: "Biologie downloaden",
    PRINT_BIOLOGY: "Biologie afdrukken",
    SEND_BIOLOGY: "Biologie versturen",
    BIOLOGY: "Biologie",
    PRINT_TITLE: "Laboratoriumresultaat van {{hcpName}} op {{date}}",
    ABNORMAL: "Abnormaal",
    REFERENCE: "Labo referentie",
    REF: "Ref.",
    COMPLETE: "Compleet",
    PARTIAL: "Partieel",
    SENDER: "Labo",
  },
  "labresult-dialog": {
    TITLE: "Analyseresultaten: {{analysis}}",
  },
  "labresult-table": {
    NO_SERIES_DATA: "De gegevens laten niet toe een grafiek weer te geven.",
  },
  "telephonic-consultation": {
    DESCRIPTION: "Mening/Diverse",
  },
  "multidisciplinary-consult": {
    DESCRIPTION: "Multidisciplinair overleg",
  },
  "patient-file-content": {
    MEDICAL_NOTE_SAVED: "Medische nota geregistreerd",
    EDIT_SUCCESS: "Patiënt bijgewerkt.",
    EDIT_FAILURE: "Het bijwerken van de patiënt is mislukt.",
    CREATE_SUCCESS: "Patiënt gecreëerd.",
    CREATE_FAILURE: "Het creëren van de patiënt is mislukt.",
    FILTERS: "Filters",
    CANCEL_FILTER: "Filter annuleren",
    BEOOGO_SYNC_ERROR: "Synchronisatie probleem met BEOOGO",
  },
  "patient-consent": {
    EXPIRED_EID_CARD: "EID-kaart verstreken",
    ERROR_CHECK:
      "Er is een fout opgetreden bij het raadplegen van de toestemming: {{error}}",
    ERROR_SUBMIT:
      "Er is een fout opgetreden bij het indiene van de toestemming: {{error}}",
    INCOMPLETE:
      "Het lijkt erop dat een een type toestemming ontbreekt. Beide zijn nodig voor de goede werking van Medispring. Gelieve de toestemmingen opnieuw te creëren.",
    NOTHING_TO_REVOKE:
      "De patiënt heeft geen enkele toestemming om te herroepen of er is geen enkele toestemming gevonden.",
    ERROR_REVOKE:
      "Er is een fout opgetreden bij het herroepen van de toestemming: {{error}}",
    MISSING_SSIN: "De patiënt heeft geen INSZ.",
    CONSENT_TITLE: "Toestemming van de patiënt",
    EID_CARD_NUMBER: "Nummer identiteitskaart (eID)",
    ISI_CARD_NUMBER: "Nummer ISI+-kaart",
    REVOKE_CONSENT: "Toestemming herroepen",
    REGISTER_CONSENT: "Toestemming registreren",
    REGISTER_NATIONAL_OK: "Nationaal toestemming succesvol geregistreerd",
    REGISTER_HUB_OK: "Toestemming hub succesvol geregistreerd",
    REGISTER_HUB_FAILED: "Toestemming hub mislukt",
    REVOKED_OK: "Toestemming succesvol herroepen",
    PLEASE_CHECK_BOX:
      "Vink het onderstaande verzoek aan om de toestemming te registreren.",
    INFO_TITLE: "Gelieve uw patiënt de volgende informatie te bezorgen: ",
    CREATE_FORM_HEADER:
      "Vul de volgende velden in om de akkoorden van de klant te creëren:",
    INFO1:
      "Ik verzoek me vrij te registreren bij gezondheidsnetwerken om zorgverstrekkers die betrokken zijn bij mijn zorg, in staat te stellen gezondheidsgegevens in verband met mijn zorg uit te wisselen",
    INFO2:
      "Deze inschrijving vormt instemming met de uitwisseling van gegevens op landelijk niveau als onderdeel van de continuïteit van de zorgen.",
    REFUSE: "Weigering patiënt registreren",
    CLOSE: "Sluiten",
    EDIT: "Wijzigen van toestemming",
    CONSENT_REFUSED: "De patient weigert zijn toestemming te verlenen",
    CONSENT_REFUSE_SAVED: "Toestemming geweigerd",
    CONSENT_REFUSE_UNSAVED: "Weigering toestemming geannuleerd",
    CONSENT_REFUSE_ERROR: "Het bijwerken van de toestemming is mislukt",
    REASON_FOR_MANUAL_INPUT:
      "Kies een rechtbaardiging voor de handmatige invoer",
    BAD_SSIN: "INSZ ongeldig",
    NOT_CONNECTED_TO_EHEALTH: "U bent niet verbonden met het eHealth-netwerk",
    ERRORS: {
      "MH2.INPUT.2": "De aanvrager van het verzoek is ongeldig",
      "MH2.INPUT.19": "De identificatie van de patiënt is ongeldig",
      "MH2.ACCESS.8": "De toestemming bestaat al",
      "IDS2.INPUT.53": "Formaat ongeldig",
      "IDS2.INPUT.70": "Formaat onbekend",
      "IDS2.INPUT.80": "Geen enkel resultaat voor dit nummer",
      "CO.UPDATE.01":
        "De toestemming van een overledne persoon kan niet worden gewijzigd",
      "CO.INPUT.30": "Het INSZ-patiëntenkaartnummer is verplicht",
    },
  },
  "patient-therlink": {
    ERROR_CHECK:
      "Er is een fout opgetreden bij het raadplegen van de therapeutische link: {{error}}",
    ERROR_SUBMIT:
      "Er is een fout opgetredenen bij het indienen van de therapeutische link: {{error}}",
    ERROR_SUBMIT_NATIONAL:
      "Er is een fout opgetredenen bij het indienen van de nationale therapeutische link",
    ERROR_SUBMIT_HUB:
      "Er is een fout opgetredenen bij het indienen van de therapeutische link van de hub",
    INCOMPLETE:
      "Het lijkt erop dat een type therapeutische link ontbreekt. Beide zijn vereist voor de goede werking van Medispring. Gelieve de therapeutische links opnieuw te creëren.",
    NOTHING_TO_REVOKE:
      "De patient heeft geen enkele therapeutische link te herroepen of er werd geen enkele therapeutische link gevonden.",
    ERROR_REVOKE:
      "Er is een fout opgetredenen bij het indienen van de therapeutische link: {{error}}",
    MISSING_SSIN: "De patiënt heeft geen INSZ.",
    THERLINK_TITLE: "Therapeutische links",
    EID_CARD_NUMBER: "Nummer identiteitskaart (eID)",
    ISI_CARD_NUMBER: "Nummer ISI+-kaart",
    ALL_TYPES: "Nationaal + Hub",
    REVOKE: "Herroepen",
    NAT_ONLY: "Enkel op nationaal netwerk",
    HUB_ONLY: "Enkel op het hub-netwerk",
    LINK_TO_CREATE: "Te creëren links",
    REVOKE_THERLINK: "De therapeutische link herroepen",
    REGISTER_THERLINK: "De therapeutische link registreren",
    REGISTER_OK: "Therapeutische link succesvol geregistreerd",
    REVOKED_OK:
      "Herroepen van de therapeutische link succesvol uitgevoerd, de behandeling ervan kan meerdere minuten duren. ",
    NAME: "Naam",
    NIHII: "RIZIV",
    DATE_START: "Begindatum",
    DATE_END: "Einddatum",
    STATUS: "Status",
    TYPE: "Type",
    LEVEL: "Niveau",
    NATIONAL: "Nationaal",
    HUB: "Hub",
    REASON_FOR_MANUAL_INPUT:
      "Kies een rechtvaardiging voor de handmatige invoer",
  },
  "health-element-form": {
    HEALTH_ELEMENT: "Zorgelement",
    NEW_HEALTH_ELEMENT: "Nieuw zorgelement",
    EDIT_HEALTH_ELEMENT: "Zorgelement wijzigen",
    SEARCH_IN: "Opzoeken in",
    DESCRIPTION: "Omschrijving",
    NATURE: "Aard",
    STATUS: "Status",
    CHARACTERIZATION: "Gekarakteriseerd als",
    CERTAINTY: "Zekerheid",
    SEVERITY: "Ernst",
    TEMPORALITY: "Tijdelijkheid",
    LATERALITY: "Lateraliteit",
    CONFIDENTIALITY: "Vertrouwelijkheid",
    NOTE: "Nota",
    PLANS_OF_ACTION: "Actieplan (initiatief)",
    OPENING_DATE: "Begindatum",
    CLOSING_DATE: "Einddatum",
    EXTRA_OPTIONS: "Meer keuzes",
    HISTORY: "Historiek",
    EPISODE: "Episode",
    EPISODES: "Episodes",
    HISTORY_COLUMN: {
      DESCRIPTION: "Omschrijving",
      NATURE: "Aard",
      STATUS: "Status",
      MODIFIED_DATE: "Wijziging",
      RESPONSIBLE: "Gebruiker",
      AUTHOR: "Betrokkene",
    },
    ERROR: {
      CODE_FETCH_FAILURE: "Code fetch mislukt",
      MISSING_DESCRIPTION: "Omschrijving ontbreekt.",
      INACTIVE_MISSING_CLOSING_DATE: "De einddatum moet aanwezig zijn",
      FUTURE_DATE: "De datum kan niet in de toekomst zijn.",
      REQUIRED_START_DATE: "Een begindatum is verplicht.",
      UNORDERED_DATES: "De begindatum is voor de einddatum.",
      FAMILY_RISK_AND_ACTIVE: "Een familiaal antecedent kan niet actief zijn.",
    },
    WARNING: {
      REQUIRED_FIELD: "Verplicht veld",
    },
    ADD: "Toevoegen",
    CANCEL: "Annuleren",
    MODIFY: "Wijzigen",
    SAVED_THE: "Geregistreerd op",
    BY: "door",
    EVIDENCE_LINKER: "Evidence Linker",
  },
  "evidence-linker-dialog": {
    SELECT: "Selecteren",
    CANCEL: "Annuleren",
  },
  "code-service": {
    FETCH_FAILURE: "De opzoeking van de code is mislukt voor {{code}}.",
  },
  "code-search": {
    FIND_HEALTH_ELEMENT: "Een zorgelement vinden",
  },
  "insurability-search": {
    FIND_INSURABILITY: "Een verzekering vinden",
  },
  "patient-synthesis-controls": {
    GENERAL: "Algemene synthese",
    VASCULAR: "TA / Pols",
    WEIGHT_HEIGHT: "Gewicht / Lengte",
    BLOOD: "Glycemie, temperatuur en zuurstofsaturatie",
    BIOLOGIES: "Laatste biologie op datum van ",
  },
  "patient-journal-controls": {
    UPDATED_HEALTH_ELEMENTS: "{{count}} zorgelementen automatisch bijgewerkt.",
    EXPAND: "Zorgelementen openvouwen",
    COLLAPSE: "Zorgelementen dichtvouwen",
    CATEGORY: {
      PROBLEM: "Actieve Gezondheidselementen",
      ARCHIVE: "Archieven",
      INACTIVE: "Antecedenten",
      ALLERGY: "Allergieën voor geneesmiddelen",
      INTOLERANCE: "Intoleranties voor geneesmiddelen",
      FAMILY_RISK: "Familiale antecedenten",
      SOCIAL_RISK: "Sociale risicofactoren",
      MEDICAL_RISK: "Medische risicofactoren",
      INACTIVE_SURGICAL: "Chirurgische antecedenten",
      INACTIVE_MEDICAL: "Medische antecedenten",
      INACTIVE_GYNECOLOGICAL: "Gynaeco-verloskundige antecedenten",
      RISK: "Risicofactoren",
      CURRENT_TREATMENTS: "Actieve behandelingen",
      DIAGNOSIS: "Irrelevante diagnoses",
      PREVENTION: "Preventie",
      VACCINE: "Vaccinaties ",
    },
    NEW_EPISODE_DIALOG: {
      TITLE: "Activering van element: {{name}}",
      CONTENT:
        "Wenst u een nieuwe episode te creëren of de vorige episode te hernemen?",
      CANCEL: "Annuleren",
      ACTIVATE: "De vorige episode hernemen",
      CREATE: "Een nieuwe episode creëren",
    },
    EPISODE: "Episode",
    RECURRENCE: "recidive",
    NO_HEALTH_ELEMENT: "GEEN {{descr}}",
    EDIT: "Bewerken",
    EDIT_OR_CARE_PATH: "Bewerken/Zorgtraject",
    EVIDENCE_LINKER: "Evidence Linker",
    ARCHIVE: "Archiveren",
    DEACTIVATE: "Antecedenten",
    ACTIVATE: "Activeren",
    REMOVE: "Verwijderen",
    ADD: "Toevoegen",
    TO_PLAN: "Plannen",
    FILTER: "Filteren",
    SHOW_RLM: "LZ-module weergeven",
    CANCEL_FILTER: "Filter annuleren",
    NO_ACTION: "Geen enkele actie beschikbaar",
    PRESET: {
      ALCOHOL: {
        MENU_ITEM: "Alcohol",
        DESCRIPTION: "Alcohol",
      },
      TOBACCO: {
        MENU_ITEM: "Tabak",
        DESCRIPTION: "Tabaksgebruik",
      },
      DIABETES2: {
        MENU_ITEM: "Zorgtraject: diabetes type 2",
        DESCRIPTION: "Diabetes type 2",
      },
      PREDIABETES: {
        MENU_ITEM: "Zorgtraject: voortraject diabetes",
        DESCRIPTION: "Voortraject diabetes",
      },
      RENAL_FAILURE: {
        MENU_ITEM: "Zorgtraject: nierfalen",
        DESCRIPTION: "Nierfalen",
      },
    },
    CREATE_DIAGNOSIS: {
      PROBLEM: "Een medisch probleem creëren",
      MEDICAL_ANTECEDENT: "Een medisch antecedent creëren",
      SURGICAL_ANTECEDENT: "Een chirurgisch antecedent creëren",
      GYNECOLOGICAL_ANTECEDENT: "Een gynaeco-verloskundig antecedent creëren",
    },
    PREVENTION: {
      CERVIX_UTERIC_CANCER: "Preventie van baarmoederhalskanker",
      COLORECTAL_CANCER: "Preventie van colorectale kanker",
      BREAST_CANCER: "Preventie van borstkanker",
      PROSTATE_CANCER: "Preventie van prostaatkanker",
      OSTEOPOROSIS: "Preventie van osteoporose",
      INFLUENZA: "Preventie van seizoensgriep",
      seasonalinfluenza: "Preventie van seizoensgriep",
      AORTIC_ANEURYSM: "Abdominaal aorta-aneurysmapreventie",
      LUNG_CANCER: "Longkankerpreventie",
      ACTION: {
        PLAN_TASK: "Een nieuwe preventietaak creëren",
        REFUSED: "Geweigerd door de patiënt",
        NON_INDICATED: "De preventie markeren als niet aangewezen",
      },
      TOOLTIP: {
        NO_OR_OVERDUE_TASK: "Geen taak geassocieerd of ze is verstreken.",
        NEXT_TASK: "Volgende preventietaak gepland op {{date}}.",
        REFUSED_DATE: "Preventie geweigerd op {{date}}.",
        MARKED_NON_INDICATED_DATE:
          "Preventie gemarkeerd als niet aangewezen op {{date}}.",
      },
    },
    CREATE_SUCCESS: '"{{descr}}" gecreëerd.',
    CREATE_ELEMENT_SUCCESS: 'Zorgelement "{{descr}}" gecreëerd.',
    CREATE_ELEMENT_FAILURE: "De creatie van het zorgelement is mislukt.",
    EDIT_SUCCESS: '"{{descr}}" gewijzigd.',
    ARCHIVE_SUCCESS: '"{{descr}}" gearchiveerd.',
    DEACTIVATE_SUCCESS: '"{{descr}}" gemarkeerd als antecedent.',
    ACTIVATE_SUCCESS: '"{{descr}}" gemarkeerd als actief.',
    REMOVE_SUCCESS: '"{{descr}}" verwijderd.',
    REMOVE_FAILURE: 'Het verwijderen van "{{descr}}" is mislukt.',
    CANNOT_REMOVE_ATTACHED:
      '"{{descr}}" kan niet worden verwijderd: het is verbonden aan een contact.',
    HEALTH_ELEMENT: "Zorgelement",
    NEW_HEALTH_ELEMENT: "Een zorgelement toevoegen",
    CLASSIFICATION: "Classificatie",
    ATTACHED_N: "Zorgelement verbonden aan {{n}} subcontacten.",
    FAILED_TO_ATTACH_N:
      "De associatie van het zorgelement is mislukt voor {{n}} sub-contacten.",
  },
  "health-element-tree-service": {
    PREVENTION_CODE_NOT_FOUND: "Codificatie van preventie niet gevonden.",
    AUTO_CREATED_PREVENTIONS: "Preventies automatisch gecreëerd.",
    NOT_INDICATED_PREVENTIONS:
      '"{{prevention}}" werd gewijzigd in "niet aangegeven"',
  },
  "patient-journal-content": {
    TYPE_FILTER_PREFIX: "Filteren op",
    EXPORT: "Exporteren",
    EXPORT_CSV: "Exporteren in csv",
    EXPORT_ERROR: "Fout bij exporteren",
    CONTACTS: "Contacten",
    FILTERS: "Filters",
    REFRESH: "Formulier wissen",
    CREATED_BEFORE: "Gecreëerd voor",
    CREATED_AFTER: "Gecreëerd na",
    CREATED_BY: "Ingevoerd door",
    AUTHOR: "Auteur",
    SEARCH_CONTACTS: "Opzoeken",
    TEXT: "Tekst",
    SORT: "Sorteren",
    ANTICHRONOLOGICAL: "Antechronologisch",
    CHRONOLOGICAL: "Chronologisch",
    CANCEL: "Annuleren",
    APPLY: "Toepassen",
    SERVICES: "Diensten",
    ALL: "Alle",
    SERVICE_GROUP_TYPES: {
      PLAN_TEXT: "Plan tekst",
      PLAN_TREATMENT: "Voorschrift",
      PLAN_VACCINE: "Vaccin",
      PLAN_INCAPACITY: "Ongeschiktheidsattest",
      PLAN_DOCUMENT: "Documenten",
      PLAN_INCAPACITY_MEDICAL: "Ongeschiktheidsattest voor medisch adviseur",
      PLAN_INCAPACITY_MEDEX: "Ongeschiktheidsattest generiek",
      PLAN_PHYSIOTHERAPY: "Verzoek kine",
      PLAN_MEDICAL_CARE: "Verzoek zorgverstrekker",
      PLAN_RADIOGRAPHY: "Verzoek RX",
      PLAN_PROCEDURE: "Procedure",
      S_PAIN_SCALE: "Schaal van subjectieve pijn",
      O_TEXT: "Tekst doel",
      O_PAIN_SCALE: "Schaal van objectieve pijn",
      O_KATZ_SCALE: "Katz-schaal",
      O_BIOMETRY: "Biométrie",
    },
    FILTER: {
      CONTACT: "contacten",
      "PHONE-CONTACT": "Raadgeving/divers",
      LAB: "laboratoria",
      REPORT: "rapporten",
      SPECIALITY: "Filteren op specialiteit",
      DATE: "Filteren op datum",
      "MULTI-DISCIPLINARY": "multidisciplinaire contacten",
      CONSULTATION_DATE: "Filteren op een zorgperiode",
    },
    TASKS: "Takenlijst",
    TASKS_LATE: "U hebt verouderde taken",
    SUMMARY: "Samenvatting",
    SEARCH: "Opzoeken",
    CLOSE: "Afsluiten",
    CONTACT: "Consultatie",
    CONTACT_COUNT: "Aantal contacten",
    NEW_CONTACT: "Nieuwe consultatie",
    CONTACT_TYPE_OPTIONS: "Nieuw contact van een ander type",
    CURRENT_CONTACT: "Huidige consultatie",
    NO_CONTACT: "Er is geen contact om weer te geven.",
    EDITING_CONTACT: "Contact wordt bijgewerkt",
    REPORT: "Rapport",
    NEW_REPORT: "Nieuw rapport",
    error: {
      MOTIVATION_AND_ASSESSMENT_ARE_MANDATORY:
        "Het motief en de diagnose zijn verplicht om een consultatie af te sluiten.",
      MOTIVATION_ASSESSMENT_ORIENTATION_AND_REPORT_ARE_MANDATORY:
        "Het motief, de diagnose, de patiëntverwijzing en het wachtrapport zijn verplicht om een consultatie af te sluiten.",
      REPORT_MOTIVATION_ASSESSMENT_AND_ORIENTATION_ARE_MANDATORY:
        "Het motief, de diagnose en de patiëntverwijzing zijn verplicht om een wachtrapport te genereren.",
      COULD_NOT_CREATE_CONTACT: "De creatie van het contact is mislukt.",
      COULD_NOT_ATTACH_HEALTH_ELEMENT:
        "Het geselecteerde zorgelement kan niet worden verbonden met het nieuwe contact.",
    },
    warning: {
      CANNOT_CLOSE_FALLING_BACK_TO_SAVE:
        "Het contact kan niet worden afgesloten. Bewaren van het contact is bezig.",
    },
    "windows-close-confirmation": {
      TITLE: "Bevestiging",
      WARNING:
        "Wilt u de huidige consultatie vensters sluiten en al uw coderingen annuleren?",
      CANCEL: "Annuleren",
      CONFIRM: "Bevestigen",
    },
  },
  "patient-dmg": {
    DMG_TITLE: "GMD",
    INSCR: "Schrijf u in bij het GMD.",
    INSCR_OK: "U bent ingeschreven bij het GMD.",
    DMG_VALIDATE_OK: "Aanvraag verstuurd",
    INFO1:
      "De aanvraag voor een nieuwe GMD moet gebeuren binnen 7 dagen van de geassocieerde code.",
    LAST_CONSULTATION_DATE: "Datum van laatste consultatie",
    VALIDATE: "Valideren",
    DATE: "Datum",
    NOMENCLATURE: "Nomenclatuur",
    STATE_INSCR: "Status van uw inschrijving bij het GMD",
    MISSING_SSIN: "De patiënt heeft geen INSZ",
    NO_INSURABILITY: "De patiënt heeft geen gegevens inzake verzekerbaarheid",
    NO_MUTUALITY_CODE: "De patiënt heeft geen mutualiteitscode",
    NO_TITULAIRE_ID: "De patiënt heeft geen ID-titularis",
    ACTUAL_STATE: "Huidige status van het GMD van de patiënt",
    RESPONSIBLE: "Verantwoordelijke: ",
    FROM: "Van",
    TO: "tot",
    NOTIFY: "Het GMD aanvragen",
    ERR_NOTIFY: "Het GMD kon niet worden aangevraagd.",
    CONSULT: "Consulteren",
    REJECT: "Aanvraag verworpen",
    ACCEPT: "Aanvraag aanvaard",
    FROM_ACC: "vanaf",
    PAYMENT_OK: "Globaal medisch dossier al betaald voor dit jaar",
    PAYMENT_NOK:
      "Het globaal medisch dossier is nog niet betaald voor dit jaar",
    YES: "Ja",
    NO: "Nee",
    ASSISTANT_ALONE:
      "Een assistent kan geen kennisgeving GMD doen zonder geldige stagemeester",
    ASSITANT_WITHOUT_MASTER_HCP:
      "Om een  kennisgeving GMD te doen als assistent moet u voorafgaand uw stagemeester instellen in uw gegevensfiche.",
    DOCTOR_NOT_REGISTERED:
      "Om een kennisgeving GMD de toen moet u voorafgaand in orde zijn met uw inschrijvingen. Raadpleeg uw inschrijvingen vanop uw eGMD-scherm.",
    REFERRAL_HISTORY: "Historiek",
  },
  "patient-hub": {
    UNKNOWN_ALLERGY: "Onbekende allergie",
    CDHCPARTY: {
      deptanatomopathology: "anatomopatologie",
      deptanesthesiology: "anesthesiologie",
      deptbacteriology: "bacteriologie",
      deptcardiology: "cardiologie",
      deptdermatology: "dermatologie",
      deptdietetics: "dieetleer",
      deptemergency: "urgentiediensten",
      deptgastroenterology: "gastro-enterologie",
      deptgeneralpractice: "algemene geneeskunde",
      deptgenetics: "genetica",
      deptgeriatry: "geriatrie",
      deptgynecology: "gynaecologie",
      depthematology: "hematologie",
      deptintensivecare: "intensieve zorgen",
      deptkinesitherapy: "kinesitherapie",
      deptlaboratory: "laboratorium",
      deptmedicine: "interne geneeskunde",
      deptmolecularbiology: "moleculaire biologie",
      deptneonatalogy: "neonatologie",
      deptnephrology: "nefrologie",
      deptneurology: "neurologie",
      deptnte: "otorhinolaryngologie",
      deptnuclear: "nucleaire geneeskunde",
      deptoncology: "oncologie",
      deptophtalmology: "oogheelkunde",
      deptpediatry: "pediatrie",
      deptpharmacy: "Apotheek",
      deptphysiotherapy: "fysiothérapi",
      deptpneumology: "pneumologie",
      deptpsychiatry: "psychiatrie ",
      deptradiology: "radiologie",
      deptradiotherapy: "radiotherapie",
      "deptrhumatology (deprecated)": "reumatologie",
      deptstomatology: "stomatologie",
      deptsurgery: "chirurgie",
      depttoxicology: "toxicologie",
      depturology: "urologie",
      orghospital: "hospitaal",
      orginsurance: "verzekering",
      orglaboratory: "onafhankelijk laboratorium",
      orgpublichealth: "openbare gezondheidsdienst",
      persbiologist: "medisch laboratoriumtechnicus",
      persdentist: "tandarts",
      persnurse: "verpleegkundige",
      persparamedical: "paramedisch personeel",
      perspharmacist: "apotheker",
      persphysician: "arts",
      perssocialworker: "sociaal werker",
      perstechnician: "Medische beeldtechnicus",
      persadministrative: "administratief personeel",
      persmidwife: "vroedvrouw",
      application: "software-applicatie",
      deptorthopedy: "orthopedie",
      orgpractice: "kabinet",
      orgpharmacy: "Onafhankelijke apotheek",
      deptalgology: "algologie",
      deptcardiacsurgery: "hartchirurgie",
      depthandsurgery: "handchirurgie",
      deptmaxillofacialsurgery: "maxillofaciale chirurgie",
      deptpediatricsurgery: "pediatrische chirurgie",
      deptplasticandreparatorysurgery:
        "cosmetische en reconstructieve chirurgie",
      deptthoracicsurgery: "thoracale chirurgie",
      deptvascularsurgery: "vasculaire chirurgie",
      deptvisceraldigestiveabdominalsurgery:
        "abdominale, digestieve en viscerale chirurgie",
      deptdentistry: "tandartsenij",
      deptdiabetology: "diabetologie",
      deptendocrinology: "endocrinologie ",
      deptoccupationaltherapy: "ergotherapie",
      deptmajorburns: "grote brandwonden",
      deptinfectiousdisease: "besmettijke ziektes",
      deptspeechtherapy: "logopedie",
      deptsportsmedecine: "sportgeneeskunde",
      deptphysicalmedecine: "fysische geneeskunde",
      depttropicalmedecine: "tropische geneeskunde",
      deptneurosurgery: "neurologie",
      deptnutritiondietetics: "voedingsdiëtetiek",
      deptobstetrics: "ergotherapie",
      deptchildandadolescentpsychiatry:
        "psychiatrie voor kinderen en adolescenten",
      deptpodiatry: "podotherapie",
      deptpsychology: "psychologie",
      deptrevalidation: "heropvoeding",
      deptsenology: "senologie",
      deptsocialservice: "sociale dienst",
      deptpediatricintensivecare: "pediatrische intensieve zorgen",
      deptpalliativecare: "palliatieve zorgen",
      deptrheumatology: "reumatologie",
      persambulancefirstaid: "eerste hulp ambulance",
      persaudician: "psychologie",
      persaudiologist: "audioloog",
      perscaregiver: "zorgverstrekker",
      persdietician: "diëtetist",
      perseducator: "opvoeder",
      perslogopedist: "logopedist",
      persoccupationaltherapist: "ergotherapeut",
      "persorthopedist (deprecated)": "orthopedist",
      persorthoptist: "orthoptist",
      persoptician: "oogarts",
      perspharmacyassistant: "apotheekassistent",
      persphysiotherapist: "kinesitherapeut",
      perspodologist: "podoloog",
      perspracticalnurse: "verpleeghulp",
      perspsychologist: "psycholoog",
      orgprimaryhealthcarecenter: "wijkgezondheidscentrum",
      guardpost: "Wachtpost",
      groupofphysicianssameaddress: "Groep artsen met hetzelfde adres",
      groupofphysiciansdifferentaddress: "Groep artsen met verschillend adres",
      groupofnurses: "Groep verpleegkundigen",
      certificateholder: "Titularis van het certificaat",
      perstrussmaker: "Bandagist",
      patient: "Patiënt",
      persorthotist: "Orthesist/Prothesist",
      perspatientdriver: "Vervoerder van patiënten (niet-dringend)",
      orgprevention: "Preventiedienst",
      perspharmacistclinicalbiologist: "klinisch apotheker-bioloog",
      hub: "hub",
      deptnursing: "verpleegkundige zorgen",
      persoraldentalhygienist: "Mondhygiënist",
      persclinicalpsychologist: "Klinisch psycholoog",
      persclinicalorthopedagogist: "Klinisch orthopedagoog",
      unknown: "onbekend",
    },
    ACCESS: {
      yes: "Ja",
      no: "Kan worden vrijgegeven door de behandelende arts",
      by_author: "Kan worden vrijgegeven door de auteur",
      unknown: "Onbekend",
      never: "Nooit",
    },
    CDPATIENTWILL: {
      ntbr: "Niet reanimeren",
      bloodtransfusionrefusal: "Weigering van bloedtransfusie",
      intubationrefusal: "Weigering van intubatie",
      euthanasiarequest: "Weigering van euthanasie",
      vaccinationrefusal: "Weigering van vaccinatie",
      organdonationconsent: "Toestemming voor orgaandonatie",
      datareuseforclinicalresearchconsent:
        "Hergebruik van gegevens voor klinisch onderzoek",
      datareuseforclinicaltrialsconsent:
        "Hergebruik van gegevens voor klinische testen",
      clinicaltrialparticipationconsent:
        "Toestemming voor deelname aan klinische testen",
      omissionofmedicaldata:
        "Gegevens werden weggelaten op verzoek van de patiënt",
    },
    "CD-PATIENTWILL-HOS": {
      undefined: "?",
      hos0: "Geen beperking op hospitalisatie",
      hos1: "Geen hospitalisatie",
      hos2: "Hospitalisatie enkel in specifieke comfortsituaties",
    },
    "CD-PATIENTWILL-RES": {
      undefined: "?",
      dnr0: "Geen therapiebeperking",
      dnr1: "Geen reanimatie",
      dnr2: "Geen reanimatie en therapie niet uitbreiden",
      dnr3: "Geen reanimatie en therapie afbouwen",
    },
    STATE: {
      title: "Toestand",
      LOADING: "Laden",
      COMPLETED: "OK",
      ERROR:
        "Er is een fout opgetreden. Probeer later opnieuw of neem contact op met de helpdesk.",
    },
    STATUS: {
      ABORTED: "Afgebroken",
      ACTIVE: "Actief",
      ADDED: "Toegevoegd",
      ADMINISTRATED: "Geadministreerd",
      CANCELLED: "Geannuleerd",
      COMPLETED: "Afgewerkt",
      CORRECTED: "Verbeterd",
      DELIVERED: "Geleverd",
      SUBSTITUTED: "Vervangen",
      INACTIVE: "Inactief",
      PLANNED: "Gepland",
      PRESCRIBED: "Voorgeschreven",
      REPORTED: "Gerapporteerd",
      REFUSED: "Geweigerd",
      SWITCHED: "Omgezet",
      SUSPENDED: "Opgeschort",
      STOPPED: "Stopgezet",
      EXCLUDED: "Uitgesloten",
      NOTPRESENT: "Niet aanwezig",
      ORDERED: "Besteld",
      SCHEDULED: "Voorzien",
      TODO: "Te doen",
      POSTPONED: "Uitgesteld",
      PENDING: "In afwachting",
      EXPECTED: "Verwacht",
      OBTAINED: "Verkregen",
      PROPOSED: "Voorgesteld",
      RETRACTED: "Ingetrokken",
    },
    HUB_TITLE: "Hub",
    LOADING: "Controle van de gegevens, even geduld",
    SELECT_A_HUB: "Selecteer een hub om te bevragen",
    DATE_FILTER: "Filteren op datum",
    DATE_FILTER_START: "Van",
    DATE_FILTER_END: "tot",
    DOCUMENTS: {
      TAB_TITLE: "Documenten",
      CONTENT_NAME: "Inhoud",
      AUTHOR: "Auteur",
      NAME: "Naam",
      VALUE: "Waarde",
      ATTACHMENTS: "Bijlagen",
      FILE: "Bestand",
      OPEN_FILE: "Bestand openen",
      IMPORT_PATIENT_FOLDER: "Importeren in Dossier",
      NO_DATA: "Geen enkel document beschikbaar voor deze patiënt",
      NO_TRANSACTION_DATA:
        "Er is (nog) geen enkel document beschikbaar voor dit element.",
    },
    TABS: {
      PREVIEW: "Algemene weergave",
      ALLERGIES: "Allergieën",
      ADR: "Intoleranties",
      RISKS: "Risicofactoren",
      DIAGNOSIS: "Diagnoses",
      MEDICATIONS: "Medicaties",
      VACCINES: "Vaccinaties",
      CONTACTHCPARTY: "Zorgverstrekkers",
      CONTACTPERSON: "Contacten",
      TREATMENTS: "Behandelingen",
    },
    SUMEHR: {
      TAB_TITLE: "Sumehrs",
      SUMHER_TITLE: "Sumehrs",
      TITLE: "Lijst van Sumehrs",
      REVOKE: "Sumehr herroepen",
    },
    SEND_SUMEHR: {
      NEW_SUMEHR: "Nieuwe Sumehr",
      INFO_1:
        "De informatie werd automatisch vervolledigd op basis van het patiëntendossier.",
      INFO_2: 'Klik op  "Versturen" of bewerk de inhoud handmatig.',
      DATE: "Datum: ",
      NO_ELEMENT: "Geen element in het dossier",
      ERROR_GET_DATA: "Onmogelijk de elementen van het dossier te bekomen",
      ERROR_SAVE: "De statuswijziging kon niet worden geregistreerd",
      SEND_SUMEHR: "Een Sumehr versturen",
      ENTER_PASSWORD:
        "Geef het wachtwoord in om de Sumehr te genereren en te versturen. Dit wachtwoord moet worden ingevoerd door de helpdesk.",
      BACK: "Terug",
      SEND: "Versturen",
      SEND_OK: "Sumehr verstuurd",
      SEND_ERROR: "Fout bij versturen van de Sumehr",
      GENERATION_ERROR: "Fout bij het genereren van de Sumehr",
      INVALID_PASSWORD: "Wachtwoord ongeldig",
      MEDICATION: "Behandelingen",
      WILL: "Wilsbeschikking",
      DOWNLOAD_XML: "XML downloaden",
      CONFIDENTIAL: "Vertrouwelijk",
    },
    SEND_DIARY_NOTE: {
      NEW_DIARY_NOTE: "Nieuwe nota",
      DATE: "Datum:",
      BACK: "Terug",
      SEND: "Versturen",
      CHOOSE_DIARY: "Kies een categorie: ",
      SEND_OK: "Nota verstuurd",
      SEND_ERROR: "Fout bij versuren van journaal",
      antibiotherapy: "Antibiotherapie",
      diabetes: "Diabetes",
      medication: "Medicatie",
      movement: "Beweging",
      nutrition: "Voeding",
      oncology: "Oncologie",
      renalinsufficiency: "Nierfalen",
      woundcare: "Wondverzorging",
    },
    DOCUMENT: {
      TYPE: "Type",
      SERVICE: "Dienst",
      HOSPITAL: "Hospitaal",
      DATE: "Datum",
      HCP_LASTNAME: "Naam van de arts",
      HCP_FIRSTNAME: "Voornaam van de arts",
      CONTENT: "Tekstinhoud",
      LINKS: "Links",
      LINK: "Link",
      FILES: "Bestanden",
    },
    TRANSACTIONS: {
      DATE: "Datum",
      AUTHOR: "Auteur",
      PROFILE: "Profiel",
      SERVICE: "Dienst",
      SITE: "Site",
      ACCESSIBILITY: "Toegankelijk voor patiënt",
      ACCESS_DATE: "Toegangsdatum patiënt",
      IMPORT_TRANSACTION: "Importeren van de transactie: actie vereist",
      CATEGORIES: "Categorieën",
      VERSION: "Versie",
      VALIDITY: "Geldigheid",
    },
    MEDICATION_SCHEMA: {
      TAB_TITLE: "Medicatieschema",
      SEND_MED_SCHEME: "Schema synchroniseren",
      LOADING: "Laden medicatieschema",
      SYNCHRONIZE: "Synchronisatie van het medicatieschema",
    },
    VACCINATION: {
      VACCINATION: "Vaccinatie",
      encounterlocation: "Land waar het vaccin is toegediend : ",
    },
    PATIENT_NOTES: {
      TAB_TITLE: "Nota's patiënt",
      SEND_NOTES: "Nota versturen",
    },
    HCP_NOTES: {
      TAB_TITLE: "Journaal",
    },
    VACCINATIONS: {
      TAB_TITLE: "Vaccinaties",
    },
    LAB_RESULTS: {
      TAB_TITLE: "Laboresultaten",
    },
    SPECIALITIES: {
      TAB_TITLE: "Andere",
    },
    WHATSAPPVIEW: {
      TABLE: "Lijst",
      JOURNAL: "Journaal",
    },
    PATIENT: {
      title: "Patiënt",
      ssin: "INSZ",
      lastName: "Naam",
      firstName: "Voornaam",
      sex: "Geslacht",
      birthDate: "Geboortedatum",
      wills: "Wilsbeschikking",
      hos: "Hospitalisatie",
      res: "Reanimatie",
    },
    GMD_ADMINISTRATION: {
      title: "Administrateur GMD",
      nihii: "RIZIV",
      lastName: "Naam",
      firstName: "Voornaam",
      date: "Datum",
    },
    AUTHOR: {
      title: "Auteur",
      nihii: "RIZIV",
      profile: "Profiel",
      lastName: "Naam",
      firstName: "Voornaam",
      address: "Adres",
      phone: "Telefoon",
      mobile: "GSM",
      UNKNOWN: "Onbekend",
    },
    PERSON: {
      birthdate: "Geboortedatum",
      ssin: "Rijksregisternummer",
      sex: "Geslacht",
      relationship: "Relatie",
    },
    WRITER: {
      title: "Editor",
    },
    ERRORS: {
      CANNOT_DECODE_MEDICATION:
        "Medicatieplannings zijn binnen Medispring nog niet zichtbaar. We vragen u om naar de applicatie van uw hub te gaan.",
      NO_SSIN: "De patiënt heeft geen geregistreerd rijksregisternummer",
      NO_EHEALTH: "U bent niet verbonden met het eHealth-netwerk",
      NO_CONSENT:
        "De patiënt heeft zijn toestemming niet gegeven voor het delen van zijn gegevens",
      NO_THERLINK: "U hebt geen therapeutische link met de patiênt",
      SERVER_ERROR: "Onmogelijk de gevraagde hulpbron te recupereren.",
      NO_WORK_ZIP_CODE:
        'U moet een "werk"-adres in uw profiel invullen om een Sumehr te kunnen sturen.',
      DISABLED:
        "De functionaliteit van de hub is gedeactiveerd voor deze verbinding",
    },
    IMPORT: {
      SUCCESS: "Document succesvol geïmporteerd",
      CANCELED: "Importeren van de transactie geannuleerd",
      LAST_NAME: "Naam",
      FIRST_NAME: "Voornaam",
      INAMI: "RIZIV-nummer",
      NATIONAL: "Rijksregisternummer",
      NEW_HCP_LABEL: "Selecteer een auteur in uw lijst van betrokkenen",
      NEW_HCP: "Een nieuwe betrokkene creëren",
    },
    DIALOG: {
      AUTHOR_IN_TRANSACTION:
        "Informatie gevonden over de auteur in de transactie:",
      REASONS: {
        no_author:
          "De auteur van de transactie is onvindbaar, gelieve deze te specificeren.",
        no_match:
          "De auteur van de transactie kon niet worden gevonden in Medispring. Selecteer of creëer een prestataris.",
        multiple_match:
          "Meerdere prestatarissen werden gevonden voor deze transactie. Selecteer er één van.",
      },
    },
    VALIDATE: "Valideren",
    CANCEL: "Annuleren",
    DESCRIPTION: "Omschrijving",
    START: "Begin",
    END: "Einde",
    STATUS_TITLE: "Status",
    CERTAINTY: "Zekerheid",
    COMMENT: "Nota",
    POSOLOGY: "Doseringsschema",
    CNKATCCODE: "Cnk/Atc-code",
    TEMPORALITY: "Tijdelijkheid",
    DATE: "Datum",
    INDICATIONS: "Indicaties",
  },
  "sumehr-import": {
    IMPORT_IN_PATIENT_FILE: "Importeren in het dossier van de patiënt",
    IMPORT_SUMEHR: "Sumehr-gegevens importeren",
    SELECT_ALL: "Alles selecteren",
    DESELECT_ALL: "Alles deselecteren",
    CANCEL: "Annuleren",
    IMPORT: "Sumehr invoeren ",
    MEDISPRING: "Medispring",
    SUMEHR: "Sumehr",
    STATUS: "Status",
    WILLS: "Wilsbeschikking",
    HEALTH_PROBLEMS: "Actieve Gezondheidselementen",
    CURRENT_TREATMENTS: "Actieve behandelingen",
    ANTECEDENTS: "Antecedenten",
    ALLERGIES: "Allergieën",
    INTOLERANCE: "Intoleranties",
    RISK_FACTORS: "Risicofactoren",
    VACCINES: "Vaccinaties invoeren",
    CONTACT_HC_PARTIES: "Zorgverstrekkers",
    ANTECEDENT_TYPES: {
      MEDICAL: "Medisch",
      SURGICAL: "Chirurgisch",
      FAMILY_RISK: "Familiaal",
      GYNECOLOGICAL: "Gynaeco-verloskundig",
    },
    RISK_FACTOR_TYPES: {
      MEDICAL: "Medisch",
      SOCIAL: "Sociaal",
    },
    VACCINE_TYPES: {
      ADULT: "Volwassene",
      CHILD: "Kind",
    },
    VACCINE_TIMELINE: {
      DURING_CHILDHOOD: "Tijdens kindertijd",
      DURING_ADULTHOOD: "Op volwassen leeftijd",
    },
    NO_DATA_AVAILABLE: "Geen gegevens beschikbaar",
    TREATMENT_TYPES: {
      CHRONIC_TREATMENTS: "Chronische behandelingen",
      ACUTE_TREATMENTS: "Acute behandelingen",
      ONESHOT_TREATMENTS: "One-shot behandelingen",
    },
    WILLS_STATUSES: {
      authorize: "Aanvaard",
      refuse: "Geweigerd",
      undefined: "Niet gespecificeerd",
    },
    SUCCESS: "Sumehr-gegevens succesvol geïmporteerd",
    FAILURE: "Importeren van Sumehr-gegevens mislukt",
  },
  "patient-invoicing": {
    TITLE: "Prestaties",
    LOADING_DATA: "Laden van uw gegevens",
    PATIENT_NO_INSURABILITY:
      "Geen verzekeringsgegevens gevonden voor de patiënt.",
    PATIENT_NO_VALID_DATA:
      "De QA-code van de patiënt is leeg of ongeldig, de eFact- en eAttest-opties zijn gedeactiveerd.",
    paper: "Papier",
    eattest: "eAttest",
    efact: "eFact",
    stat: "stat",
    payingagency: "Betalingsinstantie",
    patient: "Patiënt",
    mutualfund: "Mutualiteit",
    undefined: "Onbekend",
    COBRHA_ERROR:
      "Onmogelijk op dit moment een Cobrha-zoekopdracht uit te voeren.",
    PATIENT_NO_SSIN:
      "De patiënt heeft geen geregistreerd INSZ: de elektronische facturatie is niet beschikbaar.",
    UNKNOW_MUT: "Mutualiteit onbekend",
    UNKNOW_HCP: "Arts onbekend",
    PATIENT: "Patiënt",
    EHEALTH_NOT_CONNECTED: "U bent niet verbonden met de eSante-diensten.",
    JUSTIFICATIONS: {
      NONE: "Geen",
      OTHER: "Andere",
      DEATH: "Overlijden, coma",
      FINANCIAL_DISTRESS: "Financiële moeilijkheden",
      BIM: "RVV",
      FORFAIT:
        "Diensten verleend op grond van een overeenkomst die voorziet in de forfaitaire betaling van bepaalde diensten ",
      MENTAL_CENTER:
        "Centrum voor geestelijke gezondheid, gezinsplanning, centrum voor drugsverslaving ",
      CHILD_OLD_HANDICAP_CARE_CENTER:
        "Etablissement voor zorg voor kinderen, ouderen of gehandicapten",
      CARE_SERVICE: "Wachtdienst voor algemene geneeskunde",
      HOME_PALIATIVE_SERVICE: "Palliatieve thuiszorgen",
      FORBIDDEN: "Verbod op facturering voor betaling door derden via eFact",
      CHRONICAL: "Chronische ziekte",
    },
    ROUNDING: {
      HALF_EURO: "Afgerond op een halve euro",
      EURO_UP: "Afgerond naar boven op 1 euro",
    },
    PAYMENT_METHOD: {
      CASH: "Cash",
      BANCOTACT: "Bancontact",
      VIREMENT: "Overschrijving",
    },
    NETWORK_ERROR: "Netwerk fout. Probeer opniew.",
    INVOICE_CREATION: {
      ERROR: "Fout bij versturen eAttest",
      SUCCESS: "De verklaring is succesvol gecreëerd",
      FAILURE: "Het opslaan van de factuur is mislukt",
    },
    PREFERENCE_UPDATE_FAILURE: {
      PATIENT: "Fout tijdens het bijwerken van de voorkeuren van de patiënt.",
      HCP: "Fout tijdens het bijwerken van uw voorkeuren",
    },
    ETAR: {
      SUCCESS: "Bijwerken van de eTar-gegevens",
      EHEALTH_CONNECTION_ISSUE: "eHealth-dienst niet verbonden",
      MISSING_DATA: "eTarification-gegevens niet opgehaald",
      ERROR: "eTar-fout: {{ error }}",
      JUSTIFICATION: "Rechtvaardiging: {{justification}}",
      ACCEPTED: "Betalingsverplichting geaccepteerd",
      FINANCIAL_CONTRACT: "Nummer contract: {{contract}}",
      NO_FINANCIAL_CONTRACT: "Geen betalingsverplichting",
    },
    PATIENT_INSURABILITY_INVALID:
      "Gegevens inzake verzekerbaarheid verouderd sinds {{date}}, de factuur zou door het verzekeringsfonds kunnen worden geweigerd",
  },
  "patient-invoicing-details": {
    SUPPLEMENT_WARNING: "Deze code kan gepaard gaan met een supplement!",
    MESSAGE1:
      "De code 469814 is al gebruikt dit jaar en kan niet meer worden gebruikt voor het lopende jaar. Gebruik de code 469630 of 469652.",
    MESSAGE2:
      "De code 475856 is dit jaar al twee keer gebruikt en kan niet meer worden gebruikt voor het lopende jaar..",
    MESSAGE3:
      "De code 475871 is dit jaar al twee keer gebruikt en kan niet meer worden gebruikt voor het lopende jaar..",
    MESSAGE4:
      "De code 475893 is dit jaar al twee keer gebruikt en kan niet meer worden gebruikt voor het lopende jaar..",
    MESSAGE5:
      "De code 476210 is al gebruikt in de afgelopen 12 maanden. Deze code kan meer eens om de 12 maanden worden gebruikt.",
    MESSAGE6:
      "De code 149612 is al gebruikt in de afgelopen 3 jaar. Deze code kan meer eens om de 3 jaar worden gebruikt.",
    MESSAGE7:
      "De code 101135 is al gebruikt in de afgelopen 30 dags. Deze code kan meer 5 eens om de 30 dagen worden gebruikt.",
    COVID_CODES_COME_ALONE: "De COVID-19-codes moeten apart worden verklaard.",
    COVID_CODES_REMOVE_SUPPLEMENTS:
      "De COVID-19-codes gaan de supplementen verwijderen.",
    MISSING_EHEALTH_DATA: "Gegevens inzake eHealth-verzekerbaarheid ontbreken.",
    HEALTHCARE_PARTY: "Zorgverlener",
    SEARCH_CAREGIVER: "Een zorgverstrekker zoeken",
    HEALTHCARE_PARTY_ASSISTANT: "Zorgverlener-stagiaire",
    DONE_UNDER_AUTHORITY:
      "Gedaan door {{ firstName }} {{ lastName}} onder de autoriteit van zijn stagemeester.",
    SEARCH_FOR_YOUR_MASTER:
      "Zoek uw stagemeester of uzelf als u in uw naam wenst te factureren.",
    ASSISTANT_INVOICING:
      "Als u uw stagemeester niet selecteert, dan zult u in uw naam factureren.",
    ASSISTANT_INVOICING_HELP:
      "Als u uw stagemeester niet vindt, definieer deze dan in uw betrokkenegegevens.",
    PRESCRIBER: "Voorschrijvende arts",
    HISTORY: "Historiek",
    PRESTATION_CODES: "Prestatiecodes",
    SEARCH_PRESTATION_CODES: "Consultatiecode zoeken",
    NO_HISTORY_YET: "Nog geen historiek",
    PRESTATIONS: "Prestaties",
    NO_PRESTATION_CODES_YET: "Nog geen code in de prestatie.",
    SEARCH_CUSTOM_CODES: "De code buiten RIZIV zoeken",
    SEARCH_TECHNICAL_CODE: "Code voor technische handeling zoeken",
    SUPPLEMENTS: "Supplementen",
    NO_SUPPLEMENT: "Geen supplement",
    EVENING: "Avond",
    WEEKEND: "Weekend",
    NIGHT: "Nacht",
    GUARD: "Wacht (18-21h)",
    CONFIRM: "Valideren",
    NEXT: "Volgende",
    PENDING_NEXT: "In afwachting",
    DELETE_PENDING_INVOICE: "Archiveren",
    MODIFY_PENDING_INVOICE: "Opslaan",
    FAILED_SUPPLEMENT_RETRIEVE:
      "Onmogelijk de supplementen op te halen. U kunt niet verder gaan.",
    ONE_CONSULTATION_LIMIT:
      "Er kan maar één consultatiecode verklaard worden. De vorige code werd vervangen.",
  },
  "patient-invoicing-pricing": {
    STATISTICS: "Stats",
    DISTANCE:
      '(Afstand <span class="font-weight-bold">heen en terug</span> in km)',
    MANDATED_BY_PHYSICIAN: "Verwezen door de huisarts",
    WARNING_SPECIFY_PRESCRIBER:
      'U moet  de huisarts die deze patiënt heeft doorverwezen in het het veld "voorschrijver" van het detail van de prestatiecode aangeven.',
    MISSING_BCE_EXCEPTION:
      "eAttest niet mogelijk zonder KBO nummer voor {{ firstName }} {{ lastName }}. Zie profiel.",
    COVID_19_EATTEST_EXCEPTION:
      "De COVID-19-codes kunnen niet onderwerp zijn van een eAttest.",
    PRICE_CHOICE: "Prijskeuze",
    HISTORY: "Historiek",
    OFFICIAL_PRICING: "Officiële prijs",
    NO_TM_ASKED: "Geen eigen bijdrage",
    ROUNDED_PRICE: "Afgeronde prijs",
    CUSTOM_PRICE: "Gepersonaliseerde prijs",
    SENDING_METHOD_CHOICE: "Verzendingsmethode kiezen",
    CODE: "Code",
    ERROR_CODE: "Error code",
    ERROR_DESCRIPTION: "Beschrijving",
    ERRORS: "Fout(en)",
    DATE: "Datum",
    EFACT: "eFact",
    EATTEST: "eAttest",
    ETAR: "eTar",
    STATUS: "Status",
    PAPER: "Papier",
    HONORAIRE: "Honorarium",
    INTERVENTION: "Interventie",
    TICKET_MODERATEUR: "Remgeld",
    ETAR__STATUS: "eTar-status",
    SPECIALIST: "eAttest is niet beschikbaar voor specialisten",
    TOTAL: "TOTAAL",
    MEDICAL_CARE_CHOICE: "Opgenomen door een externe betalingsinstantie",
    NO_MEDICAL_CARE: "Niet opgenomen",
    FULL_MEDICAL_CARE: "Totaliteit",
    ONLY_TM: "Enkel eigen bijdrage",
    ONLY_TM_WARNING:
      "Enkel eigen bijdrage voor de elementen in eFact of eAttest",
    PAYMENT_SUMMARY: "Bedrag betaald door",
    PATIENT_FEE: "De patiënt",
    MEDICAL_CARE_FEE: "Betalende instantie",
    EFACT_FEE: "Mutualiteit - eFact",
    PRINT: "Ontvangstbewijs afdrukken",
    ACCEPTED: "Ok",
    REJECTED: "Niet OK",
    FAILED: "Mislukt",
    CANCEL: "Annuleren",
    BACK: "Terug",
    CONFIRM: "Valideren",
    NOT_APPLICABLE_WARNING:
      "Niet van toepassing op mogelijke elementen in eAttest.",
    CHECK_ETAR: "eTar raadplegen",
    SELECT_OP: "Selecteer een betaalinstantie.",
    EFACT_101032_101076_WARNING:
      "Een consultatie 101032 of 101076 voor een RVV-patiënt moet naar eFact worden verstuurd.",
    SELECT_IO_PAYMENT_JUSTIFICATION:
      "Kies een rechtvaardiging voor betalende derde",
    SELECT_GMD_MANAGER_INAMI: "GMD-beheerder",
    SEND_DMG_INFOS: "Tarief met GMD toepassen",
    SELECT_GMD_MANAGER_INAMI_PLACEHOLDER: "RIZIV-nummer",
    EID_NUMBER: "Nummer identiteitskaart",
    MORE_INFO: "Meer informatie",
    EID_INFOS: "Identiteitskaartgegevens",
    CARD_NUMBER: "Kaartnummer",
    EID_HOUR: "Uur van lezen van de kaart",
    DETAILS: "Details",
    RELATED_CODE: "Gerelateerde prestatie",
    UNITS: "Eenheid",
    LOCATION: "Plaats van prestatie",
    PRESCRIBER: "Voorschrijver",
    PRESCRIPTION_DATE: "Datum van voorschrift",
    OVERRIDE_REIMBURSEMENT: "Interventie",
    OVERRIDE_PATIENT_INTERVENTION: "Remgeld",
    OVERRIDE_REIMBURSEMENT_AND_PATIENT_INTERVENTION:
      "Interventie en TM (officiële waarde)",
    LONG_DELAY_JUSTIFICATION: "Opheffen van voorschrift",
    EATTEST_DIFFERENT_THAN_TODAY:
      "U kunt geen eAttest versturen dat een prestatie bevat die niet op de datum van vandaag is.",
    EATTEST_MISSING_KHMER_OR_XADES:
      "Het eAttest-antwoord bevat geen xades of khmerDocument.",
    EATTEST_INCOHERENT_RESPONSE_CODES:
      "Het eAttest-antwoord bevat niet hetzelfde aantal codes als deze die verstuurd werden.",
    EATTEST_CODE_MAPPING_ERROR:
      "Onmogelijk de lokale eAttest-codes en deze van het antwoord te linken",
    NO_OP_PROVIDED: "De betaalinsantie is niet verstrekt of is ongeldig.",
    SEND_SUPPLEMENT: "De supplementen naar de OA sturen",
    DEROGATION_MAX_NUMBER: "Identieke prestatiecode",
    DEROGATION: {
      NO_DEROGATION: "Geen afwijking",
      OTHER: "Ander geval",
      DEROGATION_MAX_NUMBER: "Afwijking van het maximale aantal",
      OTHER_PRESCRIPTION: "Andere sessie/voorschrift",
      SECOND_PRESTATION_DAY: "2e identiek prestatie van de dag",
      THIRD_AND_NEXT_PRESTATION_DAY:
        "3e of volgende identieke prestatie van de dag",
    },
    PRESCRIBER_NORM: "Voorschrijfnorm",
    PRESC_NORM: {
      NONE: "Geen voorschrijver",
      ONE_PRESCRIBER: "De prestatie kan worden toegekend aan een voorschrijver",
      SELF_PRESCRIBER:
        "De prestaties worden uitgevoerd voor de eigen patiënten.",
      ADDED_CODE: "Het betreft toegevoegde prestaties",
      REFERRING_PHYSICIAN: "Verwezen door de huisarts",
      REBATE: "50% facturatie",
      MANY_PRESCRIBERS:
        "De prestatie is voorgeschreven door verschillende voorschrijvers. Het identificatienummer van één ervan is geregistreerd in de zone van de voorschrijver.",
    },
    SIDE_CHOICE: "Lateraliteit",
    SIDE: {
      NONE: "Geen",
      LEFT: "Links",
      RIGHT: "Rechts",
    },
    SELECT_THIRD_PARTY_JUSTIFICATION:
      "Kies een rechtvaardiging voor de betalende derde",
    EATTEST_BIM_PATIENT_ONLY_SEND_WITH_EXCEPTION_CODE:
      "Een RVV-patiënt kan geen eAttest versturen als er geen uitzonderingscode is ingegeven",
    EATTEST_CANT_BE_SELECTED: "eAttest kan niet worden geselecteerd",
    EATTEST_WITH_SAME_CODE_SAME_DAY_NOT_ALLOWED:
      "U kunt geen eAttest versturen voor eenzelfde patiënt met dezelfde code op dezelfde dag",
    Chimiotherapy: "Ambulante chemotherapiebehandeling",
    ProfessionalDisease: "Professionele ziektes",
    WorkAccident: "Werkongevallen",
    Accident: "Ongevallen - gewoonrecht (verantwoordelijkheid van derden)",
    Other: "Andere ongevallen",
    OTHER: "Andere",
    "hospital-services": {
      "190": "Afdeling niet-intensieve neonatale zorgen",
      "200": "Afdeling TBC-behandeling",
      "210": "Afdeling chirurgische diagnose en behandeling",
      "220": "Afdeling medische diagnose en behandeling",
      "230": "Afdeling pediatrie",
      "240": "Afdeling eenvoudige hospitalisatie",
      "250": "Afdeling besmettelijke ziektes",
      "260": "Afdeling materniteit́",
      "270": "Afdeling intensieve neonatale zorgen",
      "290":
        "Eenheid voor behandeling van grote brandwondens (opgericht op 01/01/94)",
      "300": "Afdeling geeriatrie en revalidatie",
      "320": "Chirurgisch dagziekenhuis",
      "340": "Afdeling infantiele neuropsychiatrie (dag en nacht)",
      "350": "Daghospitalisatie in K-dienst",
      "360": "Nachthospitalisatie in K-dienst",
      "370": "Afdeling neuropsychiatrie (dag en nacht)",
      "380": "Daghospitalisatie in A-dienst",
      "390": "Nachthospitalisatie in A-dienst",
      "410": "Afdeling psychiatrie (dag en nacht)",
      "420": "Daghospitalisatie in T-dienst",
      "430": "Nachthospitalisatie in T-dienst",
      "440": "Familieplaatsing Tf in een hospitaal (vanaf 01/04/2000)",
      "450": "Familieplaatsing Tp in een familie (vanaf 01/04/2000)",
      "480": "Afdeling intensieve behandeling voor psychiatriepatiënten",
      "490": "Functie of afdeling intensieve zorgen",
      "610":
        "Gespecialiseerde afdeling voor de behandeling en functionele revalidatie van patiënten met cardiopulmonale aandoeningen ",
      "620":
        "Gespecialiseerde afdeling voor de behandeling en functionele revalidatie van patiënten met bewegingsstoornissen",
      "630":
        "Gespecialiseerde afdeling voor de behandeling en functionele revalidatie van patiënten met neurologische aandoeningen",
      "640":
        "Gespecialiseerde afdeling voor de behandeling en functionele revalidatie van patiënten met chronische aandoeningen die palliatieve zorgen vereisen",
      "650":
        "Gespecialiseerde afdeling voor de behandeling en functionele revalidatie van patiënten met chronische poliepathologieën die langdurige medische zorg vereisen ",
      "660":
        "Gespecialiseerde afdeling voor de behandeling en functionele revalidatie van patiënten met psychogeriatrische aandoeningen",
      "710": "Gipszaal",
      "720":
        "Dringende zorgen of intraveneuze infusie (vroeger miniforfait)\n+ forfait oncologische basiszorgen",
      "730": "Maxiforfait + Oncologie maxiforfait",
      "750": "Nierdialyse",
      "760": "Volledige dag psychiatrie + revalidatie nazorg",
      "770": "Revalidatie",
      "840":
        "Forfait groep 1, 2, 3, 4, 5, 6 of 7 + forfait chronische pijn 1, 2, 3 + forfait manipulatie kamercatheter",
      "990": "Andere ambulante patiënten of ambulante nierdialyse",
      "002":
        "Prestaties verleend aan patiënten die in een andere instelling zijn opgenomen",
    },
    HOSPITAL_SERVICE: "Hospitaaldienst",
    NO_INVOICE_OF_0: "Sommige codes hebben een bedrag van nul.",
    TREATMENT_REASON: "Reden van behandeling",
    BIM_EXCEPTION: "eAttest niet-geautoriseerd (RVV-patiënt)",
    REGIONAL_EXCEPTION: "eAttest niet-geautoriseerd (regionale code)",
    FORBIDDEN_EXCEPTION: "eAttest niet-geautoriseerd voor deze code",
    MEDICAL_HOUSE_WARNING:
      "Uw patiënt is aangesloten bij een medisch centrum voor een forfait van",
  },
  "patient-invoicing-summary": {
    FAILED_TO_SEND_TO_EBOX:
      "Het versturen van de eAttest geloofsbrieven naar de eBox van de patiënt is mislukt.",
    EBOX_SENDING_SUCCESS:
      "De eAttest geloofsbrief werd naar de eBox van de patiënt gestuurd.",
    PATIENT_PAID: "Bedrag betaald door de patiënt",
    PATIENT_REMAINING_AMOUNT: "Resterend bedrag verschuldigd door de patiënt",
    PAYMENT_SUMMARY: "Bedrag verschuldigd door",
    TOTAL_SUMMARY: "Totaal van de bedragen voor",
    PRINT: "Afdrukken",
    SEND_TO_EBOX: "Stuur naar de eBox van de patiënt",
    FINISH: "Afsluiten",
    CLOSE: "Sluiten",
    CANCEL_EATTEST: "eAttest annuleren of wijzigen",
    NOT_APPLICABLE: "Niet van toepassing",
    PATIENT_NOT_FOUND: "De patiëtn werd niet gevonden.",
    ERROR_PRINT_DATE_UPDATED:
      "Onmogelijk de afdrukdata van de verklaring bij te werken.",
    DOCUMENT_TITLE: "Rechtvaardiging patiënt",
    INVOICE_NUMBER: "Volgnummer",
    CARE_PROVIDER: "Begeleidend arts",
    INTERNSHIP: "Arts-stagiaire",
    GMD_MANAGER: "GMD-beheerder",
    PATIENT: "Patiënt",
    REFUNDABLE_PRESTATIONS: "Terugbetaalbare prestaties",
    TYPE: "Type",
    NC: "Onbekend",
    STATUS: "Status",
    DATE: "Datum",
    CODE: "Code",
    TOTAL_PRICE: "Aangerekend honorarium",
    REIMBURSEMENT: "Interventie",
    PATIENT_INTERVENTION: "Eigen bijdrage",
    CONVENTION_AMOUNT: "Honorarium conventie",
    DIFF_AND_SUPPLEMENTS: "Verschillen en supplementen",
    NOT_DISCLOSED: "Niet gecommuniceerd",
    TOTAL: "Totaal",
    PAYMENT: "Betaling",
    PRINTED_FOR_PATIENT: "De rechtvaardiging werd afgegeven aan de patiënt",
    CANCELLED: "Geannuleerd",
  },
  "patient-invoicing-information": {
    BIM: "RVV-patiënt",
    PAYMENT_BY_IO: "Betalende derde",
    INSURABILITY_DATE: "Datum verzekerbaarheid",
    YES: "Ja",
    NO: "Nee",
    DMG: "GMD",
    EID_NUMBER: "Nummer identiteitskaart",
    UNKNOWN: "Onbekend",
    DMG_DATA: {
      NO_DMG: "Er is geen GMD.",
      DMG_UNKNOW: "GMD onbekend.",
    },
  },
  "patient-invoicing-edit": {
    CREDIT_NOTE_TITLE: "Bewerken kredietnota",
    DIALOG_TITLE: "Bewerken factuur",
    INVOICE_EDITION: "Bewerken van de factuur",
    CREDIT_NOTE_EDITION: "ÉBewerken van de kredietnota",
    PAYMENT_INFORMATIONS: "Betaalinformatie",
    PAYMENT_MODE: "Betaalmethode",
    CANCEL: "Annuleren",
    SET_CREDIT_NOTE: "Kredietnota opstellen",
    ARCHIVE: "Archiveren",
    REACTIVATE: "Heractiveren",
    APPLY_CORRECTION: "Corrigeren",
    SAVE: "Registreren",
    ERROR_INVOICE_MODIFY: "Onmogelijk de factuur bij te werken.",
  },
  "patient-invoicing-journal": {
    CANCELED: "Geannuleerd",
    INVOICING: "Attesten",
    REFRESH: "Verversen",
    DATE: "Datum",
    PRINTED: "Afgegeven aan patiënt",
    ATTESTATION_NUMBER: "Nr. verklaring",
    TOTAL: "Totaal",
    TOTAL_PATIENT: "Totaal patiënt",
    TOTAL_MUTUALITY: "Totaal mutualiteit",
    TOTAL_OA: "Totaal betalingsinstantie",
    EFACT: "eFact",
    EATTEST: "eAttest",
    PAPER: "Papier",
    STAT: "Stat",
    PAYED: "Betaald",
    PREVIEW: "Weergave",
    PAID_AMOUNT: "Betaald bedrag",
    PAID_AMOUNT_LIQUID: "Betaald bedrag contact",
    PAID_AMOUNT_BANCONTACT: "Betaald bedrag bancontact",
    PAID_AMOUNT_VIREMENT: "Betaald bedrag overschrijving",
    CANCEL_EATTEST: "Een eAttest annuleren",
    CLOSE: "Sluiten",
    PATIENT: "Patiënt",
    INVOICING_SUMMARY: "Samenvatting van de verklaring",
    INVOICE_LOADING_ERROR:
      "Er is een fout opgetreden tijdens het laden van de verklaringen",
    INVOICE_TREATMENT_ERROR:
      "Er is een fout opgetreden tijdens het behandelen van de verklaringen",
    VIEW_INVOICING: "Attest bekijken",
    SEARCH_EATTEST_BY_INVOICE: "Opzoeken van een verklaring via factuur",
    SUPERVISOR: "Supervisor",
    INTERN: "Assistent",
    DOCTOR_INVOICES: "Mijn facturen",
    ALL_INVOICES: "Alle facturen",
    OTHER_DOCTOR_INVOICES: "De facturen van een andere arts",
    ALL_ASSISTANTS_INVOICES: "Alle assistenten",
    OTHER_ASSISTANTS_INVOICES: "Een assistent kiezen",
  },
  "medical-location-filter": {
    ALL_LOCATIONS: "Alle entiteiten",
  },
  "patient-pending-invoices": {
    PENDING_INVOICING: "Af te sluiten verklaringen",
    FETCH_ERROR: "Fout bij het ophalen van de verklaringen",
    INVOICE_ID: "ID",
    INVOICE_DATE: "Datum",
    INVOICE_CODES: "Code(s)",
    INVOICE_AUTHOR: "Auteur",
    INVOICE_RECIPIENT: "Patiënt",
    INVOICE_MEDICAL_LOCATION: "Medische entiteit",
    ALL: "Alle facturen ",
    OTHER: "Facturen van andere voorschrijvers",
    MINE: "Mijn facturen",
  },
  "patient-invoicing-eattest-cancel": {
    WRONG_PATIENT: "Verkeerde patiënt",
    WRONG_CARE_PROVIDER: "Foute prestataris",
    MISTAKE_IN_ELEMENTS: "Fout in één van de elementen van de ASD",
    DOUBLE_ENCODING: "Dubbele codering",
    REPLACE_BY_EFACT: "e-Attest vervangen door e-Fact",
    REPLACE_BY_PAPER: "e-Attest vervangen door ASD papier",
    SITUATION_DID_NOT_REQUIRE:
      "De situatie laat niet toe een e-Attest op te stellen",
    WRONG_OR_NO_PAYMENT_CHANNEL: "Fout of weglating in de betalingsmethode",
    WRONG_OR_AMOUNT_ALREADY_PAID:
      "Fout of weglating in het al door de patiënt betaalde bedrag",
    RECEIPT_DOCUMENT_NOT_PRINTEND:
      "Ondersteunend document niet afgeleverd / niet afgedrukt",
    CANCEL_THE_EATTEST: "eAttest-factuur annuleren",
    PRINT_THE_EATTEST: "eAttest-factuur afdrukken",
    NOT_CONNECTED_CANCEL:
      "Verbind u met eHealth om de eAttest-factuur te annuleren",
    EDIT_THE_EATTEST: "eAttest-factuur wijzigen",
    CANCEL_SUCCESS: "Annuleren van factuur gelukt",
    CLOSE: "Sluiten",
  },
  "patient-invoicing-confirm": {
    TITLE: "Betaling door de patiënt bevestigen",
    PAYMENT_BY_PATIENT: "Betaling door de patiënt",
    PAYMENT_MODE: "Betalingsmethode",
    ARCHIVE: "Archiveren",
    BACK: "Annuleren",
    CONFIRM: "Bevestigen",
    REACTIVATE: "Heractiveren",
    AMOUNT_PATIENT: "Bedrag te betalen door de patiënt",
    RECEIVED_AMOUNT: "Ontvangen bedrag",
    MONEY_BACK: "Terug te geven bedrag",
    WARNINGS: {
      EATTEST:
        "Opgelet, het is onmogelijk een elektronische verklaring te annuleren nadat deze is ingediend.",
      INSUFFISANT_AMOUNT_EATTEST:
        "Het bedrag is niet voldoende om de elektronische verklaring te betalen.",
      INSUFFISANT_AMOUNT:
        "Het bedrag is niet voldoende. De prestatie is gedeeltelijk betaald. Zie details hieronder.",
      WARNING_CODE_USED:
        "De volgende codes zijn vandaag al gebruikt voor deze zelfde patiënt",
    },
  },
  "invoices-details": {
    ID: "ID",
    DATE: "Datum",
    TOTAL: "Totaal",
    AUTHOR: "Auteur",
    PATIENT: "Patiënt",
    SSIN: "Rijksregisternummer",
    MUTUALITY: "Mutualiteit",
    INTERVENTION: "Interventie",
    INVOICING_CODES: "Prestaties",
    TICKET_MODERATEUR: "Remgeld",
    INVOICE_TO: "Gefactureerd aan",
    INVOICE_TYPE: "Type factuur",
    INVOICE_NUMBER: "Nr. factuur",
    CREDIT_NOTE: "Kredietnota",
    PAYMENT_TYPE: "Type betaling",
    PAYED: "Betaald",
    EDIT: "Wijzigen",
    PAID_AMOUNT: "Betaald bedrag",
    STATUS: "Status",
    ASSISTANT: "Assistent",
  },
  "base-search-combobox": {
    SEARCH_FAILED: "De opzoeking is mislukt",
  },
  "hcp-search-combobox": {
    SEARCH: "Een betrokkene zoeken",
  },
  "user-search-combobox": {
    SEARCH: "Een gebruiker zoeken",
  },
  "country-search-combobox": {
    SEARCH: "Land zoeken",
  },
  "nationality-search-combobox": {
    SEARCH: "Nationaliteit zoeken",
  },
  "talk-language-search-combobox": {
    SEARCH: "Taal",
  },
  "patient-sumehr": {
    SUMEHR_TITLE: "Sumehr",
    WARNING:
      "Waarschuwing: de in een SUMEHR getoonde gegevens kunnen niet als exhaustief worden beschouwd. Dit is in overeenstemming met de principes van de Vitalink / BGN / RSW . Deze gegevens worden louter ter informatie verstrekt en otnheffen nooit van een omstandige vergeetachtigheid.",
  },
  "contact-pdf": {
    PATIENT: "Patiënt",
  },
  beoogo: {
    AUTHENTICATION_ERROR: "Onmogelijk verbinding te maken met de Beoogo-dienst",
    MISSING_CONFIGURATION:
      "Geen enkele configuratie van de Beoogo-dienst beschikbaar",
  },
  "patient-subscription": {
    TITLE: "Beheer van de inschrijving in forfait",
    LOADING: "Laden van de abonnementen",
    NOT_AVAILABLE: "Deze dienst is niet beschikbaar voor uw kwalificatie",
    SEND_BTN: "Aanvraag verzenden",
    CLOSURE_BTN: "Beëindiging verzenden",
    CANCEL_BTN: "Annulering verzenden",
    CLOSE_BTN: "Sluiten",
    CLOSURE_BTN_MANUALLY: "Abonnement manueel beëindigen",
    OPTIONS_BTN: "Wijzigen",
    NO_CONTRACT: "Geen contract",
    manualInputOfContractId: "Contractnummer manueel invoeren",
    successfullyModified: "Wijziging succesvol opgeslagen",
    TABSTRIP: {
      closeSubscription: "Abonnement beëindigen",
      cancelSubscription: "Abonnement annuleren ",
      createSubscription: "Abonnement creëren",
      listOfSubscription: "Lijst van abonnementen",
      subscriptionOptions: "Opties voor de facturatie",
    },
    ERROR: {
      title: "Fout(en)",
      keystoreId: "De id van de keystore ontbreekt",
      ehPassword: "ehealth wachtwoord ontbreekt",
      tokenId: "Het token ontbreekt",
      nihii: "Het riziv nummer ontbreekt of is ongeldig",
      firstName: "De naam van de zorgverstrekker ontbreekt",
      patientFirstName: "De voornaam van de patiënt ontbreekt",
      patientLastName: "De naam van de patiënt ontbreekt.",
      patientGender: "Het geslacht van de patiënt ontbreekt",
      startSubscriptionDate: "De begindatum van het forfait ontbreekt",
      isTrial: "De proefperiode ontbreekt",
      signatureType: "Type handtekening ontbreekt",
      patientSsin: "Het insz nummer van de patiënt ontbreekt of is ongeldig",
      patientIo: "Het nummer van de mutualiteit ontbreekt.",
      patientIoMembership:
        "Het inschrijvingsnummer van de patiënt bij de mutualiteit ontbreekt",
      subscriptionReference: "Het contractnummer ontbreekt",
      subscriptionEndDate: "De einddatum van het abonnement ontbreekt",
      subscriptionClosureJustification:
        "De rechtvaardiging voor de beëindiging ontbreekt",
      subscriptionClosureType:
        "De initiatiefnemer van de beëindiging ontbreekt.",
      errorNoSubscriptionResponse:
        "Contractnummer niet ontvangen van de VI. Het abonnement is wel aangemaakt.",
      noMedicalHouseContract: "Geen contractnummer",
      unSubscriptionUnavailable1:
        "U kunt deze patiënt niet uitschrijven omdat zijn contractnummer ontbreekt.",
      unSubscriptionUnavailable2:
        "Neem contact op met de VI om het contractnummer te verkrijgen.",
      WARNING: "Opgepast",
    },
    INFO: {
      title: "Informatie m.b.t. de inschrijving",
      medicalHouse: "Medisch huis",
      startSubscriptionDate: "Datum van ondertekening",
      startCoverageDate: "Begindatum forfait",
      endSubscriptionDate: "Einde contract",
      endCoverageDate: "Einddatum forfait",
      signatureType: "Type handtekening",
      trialPeriod: "Proefperiode",
      type: "Type forfait",
      status: "Statuut",
      closureReason: "Reden voor beëindiging",
      receipts: "Download bewijs van inschrijving",
      TRIAL: {
        YES: "Ja",
        NO: "Nee",
      },
    },
    RECEIPT: {
      xadesCreation: "Creatie: Xades",
      soapCreation: "Creatie: SoapResponse",
      requestCreation: "Creatie: Request",
      responseCreation: "Creatie: Response",
      soapRequestCreation: "Creatie SoapRequest",
      xadesCancel: "Annulatie: Xades",
      soapRequestCancel: "Annulatie: SoapRequest",
      responseCancel: "Annulatie: Response",
      requestCancel: "Annulatie: Request",
      soapCancel: "Annulatie: SoapResponse",
      xadesClosure: "Beëindiging: Xades",
      soapResponseClosure: "Beëindiging: SoapResponse",
      soapRequestClosure: "Beëindiging: SoapRequest",
      responseClosure: "Beëindiging: Response",
      requestClosure: "Beëindiging: Request",
    },
    MDA: {
      title:
        "Informatie m.b.t. de inschrijving in medisch huis verkregen via Member Data (MDA)",
      inami: "Riziv",
      medicalHouse: "Medisch huis",
      type: "Type forfait",
      startOfContract: "Begindatum",
      endOfContract: "Einddatum",
      address: "Adres",
      postalCode: "Postcode",
      city: "Stad",
      telephoneNumber: "Tel",
    },
    PATIENT: {
      GENDER: {
        male: "Man",
        female: "Vrouw",
        unknown: "Onbepaald",
        "": " ",
        null: " ",
        undefined: " ",
      },
      title: "Informatie m.b.t. de patiënt",
      lastName: "Naam",
      firstName: "Voornaam",
      gender: "Geslacht",
      inss: "INSZ",
      mutuality: "Mutualiteit",
      mutualityNumber: "Inschrijvingsnummer mutualiteit",
    },
    SUBSCRIPTION: {
      STATUS: {
        manual: "Manuele invoer",
        onGoing: "Actief",
        cancelled: "Geannuleerd",
        closed: "Beëindigd",
      },
      SIGNATURE: {
        holderPaper: "Ondertekening van het papieren document",
        holderEid: "Handtekening via EID kaart",
        legalRepresentativeEid:
          "Ondertekening van het papieren document door de wettelijke vertegenwoordiger",
        legalrepresentativeEid:
          "Ondertekening van het papieren document door de wettelijke vertegenwoordiger",
        legalRepresentativePaper:
          "Handtekening via de EID-kaart van de wettelijke vertegenwoordiger",
        legalrepresentativePaper:
          "Handtekening via de EID-kaart van de wettelijke vertegenwoordiger",
        unknown: "Onbekend",
      },
    },
    CREATION: {
      title: "Informatie m.b.t. de inschrijving",
      signature: "Handtekening",
      subscriptionType: "Type abonnemment",
      startOfContract: "Datum handtekening",
      trialPeriod: "Proefperiode",
      startOfCoverage: "Begin forfait",
      coverageType: "Dienst(en) beschikbaar in uw MH",
      coverage: "Dienst(en) gefactureerd aan de VI",
      signatureType: "Soort handtekening",
      SIGNATURE_TYPE: {
        holderEid: "Handtekening via EID",
        holderPaper: "Ondertekening van het papieren document",
        representativeEid:
          "Handtekening via de EID-kaart van de wettelijke vertegenwoordiger",
        representativePaper:
          "Ondertekening van het papieren document door de wettelijke vertegenwoordiger",
      },
      COVERAGE_TYPE: {
        m: "M",
        k: "K",
        i: "I",
      },
      COVERAGE_INFO: {
        title: "Informatie over inschrijving in forfait van een patiënt",
        info: "Met deze optie kunt u alleen bepalen welke diensten zullen worden gefactureerd aan de VI, de patiënt zal worden ingeschreven voor alle diensten die worden aangeboden door uw MH",
        subTitle: "Kan ik een gedeeltelijke inschrijving doen?",
        subInfo:
          "Volgens de regelgeving is het niet mogelijk om gedeeltelijke inschrijvingen te doen, zelfs niet als de patiënt zich bijvoorbeeld in een WZC bevindt. Wanneer een patiënt zich inschrijft bij een Medisch Huis (MH), schrijft hij/zij zich in voor alle diensten die door het MH worden aangeboden. Het is dus niet mogelijk een patiënt in te schrijven voor slechts een deel van de aangeboden diensten, ongeacht de omstandigheden. In het nieuwe systeem is de inschrijving ook individueel. Met andere woorden, het impliceert niet de automatische inschrijving van andere familieleden. Indien de patiënt specifieke zorg nodig heeft, bijvoorbeeld kinesitherapie, en het MH is niet in staat die te verstrekken, zal het MH de vergoedingen moeten betalen die de patiënt aan de zorgverstrekker en/of het revalidatiecentrum zou moeten betalen.",
        mrsTitle:
          "Hoe zit het met patiënten die in een zorginstelling zijn (WZC, PVT, DVC) ?",
        mrsInfo:
          "Op het niveau van de inschrijving verandert dit niets. Anderzijds zal het MH de zorgen van de arts moeten factureren en eventueel de zorg die niet door de instelling wordt aangeboden (zie onderstaande tabel).",
        invoiceTitle:
          "Wat als het MH niet weet dat haar patiënt in een van deze instellingen zit (WZC, PVT, DVC) ?",
        invoiceInfo:
          "Het MH zal de 3 forfaits factureren en de forfaits zullen geweigerd worden volgens de bovenstaande tabel. Voorbeeld : de patiënt is in VZC en het MH biedt MKI aan, Het MH heeft de 3 forfaits gefactureerd, de verpleegkundige en kinesisten forfaits zullen verworpen worden.",
      },
      failed: "De inschrijving is niet gelukt.",
    },
    CLOSURE: {
      TYPE: {
        "101":
          "101 - Verhuis: patiënt woont niet meer in het activiteitsgebied",
        "102":
          "102 - Conflict met de patiënt of een lid van de familie van de patiënt",
        "103":
          "103 - Opname in een instelling (pediatrisch, bejaard of ander) buiten het geografisch gebied",
        "104": "104 - Niet-naleving van de regels van het medisch huis",
        "105": "105 - Langdurige afwezigheid van contact met de patiënt",
        "106":
          "106 - De patiënt consulteert regelmatig buiten het medisch huis zonder toestemming en eist terugbetaling van deze kosten",
        "107":
          "107 - Continuïteit van de zorg te moeilijk te verzekeren voor het medisch huis",
        "201":
          "201 - Verhuis: patiënt woont niet meer in het activiteitsgebied",
        "202":
          "202 - Conflict met een zorgverstrekker of personeelslid van het MH",
        "203":
          "203 - Opname in een instelling (pediatrische zorg, bejaardenzorg of andere)",
        "204": "204 - Niet akkoord met de regels van het MH",
        "205":
          "205 - De patiënt wenst andere zorgverleners te raadplegen dan die van het MH",
        "206":
          "206 - Alternerende opvang wanneer een van de ouders niet in het activiteitsgebied woont",
        "207":
          "207 - De patiënt wil een vaste zorgverstrekker in het MH maar kan er geen krijgen",
        "208": "208 - Persoonlijke redenen",
      },
      INITIATOR: {
        healthcarepartydecision: "Medisch Huis",
        patientdecision: "Patiënt",
      },
      endOfCoverage: "Einde forfait",
      justification: "Rechtvaardiging",
      initiator: "Initiatiefnemer",
      endOfContract: "Einde contract",
      title: "Reden voor beëindiging van het abonnement",
      failed: "De beëindiging van het abonnement is niet gelukt.",
    },
    CANCEL: {
      title: "Reden van annulatie",
      failed: "De annulatie van het abonnement is niet gelukt.",
    },
  },
  "patient-beoogo": {
    TITLE: "Inchrijving MMF",
    START_DATE_MUST_BE_BEFORE_PACKAGE_START_DATE:
      "De aanvangsdatum van de nieuwe administratieve status moet voorafgaan aan de aanvangsdatum van het vaste tarief",
    SUBSCRIPTION_DATE: "Inschrijvingsdatum",
    UNSUBSCRIPTION_DATE: "Uitschrijvingsdatum",
    UNSUBSCRIPTION_REASON: "Reden van uitschrijven",
    SUBSCRIPTION_TYPE: "Type abonnement",
    SUBSCRIPTION_M: "M",
    SUBSCRIPTION_K: "K",
    SUBSCRIPTION_I: "I",
    TRIAL_PERIOD: "Testperiode",
    SUBMISSION_WARNING:
      "Door op Versturen te klikken, wordt de informatie bijgewerkt in Beoogo",
    SEND_TO_BEOOGO: "Versturen",
    CANCEL: "Annuleren",
    PATIENT_UNSUBSCRIBED: "Patiënt uitgeschreven",
    UNSUBSCRIPTION_REASON_1: "Reden 1",
    UNSUBSCRIPTION_REASON_2: "Reden 2",
    UNSUBSCRIPTION_REASON_3: "Reden 3",
    INSURANCE_0: "Geen enkele instantie",
    INSURANCE_1: "Instantie buiten mutualiteit",
    INSURANCE_2: "Mutualiteit",
    ACT: "Patiënt op handeling",
    SUBSCRIBED: "Patiënt op forfait",
    AWAITING_SUBSCRIPTION: "In afwachting van forfait",
    SUBSCRIPTION_NOT_ALLOWED:
      "In afwachting van forfait en komende van andere medische instelling",
    AWAITING_CLOSURE:
      "In afwachting van einde forfait en nog altijd rechthebbend op forfait",
    CLOSED: "Uitgeschreven van forfait",
    SUBSCRIBE: "Inschrijven",
    SUBSCRIBE_AGAIN: "Opnieuw inschrijven",
    UNSUBSCRIBE: "Uitschrijven",
    NOT_IN_ORDER: "De patiënt is niet in orde.",
    SET_ORDER_STATUS: "In orde brengen van status",
    SELECT_AT_LEAST_ONE: "Ten minste één optie kiez",
    NO_PATIENT:
      " De patiënt is nog niet ingeschreven. Om hem/haar in te schrijven, vul het onderstaande formulier in.",
    CLOSUREDECISION: "Slotbesluit",
    PATIENT_DECISION: "Beslissing van de patiënt",
    HEALTHCARE_PARTY_DECISION: "Beslissing van de dokter",
    SIGNATURE_TYPE: "Soort van handtekening",
    PAPER_HOLDER: "Papieren handtekening",
    LEGALREPRESENTATIVE_HOLDER: "Elektronische handtekening",
    CANCEL_SUBSCRIPTION: "Abonnement opzeggen",
    TRIALPERIOD: "Proefperiode",
    CLOSUREJUSTIFICATION: {
      "101":
        "101 - Verhuis: de patiënt woont niet meer in de activiteitengebied",
      "102": "102 - Conflict met de patiënt of een familielid van de patiënt",
      "103":
        "103 - Opname in een instelling (ouderenzorg of andere) buiten het geografische gebied ",
      "104": "104 - Niet naleving van de regels van het MH",
      "105": "105 - Langdurige afwezigheid van contact met de patiënt",
      "106":
        "106 - De patiënt raadpleegt regelmatig en zonder toestemming van externe zorgverleners en vraagt de terugbetaling van zijn kosten",
      "107":
        "107 - Continuïteit van de zorgen te moeilijk over te nemen voor het MH",
      "201":
        "201 - Verhuis : de patiënt woont niet meer in de activiteitengebied",
      "202":
        "202 - Conflict met de zorgverlener of een personeelslid van het MM",
      "203": "203 - Opname in een instelling (ouderenzorg of andere) ",
      "204": "204 - Niet naleving van de regels van het MH",
      "205":
        "205 - De patiënt wenst andere zorgverleners dan die van het MH raadplegen",
      "206":
        "206 - Alternatieve ondersteuning waarbij één ouder niet in het activiteitengebied leeft ",
      "207":
        "207 - De patiënt wenst een aangestelde zorgverlener binnen het MH maar het is niet mogelijk",
      "208": "208 - Persoonlijke redenen",
    },
    DISPLAY: {
      TITLE: "Weergeven",
      PATIENT_DATA: "Gegevens van het lid",
      LEGENDS: "Legendes",
      DATA: "De gegevens",
      HOLDER: "Titularis",
      START_DATE: "Begindatum",
      END_DATE: "Einddatum",
      INSURANCE_TYPE: "Type verzekering",
      NOTE: "Opmerking",
      INSURANCE_CODE: "Verzekeringcode",
      INSURANCE_REFERENCE: "Verzekeringsreferentie",
      HOLDER_ID: "ID van de titularis",
      HOLDER_CODE: "Code van de titularis",
      PACKAGE_ENABLED_CODES: "Geactiveerde pakketten",
      STATUS: "Status",
    },
    DISPLAY_SUBSCRIPTION: {
      THIRD_PARTY: "Komende van andere medische instelling",
      SUBSCRIPTION_DATE: "Datum van vandaag",
      PACKAGE_START_DATE: "Begindatum forfait",
      START_DATE: "Begindatum van nieuwe administratieve status",
    },
    DISPLAY_UNSUBSCRIPTION: {
      DECEASED: "Patiënt overleden",
      DECEASED_DATE: "Datum van overlijden",
      DECEASED_CAUSE: "Reden van overlijden",
      PACKAGE_START_DATE: "Begindatum nieuw pakket",
      START_DATE: "Begindatum van nieuwe administratieve status",
    },
    DISPLAY_ORDER_STATUS: {
      STATUS: "Patiënt in orde verklaard",
    },
    DISPLAY_EXCLUSION: {
      EXCLUSION_DATE: "Datum van uitsluiting",
    },
    ERROR: {
      ILLEGAL_START_DATE:
        "De begindatum van het forfait moet minstens de eerste van de volgende maand zijn. De begindatum van het forfait moet later zijn dan de begindatum van de nieuwe administratieve status.",
      ILLEGAL_UNSUBSCRIPTION_DATE:
        "De datum van uitschrijving moet later zijn dan de datum van inschrijving voor het forfait. Neem contact op met Beoogo.",
    },
  },
  "patient-covid-vaccination": {
    TITLE: "covid-vaccinatiestatus",
    NOT_AVAILABLE: "De dienst is niet beschikbaar voor uw kwalificatie",
    SEND: "Patiënt activeren",
    CANCEL: "Sluiten",
    NO_DATA: "Geen gegevens gevonden voor deze patiënt",
    SYNCHRONIZE: "Gegevens ophalen",
    EXTRA_DOSE_1: {
      TOOLTIP: "Extra dosis, oa voor immuno-gecompromitteerde patiënten",
      TITLE: "SELECTIECRITERIA: personen 12 jaar of ouder op 1/8/2021:",
      TEXT: "<ul><li>Patiënten met aangeboren afweerstoornissen die niet met immunostimulantia behandeld worden of er na 31/03/2021 mee gestart zijn</li><li>HIV-patiënten waarvan nu (= 2021) het aantal CD4-cellen lager ligt dan 200 per mm³ bloed</li><li>Patiënten met bloedkanker of andere kwaadaardige tumoren, die hun behandeling gestart zijn na 31/3/2021</li><li>Patiënten die met nierdialyse begonnen zijn na 31/03/2021</li><li>Stamceltransplant of orgaantransplantpatiënten of pretransplantpatiënten die na 31/03/2021 als dusdanig door de specialisten werden gediagnosticeerd</li><li>Patiënten met inflammatoire ziekten die behandeld worden met immunosuppressiva, en waarvan de behandeling na 31/03/2021 is gestart</li></ul>",
      CONFIRM: "Bevestigen",
      CANCEL: "Annuleren",
    },
    DATA: {
      title: "Identificatiegegevens",
      lastName: "Naam",
      firstName: "Voornaam",
      birthDate: "Geboortedatum",
      ssin: "INSZ",
    },
    VACCINATION: {
      CAMPAIGN: {
        INITIAL: "Aanvankelijk",
        EXTRA_DOSE_1: "Extra dosis",
      },
      "validity-date": "Geldig vanaf",
      tab: "Afspraak",
      title: "Afspraak",
      creationDate: "Datum ingave",
      desactivationDate: "Datum deactivatie",
      deactivationReason: "Reden  deactivatie",
      vaccineType: "Soort vaccin",
      status: "Status",
      source: "Bron",
      desactivated: "Vaccinatiecode deactiveren",
      activated: "Vaccinatiecode activeren",
      alreadyVaccined: "De patiënt al is ingeënt",
      patientRefusal: "De patiënt weigert vaccinatie",
      error: "Fout bij het ophalen van de patiënt",
      pregnancy: "prioriteit voor zwangere vrouw wijzigen",
      campaign: "campagne",
      UPDATE: {
        success: "De vaccinatie is bijgewerkt",
      },
      DEACTIVATION: {
        CITIZEN_REFUSE_VACCINATION: "Weigering vaccinatie",
        CITIZEN_ALREADY_VACCINATED: "Vaccinatie reeds uitgevoerd",
        "": " ",
        null: " ",
        success: "De vaccinatie is gedeactiveerd",
        error: "Fout tijdens deactivering",
        undefined: " ",
      },
      ACTIVATION: {
        success: "De vaccinatie is opnieuw geactiveerd",
        error: "Fout tijdens reactivering",
      },
      STATUS: {
        ACTIVATED: "Geactiveerd",
        NOT_ACTIVATED: "Niet geactiveerd",
        DEACTIVATED: "Gedeactiveerd",
        UNKNOWN_SSIN: "INSZ onbekend",
        "": " ",
        null: " ",
        undefined: " ",
      },
      SOURCE: {
        RESERVATION_TOOL: "Reservatiesysteem",
        CIN_NIC: "Mutualiteit",
        GP: "Arts",
        COBRHA: "Federale entiteiten",
        CITIZEN: "Burger",
        MEDIPRIO1: "Zwanger",
        "": " ",
        null: " ",
        undefined: " ",
      },
    },
    INJECTION: {
      tab: "Vaccinatie(s)",
      title: "Vaccinatie",
      vaccineDescription: "Beschrijving",
      vaccinatorTypeDescription: "Type vaccinator",
      vaccineAdministrationDate: "Datum",
      vaccineDosesAdministration: "Dosis",
      batchNbr: "lotnummer",
      vaccinet: "Link naar Vaccinnet",
      vaccineTypeCode: "Gecodeerd",
      vaccinatorTypeVaccinnetId: "Vaccinator",
      DOSE_ADMINISTRATION: {
        A: "1/2 ",
        B: "2/2 ",
        C: "1/1 ",
        EXTRA_DOSE_1: "Extra",
      },
    },
    EVENT: {
      title: "Oproeping",
    },
    SCHEDULE: {
      title: "Afspraak",
      doseNumber: "Dosis nummer",
      scheduleDate: "Datum van afspraak",
      vaccineManufacturer: "Farmaceutisch bedrijf",
      MODE: {
        LETTER: "Brief",
        SMS: "SMS",
        EMAIL: "E-mail",
        EBOX: "Ebox ",
        "": " ",
        null: " ",
        undefined: " ",
      },
    },
    NOTIFICATION: {
      tab: "Oproeping",
      title: "Oproeping",
      target: "Doel",
      channel: "Modus",
      date: "Verzenddatum",
      code: "Inentingscode",
    },
    ERROR: {
      ssin: "Patiënt heeft geen nis",
    },
    REGISTER: {
      success: "De patiënt is aangemeld voor vaccinatie",
      warning: "Let op, de nis van de patiënt is niet bekend bij de dienst",
      error: "Fout bij verzenden van registratie ",
    },
    VACCINET: {
      notAvailable: "Service niet beschikbaar",
    },
  },
  "contact-display": {
    AUTHOR: "Gebruiker",
    INTEGRATOR: "Betrokkene",
    AT_TIME: "om {{time}}",
    CHANGE_OPENING_DATE: "Openingsdatum wijzigen",
    EDIT: "Wijzigen",
    SAVE: "Registreren",
    CLOSE: "Afsluiten",
    CANCEL: "Annuleren",
    DELETE: "Verwijderen",
    LAST_SAVE: "Bijgewerkt",
    PLACEHOLDER: {
      TRANSACTIONREASON: "Redenen",
      SUBJECTIVE: "Subjectief",
      ASSESSMENT: "Diagnoses",
      NOTE: "Nota's",
    },
    EDIT_WINDOW_ENDED: "Het bewerkingsvenster is verouderd.",
    ADD_SUB_CONTACT: "Een sub-contact toevoegen",
    DELETE_CONTACT: "Verwijderen van het contact",
    DELETE_CONTACT_WARNING:
      "Teruggaan is niet mogelijk en u loopt het risico belangrijke gegevens in dit contact kwijt te raken.",
    DELETE_THIS_CONTACT: "Dit contact verwijderen",
    CANCEL_PRESCRIPTION:
      "Wilt u ook het elektronisch voorschrift annuleren dat in dit contact aanwezig is?",
    PRESCRIPTION_CANNOT_BE_REVOKED:
      "Het voorschrift kon niet worden geannuleerd bij Recip-e",
  },
  "contact-type-display": {
    CHANGE_CONTACT_TYPE: "Type contact wijzigen",
    TYPES: {
      consult: "Raadpleging",
      labresult: "Laboratoriumresultaat",
      contactreport: "Rapport",
      telephonicconsultation: "Raadgeving/Divers",
      multidisciplinaryconsult: "Multidisciplinair overleg",
      homevisit: "Huisbezoek",
      contactfile: "Dossiercontact",
      electronicconsultation: "Elektronische consultatie",
      missedappointment: "Niet op afspraak",
      administrativecontact: "Administratief contact",
    },
  },
  "subcontact-health-elements": {
    HEALTH_ELEMENT_ASSOCIATION_SUCCEEDED:
      "Het zorgelement werd gekoppeld met het subcontact.",
    HEALTH_ELEMENT_ASSOCIATION_FAILED:
      "Het koppelen van het zorgelement met het subcontact is mislukt.",
    HEALTH_ELEMENT_DISSOCIATION_SUCCEEDED:
      "Het zorgelement werd losgekoppeld van het subcontact.",
    HEALTH_ELEMENT_DISSOCIATION_FAILED:
      "Het afkoppelen van het zorgelement van het subecontact is mislukt.",
    LINK_HEALTH_ELEMENT: "Subcontacten verbinden met een zorgelement",
  },
  "health-element-popup": {
    NEW_HEALTH_ELEMENT: "Nieuw zorgelement",
    HEALTH_ELEMENT_CREATION_SUCCEEDED: "Zorgelement gecreëerd.",
    HEALTH_ELEMENT_CREATION_FAILED:
      "De creatie van het zorgelement is mislukt.",
    SEARCH: "Opzoeken",
    CREATE_FROM_SUB_CONTACT: "Creëren vanaf het subcontact",
  },
  "patient-eid": {
    UPDATE_SUCCESS:
      "Bijwerken van de patiëntgegevens op basis van het eID geslaagd.",
    UPDATE_ERROR:
      "Bijwerken van de patiëntgegevens op basis van het eID mislukt.",
    CREATE_ERROR: "Creatie van een patiënt op basis van het eID mislukt.",
    EID_READ_ERROR: "Lezen van het eID is mislukt",
    EID_TITLE: "Een patiënt selecteren",
    PATIENT_SSIN_ALREADY_EXISTS:
      "Er werd een patient gevonden met hetzelfde rijksregisternummer als dat op het eID.",
    CHOOSE_PATIENT: "Kies hieronder een patiënt.",
    WRONG_CARD_NUMBER_COMBINATION:
      "De combinatie tussen het eID-kaartnummer en Ssin lijkt niet correct.",
  },
  "appointment-tags": {
    tags: "Tags",
    IN_WAITING_ROOM: "Aanwezig in de wachtzaal",
    CANCELED_BY_PATIENT: "Geannuleerd door de patiënt",
    CANCELED_BY_PATIENT_TARDILY:
      "Te laat geannuleerd door de patiënt tardivement",
    CANCELED_BY_DOCTOR: "Geannuleerd door de arts",
    DID_NOT_COME: "Niet opgedaagd",
    LATE: "Te laat",
    BEING_CONSULTED: "In consultatie",
    CLOSED: "Afgelsloten",
    CONFIRM_DOCTOR: "Arts bevestigen",
    CONFIRM_DRIVER: "Chauffeur bevestigen",
    CONFIRM_PATIENT: "Patiënt bevestigen",
  },
  "patient-events-search": {
    PATIENT_DOES_NOT_EXISTS: "De patiënt bestaat niet of niet mee",
    APPOINTMENT_TYPE: "Type de rendez-vous",
    DATE: "Date",
    STARTTIME: "Begintijd",
    DURATION: "Duur ( min)",
    REASON: "Reden",
    STATUS: "Status",
  },
  "ms-calendar": {
    NO_ACCESS_TO_AGENDA: "U hebt toegang tot geen enkele agenda.",
    HIDE_SIDEBAR: "Linkerpaneel verbergen",
    SHOW_SIDEBAR: "Linkerpaneel weergeven",
    HIDE_SIDEBAR_R: "Rechterpaneel verbergen",
    SHOW_SIDEBAR_R: "Rechterpaneel weergeven",
    NO_ACCESS_TO_EVENTS: "Onmogelijk de evenementen in uw agenda's op te halen",
    SEARCH_PATIENT_EVENTS: "Een afspraak zoeken",
    GUARDPOST_LOAD_AGENDA_ERROR: "Onmogelijk de agenda {{id}} te vinden",
    filters: {
      PRINT: "Afdrukken",
      DISPONIBILITY: "Beschikbaarheid artsen",
      SYNC: "Afsprakenlijst ophalen",
      MANAGE_CALENDAR_ITEM_TYPES: "Types afspraken",
      MANAGE_CALENDAR_ITEM_PLACES: "Plaatsen van afspraak",
      MANAGE_AGENDA: "Beheer agenda",
      OPEN_KEYWORD_AUTOCOMPLETE:
        "Automatisch invullen van trefwoorden weergeven",
      AGENDAS: "Agenda's",
      FIRST_NAME: "Voornaam",
      LAST_NAME: "Naam",
      ONLY_ACTIVE: "Enkel agenda's met een actieve uurrooster tonen",
      DISPLAY_CANCELED_EVENTS: "Geannulleerde afspraken weergeven",
      EVENT_TYPES: "Types afspraken",
      MIN: "min",
      NAME: "Naam",
      CHOOSE_SPECIALITY: "Kies een specialiteit",
    },
    "allow-overlap-dialog": {
      TITLE: "Tijdslot bezet",
      OTHER_APPOINTMENT: "Tijdslot ondertussen bezet door een andere afspraak",
      KEEP_YOUR_CHOICE: "Wilt u een tweede afspraak toevoegen?",
      YES: "Ja",
      NO: "Nee",
    },
    scheduler: {
      ZOOM: "Zoom: ",
      RESET: "Herinitialiseren",
      TODAY: "Vandaag",
      WEEK: "Week",
      DAY: "Dag",
      MY_SCHEDULE: "Mijn planning",
      ALL_DAY: "Hele dag",
      messages: {},
    },
    "time-table-list": {
      TITLE: "Uurrooster",
      AGENDA: "Agenda",
      ADD_TIMETABLE: "Een uurrooster toevoegen",
      NAME: "Naam",
      START: "Begin",
      END: "Einde",
      messages: {
        CREATE_TIMETABLE_SUCCESS: "Creëren van uurrooster uitgevoerd.",
        CREATE_TIMETABLE_ERROR:
          "Er is een fout opgetreden bij het creëren van het uurrooster.",
        UPDATE_TIMETABLE_SUCCESS: "Bijwerken van uurrooster uitgevoerd.",
        UPDATE_TIMETABLE_ERROR:
          "Er is een fout opgetreden bij het bijwerken van het uurrooster.",
        DELETE_TIMETABLE_SUCCESS: "Verwijderen van uurrooster uitgevoerd.",
        DELETE_TIMETABLE_ERROR:
          "Er is een fout opgetreden bij het verwijderen van het uurrooster.",
      },
    },
    "time-table": {
      TITLE: "Uurrooster bewerken",
      NAME: "Naam",
      START: "Begin",
      END: "Einde",
      ADD_TIMETABLE_ITEM: "Een element aan het uurrooster toevoegen",
      DAYS: "Dagen",
      HOURS: "Uur (uren)",
      RECURRENCE: "Herhaling",
      EVERY_WEEK: "Elke week",
      OTHER: "Andere",
      EVENT_TYPE: "Type afspraak",
      EVENT_PLACE: "Plaats van afspraak",
      VISIT: "Bezoek",
      CONSULTATION: "Consultatie",
      SAVE_TIMETABLE: "Uurrooster registreren",
      WARNING: {
        REQUIRED_FIELD: "Verplicht veld",
      },
      days: {
        MONDAY: "Maandag",
        TUESDAY: "Dinsdag",
        WEDNESDAY: "Woensdag",
        THURSDAY: "Donderdag",
        FRIDAY: "Vrijdag",
        SATURDAY: "Zaterdag",
        SUNDAY: "Zondag",
      },
    },
    "calendar-item-type-list": {
      TITLE: "Lijst met types afspraken",
      ADD_CALENDAR_ITEM_TYPE: "Een type afspraak toevoegen",
      NAME: "Naam",
      COLOR: "Kleur",
      DURATION: "Duur (minutes)",
      MINUTE: "minuut",
      MINUTES: "minuten",
      messages: {
        CREATE_CALENDAR_ITEM_TYPE_SUCCESS:
          "Creatie van type afspraak uitgevoerd.",
        CREATE_CALENDAR_ITEM_TYPE_ERROR:
          "Er is een fout opgetreden bij het creëren van het type afspraak.",
        UPDATE_CALENDAR_ITEM_TYPE_SUCCESS:
          "Bijwerken van type afspraak uitgevoerd.",
        UPDATE_CALENDAR_ITEM_TYPE_ERROR:
          "r is een fout opgetreden bij het bijwerken van het type afspraak..",
        DELETE_CALENDAR_ITEM_TYPE_SUCCESS:
          "Verwijderen van type afspraak uitgevoerd.",
        DELETE_CALENDAR_ITEM_TYPE_ERROR:
          "r is een fout opgetreden bij het verwijderen van het type afspraak..",
      },
    },
    "calendar-item-place-list": {
      TITLE: "Lijst met plaatsen van afspraak",
      ADD_CALENDAR_ITEM_PLACE: "Een plaats toevoegen",
      NAME: "Naam",
      messages: {
        CREATE_CALENDAR_ITEM_PLACE_SUCCESS:
          "Creatie van plaats van afspraak uitgevoerd.",
        CREATE_CALENDAR_ITEM_PLACE_ERROR:
          "Er is een fout opgetreden bij het creëren van de plaats van afspraak.",
        UPDATE_CALENDAR_ITEM_PLACE_SUCCESS:
          "Bijwerken van plaats van afspraak uitgevoerd.",
        UPDATE_CALENDAR_ITEM_PLACE_ERROR:
          "Er is een fout opgetreden bij het bijwerken van de plaats van afspraak.",
        DELETE_CALENDAR_ITEM_PLACE_SUCCESS:
          "Verwijderen van plaats van afspraak uitgevoerd.",
        DELETE_CALENDAR_ITEM_PLACE_ERROR:
          "Er is een fout opgetreden bij het verwijderen van de plaats van afspraak.",
      },
    },
    "calendar-item-place": {
      TITLE: "Bewerken van een plaats",
      NAME: "Naam",
      SAVE_PLACE: "Plaats registreren",
    },
    "agenda-management": {
      TITLE: "Beheer agenda",
      NAME: "Naam",
      RIGHTS: "Rechten",
      SAVE_AGENDA: "Agenda opslaan",
      rights: {
        AGENDA_READ: "Lezen",
        AGENDA_WRITE: "Schrijven",
        AGENDA_ADMINISTRATION: "Administratie",
      },
      messages: {
        SAVE_AGENDA_SUCCESS:
          "Opslaan van toegangsrechten tot de agenda uitgevoerd.",
        SAVE_AGENDA_ERROR:
          "Er is een fout opgetreden tijdens het opslaan van de toegangsrechten tot de agenda.",
      },
    },
    "guardPost-service": {
      NEW_ITEMS_AVAILABLE: "Nieuwe elementen zijn beschikbaar in stream 1733",
      UNABLE_TO_DETERMINE_MEDICAL_LOCATION:
        "Onmogelijk uw entiteit te bepalen, de streams zullen niet geladen worden.",
      FOUND_NO_AUTH_TOKEN:
        "Authenticatie naar de stream 1733 server is niet geconfigureerd.",
      PROBLEM_ENCRYPTION:
        "Er is een probleem met uw gebruiker en de 1733 wachtpost service zal binnenkort niet langer beschikbaar zijn voor u. Gelieve het support team te contacteren om het probleem op te lossen.",
      DECRYPTED_CALL_FAILED:
        "Er is een fout opgetreden tijdens het ophalen van de 1733 stream. Update Medispring en neem contact op met de ondersteuning als de fout zich blijft voordoen.",
    },
    "event-recurrence-form": {
      REPEAT: "Herhalen",
      FREQUENCY: {
        DAILY: "dagen",
        WEEKLY: "weken",
        MONTHLY: "maanden",
        YEARLY: "jaren",
      },
      WEEKDAYS: {
        MO: "maandag",
        TU: "dinsdag",
        WE: "woensdag",
        TH: "donderdag",
        FR: "vrijdag",
        SA: "zaterdag",
        SU: "zondag",
      },
      EVERY: "Elke",
      ON_WEEKDAYS: "Weekdagen :",
      WEEK_NUMBER_OF_DAY: {
        "1": "eerste",
        "2": "tweede",
        "3": "derde",
        "4": "vierde",
      },
      THE: "De ",
      OF_THE_MONTH: " van de maand",
      APPOINTMENT_COUNT: "Aantal afspraken",
      UNTIL_DATE: "Tot datum",
      WARNING_MAXIMUM_REACHED: "Maximum {{max}} afspraken toegelaten.",
      APPOINTMENTS: "afspraken:",
    },
    "calendar-event-form": {
      TITLE_CREATE_EVENT: "Afspraak creëren",
      TITLE_EDIT_EVENT: "Afspraak bewerken",
      TITLE_CREATE_EVENT_FOR_X: "Afspraak creëren voor {{practitian}}",
      TITLE_EDIT_EVENT_FOR_X: "Afspraak bewerken voor {{practitian}}",
      EVENT_TITLE: "Titel",
      EVENT_TYPE: "Type",
      PATIENT: "Patiënt",
      CREATE_NEW_PATIENT: "Nieuwe patiënt creëren",
      OPEN_PATIENT: "Patiëntdossier openen",
      CLOSE_PATIENT: "Sluiten",
      CANCEL_PATIENT: "Annuleren",
      PATIENT_DOES_NOT_EXISTS: "De patiënt bestaat niet (meer)",
      IMPORTANT: "Belangrijk",
      VISIT_TYPE: "Type bezoek",
      VISIT: "Bezoek",
      CONSULTATION: "Consultatie",
      PHONE_NUMBER: "Telefoon (enkel voor deze afspraak)",
      PLACE: "Plaats",
      MAP: "Weergeven op kaart",
      GPS: "Naar GPS versturen",
      ADDRESS: "Adres",
      EVENT_DATE: "Datum",
      START_TIME: "Begin",
      END_TIME: "Einde",
      DURATION: "Duur (minuten)",
      ALL_DAY: "De hele dag",
      DETAILS: "Reden",
      DELETE_EVENT: "Afspraak verwijderen",
      DELETE_ALL_EVENT: "Alle afspraken van deze reeks verwijderen",
      SAVE_EVENT: "Afspraak registreren",
      SINCE: "op",
      MEDICAL_HOUSE_TITLE: "Medisch huis",
      CREATED_BY: "Gemaakt door",
      THE: "de",
      flow: {
        REASON: "Reden",
        COMMENT: "Nota",
        PHONE: "Telefoon",
        TYPE: "Type",
        ADDRESS: "Adres",
        GPS_COORDINATES: "GPS-coördinaten",
        LATITUDE: "Breedtegraad",
        LONGITUDE: "Lengtegraad",
        ACTIVATE_NOTIFICATION_SOUND: "Meldingsgeluid inschakelen",
        DEACTIVATE_NOTIFICATION_SOUND: "Meldingsgeluid uitschakelen",
        LAST_UPDATE_DATE: "Laatste update",
        MARKED_AS_TREATED: "Stream gemarkeerd als behandeld",
        DESCR: "Beschrijving",
      },
      messages: {
        ERROR_APPOINTMENT_OVERLAP:
          "Dit afspraak kan niet geregistreerd worden omdat de geselecteerde periode overlapt met een andere afspraak.",
        CREATE_EVENT_SUCCESS: "{{total}} afsprake(n) gecreëerd.",
        CREATE_EVENT_ERROR:
          "Er is een fout opgetreden bij het creëren van de afspraak.",
        UPDATE_EVENT_SUCCESS: "Bijwerken van afspraak uitgevoerd.",
        UPDATE_EVENT_ERROR:
          "Er is een fout opgetreden bij het bijwerken van de afspraak.",
        DELETE_EVENT_SUCCESS: "{{total}} afsprake(n) verwijderd",
        DELETE_EVENT_SERVER_ERROR:
          "Er is een fout opgetreden bij het verwijderen van de afspraak.",
      },
    },
    "patient-appointment-history": {
      TITLE: "Afspraaksgeschiedenis",
      APPOINTMENT_CREATOR: "Aangemaakt door: ",
      CREATION_DATE: "Aanmaakdatum: ",
    },
    tooltip: {
      DATE_AND_DURATION: "Datum en duur",
      MINUTES: "minuten",
    },
    flow: {
      FLOW: "Stream",
      DISPLAY_ALL_FLOW_ITEMS: "Alle elementen weergeven",
      ERROR_GET: "Fout tijdens het laden van de elementen van stream 1733",
      item: {
        CANCEL_ELEMENT: "Element annuleren",
        PROCESSED_BY: "Behandeld door",
        CANCELED_BY: "Geannuleerd door",
        PROCESSED_THE: "op",
        CANCELED_THE: "op",
      },
      delete: {
        TITLE: "Element van stream annuleren",
        REASON: "Reden",
        NOTE: "Nota",
        CONFIRM_CANCELLATION: "Annuleren bevestigen",
      },
    },
    "flow-item": {
      VISIT: "Bezoek",
      CONSULTATION: "Consultatie",
      OPINION: "Uitstel van het verzoek",
      TESTCOVID19: "Test Covid-19",
      COVID19: "Covid-19",
      UNKNOW: "Onbekend",
    },
    "flow-treated-conflict": {
      WARNING_MESSAGE:
        "De boodschap werd behandeld door een andere gebruiker, annuleer de acties die werden ondernomen voor deze boodschap om gedupliceerde evenementen te voorkomen.",
      PATIENT_FIRSTNAME: "Voornaam van de patiënt",
      PATIENT_LASTNAME: "Naam van de patiënt",
      TITLE: "Titel",
      STREET: "Straat",
      I_UNDERSTAND: "Ik heb het begrepen",
    },
    messages: {
      OUT_OF_BUSINESS_HOURS_WARNING:
        "De geselecteerde periode bevindt zich buiten de uren van beschikbaarheid.",
      NO_PATIENT_LINKED_TO_EVENT:
        "Geen enkele patiënt gekoppeld met de afspraak.",
      PATIENT_OR_DETAIL_MANDATORY:
        "De patiënt of de reden moet in ieder geval worden vermeld.",
      PATIENT_DOES_NOT_EXISTS:
        "De patiënt die bij de afspraak hoort, bestaat niet (meer).",
    },
    actions: {
      ACTIONS: "Acties",
      EDIT: "Bewerken",
      REMOVE: "Verwijderen",
      ADD: "Toevoegen",
      UPDATE: "Bijwerken",
      CANCEL: "Annuleren",
    },
  },
  "keywords-editor-wrapper": {
    UNSUPPORTED_FORMAT: {
      WARNING: "Het formaat van dit document wordt niet ondersteund.",
      CONSEQUENCE: "Dit kan een onverwachte bladindeling geven.",
    },
  },
  keyword: {
    "keyword-management": {
      ADD_KEYWORD: "Sleutelwoord toevoegen",
      KEYWORDS: "Sleutelwoord",
      SUBWORD: "Subwoord",
      SHORTCUTS: "Snelkoppelingen",
      VALUES: "Waarden",
    },
    "keyword-autocomplete": {
      TITLE: "Sleutelwoord - automatisch aanvullen",
    },
    actions: {
      ACTIONS: "Acties",
      EDIT: "Wijzigen",
      REMOVE: "Verwijderen",
      REMOVE_KEYWORD: "Sleutelwoord verwijderen",
      ADD: "Toevoegen",
      UPDATE: "Registreren",
      CANCEL: "Annuleren",
      YES: "Ja",
      NO: "Nee",
    },
    "toolbar-actions": {
      ADD_SUBWORD: "Subwoord toevoegen",
      ADD_WORD: "Woord toevoegen",
      DELETE: "Verwijderen",
      EDIT: "Wijzigen",
    },
    messages: {
      CREATE_KEYWORD_SUCCESS: "Creatie van trefwoord uitgevoerd.",
      CREATE_KEYWORD_ERROR:
        "Er is een fout opgetreden bij het creëren van het trefwoord.",
      UPDATE_KEYWORD_SUCCESS: "Bijwerken van trefwoord uitgevoerd.",
      UPDATE_KEYWORD_ERROR:
        "Er is een fout opgetreden bij het bijwerken van het trefwoord.",
      DELETE_KEYWORD_REASK:
        "Het verwijderen van het trefwoord zal ook alle snelkoppelingen ervan verwijderen.",
      DELETE_KEYWORD_SUCCESS: "Verwijderen van trefwoord uitgevoerd.",
      DELETE_KEYWORD_ERROR:
        "Er is een fout opgetreden bij het verwijderen van het trefwoord..",
      DELETE_KEYWORD_ILLEGAL:
        "U bent niet de maker van dit trefwoord en u kunt het dus niet verwijderen.",
    },
  },
  "patient-posology-scheme-print": {
    MED_CHRONIC_DAILY: "Dagelijkse chronische medicatie",
    NAME: "Naam",
    REMARK: "Opmerking",
    MED_CACUTE_DAILY: "Tijdelijke dagelijkse medicatie",
    END_DATE: "Einddatum",
    MED_OTHER_FREQUENCY: "Medicatie met andere frequentie",
    POSOLOGY: "Doseringsschema",
    TREATMENTS_OF: "Behandelingen van",
    PAGE: "Pagina",
  },
  "patient-medication": {
    POSOLOGY: "S/",
    QUANTITY: "Dt/",
    ADMINISTRATION_MODE: "Beheermodus",
    SAMV2_VERSION: "SAMv2 Versie",
    BOXES: "Doos(dozen)",
    CANNOT_ADD_WHILE_EDITING:
      "U kunt een behandeling die bewerkt wordt, niet toevoegen",
    pdf_prescription: {
      DELIVRANCE_DATE: "Einddatum voor uitvoering",
      INSS: "INSZ",
      NIHII: "RIZIV-nr.",
      electronic: {
        PROOF: "BEWIJS VAN ELEKTRONISCH VOORSCHRIFT",
        INFOTOP:
          "Toon dit document aan uw apotheker om de barcode te laten scannen en de voorgeschreven geneesmiddelen te ontvangen.",
        PRACTITIONER: "Voorschrijver",
        PATIENT: "Begunstigde",
        CONTENT: "Inhoud van het elektronische voorschrift",
        INFOBOTTOM:
          "Opgelet: er wordt geen rekening gehouden met handgeschreven aanvullingen op dit document",
        DATE: "Datum",
      },
      paper: {
        PRACTITIONER: "Naam en voornaam van voorschrijver",
        BITRH_DATE: "Geboortedatum",
        PRACTITIONER_FILL: "IN TE VULLEN DOOR DE VOORSCHRIJVER:",
        PATIENT: "Naam en voornaam\n van de begunstigde",
        VIGNETTE: "Voorbehouden voor de sticker\nvan de verpakking",
        STAMP: "Stempel van de voorschrijver",
        DATE_SIGNATURE: "Datum en handtekening\n van de voorschrijver",
        MEDICATION:
          "VOORSCHRIJVING VAN GENEESMIDDELEN VAN TOEPASSING VANAF 1 november 2019",
      },
    },
    TITLE: "Actieve behandelingen",
    MEDICATION: "Medicatie",
    HISTORIQUE: "Historiek",
    TREATMENT_HISTORY: "Historiek van behandelingen",
    CHAPITRE_IV: "Hoofdstuk IV",
    E_RECIPE: "Historiek van voorschriften",
    CLOSE: "Sluiten",
    PRINT_POSOLOGIE: "Medicatieschema afdrukken",
    SAVE_POSOLOGIE: "Medicatieschema downloaden",
    CANCEL: "Annuleren",
    RESET: "Herinitialiseren",
    REGISTER: "Registreren",
    REGISTER_AND_PRESCRIBE: "Registreren en voorschrijven",
    CHOOSE_MEDICAMENT: "Een geneesmiddel kiezen",
    CHOOSE_MAGISTRAL_PERSO: "Een persoonlijke magistrale bereiding kiezen",
    CHOOSE_DCI: "Een DCI kiezen",
    JEUN: "Nuchter",
    MORNING: "Ontbijt",
    PRINT: "Afdrukken",
    "10H": "10u",
    LUNCH: "Middag",
    "16H": "16u",
    EVENING: "Avond",
    NIGHT: "Nacht",
    REPAS: "Maaltijd",
    BEFORE: "Voor",
    DURING: "Tijdens",
    AFTER: "Na",
    "TOUS LES": "Alle",
    REMARQUES: "Opmmerkingen",
    JOURS: "Dagen",
    CHRONIQUE: "Chronisch",
    ONESHOT: "Een keer",
    FREEPOSOLOGY: "Vrije dosering",
    DRUGROUTE: "Toediening",
    FROM: "van",
    TO: "tot",
    LINK_HEALTH_ELEM: "Koppeling met zorgelement: ",
    PRESCRIPTION_EN_COURS: "Voorschrift in uitvoering",
    NOTE: "Nota",
    TR_DURING: "Lopende behandeling",
    TR_AIGU: "Acute behandeling",
    TR_CHRONIQUE: "Chronische behandeling",
    N_COMP_X_PER_PERIOD: "{{n_comp}} comp {{x_time}}x per {{period}}",
    CBIP_DETAILS: "Meer details via de BCFI",
    CBIP: "BCFI",
    SAVE: "Registreren",
    SAVE_PRINT: "Registreren en afdrukken",
    ERECIPE_AND_PRINT: "Versturen via eRecipe en afdrukken",
    ERECIPE_AND_EMAIL: "Versturen via eRecipe en e-mail",
    EMPTY: "Order leeg",
    ADD_TREATMENT: "Deze behandeling toevoegen",
    ADD_AND_PRESCRIBE_TREATMENT: "Deze behandeling toevoegen en voorschrijven",
    ADD_ALL_TREATMENT: "Alle chronische behandelingen toevoegen",
    DELETE_TREATMENT: "De behandeling verwijderen",
    STOP_TREATMENT: "Een behandeling stopzetten",
    DELETE_ORDER: "Order verwijderen",
    PRESCRIBER: "Voorschrijver",
    ORDER: "Order",
    BEFORE_LUNCH: "voor de maaltijd",
    DURING_LUNCH: "tijdens de maaltijd",
    AFTER_LUNCH: "na de maaltijd",
    A_JEUN: "Nuchter",
    A_MORNING: "'s ochtends",
    A_10H: "om 10u",
    A_LUNCH: "'s middags",
    A_16H: "om 16u",
    A_EVENING: "'s avonds",
    A_NIGHT: "bij het slapengaan",
    NO_POSOLOGY: "Geen dosering aangegeven",
    PRESCRIBE: "Aan voorschrift toevoegen",
    TAKE_DURING_X_DAYS_1: "Gedurende 1 dag te nemen",
    TAKE_DURING_X_DAYS_N: "Gedurende {{n}} dagen te nemen",
    UNITIES: "Eenheden",
    CHECK_REASON:
      "Uw patiënt is allergisch (of intolerant) voor deze klasse medicatie vanwege de volgende reden:",
    PRESCRIBE_ANYWAY: "Alsnog voorschrijven",
    NOT_PRESCRIBE: "Niet voorschrijven",
    CHAPTER_IV_AVAILABLE: "Terugbetaling hoofdstuk IV beschikbaar",
    EDIT_TREATMENT: "Deze behandeling wijzigen",
    EDIT_TREATMENT_IN_PROLONG_MODE:
      "U kunt enkel het aantal dagen van deze behandeling wijzigen omdat deze zich op een order bevindt.",
    ARCHIVE: "Archiveren",
    EDITION_OF: "Bewerking van: ",
    ADD: "Toevoegen",
    NOT_ADD: "Niet toevoegen",
    ERROR_ATC_CHECK: "Onmogelijke de ATC-codes te controleren",
    ERROR_CNK_CHECK: "Onmogelijk om de CNK-code te recupereren",
    ERROR_UPLOAD: "Verstruren van het order is mislukt",
    ERROR_GET_MEDICATION_LIST:
      "Onmogelijk de lijst met geneesmiddelen te verkrijgen.",
    ERROR_GET_MAP_LIST:
      "Onmogelijk de lijst met uw magistrale bereidingen te bekomen.",
    ERROR_GET_PER_LIST:
      "Onmogelijk de lijst met uw persoonlijke geneesmiddelen te bekomen.",
    ERROR_GET_MORE_INFO_MED:
      "Onmogelijk aanvullende informatie over het geneesmiddel te bekomen.",
    ERROR_UPLOAD_ERECEIP:
      "Het voorschrift kon niet naar Recip-e worden verstuurd",
    ERECEIP_CREATIPON_OK: "Voorschrift verstuurd naar Recip-e.",
    DELIVRANCE_END_DATE: "Einddatum uitvoering",
    END_THE: "Eindigen op",
    CHOSE_PRESCRIPTOR: "Een voorschrijver kiezen",
    PRINT_SUCCESS: "De documenten werden naar uw printer verstuurd",
    PRINT_ERROR: "Fout bij afdrukken van de documenten",
    INVALID_REASON:
      "Deze behandeling kon niet worden geïmporteerd sinds de migratie. We bevelen u aan deze behandeling te archiveren en ze opnieuw te creëren.",
    INVALID_REASON_PRESCRIBR:
      "U probeert een ongeldige behandeling voor te schrijven (weergeven in rood in de lijst met behandelingen). Archiveer deze behandeling en creëer ze opnieuw.",
    ORDONNANCE_N_TOO_LONG:
      "Opgelet : het order nr. {{n}} is lang en wordt mogelijk slecht afgedrukt.",
    START: "Begin",
    DECREASE_TREATMENT: "Verwijder deze behandeling van het voorschrift",
    RRSSTATE: {
      R: "Niet goedkoop, met prijssupplement.",
      G: "Goedkoop",
      B: "Niet goedkoop, geen prijssupplement.",
    },
    PRICE_EVALUATION: {
      NOT_CHEAP_WITH_PRICE_SUPPLEMENT: "Niet goedkoop, tegen meerprijs.",
      CHEAP: "Goedkoop",
      NOT_CHEAP_NO_PRICE_SUPPLEMENT: "Niet goedkoop, geen extra kosten.",
    },
    SEND_EMAIL: "E-mail",
    SUBJECT: "Uw voorschrift",
    BODY: "Beste,\nHet door u aangevraagde voorschrift is gegenereerd. Een kopie van het voorschrift is als bijlage bij deze e-mail gevoegd. U kunt met uw identiteitskaart of de streepjescode op het voorschrift naar de apotheek van uw keuze gaan.\nHet is niet nodig dit document af te drukken.\nMet vriendelijke groeten,\n",
    SAVING_MEDICATION: "Registratie van de geneesmiddelen",
    DONE_SAVING: "Opslaan uitgevoerd",
  },
  date: {
    FROM: "Van",
    TO: "tot",
  },
  "attachment-service": {
    ERROR_OPEN_TAB: "Onmogelijk om het document te vinden.",
  },
  "healthcare-party-service": {
    ERROR_GET_HCPS: "Onmogelijk om de lijst van betrokkenen te vinden",
  },
  "mediction-service": {
    ERROR_NEW_ES:
      "Een fout is opgetreden bij het maken van een zorgelement tengevolge van het opslaan van de behandeling.",
    ERROR_SAVE_CONTACT:
      "Een fout is opgetreden bij het opslaan van het contact.",
    ERROR_NEW_CONTACT: "Een fout is opgetreden bij het ingeven van een gegeven",
    PENDING_PRESCRIPTION_SAVED:
      "De voorschrift werd opgeslaan en is in behandeling ",
  },
  "filter-date": {
    TITLE: "Filteren op een zorgperiode",
    DU: "Van",
    AU: "tot",
    OK: "Filter toepassen",
    CANCEL: "Annuleren",
    LAST_6_MONTHS: "De laatste 6 maanden",
    LAST_MONTH: "Afgelopen maand",
    LAST_WEEK: "Afgelopen week",
  },
  "information-update": {
    TITLE: "Bijwerken van informatie van de patiënt",
    DESC: "Nieuwe informatie is beschikbaar voor de patiënt",
    ACTUAL_TITLE: "Huidige informatie",
    NEW_TITLE: "Nieuwe informatie",
    SSIN: "ONSZ",
    NAME: "Naam",
    BIRTH_DATE: "Geboortedatum",
    DEATH_DATE: "Datum van overlijden",
    CIVIL_STATUS: "Burgerlijke staat",
    ADDRESS: "Adres",
    OK: "Mettre à jour les informations",
    CANCEL: "Informatie opslaan",
    NATIONALITY: "Nationaliteit",
    CIVILITY: "Beleefdheid",
    GENDER: "Soort",
    BIRTHPLACE: "Geboorteplaats",
    DEATHPLACE: "Plaats van dood",
    "TEMPORARY-ADDRESS": "Tijdelijk adres",
    "POST-ADDRESS": "Postadres",
    "DIPLOMATIC-ADDRESS": "Diplomatiek adres",
    "DIPLOMATIC-POST-ADDRESS": "Diplomatiek postadres",
    "REFERENCE-ADDRESS": "Referentie adres",
    "RESIDENTIAL-ADDRESS": "Woonadres",
  },
  "service-search": {
    FIND_CODE: "Een code opzoeken",
  },
  "msoap-o": {
    ADD_OBJECTIVE: "Een doel toevoegen",
    SELECTION: {
      BIOMETRY: "Biometrie",
    },
    OBJECTIVE_PLACEHOLDER: "Objectief",
    BOX: {
      HEIGHT: "Lengte:",
      WEIGHT: "Gewicht:",
      BMI: "BMI:",
      HEAD_CIRC: "Schedelomtrek:",
      HIP_CIRC: "Buikomtrek:",
      BLOOD_PRESSURE: "Bloeddruk:",
      HEART_RATE: "Polsslag:",
      GLYCEMIA: "Glyc.:",
      TEMPERATURE: "T° :",
      SATURATION_O2: "SatO² :",
      INR: "INR:",
      PAIN: "Pijnschaal: niveau",
    },
    hide: {
      TITLE: "Bevestiging",
      CONFIRM: "Bent u zeker dat u deze biometie wilt wissen?",
      YES: "Ja",
      NO: "Nee",
      HIDE: "Wis deze biometrie",
    },
  },
  "msoap-p": {
    PLAN_PLACEHOLDER: "Actieplan",
    ADD_PLAN: "Een plan toevoegen",
    NEW_PATIENT_INCAPACITY: "Ongeschiktheidsattest",
    NEW_PATIENT_PHYSIOTHERAPY: "Aanvraag kine",
    NEW_PATIENT_MEDICAL_CARES: "Aanvraag verpleegkundige",
    NEW_PATIENT_RX: "Aanvraag RX",
    NEW_PATIENT_VACCINE: "Vaccinatie",
    NEW_PATIENT_PROCEDURE: "Procedure",
    NEW_PATIENT_DOCUMENT: "Documenten",
    NEW_PATIENT_HANDICARE: "Formulier Handicare",
    NEW_PATIENT_COVID_LAB_REQUEST:
      "COVID-19: Aanvraag voor laboratoriumanalyse vanwege vermoeden van besmetting met SARS-COV-2",
    NEW_PATIENT_COVID_CONFIRMATION:
      "COVID-19: melding van vermoedelijke besmetting met SARS-COV-2 vanwege negatieve laboratoriumresultaten",
    NEW_PATIENT_COVID_SUSPECTED_CASE:
      "COVID-19: een vermodelijke besmetting met SARS-COV-2 zonder test melden",
    NEW_PATIENT_COVID_RAPID_TEST_RESULT:
      "COVID-19: Verzenden resultaat sneltest",
    NEW_PATIENT_MEDICATION: "Voorschrift",
    "NEW_PATIENT_ECONSULT-DERMATOLOGY": "Teledermatologie-applicatie",
    "REFERRAL-MARIA-MIDDELARES": "Verwijsbrief AZ Maria Middelares",
    "REFERRAL-UZ-GENT": "Verwijsbrief Spoedopname UZ Gent",
    "REFERRAL-ST-LUCAS-GENT": "Verwijsbrief Spoedopname AZ St Lucas Gent",
    "REFERRAL-AZ-JAN-PALFIJN": "Verwijsbrief Spoedopname AZ Jan Palfijn Gent",
    "PREOP-E17": "Preoperatief beleid E17",
    "KG-ZORG": "Vaststelling zorgtoeslag K&G",
    "COVID-19-FOLLOW-UP-LOCAL":
      "COVID-19: Lokale melding positieve of klinisch sterk verdachte patiënt",
    COVID_TRACKING: "COVID-19-tracking",
    "DIABETES-DRIVING-CERTIFICATE-G1":
      "Diabetes rijgeschiktheidsattest groep 1",
    "DIABETES-DRIVING-ADVICE-G2": "Diabetes rijgeschiktheidsadvies groep 2",
    "FAGG-COVID-19-VACCINE-SIDE-EFFECTS":
      "COVID-19: Melding van een bijwerking van een Covid-19 vaccin",
    "FAGG-MEDICATION-SIDE-EFFECTS":
      "Melding van een bijwerking van een geneesmiddel voor menselijk gebruik",
    FILE: "Bijlagen",
    ERECEIP_PRESCRIPTION: "Elektronisch voorschrift",
    PAPER_PRESCRIPTION: "Voorschrift op papier",
    TASK: "Taak",
    NEW_PATIENT_TASK: "Taak",
    TASK_DEADLINE: "in {{deadline}}",
    TREATMENT: "Behandeling",
    SUMMARY: "Samenvatting",
    ERROR_SAVE: "Het opslaan is mislukt",
    PRINT: "Afdrukken",
    MODAL_FILE_TITLE: "Bijlage",
    TREATMENT_MODIFIED: "Gewijzigd",
    TREATMENT_EXTENDED: "Verlengd",
    TREATMENT_STOPPED: "Gestopt",
  },
  "patient-biometry-dialog": {
    BIOMETRY: "Biometrieën",
    GLOBAL_NOTES_AND_TASKS: "Algemene nota's en taken",
    PRESSURE: "Bloeddruk",
    HEART_RATE: "Polsslag",
    WEIGHT: "Gewicht",
    HEIGHT: "Lengte",
    BODY_WEIGHT_INDEX: "BMI",
    CRANIAL_CIRCUMFERENCE: "Schedelomtrek",
    ABDOMINAL_CIRCUMFERENCE: "Buikomtrek",
    GLYCEMIA: "Bloedsuiker",
    TEMPERATURE: "Temperatuur",
    SATURATED_OXYGEN: "Sat. O₂",
    INR: "INR",
    NOTE: "Nota's",
    FOLLOW_UP: "Opvolging",
    VALUE: "Waarde",
    TYPE: "Type",
    REQUESTED_BY: "Aangevraagd door",
    TO_BE_CARRIED_OUT_BY: "Te realiseren door",
    TASKS: "Taken",
    CANCEL: "Annuleren",
    SAVE: "Opslaan",
    UNKNOWN_GENDER:
      "De referentiegegevens kunnen niet worden getoond als het geslacht van de patiënt onbekend is.",
    GLOBAL_TASKS: "Algemene taken",
    EXPAND_ME:
      "Geen enkel resultaat. Klik op één van de elementen om de details te tonen.",
    TASK_PRESET: {
      FREE_TEXT: "(vrije tekst)",
      VASCULAR: "controle BD/pols",
      SATURATION: "controle zuurstofsaturatie",
      HEIGHT_WEIGHT: "controle Gewicht/Grootte",
      CRANIAL_PERIMETER: "controle hoofdomtrek",
      ABDOMINAL_PERIMETER: "controle buikomtrek",
      GLYCEMIA: "controle bloedsuiker",
      TEMPERATURE: "controle temperatuur",
    },
    PANNING_AND_ZOOMING:
      'Om te scrollen, sleep de muis terwijl u de linkerknop ingedrukt houdt. Gebruik het muiswiel om in te zoomen. Om in te zoomen op een specifieke sectie, houd de "shift"-toets ingedrukt en klik en sleep met de muis om de sectie te selecteren waarop u wilt inzoomen.',
    FULLSCREEN_TOOLTIP: {
      OPEN: "Open de afbeelding in modus Volledig scherm.",
      CLOSE: "Sluit de modus Volledig scherm af.",
    },
    ERRORS: {
      FORM_DISABLED:
        "Een nota of een taak kan niet worden geregistreerd zonder het registreren van een nieuwe waarde.",
    },
    WARNING:
      "Opgelet, als u afsluit of annuleert zonder op de opslaan-knop te klikken, dan zal geen rekening worden gehouden met uw wijzigingen.",
  },
  "patient-incapacity": {
    CREATION_DATE: "Aanmaakdatum",
    DAYS: "Dag(en)",
    TITLE: "Certificaat van onderbreking van activiteit",
    TITLE_DIXIT: "Certificaat van onderbreking van activiteit",
    TITLE_COVID19: "Certificaat van onderbreking van activiteit",
    TITLE_MEDICAL_EMP:
      "Certificaat van onderbreking van activiteit loontrekkende werknemer - vertrouwelijk",
    TITLE_MEDICAL_IND:
      "Certificaat van onderbreking van activiteit onafhankelijk - vertrouwelijk",
    TITLE_MEDEX: "Medex-certificaat van onderbreking van activiteit",
    GENERIC: "Algemeen",
    MEDICAL: "Voor de controle-arts",
    ITT_GENERIC: "Ongeschiktheidsattest algemeen",
    ITT_MEDICAL: "Ongeschiktheidsattest voor raadgevende arts",
    INCAPACITY: "COA",
    INCAPACITY_MEDICAL: "Ongeschiktheidsattest raagevende arts",
    INCLUDE_MEDICAL: "Ongeschiktheidsattest bijvoegen voor raadgevende arts",
    OTHER: "Andere",
    DIXIT: "Dixit-certificaat",
    COVID19: "Covid-19",
    TO: "aan",
    MENTION_NOTE:
      "De bovengenoemde persoon is arbeidsongeschikt verklaard na een medisch onderzoek",
    template: {
      EMPLOYEE: "Werknemer",
      INDEPENDANT: "Zelfstandige",
      MEDEX: "Medex",
      MEDCONSULT: "Medconsult",
    },
    INCAPACITY_VALUE: "Ongeschiktheidsverklaring om:",
    CHOOSE_INCAPACITY_VALUE: "Kies waarvoor de patiënt ongeschikt is",
    value: {
      WORK: "Te werken",
      SCHOOL: "Lessen bij te wonen",
      NURSERY: "Kinderdagverblijf bij te wonen",
      SPORT: "Gymlessen te volgen",
      SWIM: "Te zwemmen",
      EFFORT: "Hevige inspanningen te doen",
      HOMEQUIT:
        "Niet in staat zijn domicilie te verlaten vanwege medische of sociale redenen",
      OTHER: "Andere",
    },
    INCAPACITY_REASON: "Reden van de onderbreking",
    reason: {
      ILLNESS: "Ziekte",
      SURGERY: "Chirurgische interventie",
      HOSPITALISATION: "Ziekenhuisopname",
      ACCIDENT: "Ongeval",
      WORKACCIDENT: "Werkongeval",
      FAMILY: "Aanwezigheid vereist door gezondheidstoestand van",
      OCCUPATIONALDISEASE: "beroepsziekte",
      PREGNANCY: "ziekte als gevolg van zwangerschap",
      ACCIDENT_OCCURED_ON: "opgekomen op",
      HOSPI_OCCURED_ON: "opgekomen op",
      OCCUP_DISEASE_DECLARED_ON: "verklaard op",
      family: {
        MOTHER: "Zijn/haar moeder",
        FATHER: "Zijn/haar vader",
        SON: "Zijn/haar zoon",
        DAUGHTER: "Zijn/haar dochter",
        HUSBAND: "Zijn/haar echtgenoot",
        WIFE: "Zijn/haar echtgenote",
      },
    },
    INCAPACITY_OUTING: "Zich buitenshuis te begeven",
    outing: {
      ALLOWED: "Toegestaan",
      FORBIDDEN: "Verboden",
      NOTRECOMMENDED: "Niet aangeraden",
    },
    INCAPACITY_TYPE: "Type",
    type: {
      GENERIC: "Algemeen",
      GENERIC_MEDICAL: "Algemeen + adviserend arts",
      MEDEX: "Medex",
    },
    date: {
      FROM: "van",
      TO: "tot",
      NB_DAYS: "dagen",
      THAT_IS: " ",
      INCLUDED: "inbegrepen",
    },
    extension: {
      EXTENSION: "Verlenging",
      BEGINNING: "Eerste ongeschiktheid",
    },
    INCAPACITY_RESUMPTION: "Herneming",
    resumption: {
      PLANNED_ON: "voorzien op",
    },
    INCAPACITY_DIAGNOSIS:
      "Diagnose (symptoom, functionele stoornis, sociale moeilijkheid)",
    INCAPACITY_DIAGNOSIS_ONLY: "Diagnose",
    INCAPACITY_JOB: "Beroep",
    INCAPACITY_STATUS:
      "Beroepsstatus op het moment van aanvang van arbeidsongeschiktheid",
    status: {
      WORKER: "Arbeider",
      EMPLOYEE: "Werknemer",
      UNEMPLOYED: "Werkloos",
      COMPLSELFEMPLOYED: "Zelfstandige in bijberoep",
      INDEPENDANT: "Zelfstandige",
      HELPER: "Helpende echtgenoot",
    },
    INCAPACITY_HOSPI_DATE: "Is of zal in het ziekenhuis opgenomen zijn vanaf",
    INCAPACITY_HOSPI_DATE_ONLY: "In het ziekenhuis opgenomen zijn vanaf",
    INCAPACITY_DELIVERY_DATE:
      "In geval van zwangerschap, de voorziene bevallingsdatum",
    INCAPACITY_DELIVERY_DATE_ONLY: "Voorziene bevallingsdatum",
    HISTORY: "Voorgeschiedenis van arbeidsonderbrekingen",
    NO_HISTORY: "Geen voorgeschiedenis weer te geven",
    error: {
      REQUIRED: "Dit veld is verplicht",
      POSITIVE_NB: "Minimale aanvaarde waarde",
    },
    pdf: {
      HEADER:
        "Ik ondergetekende, arts in de geneeskunde, verklaar te hebben onderzocht en ondervraagd: Mr/Mrs <b>{{ patientName }}</b> die zich heden heeft getoond op consultatie ",
      HEADER_DIXIT:
        "Ik ondergetekende, arts in de geneeskunde, heb gesproken met <b>{{ patientName }}</b> geboren op <b>{{ patientBirthDate }}</b>. Hij/zij verklaarde ongeschikt te zijn.",
      HEADER_COVID19:
        "Ik ondergetekende, dokter in de geneeskunde, verklaar vandaag via telefoon in het kader van de COVID-19 pandemie te hebben ondervraagd.",
      INCAPACITY_VALUE: "Niet in staat",
      INCAPACITY_VALUE_DIXIT: "Die zich niet in staat voelde om",
      INCAPACITY_REASON: "Vanwege",
      INCAPACITY_DATES: "Voor een duur van",
      NAME_PATIENT: "Naam, voornaam van de patiënt",
      SSIN_PATIENT: "Rijksregisternummer van de patiënt",
      LEAVE: "Vertrek",
      IDENTIFICATION_DOCTOR_NISS: "Identificatie van de arts met RIZIV-nummer",
      DATE: "Datum",
      ITT_CONCERNS: "Dit onbekwaamheidscertificaat betreft",
    },
    SSIN_REQUIRED:
      "Het rijksregisternummer van de patiënt is noodzakelijk om een eMediAtt aan te maken.",
    ONLY_ONE_DIAGNOSIS:
      "Enkel een diagnose is mogelijk met Medex. Enkel de eerste zal voorkomen in het eMediAtt.",
    MISSING_CODIFICATION_DIAGNOSIS:
      "De eerste gekozen diagnose is niet compatibel met eHealth (geen ICPC- en ICD-code) en kan niet naar Medex worden verstuurd.",
    EH_CONNECTION_REQUIRED:
      "Verbind u met eHealth om een eMediAtt te versturen.",
    PRINT_FOR_PATIENT: "Kopie voor de patiënt afdrukken",
    SEND_TO_MEDEX: "Naar Medex versturen",
    E_MEDI_ATT_SENT:
      "Attest verstuurd naar Medex. U kunt deze raadplegen via de eHealthBox in de sectie 'Vesrtuurde boodschappen'",
    E_MEDI_ATT_SEND_ERR:
      "De verklaring is niet verstuurd naar Medex (fout bij versturen)",
    E_MEDI_ATT_GEN_ERR:
      "De verklaring is niet verstuurd naar Medex (fout bij genereren)",
    E_MEDI_ATT_UNKNOW_ERR:
      "De verklaring is niet verstuurd naar Medex (onbekende fout)",
    SEND_BY_MAIL: "Versturen via e-mail",
    SUBJECT: "Uw onbekwaamheidscertificaat",
    BODY: "Goeiedag, hierbij in bijlage het door u gevraagde onbekwaamheidscertificaat.",
  },
  "patient-biometry-vascular": {
    SYSTOLIC_BP: "Sys. bloeddruk",
    DIASTOLIC_BP: "Dias. bloeddruk",
    SYSTOLIC_DIASTOLIC_BP: "Sys./dias. bloeddruk",
    HEART_RATE: "Polsslag",
  },
  "patient-biometry-weight-height": {
    WEIGHT: "Gewicht",
    HEIGHT: "Lengte",
    HIP_CIRCUMFERENCE: "Buikomtrek",
    HEAD_CIRCUMFERENCE: "Schedelomtrek",
    BMI: "BMI",
  },
  "patient-biometry-blood": {
    GLYCEMIA: "Bloedsuiker",
    TEMPERATURE: "T°",
    SATURATIONO2: "Sat O²",
    INR: "INR",
  },
  "patient-biometry-table": {
    DATE: "Datum",
    SHOW_VALUES: "Waardes weergeven op de grafiek",
  },
  "patient-msoap-p-services-common": {
    CLOSE: "Sluiten",
    SAVE: "Registreren",
    PRINT: "Afdrukken",
    SAVE_AND_PRINT: "Registreren en afdrukken",
    CANCEL: "Annuleren",
    EXTENSION: "Verlenging",
    DATE: "Datum",
    OTHER_TREATMENT: "Andere behandelingen",
    MISTER: "Dhr.",
    MISS: "Mevr.",
    MISTER_MISS: "Dhr./Mevr.",
    DIAGNOSIS: "Diagnose",
    DIAGNOSIS_LOCALIZATION: "LOCATIE",
    ADD_DIAGNOSIS: "Diagnoste van de SOAP",
    HISTORY_OTHER_ITEMS: "en {{ number }} autre(s)",
    CHANGE_LANG: "Taal wijzigen",
    SEARCH_MAX_REACHED: "U kunt maximaal {{ maxNumber }} element(en) toevoegen",
    SEND_TO_HEALTHBOX: "Versturen via eHealthBox",
    pdf: {
      PATIENT_LASTNAME: "Achternaam",
      PATIENT_FIRSTNAME: "Voornaam",
      PATIENT_BIRTHDATE: "Geboortedatum",
      PATIENT_SSIN: "Rijksregisternummmer",
      PATIENT_INSURABILITY: "Verzekerbaarheid",
    },
    delete: {
      TITLE: "Bevestiging",
      CONFIRM: "Bent u zeker dat u deze dienst wilt verwijderen?",
      YES: "Ja",
      NO: "Nee",
    },
    sentTo: "Verzonden naar",
  },
  "patient-physiotherapy": {
    TITLE: "Aanvraag Kinesitherapievoorschrift",
    box: {
      PHYSIOTHERAPY: "Kine",
      SESSIONS: "Sessies",
      FREQUENCY: "week",
    },
    PHYSIOTHERAPY_SESSIONS: "Sessies",
    PHYSIOTHERAPY_NUMBER_SESSIONS: "kinesitherapie sessies",
    PHYSIOTHERAPY_FREQUENCY_SESSIONS: "keer per",
    PHYSIOTHERAPY_TREATMENT: "Aangeraden extra behandelingen",
    treatment: {
      PHYSIOTHERAPY: "Fysiotherapie",
      SOPHROLOGY: "Sofrologie",
      PRENATALGYM: "Prenatale gymnastiek",
      RESPIRATORY: "Ademhalingskinesitherapie",
      WALKING: "Rehabilitatiewandeling",
      LYMPHDRAINAGE: "Lymfedrainage",
      VESTIBULAR: "Vestibulaire kinesitherapie",
      URODYNAMIC: "Uro-dynamische kinesitherapie",
      SPINALMANIP: "Manipulatie van wervelkolom",
      OTHER: "Andere behandelingen",
    },
    PHYSIOTHERAPY_NOTE: "Nota",
    note: {
      CHOOSE_S: "Inhoud van S",
      CHOOSE_O: "Inhoud van O",
      S_ADDED: "Anamnese",
      O_ADDED: "Klinisch onderzoek",
    },
    PHYSIOTHERAPY_EXTENSION: "Verlenging",
    PHYSIOTHERAPY_DATE: "Datum",
    PHYSIOTHERAPY_MOTIVE: "Reden",
    PHYSIOTHERAPY_MOTIVE_DESC:
      "Waarom heeft de patient meer dan één sessie per dag nodig ?",
    PHYSIOTHERAPY_INTERVENTION_DATE: "Datum van de behandeling",
    PHYSIOTHERAPY_INAMI: "RIZIV-code",
    PHYSIOTHERAPY_PATHOLOGY_TYPE: "Type pathologie",
    pathologyTypes: {
      HEAVY: "Zware pathologie",
      COMMON: "Normale pathologie",
    },
    PHYSIOTHERAPY_NO_MOVE:
      "Kan zich niet verplaatsen vanwege medische of sociale redenen",
    PHYSIOTHERAPY_NO_MOVE_PRINT_TEXT:
      "Kan zich niet verplaatsen vanwege medische of sociale redenen",
    REQUEST_REPORT_PRINT_TEXT:
      "Ik wil een rapport ontvangen aan het einde van de behandeling.",
    HISTORY: "Voorgeschiedenis",
    NO_HISTORY: "Geen voorgeschiedenis weer te geven",
    history: {
      OTHER_DIAGNOSIS: "en {{ number }} ander(e)",
      NO_DIAGNOSIS: "Geen diagnose",
      SESSIONS: "sessie(s)",
    },
    pdf: {
      TITLE: "Kinesitherapievoorschrift",
    },
    POST_TRAUMATIC: "Kinesitherapiesessie na trauma of chirurgische ingreep",
    REQUEST_REPORT: "Graag verslag bij einde van de behandeling",
  },
  "patient-medical-cares": {
    TITLE: "Aanvraag tot verpleegkundige zorg",
    box: {
      MEDICAL_CARES: "Verpleeglkundige",
      SESSIONS: "Sessies",
      FREQUENCY: "week",
    },
    MEDICAL_CARES_NUMBER_SESSIONS: "Aantal sessies",
    MEDICAL_CARES_FREQUENCY_SESSIONS: "sessies per",
    MEDICAL_CARES_NOTE: "Verdere informatie",
    MEDICAL_CARES_TREATMENT: "Zorgen",
    treatment: {
      MEDICATION: "Voorbereiding en toediening van medicatie",
      medication: {
        MEDICATION: "Medicatie",
        ADMINISTRATION: "Toedieningswijze",
        DOSAGE: "Doseringsschema",
        MIXING:
          "Gecombineerde toediening toegelaten (indien inspuiting van meerdere producten)",
      },
      WOUNDCARE: "Wondzorg",
      woundcare: {
        TYPE: "Type wond",
        ADVICE: "Eventuele raadgevingen",
      },
      HYGIENE: "Toilet en hygiënische zorgen",
      COMPRESSION: "Druktherapie",
      compression: {
        TYPE: "Type drukverband",
        MODE: "Modaliteiten van drukverband",
        TARGET: "Te bekomen doelen",
        STOCKINGS: "Kous",
        BANDAGE: "Verband",
        PLACEMENT: "Aanbrengen",
        PLACEMENTREMOVAL: "Aanbrengen en afhalen",
      },
      MATERIALREMOVAL: "Uithalen van hechtingsmateriaal, mechen of sondes",
      removal: {
        TYPE: "Type zorg",
        DATE: "Datum van uithalen",
      },
      CATHETERS: "Sondes",
      catheters: {
        TYPE: "Type",
        ADVICE: "Eventuele raadgevingen",
        BLADDER: "Opvolging van een blijvende blaassonde",
        INOUT: "In/out-sondering",
        PUBIC: "Suprapubische sonde",
      },
      OSTOMY: "Ostomie: opvolging en zorg",
      WEEKLYCALENDAR: "Voorbereiden van de weekkalender",
      DAILYADMINISTRATION:
        "Dagelijks toedienen van medicatie (psychiatrische patient)",
      OTHER: "Andere zorgen",
    },
    HISTORY: "Voorgeschiedenis",
    NO_HISTORY: "Geen voorgeschiedenis weer te geven",
    history: {
      OTHER_TREATMENTS: "en {{ number }} andere(n)",
      NO_TREATMENT: "Geen behandeling",
    },
    pdf: {
      TITLE: "Voorschrift voor verpleegkundige zorg",
    },
  },
  "patient-x-ray": {
    TITLE: "Aanvraag voor beeldvorming",
    box: {
      X_RAY: "RX",
    },
    SEARCH: "Voorgesteld onderzoek",
    search: {
      PLACEHOLDER: "Voorgesteld type beeldvorming",
    },
    CLINICAL_INFO: "Relevante klinische informatie",
    "clinical-info": {
      CHOOSE_S: "Inhoud van S",
      CHOOSE_O: "Inhoud van O",
      S_ADDED: "Anamnese",
      O_ADDED: "Klinisch onderzoek",
    },
    DIAGNOSIS: "Diagnostische vraagstelling",
    OTHER_INFO: "Bijkomende relevante inlichtingen",
    "other-info": {
      ALLERGY: "Allergie",
      DIABETES: "Diabetes",
      PREGNANT: "Zwangerschap",
      IMPLANT: "Implantaat",
      RENALFAILURE: "Nierfalen",
      OTHER: "Andere",
    },
    PREVIOUS_EXAMS:
      "Vorige relevante onderzoeken in verband met de diagnostische vraagstelling",
    "previous-exams": {
      MRI: "MRI",
      XRAY: "RX",
      CTSCAN: "CT-scan",
      ECHO: "Echo",
    },
    HISTORY: "Voorgeschiedenis",
    NO_HISTORY: "Geen voorgeschiedenis weer te geven",
    history: {
      NO_RADIOGRAPHY: "Geen onderzoek",
      NO_DIAGNOSIS: "Geen diagnose",
    },
    pdf: {
      TITLE: "Aanvraag formulier voor medische beeldvorming",
    },
  },
  "patient-procedure": {
    TITLE: "Procedure",
    box: {
      PROCEDURE: "Procedure",
    },
    NOTE: "Nota",
    SEARCH: "Procedure",
    search: {
      PLACEHOLDER: "Een procedure kiezen",
    },
  },
  "magistral-management": {
    MAGISTRAL_MANAGEMENT: "Beheer van magistrale bereidingen",
    RESET: "Herinitialiseren",
    SAVE: "Registreren",
    DELETE: "Verwijderen",
    EDIT: "Wijzigen",
    EDIT_NOT_MINE:
      "Deze magistrale bereiding werd door een andere gebruiker gecreëerd.",
    PRINT: "Afdrukken",
    CLASS: "Klasse",
    NAME: "Naam",
    QUANTITY: "Dt/",
    UNITY: "Eenheid",
    SHAPE: "Pf/",
    NOTE: "Note",
    FORMULA: "R/",
    POSOLOGY: "S/",
    NEW_CLASS: "Nieuwe klasse",
    NEW_CLASS_TITLE: "Een nieuwe klasse creëren",
    SEARCH: "Magistrale bereidingen vinden",
    ERROR_SAVE: "Onmogelijk om uw magistrale bereidingen te registreren",
    ERROR_GET: "Onmogelijk om uw magistrale bereidingen op te halen",
    ERROR_GET_PERSONAL: "Onmogelijk om uw persoonlijke medicatie op te halen",
    actions: {
      REMOVE: "Verwijderen",
      REMOVE_MAGISTRAL: "Magistrale bereiding verwijderen ",
      CANCEL: "Annuleren",
    },
    messages: {
      DELETE_MAGISTRAL_REASK:
        "Weet U zeker dat u de magristrale bereiding wilt verwijderen?",
      DELETE_MAGISTRAL_SUCCESS: "Verwijdering uitgevoerd",
      DELETE_MAGISTRAL_ERROR:
        "Fout bij het verwijderen van de magistrale bereiding ",
    },
  },
  "patient-history": {
    SORT_TREATMENT: "Behandelingen sorteren op:",
    DATE: "Datum",
    HEALTH_ELEMENT: "Zorgelement",
    BEGIN: "Begin",
    END: "Einde",
    TREATMENT: "Behandeling",
    POSOLOGY: "Doseringsschema",
    MOTIF_STOP: "Reden voor stoppen",
    PRESCRIPTOR: "Voorschrijver",
    CNK: "CNK-code",
    UNSPECIFIED: "Niet gespecificeerd",
    OUTDATED: "Historiek",
    NOTE: "Opmerking",
    BOXES: "{{boxes}} dozen",
    RENEW: "Opnieuw voorschrijven",
    "date-filter": {
      PERIOD: "Periode",
      LAST_6_MONTH: "De afgelopen 6 maanden",
      ALL_TIME: "Geen filter",
      BEFORE_YEAR: "Voor {{year}}",
    },
    atc_filter: {
      ATC_CLASS: "ATC-klasse",
      ALL_CLASSES: "Alle klassen",
    },
    "text-filter": {
      SEARCH: "Opzoeken",
    },
    MEDICATION_LINES: "Medicatielijnen",
  },
  "stop-treatment": {
    STOP_TREATMENT: "Een behandeling stopzetten",
    REASON: "Reden",
    COMMENT: "Nota",
    OPTIONAL: "Optioneel",
    OK: "Opslaan",
    CANCEL: "Annuleren",
    reasons: {
      ALLERGY: "Allergie",
      INTOLERANCE: "Intolerantie",
      UNEXPECTED_SIDE_EFFECTS: "Ongewenste effecten",
      INEFFICIENT: "Inefficiëntie",
      REFUSAL_PATIENT: "Weigering van de patiënt",
      endOfTreatment: "Einde van de behandeling",
      packagingChange: "Wijziging van verpakking",
      other: "Andere",
    },
  },
  diagnostic: {
    SCIENSANO: "Covid 19: Sciensano procedures",
  },
  scales: {
    SERVICE_EXIST: "De dienst bestaat",
    pain: {
      TITLE: "Pijnschaal",
      QUESTION: "Pijnniveau op een schaal van 0 to 10",
      CANCEL: "Annuleren",
      PROCESS: "De evaluatie beëindigen",
      ANSWER_COMMENTS: {
        "0": "Geen enkele pijn",
        "1": "Zeer lichte pijn",
        "2": "Lichte pijn",
        "3": "Lichte pijn",
        "4": "Middelmatige pijn",
        "5": "Middelmatige pijn",
        "6": "Middelmatige pijn",
        "7": "Ernstige pijn",
        "8": "Ernstige pijn",
        "9": "Zeer ernstige pijn",
        "10": "Ondraaglijke pijn",
      },
    },
    belrai: {
      TITLE: "Belrai-schaal",
      OPEN_BELRAI: "Belrai openen",
      SCALE: "Schaal",
      SCORE: "Score",
      REPORT: "Rapport",
      CHOOSE_FILE: "Een bestand kiezen",
      CANCELED: "Annuleren",
      SAVE: "Registreren",
      SCALE_WITH_TYPE_EXIST: "De schaal van dit type bestaat al",
      COPY_SSIN: "INSZ van de patiënt kopiëren",
      PATIENT_SSIN: "INSZ van de patiënt",
      COPY_SUCCESS: "Kopiëren van INSZ gelukt",
      COPY_FAILED: "Kopie van INSZ mislukt",
      DROP_REPORT_HERE: "Rapport hier neerzetten",
      OR: "of",
      DROP_HERE: "Hier neerzetten",
      types: {
        BELRAI_SCREENER: "Belrai-screener",
        HOME_CARE: "Thuiszorg",
        LONG_TERM_CARE_FACILITIES: "Faciliteiten voor langdurige zorg",
        PALLIATIVE_CARE: "Palliatieve zorgen",
        MENTAL_HEALTH: "Mentale gezondheid",
        COMMUNITY_MENTAL_HEALTH: "Geestelijke gezondheid van de gemeenschap",
        PALLIATIVE_SCREENER: "Palliatieve screener",
        AC_ADMISSION_ASSESSMENT: "Acute zorgen - Beoordeling van opname",
        AC_DISCHARGE_ASSESSMENT: "Acute zorgen - Beoordeling van ontslag",
        AC_CGA_ADMISSION_ADMISSION:
          "Acute zorgen - Opname voor de uitgebreide geriatrische beoordeling",
        AC_CGA_ADMISSION_DISCHARGE:
          "Acute zorgen - Ontslag voor de uitgebreide geriatrische beoordeling",
        AC_CGA_ADMISSION_PREMORBID:
          "Acute zorgen - Pre-morbide uitgebreide geriatrische beoordeling",
        AC_CGA_ADMISSION_REVIEW:
          "Acute zorgen - Beoordeling van de uitgebreide geriatrische beoordeling",
      },
    },
    katz: {
      TITLE: "Katz-schaal",
      CANCEL: "Annuleren",
      NEXT: "Volgende",
      PREV: "Vorige",
      FINISH: "Beoordeling afwerken",
      PRINT: "Afdrukken",
      FORM: "Formulier",
      RESULTS: "Resultaten",
      SCORE_KATZ: "Katz-score",
      CONCLUSION: "Conclusie",
      questions: {
        wash_yourself: {
          question: "Zich wassen",
          answers: {
            "0": "kan zich volledig wassen zonder enige hulp",
            "1": "heeft gedeeltelijke hulp nodig om zich te wassen boven of onder de gordel",
            "2": "heeft gedeeltelijke hulp nodig om zich zowel boven als onder de gordel te wassen",
            "3": "moet volledig worden geholpen om zich zowel boven als onder de gordel te wassen",
          },
        },
        get_dressed: {
          question: "Zich aankleden",
          answers: {
            "0": "kan zich geheel zonder hulp aan- en uitkleden",
            "1": "heeft gedeeltelijke hulp nodig bij het aankleden boven of onder de gordel (exclusief veters)",
            "2": "heeft gedeeltelijke hulp nodig bij het aankleden zowel boven als onder de gordel ",
            "3": "moet volledig worden geholpen om zich zowel boven als onder de gordel aan te kleden",
          },
        },
        transfer_relocation: {
          question: "Transfer en verplaatsing",
          answers: {
            "0": "is autonoom voor de transfer en beweegt volledig zelfstandig, zonder mechanische hulp en/of hulp van derden",
            "1": "is autonoom voor de transfer en zijn verplaatsingen met behulp van een mechanische hulpmiddel (kruk(ken), rolstoel, ...)",
            "2": "heeft absoluut hulp van derden nodig voor minstens één van de transfers en/of verplaatsingen",
            "3": "is bedlegerig of in een rolstoel en is volledig afhankelijk van anderen om zich te verplaatsen",
          },
        },
        go_toilet: {
          question: "Naar het toilet gaan",
          answers: {
            "0": "kan allen naar het toilet gaan, zich aankleden of zich schoonmaken",
            "1": "heeft hulp nodig voor één van de drie handelingen: zich verplaatsen, aankleden of schoonmaken",
            "2": "heeft hulp nodig voor twee van de drie handelingen: zich verplaatsen, aankleden of schoonmaken",
            "3": "heeft hulp nodig voor de drie handelingen: zich verplaatsen, aankleden en schoonmaken",
          },
        },
        continence: {
          question: "Continentie",
          answers: {
            "0": "is continent voor urine en ontlasting",
            "1": "is onbedoeld incontinent voor urine of ontlasting (inbegrepen blaaskatheter of kunstmatige anus)",
            "2": "is incontinent voor urine (inclsuief plasoefeningen) of ontlasting",
            "3": "is incontinent voor urine en ontlasting",
          },
        },
        incontinence: {
          question:
            "Gecombineerde incontinentie: een combinatie van nachtelijke urine-incontinentie occasionele urine-incontentie gedurende de dag werd vastgesteld bij de begunstigde",
          answers: {
            "0": "ja",
            "1": "nee",
          },
        },
        eat: {
          question: "Eten",
          answers: {
            "0": "kan alleen eten en drinken",
            "1": "heeft voorafgaande hulp nodig om te eten of te drinken",
            "2": "heeft gedeeltelijke hulp nodig tijdens eten of drinken",
            "3": "de patiënt is volledig afhankelijk om te eten en te drinken",
          },
        },
        is_dementia: {
          question:
            "De patiënt werd gediagnostiqceerd als lijdend aan dementie na een gespecialiseerd diagnostisch onderzoek",
          answers: {
            "0": "ja",
            "1": "nee",
          },
        },
      },
      results: {
        forfait_c: "Forfait C",
        forfait_b: "Forfait B",
        forfait_a: "Forfait A",
        toilets0: "Toilet 0x/week",
        toilets2: "Toilet 2x/week",
        toilets7: "Toilet 7x/week",
        incontinence: {
          true: "Met gecombineerde incontinentie",
          false: "Zonder gecombineerde incontinentie",
        },
        is_dementia: {
          true: "Met dementie",
          false: "Zonder dementie",
        },
      },
      DOC_FOOTER: "Geeft kennis aan",
      PDF: {
        TITLE: "Katz-schaal thuis",
        SUB_TITLE:
          "Te versturen onder gesloten enveloppe naar de raadgevende arts Verpleging - beoordelingsschaal",
        SUB_TITLE_1:
          "- rechtvaardiging van het verzoek om forfaitaire honoraria per dag verzorging (1)",
        SUB_TITLE_2: "- met melding van de toiletzorgen (1)",
        TITLE_PATIENT_INFO: "Identificatiegegevens van de begunstigde",
        NAME: "Naam en voornaam",
        SSIN: "INSZ",
        DATE_OF_BIRTH: "Geboortedatum",
        ADDRESS: "Adres",
        INSURANCE: "MUTUALITEIT",
        ESTABLISHED_BY: "Opgesteld door",
        NIHII: "Riziv",
        THE: "Op",
        SCORE: "Score",
      },
    },
  },
  "soap-templating": {
    PATIENT_ORIENTATION: "Orientatie patiënt",
    TERMINATE: "Wachtrapport",
    SAVE: "Opslaan",
    NO_REFERRING_PHY:
      "De patiënt heeft geen behandelde arts gedefinieerd in zijn fiche.",
    RAPPORT: "Rapport",
    ERROR_TEMPLATING: "Fout bij genereren van sjabloon",
    SERVER_URL: "De url van de server antwoord niet",
    SERVER_ERROR: "Interne serverfout",
    PATIENT_ORIENTATION_OPTIONS: {
      HOME: "Terugkeer naar huis",
      HOSPITALIZATION: "Hospitalisatie",
      EMERGENCY: "Stuur naar spoedeisende hulp",
      DECEASED: "Patiënt overleden",
      REFERRING_PHYSICIAN: "Behandelende arts",
      CONTRACTOR: "Andere prestataris",
    },
    NO_REFERRING_PHY_NIHII:
      "De behandelende arts van de patiënt heeft geen riziv-nummer.",
  },
  "templating-view": {
    TERMINATE: "Afsluiten",
    TERMINATE_SEND: "Valideren en versturen via eHealth",
    TERMINATE_PRINT: "Afdrukken",
    SEND_TO_HEALTHBOX_PDF: "Versturen via eHealthBox (PDF)",
    SEND_TO_HEALTHBOX_H1: "Versturen via eHealthBox (H1)",
    SEND_TO_HEALTHBOX_MEDIDOC: "Versturen via eHealthBox (Medidoc)",
    VALIDATE: "Versturen",
  },
  "templating-tree-view": {
    child: {
      referring: {
        label: "Bestemmeling van de brief",
        child: {
          "lastName.label": "Naam",
          "firstName.label": "Voornaam",
          "adresse.label": "Adres",
          "houseNumber.label": "Huisnummer",
          "postboxNumber.label": "Bus",
          "town.label": "Stad",
          "townCode.label": "Postcode",
          "tel.label": "Telefoon",
          "gsm.label": "GSM",
          "mail.label": "e-mail",
        },
      },
      doctor: {
        label: "Gebruiker",
        child: {
          "lastName.label": "Naam",
          "firstName.label": "Voornaam",
          "adresse.label": "Adres",
          "houseNumber.label": "Huisnummer",
          "postboxNumber.label": "Bus",
          "town.label": "Stad",
          "townCode.label": "Postcode",
          "tel.label": "Telefoon",
          "gsm.label": "GSM",
          "mail.label": "e-mail",
          "inami.label": "Riziv",
          "bank.label": "Bank",
        },
      },
      patient: {
        label: "Patiënt",
        child: {
          "lastName.label": "Naam",
          "firstName.label": "Voornaam",
          "gender.label": "Geslacht",
          "dateOfBirth.label": "Geboortedatum",
          "dateOfDeath.label": "Overlijdensdatum",
          "placeOfBirth.label": "Plaats van gebooryte",
          "placeOfDeath.label": "Plaats van overlijden",
          "adresse.label": "Adres",
          "houseNumber.label": "Huisnummer",
          "town.label": "Stad",
          "townCode.label": "Postcode",
          "tel.label": "Telefoon",
          "gsm.label": "GSM",
          "mail.label": "e-mail",
          "ssin.label": "Rijksregisternummer",
          "mutualityNum.label": "Nummer mutualiteit",
          "titularCode1.label": "Titularisocode mutualiteit 1",
          "titularCode2.label": "Titularisocode mutualiteit 1",
          "titularyId.label": "Inschrijvingsnummer bij de mutualiteit",
        },
      },
      soaps: {
        label: "Contact",
        child: {
          "contactDate.label": "Contactdatum",
          "motifs.label": "Redenen",
          "subjectif.label": "Subjectief",
          "objectif.label": "Objectief",
          "diagnostic.label": "Diagnose",
          "prescriptions.label": "Actieplan (vrije tekst)",
          "note.label": "Nota's",
          "orientation.label": "Oriëntatie",
          itt: {
            label: "Ongeschiktheidsattest",
            child: {
              "dixit.label": "Dixit",
              "startDate.label": "Begindatum",
              "endDate.label": "Einddatum",
              "extension.label": "Verlenging",
              "resumption.label": "Hernemingsdatum",
              "reason.label": "Motief",
              "value.label": "Onbekwaamheid",
              "outing.label": "Uitgang",
            },
          },
          kine: {
            label: "Kinesitherapie",
            child: {
              "seanceNumber.label": "Aantal sessies",
              "numberByWeek.label": "Hoeveel keer per week",
              "diagnosis.label": "Diagnose",
              "note.label": "Aanvullende vragen",
            },
          },
          rx: {
            label: "Voorschrift",
            child: {
              "radiography.label": "Onderzoeken van RX",
              "clinicalInfo.label": "Pertinente klinische informatie over RX",
              "otherInfo.label": "Pertinente aanvullende informatie",
              "otherOtherInfo.label": "Aanvullende informatie",
              "previousExams.label": "Voorafgaand onderzoek",
              "diagnosis.label": "Diagnose van RX",
              "date.label": "Datum van RX",
            },
          },
          infi: {
            label: "Verpleegster",
            child: {
              "seanceNumber.label": "Aantal sessies",
              "frequency.label": "Frequentie",
              "treatment.label": "Soort verzorging",
            },
          },
          medication: {
            label: "Medicatie",
            child: {
              "treatment.label": "Voorgeschreven geneesmiddelen",
            },
          },
          biometrie: {
            label: "Biometrie",
            child: {
              "weight.label": "Gewicht",
              "height.label": "Grootte",
              "sbp.label": "Systole",
              "dbp.label": "Diastole",
              "heartrate.label": "Pols",
              "bmi.label": "BMI",
              "hipcircumference.label": "Buikomtrek",
              "headcircumference.label": "Schedelomtrek",
              "glycemia.label": "Bloedsuikerspiegel",
              "temperature.label": "Temperatuur",
              "saturationO2.label": "Zuurstofverzadiging",
              "inr.label": "INR",
            },
          },
        },
      },
      summary: {
        label: "Medische gegevens",
        child: {
          "problems.label": "Problemen",
          "administrativeNote.label": "Administratieve opmerking",
          "medicalBackground.label": "Medische antecedenten",
          "surgicalHistory.label": "Chirurgische antecedenten",
          "gynecologicalHistory.label": "Gynaeco-verloskundige antecedenten",
          "familyHistory.label": "Familiale antecedenten",
          "socialRiskFactors.label": "Factoren van sociaal risico",
          "medicalRiskfactor.label": "Factor van medisch risico",
          "allergy.label": "Allergie",
          "intolerances.label": "Intoleranties",
          "vaccines.label": "Vacinaties",
          "preventions.label": "Preventies",
          "chronicTreatment.label": "Chronische behandeling",
          "gs.label": "Bloedgroep",
          "rh.label": "Resusfactor",
          "sgs.label": "Onder bloedgroep",
          "antec.label": "Medische opmerking: antecedenten",
        },
      },
      info: {
        label: "Informatie",
        child: {
          "date.label": "Datum",
          "hours.label": "Uur",
        },
      },
    },
    menu: {
      ADD: "Toevoegen",
    },
  },
  "rlm-templating-tree-view": {
    child: {
      administrative: {
        label: "Administratieve gegevens",
        child: {
          "patientManager.label": "Beheer van het zorgtraject",
          "rlmsecretary.label": "Secretaris Lokale Zorgraad",
          "activecarepath.label": "Actief zorgtraject",
          "diabetesconvention.label": "Diabetesconventie",
          "typeofpathology.label": "Type pathologie",
          "startdate.label": "Begindatum",
          "enddate.label": "Einddatum",
          "endreason.label": "Reden voor beëindiging",
          "patientsigndate.label": "Handtekening patiënt",
          "generalphysiciansigndate.label": "Handtekening huisarts",
          "specialistphysiciansigndate.label": "Handtekening specialist",
          "mutualitysigndate.label": "Handtekening ziekenfonds",
          "note.label": "Administratieve opmerking",
        },
      },
      healthcareparties: {
        label: "Belanghebbenden",
        child: {
          "healthcareparties.label": "Belanghebbenden",
        },
      },
      treatments: {
        label: "Behandelingen",
        child: {
          "inclusion.label": "Bij opname",
          "current.label": "Momenteel",
        },
      },
      objective: {
        label: "Doel",
        child: {
          "objective.label": "Doel",
        },
      },
      prescriptions: {
        label: "Voorschriften",
        child: {
          "prescriptions.label": "Voorschriften",
        },
      },
    },
  },
  "patientMail-templating-tree-view": {
    child: {
      patient: {
        label: "Patiënt",
        child: {
          lastName: {
            label: "Familienaam",
          },
          firstName: {
            label: "Voornaam",
          },
          adresse: {
            label: "Adres",
          },
          houseNumber: {
            label: "Huisnummer",
          },
          town: {
            label: "Stad",
          },
          townCode: {
            label: "Postnummer",
          },
          age: {
            label: "Leeftijd",
          },
        },
      },
      doctor: {
        label: "Dokter",
        child: {
          lastName: {
            label: "Familienaam",
          },
          firstName: {
            label: "Voornaam",
          },
          adresse: {
            label: "Adres",
          },
          houseNumber: {
            label: "Huisnummer",
          },
          town: {
            label: "Stad",
          },
          townCode: {
            label: "Postnummer",
          },
          tel: {
            label: "Telefoonnummer",
          },
          gsm: {
            label: "Mobiel telefoonnummer",
          },
          mail: {
            label: "E-mailadres",
          },
          inami: {
            label: "Riziv",
          },
          bank: {
            label: "Rekeningnummer",
          },
        },
      },
      info: {
        label: "Info",
        child: {
          date: {
            label: "Datum",
          },
          hours: {
            label: "Uren",
          },
        },
      },
    },
  },
  "hcp-signature": {
    DOCTOR: "Dokter",
    DOCTOR_ABBR: "Dr.",
    PHONE_ABBR: "Tel.",
    NIHII: "Riziv",
    SIGNATURE: "Handtekening",
    DATE: "Datum",
    CBE: "KVO-Nr",
  },
  "templating-generator": {
    CLOSE: "Sluiten",
    PREVIEW: "Preview",
    EXPORT: "Exporteren",
    SAVE: "Bewaren",
    UPDATE: "Wijzigen",
    BACK: "Terug",
    CANCEL: "Annuleren",
    EDIT: "Wijzigen",
    REMOVE: "Verwijderen",
    SHOW: "Bekijken",
    form: {
      TITLE: "Titel",
      GROUP: "Type",
    },
  },
  "templating-management": {
    FIND_BY_NAME: "Zoeken op naam",
    TITLE: "Documenttype",
    NAME: "Naam",
    USER: "Gebruiker",
    TYPE: "Type",
    GROUP: "Groep",
    ADD: "Documenttype toevoegen",
    IMPORT: "Documenttypes importeren",
    EXPORT_ALL: "Alle documenten uitvoeren",
    IMPORT_TITLE: "Nieuw documenttype importeren",
    actions: {
      ACTIONS: "Acties",
    },
    IMPORT_DOCUMENTS: {
      name: "Documentnaam",
      content: "Overzicht",
      import: "Geselecteerde documenten importeren",
      import_from_file: "Documenten importeren vanuit een map",
    },
  },
  "efac-management": {
    EFAC_TO_BE_SENT: "Te versturen facturen",
    EFAC_TO_BE_CORRECTED: "Te verbeteren facturen",
    EFAC_SENT: "Batch facturen",
    SELECT_ALL: "Alles selecteren",
    UNSELECT_ALL: "Alles deselecteren",
    EFAC_LIST: "Lijst facturen eFact",
    ALERT_INFO_SEND_ASSISSTANT:
      "Om de attesten van uw assistenten te laten uitbetalen op hun gedeelde bankrekening,",
    ALERT_INFO_SEND_ASSISSTANT_FILTER:
      'Gelieve de bankgegevens van de assistenten weer te geven in het venster "Gegevens van de gebruiker". Klik daarna op de filter',
    ALERT_INFO_SEND_ASSISSTANT_PROCESS:
      'van de kolom "Assistent", filter de assistenten en klik tenslotte op de knop "Versturen naar eFact"',
    REFRESH: "Actualiseren",
    BANK_INFO_MANDATORY: "Dit is nodig om batches eFActs te versturen",
    SEND_TO_EFACT: "Versturen naar eFact",
    CORRECT_EFACT: "Facturen verbeteren",
    MAINTENANCE: "eFact voert onderhoud uit",
    INVOICE_DATE: "Factuurdatum",
    INVOICE_NUMBER: "Factuurnummer",
    VIEW_HISTORY: "Geschiedenis eFact weergeven",
    PATIENT: "Patiënt",
    MUTUALITY: "Ziekenfonds",
    ACCEPTED_AMOUNT: "Bedrag aanvaard",
    PAID_AMOUNT: "Bedrag betaald",
    AMOUNT: "Bedrag",
    TO_BE_SENT: "Te versturen",
    TO_BE_CORRECTED: "Te verbeteren",
    TREATED: "Behandeld",
    ARCHIVED: "Gearchiveerd",
    ARCHIVED_CODE: "Gearchiveerd",
    PENDING: "In behandeling",
    STATUS: "Statuut",
    ACCEPTED: "Aanvaard",
    CANCELED: "Geweigerd",
    LOST: "Verloren",
    DATE: "Datum",
    CODE: "Code",
    EDIT: "Wijzigen",
    ERRORS: "Fouten",
    IO_FEDERATION_CODE: "Nummer ziekenfonds",
    ASSISTANTS_BANK_ACCOUNT: "(rekeningnummer van de assistenten)",
    PMG_BANK_ACCOUNT: "(rekeningnummer van de wachtdienst)",
    MY_BANK_ACCOUNT: "(mijn rekeningnummer)",
    EXTERNAL_REF: "Verzendnummer",
    INVOICE_MONTH: "Factuurmaand",
    NUMERICAL_REF: "Verzendreferentie",
    EDIT_INVOICING: "Factuur wijzigen",
    VIEW_INVOICING: "Factuur bekijken",
    MY_EFACTS: "Mijn eFacts",
    REJECTED_PRESTATIONS: "Verworpen prestaties",
    MISSING_PATIENT: "Onmogelijk om de patiënt in te laden",
    ASSISTANT: "Assistent",
    "ASSISTANT-TITLE": "Factuur opgesteld door een van uw assistenten",
    CANNOT_LOAD_EFACT_HCP:
      "Onmogelijk om de facturen die gelinkt zijn aan uw interveniënt te laden",
    "efac-sent": {
      TITLE: "Lijst verzendingen eFact",
      REFRESH: "Actualiseren",
      FETCH_MESSAGES: "Mijn berichten recupereren",
      FILTER: "Filteren",
      OA: "Ziekenfonds",
      CONTRACTOR: "Dienstverlener",
      REFERENCE: "Referentie",
      SENDER_NUMBER: "Verzendnummer",
      INVOICE_MONTH: "Factuurmaand",
      SENT: "Verzenddatum",
      TOTAL_AMOUNT: "Bedrag",
      STATUS: "Statuut",
      ACCEPTED_AMOUNT: "Bedrag aanvaard",
      REJECTED_AMOUNT: "Bedrag geweigerd",
      REASON_FOR_REJECTION: "Reden van verwerping",
      PAYMENT_REF: "Betalingsreferentie",
      PAYED: "Betaald",
      IMPOSSIBLE_TO_RELOAD_BATCH:
        "Onmogelijk om de verstuurde facturen in te laden.",
      HELPDESK_INFO: "Helpdesk info",
      EFACT_MESSAGES_FETCHED_LESS_THAN_ONE_HOUR_AGO:
        "U hebt uw berichten al gerecupereerd. Gelieve opnieuw te proberen {{time}}",
      NO_MESSAGES_FOUND:
        "Geen enkel eFact-bericht gevonden, gelieve later opnieuw te proberen.",
    },
  },
  "efact-reset-confirm": {
    WARNING: "Opgepast!",
    WARNING_TEXT:
      "Een eFact-boodschap herstarten kan niet ongedaan gedaan worden, waardoor sommige zendingen misschien verschillende keren worden verstuurd.",
    RESET_MESSAGE: "Herstart de facturen voor verzending",
    CANCEL: "Annuleren",
    CONFIRM: "Bevestigen",
  },
  "helpdesk-info": {
    HCP_NAME: "Naam van de derde betaler",
    HCP_NIHII: "INAMI-nummer van de derde betaler",
    HCP_PHONE_NUMBER: "Telefoonnummer van de derde betaler",
    MONTH_AND_YEAR_INVOICING: "Maand en jaar van de facturatie",
    SENT_NUMBER: "Verzendnummer",
    SENT_REF: "Verzendreferentie",
    SENT_DATE: "Verzenddatum",
    LAST_RESPONSE_TYPE: "Type van het laatst ontvangen antwoord",
    LAST_RESPONSE_DATE: "Datum van het laatst ontvangen antwoord",
    TOTAL_INVOICE_AMOUNT: "Totaal gefactureerd bedrag",
    SOFTWARE_NAME: "Naam software",
    TECHNICAL_SHEETS: "Technische fiches",
    DOWNLOAD: "Downloaden",
    RESET_MESSAGE: "Herstart de facturen voor verzending",
    CHANGE_STATUS_TITLE:
      "Statusverandering afdwingen voor een verzending zonder antwoord",
    MANUAL_STATUS_CHANGE: "Manuele bediening",
    MANUAL_STATUS_CHANGE_OPERATOR: "Operator heeft de actie uitgevoerd",
    MANUAL_STATUS_CHANGE_DATE: "Datum van manuele uitvoering",
    MANUAL_STATUS_CHANGE_BUTTON: "De status manueel aanpassen",
  },
  "efact-change-status-modal": {
    NOT_OWNED_BY_USER:
      "De eFact werd opgesteld door {{firstName}} {{lastName}} en mag niet door u gewijzigd worden.",
    WRONG_PASSWORD: "Verkeerd wachtwoord",
    MESSAGE_NOT_FOUND: "Onmogelijk om het overeenkomstige bericht te vinden.",
    NO_INVOICE_IN_MESSAGE: "De batch heeft geen overeenkomstige factuur",
    MISSING_INVOICES:
      "Onmogelijk om alle facturen te vinden die met de batch overeenkomen",
    UNABLE_TO_UPDATE_INVOICE: "Onmogelijk om de factuur te updaten",
    MANUAL_REJECT_INVOICE: "Manueel verwerpen van de factuur",
    UNKNOWN_STATUS_UPDATE:
      "De keuze die u maakt voor het veranderen van het statuut is onbekend.",
    SUCCESSFUL_BATCH_STATUS_UPDATE:
      "Het statuut van de batch werd met succes geüpdatet",
    OPERATOR_MODIFICATION_ONLY:
      "Deze actie mag alleen worden uitgevoerd door support Medispring als het antwoord van het ziekenfonds verloren gegaan is",
    OPERATOR_NAME: "Naam van de zorgverstrekker",
    OPERATOR_PASSWORD: "Wachtwoord",
    VALIDATE_PASSWORD_BUTTON: "Wachtwoord valideren",
    SELECT_STATUS_CHANGE_CHOICE: "Uit te voeren actie kiezen:",
    STATUS_CHANGE_CHOICE_FULL_SUCCESS: "Succesvol uitgevoerd",
    STATUS_CHANGE_CHOICE_FULL_REJECT: "Volledig verwerpen",
    ACKNOWLEDGE_EFACT_CHANGE_ACTION:
      "Ik besef dat ik de status ga veranderen en dat dit onomkeerbaar is.",
    FORCE_CORRECTION_OVERWRITE:
      "(Sterk afgeraden) NIEUWE, verbeterde facturen samenstellen als de batch al verworpen werd",
    VALIDATE_STATUS_CHANGE: "Verandering uitvoeren",
    INVOICE_ALREADY_CORRECTED_IDS: "IDs-facturen reeds verbeterd",
    INVOICE_CORRECTED_IDS: "IDs-facturen verbeterd",
    INVOICE_FAILED_UPDATE_IDS: "IDs-facturen mislukt (u moet de actie herhalen",
    SOME_INVOICES_FAILED_WARNING:
      "De verbetering van sommige facturen is mislukt",
  },
  "efac-settings": {
    PARAM_EFAC: "Parameters eFact",
    VALIDATE: "Valideren",
    NEXT_SENDING_NUMBERS: "Volgende verzendnummers eFact",
    ALERT_EDIT_NUMBERS:
      "Opgepast: de verzendnummers van eFact veranderen kan onverwachte gevolgen hebben. Verander ze niet als u andere medische software kunt gebruiken om de exact-facturen te versturen.",
    MUT_NAME: "Ziekenfonds",
    ERROR_CANT_FETCH:
      "Onmogelijk om de laatst door Medispring gebruikte waarde terug te vinden.",
    VALUE: "De waarde",
    ERROR_TOO_SMALL: "is te klein.",
    ERROR_CANT_UPDATE: "Update onmogelijk",
    UPDATE: "Updaten",
  },
  "efac-history": {
    TITLE: "Geschiedenis van de eFacts",
    DATE: "Datum",
    INVOICE_NUMBER: "Factuurnummer",
    STATUS: "Statuut",
    LOT_NUMBER: "Batchnummer van eFact",
    LOADING: "Bezig met downloaden",
  },
  "patient-chapteriv": {
    REFUND_REQUEST: "Aanvraag voor terugbetaling: ",
    COMPLETE: "Aanvraag volledig",
    SEND: "Versturen",
    OPENING_DATE: "Begindatum",
    CLOSING_DATE: "Einddatum",
    MEDICATIONS: "Medicijn(en)",
    PARAGRAPH: "Paragraaf",
    QUANTITY: "Hoeveelheid",
    EHEALTH_CONNECTION_KO:
      "Maak verbinding met eHealth oom de aanvragen van hoofdstuk IV te raadplegen en op te maken.",
    AGREEMENT_CONSULTATION_ERROR:
      "Onmogelijk om de aanvragen van hoofdstuk IV te raadplegen.",
    CURRENT_STATE: "Uw goedkeuringen",
    NO_ACCORD: "Geen goedkeuringen gevonden",
    IN_TREATMENT: "Wordt behandeld",
    ACCEPTED: "Aanvaard",
    REFUSED: "Geweigerd",
    CANCELED: "Geannuleerd",
    CONSULT: "Raadplegen",
    MODEL: "Model (Versie)",
    STATUS: "Statuut",
    TRANSACTION_DATE: "Transactiedatum",
    QUANTITIES: "Hoeveelheden",
    COVERTURE: "Dekking",
    DECISION: "Beslissing",
    REFERENCE: "Referentie",
    VERSES: "Verset",
    REFUSAL_JUSTIFICATION: "Verantwoording van weigering",
    APPENDICES: "Bijlage(n)",
    PARAGRAPH_NOT_EXIST:
      "Deze paragraaf bestaat niet meer in de huidige versie van de gegevensbank SAM.",
    VERSE_VERSESEQ: "Vers (verseSeq)",
    APPENCIDE_NEEDED_NEWREQUEST:
      "Een bijlage is nodig voor deze nieuwe aanvraag",
    APPENCIDE_NEEDED_PROLONGATION: "Een bijlage is nodig voor deze verlenging",
    APPENCIDE_NEEDED_VERSE: "Een bijlage is nodig voor dit vers",
    UNCONTINIOUS_ALERT:
      "Als u een latere datum kiest dan het einde van het lopende akkoord + 1 dag, zal de enige mogelijke verlenging beëindigd worden. Gelieve te bevestigen dat u deze soort verlenging wenst.",
    UNCONTINUOUS: "Stopzetten",
    CONTINUE: "Verder zetten",
    CANCEL: "Annuleren",
    ONE_APPENCIDE_NEEDED_PROLONGATION:
      "bijlage is nodig bij de aanvraag van deze verlenging",
    ONE_APPENCIDE_NEEDED_NEWREQUEST:
      "bijlage is nodig voor een nieuwe aanvraag",
    X_APPENCIDE_NEEDED_PROLONGATION:
      "bijlagen zijn nodig bij de aanvraag van deze verlenging",
    X_APPENCIDE_NEEDED_NEWREQUEST:
      "bijlagen zijn nodig voor een nieuwe aanvraag",
    NEWREQUEST: "Aanvraag",
    PROLONGATION: "Verlenging",
    ADD_APPENDICES: "Bijlage(n) toevoegen",
    CLOSURE: "Afsluiten",
    CANCELLATION: "Annulatie",
    SEND_NEWREQUEST: "Aanvraag versturen",
    SEND_CLOSURE: "Akkoord afsluiten",
    SEND_EXTENSION: "Akkoord verlengen",
    SEND_APPENCIDE: "Versturen",
    SEND_CANCEL: "Akkoord annuleren",
    UNEXPECTED_ERROR: "Er is een fout gebeurd",
    NO_TRANSACTION: "Er is een fout gebeurd: geen enkele transactie ontvangen.",
    SAVE_APPENDICES_ERROR:
      "Uw bijlagen konden niet bewaard worden. Ze werden correct verstuurd maar u kunt ze niet raadplegen in het lopende akkoord hieronder.",
    ERROR_GET_NOT_SELECTED: "Er werd geen enkel akkoord geselecteerd",
    ERROR_GET_P: "Onmogelijk om de paragrafen te recupereren.",
    ERROR_GET_V: "Onmogelijk om de verzen te recupereren.",
    APPENDICE_NOT_USEFUL:
      "Gelieve geen bijlagen te sturen als het niet nodig. U vertraagt hierdoor de aanvraag.",
    CLOSE_OK: "Akkoord met succes afgesloten",
    MAX_SIZE_REACHED:
      "De mappen die u geselecteerd hebt, zijn te groot. De mappen mogen maximum {{maxTotalFileSize}} KB bedragen of {{totalFileSize}} KB.",
    YOU_CAN_0: "U kunt: ",
    YOU_CAN_1:
      "- Verschillende onafhankelijke bijlagen sturen om de mappen een voor een door te sturen.",
    YOU_CAN_2: "- De grootte van uw mappen verkleinen.",
    MIME_TYPE_ERROR_0:
      "U hebt mappen geselecteerd met als formaat {{mimeTypeErrors}}.",
    MIME_TYPE_ERROR_1:
      "Wij kunnen niet garanderen dat deze mappen correct verstuurd worden.",
    MIME_TYPE_ERROR_2:
      "Wij raden u aan enkel mappen te versturen in deze formaten: pdf, image (.png, .jpg, …) en tekst (.txt).",
    SUPPLEMENTARY_INFO: "Extra informatie",
    VERSE_TYPE: "Soort vers",
    "request-type": {
      "0": "",
      P: "Verlenging",
      N: "Nieuwe aanvraag",
    },
    ERROR_1: "U hebt tegelijk nieuwe aanvraag en verlenging aangevinkt.",
    ERROR_2: "U hebt verlenging aangevinkt terwijl u een nieuwe aanvraag doet.",
    ERROR_3:
      "U hebt nieuwe aanvraag aangevinkt terwijl u een verlenging vraagt.",
    EXT_WARN_1: "De begindatum van de verlenging mag niet voorbij zijn.",
    EXT_WARN_2:
      "U kunt een akkoord alleen verlengen gedurende de laatste 3 maanden van het akkoord.",
    LAST_CONSULT: "Laatste verbinding op {{date}} - {{time}}",
  },
  "patient-erecipe": {
    E_RECIPE_ID: "Recip-e",
    ERR_GET_OPEN_PRESC:
      "U hebt de lijst met openstaande voorschriften op Recip-e nog niet ontvangen.",
    PRESC_NOT_FOUND: "Geen enkel elektronisch voorschrift gevonden",
    TREATMENTS: "Behandelingen",
    POSOLOGY: "Doseringsschema",
    START_DATE: "Af te leveren a-p-d",
    END_DATE: "Einde",
    PRESCRIBER: "Voorschrijver",
    HCP_NO_PHONE:
      "De interveniënt voorschrijver heeft geen telefoonnummer (mobiel of vast).",
    NOT_CONNECTED_CANCEL:
      "Maak verbinding met eHealth om uw voorschriften bij Recip-e te annuleren",
    CANCEL_OK: "Annulatie correct verstuurd naar Recip-e",
    ERROR_CANCEL:
      "Er is een fout gebeurd bij het annuleren van uw voorschrift. Is uw voorschrift misschien al geannuleerd?",
    ERROR_CANCEL_SAVE:
      "Er is een fout gebeurd bij het registreren van de annulatie",
    CANCEL_SUCCESS: "Annulatie verstuurd naar Recip-e",
    CANCEL_BTN: "Voorschrift Recip-e annuleren",
    ACTIVE_INGREDIENT: "Voorschrift actief",
    PRESCRIBED_DATE: "Voorgeschreven op",
    PRESCRIBE_NEW: "Opnieuw voorschrijven",
    NOT_SENT: "Voorschrift niet verstuurd naar Recip-e",
    NO_PHONE_WARNING:
      "Opgepast: het voorschrift zal niet elektronisch zijn! Om een ePrescription te versturen, bent u verplicht om een telefoonnummer op te geven in uw fiche. Verander uw fiche door op uw initialen te klikken links onderaan het scherm",
  },
  "patient-contact-document-selector": {
    APPENDICE_COMPUTER: "Mappen kiezen die op de computer staan",
    CHOOSE_DOCUMENT: "Mappen kiezen uit het dossier van de patiënt",
    CHOOSE_FILES: "Mappen kiezen ...",
    FROM_PATIENT_FOLDER: "Uit het dossier van de patiënt",
    FROM_COMPUTER: "Van uw computer",
    SELECTED_FILES: "Geselecteerde mappen",
    NO_SELECTED_FILES: "Geen mappen om weer te geven.",
    PLACEHOLDER_PLEASE_SELECT:
      "Gelieve links een of meerdere mappen te selecteren van uw computer of uit het dossier van de patiënt.",
    DELETE_SELECTED_FILES: "Verwijderen",
    DOCUMENT_SEQ: "Document seq",
    VERSE_SEQ: "Vers seq",
    FILE_TOO_BIG: "De map is te groot. De maximum grootte is 1000 KB.",
    ERROR_REPORT_GET_FILE: "Sommige documenten konden niet worden geopend.",
    APPENDICES_AVAILABLE_FOR_DL:
      "Voorbeeldbijlagen kunnen gedownload worden van de website van INAMI",
    ONCE_COMPLETED: "Vergeet de waarden niet te vermelden tijdens het invullen",
    AND: "en",
    OF_EACH_APPENDICE:
      "van elke toegevoegde bijlage of extra bijlage bij uw aanvraag.",
    DESCRIPTION: "Beschrijving",
    DOWNLOAD: "Downloaden",
    CHOOSE: "Kiezen",
  },
  "patient-medication-archive": {
    ALLERGY: "Allergie",
    INTOLERANCE: "Intolerantie",
    PATIENT_REQUEST: "Vraag van de patiënt",
    OTHER: "Andere",
    NOTE: "Nota",
    CANCEL: "Annuleren",
    ARCHIVE: "Archiveren",
  },
  "contactreport-subcontact": {
    PREVIEW_OF: "Overzicht van",
    PRINT_FULL_REPORT: "Volledig verslag afdrukken",
    CONSLUSION: "Besluit",
    ERROR_UPLOAD: "Het document kon niet naar de server worden verstuurd.",
    ERROR_SAVE_CONTACT: "Fout bij het opslaan van het verslag.",
    ERROR_GET: "Een document kon niet worden gedownload.",
    EXT_NOT_AUTHORIZED:
      "Bestanden {{ext}} zijn niet toegelaten. Toegelaten bestanden: {{autorized}}",
    FILE_TOO_BIG:
      "Het bestand {{filename}} is te groot.De maximum grootte is {{size}}",
    ERR_FILE_READ: "De map kan niet gelezen worden",
    FIND_PROCEDURE: "Zoek een procedure",
    ATTACHMENT: "Bijlage toegevoegd",
    PROCEDURE: "Procedure",
    SPECIALITY: "Specialiteit",
    CHOOSE: "Kiezen",
    PRINT: "Afdrukken",
    DOWNLOAD: "Downloaden",
    DELETE: "Verwijderen",
    TEXT: "Tekst",
    LINKS: "Links",
    LINK: "Link",
    "new-message": {
      TITLE: "Verslag versturen via eHealthBox",
    },
    REPORT_S: "Verslag ",
    DE_: "van ",
    DU_: "van ",
    APPENDICES: "Bijlagen",
    APPENDICE: "Bijlage",
    DOCS_AS_APPENDICE: " documenten in bijlage",
    DOC_AS_APPENDICE: " document in bijlage",
    SAVE_REPORT: "Registreren",
    REPORT_UPDATED_SUCCESS: "Verslag bijgewerkt.",
    OPEN_PATIENT: "Dossier openen",
  },
  "text-templating": {
    enums: {
      template_group_type: {
        SOAPS: "soaps",
      },
    },
  },
  "messages-box-manager": {
    NO_ACCESS_TO_ALL_MAIL_FEATURES_IF_NOT_LOGGED_TO_EHEALTH:
      "U hebt geen toegang tot alle functies van de mailmodule als u niet verbonden bent met eHealth.",
  },
  "messages-box-controls": {
    MANUAL_IMPORT: "Manueel importeren",
    AUTO_IMPORT: "Automatisch importeren",
    UPDATE: "Updaten",
    MARK_READ: "Als gelezen markeren",
    MARK_UNREAD: "Als ongelezen markeren",
    CONFIRM_DIALOG: {
      TITLE: "Bevestiging van de verwijdering",
      MESSAGE: "Wilt u deze boodschap(pen) verwijderen?",
      YES: "Ja",
      NO: "Neen",
    },
    DELETE: "Verwijderen",
    CANNOT_DELETE_MESSAGE: "Kan bericht niet verwijderen",
    SEARCH: "Zoeken",
  },
  "messages-box-filter-selector": {
    UNTREATED: "Onbehandelde boodschappen",
    TREATED: "Behandelde boodschappen",
    SELECT_ALL: "Alles selecteren",
    STATUS: "Statuut",
    RECIPIENT: "Bestemmeling",
    OTHER: "Andere",
  },
  "messages-box-selector": {
    TITLE: "Mailbox eHealth",
    NEW_MESSAGE: "Nieuw bericht",
    INBOX: "Inbox",
    INBOX_ALL: "Alle",
    INBOX_USER_ACTION: "Onbehandeld",
    OUTBOX: "Verzonden berichten",
    ARCHIVE: "Prullenmand",
    MANUAL_IMPORT: "Manuele import uit een map",
  },
  "message-messages-box": {
    NEW_MESSAGE: "Nieuw bericht",
    SEARCH_INPUT:
      "Zoeken: naam patiënt, geboortedatum, adres, afzender, specialiteit, procedure",
    CAPACITY: "Gebruikte capaciteit",
    MESSAGE_QUEUE: "Berichten in de wachtlijst: ",
    FULL_BOX_ERROR:
      "Uw inbox is vol, wis berichten om nieuwe berichten te ontvangen",
    FULL_BOX_WARNING:
      "Uw inbox is bijna vol, wis berichten om te vermijden dat uw inbox vol raakt",
    EMPTY_TRASH: "Prullenmand ledigen",
    ERROR_DELETE_CHUNK:
      "Er heeft zich een fout voorgedaan bij het verwijderen van uw berichten",
    N_DELETED_MESSAGE: "{{n}} berichten werden verwijderd",
    TEXT_ACTIVATE_HEALTHBOX: "Automatisch ophalen van healthbox-berichten",
  },
  "editor-message-messages-box": {
    report: "Verslag",
    label: {
      TO: "Naar",
      TO_PLACEHOLDER: "Zoeken in intern adresboek (betrokkenen)",
      SUBJECT: "Onderwerp",
      SET_AS_IMPORTANT: "Markeren als belangrijk",
      ENCRYPTED: "Versleuteld",
      METADATA: "Metadata",
      ADD_METADATA: "Een set metadata toevoegen",
      CUSTOM_METADATA: "Gepersonaliseerde metadata toevoegen",
      ADD_RECIPIENT: "Bestemmeling toevoegen",
    },
    confirmationType: {
      SEND: "Verzenden",
      RECEIVE: "Ontvangst",
      READ: "Lezen",
    },
    button: {
      SEND: "Verzenden",
    },
    NO_HCP: "Er werd geen HCP-correspondent gevonden voor NIHII.",
  },
  "add-recipient": {
    TITLE: "Bestemmeling toevoegen op basis van een nummer en een kwaliteit",
    IDENTIFIER: "Nummer",
    QUALITY: "Kwaliteit",
    CANCEL: "Annuleren",
    OK: "Toevoegen",
  },
  "message-list": {
    NO_DATE: "Geen datum",
    MESSAGE_WARNING: "Deze boodschap vergt uw aandacht",
    MESSAGE_ERROR: "Fout bij de automatische behandeling",
    MESSAGE_SUCCESS: "Automatische behandeling met succes uitgevoerd",
    FROM_DISK: "Geïmporteerd van de schijf",
    HAS_ANNEX: "Bevat een of meerdere bijlagen",
    MORE_THAN_ONE_PATIENT: "Meerdere patiënten",
    NO_PATIENT: "Geen enkele patiënt",
    SOURCE_UNKNOWN: "Onbekende afzender",
    RECIPIENT_UNKNOWN: "Onbekende bestemmeling",
    IMPORTANT: "belangrijk",
    UNKNWON: "Onbekend",
    status: {
      SENT: "Verzonden",
      RECEIVED: "Ontvangen",
      READ: "Gelezen",
    },
  },
  "patient-medical-note": {
    TITLE: "Medische nota",
    tab: {
      WILL: "Wilsbeschikking",
      BLOOD_TYPE: "Bloedgroep",
      INACTIVE: "Antecedenten",
    },
    SAVE: "Registreren",
    CANCEL: "Annuleren",
    CLOSE: "Sluiten",
    EDIT: "Wilsbeschikking van de patiënt wijzigen",
    LOADING: "De contacten worden geladen. Even geduld aub",
  },
  "patient-administrative-note": {
    TITLE: "Administratieve nota",
    OPEN_PATIENT_NOTE: "Weergeven bij de opening van het dossier",
    PLACEHOLDER: "Een nota toevoegen",
    SAVE: "Registreren",
    CANCEL: "Annuleren",
    NOTE_SAVED: "Administratieve nota geregistreerd",
    NOTE_SAVE_ERROR: "De administratieve nota kon niet geregistreerd worden",
  },
  "patient-biometry-note": {
    TITLE: "Nota biometrie",
  },
  "patient-will": {
    DATE: "Datum",
    NOTE: "Nota",
    EMPTY: "Voor deze patiënt werd nog geen wens geregistreerd",
    HISTORY: "De geschiedenis van deze wens weergeven",
    NO_HISTORY: "Geen geschiedenis om weer te geven",
    LINK_ORGADON: "Link naar Orgadon",
    bloodtransfusion: {
      LABEL: "Bloedtransfusie",
      AUTHORIZE: "Aanvaardt transfusie",
      REFUSE: "Aanvaardt geen transfusie",
      UNDEFINED: "?",
    },
    intubation: {
      LABEL: "Intubatie",
      AUTHORIZE: "Aanvaardt intubatie",
      REFUSE: "Aanvaardt geen intubatie",
      UNDEFINED: "?",
    },
    reanimation: {
      LABEL: "Reanimatie",
      AUTHORIZE: "Aanvaardt reanimatie",
      REFUSE: "Aanvaardt geen reanimatie",
      UNDEFINED: "?",
    },
    organdonation: {
      LABEL: "Orgaandonatie",
      AUTHORIZE: "Aanvaardt orgaandonatie",
      REFUSE: "Aanvaardt geen orgaandonatie",
      UNDEFINED: "?",
    },
    vaccination: {
      LABEL: "Vaccinatie",
      AUTHORIZE: "Aanvaardt vaccinatie",
      REFUSE: "Aanvaardt geen vaccinatie",
      UNDEFINED: "?",
    },
    euthanasia: {
      LABEL: "Euthanasie",
      AUTHORIZE: "Heeft een aanvraag tot euthanasie gedaan",
      REFUSE: "Aanvaardt geen euthanasie",
      UNDEFINED: "?",
    },
    datareuseforclinicalresearch: {
      LABEL: "Klinische onderzoeken",
      AUTHORIZE:
        "Aanvaardt het gebruik van gegevens voor klinische onderzoeken",
      REFUSE: "Aanvaardt niet",
      UNDEFINED: "?",
    },
    datareuseforclinicaltrials: {
      LABEL: "Gebruik van gegevens voor klinische proeven",
      AUTHORIZE: "Aanvaardt het gebruik van gegevens voor klinische proeven",
      REFUSE: "Aanvaardt niet",
      UNDEFINED: "?",
    },
    clinicaltrialparticipation: {
      LABEL: "Deelname aan klinische proeven",
      AUTHORIZE: "Aanvaardt de deelname aan klinische proeven",
      REFUSE: "Aanvaardt niet",
      UNDEFINED: "?",
    },
    hospitalisation: {
      LABEL: "Hospitalisatie",
    },
    resuscitation: {
      LABEL: "Reanimatie",
    },
    "MS-FIXME-note": {
      LABEL: "Nota",
    },
  },
  "patient-inactive": {
    CONTENT: "Antecedenten",
  },
  "patient-blood-type": {
    BLOOD_TYPE: "Bloedgroep",
    SUB_BLOOD_TYPE: "Subbloedgroep",
    HISTORY: "Historiek van de bloedgroep",
    NO_HISTORY: "Geen historiek om weer te geven",
  },
  "patients-state": {
    FETCH_FAILED: "De ontvangst van de patiënt {{id}} is mislukt.",
  },
  "patient-journal-service": {
    CONTACT_SUBMISSION_FAILED: "Contact leggen is mislukt",
    ATTEMPTED_ICURE_TOPAZ_CONTACT_SUBMISSION:
      "Gegevens aangemaakt met iCure of Topaz kunnen op dit moment niet worden gewijzigd door Medispring.",
  },
  "unsupported-services": {
    SERVICE: {
      LABEL: "Etiket",
      VALUE: "Waarde",
    },
    UNSUPPORTED_SERVICES: "Extra diensten",
    BINARY_DATA: "Binaire gegevens",
    COMPOUND_VALUE: "Samengestelde waarde",
  },
  "file-viewer": {
    DISPLAY_IMPOSSIBLE: "Dit soort map kan niet worden weergegeven",
    DOWNLOAD: "Bijlage downloaden",
  },
  "message-display": {
    DELETED_LAB: "Dit labo is gewist",
    DELETED_REPPORT: "Dit verslag is gewist",
    SEARCH_PATIENT: "Patiënt zoeken",
    EDIT_FROMHCP: "Afzender van het bericht aanpassen",
    EDIT_TOHCP: "Bestemmeling van het bericht aanpassen",
    RETRY_PROCESSING: "Opnieuw proberen",
    TRY_PROCESSING: "Boodschap behandelen",
    WAITING_MESSAGE:
      "Deze boodschap werd nog niet behandeld. Er kan informatie ontbreken.",
    PROCESSING_FAILED: "De boodschap kon niet automatisch behandeld worden.",
    PREVIEW_NOT_AVAILABLE: "Overzicht niet beschikbaar.",
    CANNOT_HANDLE_FILE: "De map is niet leesbaar.",
    CANNOT_UPDATE_MESSAGE: "Update van de boodschap mislukt.",
    CANNOT_DELETE_MESSAGE: "Kan bericht niet verwijderen",
    CANNOT_UPDATE_INFO: "Update van de info mislukt.",
    HCP_EDITION_MODAL_TITLE_SENDER:
      "Aanpassing van de afzender van het bericht",
    HCP_EDITION_MODAL_TITLE_RECEIVER:
      "Aanpassing van de bestemmeling van het bericht",
    AUTHOR_UNKNOWN: "Onbekende afzender",
    RECIPIENT_UNKNOWN: "Onbekende bestemmeling",
    FROM_PREFIX: "Naar",
    SENDER_PREFIX: "Van",
    ERROR_RECIPIENT: "Het bepalen van de bestemmeling is mislukt.",
    SUBJECT_PREFIX: "Onderwerp: ",
    NO_PREVIEW_MESSAGE: "Er zijn geen boodschappen om weer te geven.",
    SELECT_MESSAGE: "Gelieve een boodschap te selecteren.",
    PREVIEW: "Preview van bijlagen",
    CUSTOM_METADATA: "Aangepaste metadata",
    data: {
      REPLY: "Beantwoorden",
      REPLY_ALL: "Allen beantwoorden",
      TRANSFER: "Doorsturen",
      ARCHIVE: "Archiveren",
      DELETE: "Wissen (definitief)",
      MARK_READ: "Markeren als gelezen",
      MARK_UNREAD: "Markeren als ongelezen",
      MOVE_TO_TRASH: "Prullenmand",
    },
    annex: {
      DOWNLOAD: "Downloaden",
      PATIENT_LINK: "Linken aan een patiënt",
      AUTHOR_LINK: "Linken aan een auteur",
    },
    dialog: {
      reply: "Antwoord aan de afzender van het bericht",
      replyAll: "Alle ontvangers van het bericht beantwoorden",
      forward: "Stuur het bericht door",
    },
  },
  "message-display-body": {
    UNSUPPORTED_BODY_TYPE:
      "Formaat van de boodschap wordt niet ondersteund: {{type}}",
    DOWNLOAD: "Bericht downloaden",
  },
  "import-result-summary": {
    AUTHOR_UNKNOWN: "Onbekende bron",
  },
  "contact-task-form": {
    DESCRIPTION: "Beschrijving",
    DEADLINE: "Vertraging",
    REQUESTER: "Aanvraag door ...",
    OWNER: "Uit te voeren door ...",
    SEARCH_OWNER: "Een gebruiker zoeken",
    CREATE_TASK: "Een taak toevoegen",
    REMOVE_TASK: "Een taak verwijderen",
    VALUE: "Waarde",
    TYPE: "Type",
    PROCEDURE: "Procedure",
  },
  "health-one-import": {
    success: {
      DOCUMENT_SUCCESSFULLY_IMPORTED:
        "Het document werd met succes geïmporteerd.",
      X_MESSAGE_PROCESSED: "{{value}} behandeld bericht.",
      X_MESSAGES_PROCESSED: "{{value}} behandelde berichten.",
    },
    error: {
      ERROR_MORE_THAN_ONE_DOCUMENT_IN_MESSGAE:
        "Het bericht bevat meerdere documenten.",
      ERROR_NO_DOCUMENT_IN_MESSGAE: "Dit bericht bevat geen document.",
      ERROR_DOC_HAS_NO_TYPE: "Onmogelijk om het soort document te bepalen.",
      ERROR_DOCUMENT_CANT_BE_LINKED_TO_PATIENT:
        "Het document kan niet gelinkt worden aan een patiënt.",
      ERROR_WHILE_ADDING_CONTACT_TO_PATIENT:
        "Fout bij het toevoegen van het contact aan patiënt ({{lastName}} {{firstName}}{{dateOfBirth}}).",
      ERROR_GET_HCP: "Fout bij het recupereren van de interveniënt.",
      PATIENT_SSIN_AMBIGUOUS:
        "Meerdere patiënten zijn verbonden met dit INSZ-nummer ({{ssin}}).",
      PATIENT_NAME_BIRTH_DATE_NOT_FOUND:
        "Geen enkele patiënt voor deze naam en geboortedatum ({{lastName}} {{firstName}} {{dateOfBirth}}).",
      PATIENT_NAME_BIRTH_DATE_AMBIGUOUS:
        "Meerdere patiënten gevonden voor deze naam en geboortedatum ({{lastName}} {{firstName}} {{dateOfBirth}}).",
      PATIENT_INFORMATION_MISSING:
        "Geen informatie over de patiënt in het document.",
      CONTACT_CREATION_FAILED:
        "Fout bij het aanmaken van het contact({{lastName}} {{firstName}}{{dateOfBirth}}).",
      PARSING_FAILED:
        "Fout bij de interpretatie van het document({{lastName}} {{firstName}}{{dateOfBirth}}).",
      NO_AUTHOR:
        "Het bericht bevat geen auteur. Het laboratorium/de confrater had u deze informatie moeten doorgeven. U kunt ook manueel de auteur van deze informatie kiezen.",
      UNKNOWN: "Onbekende fout.",
    },
  },
  "pricare-document-import": {
    MEDIDOC_FROM_PRICARE_WITHOUT_CONTENT: "Medidoc van Pricare zonder inhoud",
    UNKNOWN_FILE_NAME_IN_PRICARE: "Bestandsnaam onbekend in Pricare",
  },
  "patient-tasks": {
    TITLE: "Taken",
    PLACEHOLDER_NO_TASK: "Er zijn geen taken om weer te geven.",
    CATEGORY: {
      CURRENT: "Taken worden uitgevoerd",
      LATE: "Voorbije taken",
      ENDED: "Historiek van de taken",
    },
    EDIT: "Taak wijzigen",
    TYPES: {
      DIAGNOSTIC_ACT: "Diagnose",
      THERAPEUTIC_ACT: "Therapie",
      VACCINATIONS: "Vaccinatie",
      ADMINISTRATIVE_TASK: "Medisch-administratief",
      PREVENTION: "Preventie",
      IMAGERY: "Aanvraag beeldvorming",
      LAB_ANALYSIS: "Aanvraag labo",
      OPINION_REQUESTS: "Aanvraag mening",
      EMPTY: "Zonder type",
      ALL: "Alle types",
    },
    TABS: {
      OPENED: "Openstaande taken",
      CLOSED: "Historiek van de taken",
    },
    "export-columns": {
      DATE: "Date",
      DESCRIPTION: "De beschrijving",
      DEADLINE: "Uiterste datum",
      E_HEALTH: "eHealth",
      TYPE: "Type",
      STATUS: "Statuut",
      REQUESTER: "Aanvrager",
    },
  },
  "patient-free-task-dialog": {
    TITLE: "Taak",
  },
  "patient-selection": {
    TITLE: "Selectie van een patiënt",
    INFO_FROM_H1:
      "Informatie gevonden in de map: {{lastName}} {{firstName}} {{dateOfBirth}}",
    CREATE_NEW_PATIENT: "Nieuwe patiënt aanmaken",
    LAST_NAME: "Familienaam",
    FIRST_NAME: "Voornaam",
    DATE_OF_BIRTH: "Geboortedatum",
    NO_PATIENT: "Geen patiënt",
    NEXT: "Volgende",
    PATIENT_LINK: "Linken met de patiënt",
    CREATE_FROM_FILE_INFO: "Aanmaken vertrekkend van de informatie in de map",
  },
  "hcparty-selection-dialog": {
    TITLE: "Selectie van een provider",
    FIND_PLACEHOLDER: "Zoeken op naam",
    CREATE_NEW: "Nieuwe betrokkene",
    HCP: "Betrokkene",
    ACCEPT: "Selecteren",
  },
  "ms-mailing": {
    NO_ACCESS_TO_FEATURE_FOR_GUARDPOST:
      "U hebt geen toegang tot deze functie in de modus Wachtdienst.",
    NO_LOG_EHEALTH: "U bent niet verbonden met de diensten van eHealth.",
  },
  "messages-box-service": {
    NO_LOG_EHEALTH: "U bent niet verbonden met de diensten van eHealth.",
    SEND_SUCCESS: "Het bericht werd verstuurd",
    SEND_FAILED: "Het versturen van het bericht is mislukt",
  },
  "date-period-selector": {
    FROM: "Van",
    TO: "Tot",
    SELECT: "Selecteren",
    PREVIOUS: "Vorige",
    NEXT: "Volgende",
  },
  "task-update-popup": {
    TITLE: "Update van de taak: {{label}}",
    NOTE: "Nota",
    COMPLETION_DATE: "Datum",
    BY_HCP: "Door",
    RENEWAL_DATE: "Vernieuwing",
    STATUS: {
      COMPLETED: "Uitgevoerd",
      COMPLETED_RENEW: "Uitgevoerd en te vernieuwen",
      CANCELLED: "Niet uitgevoerd",
      REFUSED_BY_PATIENT: "Geweigerd door de patiënt",
    },
    UPDATE_FAILED: "Update van de taak is mislukt.",
    CANCEL: "Annuleren",
    OK: "Valideren",
  },
  "ms-html-dialog": {
    SAVE_AS_PDF: "Downloaden als PDF",
  },
  "ms-eh-dmg": {
    GET_PATIENT_LIST: "Patiëntenlijst raadplegen",
    GET_DMG_MESSAGES: "Mijn berichten recupereren",
    REQUEST_DMG_LIST: "Mijn patiëntenlijst opvragen",
    REQUEST_DMG_LIST_DATE: "Datum waarop de lijst aangevraagd werd",
    CONSULT_DMG_MESSAGES: "Mijn berichten weergeven",
    CLEAR_DMG_MESSAGES: "De berichten verwijderen",
    CONFIRM: "Bevestigen",
    REQUESTS_LIST: "Aanvraaglijst",
    PATIENTS_LIST: "Patiëntenlijst",
    DATE: "Datum",
    REQUEST_DATE: "Datum van aanvraag",
  },
  "eh-e-dmg": {
    PATIENT_LIST: "Mijn patiëntenlijst",
    MESSAGES: "Berichten",
  },
  "measure-form": {
    VALUE: "Waarde",
  },
  "manual-h1-import": {
    HCP: "Interveniënt",
    PATIENT: "Patiënt",
    FILE: "Map",
    DIALOG_TITLE: "Manueel importeren van een Health One-map",
    IMPORT: "Importeren",
  },
  "dmg-patient-list": {
    OA: "Ziekenfonds",
    SSIN: "Burgerservicenummer",
    LAST_NAME: "Naam",
    FIRST_NAME: "Voornaam",
    PAYMENT: "Betaling",
    PAYMENT_DATE: "Betalingsdatum",
    SUB_TOTAL: "Subtotaal",
    TOTAL: "Totaal",
    GROUP_DRAG:
      "Sleep de kop van een kolom en plaats hier om te groeperen volgens die kolom",
    NO_LIST: "Er is op dit ogenblik geen enkele lijst beschikbaar",
    EXPORT: "Export Excel",
    EXPORT_FILE_NAME: "Patiëntenlijst_",
    MUT: "Ziekenfonds",
    BIRTH: "Geboortedatum",
    PAYMENT_REFERENCE: "Referentie",
    START: "Start",
    END: "Einde",
  },
  "ms-dmg-update-management": {
    ERROR_GET_GUID:
      "Onmogelijk om de lijsten met vernieuwingen en afsluitingen op te halen",
    NO_MESSAGE_GET:
      "Geen enkele boodschap gerecupereerd. Gelieve later opnieuw te proberen",
    EXTENSION_AND_CLOSURE_LIST: "Lijst met vernieuwingen en afsluitingen",
    closure: "Afsluiting",
    extension: "Verlenging",
    TYPE: "Type",
    PATIENT: "Patiënt",
    DATE: "Datum van de aanvraag",
    PREVIOUS_HCP: "Vorige HCP",
    END_OF_PREVIOUS_DMG: "Datum einde oud GMD",
    NEW_HCP: "Nieuwe HCP",
    BEGIN_OF_NEW_DMG: "Begindatum nieuw GMD",
  },
  reporting: {
    patient: "patiënt(en)",
    beta: "De reporting module is momenteel in beta, alle commentaar of feedback is welkom",
    searchPH: "Voer uw zoekopdracht in en klik op zoeken",
    tab: {
      reporting: "Reporting ",
      dictionary: "Lijst van zoekopdrachten",
    },
    type: {
      mammotest: "Mammotest ",
      colorectal_cancer: "Colon kanker",
      collarSmear: "Uitstrijkje",
      influenza: "Griepvaccinatie",
      mail: "Patiënten met e-mail",
      "external-patient": "Patiënten buiten de zone",
      "unregistered-patient": "Uitgeschreven patiënten",
      "registered-patient": "Ingeschreven patiënten",
      "unregister-reason": "Reden van uitschrijving",
      description: {
        mail: "Lijst van patiënten met een e-mail adres",
        "external-patient": "Lijst van actieve patiënten buiten de zone",
        "unregistered-patient":
          "Lijst van in een periode uitgeschreven patiënten",
        "registered-patient": "Lijst van in een periode ingeschreven patiënten",
        "unregister-reason":
          "Aantal per maand van redenen van uitschrijving voor een periode",
      },
    },
    column: {
      firstName: "Voornaam",
      lastName: "Naam",
      dateOfBirth: "Geboortedatum",
      ssin: "INSZ",
      gender: "Geslacht",
      active: "Actief",
      email: "e-mail",
      phone: "Tel",
      city: "Stad",
      postalCode: "Postaal nummer",
      addresse: "Adr",
      houseNumber: "Nummer",
    },
    btn: {
      search: "Zoeken",
      addFavorite: "Uw zoekopdracht opslaan",
      favoriteList: "Lijst van opgeslagen zoekopdrachten",
      save: "Opslaan",
      cancel: "Annuleren",
      export: "Exporteer het resultaat",
      helper: "Help",
      confirm: "Bevestigen",
      clean: "Schoon",
    },
    error: {
      filterIsEmpty: "Uw zoekopdracht is leeg",
    },
    dialog: {
      save: {
        title: "Nieuwe zoekopdracht opslaan",
        "query-name": "Naam van de zoekopdracht",
        "query-description": "Omschrijving van de zoekopdracht",
        query: "Zoekopdracht",
        "query-description-placeholder":
          "Beschrijving resultaat van de zoekopdracht",
      },
      helper: {
        title: "Hulp bij opstellen zoekopdracht",
      },
      "query-list": {
        title: "Lijst van de beschikbare zoekopdrachten",
        placeholder: "Zoek een zoekopdracht uit de lijst",
        column: {
          name: "Naam",
          description: "Omschrijving",
        },
      },
      delete: {
        title: "Zoekopdracht wissen",
        content: "Wilt u de volgende zoekopdracht wissen",
      },
    },
    notification: {
      save: {
        success: "De zoekopdracht is opgeslagen",
        error: "Fout bij opslaan zoekopdracht",
        incomplete: "Gelieve een naam te geven aan uw zoekopdracht",
      },
    },
    gender: {
      male: "Man",
      female: "Vrouw",
      unknown: "Onbepaald",
      "": "",
      null: "",
      undefined: "",
    },
    status: {
      active: "Actief",
      inactive: "Inactief",
    },
  },
  "patient-vaccine": {
    TITLE: "Vaccins geldig",
    CANCEL: "Annuleren",
    PRINT: "Afdrukken",
    SAVE: "Opslaan",
    SAVE_REGISTER: "Opslaan + Vaccinnet",
    SAVE_PRINT: "Opslaan en afdrukken",
    SCHEMA: "Vaccinatieschema",
    PRINT_HISTORY: "Historiek afdrukken",
    REGISTRATION_ERROR: "Fout: Vaccinnet",
    VACCINNET_NOT_ALLOWED:
      "Vaccinnet registratie enkel toegestaan voor COVID-19 vaccins",
    prescription: {
      CATEGORY: "Category",
      CURRENT_VACCINE: "Vaccin geldig",
      DOSE: "Dosis",
      dose: {
        UNIQ: "Enkele dosis",
        FIRST: "1ste dosis",
        SECOND: "2ste dosis",
        THIRD: "3ste dosis",
        FOURTH: "4ste dosis",
        RECALL: "Herhalingsdosis",
      },
      RECALL: "Herhaling",
      age: {
        ADULT: "Volwassene",
        CHILD: "Kind",
      },
      AGE: "Leeftijd",
      NOTE: "Nota",
      AUTHOR: "Auteur",
      RESPONSIBLE: "Verantwoordelijke",
      BATCH: "Batch",
      ORIGIN: "Oorsprong",
      REGISTRATION: "registratie",
    },
    code: {
      DIVERS: "Diverse",
      ARCHIVE: "Andere vaccins",
      OTHER: "Aanvullende vaccins",
      CHOLERA: "cholera",
      COVID19: "COVID-19",
      DIPHTERIA: "difterie",
      EJ: "Japanese encefalitis",
      HEPATITISA: "hepatitis A",
      HEPATITISB: "hepatitis B",
      HIB: "Hibinfecties (Haemophilus influenzae)",
      MEASLES: "mazelen",
      MENINGITIS: "meningokokkeninfectie van serogroepen A, C, W et Y",
      MENINGITISC: "meningokokkeninfectie van serogroep C",
      MUMPS: "bof",
      PANDEMICS: "specifieke pandemie",
      PAPILLOMAVIRUS: "human papillomavirus",
      PERTUSSIS: "kinkhoest",
      PNEUMONIA7: "7 types pneumokokken",
      PNEUMONIA10: "10 types pneumokokken",
      PNEUMONIA13: "13 types pneumokokken",
      PNEUMONIA23: "23 types pneumokokken",
      POLIOMYELITIS: "poliomyelitis",
      RABIES: "rabiës",
      ROTAVIRUS: "rotavirus",
      RUBELLA: "rubella",
      SEASONALINFLUENZA: "seizoengebonden influenza",
      TETANUS: "tetanus",
      TICKBORNEENCEPHALITIS: "tekenencefalitis",
      TUBERCULOSIS: "tuberculose (BCG)",
      TYPHOID: "buiktyfus",
      VARICELLA: "varicella",
      YELLOWFEVER: "gele koorts",
      ZONA: "zona",
    },
    task: {
      TASKS: "Lijst vaccins",
      RECALL: "Herhaling voor",
    },
    box: {
      VACCINE: "Vaccinatie",
    },
    history: {
      ADULT: "Volwassene",
      CHILD: "Kind",
    },
    DELETE: "Wil je dit vaccin verwijderen ?",
    "PDF-TITLE": "Overzicht vaccinaties",
    BORN_ON: "Geboren op",
    SSIN: "INSZ",
    DOMICILED: "Gedomicilieerd",
    GENERATED_BY: "Gegenereerd door",
  },
  "template-group-type": {
    SOAPS: "SOAPS",
    ADMINISTRATIFS: "Administratieve documenten",
    COURRIER: "Mailtemplate",
    "COURRIER PATIENT": "Mailtemplate voor patiënt",
    CONSEILS: "Raadgevingen aan de patiënten",
    RLM: "Lokale Zorgraad",
  },
  download: {
    "word-download": "Word-document downloaden",
    "no-info": "Gemeenschappelijke map downloaden",
  },
  "patient-ritch-text-service": {
    CLOSE: "Sluiten",
  },
  "patient-document-dialog": {
    FAILURE: "Fout bij het opslaan",
    HUB_FAILURE: "Fout bij het verzenden",
    HUB_SUCCESS: "Uw document werd verzonden",
    FAILURE_REFFERING: "Geen behandelende arts geïnformeerd voor deze patiënt",
  },
  "patient-document": {
    TITLE: "Documenten",
    VALIDATE: "Validatie",
    CANCEL: "Annuleren",
    PRINT: "Afdrukken",
    SEND_TO_EHB: "Verzenden via eHealthBox",
    SEND_TO_HUB: "Verzenden via HUB",
    SAVE: "Opslaan",
    DOWNLOAD_FOR_PATIENT: "Download schema medispring",
    EMAIL: "Verzenden via e-mail",
    SUBJECT: "",
    BODY: "",
    SELECT_HCP: "Gelieve een bestemmeling te kiezen: ",
    SELECTION: {
      NONE: "Geen",
      ATTENDING_PHYSICIAN: "Behandelende arts",
      OTHER_HCP: "Andere bestemmeling",
      PATIENT: "Patiënt",
    },
    EXPORT_FORMAT: {
      medidoc: "Medidoc formaat",
      h1: "HealthOne formaat",
    },
    NEXT: "Valideren",
    BACK: "Terug",
    UNKNOWN_PAGE: "Fout, dialoogpagina onbekend!",
    PLEASE_WAIT: "De modellen worden geladen, gelieve te wachten ...",
    NO_ATTENDING_PHYSICIAN: "Deze patiënt heeft geen behandelend arts!",
  },
  "patient-eform": {
    TITLE: "eForm",
    VALIDATE: "Validatie",
    CANCEL: "Annuleren",
    PRINT: "Afdrukken",
    SEND_TO_EHB: "Verzenden via eHealthBox",
    SAVE: "Opslaan",
  },
  boolean: {
    TRUE: "Ja",
    FALSE: "Nee",
  },
  "partial-date": {
    UNKNOWN: "onbekende datum",
  },
  "maintenance-module": {
    SHOULD_FIRST_START_CONFLICT_RESOLUTION:
      "Te veel conflicten. Voer eerst het onderhoud uit om database conflicten op te lossen.",
    MAINTENANCES: "Onderhoud",
    MAINTENANCE_NAME: "Naam",
    MAINTENANCE_START_DATE: "Begindatum",
    MAINTENANCE_END_DATE: "Einddatum",
    MAINTENANCE_LOG_DETAILS: "Extra informatie",
    MAINTENANCE_NEED_PASSWORD:
      "U hebt wachtwoord nodig. Gelieve contact op te nemen met support om het wachtwoord te verkrijgen.",
    PASSWORD: "Wachtwoord",
    INCORRECT_PASSWORD: "Fout wachtwoord",
    STOP_MAINTENANCE: "Onderhoud stopzetten",
    VALIDATE: "Valideren",
    YOU: "U",
    WAITING_FOR_STABILISATION:
      "In afwachting van het stabiliseren van uw databank ...",
    STOP_AND_QUIT: "Onderhoud stopzetten en verdergaan naar Medispring",
    MAINTENANCE_AVAILABLE:
      "Een of meerdere manieren van onderhoud beschikbaar: ",
    MAINTENANCE_STOPPED: "Stopgezet",
    MAINTENANCE_FAILED: "Is mislukt",
    RUN_MAINTENANCE: "Onderhoud toepassen",
    RUN_SELECTED_MAINTENANCES: "Geselecteerd onderhoud toepassen",
    MAINTENANCE_HISTORY_UNAVAILABLE:
      "Onmogelijk om de historiek van dit onderhoud te openen",
    MAINTENANCE_RESULTS: "Resultaat van het onderhoud",
    MAINTENANCE_STATUS: "Status",
    MAINTENANCE_DESCRIPTION: "Omschrijving",
    MAINTENANCE_DETAILS: "Gegevens",
    CONTINUE_TO_MEDISPRING: "Verdergaan naar Medispring",
    MAINTENANCE_NOT_STARTED: "Niet begonnen",
    MAINTENANCE_IN_ERROR: "Fout",
    MAINTENANCE_STARTED: "Begonnen",
    MAINTENANCE_SUCCESS: "Succes",
    MAINTENANCE_REQUIRED: "Verplicht",
    MAINTENANCE_HAS_BEEN_RUN: "Werd uitgevoerd",
    MAINTENANCE_START_TIME: "Gestart om",
    MAINTENANCE_COMPLETION_TIME: "Geëindigd om",
    BACK: "Terug",
    PAUSE_MIGRATION: "Pauzeren",
    INFO_PAUSED_MIGRATION:
      "De migratie zal zo dadelijk ophouden. Gelieve te wachten. U wordt zo dadelijk doorverwezen naar de startpagina.",
    TITLE_PAUSED_MIGRATION: "Migratie wordt gepauseerd",
    INFO_RESUME_MIGRATION:
      "De migratie is gepauseerd. U kan de migratie nu of later hervatten door de migratie opnieuw uit te voeren in het onderhoudsscherm.",
    INFO_FAILED_MIGRATION_PATIENT:
      "De migratie van de volgende patiënt is mislukt: ",
    INFO_FAILED_MIGRATION_PATIENT_2:
      "De migratie van de andere patiënten gaat door. In het geval dat de migratie van vele patiënten mislukt, kan u de migratie pauzeren en de klantenservice contacteren.",
    FAILED_TO_RESOLVE_DB_CONFLICTS: "Database conflictoplossing is mislukt.",
    INFO_NO_VALID_GROUPID:
      "Er werd geen geldige group id gevonden. Zonder group id kan de migratie niet starten. Gelieve de klantenservice te contacteren.",
    INFO_COMPLETED_MIGRATION:
      "De migratie van Topaz/iCure-gegevens is met succes voltooid.",
    TITLE_COMPLETED_MIGRATION: "Migratie voltooid",
    INFO_MIGRATION_UNMIGRATED_PATIENTS:
      "Sommige patiënten konden niet worden gemigreerd. Om dit op te lossen, start a.u.b. het onderhoud opnieuw, of neem contact op met het klantenservice.",
    INFO_FAILED_MIGRATION:
      "Migratie van Topaz/iCure gegevens is gecrasht. Neem contact op met het ondersteuningsteam voor assistentie.",
    TITLE_FAILED_MIGRATION: "Migratie onverwacht mislukt",
    INFO_FAILED_TO_LAUNCH:
      "Migratie van Topaz/iCure-gegevens kon niet worden gestart. Neem contact op met het ondersteuningsteam voor assistentie.",
    TITLE_FAILED_TO_LAUNCH: "Fout bij het starten van de migratie",
    INFO_MIGRATION_ALREADY_STARTED:
      " heeft de migratie al gestart. De migratie kan niet door twee gebruikers tegelijk worden uitgevoerd.",
    INFO_MIGRATION_IS_LOCKED: "De migratie is vergrendeld.",
    PERMANENT_MIGRATION_NOT_ALLOWED:
      "U hebt geen toelating om deze migratie uit te voeren.",
    INFO_MIGRATION_IN_ERROR:
      "De migratie kan niet gestart worden omdat hij eerder met een error beëindigd is. Gelieve de klantenservice te contacteren.",
    INFO_MIGRATION_ALREADY_SUCCEEDED:
      "De migratie is reeds successvol beëindigd.",
    MIGRATION_IN_PROGRESS: "Migratie aan de gang ...",
  },
  "printer-service": {
    SENT: "Gegevens verstuurd naar de printer.",
    ERROR: "Er is een fout gebeurd tijdens het afdrukken.",
    IMPRESSION: "Preview",
    PRINTER_NOT_FOUND:
      "{{ printerName }} i niet beschikbaar. Gelieve een andere printer te kiezen.",
    CONVERT_ERROR: "Onmogelijk om de map te converteren voor de preview",
  },
  "late-invoice-dialog": {
    title: "Betalingstermijn verstreken",
    recall_1:
      "Beste, wij hebben vastgesteld dat de betalingstermijn(en) van volgende factu(u)r(en) is vestreken:",
    recall_2:
      "Teneinde desactivatie van uw toegang te vermijden, gelieve de betaling(en) hiervan uit te voeren, indien dit nog niet is gebeurd.",
    recall_3:
      "Aarzel niet ons te contacteren op facture@medispring.be bij elke onduidelijkheid.",
    recall_4: "Dank bij voorbaat",
    recall_5: "van",
    recall_6: "Factuur nr.",
    cancel: "Sluiten",
  },
  rlm: {
    DOCUMENTS: "Documenten",
    ADMIN_DATA: "Administratief",
    TREATMENTS: "Behandelingen",
    HCP: "Interveniënten",
    OBJECTIVES: "doelen",
    FOLLOW_UP: "Jaarlijkse opvolging",
    PRESCRIPTIONS: "Voorschriften",
    CAREPATH: "Zorgtraject",
    RENAL_FAILURE: "Nierinsufficiëntie",
    PREDIABETES: "Voortraject diabetes",
    DIABETES2: "Diabetes type 2",
    CAREPATH_RENAL_FAILURE: "Zorgtraject: Nierinsufficiëntie",
    CAREPATH_PREDIABETES: "Zorgtraject: Voortraject diabetes",
    CAREPATH_DIABETES2: "Zorgtraject: Diabetes type 2",
    administrative: {
      PATIENT_MANAGER: "Beheerder van het zorgtraject",
      PATIENT_MANAGER_DOES_NOT_EXISTS: "Geen beheerder.",
      RLM_SECRETARY: "Secretaris Lokale Zorgraad",
      ACTIVE_CAREPATH: "Actief zorgtraject",
      DIABETES_CONVENTION: "Diabetesconventie",
      RADIO_YES: "ja",
      RADIO_NO: "neen",
      TYPE_OF_PATHOLOGY: "Type pathologie",
      START_DATE: "Aanmaakdatum",
      END_DATE: "Einddatum",
      END_REASON: "Reden van stopzetten",
      NOTE: "Administratieve nota",
      CAREPATH_SIGN_DATES: "Handtekening zorgtraject",
      SIGN_DATE: {
        PATIENT: "Patiënt",
        GENERAL_PHYSICIAN: "Huisarts",
        SPECIALIST_PHYSICIAN: "Specialist",
        MUTUALITY: "Ziekenfonds",
      },
    },
    "hcp-form": {
      ADD_HCP: "Een interveniënt toevoegen",
      SPECIALITY: "Specialiteit",
      DATE: "Datum van de volgende afspraak",
      ACTIONS: "Acties",
      actions: {
        DETAIL: "Gegeven",
        DELETE: "Verwijderen",
        APPOINTMENT: "Afspraak",
      },
      APPOINTMENT_SUCCESS: "De update van de afspraak is gelukt",
    },
    appointment: {
      DATE: "Datum",
      APPOINTMENT_NUM: "Afspraaknummer",
      NOTE: "Nota",
      ADD_APPOINTMENT: "Afspraak toevoegen",
    },
    treatment: {
      grid: {
        INCLUSION: "Bij baseline",
        CURRENTLY: "Nu",
        ADD_TREATMENT: "Een behandeling toevoegen",
        DESCRIPTION: "Beschrijving",
        TYPE: "Type",
        ACTIONS: "Acties",
        EDIT: "Aanpassen",
        DELETE: "Verwijderen",
      },
      dialog: {
        TITLE_ADD: "Een behandeling toevoegen",
        TITLE_EDIT: "Een behandeling aanpassen",
        TITLE_EDIT_FROM_REPRISE:
          "Een behandeling aanpassen (na een hervatting)",
        TYPE: "Type",
        DESCRIPTION: "Beschrijving",
      },
    },
    followup: {
      ADD_FOLLOW_UP: "Opvolging toevoegen",
      DESCRIPTION: "Beschrijving",
      YEAR_X: "{{value}}° Jaar",
      COMBO_ANNEE: "Jaar van opvolging",
      COMBO_GLUCOMETRE: "Type glucosemeter",
      EDUCATION: "Opvoeding",
      DIETETIC: "Dieet",
      PODIATRIST: "Podologie",
      SAI_EDUC_DATE_PLACE: "Datum van oprichting",
      SAI_Nom_Educateur: "Naam opvoeder",
      COMBO_EDUC_Statut: "Statuut",
      SAI_EDUC_DATE_Global1: "1° Vertrek",
      SAI_EDUC_DATE_Global2: "2° Vertrek",
      SAI_EDUC_DATE_Global3: "3° Vertrek",
      SAI_EDUC_DATE_Global4: "4° Vertrek",
      SAI_EDUC_DATE_Global5: "5° Vertrek",
      SAI_EDUC_DATE_Rapport1: "Verslag",
      SAI_EDUC_DATE_Indiv1: "6° Vertrek",
      SAI_EDUC_DATE_Indiv2: "7° Vertrek",
      SAI_EDUC_DATE_Indiv3: "8° Vertrek",
      SAI_EDUC_DATE_Indiv4: "9° Vertrek",
      SAI_EDUC_DATE_Indiv5: "10° Vertrek",
      SAI_EDUC_DATE_Rapport2: "Verslag",
      SAI_EDUC_DATE_Rapport3: "Verslag",
      SAI_EDUC_DATE_Rapport4: "Verslag",
      SAI_EDUC_DATE_Rapport5: "Verslag",
      SAI_EDUC_DATE_Rapport6: "Verslag",
      SAI_EDUC_DATE_Suivi1: "Opvolging 1",
      SAI_EDUC_DATE_Suivi2: "Opvolging 2",
      SAI_EDUC_DATE_Rapport11: "Verslag",
      SAI_EDUC_DATE_Rapport12: "Verslag",
      SAI_EDUC_Note: "Nota",
      SAI_EDUC_DATE_Compl1: "Complicatie 1",
      SAI_EDUC_DATE_Compl2: "Complicatie 2",
      SAI_EDUC_DATE_Compl3: "Complicatie 3",
      SAI_EDUC_DATE_Compl4: "Complicatie 4",
      SAI_EDUC_DATE_Rapport7: "Verslag",
      SAI_EDUC_DATE_Rapport8: "Verslag",
      SAI_EDUC_DATE_Rapport9: "Verslag",
      SAI_EDUC_DATE_Rapport10: "Verslag",
      SAI_DIE_DATE_PLACE: "Datum van oprichting",
      SAI_Nom_Dietetique: "Naam diëtist",
      COMBO_DIE_Statut: "Statuut",
      SAI_DIE_DATE_1: "1° Sessie 30'",
      SAI_DIE_DATE_2: "2° Sessie 30'",
      SAI_DIE_DATE_Rapport1: "Verslag",
      SAI_DIE_DATE_Rapport2: "Verslag",
      SAI_DIE_Note: "Nota",
      SAI_POD_DATE_PLACE: "Datum van oprichting",
      SAI_Nom_Podologue: "Naam podoloog",
      COMBO_POD_Statut: "Statuut",
      SAI_POD_DATE_1: "1° Sessie 45'",
      SAI_POD_DATE_2: "2° Sessie 45'",
      SAI_POD_DATE_Rapport1: "Verslag",
      SAI_POD_DATE_Rapport2: "Verslag",
      SAI_POD_Note: "Nota",
      dialog: {
        TITLE: "Jaarlijkse opvolging diabetes",
        TITLE_DOCUMENT: "Opvolgdocument",
        ERROR_UPLOAD: "Het document kon niet naar de server worden verzonden.",
        EXT_NOT_AUTHORIZED:
          "De mappen {{ext}} zijn niet toegelaten. Toegelaten mappen: {{autorized}}",
      },
    },
    prescription: {
      grid: {
        ADD_PRESCRIPTION: "Voorschrift toevoegen",
        DATE: "Datum",
        RENEW_DATE: "Te vernieuwen",
        DESCRIPTION: "Voorschrift",
        NUMBER: "Aantal(len)",
        COMMENT: "Opmerkingen",
        ACTIONS: "Acties",
        EDIT: "Aanpassen",
        DELETE: "Verwijderen",
      },
      dialog: {
        TITLE_ADD: "Voorschrift toevoegen",
        TITLE_EDIT: "Voorschrift aanpassen",
        CHOICE: "Keuze van het voorschrift",
        DATE: "Datum voorschrift",
        RENEW_DATE: "Voorschrift te vernieuwen op",
        NUMBER: "Aantal(len) voorgeschreven",
        COMMENT: "Opmerking",
      },
    },
    actions: {
      DELETE_ANNUAL_CONTACT: "Jaarlijkse opvolging verwijderen",
      DELETE_DOCUMENT: "Document verwijderen",
      CANCEL: "Annuleren",
      SAVE: "Opslaan",
      SAVE_AND_PRINT: "Opslaan en afdrukken",
    },
    message: {
      DELETE_ANNUAL_CONTACT:
        "Weet u zeker dat u de jaarlijkse opvolging wil verwijderen?",
      DELETE_REPORT_DOCUMENT:
        "Weet u zeker dat u het document wil verwijderen?",
      CONTACT_SAVED: "Contact opgeslagen",
      CONTACT_DELETED: "Contact verwijderd",
      ERROR_WHILE_SAVING_CONTACT:
        "Er heeft zich een fout voorgedaan tijdens het opslaan van het contact",
      ERROR_WHILE_DELETING_CONTACT:
        "Er heeft zich een fout voorgedaan tijdens het verwijderen van het contact",
    },
    "list-code": {
      undefined: "?",
      nodiabetes: "Geen diabetes",
      diabetestypeone: "Diabetes type I",
      diabetestypeoneorgan: "Diabetes type I + organen aangetast",
      diabetestypetwo: "Diabetes type II",
      diabetestypetwoandrisk: "Diabetes type II + ten minste een FR",
      no: "Nee",
      low: "Laag (>60 ml/min/1.73m²)",
      moderatetostrict: "Gematigd tot streng (<60ml/min/1.73m²)",
      moved: "Is verhuisd",
      convention: "Conventie",
      deceased: "Overleden)",
      offcriteria: "Buiten criteria",
      notcompleted: "Niet beëindigd",
      nonrespectobligation: "Geen respect verplichting",
      refusesfollowup: "Weigering opvolging",
      year: "{{value}}° Jaar",
      ACCUCHEK_AVIVA: "Accu-chek Aviva",
      ACCUCHEK_COMPACT: "Accu-chek Compact",
      ACCUCHEK_COMPAC: "Accu-chek Compact +",
      ACCUCHEK_MOBILE: "Accu-chek Mobiel",
      ACCUCHEK_NANO: "Accu-chek Nano",
      ACCUCHE: "Accu-chek*",
      BGSTAR: "BG star",
      BREEZE2: "Breeze 2",
      CONTOUR: "Contour",
      CONTOUR_XT: "Contour XT",
      FINE_TOUCH: "Fine touch",
      FREESTYLE: "Freestyle",
      GLUCOMEN_GM: "Glucomen GM",
      GLUCOMEN_LX: "Glucomen LX",
      GLUCOME: "Glucomen*",
      ONETOUCH_ULTRA: "One Touch Ultra",
      ONETOUCH_ULTRA2: "One Touch Ultra 2",
      ONETOUCH_ULTRA_EASY: "One Touch Ultra Easy",
      ONETOUCH_ULTRA_VERIO: "One Touch Ultra Verio",
      VITA: "Vita",
      ONETOUCH_ULTR: "One Touch Ultra*",
      PRECISION_EXCEED: "Precision Exceed",
      GLUCOMEN_AREO: "Glucomen Areo",
      FOLLOWED: "Al opgevolgd",
      WAITING: "In afwachting",
      MESSAGE: "Bericht",
      REFUSAL: "Weigering",
      VIA_RML: "Via RML",
      dietetic: "Dieetsessie",
      initialeducation: "Beginsessie opvoeding",
      followupeducation: "Opvolgsessie opvoeding",
      complementaryeducation: "Extra sessie opvoeding",
      podiatrist: "R / podoloog",
      glucometer: "Glucometer",
      teststrips: "Urinestrookjes",
      oralantidiabetic: "Orale antidiabetica",
      incretinomimetics: "incretinemimeticum",
      "1insulininjection": "1 insuline-injectie",
      "2insulininjection": "2 insuline-injecties",
    },
  },
  "file-vignettes": {
    SELECT_FILE: "Deze map kiezen",
  },
  "file-vignette": {
    DOCUMENT: "Document",
  },
  "hcp-selector": {
    NIHII: "RIZIV",
    SSIN: "INSZ",
    LAST_NAME: "Naam",
    FIRST_NAME: "Voornaam",
    SPECIALITY: "Specialiteit",
    COORDINATE: "Gegevens",
    NOTES: "Nota's",
    LOADING: "Aan het laden ...",
    CREATE: "Zorgverstrekker opmaken",
  },
  "guardPost-mode": {
    UNDEFINED_GUARDPOST_MEDICAL_LOCATION:
      "De informatie van uw entiteit is niet beschikbaar of kon niet correct geselecteerd worden. Gelieve opnieuw verbinding te maken om informatie te kiezen van uw entiteit of neem contact op met de Helpdesk",
    INVALID_GUARDPOST_MEDICAL_LOCATION:
      "De informatie van uw entiteit is niet volledig. Naam: {{name}} en Riziv: {{nihii}}. Gelieve de Helpdesk te contacteren",
  },
  "printer-management": {
    PRESCRIPTION: "Voorschrift",
    ITT: "Ongeschiktheidsattest",
    TAG: "Etiket",
    DOCUMENT: "Document",
    PRINTER: "Printer",
    FORMAT: "Formaat",
    TITLE: "Standaard instellingen",
    ALWAYS_REQUEST_PRINTER: "Elke keer vragen",
  },
  "tag-print": {
    "L2.1": "INSZ: {{ssin}}  GSM: {{gsm}}",
    "L2.2": "DN: {{date}}  GSM: {{gsm}}",
    L5: "Ziekenfonds: {{mutuality}}  Status: {{statut}}",
  },
  "security-management": {
    TITLE: "Veiligheid",
    TEXT: "Automatisch verbinding verbreken als er geen activiteit is gedurende: ",
    MINUTES: "minuten.",
  },
  "idle-popup": {
    TITLE: "Verbinding automatisch verbreken",
    CONTENT:
      "U bent al {{timeout}} minuten niet actief geweest op Medispring. De verbinding wordt zo dadelijk verbroken.",
  },
  "print-document-dialog": {
    CANCEL: "Annuleren",
    FORMAT: "Formaat",
    PRINTER: "Printer",
    PRINT: "Afdrukken",
    SAVE_MY_CHOICE: "Mijn keuze bewaren",
    ALWAYS_CHANGE_LATER:
      "U kunt uw keuze altijd wijzigen bij instellingen, afdruk",
  },
  "document-types": {
    ITT: "Ongeschiktheidsattest",
    PRESCRIPTION: "Voorschrift",
    TAG: "Etiket patiënt",
  },
  SUMEHR: {
    COMMENT: "Sumehr opgemaakt door Medispring.",
    SECRET_ELEMENT:
      "Sommige onderdelen van dit dossier verschijnen niet omdat ze privé zijn of niet relevant.",
    EMPTY_CATEGORY: "De categorie {{c}} heeft geen element in het dossier.",
    EMPTY_CATEGORIES:
      "De categorieën {{c}} hebben geen element in het dossier.",
    STATUS: {
      UP_TO_DATE: "Laatste sumehr verzonden op {{date}}",
      MISSING: "Geen sumehr geüpload voor de patiënt",
      CHANGED:
        "Een nieuwe versie van sumehr kan worden geëxporteerd (laatste export op {{date}})",
    },
  },
  "version-info": {
    RELEASE: "Update",
    RELEASE_DATE: "Datum van update",
  },
  "versions-info": {
    BACK: "Terug",
  },
  "scan-import-service": {
    ERROR_SEARCH_AND_IMPORT: "Fout bij het zoeken en het importeren",
  },
  "progress-bar": {
    ESTIMATED_TIME_LEFT: "Geschatte resterende tijd",
  },
  "import-task-row": {
    IMPORT_OF_FILES: "Importeren van de mappen",
    IMPORT_COMPLETE: "Importeren volledig",
    IMPORT_WILL_NOT_STOP_IF_YOU_NAVIGATE_TO_OTHER_PAGE:
      "Het importeren zal niet onderbroken worden als u deze pagina verlaat",
  },
  "scan-file-list": {
    FILE_NAME: "Mapnaam",
    IMPORT_STATUS: "Status importeren",
    PATIENT: "Patiënt",
    CONTACT_DATE: "Contactdatum",
    RESPONSIBLE: "Verantwoordelijke",
    CONCLUSION: "Besluit",
  },
  "scan-file-row": {
    READY_FOR_IMPORT: "Klaar voor importeren",
    IMPORTED: "Ingevoerd",
    MISSING_INFO: "Ontbrekende informatie",
    DUPLICATE: "Kopieer",
    IMPORTING: "Importeren",
    FAILED: "Mislukt",
    SELECT_PATIENT: "Kies een patiënt",
    CANCEL: "Annuleren",
    SAVE: "Bewaren",
    SELECT_RESPONSIBLE: "Kies een verantwoordelijke",
    BASED_ON_NAME_FILE: "Volgens de naam van de map",
  },
  "select-date-dialog": {
    SAVE: "Bewaren",
    CANCEL: "Annuleren",
  },
  "efac-to-be-sent": {
    ONLY_SUPERVISOR_CAN_SEND_ATTESTS:
      "Alleen uw stagemeester can eFacts sturen.",
    INCORRECT_PRESCRIBER_NIHII: "Verkeerde Riziv nummer voor voorschrijver.",
    INCORRECT_LOCATION_NIHII:
      "Verkeerde Riziv nummer voor plaats van prestatie.",
    PATIENT_CT_PARAMS_NOT_THERE:
      " Ontbrekende verzekerbaarheidsgegevens CT1 en CT2.",
    BATCH_ALREADY_SENT_ERROR:
      "er werd vandaag al een batch gestuurd naar het ziekenfonds {{ioCode}}",
    NO_FINANCIAL_INFO_FOR_ASSISTANTS:
      "Gedeelde bankrekening van onbekende assistenten",
    COLLECTIVE_SETTING_WHILE_PRACTICIAN:
      "Individuele perceptie is niet toegelaten voor deze wachtdienst.",
    INDIVIDUAL_SETTING_WHILE_GUARDPOST:
      "Gecentraliseerde perceptie is niet toegelaten voor deze wachtdienst.",
  },
  exports: {
    groups: {
      CONTACT: "Contact",
      SUB_CONTACT: "Subcontact",
      HEALTH_ELEMENT: "Zorgelement",
      PLAN_OF_ACTION: "Actieplan",
      SERVICE: "Dienst",
    },
    fields: {
      CONTACT_ID: "ID",
      CONTACT_TYPE: "Type",
      CONTACT_SPECIALITY: "Specialiteit",
      CONTACT_DATE: "Datum",
      CONTACT_AUTHOR_ID: "ID van de auteur",
      CONTACT_AUTHOR_NAME: "Naam van de auteur",
      CONTACT_INTEGRATOR_ID: "ID van de l'ƒ",
      CONTACT_INTEGRATOR_NAME: "Naam de l'integrator",
      CONTACT_INTEGRATION_DATE: "Datum van de integratie",
      SUB_CONTACT_ID: "ID",
      SUB_CONTACT_HE_NAMES: "Zorgelementen",
      HEALTH_ELEMENT_NAME: "Naam (Zorgelementen)",
      PLAN_OF_ACTION_NAME: "Naam (Actieplan)",
      SERVICE_ID: "ID",
      SERVICE_NAME: "Naam",
      SERVICE_VALUE: "Waarde",
      SERVICE_UNIT: "Eenheid",
      SERVICE_TAGS: "Tags",
      SERVICE_CREATION_DATE: "Opmaakdatum",
    },
    values: {
      CONTACT: {
        types: {
          consult: "Consultatie",
          labresult: "Laboratoriumresultaat",
          contactreport: "Verslag",
          telephonicconsultation: "Mening/Divers",
          multidisciplinaryconsult: "Multidisciplinair overleg",
          homevisit: "Huisbezoek",
        },
      },
      SERVICE: {
        types: {
          PLAN_TEXT: "Actieplan",
          PLAN_MEDICATION: "Behandeling",
        },
      },
    },
  },
  "patients-mailing-dialog": {
    EMAIL: "E-mail",
    PAPER: "Papier",
    PLACE_DOCUMENT_AS_ATTACH: "Document als bijlage toevoegen aan de e-mail",
    SEND_CHANNEL: "Type verzending",
    DOCUMENT: "Document",
    PRINT_FOR_PATIENTS_WITHOUT_ADDRESS:
      "Ook afdrukken voor patiënten zonder adres",
    PRINTER: "Printer",
    CANCEL: "Annuleren",
    PRINT: "Afdrukken",
    SEND_EMAIL: "E-mails versturen",
    EMAIL_BODY: "Inhoud van de e-mail",
    EXPORT_CSV: "Export Excel",
    FAILED_SENDING_EMAIL_TO: "Onmogelijk om e-mail te versturen naar {{name}}",
    FAILED_PRINTING_DOCUMENT_FOR:
      "Onmogelijk om het document af te drukken voor {{name}}",
    BACK: "Terug",
    SUBJECT: "Onderwerp",
    NAME: "Naam",
    FROM: "Van",
    EXCLUDE_PATIENTS:
      "Patiënten uitsluiten die al een vergelijkbaar bericht gekregen hebben",
    TIME_UNIT: {
      days: "Dagen",
      weeks: "Weken",
      months: "Maanden",
      years: "Jaar",
    },
    TIME_FRAME: "Tijdsbestek",
    EXCLUDED_PATIENTS:
      "patiënten die al een vergelijkbaar bericht gekregen hebben",
    SELECT_SEND_CHANNEL: "Kies een soort verzending",
    CONFIGURE_PAPER: "Afdruk configureren",
    CONFIGURE_MAILING: "Verzending configureren",
    COMPOSE_MAIL: "Te verzenden post samenstellen",
    SELECT_A_DOCUMENT_TYPE: "Kies een documenttype (optioneel)",
    EXCLUSION_OF_PATIENTS: "Sommige patiënten uitzonderen",
    PATIENTS_WITHOUT_EMAIL: "patiënten zonder e-mailadres",
    PATIENTS_WITHOUT_HOME_ADDRESS: "patiënten zonder thuisadres",
    PATIENTS_WITH_MISSING_INFO: "patiënten waar informatie ontbreekt patients",
    EXPORT_LIST_OF_THESE_PATIENTS: "Lijst van deze patiënten exporteren",
    CONFIRMATION_OF_IMPRESSION: "Bevestiging van de afdruk",
    NUMBER_OF_PATIENTS_SELECTED: "Aantal geselecteerde patiënten",
    NUMBER_OF_PATIENTS_AFTER_EXCLUSION: "Aantal patiënten na uitsluiting",
    THE_CONTENT_WILL_BE_SAVED_AS_PDF: "De post wordt gedownload als PDF",
    NAME_OF_SENDER: "Naam van de afzender",
    SEND_FROM_EMAIL_ADDRESS: "Verzenden van e-mailadres",
    COMPOSE_EDIT_BODY_CONTENT: "Tekst samenstellen/aanpassen",
    ALL_FIELDS_ARE_REQUIRED: "Alle velden zijn verplicht in te vullen",
    PATIENT_HAS_NO_EMAIL: "Patiënt heeft geen e-mailadres",
  },
  "dropdownlist-filter": {
    ALL: "Alle",
  },
  "medication-scheme": {
    AUTHOR: "Auteur",
    REMARKS: "Opmerkingen",
    FREQUENCY: "Frequentie",
    BEGIN_MOMENT: "Begin",
    END_MOMENT: "Einde",
    INNAME_AND_ROUTE: "Manier van toedienen",
    groups: {
      ONESHOT: "Medicatie op vraag",
      ACUTE: "Spoedmedicatie",
      CHRONIC: "Chronische medicatie",
    },
    regimen: {
      BREAKFAST: "Ochtend",
      LUNCH: "Middag",
      DINNER: "Avond",
      MORNING: "Nuchter",
      NIGHT: "Voor het slapengaan",
      BEFORE: "Voor de maaltijd",
      DURING: "Tijdens de maaltijd",
      AFTER: "Na de maaltijd",
      head: {
        PATIENT: "Patiënt",
        EDITED_BY: "Laatste aanpassing door",
        RETRIEVED_BY: "Afgedrukt door",
        EDITED_ON: "Looptijd",
        RETRIEVED_ON: "Herstelperiode",
        VERSION: "Versie nr.",
      },
    },
    SEND_OK: "Medicatieschema verzonden",
    SEND_ERROR: "Fout bij het verzenden van het medicatieschema: {{error}}",
    SEND: "Schema verzenden",
    DOWNLOAD: "XML downloaden",
    IMPORT: "Importeren",
    IMPORT_TITLE: "Schema importeren",
  },
  "access-journal": {
    DATE: "Datum",
    USER: "Gebruiker",
    ROLES: "Rollen",
    PATIENT_FILE: "Patiëntendossier",
    ACCESS_TYPE: "Type",
    type: {
      USER_ACCESS: "Toegang tot patiënten",
      USER_LOGIN: "Verbindingen",
      ALL: "Alle",
    },
    FILTER_BY_USER: "Sorteren op gebruiker",
  },
  proofs: {
    NO_CARD: "Geen kaart",
    HOUSE_CALL: "Huisbezoek",
    TECH_PROBLEM: "Technisch probleem",
  },
  "address-book": {
    SEARCH_GLOBAL_DIRECTORY: "Zoeken in eHealth adresboek (Cobrha)",
    SEARCH_COBRHA: "Zorgverstrekker zoeken in eHealth adresboek (Cobrha)",
    SEARCH_BY_NAME:
      "Tik minstens 2 letters om te zoeken op naam of geef volledig RIZIV nummer in",
    SEARCH_BY_QUALITY:
      "Zoek of selecteer type zorgverstrekker (standaard ‘dokter’)",
    CANCEL: "Annuleren",
    OK: "Toevoegen",
    LABELS: {
      NAME: "Naam",
      NIHII_NISS_CBE_EHP: "RIZIV/INSZ/CBE/EHP",
      SPECIALTY: "Specialiteit",
      QUALITY: "Kwaliteit",
    },
    TOOLTIP: "Zoeken in eHealth adresboek (Cobrha)",
    SELECTED_RECIPIENTS: "Bestemmelingen geselecteerd: ",
    ADD_IN_LOCAL_ADDRESS_BOOK: "Bestemmelingen toegevoegd aan adressenlijst",
    QUALITIES: {
      AMBULANCE_RESCUER: "Ambulancier",
      APPLIED_PSYCH_BACHELOR: "Bachelor Toegepaste Psychologie",
      AUDICIAN: "Audiologist",
      AUDIOLOGIST: "Audioloog",
      DENTIST: "Tandarts",
      DIETICIAN: "Diëtist",
      PHYSICIAN: "Dokter",
      FAMILY_SCIENCE_BACHELOR: "Bachelor Gezinswetenschappen",
      GERONTOLOGY_MASTER: "Master Gerontologie",
      IMAGING_TECHNOLOGIST: "Technicus Medische Beeldvorming",
      IMPLANTPROVIDER: "Implantaatverstrekker",
      LAB_TECHNOLOGIST: "Laboratoriumtechnicus",
      LOGOPEDIST: "Logopedist",
      MIDWIFE: "Vroedvrouw",
      NURSE: "Verpleger/verpleegster",
      OCCUPATIONAL_THERAPIST: "Ergotherapeut",
      OPTICIAN: "Oogarts",
      ORTHOPEDAGOGIST_MASTER: "Master Orthopedagogiek",
      ORTHOPEDIST: "Orthopedist",
      ORTHOPTIST: "Oogarts",
      PEDIATRIC_NURSE: "Pediatrisch verpleegkundige",
      PHARMACIST: "Apotheek",
      PHARMACIST_ASSISTANT: "Apotheekassistent",
      PHYSIOTHERAPIST: "Kinesist",
      PODOLOGIST: "Podoloog",
      PRACTICAL_NURSE: "Hulpverlener",
      PSYCHOLOGIST: "Psycholoog",
      PSYCHOMOTOR_THERAPY: "Master Psychomotorische Therapie",
      READAPTATION_BACHELOR: "Bachelor Revalidatie",
      SOCIAL_WORKER: "Sociaal assistent",
      SPECIALIZED_EDUCATOR: "Gespecialiseerd opvoeder",
      TRUSS_MAKER: "Bandagist",
      AMBU_SERVICE: "Ziekenvervoer",
      CONSORTIUM: "Consortium kankerscreening",
      CTRL_ORGANISM: "Controleorganisme",
      EHP: "Speciale eenheid, erkend door eHealth",
      ENTERPRISE: "Onderneming",
      GROUPOFDOCTORS: "Groepering huisartsen op verschillende locaties",
      GROUPOFNURSES: "Groepering verpleegkundigen",
      GUARD_POST: "Wachtdienst",
      HOME_SERVICES: "Geïntegreerde diensten voor thuisverzorging",
      HOSPITAL: "Ziekenhuis",
      LABO: "Laboratorium",
      MEDICAL_HOUSE: "Medisch huis",
      OF_BAND: "Atelier bandagist-orthopedist",
      OFFICE_DOCTORS: "Groepering huisartsen op dezelfde locatie",
      OTD_PHARMACY: "Tariferingsdienst",
      PALLIATIVE_CARE: "Palliatieve zorgen",
      PHARMACY: "Apotheek",
      PROT_ACC: "Initiatief voor beschut wonen voor psychiatrische patiënten",
      PSYCH_HOUSE: "Psychiatrisch verzorgingstehuis",
      RETIREMENT: "Rustoord",
      TREAT_CENTER: "Behandelingscentrum",
      INSTITUTION: "Instelling",
      SORTING_CENTER: "Sorteer centrum",
      DOCTOR: "Dokter",
      INSTITUTION_EHP: "Instelling EHP",
    },
  },
  "e-health-box-errors-by-code": {
    "700": "Onbekende technische fout",
    "701":
      "Validatiefout bij het opgestelde bericht. Sommige technische velden ontbreken of werden fout ingevuld.",
    "702":
      "ID-publicatie bestaat al, kies een nieuwe en verzend het bericht opnieuw",
    "703": "Een of meerdere bestemmelingen zijn ongeldig",
    "801": "Het bericht overschrijft de maximaal toegelaten grootte",
    "802":
      "De bestemmeling is onbekend; gelieve na te gaan of de ID van de bestemmeling geldig is (NISS, INAMI, CBE,...)",
    "803":
      "De kwaliteit van de bestemmeling is ongeldig; gelieve na te gaan of de kwaliteit erkend is door eHealth",
    "804":
      "Het soort bestemmeling is ongeldig; gelieve na te gaan of het type erkend is door eHealth",
    "805":
      "Het gespecificeerde subsoort is ongeldig; gelieve na te gaan of de subsoort erkend is door eHealth",
    "806":
      "MessageID is ongeldig; gelieve na te gaan of de Bron en het MessageID correct zijn en u er toegang toe hebt",
    "807":
      "EndIndex moet even groot of gelijk zijn aan StartIndex; gelieve StartIndex en EndIndex te verbeteren",
    "808":
      "Er kunnen maximum 100 berichten teruggevonden worden;  gelieve StartIndex et EndIndex te verbeteren",
    "809":
      "MessageID is ongeldig; gelieve na te gaan of MessageID juist is en u er de auteur van bent.",
    "810":
      "ID van gespecificeerde inbox eHealthbox is ongeldig; gelieve na te gaan of u normaal gezien toegang hebt tot deze eHealthbox",
    "812":
      "U kunt een bericht uit uw inbox niet verplaatsen naar outbox (zelfs niet via de prullenmand) of omgekeerd",
    "813":
      "Alle berichten konden niet verplaatst worden. Gelieve voor elke boodschap na te gaan of de Source en MessageID correct zijn. Let ook op of het bericht in de prullenbak dat uit inbox komt, niet uit de inbox komt of omgekeerd.",
    "814":
      "U hebt geen toelating om over deze kwaliteit te publiceren; neem contact op met eHealth voor toelating.",
    "815":
      "Niet alle berichten konden verwijderd worden. Gelieve voor elke boodschap na te gaan of de Source en MessageID correct zijn.",
    "816":
      "De versleuteling van het document komt niet overeen met de geleverde sleutel. Herbereken SHA-256 van de inhoud.",
    "820":
      "De periode van <startdate> tot <enddate> is ongeldig omdat ze overlapt met een andere periode.",
    "821":
      "De einddatum van de periode mag niet verder dan een jaar in de toekomst zijn ",
    "822": "De begindatum mag niet na de einddatum vallen.",
    "823": "De begindatum mag niet in het verleden zijn.",
    "824":
      "Een of meerdere vervangers kunnen niet gekozen worden omdat ze afwezig zijn",
    "825": "Het aantal vervangers mag niet hoger zijn dan 5.",
    "826":
      "Een of meerdere bestemmelingen hebben 'out-of-office' geactiveerd, gelieve hen te verwijderen uit de lijst met bestemmelingen. ",
    "827":
      "Een of meerdere vervangers zijn onbekend of fout, gelieve dit te verbeteren.",
    "828": "De gebruiker is onbekend of fout, gelieve dit te verbeteren. ",
    "829":
      "Een geldige vervanger moet een persoon zijn, geen organisatie. Gelieve dit te verbeteren.",
    "830": "Een persoon kan niet zichzelf vervangen.",
    "840": "Een of meerdere OoOId zijn ongeldig.",
    UNKNOWN: "Onbekende fout.",
    "403_COULD_NOT_RETRIEVE_ETK":
      "Een of meerdere bestemmelingen hebben geen geldig eHealth-certificaat. Misschien is hun certificaat vervallen of de gekozen bestemmeling bestaat niet.",
  },
  application: {
    UNKNOWN_ERROR: "Er heeft zich een onbekende fout voorgedaan",
  },
  statistics: {
    PATIENT_ISO_CODE: "Patiënt wiens land handmatig moet worden gewijzigd",
    APPOINTMENT_HOUR: "Uur van de afspraak",
    APPOINTMENT_DATE: "Datum van de afspraak",
    CALENDAR_ITEM_ID: "Calendar item ID",
    PMG_AGENDA: "Agenda wachtpost",
    WEEKDAY: {
      "1": "Maandag",
      "2": "Dinsdag",
      "3": "Woensdag",
      "4": "Donderdag",
      "5": "Vrijdag",
      "6": "Zaterdag",
      "7": "Zondag",
    },
    STATISTICS: "Statistieken",
    AGE_DISTRIBUTION: "Leeftijdsgroep",
    AGE_NOT_DEFINED: "leeftijd onbekend",
    YEARS: "jaren",
    FILE_FOR_RASH: "Dossier voor RASH",
    PERIOD_FROM: "Periode van",
    TO: "tot",
    NUMBER_OF_BIM_PATIENTS: "Aantal patiënten RVV",
    NUMBER_OF_DMG_PATIENTS: "Aantal patiënten GMD",
    NUMBER_OF_AMU_PATIENTS: "Aantal patiënten AMU",
    GROUPING_BY_GENDER: "Verdeling volgens soort",
    GENDER: {
      MEN: "Mannen",
      WOMEN: "Vrouwen",
      OTHER: "Anderen",
      TOTAL: "Totaal",
    },
    GROUPING_BY_POSTAL_CODE: "Verdeling volgens woonplaats van de bestemmeling",
    GROUPING_OF_CONSULATIONS_BY_FUNCTION: "Aantal consultaties/prestaties",
    NUMBER_OF_ACTIVE_PATIENTS: "Aantal actieve patiënten",
    INAMI: "Op kosten van Inami",
    ACT: "Tot actie",
    ERROR_GENERATING_STATISTICS:
      "Er heeft zich een fout voorgedaan bij het samenstellen van de statistieken",
    COMPLETED_GENERATING_STATISTICS:
      "Het samenstellen van de statistieken is voltooid",
    NUMBER_OF_PATIENT_WITH_COLON_CANCER_PREVENTION:
      "Aantal screenings darmkanker",
    "50_TO_75_YEARS": "50 tot 75 jaar",
    NUMBER_OF_PATIENT_WITH_MAMO_PREVENTION: "Aantal screenings mammografie",
    "50_TO_69_YEARS_WOMEN": "vrouwen van 50 tot 69 jaar",
    NUMBER_OF_PATIENT_WITH_FLU_PREVENTION: "Aantal griepvaccins",
    "65_YEARS_AND_OLDER": "65 jaar en meer",
    OTHERS: "Anderen",
    CUSTOM_DATES: "Gepersonaliseerde gegevens",
    RENAL_FAILURE: "Nierinsufficiëntie",
    PRE_DIABETES: "Voortraject diabetes",
    DIABETES_2: "Diabetes type 2",
    PATIENT_NAME: "Naam van de patiënt",
    CREATION_DATE_TS: "Opmaakdatum TS",
    STOP_DATE: "Datum stopzetting TS",
    MOTIF_STOP: "Reden stopzetting",
    POSTAL_CODE: "Postnummer",
    M_GENERAL: "Huisarts",
    NEPHRO: "Nefroloog",
    ENDO: "Endocrinoloog",
    EDU: "opvoeder",
    PODO: "Podoloog",
    DIET: "dieet",
    RASH: "RASH",
    RLM: "LZ",
    LOADING: {
      RLM: "Opmaak statistieken Lokale Zorgraad bezig voor de periode van {{start}} tot {{end}}",
      RLM_NO_START:
        "Opmaak statistieken Lokale Zorgraad bezig voor de periode van voor {{end}}",
      RASH: "Opmaak statistieken RASH bezig voor de periode van {{start}} tot {{end}}",
      RASH_NO_START:
        "Opmaak statistieken RASH bezig voor de periode van voor  {{end}}",
      PMG: "Opmaak statistieken wachtpost bezig voor de periode van {{start}} tot {{end}}",
      PMG_NO_START:
        "Opmaak statistieken wachtpost bezig voor de periode van voor {{end}}",
      PMGCSV:
        "Opmaak statistieken wachtpost (formaat CSV) bezig voor de periode van {{start}} tot {{end}}",
      PMGCSV_NO_START:
        "Opmaak statistieken wachtpost (formaat CSV) bezig voor de periode van voor {{end}}",
      PMGAGENDA:
        "Opmaak statistieken afspraken voor wachtpost bezig voor de periode van {{start}} tot {{end}}",
      PMGAGENDACSV:
        "Opmaak statistieken afspraken wachtpost (formaat CSV) bezig voor de periode van {{start}} tot {{end}}",
    },
    LOADING_ACTIVE_PATIENTS: "Actieve patiënten laden",
    FETCHING_CONTACTS: "Contacten ophalen",
    FETCHING_PATIENTS: "Patiënten ophalen",
    FETCHING_AGENDAS: "Afspraken ophalen",
    GENERATING_DATA: "Gegevens genereren",
    FETCHING_INSURANCE_DATA_FOR_BIM:
      "Ophalen verzekeringsgegevens voor RVV-statistieken",
    FETCHING_DMG_DATA: "Ophalen GMD-gegevens",
    FETCHING_ACTIVE_PATIENTS: "Ophalen gegevens actieve patiënten",
    FETCHING_COLON_CANCER_PREVENTION_DATA:
      "Ophalen gegevens voor aantallen darmkankerscreening",
    FETCHING_MAMMOGRAPHY_PREVENTION_fDATA:
      "Ophalen gegevens voor aantallen screenings mammografie",
    FETCHING_INFLUENZA_PREVENTION_DATA:
      "Ophalen gegevens aantallen griepvaccins",
    TREATMENT_INCLUSION: "Beginbehandeling",
    TREATMENT_CURRENT: "Huidige behandeling",
    PMG_SHEET: "Wachtpost",
    PATIENT_ID: "Patiënt id",
    AGENDA_NAME: "Agenda",
    CREATION_WEEKDAY: "Dag",
    CREATION_DATE: "Opmaakdatum",
    CONTACT_TIME: "Uur",
    PATIENT_DATE_OF_BIRTH: "Geboortedatum",
    PATIENT_SEX: "Geslacht van de patiënt",
    CONSULTATION_TYPE: "Type consultatie",
    PATIENT_ORIENTATION: "Oriëntatie patiënt",
    PATIENT_POSTAL_CODE: "Postnummer",
    MEDICAL_LOCATION_NAME: "Wachtpost Locatie",
    UNKNOWN_DATE_OF_BIRTH: "Ontbrekende informatie",
    PATIENT_ACT: "Fee-for-service-activa",
    PATIENT_FORFAIT: "Activa bij het pakket",
    CONSULT: "Raadpleging",
    HOME_VISIT: "Huisbezoek",
    ELECTRONIQUE_CONSULT: "Elektronische raadpleging",
    TOTAL: "Totaal",
    GROUP_BY_SPECIALITY: "Aantal contacten per specialisme",
    NUMBER_OF_AMU_PATIENT: "Aantal AMU-patiënten",
    PATIENT_AMU: "AMU-patiënten",
    OTHER_PATIENT: "Andere patiënten",
  },
  EXPORT_COUNTRY_AND_NATIONALITY_MIGRATION: {
    PATIENT_ISO_CODE: "Patiënten wiens land handmatig moet worden gewijzigd",
    FIRSTNAME: "Voornaam",
    LASTNAME: "Naam",
    NISS: "Insz",
    EXTERNALID: "Externe identificatie",
    ADDRESS: "Adres",
    BAD_ENCODE_COUNTRY: "Land te wijzigen",
    BAD_ENCODE_NATIONALITY: "Nationaliteit te wijzigen",
    EXPORT_COUNTRY_AND_NATIONALITY_MIGRATION: "pays_nationalit_a_corriger",
    ADDRESS_NUMBER: "Adres nr.",
  },
  "patients-pmf": {
    IMPORT_SUFFIX: "Importeren PMF",
    PMF_LEFT: "Aantal resterende bestanden :",
    IMPORT: {
      STARTED: "Er wordt een patiëntendossier geïmporteerd.",
      FINISHED: "Het importeren van een patiëntendossier is voltooid.",
    },
    MULTIPLE_IMPORT: {
      STARTED: "Er wordt geïmporteerd van meerdere patiëntendossiers.",
      FINISHED: "Het importeren van meerdere patiëntendossiers is voltooid.",
    },
    EXPORT: {
      STARTED: "Er wordt een export van patiëntendossiers uitgevoerd.",
      FINISHED: "De export van een patiëntendossier is voltooid.",
    },
    MULTIPLE_EXPORT: {
      STARTED:
        "De export van verschillende patiëntendossiers wordt uitgevoerd.",
      FINISHED: "De export van verschillende patiëntendossiers is voltooid.",
    },
    LOGS_DIALOG: {
      TITLE: "Samenvatting van fouten",
      BTN_CLOSE_LABEL: "Oke",
      DOWNLOAD_LOGS: "Download alle logboeken",
    },
  },
  "mailing-dialog": {
    CLOSE: "Sluiten",
    SEND_EMAIL: "E-mail versturen",
    TITLE: "E-mail versturen",
    NAME_OF_SENDER: "Naam van de afzender",
    SEND_FROM_EMAIL_ADDRESS: "Verzenden vanaf het e-mailadres",
    COMPOSE_EDIT_BODY_CONTENT: "Tekst opmaken/aanpassen",
    EMAIL_BODY: "Inhoud e-mail",
    SUBJECT: "Onderwerp",
    SEND_TO_EMAIL_ADDRESS: "E-mail van bestemmeling",
    ATTACHMENTS: "Bijlagen",
    ERROR_SENDING: "Fout bij het versturen van de e-mail",
    SUCCESS_SENDING: "E-mail met succes verstuurd",
    ONLY_AVAILABLE_ON_DESKTOP_APP: "Enkel beschikbaar op computer",
  },
  "widget-stats": {
    TITLE: "Statistieken",
    DMG_COUNT: "Aantal GMD die mij toebehoren",
    SUMEHR_SENT_COUNT: "Aantal verstuurde Sumehrs",
    DMG_WITH_SUMEHR_SENT_COUNT:
      "Aantal GMD die mij toebehoren en waar ik een sumehr voor gestuurd heb",
    WARNING:
      "De hier voorgestelde cijfers gaan enkel over de patiënten die in de huidige gegevenslijst zijn opgenomen en kunnen verschillen van de officiële telling van eHealth.",
    REFRESH: "Vernieuwen",
  },
  "widget-pending-prescriptions": {
    TITLE: "Voorschriften in behandeling",
    OPEN_PRESCRIPTION: "Voorschrit openen ",
    MY_PENDING_PRESCRIPTIONS: "Mijn voorschriften in behandeling",
    ALL_PENDING_PRESCRIPTIONS: "Alle voorschriften in behandeling",
  },
  "widget-appointments": {
    TITLE: "Volgende afspraken van {{name}}",
  },
  "dashboard-management": {
    TITLE: "Dashboardconfiguratie",
    STATS: "Statistieken",
    APPOINTMENTS: "Afspraak",
    VISIBLE_WIDGETS: "Keuze widgets",
    PENDING_PRESCRIPTIONS: "Voorschriften in behandeling",
  },
  "eform-service": {
    ERROR: {
      MISSING_CONFIGURATION: "Geen enkele configuratie van EForm beschikbaar",
      AUTHENTICATION_ERROR: "Onmogelijk om verbinding te maken met eForm",
    },
    MESSAGE: {
      SEE_ATTACHMENT: "Zie bijlage.",
    },
  },
  "eform-component": {
    EHEALTH_CONNECTION: "Verbinding eHealth",
    EFORM_CONNECTION: "Verbinding eForm",
    EFORM_CONNECTION_ERROR: "Verbinding eForm onmogelijk",
    EHEALTH_CONNECTION_REQUIRED: "Verbinding eHealth est necessaire",
    LOADING_FORM_DEFINITION: "Download van de referentie van het formulier",
    LOADING_PATIENT_DATA: "Download van de patiëntgegevens",
    LOADING_HANDICARE_FORM: "Opmaak van het formulier",
    LOADING_HANDICARE_FORM_CONTENT: "Vooraf invullen van het formulier",
    SELECT_FILES: "Bijlagen toevoegen",
    SELECT_FILES_DONE: "Selectie valideren",
    SELECT_RECIPIENTS: "Bestemmelingen toevoegen",
    SELECT_RECIPIENTS_DONE: "Selectie valideren",
    CANCEL: "Annuleren",
    SEND: "Verzenden",
    PRINTANDSEND: "Verzenden en afdrukken",
    STATUS: {
      LOADING: "Download",
      CONNECTED: "Verbonden",
      ERROR: "Fout",
    },
    ERROR: {
      DATE_NOT_ALLOWED:
        "U mag uw statistieken enkel tussen vrijdag 12u en maandag 12u versturen.",
      FORM_GENERATION_VALIDATION_ERROR:
        "Gelieve de verplichte velden in te vullen en in te dienen",
      FORM_GENERATION_ERROR:
        "Er heeft zich een fout voorgedaan bij het genereren van het document. Gelieve opnieuw te proberen of neem contact op met support als het probleem blijft duren",
      FORM_SENDING_ERROR:
        "Het e-form kon niet worden verzonden vanwege een probleem op een healtConnect server. Gelieve opnieuw te proberen",
      MISSING_RECIPIENT: "Opgepast, de lijst met bestemmingen is leeg",
      FILE_LIMIT_WARNING:
        "Opgepast, u kunt maximum 10 mappen toevoegen waarbij het volume niet groter mag zijn dan 9MB",
      NO_MORE_FILE_ALLOWED:
        "Het toevoegen van 10 mappen of de limiet van 9MB zorgt ervoor dat u geen nieuwe bijlagen kunt toevoegen",
      GET_PATIENTS: "Fout bij het ophalen van patiënten. Probeer het opnieuw.",
    },
    INPUT_STATISTICS: "Statistieken",
    STATS: {
      PROCESSING_PATIENTS: "berekening patiëntengroep {{chunk}} op {{total}}",
      LOADING_PATIENTS: "patiënten laden ...",
      VACCINATION_BAROMETER: "“Vaccinatie barometer",
      ACTIVE_PATIENTS: "Actieve patiënten",
      ARI_PATIENTS: "Aantal ARI patiënten",
      ILI_PATIENTS: "Aantal ILI patiënten",
      COVID_SUSPECT_PATIENTS: "Aantal COVID-19 verdachten",
      A77_PATIENTS: "Aantal A77 patiënten",
      COVID_PATIENTS: "Aantal COVID patiënten",
      RATIO_CODED_DIAGNOSES: "Procentueel gecodeerd",
      PATIENTS_ABOVE_65: "Patiënten méér dan 65 oud",
      PATIENTS_45_TO_65_WITH_RISK: "Risicopatiënten 45 tot 64 jaar oud",
      PATIENTS_18_TO_44_WITH_RISK: "Risicopatiënten 18 tot 44 jaar oud",
      PATIENTS_45_TO_64_NO_RISK: "Niet-risicopatiënten 45 to 64 jaar oud",
      PATIENTS_18_TO_44_NO_RISK: "Niet-risicopatiënten 18 tot 44 jaar oud",
      PATIENTS_INCREASED_COMPENSATION:
        "Patiënten met verhoogde tegemoetkoming (VT)",
      PATIENTS_DIABETES: "Diabetische patiënten",
      PATIENTS_KIDNEY_CONDITION: "Patiënten met nierziekten",
      PATIENTS_LUNG_CONDITION: "Patiënten met longziekten",
      PATIENTS_LIVER_CONDITION: "Patiënten met leverziekten",
      PATIENTS_CARDIOVASCULAR_CONDITION:
        "Patiënten met cardiovasculaire ziekten",
      PATIENTS_OBESITY: "Zwaarlijvige patiënten",
      PATIENTS_NEUROLOGICAL_CONDITION: "Patiënten met neurologische ziekten",
      PATIENTS_CANCER: "Patiënten met kanker",
    },
    SELECTION: {
      TITLE: " Dagelijkse statistieken voor:",
      TODAY: "Vandaag",
      YESTERDAY: "Gisteren",
      TOOLTIP: "Het e-form van gisteren kan pas tot 10 uur worden ingediend.",
    },
    MESSAGE: {
      BUILDING_STATISTICS: "Berekening ...",
      ACTIVE_PATIENTS: "Ophalen van de actieve patiënten ...",
      ERROR_ACTIVE_PATIENTS:
        "Fout: Contacten van de laatste twee jaar konden niet worden opgehaald.",
      MODIFYING_USER_PROPERTIES:
        "Wijzigen van de eigenschappen van gebruiker ...",
      UNABLE_TO_MODIFY_PROPERTIES:
        "Fout: Eigenschappen van de gebruiker konden niet worden gewijzigd.",
      UNABLE_TO_REFRESH:
        "Fout: Kan niet worden ververst: log opnieuw in voor wijzigingen om een effect te krijgen.",
      BUILDING_DAILY_STATISTICS: "Berekenen van de dagelijkse statistieken ...",
      FETCHING_CONTACTS: "Ophalen van de dagelijkse contacten ...",
      UNABLE_TO_FETCH_DAILY_CONTACTS:
        "Fout: Kon de dagelijkse contacten niet ophalen.",
      COUNTING_PATIENTS: "Patiënten tellen...",
      DIAGNOSTIC_RATIO: "Berekenen van de ratio van diagnoses ...",
    },
  },
  "recipient-selector": {
    SEARCH_CREATE: "Bestemmeling toevoegen",
    RECIPIENT_LIST: "Lijst bestemmelingen",
    DELETE_RECIPIENT: "Bestemmeling van de lijst verwijderen",
  },
  "eform-services": {
    LABEL: {
      GENERIC: "eForm",
      HANDICARE: "Handicare",
      COVID_LAB_REQUEST: "Covid-19: Aanvraag voor laboratoriumanalyse",
      COVID_FALSE_NEGATIVE_OVERRIDE:
        "Covid-19: Bevestiging ondanks vals negatief",
      COVID_RAPID_TEST_RESULT: "Covid-19: Verzenden resultaat sneltest",
    },
  },
  prescriptions: {
    SAMV2: "SAMv2",
    NEW: "Nieuwe",
    "close-confirmation": {
      TITLE: "Bevestiging",
      WARNING: "Wilt u dit venster sluiten en al uw ingave annuleren?",
      YES: "Ja",
      NO: "Nee",
    },
    tab: {
      MEDICATION: "Actieve medicatie",
      TREATMENT_HISTORY: "Historiek",
      CHAPTER_IV: "Hoofdstuk IV",
    },
    SEARCH: {
      RESULTS: "Resultaten",
      LABEL: "R/",
      ALTERNATIVES: {
        LABEL: "Alternatieven",
        CHEAPEST: "Goedkoopste",
        ALL_PRICES: "Alle",
        ALL_PHARMACEUTICAL_FORMS: "Alle",
        VMP_GROUP_NAME: "Groepsnaam",
      },
      CHOOSE_MEDICAMENT: "Kies een geneesmiddel",
      SEARCH_PLACEHOLDER: "Zoeken",
      PRICE_EVALUATION: {
        RECOMMENDED: "Goedkoop",
        ALTERNATIVE: "Terugbetaalbaar",
      },
      LISTING_HEADERS: {
        NAME: "Naam",
        STRENGTH: "Dosering",
        PACK_SIZE: "Verpakkingsgrootte",
        PHARMACEUTICAL_FORM: "Farmaceutische vorm",
        PUBLIC_PRICE: "Prijs",
        CO_PAYMENT: "Remgeld",
        PUBLIC_PRICE_INDEX: "Publieke prijsindex",
        PRICE_INDEX_SAMV2: "Prijsindex Samv2",
        CO_PAYMENT_INDEX: "Index remgeld",
        PRESCRIPTION: "Voorschrift",
        REIMBURSEMENT: "Terugbetaling",
        COMMERCIALIZATION: "Commercialisatie",
        DELIVERY_MODUS: "Voorwaarden voor afleveren",
      },
      ORDER_BY: {
        CHEAP: "Goedkoop",
        PRICE_INDEX_SAMV2: "inhoudsopgave",
        FAVORITES: "Favorieten",
      },
      PRESCRIPTION_REQUIRED: "R",
      NOT_APPLICABLE: "n.a.",
      DELIVERY_CONDITIONS: "Voorwaarden voor het afleveren",
      PRESCRIPTION_CONDITIONS: "Voorwaarden voor het voorschrijven",
      PRESCRIPTION_CONDITIONS_NOT_APPLICABLE: "Niet van toepassing",
      RECOMMANDATION_EXPLANATION: {
        END_COMMERCIALIZATION: "Voorschrijfbaar tot {{date}}.",
        START_COMMERCIALIZATION: "Voorschrijfbaar vanaf {{date}}.",
        END_REIMBURSEMENT: "Terugbetaald tot {{date}}.",
        REASON: "Reden:",
        IMPACT: "Impact:",
        ADDITIONAL_INFO: "Extra informatie:",
        UNAVAILABLE: "Niet beschikbaar:",
        ANCIENT_NAMES: "Vorige namen:",
      },
      REIMBURSEMENT_CATEGORY_CODE_EXPLANATION: {
        A: "Levensnoodzakelijke specialiteiten (volledige terugbetaling)",
        B: "Therapeutisch belangrijke farmaceutische specialiteiten (grotendeels terugbetaald) ",
        C: "Geneesmiddelen bestemd voor symptomatische behandeling (slechts gedeeltelijk terugbetaald, in afnemende mate van C over Cs tot Cx)",
        Cs: "Geneesmiddelen bestemd voor symptomatische behandeling (slechts gedeeltelijk terugbetaald, in afnemende mate van C over Cs tot Cx)",
        Cx: "Geneesmiddelen bestemd voor symptomatische behandeling (slechts gedeeltelijk terugbetaald, in afnemende mate van C over Cs tot Cx)",
        Fa: "Alleen in ziekenhuismilieu gebruikt (forfaitair bedrag voorzien ivoor terugbetaling)",
        Fb: "Alleen in ziekenhuismilieu gebruikt (forfaitair bedrag voorzien ivoor terugbetaling)",
        J: "Voor anticonceptiva betekent dit dat er speciale tegemoetkoming is in de prijs bij vrouwen jonger dan 25 jaar en, ongeacht hun leeftijd, bij vrouwen die in aanmerking komen voor “verhoogde tegemoetkoming”. Voor de morning after pill betekent de letter J dat er speciale tegemoetkoming is bij alle vrouwen, ongeacht de leeftijd (Koninklijk Besluit 16-09-2013)",
      },
    },
    TYPES: {
      SPECIALTY: "Specialiteit",
      ATC: "ATC",
      MAP: "Magistraal",
      DCI: "Stofnaam",
      NON_MEDICINAL_PRODUCT: "Niet-geneesmiddelen",
      PRIVATE: "Persoonlijke",
      FREE_TEXT: "Vrij",
    },
    ATC: {
      LISTING_TITLE: "ATC-klassen",
      VALIDATE: "Valideer",
    },
    PHARMACEUTICAL_COMPOUNDING: {
      LISTING_HEADERS: {
        NAME: "Naam",
        FORMULA: "Formule",
        FORM: "Vorm",
        QUANTITY: "Hoeveelheid",
        CLASS: "Klasse",
      },
      NAME: "Naam",
      FORMULA: "R/",
      FORM: "Pf/",
      QUANTITY: "Dt/",
      UNIT: "Eenheid",
    },
    SAVE: "Opslaan",
    CANCEL: "Wissen",
    PRESCRIBE: "Voorschrijven",
    PRINT: "Afdrukken",
    EMAIL: "Email",
    PRINT_POSOLOGY: "Medicatieschema afdrukken",
    SAVE_POSOLOGY: "Medicatieschema downloaden",
    SELECT: "Selecteren",
    CLOSE_WARNING: "Wilt u dit venster sluiten en al uw ingave annuleren?",
    ADVERSE_EFFECTS_REPORTING: "Melden van bijwerkingen",
    DCI_NON_PRESCRIBABLE:
      "Het voorschrijven op stofnaam wordt niet aanbevolen. Reden: ",
    posology: {
      POSOLOGY: "S/",
      BT: "Bt",
      QUANTITY: "Dt/",
      PHARMACEUTICAL_FORM: "Vorm",
      JEUN: "Nuchter",
      MORNING: "Ontbijt",
      "10H": "10u",
      LUNCH: "Middag",
      "16H": "16u",
      EVENING: "Avond",
      NIGHT: "Nacht",
      REPAS: "Maaltijd",
      BEFORE: "Voor",
      DURING: "Tijdens",
      AFTER: "Na",
      "TOUS LES": "Alle",
      JOURS: "Dagen",
      CHRONIQUE: "Chronisch",
      ONESHOT: "Een keer",
      FREEPOSOLOGY: "Vrije dosering",
      TEMPLATE_NAME: "Standaard doseringsschema",
      SAVE_TEMPLATE: "Opslaan doseringsschema",
      TEMPLATE_DELETE_CONFIRMATION: "Bevestiging van de verwijdering",
      TEMPLATE_DELETE_WARNING: "Wilt u deze doseringsschema verwijderen?",
      CONFIRMATION_NO: "Nee",
      CONFIRMATION_YES: "Ja",
      ROUTE_OF_ADMINISTRATION: "Toediening",
      THIRD_PARTY_CHARGE: "3BT",
      FOR: "voor",
      periodType: {
        day: "Dagen",
        week: "Weken",
        month: "Maand",
        year: "Jaren",
      },
      UNITIES: "Eenheden",
    },
    remark: {
      notes: {
        DIABETES_CARE_PATH: "Zorgtraject diabetes",
        RENAL_INSUFFICIANCE_CARE_PATH: "Zorgtraject nierfalen",
        DIABETES_CONVENTION: "Diabetes conventie",
        THIRD_PARTY_CHARGE: "Derde betaler toepasbaar",
        THIRD_PARTY_NO_CHARGE: "Derde betaler niet toepasbaar",
        ON_DEMAND: "Op aanvraag",
        NO_IMPROVEMENTS_AFTER_DAYS:
          "Als er na een paar dagen geen verbetering is",
      },
      REMARK: "Opmerkingen",
      NOTE: "Nota",
    },
    PRESCRIPTION: "Voorschrift",
    ACUTE: "Acute behandeling",
    CHRONIC: "Chronische behandeling",
    START_DATE: "Begindatum",
    ACTIONS: {
      EDIT: "Bewerk",
      STOP: "Stoppen",
      HISTORY: "Historiek",
      NOTICE: "Bijsluiter",
      RCP: "SKP",
      CBIP: "BCFI",
      INFO_HINT: "Informatie",
      SUSP_HINT: "Een medicijn opschorten",
      STOP_HINT: "Een medicijn stoppen {{date}} {{reason}}",
      EDIT_HINT: "Een medicijn bewerken",
      WADA_INFO: "Informatie WADA",
    },
    POSOLOGY_LISTING: {
      JEUN: "nucht.",
      MORNING: "8",
      "10H": "10",
      LUNCH: "12",
      "16H": "16",
      EVENING: "20",
      NIGHT: "22",
      PERIOD_TYPE: {
        DAY: "D",
        WEEK: "W",
        MONTH: "M",
        YEAR: "J",
      },
    },
    ADDITIONAL_INFO: {
      CATEGORY: "Categorie",
      WADA: "WADA (World Anti-Doping Agency)",
      ADDITIONAL_MONITORING: "Aanvullende monitoring",
      RMA: "RMA (Risk Minimisation Activites)",
      DHPC: "DHPC (Dear Healthcare Professional Communication)",
      CBIP: "BCFI",
      NOTICE: "Bijsluiter",
      RCP: "SKP",
      UNAVAILABLE: "Niet beschikbaar",
      MOTIVE: "Reden",
      IMPACT: "Impact",
      EXPIRED: "Vervallen",
    },
    PRESCRIPTIONS_LISTING: {
      TITLE: "Te verzenden voorschriften",
      TITLE_EXTRA_INFO:
        "Met de “dematerialisatie” wordt ieder geneesmiddel via een apart voorschrift naar Recip-e verzonden.",
      LABEL: "Voorschriften",
      PRACTITIONER: "Voorschrijver",
      DESCRIPTION: "R/",
      POSOLOGY: "S/",
      QUANTITY: "Dt/",
      UNITIES: "Eenheden",
      FORM: "Pf/",
      END_VALIDITY: "Einddatum van de uitvoerbaarheid",
      PRINT_WARNING:
        "Opgelet : het order nr. {{number}} is lang en wordt mogelijk slecht afgedrukt.",
      POSOLOGY_DESCRIPTION: {
        REGIMEN: {
          A_JEUN: "Nuchter",
          A_MORNING: "'s ochtends",
          A_10H: "om 10u",
          A_LUNCH: "'s middags",
          A_16H: "om 16u",
          A_EVENING: "'s avonds",
          A_NIGHT: "vóór het slapengaan",
        },
        MEAL: {
          BEFORE: "voor maaltijden",
          DURING: "tijdens maaltijden",
          AFTER: "na de maaltijden",
        },
        FREQUENCY: {
          EVERY_ONE: {
            DAY: "elke dag",
            WEEK: "elke week",
            MONTH: "elke maand",
            YEAR: "elk jaar",
          },
          EVERY_N: {
            DAY: "elke {{n}} dagen",
            WEEK: "elke {{n}} weken",
            MONTH: "elke {{n}} maand",
            YEAR: "elke {{n}} jaar",
          },
        },
        ONESHOT: "(Eénmalig)",
      },
      VISION_LOCKED_INFO:
        "Vertrouwelijk: enkel zichtbaar voor de apotheek waar de patiënt het voorschrift toont.",
      VISION_OPEN_INFO:
        "Niet vertrouwelijk: zichtbaar voor alle apotheken waar de patiënt zich identificeert.",
      NAME_AND_POSOLOGY: "Geneesmiddel en posologie",
      EDIT: "Wijzigen",
      DECREASE_QUANTITY: "Verminderen met 1",
      REMOVE: "Verwijderen",
    },
    ERROR_SAVE_CONTACT:
      "Een fout is opgetreden bij het opslaan van het contact.",
    PENDING_PRESCRIPTION: {
      SEARCH_CAREGIVER: "Een zorgverstrekker zoeken",
      SAVED: "De voorschrift werd opgeslaan en is in behandeling",
      CHOOSE_PRESCRIBER: "Een voorschrijver kiezen",
    },
    DCI: {
      ALTERNATIVES: "Alternatieven specialiteiten",
    },
    STOP_MEDICATION_TREATMENT: {
      TITLE: "Medicatie stoppen",
      MOTIVE: "Reden",
      COMMENT: "Commentaar",
      OPTIONAL: "Optioneel",
      SAVE: "Opslaan",
      CANCEL: "Annuleren",
      UNDO: "Heractiveren",
      REASONS: {
        ALLERGY: "Allergie",
        INTOLERANCE: "Intolerantie",
        UNEXPECTED_SIDE_EFFECTS: "Bijwerkingen",
        INEFFICIENT: "Inefficiënt",
        REFUSAL_PATIENT: "Weigering van de patiënt",
        END_OF_TREATMENT: "Einde behandeling",
        PACKAGE_CHANGE: "Verandering van verpakking",
        DRUG_NOT_AVAILABLE: "Geneesmiddel niet meer beschikbaar",
        OTHER: "Andere",
      },
      SELECT_CLASS: "Uitbreiden tot ATC-classe",
      ATC_CLASS: "ATC-classe",
      END_DATE: "Einddatum",
      LAST_MONTH_WARNING:
        "Dit is de laatste maand dat deze behandeling actief is. Het zal worden gestopt op {{date}}.",
      LAST_MONTH_WARNING_FOR_ONE:
        "Dit is de laatste maand dat deze behandeling actief is: ",
      LAST_MONTH_WARNING_FOR_MANY:
        "Het is de laatste maand dat deze behandelingen actief zijn: ",
      LAST_DAY_WARNING_FOR_ONE:
        "Het is de laatste dag dat deze behandeling actief is. Ze kan niet meer worden verlengd.",
      LAST_DAY_WARNING_FOR_MANY:
        "Het is de laatste dag dat deze behandelingen actief zijn. Zij kunnen niet meer worden verlengd",
    },
    SUSPEND_MEDICATION_TREATMENT: {
      TITLE: "Een behandeling opschorten",
      MOTIVE: "Reden",
      COMMENT: "Commentaar",
      OPTIONAL: "Optioneel",
      SAVE: "Opslaan",
      CANCEL: "Annuleren",
      START_DATE: "Startdatum",
      END_DATE: "Einddatum",
      ADD: "Opschorting toevoegen",
      REMOVE: "Wissen",
      SUSPENSIONS: "Opschortingen",
    },
    UNAVAILABLE_MEDICATION: {
      TITLE: "{{medicationName}} is niet langer beschikbaar",
      ALTERNATIVES: "ALTERNATIEVEN",
      CANCEL: "Annuleren",
      SELECT: "Selecteren",
    },
    history: {
      types: {
        PRESCRIPTION: "Voorschriften",
        TREATMENT: "Medicatie",
      },
    },
    treatmentHistory: {
      groups: {
        HISTORY: "Historiek",
        UNSPECIFIED: "Onbepaald",
      },
      DATE: "Datum",
      HEALTH_ELEMENT: "Zorgelement",
      BEGIN: "Begin",
      END: "Einde",
      TREATMENT: "Medicatie",
      POSOLOGY: "Doseringsschema",
      MOTIF_STOP: "Reden voor stopzetting",
      PRESCRIPTOR: "Voorschrijver",
      CNK: "CNK code",
      UNSPECIFIED: "Onbepaald",
      BOXES: "{{boxes}} dozen",
      dateFilter: {
        PERIOD: "Periode",
        LAST_MONTHS: "De afgelopen {{months}} maanden",
        INDEFINITE: "Geen filter",
        BEFORE_YEAR: "Vóór {{year}}",
      },
      atc_filter: {
        ATC_CLASS: "ATC klasse",
        ALL_CLASSES: "Alle klassen",
      },
      "text-filter": {
        SEARCH: "Zoeken",
      },
      MEDICATION_LINES: "Medicatie lijnen",
      OUTDATED: "Vervallen",
      NOTE: "Notitie",
      CHRONICAL: "Chronische ziekte",
      MODIFIED: "Gewijzigd",
      PROLONGATED: "Verlengd",
      STOPPED: "Gestopt",
    },
    RECIPE: {
      NOT_APPLICABLE: "Niet van toepassing",
      NO_PHONE_WARNING:
        "Opgelet, het voorschrift zal niet elektronisch zijn ! Om een ​​elektronisch voorschrift te kunnen versturen, moet u een telefoonnummer en type in uw profiel invullen. Pas dit  aan door onderaan links van het scherm op uw initialen te klikken",
      NO_UNIQUE_CODE:
        "Let op, het geneesmiddel bevat geen unieke identificatiecode",
      ERROR_UPLOAD: "Het voorschrift kon niet naar Recip-e worden verzonden",
      SUCCESSFUL_UPLOAD: "Voorschrift verzonden naar Recip-e.",
      INVALID_TREATMENTS:
        "De startdatum van de behandeling ({{treatments}}) is na de vervaldatum van het recept",
      FAILED_TO_SEND_ALL:
        "Voor sommige geneesmiddelen is het elektronisch voorschrift niet gelukt (probleem met internet of eHealth);  voor deze werd een papieren voorschrift gemaakt",
    },
    EMAIL_RECIPE: {
      SUBJECT: "Uw voorschrift",
      BODY: "Beste,\nHet door u aangevraagde voorschrift is gegenereerd. Een kopie van het voorschrift is als bijlage bij deze e-mail gevoegd. U kunt met uw identiteitskaart of de streepjescode op het voorschrift naar de apotheek van uw keuze gaan.\nHet is niet nodig dit document af te drukken.\nMet vriendelijke groeten,\n",
    },
    PDF_PRESCRIPTION: {
      EXPIRATION_DATE: "Einddatum van de uitvoerbaarheid",
      SSIN: "INSZ",
      NIHII: "RIZIV Nr",
      BOXES: "Doos(en)",
      UNITIES: "Eenheden",
      QUANTITY: "Dt/",
      SHAPE: "Pf/",
      FORMULA: "R/",
      POSOLOGY: "S/",
      TREATMENT_START_DATE: "Startdatum van de behandeling",
      PRINT_ERROR: "Fout bij het afdrukken",
      ONLINE: {
        PROOF: "ELEKTRONISCH BEWIJS VAN VOORSCHRIFT",
        INFO_TOP:
          "Overhandig dit document aan uw apotheker om de barcode te scannen en u de voorgeschreven medicatie af te leveren",
        PRACTITIONER: "Voorschrijver",
        PATIENT: "Begunstigde",
        CONTENT: "Inhoud van het elektronisch voorschrift",
        INFO_BOTTOM:
          "Waarschuwing: er wordt geen rekening gehouden met handgeschreven aanvullingen op dit document",
        DATE: "Datum",
      },
      OFFLINE: {
        PRACTITIONER: "Naam en voornaam van voorschrijver",
        BIRTH_DATE: "Geboortedatum",
        PRACTITIONER_FILL: "IN TE VULLEN DOOR DE VOORSCHRIJVER:",
        PATIENT: "Naam en voornaam\n van de begunstigde",
        VIGNETTE: "Voorbehouden voor de sticker\nvan de verpakking",
        STAMP: "Stempel van de voorschrijver",
        DATE_SIGNATURE: "Datum en handtekening\n van de voorschrijver",
        MEDICATION:
          "VOORSCHRIJVING VAN GENEESMIDDELEN VAN TOEPASSING VANAF 1 november 2019",
      },
    },
    NON_MEDICINAL_PRODUCTS: {
      LISTING_HEADERS: {
        NAME: "Naam",
        PRODUCER: "Producent",
      },
    },
    prescriptionHistory: {
      TITLE: "Voorschriften",
      headers: {
        START: "Voorgeschreven op: ",
        END: "Einddatum: ",
        PRESCRIBER: "Voorschrijver: ",
        ERECIPE: "Recip-e: ",
      },
      QUANTITY: "Dt/",
      CNK: "CNK: ",
      REPRESCRIBE: "Herhalen",
      SPECIALITY: "Specialiteit",
      SPECIALITY_FILTER: "Filter op specialiteit",
      NOT_SENT: "Niet verzonden",
      MEDICATION_UNAVAILABLE:
        "Dit geneesmiddel is niet langer beschikbaar. Pas deze aan in de lijst met geneesmiddelen",
      CHECK_AVAILABILITY: "Beschikbaar",
      CONFIRM: "Bevestigen",
      CANCEL: "Annuleren",
      status: {
        NOT_DELIVERED: "Niet afgeleverd (verzonden)",
        DELIVERED: "Afgeleverd",
        IN_PROCESS: "In behandeling",
        REVOKED: "Ingetrokken",
        EXPIRED: "Verstreken",
        ARCHIVED: "Gearchiveerd",
      },
      CANCEL_SUCCESS: "Annulatie verstuurd naar Recip-e",
      ERROR_CANCEL_SAVE:
        "Er is een fout gebeurd bij het registreren van de annulatie",
      feedback: {
        TITLE: "Terugkoppeling",
        DATE: "Datum: ",
        CONTENT: "Tekstinhoud: ",
        RETRIEVE_ERROR: "TODO: wait translation --- for all above nl",
        SEND_ERROR: "TODO: wait translation --- for all above nl",
        FEEDBACK_RESPONSE: "Response: nl",
        RESPONSE_TITLE: "Response nl",
        SEND: "Envoyer nl",
        CANCEL: "Annuler nl",
        SENT_BY_HCP: "Sent as response: nl",
      },
      VISION_UPDATE_SUCCESS:
        "Zichtbaarheid voor de apotheken (“Visi flag”) aangepast.",
      VISION_UPDATE_ERROR:
        "Fout bij het aanpassen van de zichtbaarheid voor de apotheken (“Visi flag”).",
      VISION_LOCKED: "Vertrouwelijk",
      VISION_OPEN: "Niet vertrouwelijk",
    },
    filters: {
      START_DATE: "Van datum",
      END_DATE: "Tot datum",
    },
    FREE_TEXT_MEDICATION: {
      NAME: "Naam",
    },
    PRIVATE_MEDICATION: {
      LISTING_HEADERS: {
        NAME: "Naam",
        DOSAGE: "Dosering",
        PHARMACEUTICAL_FORM: "Het formulier",
        PACK_SIZE: "Verpakking",
      },
    },
    INDICATIONS: {
      LINK_INDICATION: "Subcontacten verbinden met een zorgelement",
      SEARCH: "Zoeken",
    },
    POSOLOGY_SCHEME_PRINT: {
      POSOLOGY: "Doseringsschema",
      TREATMENTS_OF: "Behandelingen van",
      PAGE: "Pagina",
      NAME: "Naam",
      REMARK: "Opmerking",
      END_DATE: "Einddatum",
      CHRONIC_TREATMENTS_DAILY: "Dagelijkse chronische medicatie",
      ACUTE_TREATMENTS_DAILY: "Tijdelijke dagelijkse medicatie",
      OTHER_FREQUENCY_TREATMENTS: "Medicatie met andere frequentie",
      REGIMENS: {
        JEUN: "Nuchter",
        MORNING: "Ochtend",
        "10H": "10u",
        LUNCH: "Middag",
        "16H": "16u",
        EVENING: "Avond",
        NIGHT: "Nacht",
      },
    },
    ALLERGY_INTOLERANCE_ALERT: {
      CHECK_REASON:
        "Uw patiënt is allergisch (of intolerant) voor deze klasse medicatie vanwege de volgende reden:",
      PRESCRIBE_ANYWAY: "Alsnog voorschrijven",
      NOT_PRESCRIBE: "Niet voorschrijven",
      ADD: "Toevoegen",
      NOT_ADD: "Nie toevoegen",
    },
    MEDICATION_NOT_PRESCRIBABLE: {
      OK: "Ok",
      SINGLE_EXTEND:
        "Dit geneesmiddel is niet correct gecodeerd of is niet meer op de markt en kan niet meer voorgeschreven worden. Stop deze medicatie en zoek een equivalent geneesmiddel.",
      MULTIPLE_EXTEND:
        "Deze geneesmiddelen zijn niet meer op de markt en kunnen niet meer voorgeschreven worden. Stop ze en zoek equivalenten.",
    },
  },
  "admin-tools": {
    ADMIN_TOOLS: "MSAdmin",
    DB_INDEXATION_CHECKING: "Analyse van de indexatie",
    USER_HCP_CHECKING: "Analyse User/Hcp",
    HCP_GENERATOR: "Generator van Hcp",
    DB_SHARING: "Delen",
    DB_PARENTING: "Afstamming",
    PLEASE_LOG_IN:
      "Geef het wachtwoord in, valideer het admin wachtwoord om toegang te krijgen tot meer opties.",
    PASSWORD: "Wachtwoord",
    SUBMIT_PASSWORD: "Wachtwoord valideren",
    INVALID_PASSWORD: "Wachtwoord ongeldig",
    WELCOME_TITLE: "Welkom bij de administratietool van Medispring!",
    WELCOME_CONTENT:
      "Dit is de administratietool van Medispring. U hebt hier toegang tot verschillende tools voor monitoring, wijziging en onderhoud. Opgepast, sommige wijzigingen zijn onherroepbaar. Dit gebruik is dus voorbehouden aan het supportteam van Medispring.",
    WARNING: "Opgepast",
    INDEXATION: {
      DESCRIPTION: {
        TITLE: "Instructies",
        BODY: 'Via deze tool kunt u de huidige indexaties van de gegevensbank op gedetailleerde wijzes controleren. De knop "Synchroniseren" laat u toe om manueel te updaten en de dagelijkse updates van de corresponderende index te starten.',
        WARNING_BODY:
          "Al naargelang de grootte van de gegevensbank, kan een dagelijkse update zorgen voor lange indexaties. Voorzie deze updates dus op het einde van de dag/begin van de avond terwijl u niet werkt.",
      },
      SYNCHRONIZE: "Synchroniseren",
      SYNCHRONIZE_ALL: "Alles synchroniseren",
      ENTITY: "Entiteit",
      STATUS: "Statuut",
      PROGRESS: "Verder",
      ONGOING: "Bezig",
      GLOBAL: "Globale indexatie: ",
    },
    USER_HCP: {
      DESCRIPTION: {
        TITLE: "Instructies",
        BODY: "Via dit scherm kunt u de gebruikers (met hun autoDelegation), hun HCP en hun hoofd-HCP controleren. Voor elke gebruiker vindt u ook hoeveel patiënten hem toegewezen zijn (gedeeld) via zijn HCP en/of zijn HCP-ouder. PS: Een groene lijn betekent dat het totaal van patiënten dat gedeeld is met de HCP-ouder (minstens) het geheel van de toegewezen patiënten (gedeeld) bevat dat toegewezen is aan de HCP van de overeenkomstige gebruiker.",
        WARNING_BODY: "",
      },
      ANALYSIS: "Analyseren",
      CURRENT_USER: "Id van de huidige gebruiker",
      HCP_USER_COUNT: "Aantal gebruikers met HCP",
      BEST_PARENT_HCP: "Id van de belangrijkste HCP-ouder",
      UNKNOWN: "Onbekend",
      STATUS: {
        SINGLE_PARENT: "Een enkele HCP-ouder gevonden tussen alle gebruikers",
        MULTIPLE_PARENTS: "Meerdere HCP-ouder’s gevonden",
        PARTIAL_SINGLE_PARENT:
          "Een enkele HCP-ouder gevonden en gebruikers die er geen hebben",
        PARTIAL_MULTIPLE_PARENTS:
          "Meerdere HCP-ouders gevonden en gebruikers die er geen hebben",
        NO_PARENT: "Geen enkele HCP-ouder gevonden",
        UNKNOWN: "Analyse bezig ...",
      },
      USER_ID_DELEGATION: "Gebruikers-id, status en autoDelegation",
      USER_HCP_ID: "Id HCP",
      USER_PARENT_HCP_ID: "Id HCP-ouder",
      DELEGATION_STATUS: "Staat van de toewijzingen patiënten",
      HCP_PATIENT_COUNT: "# patiënten gedeeld met de HCP",
      PARENT_HCP_PATIENT_COUNT: "# patiënten gedeeld met de HCP-ouder",
      COMPLETE_PARENT_DELEGATIONS: "Alle patiënten zijn gedeeld met de ouder",
    },
    HCP_TOOL: {
      DESCRIPTION: {
        TITLE: "Instructies",
        BODY: "Via dit scherm kunt u automatisch of manueel een HCP genereren. Als het formulier ingevuld is, kunt u nakijken of het gegenereerde of gekozen ID nog niet gebruikt wordt. Bovendien hebt u toelating om de HCP te downloaden in formaat Json of rechtstreeks in de gegevensbank opslaan.",
      },
      FIRST_NAME: "Voornaam",
      LAST_NAME: "Familienaam",
      SSIN: "Burgerservicenummer",
      NIHII: "INAMI-nummer",
      HCP_ID: "ID",
      CRYPTED_AES: "Sleutel AES",
      PUBLIC_RSA: "Sleutel openbare RSA",
      CHECK_IF_ID_EXISTS: "ID valideren",
      DUMP_HCP: "Afbeelden",
      AUTOFILL: "Automatisch vullen",
      SAVE: "Opslaan",
    },
    HCP_PARENTING: {
      ALERT: {
        TITLE: "Opgepast",
        BODY: "Als u de verbindingslink met een HCP verandert, blijft het ID van de vorige ouder niet bewaard. Gelieve deze indien nodig te noteren",
      },
      DESCRIPTION: {
        TITLE: "Instructies",
        BODY: "Via dit scherm kunt u de link de familielink van een HCP met een gebruiker veranderen. De autoDelegation van de gebruiker zal ook aangepast worden.",
      },
      USER_ID: "Id gebruiker",
      PARENT_HCP_ID: "Id Hcp-ouder",
      LINK_PARENT: "Link opmaken",
      SUCCESS: "Link met succes opgemaakt",
    },
    HCP_SHARING: {
      DESCRIPTION: {
        TITLE: "Instructies",
        BODY: "Via dit scherm kunt u de patiënten (en bijhorende documenten) delen met de huidige gebruiker. Dit kan via de HCP van de gebruiker of vanaf zijn ouder (als die er is). Deze actie is niet gemakkelijk omkeerbaar maar kan verschillende keren na elkaar uitgevoerd worden.",
      },
      OWNER: "Id van de Hcp-eigenaar",
      DELEGATE_ID: "Id van de Hcp-afgevaardigde",
      PRIMARY_BTN: "Delen",
      ONGOING: "Bezig ...",
      ONGOING_SHARING: "Delen bezig",
      WARNING: "Opgepast",
      NO_HCP: "De huidige gebruiker is niet gelinkt aan een Hcp",
    },
  },
  licencing: {
    TITLE: "Licentie",
    ENTER_LICENCE_NUMBER: "Geef uw licentienummer in",
    IN_ORDER: "Licentie {{type}} in orde",
    PROVISIONALLY_ACTIVE: "Licentie {{type}} voorlopig actief",
    EXPIRED: "Licentie {{type}} vervallen",
    NOT_VALID: "Licentie {{type}} ongeldig",
    LICENCE: {
      OF: {
        GROUP: "van de groep",
        USER: "van de gebruiker",
      },
      ENUM: {
        GROUP: "Groepslicentie",
        USER: "Gebruikerslicentie",
        DEMO: "Proeflicentie",
      },
      YOUR: {
        GROUP: "Uw groepslicentie",
        USER: "Uw persoonlijke licentie",
      },
    },
    INVALID_LICENCE_DATES: "Licentiedata ongeldig.",
    ERROR_VALIDATING: "Fout bij de validatie van de licentie",
    ERROR_UPDATING_USER_LICENCE:
      "Fout bij het updaten van de gebruikerslicentie",
    END_DATE: "Einddatum",
    VALIDATE: "Valideren en updaten",
    UPDATE_USER_LICENCE_SUCCESS: "Gebruikerslicentie met succes geüpdatet",
    GROUP_LICENCE: "Groepslicentie",
    USER_LICENCE: "Gebruikerslicentie",
    STATUS: {
      STATUS: "Statuut",
      TYPE: "Type",
      ACTIVE: "Actief",
      EXPIRED: "Vervallen",
      NOT_VALID: "Ongeldig",
      PROVISIONALLY_ACTIVE: "Voorlopig actief",
    },
    NIHII: "",
    EXPIRATION_SOON: "Licentie vervallen",
    EXPIRED_ALREADY:
      "Uw licentie is vervallen. Medispring is enkel toegankelijk in alleen lezen-functie. Stuur een bericht naar licence@medispring.be om uw licentie te activeren.",
    EXPIRE_IN_DAYS_PROVISIONAL:
      "Uw licentie is vervallen sinds {{expirationDate}}. Binnen <b>{{daysLeft}}</b> dagen, zal Medispring enkel toegankelijk zijn in alleen lezen-functie. Stuur een bericht naar licence@medispring.be om uw licentie te activeren.",
    CONFIRM: "Bevestigen",
    LICENCE_HAS_EXPIRED:
      "Uw licentie is vervallen, gelieve uw licentie te vernieuwen via deze functie",
  },
  "private-medication-management": {
    TITLE: "Voeg een persoonlijk geneesmiddel toe",
    NAME: "Naam",
    DOSAGE: "Posologie",
    PHARMACEUTICALFORM: "Vorm",
    PACKSIZE: "Verpakking",
    NOTE: "Nota",
    RESET: "Annuleren",
    SAVE: "Opslaan",
    EDIT: "Wijzigen",
    EDIT_NOT_MINE:
      "Dit persoonlijk geneesmiddel is gemaakt door een andere gebruiker.",
    ERROR_SAVE: "Persoonlijk geneesmiddel werd niet opgeslaan",
    SAVE_SUCCESS: "Persoonlijk geneesmiddel opgeslaan",
    EDIT_SUCCESS: "Persoonlijk geneesmiddel bijgewerkt.",
    ERROR_GET: "Fout bij het ophalen van uw persoonlijke geneesmiddelen",
    DELETE: "Verwijderen",
    CONFIRM_DELETE: "Wilt u deze persoonlijke medicatie wijzigen?",
    YES: "Ja",
    NO: "Nee",
    DELETE_SUCCESS: "Persoonlijk medicatie gewist.",
    ERROR_DELETE: "Fout bij het wissen van de persoonlijke medicatie.",
  },
  DATE_PERIOD: {
    DAY: "Dag",
    WEEK: "Week",
    MONTH: "Maand",
    QUARTER: "Trimester",
    YEAR: "Jaar",
    CUSTOM: "Gepersonaliseerd",
  },
  "patient-hub-ms": {
    NO: "Nee",
    YES: "Ja",
    legend: "Legende",
    "hub-chron-med": "Chronische medicatie",
    "hub-sus-med": "Opgeschorte medicatie",
    "hub-med-mag": "Magistrale medicatie",
    "hub-med-dci": "Medicatie op stofnaam",
    "hub-one-shot": "Eenmalige medicatie",
    "endDate-short": "Einde",
    comment: "Commentaar",
    "start-date-short": "Begin",
    mom_after_short: "Na",
    mom_during_short: "Tijd",
    mom_before_short: "Vr",
    freq: "Frequentie",
    medic: "Medicatie",
    dmi: "Emd",
    hub: "Hub",
    morning: "Ontbijt",
    midday: "Lunch",
    evening: "Diner",
    version: "Versie",
    accessDate: "Toegangsdatum",
    patientAccess: "Toegang voor de patiënt",
    lastUpdate: "Laatste update",
    profil: "profiel",
    author: "Auteur",
    weekDay_1: "Maandag",
    weekDay_2: "Dinsdag",
    weekDay_3: "Woensdag",
    weekDay_4: "Donderdag",
    weekDay_5: "Vrijdag",
    weekDay_6: "Zaterdag",
    weekDay_7: "Zondag",
    weeknr: "Week #",
    day_0: "Zondag",
    day_1: "Maandag",
    day_2: "Dinsdag",
    day_3: "Woensdag",
    day_4: "Donderdag",
    day_5: "Vrijdag",
    day_6: "Zaterdag",
    day_7: "Zondag",
    daynr: "Dag #",
    monday: "Maandag",
    tuesday: "Dinsdag",
    wednesday: "Woensdag",
    thursday: "Donderdag",
    friday: "Vrijdag",
    saturday: "Zaterdag",
    sunday: "Zondag",
    ms_afterbreakfast: "Na ontbijt",
    ms_afterdinner: "Na avondmaal",
    ms_afterlunch: "Na lunch",
    ms_beforebreakfast: "Vóór ontbijt",
    ms_beforedinner: "Vóór avondeten",
    ms_beforelunch: "Vóór lunch",
    ms_duringbreakfast: "Tijdens ontbijt",
    ms_duringdinner: "Tijdens avondeten",
    ms_duringlunch: "Tijdens lunch",
    ms_betweenbreakfastandlunch: "Tussen ontbijt en lunch",
    ms_betweendinnerandsleep: "Tussen avondeten en bedtijd",
    ms_betweenlunchanddinner: "Tussen lunch en avondeten",
    ms_betweenmeals: "Tussen maaltijden",
    ms_evening: "Avond",
    ms_morning: "Ochtend",
    ms_night: "Nacht",
    ms_thehourofsleep: "Vóór bedtijd",
    ms_afterwakingup: "Bij het opstaan",
    ms_at: "om",
    "hub-upl-fil": "Bestand opladen op hubs",
    "hub-down-fil-pat": "Bestand importeren in patiëntendossier",
    "hub-doc-title": "Titel document",
    "hub-doc-type": "Type document",
    "hub-medic-susp-res": "Reden van onderbreking",
    "hub-med-sus-date": "Datum onderbreking",
    "hub-med-trt-date": "Datum behandeling",
    "hub-med-use": "Medicatie gebruik",
    des: "Descriptie",
    pos: "Posologïe",
    suspension: "Opschorting",
    ms_day: "Dag",
    "ms-export-failure": "Fout bij het synchroniseren van uw schema",
    "ms-export-success": "Uw medicatieschema is gesynchroniseerd",
    "ms-known-posology": "Bekende dosering",
    "ms-local": "Lokaal",
    "ms-vault": "Kluis",
    "ms-winner": "Winnaar",
    "error-loading":
      "Er is een probleem opgetreden tijdens het laden van het medicatieschema, gelieve opnieuw te proberen",
    retry: "Opnieuw",
    "modif-date": "Gewijzigd op",
    drugroute: "Toediening",
    "administration-unit": "Vorm",
    "instructions-for-patient": "Instructies voor patiënt",
    compound: "Magistrale",
    "medication-use": "Gebruik",
    "day-number": "Dag #",
    "up-new": "Nieuw lokaal",
    "up-update": "Update lokaal",
    "down-new": "Nieuw kluis",
    "down-update": "Update kluis",
    deleted: "Gewist",
    stopped: "Gestopt",
    equal: "Identiek",
    errors: "Fouten in medicatielijn",
    invalid: "Foutieve waarde",
    "error-uploading":
      "Er is een fout opgetreden tijdens het versturen van het medicatieschema.",
    close: "Sluiten",
    "treatment-suspension": "Opschorting behandeling",
    begincondition: "Begin conditie",
    endcondition: "Reden beëindiging",
    "unit-route": "Eenheid/toediening",
    "begin-end": "Begin/Einde",
    action: "Actie",
    select: "Selecteren",
    include: "Toevoegen",
    exclude: "Verwijderen",
    status: "Status",
    "new-line": "Nieuwe medicatielijn",
    "updated-line": "Update medicatielijn",
    "to-vault": "opladen naar kluis",
    "to-local": "lokaal aanmaken",
    identical: "Medicatielijn is lokaal en op kluis identiek",
    "deleted-vault": "Medicatielijn is gewist op de kluis",
    "stopped-line": "Medicatielijn is gestopt",
    "error-title": "Fouten in 'kluis'-schema",
    "value-error": "Er zitten foutieve waarden in sommige medicatielijnen",
    "remove-vault-scheme": "Medicatieschema van de kluis verwijderen",
    "error-list": "Overzicht van de gevonden fouten",
    "correct-list": "Overzicht van de correcte lijnen in dit schema",
    "acknowledge-errors":
      "Verder gaan met enkel de correcte lijnen in dit schema",
    "removed-from-vault": "Verwijderd van kluis-schema",
  },
  compatibility: {
    UNKNOWN_SERVICES: "Onbekende diensten",
  },
  import: {
    excel: {
      CHOOSE_FILE: "Een bestand kiezen",
      TITLE: "Excel importeren",
      IMPORT_YOUR_FILE: "Importeer uw Excel-bestand:",
      ERROR: "Er is een fout opgetreden tijdens het importeren.",
      ERROR_INVALID: "Inhoud of Excel-bestand is niet compatibel.",
      FINISH: "Afsluiten",
    },
  },
  "e-labo": {
    TABS: {
      GENERAL: "Algemene instellingen",
    },
    FILE_NAMES: "patientinfo.xml & patient.txt",
    OUTPUT_FOLDER: "Map",
    APP_PATH: "Labo applicatie",
    APP_FILE_TYPE: "Applicatie",
    CHOOSE: "Kiezen",
    EXPORT: "Patiënt bestand klaarzetten en labo applicatie starten",
    EXPORT_ERRORS: {
      CONTENT_GENERATION: "Fout bij het genereren van de eLabo bestanden.",
      NO_DESTINATION_FOLDER:
        "eLabo map niet ingesteld, ga naar gebruikersprofiel om in te stellen.",
      NON_EXISTENT_DESTINATION_FOLDER: "Map voor eLabo export bestaat niet.",
      NO_WRITE_PERMISSIONS: "Geen schrijfrechten op map voor eLabo bestanden.",
      WRITE_FAILED: "Onvoorziene fout bij aanmaken eLabo bestanden.",
    },
    EXPORT_SUCCESS: "eLabo bestanden klaar.",
    LAUNCH_APP_ERRORS: {
      NON_EXISTENT_APP_PATH: "Labo applicatie niet aanwezig.",
      OPEN_FAILED: "Fout bij het starten van de labo applicatie.",
    },
  },
  bcpErrorMessages: {
    GENERAL_ERROR_MESSAGE: "Probleem met de eHealth verbinding",
    TARIFICATION:
      "Op basis van de gegevens die op dit moment in uw software bekend zijn en de gegevens die door uw patiënt zijn verstrekt, berekent de software het tarief, maar zonder enige verplichting tot betaling of tarief<br>",
    EATTEST:
      "Het e-Attest systeem is niet beschikbaar, gelieve een papieren attest te gebruiken<br>",
    EFACT:
      "gelieve later opnieuw te proberen<br> Voor meer informatie, volg deze link:  <a href='http://www.ejustice.just.fgov.be/cgi_loi/loi_a1.pl?language=fr&la=F&cn=2015091802&table_name=loi&&caller=list&F&fromtab=loi&tri=dd+AS+RANK&rech=1&numero=1&sql=(text+contains+(%2527%2527))#Art.5'>reglementaire link</a><br>",
    MEMBERDATA: {
      "1": "De software zal opnieuw proberen om uw patiëntendossier bij te werken. <br>",
      "2": "Op basis van de informatie die op dit moment bekend is in uw software en de informatie die door uw patiënt is verstrekt, is het aan u om te beslissen of u al dan niet derdebetalersregeling toepast. De mutualiteit aanvaardt het verzoek om betaling door derden indien aan de reglementaire voorwaarden is voldaan <br> La mutualité acceptera l’application du tiers payant si les conditions réglementaires sont remplies.<br> Voor meer informatie, volg deze link:  <a href='http://www.ejustice.just.fgov.be/cgi_loi/loi_a1.pl?language=fr&la=F&cn=2015091802&table_name=loi&&caller=list&F&fromtab=loi&tri=dd+AS+RANK&rech=1&numero=1&sql=(text+contains+(%2527%2527))#Art.9/2'> reglementaire link </a> <br>",
    },
    DMG: {
      "1": "De dienst is niet beschikbaar, probeer het later nog eens.<br>",
      "2": "De dienst is niet beschikbaar, probeer het opnieuw binnen 7 dagen<br>",
    },
    CHAPTERIV: {
      "1": "Aangezien deze dienst niet beschikbaar is, gelieve u te baseren op de historiek in uw software of op de informatie die door uw patiënt is verstrekt<br>",
      "2": "De dienst is niet beschikbaar, probeer het later nog eens of vraag een akkoord op papier aan.<br>",
    },
  },
  "flatrate-settings": {
    title: "Codering van de prijsstelling van zorgpakketten",
    warning: "Voer uw tarieven voor de afgelopen 24 maanden in",
    deleteBtn: "Verwijder prijzen",
    addTarificationBtn: "Voeg prijzen toe",
    TAB: {
      doctor: "Medisch zorgpakket",
      physio: "Fysiotherapie pakket",
      nurse: "Verpleegpakket",
      ptd: "Pre-diabetes zorgpad pakket",
    },
    COLUMN: {
      code: "Nmcl ",
      type: "Beschrijving",
      startDate: "Startdatum",
      price: "Prijsstelling",
    },
    TYPE: {
      "109616": "Zorgpakket in gezondheidscentra",
      "409614": "Verpleegpakket in gezondheidscentra",
      "509611": "Fysiotherapie pakket in gezondheidscentra",
      "109594": "Pre-diabetes zorgpad",
    },
    "TARIF-DIALOG": {
      add: "Toevoegen",
      cancel: "Annuleren",
      title: "Codering van een nieuwe prijsstelling",
      startDate: "Startdatum",
      amount: "Bedrag",
    },
  },
  pleaseWait: "Even geduld aub",
  manuallyForced: "Handmatig gedwongen",
  FRI: {
    MENU: {
      title: "Forfait facturatie",
      mda: "Controle MDA",
      mhcOverview: "Overzicht MH contract",
      insurabilityOverview: "Overzicht verzekerbaarheid",
      invoicesToCorrect: "Te corrigeren facturen",
      invoicesToSend: "Te verzenden facturen",
      lostInvoices: "Verloren' facturen",
      pastInvoices: "Eerdere facturen",
      sentBatches: "Zendingen in behandeling",
      rejectedBatches: "Verworpen zendingen",
      acceptedBatches: "Aanvaarde zendingen",
      archivedBatches: "Gearchiveerde zendingen",
      importTestPatients: "Importeer testpatiënten",
      getTestPatients: "Laad testpatiënten",
      deleteTestPatients: "Wis testpatiënten",
      generateTestInvoicingData: "Genereer test data",
      generatePastInvoicingData: "Genereer historische data",
      getInvoicingData: "Get invoicing data",
      sendTestPastBatches: "Send past batches (TEST)",
      sendPastBatches: "Send past batches (PROD)",
      deleteInvoicingData: "Delete invoicing data",
    },
    "PAGE-TITLE": {
      mda: "Controle lidmaatschapsgegevens (MDA)",
      "mhc-overview": "Overzicht medisch huis contracten",
      "insurability-overview": "Overzicht verzekerbaarheid",
      "invoices-to-correct": "Te corrigeren facturen",
      "invoices-to-send": "Te verzenden facturen",
      "lost-invoices": "Verloren' facturen",
      "generate-past-invoices":
        "Aanmaken facturen in het verleden / recuperatie",
      "sent-batches": "Zendingen in behandeling",
      "rejected-batches": "Verworpen zendingen",
      "accepted-batches": "Aanvaarde zendingen",
      "archived-batches": "Gearchiveerde zendingen",
      "import-test-patients": "Importeer testpatiënten",
      "get-test-patients": "Laad testpatiënten",
      "delete-test-patients": "Wis testpatiënten",
      "generate-past-invoicing-data":
        "Genereer historische facturatie gegegevens",
      "get-invoicing-data": "Laad facturatie gegegevens",
      "create-test-batches": "Creer test batches",
      "send-test-batches": "Verzend batches (TEST)",
      "send-past-batches": "Verzend historische batches (PROD)",
      "delete-invoicing-data": "Wis facturatie gegegevens",
      "reopen-closed-mhcs-upon-transfer": "Reopen closed MHCs upon transfer",
      "rewrite-mhc-signaturetype-to-holderpaper-when-null":
        "Rewrite MHC signatureType to holderPaper when null",
      "update-mhcs-ptd-based-on-ptd-he": "Update MHC PTD based on PTD HE",
      "update-mhcs-ptd-based-on-patient-property":
        "Update MHC PTD based on patient's property",
    },
    LOADING: {
      gettingPatients: "Laden patiëntenbestand",
      deletingPatients: "Wissen patiëntenbestand",
      importingPatients: "Importeer patiënt",
      gettingMh: "Laden Medisch Huis",
      gettingEfactResponses: "Laden e-fact antwoorden",
      confirmingEfactTacks: "Bevestigen Tacks e-fact",
      confirmingEfactMessages: "Bevestigen berichten e-fact",
      gettingRawInvoicingMessages: "Laden facturatie historiek",
      deleteingInvoicingData: "Wissen facturatie gegegevens",
      gettingMhValorisations: "Laden RIZIV tarieven ",
      gettingTarifications: "Laden tarieven",
      gettingMdaRequests: "Laden MDA aanvragen",
      gettingMdaResponses: "Laden MDA antwoorden",
      gettingInsurances: "Laden verzekeringsinstellingen",
      gettingInsurancesOas: "Laden landsbonden",
      gettingPatientsInvoicingAttributes: "Laden facturatie attributen",
      creatingInvoices: "Creëren facturen",
      savingInvoicingData: "Opslaan facturatie",
      gettingInvoicingData: "Laden facturatie gegegevens",
      creatingTestBatch: "Creëren TEST btach",
      sendingTestBatch: "Simulatie verzending (TEST) batch",
      creatingBatches: "Creëren zendingen",
      batchSentForOa: "Zending verzonden naar VI ",
      processingBatchAnswer: "Behandeling antwoorden VI ",
      savingMdaRequests: "Opslaan MDA aanvragen",
      savingMdaResponses: "Opslaan MDA antwoorden",
      sendingMdaRequests: "Verzenden MDA aanvragen",
      analyzingMdaAnswers: "Analyse MDA antwoorden",
      updatingPatsInformation: "Updaten lidmaatschapsgegevens",
      finalizingProcess: "Beëindigen van het proces",
    },
    MISC: {
      run: "Uitvoeren",
      xlsExport: "Excel export",
    },
    "BATCH-DETAILS": {
      batchDetails: "Details zending",
      batchType: "Type zending",
      type: "Type",
      batch: "Zending",
      context: "Context",
      charged: "Gefactureerd",
      accepted: "Aanvaard",
      rejected: "Verworpen",
      payment: "Betaling",
      rejectionReason: "Reden verwerping",
      noTechnicalDetailsFound: "Geen enkel technisch gegeven gevonden",
      moreThan5pcErrors: "Meer dan 5% fouten",
      errorsInPreliminaryControl: "Fout tijdens eerste controle",
    },
    GENERAL: {
      oa: "VI",
      ins: "Verzekding",
      oaVerbose: "Landsbond",
      ref: "Referentie",
      month: "Maand",
      invoicedMonth: "Gefactureerde maand",
      sentOn: "Verzonden op",
      amount: "Bedrag",
      amounts: "Bedragen",
      chargedAmount: "Gefactureerd bedrag",
      chargedOaAmount: "Bedrag VI",
      acceptedAmount: "Aanvaard bedrag",
      rejectedAmount: "Verworpen bedrag",
      status: "Status",
      actions: "Acties",
      getBatchesAnswers: "Ophalen antwoorden",
      search: "Voer uw zoekopdracht in",
      invoices: "Facturen",
      reason: "Reden",
      bankAccount: "Bankrekening",
      paymentReference: "Referentie betaling",
      flagAllInvoicesAsCorrected: "Alles als gecorrigeerd markeren",
      flagAllInvoicesAsToBeCorrected: "Alles als te corrigeren aanduiden",
      flagAllInvoicesAsLost: "Alles als verloren markeren",
      ssin: "INSZ",
      ssinVerbose: "Rijksregister nummer",
      lastName: "Naam",
      firstName: "Voornaam",
      invoiceNumber: "Factuurnr",
      patient: "Patiënt",
      patientSingularOrPlural: "Patiënt(en)",
      generateMyInvoices: "Genereer mijn facturen",
      sendMyInvoices: "Verzend mijn facturen",
      codeVerbose: "Code / nomenclatuur",
      flagAsAccepted: "Markeer als aanvaard",
      flagAsPartiallyAccepted: "Markeer als gedeeltelijk aanvaard",
      flagAsRejected: "Markeer als geweigerd",
      flagAsArchived: "Archiveer gegegevens",
      flagAsUnarchived: "De-archiveer gegegevens",
      flagAsCorrected: "Markeer als gecorrigeerd",
      flagAsLost: "Markeer als verloren",
      flagAsToBeCorrected: "Als te markeren aanduiden",
      close: "Sluiten",
      patientAmount: "Bijdrage patiënt",
      additionalAmount: "Suplementaire bijdrage",
      total: "Totaal",
      batchsTotal: "Totaal zending",
      monthlyTotals: "Maandelijkse totalen",
      sendBatchAgain: "Zending herzenden",
      message: "Bericht",
      feedback: "Feedback",
      downloadTechnicalDetails: "Technische details",
      technicalDetails: "Technische details",
      nihiiVerbose: "RIZIVnr",
      notVerified: "Niet gecontroleerd",
      yes: "Ja",
      no: "Nee",
      save: "Opslaan",
      saveAndInvoice: "Opslaan en factureren",
      date: "Datum",
      delete: "Wissen",
      deleteInvoice: "Factuur wissen",
      deletionConfirmation: "Bevestiging wissen factuur",
      cancel: "Annuleren",
      deleteInvoiceWarning1: "Opgelet, u gaat een te verzenden factuur wissen.",
      deleteInvoiceWarning2: "Deze handeling is onomkeerbaar.",
      patientCurrentlyActive: "Patiënt momenteel actief",
      patientCurrentlyInactive: "Patiënt momenteel inactief",
    },
    "INVOICES-SUMMARY": {
      noInvoicesToSend: "Geen enkele te verzende factuur",
      dialogTitle: "Samenvatting voor verzending",
    },
    "MY-PROFILE": {
      flatrateTarifications: "Forfaitaire tarieven",
      "contactPerson-invoicingResponsible":
        "Contactpersoon / verantwoordelijke facturatie",
      "contactPerson-invoicingResponsible-required":
        "Verantoordelijke facturatie is vereist",
    },
    ERRORS: {
      dialogTitle: "Ontbrekende gegevens",
      intro1: "Opgelet, de volgende gegevens ontbreken in uw gebruikersfiche",
      intro2: "Vul deze in voordat u uw facturatiemodule gebruikt.",
      "hcp.nihii": "RIZIVnr",
      "hcp.cbe": "KBO nummer",
      "hcp.bankAccount": "Bankrekening (IBAN)",
      "hcp.bic": "Bankrekening (BIC)",
      "hcp.contactPersonHcpId": "Verantoordelijke facturatie",
      "hcpContactPerson.firstName": "Voornaam van verantwoordelijke facturatie",
      "hcpContactPerson.lastName": "Naam van verantwoordelijke facturatie",
      "hcpContactPerson.ssin": "INSZ van verantwoordelijke facturatie",
      "reimbursement.gp": "Tarief huisartsgeneeskunde",
      "reimbursement.kine": "Tarief kiné",
      "reimbursement.nurse": "Tarief verpleging",
      "reimbursement.ptd": "Tarief voortraject diabetes (VTD)",
      noPatientsFoundForMda: "Geen enkele patiënt gevonden",
      requestMessageNotCreated: "Fout bij opslaan MDA aanvragen",
      mdaRequestNotSent: "Fout bij verzenden MDA aanvragen",
      technicalErrorWithOa: "Technische fout bij de VI",
      ssinNotFound: "Rijksregisternummer onbekend",
      dataCouldNotBeVerified: "De gegevens konden niet worden geverifieerd",
      unknownInsuranceRegistrationNumber:
        "Contractnummer niet bekend bij de VI",
      callToBackendFailed: "Technische fout bij de VI (call backend)",
      proceedWithMdaFirst:
        "Voltooi de controle van de lidmaatschapsgegevens (MDA) alvorens te factureren",
      incorrectSsin: "Verkeerd rijksregisternummer",
    },
    MDA: {
      month_01: "Januari",
      month_02: "Februari",
      month_03: "Maart",
      month_04: "April",
      month_05: "Mei",
      month_06: "Juni",
      month_07: "Juli",
      month_08: "Augustus",
      month_09: "September",
      month_10: "Oktober",
      month_11: "November",
      month_12: "December",
      memberDataRequest: "Verzending van de aanvraag",
      requestStatus: "Status van de aanvraag",
      requestResults: "Resultaaten van de aanvraag",
      invoicesCreation: "Creëren facturen",
      verifyMemberData: "Controle lidmaatschapsgegevens",
      verifyData: "Controle gegevens",
      membershipReference: "Contractnummer verzekering",
      noKnownOa: "Momenteel geen verzekeraar geïdentificeerd",
      errorInResponse: "Fout in het antwoord",
      responseObtained: "Antwoord verkregen",
      beingTreated: "In behandeling",
      mdaRequestSent: "Verzeding van de MDA aanvragen voltooid",
      pleaseVerifyMemberDataToBegin:
        "Controleer eerst de lidmaatschapsgegevens MDA",
      bypassMdaResponses: "Wachttijd antwoorden overslaan",
      patientHasNoMhc: "De patiënt heeft geen contract in een Medisch Huis",
      patientIsNowInMhc: "De patiënt is nu in het Medisch Huis",
      patientHasNoOnGoingMhc:
        "De patiënt heeft geen geldig/actueel contract in uw Medisch Huis",
      patientHasNoValidIns: "De patiënt heeft geen geldige verzekerbaarheid",
      patientHasNoIns: "De patiënt is niet verzekerd",
      patientIsInOtherMhc: "De patiënt is veranderd van Medisch Huis",
      invoicable: "Factureerbaar",
      flagAsInvoicable: "Markeer als factureerbaar",
      flagAsNotInvoicable: "Markeer als niet factureerbaar",
      flagAllAsInvoicable: "Markeer alles als factureerbaar",
      flagAllAsNotInvoicable: "Markeer alles als niet factureerbaar",
      readonlyExplanation:
        "Dit scherm is, na uw validatie van wijzigingen voor het genereren van facturen, nu alleen-lezen (tot volgende maand).",
    },
    "PAST-INVOICES": {
      firstInsurability: "Eerste bekende verzekerbaarheid",
      lastInsurability: "Laatste bekende verzekerbaarheid",
      noValidInsurability: "Geen / niet vezekerd",
      mhcShort: "MH Contract",
      ongoing: "Lopende",
      ongoingSince: "Lopende sinds",
      stoppedSince: "Afgesloten sinds",
      noContract: "Geen contract",
      workWithThisPatient: "Werken met deze patiënt",
      gpOneLetterAbbreviation: "M",
      kineOneLetterAbbreviation: "K",
      nurseOneLetterAbbreviation: "I",
      backToList: "Terug naar de lijst",
      listingNotaBene:
        "Enkel patiënten die een contract hebben (gehad) kunnen (mogelijk) worden gefactureerd/gerecupereerd.",
      verify: "Controleren",
      insured: "Vezekerd",
      gotInvoiced: "Is gefactureerd",
      manually: "manueel",
      automatically: "automatisch",
      alreadyInOrder: "Al in orde",
      createInvoice: "Aanmaken factuur",
      create: "Aanmaken",
      notInOrder: "Niet in orde",
      noModificationMade: "Geen enkele wijziging toegepast",
      invoiceCreated: "Factuur aangemaakt",
      invoiceAlreadyCreated: "Factuur is al aangemaakt",
    },
  },
  "cert-management": {
    connectAsPmg: "Verbinding als wachtpost",
    TAB: {
      current: "Huidig certificaat",
      outdated: "Oud certificaat",
    },
    GRID: {
      title: "Opgeladen certificaten",
      uploadBtn: "Certificaat opladen",
      CELL: {
        name: "Naam van het certificaat",
        endOfValidity: "Einde geldigheid",
        active: "Actief",
        password: "Wachtwoord",
        asPmg: "Wachtpost-verbinding",
      },
      BTN: {
        delete: " Verwijderen",
        modify: "Wijzigen",
        active: "Activeren",
      },
    },
    DIALOG: {
      MODIFICATION: {
        title: "Wachtwoord van het certificaat wijzigen",
        password: "Wachtwoord",
        BTN: {
          cancel: "Annuleren",
          modify: "Wijzigen",
        },
      },
    },
    ERROR: {
      password: "Wachtwoord certificaat ontbreekt",
      encrypt: "Fout tijdens wachtwoordversleuteling",
      decrypt: "Fout bij het decoderen van het wachtwoord",
    },
  },
  "MDA-SA": {
    before: "Vóór",
    after: "Na",
    unauthorizedSector: "Niet toegestaan",
    summaryBeforeInvoicing: "Samenvatting alvorens factureren",
    verifiedMonth: "Gecontroleerde maand",
    requestDate: "Aanvraag gedaan op",
    responseLastCheckDate: "Antwoorden laatst opgehaald op",
    totalRequestedPatients: "Te controleren patiënten",
    totalAnsweredPatients: "Verkregen antwoorden",
    totalInvoiceablePatientsBasedOnMda:
      "Factureerbare patiënten (op basis van MDA)",
    totalNonInvoiceablePatientsBasedOnMda:
      "Niet factureerbare patiënten (op basis van MDA)",
    totalPatientsForcedAsInvoiceable:
      "Als factureerbaar 'geforceerde' patiënten",
    totalPatientsNotToCharge: "Totaal niet factureerbare patiënten",
    totalPatientsToCharge: "Totaal factureerbare patiënten",
  },
  laboratories: {
    management: {
      list: {
        TITLE: "Configuratie van laboratoria",
        NAME: "Laboratoria",
        FAVORITE: "Toevoegen aan favorieten",
        CREDENTIALS: "Inloggegevens toevoegen",
      },
      "credentials-form": {
        TITLE: "Inloggegevens instellen - {{name}}",
        SAVE: "Bewaren",
        CANCEL: "Annuleren",
        USER: "Gebruiker",
        PASSWORD: "Wachtwoord",
        CONFIRM_PASSWORD: "Bevestiging van wachtwoord",
      },
    },
    "patient-dialog": {
      TITLE: "Laboratoria",
      LABORATORY: "Selecteer een laboratorium:",
      REQUEST: "Voorschrift",
      RESULTS: "Analyse resultaten",
      NO_CREDENTIALS: "Geen identifier gedefinieerd voor dit laboratorium.",
    },
  },
  PATIENT_INFORMATIONS: {
    postalBox: "ds",
  },
  "medispring-window": {
    TITLE: "{{windowTitle}} van {{fullName}} of {{date}}",
  },
  "events-covid-management": {
    TITLE: "Opvolging van de laatste COVID events",
    GET_LAST_EVENTS: "Laatste COVID events opvragen",
    PATIENT: "Patiënt",
    PATIENTS: "Patiënten",
    AGE: "Leeftijd",
    TYPE: "Event",
    MEETING_DATE: "Datum van de volgende afspraak",
    DOSES: "Dosis",
    NIHII: "RIZIV nummer",
    DATE: "van",
    FINISH: "Herstel voltooid",
    ERROR: {
      CHECK_EVENT: "Fout bij het ontvangen van de laatste gebeurtenissen:",
      CREATION_VACCINE: "Fout bij het maken van nieuwe vaccins:",
      MODIFY_PROP: "Fout bij bijwerken gebruiker:",
      GET_PATIENTS: "Fout bij ophalen van patiënten:",
      MODIFY_PATIENT: "Fout bij bijwerken van patiëntafspraken:",
      PATIENT_NOT_FOUND: "Patiënt niet gevonden",
      GET_CONTACTS: "Fout bij ophalen van patiëntcontacten:",
    },
  },
  GUARD: {
    CANNOT_ACCES_PATIENT_DATA:
      "U heeft geen toegang tot het dossier van deze patiënt.",
  },
};
