const BASE_URL: string = "https://api.icure.cloud";
export const API_URL: string = `${BASE_URL}`;

//export const PROCESS_ID:string = "aa6eed57fb67481aaa5e058d1ad2b9e9";
export const PROCESS_ID: string = "a1f3afd3-54b3-461e-8407-3545252a5c06";

// Mobile app process
//export const PROCESS_ID = 'd6311d1b-fece-410e-8f38-d519f6109ccb';
//export const MSG_GW_URL = 'https://msg-gw-ms.icure.cloud';
export const MSG_GW_URL = "https://msg-gw.icure.cloud";
//export const SPEC_ID = 'ms';
export const SPEC_ID = "root-ms-superadmin";

export const RECAPTCHA = "a58afe0e-02dc-431b-8155-0351140099e4";

export const KEY_STORAGE_PREFIX = "org.taktik.icure.rsa";

export const API_TIME_FORMAT: string = "YYYYMMDDHHmmss";

export const RECOVERY_KEY ="recoveryKey"; 
