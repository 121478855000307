import {useLocation, useParams} from "react-router-dom";
import Button from "../../ui/components/Button";
import Typography from "../../ui/components/Typography/Typography";
import Section from "../../ui/components/Layout/Section";
import AvatarGroup from "../../ui/components/Avatar/AvatarGroup";
import {HCPModel, isHCPModel} from "../../models/HCPModel";
import {
  formatAddress,
  formatDate,
  formatDateAndTime,
  formatName,
  formatTime,
  getUpdateTime,
} from "../../utils/formatting";
import InformationItem from "../../ui/components/InformationItem";
import {Icons} from "../../ui/assets";
import {ContactPoint, ContactPointUse} from "../../models/ContactPoint";
import styled from "styled-components";

import {AgendaItemModel, AgendaItemStatusEnum} from "../../models/AgendaItemModel";
import useResource from "../../hooks/useResource";
import {Color, Size} from "../../ui/tokens";
import {FunctionComponent} from "react";
import {Fragments} from "../home/HomeSection";
import {ResourceType} from "../../types/ResourceType";
import Comment from "../../ui/components/Comment";
import dayjs from "dayjs";
import {API_TIME_FORMAT} from "../../../services/constants";
import Spacing from "../../ui/components/Layout/Spacing";
import {useFlowContext} from "../../ui/components/ScreenFlow/FlowContext";
import {FlowScreenProps} from "../../ui/components/ScreenFlow/ScreenFlow";
import {
  useCancelAppointmentMutation,
  useDeclineProposedProcedureMutation
} from "../../connectors/medispring/api/medispring";
import useConfirmation from "../../hooks/useConfirmation";

function contactPointIcon(contact: ContactPoint): FunctionComponent {
  switch (contact.use) {
    case ContactPointUse.EMAIL:
      return Icons.icons.email_xs;
    case ContactPointUse.MOBILE:
      return Icons.icons.mobile_xs;
    default:
      return Icons.icons.landline_xs;
  }
}

const MapContainer = styled.iframe`
  display: block;
  min-height: 320px;
  border: 0;
`;


const AppointmentDetailsScreen = ({name}:FlowScreenProps) => {
  
  const { section, resourceId } = useParams();

  

  const { data: appointment } = useResource<AgendaItemModel>(
    ResourceType.APPOINTMENTS,
    resourceId,
    section !== Fragments.AGENDA
  );

  const location = useLocation();

  const [cancelAppointment] = useCancelAppointmentMutation();

  const cancelAppointmentAlert = useConfirmation({
    data: resourceId,
    alertTitle: "Annuler ce rendez-vous?",
    alertMessage:
      "En annulant ce rendez-vous, vous libérez un créneau pour un autre patient.",
    onConfirm: () => cancelAppointment(resourceId!),
    confirmationMessage:
      "Votrerendez-vous a bien été annulé, merci de nous avoir prévenu à temps, bonne journée !",
    confirmationTitle: "Rendez-vous annulé",
  });

const {setActions} = useFlowContext();


  // setNavigationForScreen
  setActions(
    name,
    
    <Button
      buttonType="destructive"
      small
      title="Annuler"
      onPress={() => cancelAppointmentAlert()}
    />
  );

  const hcp = appointment?.participants?.find((p) => isHCPModel(p)) as HCPModel;

  const d = dayjs(appointment?.start.toString(), API_TIME_FORMAT);
  const time = d?.isBefore(dayjs().add(1, "day"))
    ? ""
    : getUpdateTime(appointment.start);

  const isTodo = appointment?.status === AgendaItemStatusEnum.PROPOSED;


  const [declineProposedProcedure] = useDeclineProposedProcedureMutation();

  const declineProposedProcedureAlert = useConfirmation({
    data: resourceId,
    alertTitle: "Refuser la procédure proposée?",
    alertMessage: "Êtes-vous sur de vouloir refuser la procédure proposée?",
    onConfirm: () => declineProposedProcedure(resourceId),
    confirmationMessage: "La procédure proposée a bien été refusée.",
    confirmationTitle: "Procédure refusée",
  });



  if (isTodo) {
    setActions(
        name,

        <Button
            buttonType="destructive"
            small
            title="Annuler"
            onPress={() => declineProposedProcedureAlert()}
        />
    );
    return (
      <>
        <Typography.h1>{appointment.title}</Typography.h1>
        <Typography.h2 color={Color.NEUTRAL_500}>{time}</Typography.h2>
        {!!appointment?.description?.length && (
          <>
            <Spacing large />
            <Comment
              text={appointment.description ?? ""}
              author={hcp}
              date={formatDateAndTime(appointment.created)}
            />
          </>
        )}
      </>
    );
  }
  
  return (
    <>
      <Typography.h1>Détails de votre rendez-vous</Typography.h1>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography.h2>{formatName(hcp)}</Typography.h2>
        <AvatarGroup persons={appointment?.participants} />
      </div>
      {/*<Typography.small>{t('agenda.confirmed')}</Typography.small>*/}

      {appointment && (
        <>
          <Section title="Informations">
            <Spacing />
            <InformationItem
              title={formatDate(appointment?.start!)}
              icon={Icons.icons!.calendar_xs}
            />
            <InformationItem
              title={`${formatTime(appointment?.start!)} - ${formatTime(
                appointment?.end!
              )}`}
              icon={Icons.icons.clock_xs}
            />

            <InformationItem
              title={`${formatAddress(appointment?.location!)}`}
              icon={Icons.icons.pin_xs}
            />
            <>
              {hcp?.telecom?.map((contact, index) => (
                <InformationItem
                  key={index}
                  icon={contactPointIcon(contact)}
                  title={contact.value!}
                />
              ))}
            </>
            <Spacing />
          </Section>

          {
            <MapContainer
              style={{
                padding: Size.S,
                borderRadius: Size.S,
                border: `2px solid ${Color.NEUTRAL_200}`,
                boxSizing: "border-box",
              }}
              title="Map"
              width="100%"
              height="300px"
              loading="lazy"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB_XCU_o291M3tGlBNcR01PwPQvEcnC0vE&q=${appointment.location?.streetName} ${appointment.location?.houseNumber},${appointment.location?.zip} ${appointment.location?.city}, ${appointment.location?.country}`}
            ></MapContainer>
          }
        </>
      )}
    </>
  );
};

export default AppointmentDetailsScreen;
