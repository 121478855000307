import { useTranslation } from "react-i18next";
import Typography from "../../../../ui/components/Typography/Typography";
import { useSelector } from "react-redux";
import { selectAllPatients } from "../../../../features/login/AuthSlice";
import { formatDateAndTime, formatName } from "../../../../utils/formatting";
import useURLQuery from "../../../../hooks/useURLQuery";
import { Size } from "../../../../ui/tokens";
import ButtonGroup from "../../../../ui/components/Layout/ButtonGroup";
import Button from "../../../../ui/components/Button";
import {
  useCreateAppointmentMutation,
  useGetAppointmentTypesQuery,
} from "../../api/medispring";
import useUpdateSearchParams from "../../../../hooks/useUpdateSearchParams";
import { AppointmentTypeAndPlace } from "@icure/api";
import { useToast } from "../../../../ui/components/Toast/useToast";
import { useGetResourceByIdQuery } from "../../../../api/healthdata";
import useResource from "../../../../hooks/useResource";
import { HCPModel } from "../../../../models/HCPModel";
import Icon from "../../../../ui/components/Icon";
import { Icons } from "../../../../ui/assets";
import { ResourceType } from "../../../../types/ResourceType";
import { useModalSheet } from "../../../../ui/components/ModalSheet/useModalSheet";

const ConfirmationScreen = () => {
  const { t } = useTranslation();

  const { slot, hcp, place, type } = useURLQuery();

  const { data: types } = useGetAppointmentTypesQuery(hcp);

  const { data: provider } = useResource<HCPModel>(ResourceType.PROVIDERS, hcp);

  const setParam = useUpdateSearchParams();

  const patients = useSelector(selectAllPatients);

  const setModal = useModalSheet();

  const [bookAppointment, { isLoading, error }] =
    useCreateAppointmentMutation();

  const addToast = useToast();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div>
        <Typography.body>
          <Typography.strong>
            {
              t("agenda.confirmation_message_intro", {
                hcp: formatName(provider),
                datetime: formatDateAndTime(parseInt(slot!)),
              }) as string
            }
          </Typography.strong>

          <Typography.body>
            <p
              dangerouslySetInnerHTML={{
                __html: t("agenda.confirmation_message_details") as string,
              }}
            ></p>
          </Typography.body>
        </Typography.body>
      </div>

      <Icon illustration asset={Icons.illustrations.confirm_agenda} />

      <ButtonGroup isLoading={isLoading}>
        <Button
          primary
          title={t("agenda.confirm_btn") as string}
          onPress={() => {
            setModal(null);
            setParam("hcp", undefined);
            setParam("slot", undefined);
            setParam("type", undefined);
            setParam("place", undefined);

            bookAppointment({
              slot,
              hcp,
              type: types!.find(
                (t: AppointmentTypeAndPlace) => t.calendarItemTypeId === type
              )!,
              patient: patients![0],
            });
            addToast({
              title: "Rendez vous confirmé",
              message: "Votre rendez vous a été confirmé",
              active: true,
            });
          }}
        />
      </ButtonGroup>
    </div>
  );
};

export default ConfirmationScreen;
