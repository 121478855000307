import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../../store";
import dayjs from "dayjs";
import { SortingOptions } from "../documents/DocumentsDisplayOptions";
import { MedicationDisplayModes } from "./DisplayOptions";

export const LANGUAGE_VALUES = ["fr", "nl", "en"] as const;

export type Language = (typeof LANGUAGE_VALUES)[number];

interface DisplayState {
  options: { [key: string]: boolean | string };
  language: Language;
}

// REDUCER
const slice = createSlice({
  name: "display",
  initialState: {
    language: "fr",
    options: {
      language: "fr",
      "agenda.booked": true,
      "agenda.scheduled": true,
      "agenda.past": false,
      "documents.prescriptions": true,
      "documents.reports": true,
      "documents.results": true,
      "documents.sortingOrder": SortingOptions.EXAM_DATE,
      "home.prescriptions": true,
      "home.results": true,
      "medication.grouping": MedicationDisplayModes.CHRONOLOGICAL,
    },
  } as DisplayState,

  reducers: {
    toggleOption: (state, action) => {
      state.options[action.payload] = !state.options[action.payload];
    },
    setOption: (state, action) => {
      state.options[action.payload.id] = action.payload.value;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

// SELECTORS
export const selectOption = (state: RootState, option: string) =>
  state?.display.options[option];

export const selectLanguage = (state: RootState) => state?.display.language;
// ACTIONS

export const { toggleOption, setOption, setLanguage } = slice.actions;

export default slice.reducer;

/*
 dayjs.locale(lng);
    await i18n.changeLanguage(lng);
*/
