import React from "react";

import Typography from "../Typography/Typography";
import { Color, Size } from "../../tokens";
import Assets, { Icons } from "../../assets";
import styled from "styled-components";
import Icon from "../Icon";

interface ListItemProps {
  item?: any;
  onPress?: (event: any) => void;
  title?: string;
  subtitle?: string;
  details?: string;
  icon?: JSX.Element;
  showSeparator?: boolean;
  iconRight?: JSX.Element;
  noChevron?: boolean;
}

const ListItemContainer = styled.div<{ bottomSeparator: boolean }>`
  border-bottom: ${(props) =>
    props.bottomSeparator ? `1px solid ${Color.NEUTRAL_200}` : "none"};
  width: 100%;
`;

export default function ListItem({
  title,
  subtitle,
  details,
  onPress,
  icon,
  iconRight,
  showSeparator,
  noChevron,
}: ListItemProps) {
  return (
    <ListItemContainer onClick={onPress} bottomSeparator={!!showSeparator}>
      <div style={styles.content}>
        {icon && <div style={styles.icon}>{icon}</div>}
        <div style={{ flex: 1, marginRight: Size.S }}>
          {subtitle && <Typography.body>{subtitle}</Typography.body>}
          {title && <Typography.strong>{title}</Typography.strong>}
          {details && <Typography.small>{details}</Typography.small>}
        </div>
        {iconRight && <div style={styles.iconRight}>{iconRight}</div>}
        {(onPress && !noChevron) && (
          <Icon asset={Icons.navigation.chevron} color={Color.NEUTRAL_500} />
        )}
      </div>
    </ListItemContainer>
  );
}

const styles = {
  icon: {
    marginRight: Size.M,
  },
  iconRight: {
    marginRight: `${0}px`,
  } as React.CSSProperties,
  content: {
    alignItems: "center",
    padding: `${Size.M}px 0`,
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
  } as React.CSSProperties,
};
