import React from "react";

import ListItem from "./ListItem";

export interface EzoListRenderingProps<U> {
  data?: U[];
  title?: (item: U) => string;
  subtitle?: (item: U) => string;
  details?: (item: U) => string;
  icon?: (item: U) => JSX.Element;
  iconRight?: (item: U) => JSX.Element | undefined;
  onItemSelection?: (item: U) => void;
  noChevron?: boolean;
}
export default function EzoList<U>({
  data,
  title,
  subtitle,
  details,
  icon,
  iconRight,
  onItemSelection,
  noChevron
}: EzoListRenderingProps<U>) {
  return (
    <div>
      {data?.map((item: U, index) => (
        <ListItem
          key={`${index} ${Math.round(Math.random() * 1000)}`}
          item={item}
          icon={icon?.(item)}
          iconRight={iconRight?.(item)}
          title={title?.(item)}
          subtitle={subtitle?.(item)}
          details={details?.(item)}
          onPress={onItemSelection ? () => onItemSelection?.(item) : undefined}
          showSeparator={index < data.length - 1}
          noChevron={noChevron}
        />
      ))}
    </div>
  );
}
