import styled, { css } from "styled-components";
import { Color, Easing, Size } from "../tokens";
import Button from "./Button";

const tint = { light: Color.ACCENT, dark: Color.NEUTRAL_600 };

const NavigationIconContainer = styled.div<Props>`

    width: ${Size.M}px;
    height: ${Size.M}px;
    position: relative;

   
    transition: all ${Easing.IN_OUT}; 
    transition-delay: 0;
    &::after,
    &::before{
      content: "";
      display: block;
      position: absolute;
      
      height: 2px;
      background-color: ${tint.dark};
      width: ${Size.M}px;
      transform: rotate(45deg) ;
      top: 50%;

      transition: all ${Easing.IN_OUT}; 
    }
    &::after{
      transform: rotate(135deg);

    }


    > span {
      background-color: ${tint.dark};
    
      width: 16px;
      height: 2px;
      display: block;
      transform: translateY(${Size.S}px) ;
      opacity: 0;
      transition:${Easing.IN_OUT}};
    }

    ${(props) =>
      props.hideClose &&
      !props.back &&
      css`
        opacity: 1;
      `}

      
    
    ${(props) => 
      props.back &&
      css`
        &::before {
          transform: translateY(-3px) translateX(0px) rotate(135deg);
          width: ${Size.S}px;
          opacity: 1;
        }
        &::after {
          width: ${Size.S}px;
          opacity: 1;
          transform: translateY(3px) translateX(0px) rotate(-135deg);
        }
        > span {
          opacity: 1;
        }
      `}


      ${(props) =>
        props.home &&
        css`
          &::after {
            transform: translateY(5px);
            width: ${Size.M}px;
            background-color: ${tint.dark};
          }
          &::before {
            width: ${Size.M}px;
            transform: translateY(-5px);
            background-color: ${tint.dark};
          }
          > span {
            opacity: 1;
          }
        `}
    }

`;

interface Props {
  back?: boolean;
  home?: boolean;
  close?: boolean;
  hideClose?: boolean;
  onClick?: () => void;
}

const NavigationIcon = ({ back, close, hideClose, home }: Props) => {
  return (
    <NavigationIconContainer
      back={back}
      home={home}
      close={close}
      hideClose={hideClose}
    >
      <span></span>
    </NavigationIconContainer>
  );
};

const ModalSheetNavigationButton = ({
  back,
  home = true,
  hideClose,
  onClick,
}: Props) => {
  return (
    <Button
      secondary
      icon={
        <NavigationIcon
          back={back}
          home={home && !back}
          hideClose={hideClose}
        />
      }
      onPress={onClick}
    />
  );
};

export default ModalSheetNavigationButton;
