import React, { ReactElement } from "react";
import styled, { keyframes } from "styled-components";
import { Breakpoint, Size, Color, Elevation, Easing } from "../../ui/tokens";

const translateIn = keyframes`
  from {
    transform: translateY(-32px);
   

  }
  to {
    transform: translateY(0);
    
    
  }
`;
const translateOut = keyframes`
  from {
    transform: translateY(0);
   
  }
  to {
    transform: translateY(-32px);
  
  }
`;

const OptionsContainer = styled.div`
  padding: ${Size.L}px ${Size.L}px;
  top:${Size.XXL + Size.S}px;
  right:${Size.S}px;
  @media (min-width: ${Breakpoint.MOBILE}px) {
    top:${Size.XXL + Size.XL}px;
    right:${Size.L}px;
    pointer-events: all;
  }

 position: absolute;
 z-index: 10000;
 background-color: #fff;
border: 1px solid ${Color.NEUTRAL_200}};
border-radius:  ${Size.XS}px;
box-shadow:${Elevation.L}
transition: all 0.2s ease-in-out;
opacity: 0;
animation: ${translateOut} ${Easing.CUBIC};
pointer-events: none;

  @media (min-width: ${Breakpoint.DESKTOP}px) {
    padding-top:${Size.XXL}px;
    display: block;
    width: 280px;
    background:none;
    
    position: static;
    box-shadow: none;
    border: none;
    opacity: 1;
    transition: none;
  }

  &.active {
    pointer-events: all;
   opacity: 1;
   animation: ${translateIn} 0.3s cubic-bezier(0.25, 1, 0.25, 1) forwards;
  }

  
`;

interface SidebarProps {
  children: ReactElement | Array<ReactElement | undefined | boolean>;
}

const ScreenOptions = ({ children }: SidebarProps) => (
  <>
    <OptionsContainer className="options">{children}</OptionsContainer>
  </>
);

export default ScreenOptions;
