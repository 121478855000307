import { DocumentReference } from "./DocumentReference";
import { DocumentResource } from "./DocumentResource";
import { HCPModel } from "./HCPModel";
import { ObservationModel } from "./ObservationModel";

export interface DiagnosticReportModel extends DocumentResource {
  resultsInterpreter?: HCPModel;
  performer?: HCPModel;
  conclusion?: string;
  result?: ObservationModel[];
  procedure?: string;
}

export enum CommunicationTopic {
  REFILL_REQUEST = "prescription-refill-request",
  REPORT_LABS = "report-labs",
  SUMMARY_REPORT = "summary-report",
}
/*
http://hl7.org/fhir/R4/valueset-communication-topic.html

prescription-refill-request	Prescription Refill Request	The purpose or content of the communication is a prescription refill request.
progress-update	Progress Update	The purpose or content of the communication is a progress update.
report-labs	Report Labs	The purpose or content of the communication is to report labs.
appointment-reminder	Appointment Reminder	The purpose or content of the communication is an appointment reminder.
phone-consult	Phone Consult	The purpose or content of the communication is a phone consult.
summary-report */
