import styled from "styled-components";
import { Color, Elevation, Size, Tint } from "../tokens";
import { HCPModel } from "../../models/HCPModel";
import Typography from "./Typography/Typography";
import UserProfile from "./UserProfile";
import useTint from "../../hooks/useTint";

const CommentContainer = styled.div<{ tint: Tint }>`
  padding: ${Size.M}px;
  background-color: ${(props) => props.tint.light};
  border-radius: ${Size.M}px;
  color: ${Color.WHITE};
  position: relative;

  /*box-shadow: ${Elevation.M};

&::before {
  content: '';
  position: absolute;
  bottom: -${Size.S}px;
  left: ${Size.M}px;
  border-width: ${Size.S}px;
  border-style: solid;
  border-color: transparent transparent ${Color.WHITE} transparent;
  transform: rotate(-45deg);

box-shadow: ${Elevation.M};
}
*/
`;

interface CommentProps {
  text: string;
  author?: HCPModel;
  date?: string;
}
const Comment = ({ text, author, date }: CommentProps) => {
  const tint = useTint(author?.id);
  return (
    <div>
      <CommentContainer tint={tint}>
        <Typography.h3>
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </Typography.h3>
      </CommentContainer>
      {author && <UserProfile person={author} details={date} />}
    </div>
  );
};

export default Comment;
