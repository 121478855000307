import dayjs from "dayjs";
import Button from "../Button";
import Typography from "../Typography/Typography";
import { Color, Size } from "../../tokens";
import styled from "styled-components";
import Section from "../Layout/Section";
import { API_TIME_FORMAT } from "../../../../services/constants";

interface TimeslotGroupProps {
  day: string;
  slots: number[];
  onSlotSelected?: (slot: number) => void;
}

function getTime(slot: number) {
  return dayjs(String(slot), API_TIME_FORMAT).format("HH:mm");
}

function getDay(day: string) {
  return dayjs(day, "YYYYMMDD").format("dddd D MMM");
}

const Grid = styled.div`
  display: grid;
  margin-top: ${Size.M}px;
  position: relative;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: ${Size.XS}px;
`;
const Item = styled.div`
  padding: ${Size.XS}px ${Size.M}px;
  background-color: ${Color.NEUTRAL_200};
  border-radius: ${Size.XS}px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${Color.NEUTRAL_300};
  }
`;

export default function TimeslotGroup({
  day,
  slots,
  onSlotSelected = () => {},
}: TimeslotGroupProps) {
  return (
    <Section title={getDay(day)}>
      <Grid>
        {slots.map((slot) => (
          <Item key={slot} onClick={() => onSlotSelected(slot)}>
            {getTime(slot)}
          </Item>
        ))}
      </Grid>
    </Section>
  );
}
