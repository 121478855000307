import { useSearchParams } from "react-router-dom";

export default function useUpdateSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedParams = new URLSearchParams(searchParams.toString());
  return (key: string, value?: string) => {
    if (value) {
      updatedParams.set(key, value);
    } else {
      updatedParams.delete(key);
    }
    setSearchParams(updatedParams);
  };
}
