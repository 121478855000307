import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ScreenFlow from "../../../../ui/components/ScreenFlow/ScreenFlow";
import HCPSelectionScreen from "./HCPSelectionScreen";
import LocationSelectionScreen from "./LocationSelectionScreen";
import TypeSelectionScreen from "./TypeSelectionScreen";
import SlotSelectionScreen from "./SlotSelectionScreen";
import ModalSheetNavigationButton from "../../../../ui/components/ModalSheetNavigationButton";
import ConfirmationScreen from "./ConfirmationScreen";
import { useGetAppointmentTypesQuery } from "../../api/medispring";
import { AppointmentTypeAndPlace } from "@icure/api";
import { getUniquePlacesFromAppointmentTypes } from "../../utils/utils";
import LoadingScreen from "./LoadingScreen";

enum FlowSteps {
  LOADING = 0,
  HCP_SELECTION = 1,
  LOCATION_SELECTION = 2,
  TYPE_SELECTION = 3,
  SLOT_SELECTION = 4,
  CONFIRMATION = 5,
}

const screenTitles = [
  "Chargement de l'agenda",
  "Choisir un soignant",
  "Selectionnez votre lieu",
  "Type de rendez-vous",
  "Prendre rendez-vous",
  "Confirmation",
];

function getStep(
  loading: boolean,
  hcp: string | null,
  place: string | null,
  type: string | null,
  slot: string | null,
  types: AppointmentTypeAndPlace[] | undefined
) {
  const places = getUniquePlacesFromAppointmentTypes(types);

  if (loading) return FlowSteps.LOADING;

  if (!hcp) return FlowSteps.HCP_SELECTION;

  if (!place && places.length > 1) return FlowSteps.LOCATION_SELECTION;
  if (!type && types?.length! > 1) return FlowSteps.TYPE_SELECTION;
  if (!slot) return FlowSteps.SLOT_SELECTION;

  return FlowSteps.CONFIRMATION;
}

const CreateAppointmentFlow = () => {
  const [searchParams] = useSearchParams();

  const hcp = searchParams.get("hcp");

  const { data: types, isLoading } = useGetAppointmentTypesQuery(hcp ?? "", {
    skip: !hcp,
  });

  const navigate = useNavigate();

  const screen = getStep(
    isLoading,
    searchParams.get("hcp"),
    searchParams.get("place"),
    searchParams.get("type"),
    searchParams.get("slot"),
    types
  );
  return (
    <>
      <ScreenFlow
        activeIndex={screen}
        title={screenTitles[screen]}
        persistNavBar
        leftButtons={[
          <ModalSheetNavigationButton
            key="nav-back"
            home={false}
            back={!!searchParams.get("hcp")}
            onClick={() => navigate(-1)}
          />,
        ]}
      >
        <LoadingScreen />
        <HCPSelectionScreen />
        <LocationSelectionScreen />
        <TypeSelectionScreen />
        <SlotSelectionScreen />
        <ConfirmationScreen />
      </ScreenFlow>
    </>
  );
};

export default CreateAppointmentFlow;
