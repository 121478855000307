import { EzoResource } from "./EzoResource";
import { HCPModel } from "./HCPModel";
import { PatientModel } from "./PatientModel";
import { DiagnosticReportModel } from "./DiagnosticReportModel";
import { DocumentResource, DocumentType } from "./DocumentResource";

export interface DocumentReference extends EzoResource {
  type: DocumentType;
  attachment?: string;
  created: number;
  content?: DocumentContent;
  title?: string;
  author?: HCPModel;
  description?: string;
  context?: DocumentContext;
  subject?: PatientModel;
}

export interface DocumentContent {
  id: string;
  attachment?: string | ArrayBuffer;
  format?: string;
  name?: string;
}

export interface DocumentContext {
  related?: DiagnosticReportModel;
}

/*
http://hl7.org/fhir/DSTU2/valueset-c80-doc-typecodes.html

*/

export const mostRecent = (a: DocumentResource, b: DocumentResource) =>
  a.issued > b.issued ? -1 : 1;

export const mostRecentlyShared = (a: DocumentResource, b: DocumentResource) =>
  a.commented && b.commented && a.commented > b.commented ? -1 : 1;
