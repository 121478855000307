import { ResourceType } from "../types/ResourceType";

export const GRID_SIZE = 8;

export enum Size {
  XS = GRID_SIZE / 2, // 4
  S = GRID_SIZE, // 8
  M = GRID_SIZE * 2, // 16
  L = GRID_SIZE * 4, // 24
  XL = GRID_SIZE * 5 + Size.XS, // 44
  XXL = GRID_SIZE * 8, // 64
}

export enum Breakpoint {
  MOBILE = 480,
  TABLET = 768,
  DESKTOP = 1200,
}

export enum Elevation {
  XS = "0px 1px 1px rgba(9, 30, 66, 0.25),0px 0px 1px rgba(9, 30, 66, 0.31);",
  S = "rgba(0, 0, 0, 0.16) 0.5px 1px 4px;",
  M = "0px 0.5985091924667358px 1.1970183849334717px 0px rgba(0, 0, 0, 0.02),0px 2.010267734527588px 4.020535469055176px 0px rgba(0, 0, 0, 0.02),0px 9px 18px 0px rgba(0, 0, 0, 0.02);",
  M2 = "0px 4px 70px rgba(114, 114, 114, 0.25);",
  L = " 0px 27px 80px rgba(0, 0, 0, 0.02), 0px 8.13971px 24.1177px rgba(0, 0, 0, 0.0456112), 0px 3.38082px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.22278px 3.62304px rgba(0, 0, 0, 0.0243888);",
}

export enum Easing {
  CUBIC = "0.3s cubic-bezier(0.25, 1, 0.25, 1) forwards",
  IN_OUT = "0.3s ease-in-out",
  SCREEN = ".3s cubic-bezier(0.42, 0, 0.58, 1)",
}

export enum Color {
  ACCENT = "#6832DD",
  BACKGROUND = "#ECF6FF",
  DESTRUCTIVE = "#FF0000",
  WHITE = "#fff",

  NEUTRAL_900 = "#120229",
  NEUTRAL_800 = "#1E0048",
  NEUTRAL_600 = "#463074",
  NEUTRAL_500 = "#7F6EA4", //"#7F6EA4",
  NEUTRAL_400 = "#D7D6DB",
  NEUTRAL_300 = "#EFECF4",
  NEUTRAL_200 = "#E5E4E8",
  NEUTRAL_100 = "#F2F2F7",

  BLUE_100 = "#1867E2",
  BLUE_200 = "#183DE2",
  BLUE_300 = "#1C18E2",
  BLUE_400 = "#3618E2",
  BLUE_500 = "#6018E2",
  BLUE_600 = "#8A18E2",

  RED_100 = "#F126DF",
  RED_200 = "#F126B5",
  RED_300 = "#F1268B",
  RED_400 = "#F12671",
  RED_500 = "#F12647",
  RED_600 = "#F14926",

  YELLOW_100 = "#FF5C00",
  YELLOW_200 = "#FF9100",
  YELLOW_300 = "#FFC500",
  YELLOW_400 = "#FFC500",
  YELLOW_500 = "#E3FF00",
  YELLOW_600 = "#AFFF00",

  GREEN_100 = "#20CC79",
  GREEN_200 = "#20CC9D",
  GREEN_300 = "#20CCC0",
  GREEN_400 = "#20C2CC",
  GREEN_500 = "#209ECC",
  GREEN_600 = "#207BCC",

  GREEN = "#62D649",
  GREEN_LIGHT = "#C5FFF1",
  GREEN_DARK = "#22AA64",
  YELLOW = "#FFF8DD",
  YELLOW_DARK = "#FFDA18",
  PINK_100 = "#F6E2FF",
  PINK_600 = "#7E42FC",
  BLUE = "#29D9FF",
  BLUE_DARK = "#0E7AD4",
  RED = "#FF008A",
  PINK_300 = "#FF00E5",
  ORANGE = "#FF7A00",

  MODAL_BACKGROUND = "rgba(40, 40, 50, 0.8)",
}

export interface Tint {
  light: Color;
  dark: Color;
}

export const Tints = {
  WHITE: { dark: Color.ACCENT, light: Color.WHITE } as Tint,
  NEUTRAL: { dark: Color.NEUTRAL_600, light: Color.NEUTRAL_500 } as Tint,
  BLUE: { dark: Color.WHITE, light: Color.BLUE } as Tint,
  BLUE_DARK: { dark: Color.WHITE, light: Color.PINK_300 } as Tint,
  GREEN: { dark: Color.WHITE, light: Color.GREEN_DARK } as Tint,
  GREEN_LIGHT: { dark: Color.WHITE, light: Color.GREEN } as Tint,
  YELLOW: { dark: Color.WHITE, light: Color.YELLOW_DARK } as Tint,
  YELLOW_LIGHT: { dark: Color.YELLOW_DARK, light: Color.YELLOW } as Tint,
  PINK: { dark: Color.WHITE, light: Color.PINK_600 } as Tint,
  PINK_LIGHT: { dark: Color.NEUTRAL_600, light: Color.PINK_100 } as Tint,
  NEUTRAL_LIGHT: { dark: Color.NEUTRAL_500, light: Color.NEUTRAL_100 } as Tint,
  DARK: { dark: Color.WHITE, light: Color.NEUTRAL_800 } as Tint,
  RED: { dark: Color.WHITE, light: Color.RED } as Tint,
  ORANGE: { dark: Color.WHITE, light: Color.ORANGE } as Tint,

  PRESCRIPTION: { dark: Color.WHITE, light: Color.BLUE_100 } as Tint,
  REPORT: { dark: Color.WHITE, light: Color.BLUE_100 } as Tint,
  LAB_RESULT: { dark: Color.WHITE, light: Color.BLUE_100 } as Tint,
  MESSAGE: { dark: Color.WHITE, light: Color.BLUE_100 } as Tint,

  MEDICATION: { dark: Color.WHITE, light: Color.RED_100 } as Tint,
  VACCINATION: { dark: Color.WHITE, light: Color.RED_200 } as Tint,

  APPOINTMENT: { dark: Color.WHITE, light: Color.YELLOW_200 } as Tint,
  PREVENTION: { dark: Color.WHITE, light: Color.YELLOW_300 } as Tint,
  FORM: { dark: Color.WHITE, light: Color.YELLOW_100 } as Tint,

  PROVIDER: { dark: Color.WHITE, light: Color.GREEN_100 } as Tint,
};

export const ResourceColors = {
  [ResourceType.APPOINTMENTS]: Color.YELLOW_200,
  [ResourceType.DOCUMENTS]: Color.BLUE_100,
  [ResourceType.MEDICATIONS]: Color.RED_100,
  [ResourceType.PROVIDERS]: Color.GREEN_100,
};
