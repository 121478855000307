import React, { createContext, useContext, useState } from "react";

export interface ToastProps {
  id?: string;
  active?: boolean;
  message?: string;
  title?: string;
  type?: ToastTypeEnum;
}

export enum ToastTypeEnum {
  DEFAULT = "default",
  SUCCESS = "success",
  ERROR = "error",
}

type ToastContextProps = {
  toasts: ToastProps[];
  addToast: (toast: ToastProps) => void;
};

export const ToastContext = createContext<ToastContextProps>({
  toasts: [],
  addToast: () => {},
});
