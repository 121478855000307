import Typography from "./Typography/Typography";
import { Size } from "../tokens";
import styled from "styled-components";
import Icon from "./Icon";
import { FunctionComponent } from "react";

interface InformationItemProps {
  title: string;
  icon: FunctionComponent;
}

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${Size.S}px;
  gap:${Size.S}px;
  font-size: 18px;
  > img {
    margin-right: ${Size.S}px;
  }
  }
`;

export default function InformationItem({ icon, title }: InformationItemProps) {
  return (
    <>
      <Item>
        <Icon asset={icon} />
        <Typography.body>{title}</Typography.body>
      </Item>
    </>
  );
}
