import styled, { keyframes } from "styled-components";
import { Breakpoint, Color, Easing, Elevation, Size } from "../../ui/tokens";
import { useParams } from "react-router-dom";
import { ReactNode } from "react";

const translateIn = keyframes`
  from {
    transform: translateX(0);

  }
  to {
    transform: translateX(calc(100% - 80px));
    
  }
`;
const translateOut = keyframes`
  from {
    transform: translateX(100%);

  }
  to {
    transform: translateX(0px);
    
  }
`;
const MainContentContainer = styled.div<{ isDetails: boolean }>`
  position: relative;
  background-color: ${Color.NEUTRAL_100};
  /*background-color:  ${(props) =>
    props.isDetails ? Color.WHITE : Color.NEUTRAL_100};*/
  overflow: hidden;
  transition: all ${Easing.CUBIC};
  /* border: 1px solid ${Color.NEUTRAL_300};
  box-shadow: ${Elevation.XS};*/
  flex: 1;

  @media (max-width: ${Breakpoint.MOBILE}px) {
    width: 100%;
    position: relative;
    box-shadow: none;
    border-top: none;
    animation: ${translateOut} ${Easing.CUBIC};

    &.sidebar-active {
      animation: ${translateIn} ${Easing.CUBIC};
    }
  }
  @media (min-width: ${Breakpoint.MOBILE}px) {
    max-width: 960px;
    border-radius: 16px;
    flex: 1;
  }
`;

const MainContent = ({
  children,
  className,
}: {
  children: ReactNode;
  className: string;
}) => {
  const { resourceId } = useParams();
  return (
    <MainContentContainer isDetails={!!resourceId}>
      {children}
    </MainContentContainer>
  );
};

export default MainContent;
