import { Size } from "../../tokens";
import ListItemShimmer from "./ListItemShimmer";
import ShimmerBlock from "./ShimmerBlock";

export default function LabResultsShimmer({ length = 3 }: TableShimmerProps) {
  return (
    <div
      style={{
        marginTop: `${Size.M}px`,
        marginBottom: `${Size.S}px`,
        padding: `${Size.S}px`,
      }}
    >
      <ShimmerBlock delay={0} styleName="small" />
      <TableShimmer length={length} />
    </div>
  );
}

interface TableShimmerProps {
  length?: number;
}
export function TableShimmer({ length = 3 }: TableShimmerProps) {
  return <></>;
}
