import Assets from "../../assets";
import { Tints } from "../../tokens";
import Avatar from "../Avatar/Avatar";
import "./success.css"
export default function Warning() {
    return (

       <Avatar
                  large
                  icon={<img src={Assets.icons!.warning} />}
                  tint={Tints.YELLOW_LIGHT}
                />
     
  
       
    );
  }
  