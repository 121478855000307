export const en = {
  home: {
    MEDISPRING: "Medispring",
    DESCRIPTION:
      "The DMI in the heart of your medical practice, run by doctors",
    UPDATE: {
      TITLE: "Update available",
      RESTART: "Restart Medispring.",
      OR: "or",
      LATER: "Update later.",
    },
  },
  menu: {
    ITEM: {
      DASHBOARD: "Dashboard",
      PATIENTS: "List of patients",
      MAILING: "Receiving mail / eHealthBox",
      CALENDAR: "Agenda",
      MANAGEMENT: "Tools",
      INVOICING: "Billing",
    },
  },
  login: {
    SUCCESS: "You are connected to",
    FAILURE: "Connection failure",
    LOGIN: "Login",
    USER: "User",
    PASSWORD: "Password",
    PASSWORD_EH_HEALTH: "Password eHealth",
    CONNECTION: "Connect",
    OPTIONS: {
      "1733": "Flux 1733",
      MORE_OPTIONS: "Plus d'options",
      FHC: "FHC",
      ICURE: "iCure",
      LOGIN_TYPE: "Connection Type",
      OFFICE: "Cabinet",
      MMF: "Medical House lump",
      GUARDPOST: "GUARDPOST",
      RLM: "RLM",
    },
    WRONG_ATTEMPTS_EXCEEDED:
      "You entered the wrong password {{attempts}} times. The next attempt is available after {{minutes}} minutes.",
    CUSTOM_URL: "Specific server",
  },
  datepicker: {
    year: "year",
    month: "months",
    day: "day",
  },
  "contact-compatibility": {
    OMISSION_OF_MEDICAL_DATA:
      "At the request of the patient, some medical data is not included in this transaction: ",
    authorize: "yes.",
    refuse: "no.",
  },
  "private-home": {
    AUTO_CLOSE_KNOWN:
      "The patient {{name}} is automatically closed because too many patients were open",
    AUTO_CLOSE_UNKNOWN:
      "A patient is automatically closed because too many patients were open",
    PRELOAD: {
      TITLE: "Loading the application",
      CONFIGURATION: "Loading the configuration",
      BEOOGO: "Connection to Beoogo",
      EHEALTH: "Connection to the eHealth",
      ERROR: "Error",
    },
  },
  "management-home": {
    TABS: {
      SETTINGS: "Settings",
      DATABASE: "Database",
      DOCUMENTS: "Documents",
      TOOLS: "Tools",
    },
    HCPS: "Stakeholders",
    USERS: "Users",
    MAINTENANCES: "Maintenances",
    SECURITY: "Security",
    PRINTERS: "Printers",
    CLASSIFICATION_TEMPLATES: "Classification templates",
    MAGISTRAL: "The extemporaneous",
    TEMPLATING: "Documents type",
    KEYWORDS: "Keywords",
    KEYWORD_AUTOCOMPLETE: "Auto-completion",
    INVOICING: "Attestations",
    EFAC: "Facturation eFact",
    MYCARENET: "MyCareNet Conversation",
    E_DMG: "eDMG",
    E_DMG_PATIENT_LIST: "List of patients",
    E_DMG_OTHERS: "Renewals and clotures",
    SCAN_IMPORT: "Import scans",
    ACCESS_LOG: "Access Log",
    CONNECTIONS: "Connections",
    ACCESS_LOG_ALL: "All logs",
    ACCESS_LOG_USER: "By user",
    ACCESS_LOG_PATIENT: "By patient file",
    PRIVATE_MEDICATION: "Private",
    SUGGESION_HE: "Suggestion for es creation",
  },
  mycarenet: {
    STUCK: "You're blocked? Download this file and send it to the helpdesk.",
  },
  "efact-status": {
    NOT_EFACT: "Not a eFact",
    ERRORS_IN_PRELIMINARY_CONTROL: "Error detected",
    ERROR: "In error",
    WARNING: "Partially accepted",
    SUCCESS: "Accepted",
    REJECTED: "Rejected",
    ACCEPTED: "Accepted for treatment",
    TREATMENT: "In treatment",
    SUBMITTED: "Transmitted",
    RECEIVED: "Received",
    SENT: "Sent",
    WAITING: "Waiting",
    ARCHIVED: "Archived",
    UNKNOWN: "Status unknown",
  },
  "invoicing-summary": {
    INVOICING: "Attestations",
    PENDING_INVOICES: "Pending invoices",
    EFAC: "Facturation eFact",
    EFAC_TO_BE_SENT: "To send",
    EFAC_PENDING: "Waiting",
    EFAC_TO_BE_CORRECTED: "To correct",
    EFAC_TREATED: "Processed",
    EFAC_ARCHIVED: "Archived",
    EFAC_INVOICES: "Factures eFact",
    EFAC_BATCH: "Sent eFact (Lots)",
  },
  "invoicing-excel": {
    EXPORT_PATIENTS: "Export patients",
    EXPORT: "Export Excel",
    EH_NOT_CONNECTED: "Connect with eHealth to include patients in the export",
    GEN_0:
      "Generation of Excel file in progress. Please wait, this may take a lot of time.",
    GEN_50: "Generation of the Excel file 50%",
    GEN_100: "Generation of the Excel file 100%",
    GEN_ERR:
      "Error during the generation of the Excel file. The generate on a shorter period of time might avoid the error",
    DETAILS: "Details",
    GENINS_FAIL:
      "The insurability of the patient {{name}} failed. The mutual will not be present in the export for this patient.",
    CERTIFICATES: "Certificates",
  },
  "patients-home": {
    TITLE: "List of patients",
    FIND_BY_NAME: "Find by name",
    MERGE_PATIENTS: "Merge patients",
    MERGE_PATIENTS_WARNING:
      "Please confirm that you want to merge {{patient}} with {{duplicate}}. Warning: this action is irreversible.",
    CONFIRM: "Confirm",
    CANCEL: "Cancel",
    REFRESH: "Refresh",
    LAST_NAME: "Name",
    FIRST_NAME: "First name",
    DATE_OF_BIRTH: "Date of birth",
    SSIN: "National number",
    ADDRESS: "Home",
    CONTACT: "Contact",
    INCLUDE_INACTIVES: "Include the patients who are inactive",
    DUPLICATES: "Duplicates",
    EXPORT: "Export...",
    MAILING: "Mailing",
    MAILING_TITLE: "Send a message to a selection of patients",
    ADVANCED_SEARCH: "Advanced search",
    BIOMETRY: "Biometrics",
    SERVICES: "Services",
    MERGE_ERROR_PATIENT_OPENED:
      "Please close the tab '{{tabName}}' before merging",
    DISPLAY_MODE: {
      MODE: "Mode",
      NORMAL: "Normal",
      PREVENTION: "Prevention",
      BIOMETRY: "Biometrics",
      SERVICES: "Services",
      BREAST_CANCER_PREVENTION: "Breast Cancer Prevention",
      CERVIX_CANCER_PREVENTION: "Cervix Cancer Prevention",
      COLORECTAL_CANCER_PREVENTION: "Colon Cancer Prevention",
      INFLUENZA_FLU_PREVENTION: "Flu Prevention",
      HEALTH_ELEMENT: "Health Element",
      COVID19_QUERY: "Covid-19 high-risk patients",
      VACCINATION_COVID: "Vaccination Covid",
    },
    YES: "Yes",
    NO: "Non",
    GENDER: "Genre",
    AGE: "Age",
    MAX_DUE_DATE: "Due before",
    VACCINATION_COVID: {
      GET_EVENTS: "Get last informations",
      MEETING_DATE: " Next meeting date",
      STATUS: "Status",
      FIRST_DOSE_DATE: "First dose date",
      SECOND_DOSE_DATE: "Second dose date",
      OTHER_INFO: "Other information",
      NO_INFO: "No information",
      ACTIVATED: "Activated",
      NOT_ACTIVATED: "Not activated",
    },
    COVID19: {
      SEND: "Send list",
      SENT: "List sent",
      SEND_OK: "Liste sent",
      SEND_ERROR: "Error sending the list",
      SEND_NOT_AVAILABLE:
        "Covid vaccination service not available. Try again later.",
      PRIORITY: {
        "1": {
          "1864": "Priority 1 - 18-64 years",
          "4564": "Priority 1 - 45-64 years",
        },
        "3": {
          "1844": "Priority 3 - 18-44 years",
        },
      },
    },
  },
  "access-log": {
    LATEST_PATIENTS: "Last patients open",
    LAST_DATE: "Last access date",
    OPEN_PATIENT: "Open the folder of the patient",
  },
  "guardPost-access-log": {
    LAST_VISITS: "Recent visits",
    CONNECT: "Log in to eHealth to send the reports of the guard.",
    X_REPORT_OK: "{{ c }} reports ready to be sent",
    "1_REPORT_OK": "1 report ready to be sent",
    X_SEND: "Send {{ c }} reports",
    "1_SEND": "Send the report",
    FILTER_BY: "Filter by",
    PATIENT: "Patient",
    GUARD_HCP: "Doctor on call",
    DEST_HCP: "Destinataire",
    CONSULT_DATE: "Date of visit",
    STATUS: "Status",
    STATUT_WAIT_DEST: "Waiting for a recipient",
    STATUT_WAIT_REPORT: "Waiting for the report",
    STATUT_WAIT_SEND: "Waiting to be sent",
    SEND: "Send",
    SENT: "Sent",
    NO_DOCUMENT_IN_CONTACT:
      "No document is present in the contact. Contact the physician to prepare the report of the guard.",
    ERROR_MARK_SENT: "Impossible to mark the report as sent",
    ERROR_NOT_SENT: "The message has not been sent",
    ERROR: "An error occurred",
    ERROR_CONTACT_GET: "Unable to retrieve contacts",
    ERROR_PATIENTGET: "Impossible to obtain the data of a patient",
    ERROR_REF_HCP_GET:
      "Impossible to obtain the data of the doctor triatant of the patient",
    ERROR_RESP_HCP_GET: "Unable to obtain data of the responsible contact",
    ERROR_NO_DEST_NIHII:
      "The speaker destinatire {{hcp}} has no riziv number defined in its data sheet",
    ERROR_UPDATE_PATIENT: "It is Impossible to put the patient at day",
    PATIENT_UPDATED: "Patient changed",
    AUTO_MAIL_SUBJECT: "CUSTODY",
  },
  "user-management": {
    ALL_USERS: "All users",
    FIND_USER_BY_NAME: "Find a user by name",
    NEW_USER: "New user",
    EDIT: "Edit",
    CREATE_USER: "Create a user",
    EDIT_USER: "Modify a user",
    DISABLE_USER: "Disable the user",
  },
  "hcp-management": {
    HCPS: "Stakeholders",
    FIND_BY_NAME: "Find by name",
    NEW_HCP: "New contributor",
    DELETE: "Delete",
    CONFIRM: "Do you want to delete this speaker?",
    PROCESSING: "Deleting speaker...",
    DONE: "Successfully removed speaker",
    NAME: "Name",
    SPECIALITY: "Specialty",
    LAST_NAME: "Family name",
    FIRST_NAME: "First name",
    CREATE_HCP: "Create a contributor",
    EDIT_HCP: "Change the speaker",
    EMAIL: "E-mail",
    LANDLINE: "Phone fixed",
    MOBILE: "Mobile phone",
    ADRESSE: "Address",
    PMG_MISSING_FIELD:
      "The field {{field}} is missing for your medical location.",
    PMG_MISSING_FIELDS:
      "The field {{field}} are missing for your medical location.",
    PRACTICIAN_MISSING_FIELD:
      "The field {{field}} of the practician is missing in your user master.",
    PRACTICIAN_MISSING_FIELDS:
      "The field {{field}} of the practician is missing in your user master.",
    ASSISTANTS_MISSING_FIELD:
      "The field {{field}} of the assistants is missing in your user master.",
    ASSISTANTS_MISSING_FIELDS:
      "The field {{field}} of the assistants is missing in your user master.",
  },
  "he-management": {
    CONFIRMATION: "Confirmation",
    SUGGESTED_ITEM: "Suggested item",
    CRITERIA_FOUND: "Criteria found",
    SSIN: "Ssin",
    PATIENT: "Patient",
    SUGGESTION_HE: "Suggestion of care items to add to the files",
    TYPE: "Potential problem",
    AGE: "Age",
    SAVE: "Create ES",
    SAVING: "Creation ES in progress",
    OR: "or",
    CREATION_OK: "Health elements created.",
    EXCLUDE: "Exclusion",
    DMG: "Dmg owner",
    FIND_BY_DMG_OWNER: "Find by dmg owner",
    criteria: {
      DRUG_CHRON_CLASS: "Chronic drugs of class",
      DRUG_CLASS: "Drugs of class",
      GLY: "Glycemy with an empty stomach",
      NGSP: "Glycosylated hemoglobin (ngsp)",
    },
    type: {
      cardiovascularProblems: "Cardiovascular problems",
      diabetes: "Diabetes",
      obesityProblems: "Obesity problems",
      respiratoryProblems: "Respiratory problems",
      renalFailure: "Renal failure",
    },
  },
  "mycarenet-management": {
    TITLE: "Conversations MyCareNet",
    SEARCH: "Search by references",
  },
  "current-user": {
    USER_DETAILS: "Details of the user",
    CONNECTED: "You are connected to the services such as eHealth.",
    DISCONNECTED: "You are not connected to services such as eHealth.",
  },
  "form-selector": {
    USER: "User",
    HCP: "Speaker",
    PRIVATE_KEY: "Private key",
    CERTIFICATE: "Certificate",
    DMG: "eDMG",
    LOGOUT: "Disconnect",
    CLEAN_DATA_ON_LOGOUT: "Clear my private data",
    E_HEALTH: "Connexion eHealth",
    E_DMG: "eDmg",
    EFACT: "eFact",
    UNSAVED_CHANGES: "The current form contains unsaved data.",
  },
  "eh-menu": {
    TITLE_MAIN: "eHealth",
    CONNEXION: "Connection",
    E_DMG: "eDMG",
  },
  "eh-cert-import": {
    CERTIFICATE: "Certificat eHealth",
    STATUS: {
      IMPORTED: "Imported",
      MISSING: "Missing",
    },
    IMPORT_YOUR_CERTIFICATE: "Import your certificate eHealth :",
    SELECT_FILE: "Choose a file",
    CONNECTION: "Connexion eHealth",
    SELECT_GROUP: "Please select your entity:",
    CONNECTION_BUTTON: "Se connecter à eHealth",
    PASSWORD: "Password eHealth",
    CERT_UPLOAD_SUCCESS: "Certificate eHealth successfully sent.",
    CERT_UPLOAD_FAILURE:
      "The sending of the certificate of eHealth has failed.",
    CONNECTED: "Connected",
    NOT_CONNECTED: "Not connected",
    INVALID_CERTIFICATE: "Certificate invalid.",
    ERROR_SIZE:
      "The size of your certificate does not seem reasonable. Its size (currently {{actual}} bytes) must be between {{min}} and {{max}}.",
    ERROR_DATE: "The date your certificate is not in {{y}} in recent years",
    ERROR_SSIN:
      "The'SSIN of the certificate ({{p12Ssin}}) does not match to your ({{hcpSsin}})",
    GUARDPOST_MODE: "Connection as a guard post",
    VALIDITY: "Validity",
    CERTIFICATE_NAME: "Certificate name",
    END_OF_VALIDITY: "End of validity",
    INSTRUCTIONS: "Instructions before connection",
    VALIDITY_WARNING_TEXT:
      "Attention, Your eHealth certificate expires in less than a month.",
    VALIDITY_ERROR_TEXT:
      "Your eHealth certificate has expired, you must renew it.",
    CERTIFICATE_RENEW_LINK_TEXT: "Request a new eHealth certificate.",
    CERTIFICATE_RENEW_LINK_VALUE:
      "https://www.ehealth.fgov.be/fr/esante/professionnels-de-la-sante/gestion-des-certificats-ehealth/presentation-generale",
  },
  "private-key-import": {
    PRIVATE_KEY: "Private key",
    STATUS: {
      IMPORTED: "Key imported : {{hcpId}}",
      MISSING: "Private key missing",
    },
    IMPORT_YOUR_PRIVATE_KEY: "Import your private key ({{hcpId}}) :",
    SELECT_FILE: "Choose a file",
    QR_IMPORT: {
      START: "Start the scan",
      CANCEL: "Cancel",
      RESTART: "Again",
      FAILED: "The scan failed.",
    },
    DOWNLOAD_KEY: "Download the private key",
    DELETE_KEY: "Delete the private key",
    KEY_FILES_IMPORTED: "Key imported successfully.",
    KEY_FILE_INVALID: "Key file is invalid.",
    CANCEL_IMPORT: "Cancel",
    EXPORT_ALL: "Export everything",
    DOWNLOAD_FILE: "Download the file",
  },
  "key-import-qr-code": {
    NUMBER_OF_CODES: "Nombre de QR codes",
    UNKNOWN_NUMBER: "Unknown",
    SCANNED_CODES: "QR codes retrieved",
    ERROR: {
      NO_DEVICE: "No camera available.",
      SCAN_FAILED: "The scan of the QR code has failed",
      SCAN_ERRORED: "An error occurred during the scan",
    },
  },
  "key-export-qr-code": {
    PRINT_KEY: "Print the key of the format QRCode",
    DOWNLOAD_KEY: "Download the key of the format QRCode",
    PDF: {
      KEY_FOR: "Private key for {{hcpId}}",
      PART_N: "Part {{n}}",
      INSTRUCTIONS:
        "This document contains sensitive information. To be stored in a secure and private location.",
    },
  },
  "key-pair-generation": {
    TITLE: "Welcome to the application Medispring",
    GENERATE_KEYS: "Generate my pair of keys",
    CLOSE: "Close",
    SUCCESS_MESSAGE:
      "Your pair of public and private key was successfully generated and downloaded in the file ",
    FAILURE_MESSAGE_ENCRYPTED:
      "Failed to save the key pair in your user profile!",
    FAILURE_GENERATION: "The key generation has failed.",
  },
  help: {
    TITLE: "Help",
    ALERT_INDEXATION: "The indexing is in progress, please wait.",
    TITLE_INDEXATION: "Current index",
    HELPDESK: "HelpDesk",
    PHONE: "Phone",
    MAIL: "Email",
    PHONE_VALUE: "078/077050 (free)",
    PMG: {
      PHONE_VALUE_OFF: "078/481.086",
      PHONE_VALUE_OFF_HOURS: "(week: 18h-22h, WE: 08h-20h)",
      PHONE_VALUE_BUSINESS: "078/077.050",
      PHONE_VALUE_BUSINESS_HOURS: "(week: business hours)",
    },
    MAIL_VALUE: "support@medispring.be",
    FAQ: "FAQ",
    FAQ_VALUE: "https://support.medispring.be/hc/fr",
    YOUTUBE: "See the videos of our Youtube channel",
    YOUTUBE_VALUE: "https://www.youtube.com/channel/UCDMPh0v-Kq76Ph0KVDk2hIA",
    YOUTUBE_VIEW: "See the videos",
    VERSION: "Version de medispring",
    VERSION_REF: "Version reference",
    VERSION_VIEW: "See what's new",
    LINK: "Link",
    LOCAL: "Local",
    REMOTE: "Remote",
    STATUS: "Status",
    DOC_COUNT: "# Documents",
    LATEST_REPLICATION: "Last replication",
    TITLE_NEW_VERSION: "New update",
    FAQ_VIEW: "See the questions/answers",
    UPDATE: "Update",
    UPDATE_VIEW: "To see the updates",
    MANUAL: "Medispring Help Manual",
    MANUAL_VALUE:
      "https://medispring-banner.s3-eu-west-1.amazonaws.com/fr/documents-annonces/Manuel_utilisateur.pdf",
  },
  "hcp-form": {
    PAYMENT_TYPE: "Payment type: ",
    PAYMENT_TYPE_UNDEFINED: "Undefined",
    PAYMENT_TYPE_INDIVIDUAL: "Indivual",
    PAYMENT_TYPE_CENTRALIZED: "Centralized",
    ASSISTANTS_BANK_ACCOUNT: "Shared bank account of the assistants",
    FIND_HCP: "Search for a speaker",
    BASIC_INFO: "General information",
    CONTACT: "Contact",
    FIRST_NAME: "First name",
    LAST_NAME: "Family name",
    GENDER: "Gender",
    PROFESSION: "Profession",
    SPECIALITY: "Specialty",
    SSIN: "National number",
    NIHII: "RIZIV number",
    OTHER: "Other",
    NOTES: "Notes",
    MASTER: "Master internship",
    ADDRESSES: "Addresses",
    ADDRESS: {
      TYPE: "Type",
      DESCRIPTION: "Description",
      STREET: "Street",
      HOUSE_NUMBER: "Number",
      POSTBOX_NUMBER: "Box",
      POSTAL_CODE: "Code postal",
      CITY: "City",
      COUNTRY: "Pays",
      TELECOMS: "Telecom",
    },
    CREATE_HCP: "Create the speaker",
    EDIT_HCP: "Change the speaker",
    EDIT_SUCCESS: "Speaker updated.",
    EDIT_FAILURE: "The update of the speaker has failed.",
    CREATE_SUCCESS: "Speaker created.",
    CREATE_FAILURE: "The creation of the speaker has failed.",
    CREATE_TELECOM: "New telecom",
    CREATE_ADDRESS: "New address",
    INSURABILITY: "Insurability",
    SOCIETY_NAME_ABR: "Company name (abbreviation)",
    SOCIETY_NAME: "Name of the company",
    MEDECIN_CONVENTION: "Convention",
    YES: "Yes",
    NO: "Non",
    PARTIAL: "Partially",
    IBAN: "WENT",
    BIC: "BIC",
    CBE: "BCE",
    BANK_ACCOUNT: "WENT",
    FACTURATION: "Billing",
    HCP_LIST: "List of caregivers",
    CBE_REQUIRED: "The ECB is required to send eFact batches.",
    IBAN_REQUIRED: "The IBAN is needed to send eFact lots.",
    ASSISTANTS_IBAN_REQUIRED: "Assistants IBAN not specified.",
    ASSISTANTS_BIC_REQUIRED: "Assistants BIC not specified",
    BIC_REQUIRED: "The BIC is necessary to send batches eFact.",
    GUARDPOST_SSIN: "National number of the GUARDPOST",
    GUARDPOST_NIHII: "RIZIV number of the GUARDPOST",
    GUARDPOST_NAME: "Name of the GUARDPOST",
    GUARDPOST_IBAN: "GUARDPOST IBAN Number",
    GUARDPOST_BIC: "GUARDPOST BIC Number",
    GUARDPOST_CBE: "GUARDPOST CBE Number",
    VACCINNET_GROUPID: "Vaccinnet grouppractice id",
    SPECIALITIES: {
      ADMINISTRATION: "Administration",
      CARE_GIVER: "Caregiver",
      ANATOMOPATHOLOGY: "Anatomopathology",
      ANESTHESIOLOGY: "Anesthesiology",
      SOCIAL_WORKER: "Social worker",
      PHARMA_ASSISTANT: "Pharmaceutical-technical assistant",
      AUDIOLOGIST: "Audiologist",
      BACTERIOLOGY: "Bacteriology",
      TRUSS_ORTHOTIST: "Bandagiste-orthotist",
      CLINICAL_BIOLOGY: "Clinical biology",
      CARDIOLOGY: "Cardiology",
      GENERAL_SURGERY: "General surgery",
      MAXILLOFACIAL_SURGERY: "Maxillo-facial surgery",
      PLASTIC_SURGERY: "Plastic surgery",
      STOMATOLOGICAL_SURGERY: "Stomatological surgery",
      CARDIOVASCULAR_SURGERY: "Cardiovascular surgery",
      ABDOMINAL_SURGERY: "Abdominal surgery",
      DENTISTRY: "Dentistry",
      DERMATOLOGY: "Dermatology",
      DIETETIC: "Dietary",
      ENDOCRINOLOGY: "Endocrinology",
      OCCUPATIONAL_THERAPIST: "Occupational therapist",
      GASTROENTEROLOGY: "Gastroenterology",
      GENETIC: "Genetic",
      GERIATRICS: "Geriatrics",
      GYNECOLOGY: "Gynecology / Obstetrics",
      HEMATOLOGY: "Hematology",
      NURSE: "Nurse(e)",
      NURSE_EDUCATOR: "Infirmier(e) educator",
      PHYSIOTHERAPY: "Physiotherapy",
      SPEECH: "Therapy",
      ACUTE_MEDICINE: "Medicine Acute",
      GENERAL_MEDICINE: "General medicine",
      persphysician: "General medicine",
      INTERNAL_MEDICINE: "Internal medicine",
      NUCLEAR_MEDICINE: "Nuclear medicine",
      PHYSICAL_MEDICINE: "Physical medicine / Physiotherapy",
      EXPERTISE_MEDICINE: "Expertise medicine",
      INSURANCE_MEDICINE: "Insurance medicine",
      TRAVEL_MEDICINE: "Travel medicine",
      NEONATOLOGY: "Neonatology",
      NEPHROLOGY: "Nephrology",
      NEUROSURGERY: "Neurosurgery",
      NEUROLOGY: "Neurology",
      NEUROPEDIATRICS: "Neuropediatrics",
      NEUROPSYCHIATRY: "Neuropsychiatry",
      NUTRITIONIST: "Nutritionist",
      ONCOLOGY: "Oncology",
      OPHTHALMOLOGY: "Ophthalmology",
      ORTHOPEDICS: "Orthopedics",
      ORTHOPTISTE: "Orthoptiste",
      OTORHINOLARYNGOLOGY: "Oto-Rhino-Laryngology",
      PEDIATRICS: "Pediatrics",
      PHARMACOLOGY: "Pharmacology",
      RESPIRATORY: "Pneumology",
      PODIATRIST: "Podologist",
      PUBLIC_HEALTH_PREVENTION: "Prevention Public Health",
      PROSTHETIST: "Prosthetist",
      PSYCHIATRY: "Psychiatry",
      MEDICAL_PSYCHOLOGY: "Medical psychology",
      RADIODIAGNOSTICS: "Diagnostic radiology / medical Imaging",
      RADIOTHERAPY: "Radiation therapy",
      RESUSCITATION: "Resuscitation",
      RHEUMATOLOGY: "Rheumatology",
      MIDWIFE: "Wise woman",
      STOMATOLOGY: "Stomatology",
      EMERGENCY: "Emergency",
      UROLOGY: "Urology",
      TOXICOLOGY: "Toxicology",
      TRANSPORT: "Transport of sick",
      ALGOLOGY: "Algology",
      GUARDHOUSE: "Guardhouse",
      REVALIDATION: "Revalidation",
      SENOLOGY: "Senology",
      DIABETOLOGY: "Diabetology",
      INFECTIOLOGY: "Infectiology",
    },
    INSURANCE_CLAIM: "Insurance Claim",
  },
  "telecom-form": {
    NUMBER: "Telecom",
    TYPE: "Type",
    DESCRIPTION: "Description",
  },
  "user-form": {
    NAME: "Name",
    LAST_NAME: "Family name",
    FIRST_NAME: "First name",
    LOGIN: "Login",
    NAME_PLACEHOLDER: "Name of the user",
    EMAIL: "E-mail",
    EMAIL_PLACEHOLDER: "E-mail address of the user",
    ROLES: "Roles",
    ROLES_PLACEHOLDER: "The roles of the user",
    PASSWORD: "Password",
    CONFIRM_PASSWORD: "Confirmation of the password",
    ACTIVE: "Active",
    CREATE_USER: "Create the user",
    EDIT_USER: "To change the user",
    EDIT_SUCCESS: "User updated.",
    EDIT_FAILURE: "The update of the user failed.",
    CREATE_SUCCESS: "User created.",
    CREATE_FAILURE: "The creation of the user failed.",
    INVALID_ROLES_REMOVED:
      "Roles unknown to the user have been removed ({{roles}}).",
    HUB_REGION: "Hubs region",
    WAL: "Wallonia",
    FLA: "Flanders",
    BRU: "Brussels",
    RSW: "RSW",
    RSB: "RSB",
    COZ: "Cozo + Vitalink",
  },
  "location-selector": {
    CHOOSE_LOCATION: "Select your entity",
    RESPONSIBLE: "Responsible",
    VALIDATE: "Validate",
    CANCEL: "Cancel",
  },
  "eh-dmg": {
    ALL: "All",
    INSCRIPTION: "Inscription",
    IBAN: "WENT",
    BIC: "BIC",
    DATE_ACTIVATION: "Date d'activation",
    VALIDATE: "Application for registration",
    CHECK: "Check the registration",
    SEVEN_MUTUALITY: "I use the same bank account for 7 mutual",
    COND_TITLE: "Terms of use of MyCareNet and DMG",
    READ_AND_ACCEPTED:
      "I have read and I accept the terms of use of MyCareNet and DMG.",
    CONDA:
      "As soon as he made the choice to use MyCareNet for the management of the DMG, the health care provider agrees to :",
    CONDA1:
      "Do not send notification MycareNet for a DMG that it has already attested to the patient (via the code nomenclature 102771).",
    CONDA2:
      "No longer attest to DMG via code nomenclature 102771 from the day following the registration date, neither the openings nor for extensions.",
    CONDA3:
      "Use the send notifications MyCareNet for any opening of a new DMG.",
    CONDA4:
      "Send notifications MyCareNet within 7 working days after delivery (consultation or visit) that gives the right to the DMG.",
    CONDB:
      "For a health care provider enrolled in MyCareNet for the management of the DMG, the insurer agrees to :",
    CONDB1:
      "Take into account the registration notification sent by a health care provider as the commitment of the provider to use the system MyCareNet for the management of all of its DMG.",
    CONDB2:
      "From the date of registration to MyCareNet : pay within 30 days all extensions administration for the current year and the previous year, when the conditions are met.",
    CONDB3:
      "Make an extension auto-accelerated for all DMG for which the conditions are fulfilled, within 30 days from the receipt of the certificate of delivery (consultation/visit).",
    CONDB4:
      "Pay openings DMG within 30 days from the receipt of the notification MyCareNet if the conditions are met.",
    CONDB5:
      "Refuse the certificates to DMG in respect of benefits to which the date is subsequent to the date of registration in MyCareNet.",
    CONDB6: "Send messages and closing and extension wisely.",
  },
  "eh-auth": {
    MISSING_USER: "Current user is missing.",
    MISSING_EH_CERTIFICATE: "Certificat eHealth manquant.",
    MISSING_NO_HCP: "Any of the speakers.",
    CONNECTION_SUCCESS: "Connection to services eHealth successful.",
    MISSING_KEY_STORE_ID: "Session expired.",
    NOT_CONNECTED: "You are not connected to services such as eHealth.",
    CONNECTED: "You are connected to the services such as eHealth.",
    SOMETHING_WRONG_HAPPENED:
      "An error has occurred. Verify that the information entered is correct and your internet connection.",
    BAD_PASSWORD: "Password is incorrect.",
    MISSING_EH_PASSWORD: "The password is blank.",
    ERROR_CERT_INFO: "Cannot obtain the certificate information.",
    CERTIFICATE_ALMOST_EXPIRED:
      "Attention, Your eHealth certificate expires in less than a month.",
    CERTIFICATE_EXPIRED:
      "Your eHealth certificate has expired, you must renew it.",
    NOT_ALLOWED:
      "The current eHealth connection doesn't support this action. Login mode: '{{mode}}'; Guard post flow: '{{guardFlow}}'",
    NOT_ALLOWED_SECTOR: "No available for this sector",
  },
  _ENUM_: {
    "patient-blood-type": {
      "blood-type": {
        A: "A",
        B: "B",
        O: "O",
        AB: "AB",
        UNDETERMINED: "Undetermined",
      },
      "sub-blood-type": {
        CCDee: "CCDee",
        CcDee: "CcDee",
        ccDEE: "ccDEE",
        CcDEe: "CcDEe",
        ccDee: "ccDee",
        Ccdee: "Ccdee",
        ccDEe: "ccDEe",
        UNDETERMINED: "Undetermined",
      },
      rhesus: {
        PLUS: "Rh +",
        MINUS: "Rh -",
        UNDERTERMINED: "Undetermined",
      },
    },
    telecom: {
      type: {
        mobile: "Mobile phone",
        phone: "Phone fixed",
        email: "E-mail",
        fax: "Fax",
        skype: "Skype",
        im: "Instant messaging",
        medibridge: "MediBRIDGE",
        ehealthbox: "eHealthBox",
        apicrypt: "APICRYPT",
        web: "Web",
        print: "Imprimante",
        disk: "Disk",
      },
    },
    address: {
      type: {
        home: "Home",
        work: "Place of work",
        vacation: "Holiday",
        hospital: "Hospital",
        clinic: "Clinique",
        hq: "Headquarters",
        other: "Other",
      },
    },
    hcparty: {
      gender: {
        male: "Man",
        female: "Woman",
        unknown: "Unknown",
        indeterminate: "Undetermined",
        changed: "Changed",
        changedToMale: "Changed to man",
        changedToFemale: "Changed to woman",
        m: "Man",
        f: "Woman",
      },
    },
    "health-element": {
      nature: {
        medical: "Medical problem",
        surgical: "Surgical problem",
        allergy: "Allergy",
        familyrisk: "Family history",
        risk: "Risk factor medical",
        socialrisk: "Risk factor social",
        adr: "Intolerance",
        null: "Unknown",
      },
      status: {
        "active-relevant": "Active (relevant)",
        "active-irrelevant": "Active (without relevance)",
        inactive: "History",
        archived: "Archived",
      },
      certainty: {
        probable: "Probable",
        proven: "Proven",
        unprobable: "Doubtful",
        excluded: "Exclu",
        undefined: "Undefined",
      },
      severity: {
        normal: "Normal",
        verylow: "Very low",
        low: "Low",
        high: "High",
        veryhigh: "Very high",
      },
      temporality: {
        chronic: "Chronic",
        subacute: "Subaigu",
        acute: "Acute",
        remission: "In remission",
      },
      laterality: {
        left: "Left",
        right: "Right",
        bilateral: "Bilateral",
      },
      carepath: {
        "carepath-renalfailure": "Renal failure",
        "carepath-prediabetes": "Pre-diabetes",
        "carepath-diabetes2": "Type 2 diabetes",
      },
      confidentiality: {
        "not-confidential": "Not confidential",
        "no-export": "No export (visible to my group practice)",
      },
    },
    role: {
      MS_ADMIN: "Administrator",
      MS_PRACTICIAN: "Practitioner",
      MS_SECRETARY: "Secretary",
      MS_USER_MANAGER: "User manager",
    },
    prescription: {
      SPE: "Specialties",
      OTC: "OTC",
      MAP: "Masterful personal",
      MAF: "Masterful FTM",
      DCI: "DCI",
      CLA: "Class",
      PER: "Staff",
    },
    period: {
      d: "Days",
      wk: "Weeks",
      mo: "Months",
      a: "Years",
    },
    period_simple: {
      d: "day",
      wk: "week",
      mo: "months",
      a: "an",
    },
    period_simple_posology_1: {
      d: "every day",
      wk: "every week",
      mo: "every month",
      a: "each year",
    },
    period_simple_posology_n: {
      d: "all {{n}} days",
      wk: "all {{n}} weeks",
      mo: "all {{n}} months",
      a: "all {{n}} years",
    },
    filter: {
      planOfAction: "Plan d'action :",
      healthElement: "Item care :",
      medication: "Treatment :",
      diagnosis: "Diagnostic :",
      speciality: "Specialty :",
      date: "Dates :",
      vaccine: "Vaccine :",
      classification: "Classification :",
    },
    health_element_search: {
      ATC: "Atc",
      MEDICATION: "Specialty",
      PROBLEM: "Problem med.",
      PROCEDURE: "Procedure ideas.",
    },
    health_element_history_filter: {
      ACTIVE: "Active",
      INACTIVE: "History",
      RISK: "Risk factors",
      PREVENTION: "Prevention",
      ARCHIVED: "Archived",
      ALL: "All",
    },
    task_time_unit: {
      day: "days",
      week: "weeks",
      month: "months",
      year: "years",
    },
    task_time_unit_singular: {
      day: "day",
      week: "week",
      month: "month",
      year: "year",
    },
    patient: {
      deactivation: {
        deceased: "Deceased",
        moved: "Moved",
        other_doctor: "Has changed doctor",
        retired: "Retirement home",
        no_contact: "More contact for a long time",
        unknown: "Unknown",
        none: "No",
      },
      thirdpartytypes: {
        cpas: "CPAS",
        police: "Police",
        nationaldefense: "National defence",
        luxembourgnationalsecurity: "N° of national security luxembourg",
        other: "Other",
      },
      healthcareparty: {
        doctor: "Doctor",
        referral: "Reference",
        medicalhouse: "Medical House",
        retirementhome: "Retirement home",
        hospital: "Hospital",
        other: "Other",
        referringphysician: "Doctor",
      },
      medicalhousestop: {
        dead: "Death",
        end_inscr_by_patient_moved: "Late registration by the patient - Moving",
        end_inscr_by_patient_abroad:
          "Late registration by the patient - the Departure of foreign",
        end_inscr_by_patient_home: "Late registration by the patient - Home",
        end_inscr_by_patient_transferal_other_mh:
          "Late registration by the patient - Transfer to other MM",
        end_inscr_by_patient_not_happy:
          "Late registration by the patient - Conflict-Mecontent",
        end_inscr_by_patient_other:
          "Late registration by the patient - Various",
        end_insrc_by_mh_radiation: "Late registration by the MM - Radiation",
        end_insrc_by_mh_other: "Late registration by the MM - Other",
        end_agreement_denounced_by_non_registered_mutual:
          "End agreement terminated by the mutual - Non-registered",
        end_agreement_denounced_by_non_registered_mh:
          "The end agreement is terminated by the MM - Non-registered",
      },
    },
    guardPost_access_log: {
      "24h": "Last 24 hours",
      "48h": "The last 48 hours",
      "72h": "Last 72h",
      custom: "Date",
      all: "All",
      ready: "Ready to be sent",
      action_required: "Action required",
      sent: "Already sent",
    },
  },
  "patient-form": {
    PRINT: "Print tag",
    RESET: "Reset",
    CLOSE: "Cancel",
    CHOOSE: "Choose this patient",
    CREATE_NEW_PATIENT: "Create a new patient",
    CREATE_SUCCESS: "Patient created successfully.",
    FOUND_PATIENT: "Patient(s) found(s)",
    NO_PATIENT: "Ce patient n'existe pas encore.",
    WARNING_DUPLICATE:
      "Attention : a patient with data similar or the same already exists.",
    ERROR_DUPLICATE:
      "A patient already exists with this combination of last name, first name and date of birth, open the patient file",
    READ_DATA_FROM_EID: "Data read from the eID card",
    _comment: "concerns both patient-edit-form and patient-create-form",
    FIRST_NAME: "First name",
    LAST_NAME: "Family name",
    GENDER: "Gender",
    SSIN: "National number",
    CARDNUMBER: "ISI+ card/eId",
    SSIN_TAKEN: "A patient with this national number already exists",
    SSIN_WRONG_FORMAT: "SSIN is incorrectly formatted",
    SSIN_IS_NOT_IN_USE: "SSIN does not correspond to any patient",
    SSIN_UNKNOWN_ERROR: "An unknown error has occurred",
    REFERRING_PHYSICIAN: "Doctor",
    PREFERRED_USER: "User manager",
    ACTIVE: "Active",
    DEACTIVATION: "Reason of disabling",
    NOTES: "Notes",
    ADDRESSES: "Addresses",
    AN_ADDRESS: "Address",
    ADDRESS: {
      TYPE: "Type",
      DESCRIPTION: "Description",
      STREET: "Street",
      HOUSE_NUMBER: "Number",
      POSTBOX_NUMBER: "Box",
      POSTAL_CODE: "Code postal",
      CITY: "City",
      COUNTRY: "Pays",
      TELECOMS: "Telecom",
    },
    WORK_ADDRESS: "Work address",
    DATE_OF_BIRTH: "Date of birth",
    PLACE_OF_BIRTH: "Place of birth",
    DATE_OF_DEATH: "Date of death",
    PLACE_OF_DEATH: "Place of death",
    NATIONALITY: "Nationality",
    EXTERNAL_ID: "External id",
    PROFESSIONS: "Professions",
    SPOUSE_NAME: "Name of spouse",
    PARTNER_NAME: "The name of the spouse",
    CREATE_PATIENT: "Create the patient",
    EDIT_PATIENT: "Change the patient",
    SUBMIT: "Submit Form",
    NO_CODE: "The code you have entered does not correspond to any mutual.",
    INVALID_CODE: "Without valid code, the eFact and eAttest will be disabled.",
    UNKNOWN_OA_NUMBER: "Mutual unknown",
    MEDICAL_HOUSE: {
      TITLE: "Medical House",
      NAME: "Name",
      START_DATE: "Start Date",
      END_DATE: "End Date",
      BILL_START_DATE: "1st invoice",
      BILL_END_DATE: "End of invoice",
      DOCTOR: "Doctor",
      PHYSIOTHERAPIST: "Physio",
      NURSE: "Nurse",
      IS_MEDICAL_HOUSE: "Enrolled in medical home",
      IS_TRIAL: "In the trial period",
    },
    HOSPITALIZATION: {
      TITLE: "Hospitalisation",
      HOSPITAL: "Hospital",
      ADMISSIONS_DEPARTMENT: "Service d'admission",
      ADMISSIONS_DATE: "Date d'admission",
    },
    THIRD_NUMBER: "Number third party",
    THIRD_PARTY_TYPE: "Type du tiers",
    THIRD_PARTY_NUMBER: "Number",
    NAME: "Name",
    INAMI: "Inami",
    SPECIALITY: "Specialty",
    TYPE: "Type",
    PHCP: "Caregiver",
    ADD_PHCP: "Add a caregiver",
    NO_PHCP: "There is no caregiver associated with the patient.",
    DELETE: "Delete",
    HCP_ALREADY_PRESENT:
      "This caregiver is already present in the list of caregivers",
    INSURANCE_CLAIM: {
      PRIVATE: "Private",
      PROFESSIONAL: "Professional",
      TITLE: "Insurance Claim",
      RETURN: "Return",
      ADD: "+ Claim ",
      TYPE: "Type",
      PATIENT_IDENTIFICATION: "Patient Insurance ID",
      RECORD_NUMBER: "Record Number",
      OPENING_DATE: "Opening Date",
      CLOSING_DATE: "Closing Date",
      ADDITIONAL_INFORMATION: "Notes",
      INSURANCE_COMPANY: {
        TITLE: "Insurance Company",
        NAME: "Name",
        ADDRESS_INFORMATION: {
          TITLE: "Address Information",
          STREET: "Street",
          CITY_CODE: "City Code",
          CITY: "City",
          COUNTRY: "Country",
        },
      },
      CONTACT_PERSON: {
        TITLE: "Contact Person",
        NAME: "Name",
        EMAIL_ADDRESS: "Email Address",
        TEL: "Mobile",
        FAX: "Fax",
        ADDRESS_INFORMATION: {
          TITLE: "Address Information",
          STREET: "Street",
          CITY_CODE: "City Code",
          CITY: "City",
          COUNTRY: "Country",
        },
      },
    },
    SSIN_ALREADY_PRESENT: "This SSIN is already in your patient database",
    SSIN_NOT_IN_REGISTRY: "The SSIN is not in the national register",
    PATIENT_SEARCH: "Search patient",
    OPEN_PATIENT: "Open the patient file",
    NO_EHEALTH_CONNECTION:
      "You must be connected to the eHealth services to assign a patient search based on its SSIN/ISI+",
    SEARCH_NISS_BTN: "Search SSIN",
    TO_MANY_SEARCH_RESULTS: "too many result, please specify your search",
  },
  "rn-consult": {
    "phonetic-search": {
      title: "Search SSIN",
      search: "Search",
      tolerance: "Tolerance",
      cancel: "Cancel",
      "change-search": "Edit search",
      OPEN_BIS: "Request a BIS number",
      "skip-step": "Skip this step",
      "refine-your-search": "Please refine your search",
      error: "Error in the request",
      country: "Country",
      city: "City",
      RN: "National registry",
      RAD: "Radiated registry",
      BIS: "Bis registry",
      RAN: "Cancelled registry",
      NR: "",
      noResult: "No result found",
    },
    SSIN_EXISTS: "This SSIN is already used for a patient in Medispring",
  },
  "request-bis-form": {
    TITLE: "Request a BIS number",
    IDENTITY: "Identity",
    GENDER: "Gender",
    FIRST_NAME: "First Name",
    LAST_NAME: "Surname",
    DATE_OF_BIRTH: "Birthdate",
    NATIONALITY: "Nationality",
    address: {
      STREET: "Street",
      POSTAL_CODE: "Postal Code",
      CITY: "City",
      COUNTRY: "Country",
    },
    "date-info": {
      p1: "When you make a request to create a BIS number, if the patient does not know his exact date of birth, you can enter an incomplete birth date.",
      p2: "An incomplete date is a date that contains only the year or month and year.",
      p3: "Please note that if you indicate an incomplete date, you will have to fill in the complete information concerning the place of residence, country, city, postal code if the place is in Belgium as well as the name of the street.",
    },
    BIRTH_MID: "Birthplace",
    RES_MID: "Location",
    ACCEPT: "Accept",
    SAVE: "Save",
    CANCEL: "Cancel",
    REGISTRATION_TYPE: {
      BIRTH_DATE: "Based on birth date",
      ADDRESS_OUTSIDE_BELGIUM: "Based on a residence address outside Belgium ",
      ADDRESS_IN_BELGIUM: "Based on a residence address in Belgium ",
    },
    PATIENT_GENERATED_BIS_MODAL: {
      OK: "OK",
      GIVE_BIS_TO_PATIENT:
        "Please give this generated BIS number to the patient : <b>{{BIS_NUMBER}}</b>",
    },
  },
  "patient-search": {
    FIND_PATIENT: "Find a patient",
    SPOUSE_NAME: "Name of spouse",
    DEFAULT_FILTER: "Default",
    EXTERNAL_ID_FILTER: "Case number",
    FILTER: "Filter",
  },
  "hcparty-search": {
    FIND_HCPARTY: "Select an OP",
  },
  kendo: {
    dropdownlist: {
      noDataText: "No element",
    },
    multiselect: {
      noDataText: "No element",
    },
    grid: {
      groupPanelEmpty: "TODO (groupPanelEmpty)",
      pagerFirstPage: "First page",
      pagerLastPage: "Last page",
      pagerPreviousPage: "Previous Page",
      pagerNextPage: "Next Page",
      pagerItems: "elements",
      pagerOf: "of",
      pagerItemsPerPage: "items per page",
      pagerPage: "Page",
      filter: "Filter",
      filterEqOperator: "is equal to",
      filterNotEqOperator: "is different from",
      filterIsNullOperator: "is null",
      filterIsNotNullOperator: "is not null",
      filterIsEmptyOperator: "is empty",
      filterIsNotEmptyOperator: "n'est pas vide",
      filterStartsWithOperator: "begins with",
      filterContainsOperator: "contains",
      filterNotContainsOperator: "does not contain",
      filterEndsWithOperator: "eventually",
      filterGteOperator: "is greater than or equal to",
      filterGtOperator: "is greater than",
      filterLteOperator: "is smaller than or equal to",
      filterLtOperator: "is smaller than",
      filterIsTrue: "Yes",
      filterIsFalse: "Non",
      filterBooleanAll: "All",
      filterAfterOrEqualOperator: "is after or equal to ",
      filterAfterOperator: "is after",
      filterBeforeOperator: "is before",
      filterBeforeOrEqualOperator: "is before or equal to",
      filterFilterButton: "Filter",
      filterClearButton: "Clear",
      filterAndLogic: "AND",
      filterOrLogic: "OR",
      isLoading: "Loading",
      columnMenu: "Menu of the column",
      columns: "Columns",
      lock: "Key",
      unlock: "Unlock",
      sortAscending: "Sort by ascending order",
      sortDescending: "Sort by descending order",
      sortable: "Triable",
      sortedAscending: "Sorted in ascending order",
      sortedDescending: "Sorted in descending order",
      sortedDefault: "Sorted by default",
      columnsApply: "Apply",
      columnsReset: "Clear",
      noRecords: "No results",
    },
    calendar: {
      today: "Today",
    },
    datepicker: {
      today: "Today",
      toggle: "Select a date",
    },
    multiviewcalendar: {
      today: "Today",
    },
    dateinput: {
      decrement: "ALL",
      increment: "ALL",
    },
    timepicker: {
      now: "Now",
      cancel: "Cancel",
      accept: "Select",
      acceptLabel: "Select",
      cancelLabel: "Cancel",
      nowLabel: "Now",
      toggle: "Select a time",
    },
    upload: {
      invalidFileExtension: "File Extension is invalid!",
      cancel: "Cancel",
      clearSelectedFiles: "Delete the files sélectionés",
      dropFilesHere: "Drag files here",
      headerStatusUploaded: "Sending finished",
      headerStatusUploading: "Send",
      invalidFiles: "Invalid files",
      invalidMaxFileSize: "File size too large",
      invalidMinFileSize: "File size too small",
      remove: "Delete",
      retry: "Try again",
      uploadSelectedFiles: "Send the files sélectionés",
      select: "Choose a file",
    },
    slider: {
      decrement: "Decrease",
      increment: "Increase",
      dragHandle: "ALL",
      gerPage: "ALL",
      gerItemsPerPage: "ALL",
      gerOf: "ALL",
      gerItems: "ALL",
    },
    combobox: {
      noDataText: "No results found.",
    },
    numerictextbox: {
      decrement: "Decrease 1",
      increment: "Increase of 1",
    },
  },
  "patient-information": {
    ADDRESS: "Address",
    PHONE: "Phone",
  },
  "payment-types": {
    cash: "Liquid",
    wired: "Transfer",
    debitcard: "Bancontact",
  },
  "patient-insurability": {
    MISSING_SSIN: "The patient has no N° national",
    MISSING_DATA_MEDICAL_HOUSE: "No medical house",
    BAD_SSIN: "N° national invalide",
    MDA_ERROR:
      "We did not receive any answer in the allocated timeframe (error code 500). Try sending a new request or wait until the service is available again.",
    SSIN: "N° national",
    IO: "N° of mutual",
    IO_MEMBERSHIP: "N° holder",
    HOSPITALIZED: "Hospitalisation",
    MEDICAL_HOUSE: "Medical House",
    ON: "the",
    CHECK: "Check the insurability",
    CHECK_DATE: "At the date of the",
    CHECK_START_PERIOD: "Period from",
    CHECK_END_PERIOD: "to",
    CHECK_BUTTON: "Check",
    ADDITIONAL_INFO: "Additional information",
    BEWARE: "Attention",
    ADMISSION_DATE: "Date d'admission : ",
    ADMISSION_SERVICE: "Service d'admission : ",
    HOPITAL: "Hospital : ",
    CONTRAT_START: "Allowed(e) the",
    CONTRAT_END: "until the",
    MEDICAL_HOUSE_CONTRAT: "Contract in-house medical",
    CLOSED_BEFORE:
      "File ended before the date of the inspection {{date}} (death, change of affiliation,...)",
    DEATH_DATE: " - Patient who died on {{date}}",
    BIRTH_DATE: " - Patient born on {{date}}",
    START_AFTER:
      "Folder begins after the date of the inspection {{date}} (new born, new affiliate,...)",
    NOT_CONFORM: "Non-compliant (no doctor)",
    MKI: "MKI (physician + physical therapist + nurse)",
    MNI: "MNI (physician + nurse)",
    MKN: "MKN (physician + physio)",
    MNN: "(medical care)",
    CONTRAT_TYPE: "Contract type",
    ETENDUE: "Scope : ",
    UNKNOW: "Unknown",
    ERROR_CODE: "Error Code : ",
    ERROR_MESSAGE: "Error Message :",
    ERROR_SOURCE: "The Source of the error : ",
    CHECK_SUCCESS: "Data of insurability successfully obtained",
    ERROR_CHECK: "Member data could not be accessed",
    ERROR_CHECK_2: "Error during the verification of insurability",
    INSURABILITY_TITLE: "Member data",
    LABELS: {
      NAME: "Name",
      MUT_NAME: "Mutual",
      TITULARY_ID: "ID holder",
      START_DATE: "Start of validity",
      END_DATE: "End of validity",
      INSURABILITY_DATE: "Date of insurability",
      TC: "CT1 / CT2",
      BIM: "BIM",
      THIRD_PARTY_PAYER: "Third party payer",
    },
    LOCAL_UPDATE: "Automatic update of data insurance.",
    LOCAL_UPDATE_ERROR:
      "Automatic update of data insurance failed. Someone else may have modified the patient, try to reload the patient.",
    PATIENT_DATA: "Patient data",
    PATIENT_DATA_LABELS: {
      SSIN: "SSIN",
      LAST_NAME: "Surname",
      FIRST_NAME: "First Name",
      BIRTHDATE: "Date of Birth",
      GENDER: "Gender",
      GENDER_VALUES: {
        male: "Male",
        female: "Female",
        undefined: "Undefined",
        unknown: "Unknown",
        changed: "Changed",
        null: "",
      },
      DECEASED_DATE: "Date of Death",
    },
    PERIOD: "Period",
    PERIOD_LABELS: {
      REGISTRATION_NUMBER: "Registration number used to be",
      MUTUALITY: "Mutuality",
      CB1_CB2: "CT1 / CT2",
      COMMUNICATION_DATE: "Date of Communication",
      START_DATE: "Beginning of validity",
      END_DATE: "End of validity",
      PAYMENT_APPROVAL: "Payment Approval",
    },
    PAYMENT: "Payment",
    PAYMENT_LABELS: {
      THIRD_PARTY_PAYMENT: "Third-party payment",
      MISSING_DATA: "No third-party payment",
    },
    TYPE: "Type",
    START_DATE: "Start date",
    END_DATE: "End Date",
    KINE: "Kine",
    MEDICAL: "Medical",
    MEDICAL_HOUSE_ID: "Medical House nihii",
    NURSE: "Nurse",
    HOSPITALIZATION: "Hospitalization",
    HOSPITALIZATION_LABELS: {
      HOSPITAL_ID: "Hospital ID",
      SERVICE: "Service",
      ADMISSION_DATE: "Date of admission",
      MISSING_DATA: "No hospitalisation",
    },
    GENERAL_SITUATION: "General situation",
    GENERAL_SITUATION_LABELS: {
      EVENT: "Event",
      TRANSFER_DIRECTION: "Direction Transfer",
      TRANSFER_IO: "IO transfer",
      TRANSFER_DATE: "Transfer date",
      MISSING_DATA: "No specific events",
    },
    CARE_PATHWAY: "Care path",
    CARE_PATHWAY_LABELS: {
      PHYSICIAN: "Doctor",
      MEDICAL_HOUSE: "Medical House",
      SPECIALIST: "Specialist",
      CONTRACT_END_DATE: "End of contract date",
      MISSING_DATA: "No care path",
      DIABETES: "Diabetes",
      RENALINSUFFICIENCY: "Renal insufficiency",
    },
    CHRONIC_CONDITION: "Chronic condition",
    CHRONIC_CONDITION_LABELS: {
      YEAR: "Year",
      MISSING_DATA: "No chronic condition status",
    },
    REFERENCE_PHARMACY: "Reference pharmacy",
    REFERENCE_PHARMACY_LABELS: {
      PHARMACY: "Pharmacy",
      MISSING_DATA: "No reference pharmacy",
    },
    LAST_ASSURABILITY_CHECK: "Last Audit of Insurability on",
    MDA_STATUS_SUCCESS:
      "The request has been completed but some information is missing.",
    MEDISPRING_ISSUE: "Error in Medispring",
    IO_ISSUE: "Error in the insurer",
    BO_INVALID_REGNBR: "Registration number unknown to the insurance organism",
    BO_UNKNOWN_REGNBR: "Registration number unknown to the insurance organism",
    MISMATCHED_CONTACTTYPE: "The patient is not hospitalized",
    UNKNOWN_NISS_ROUTING: "SSIN is unknown to the OA",
    INVALID_PERIOD: "The requested period is invalid",
    SEND_TO_IM_EXCEPTION: "Connection problem with insurance organism",
    FACET_EXCEPTION: {
      insurability: "Unexpected Error",
      REFERENCEPHARMACY: "Information momentarily unavailable",
      CHRONICCONDITION: "Information momentarily unavailable",
      CAREPATH: "Information momentarily unavailable",
    },
    SAML_REQUEST_ERROR: "Error in SAML request",
    BUSINESS_ERROR: "Business error (facets or dimensions)",
    BUSINESS_VALIDATION_FAILED: "Business validation of the request failed",
    TECHNICAL_ERROR: "Technical error",
    INFO_MISSING:
      "Some information is missing (several facets have been requested but only part is available)",
  },
  "patient-layout": {
    BORN_ON: "Born(e) the",
    YEARS: "years",
    YEAR: "an",
    MONTHS: "months",
    MONTH: "months",
    FAMILY: "Family",
    INVALID_SSIN: "N° of national register invalid",
    MISSING_SSIN: "N° of national register missing",
    SUBMITTED_PENDING_CONTACTS: "{{count}} contact(s) edition saved(s).",
    DISCARDED_PENDING_CONTACTS:
      "{{count}} contact(s) edition abandoned(s) (edit window expired).",
  },
  "patient-layout-buttons": {
    INSURABILITY: "Insurability",
    NO_ASSURABILITY: "The patient doesn't have a mutual fund",
    CONSENT: "Consent of the patient",
    THERLINK: "Therapeutic relationship",
    DMG: "DMG",
    "dmg-type": {
      DMG_UNKNOW: "DMG inconnu",
      NO_DMG: "There is no DMG",
      DMG_TO_MYSELF: "The DMG belongs to me",
      DMG_COLLEAGUE: "The DMG belongs to a doctor's office",
      DMG_OUTSIDE: "The DMG belongs to a doctor outside",
    },
    SUMEHR: "Sumehr",
    HUB: "Hub",
    ATTESTATION: "Attestation",
    MEDICAL_NOTE: "Medical Note",
    ADMINISTRATIVE_NOTE: "Note administrative",
    INVOICING: "Billing",
    BEOOGO: "Beoogo",
    COVID_VACCINATION: "Covid vaccination status",
    SUBSCRIPTION: "Subscription management",
  },
  "patient-layout-tabs": {
    JOURNAL: "Journal",
    SYNTHESIS: "Synthesis",
    FILE: "Sheet",
    SYNTHESE: "Hubs",
    FINANCE: "Finance",
  },
  "patient-layout-labresult": {
    NOTE: "Note",
    SAVE: "Save",
    NOTE_UPDATED_SUCCESS: "Note update.",
    NOTE_UPDATED_ERROR: "Error during the update of the note.",
    LABEL: "Wording",
    DATE: "Date",
    VALUE: "Value",
    REFERENCE_VALUE: "Reference values",
    REFERENCE_VAL: "Val. reference",
    UNIT: "Unit",
    FILTER: "Filter",
    MORE_THAN_X: "More than {{value}}",
    LESS_THAN_X: "Less than {{value}}",
    DOWNLOAD_BIOLOGY: "Download the biology",
    PRINT_BIOLOGY: "Print biology",
    SEND_BIOLOGY: "Send biology",
    BIOLOGY: "Biology",
    PRINT_TITLE: "Laboratory result of {{hcpName}} of {{date}}",
    ABNORMAL: "Abnormal",
  },
  "labresult-dialog": {
    TITLE: "Results of the analysis : {{analysis}}",
  },
  "labresult-table": {
    NO_SERIES_DATA: "The data do not allow us to display a graph.",
  },
  "telephonic-consultation": {
    DESCRIPTION: "Notices/Miscellaneous",
  },
  "multidisciplinary-consult": {
    DESCRIPTION: "Multidisciplinary discussion",
  },
  "patient-file-content": {
    MEDICAL_NOTE_SAVED: "Medical Note recorded",
    EDIT_SUCCESS: "Patient updated.",
    EDIT_FAILURE: "The update of the patient has failed.",
    CREATE_SUCCESS: "Patient created.",
    CREATE_FAILURE: "The creation of the patient has failed.",
    FILTERS: "Filters",
    CANCEL_FILTER: "To cancel the filter",
  },
  "patient-consent": {
    EXPIRED_EID_CARD: "Expired EID card",
    ERROR_CHECK:
      "An error occurred during the consultation of consent: {{error}}",
    ERROR_SUBMIT: "An error occurred while submitting the consent: {{error}}",
    INCOMPLETE:
      "It would seem that a type of consent is missing. Both are required for the proper functioning of Medispring. Please re-create the consents.",
    NOTHING_TO_REVOKE:
      "The patient has no consent to revoke, or no consent has been found.",
    ERROR_REVOKE: "An error occurred while submitting the consent: {{error}}",
    MISSING_SSIN: "The patient does not'SSIN.",
    CONSENT_TITLE: "Consent of the patient",
    EID_CARD_NUMBER: "Number of identity card (eID)",
    ISI_CARD_NUMBER: "Card number ISI+",
    REVOKE_CONSENT: "Revoke consent",
    REGISTER_CONSENT: "Save the consent",
    REGISTER_NATIONAL_OK: "National consent successfully registered",
    REGISTER_HUB_OK: "Consent hub successfully registered",
    REGISTER_HUB_FAILED: "Failed hub consent",
    REVOKED_OK: "Consent revoked successfully",
    INFO_TITLE: "Please give your patient the following information : ",
    CREATE_FORM_HEADER:
      "Complete the following fields to create the consents of the patient :",
    INFO1:
      "I ask freely I register for the health system to enable health professionals involved in the care I provided, to exchange health data that concern me.",
    INFO2:
      "This registration signifies consent to the exchange of data at national level in the framework of the continuity of care.",
    REFUSE: "Log patient refusal",
    CLOSE: "Close",
    EDIT: "Edit consent",
    CONSENT_REFUSED: "Patient refused to give his consent",
    CONSENT_REFUSE_SAVED: "Consent refuse saved",
    CONSENT_REFUSE_UNSAVED: "Consent refuse canceled",
    CONSENT_REFUSE_ERROR: "Update of consent failed",
    REASON_FOR_MANUAL_INPUT: "Choose a reason for manual entry",
    BAD_SSIN: "Invalid national number",
    NOT_CONNECTED_TO_EHEALTH: "You are not connected to the eHealth network",
    ERRORS: {
      "MH2.INPUT.2": "The request is invalid",
      "MH2.INPUT.19": "Patient identification is invalid",
      "MH2.ACCESS.8": "Consent already exists",
      "IDS2.INPUT.53": "Invalid format",
      "IDS2.INPUT.70": "Unknown format",
      "IDS2.INPUT.80": "No results for this number",
      "CO.UPDATE.01": "The consent of a deceased person cannot be changed",
      "CO.INPUT.30": "The support card number of the patient SSIN is mandatory",
    },
  },
  "patient-therlink": {
    ERROR_CHECK:
      "An error occurred during the consultation of the link therapeutic: {{error}}",
    ERROR_SUBMIT:
      "An error occurred while submitting the therapeutic relationship: {{error}}",
    INCOMPLETE:
      "It would seem that a type of therapeutic relationship is missing. Both are required for the proper functioning of Medispring. Please re-create the therapeutic relationship.",
    NOTHING_TO_REVOKE:
      "The patient has no therapeutic relationship to revoke or none of the therapeutic relationship has been found.",
    ERROR_REVOKE:
      "An error occurred while submitting the therapeutic relationship : {{error}}",
    MISSING_SSIN: "The patient does not'SSIN.",
    THERLINK_TITLE: "Therapeutic relationship",
    EID_CARD_NUMBER: "Number of identity card (eID)",
    ISI_CARD_NUMBER: "Card number ISI+",
    ALL_TYPES: "National + Hub",
    REVOKE: "Revoke",
    NAT_ONLY: "Only on the national network",
    LINK_TO_CREATE: "Link(s) to create",
    REVOKE_THERLINK: "Revoke the therapeutic relationship",
    REGISTER_THERLINK: "Save the therapeutic relationship",
    REGISTER_OK: "Therapeutic relationship successfully saved",
    REVOKED_OK: "Therapeutic relationship revoked successfully",
    NAME: "Name",
    NIHII: "INAMI",
    DATE_START: "Start Date",
    DATE_END: "End Date",
    STATUS: "Status",
    TYPE: "Type",
    LEVEL: "Level",
    NATIONAL: "National",
    HUB: "Hub",
    ERROR_SUBMIT_NATIONAL:
      "An error occurred while submitting the therapeutic relationship national",
    ERROR_SUBMIT_HUB:
      "An error occurred while submitting the therapeutic relationship of the hub",
  },
  "health-element-form": {
    HEALTH_ELEMENT: "Element of care",
    NEW_HEALTH_ELEMENT: "New element of care",
    EDIT_HEALTH_ELEMENT: "Change the element of care",
    SEARCH_IN: "Search",
    DESCRIPTION: "Description",
    NATURE: "Nature",
    STATUS: "Status",
    CHARACTERIZATION: "Characterized as",
    CERTAINTY: "Certainty",
    SEVERITY: "Severity",
    TEMPORALITY: "Temporality",
    LATERALITY: "Laterality",
    CONFIDENTIALITY: "Confidentiality",
    NOTE: "Note",
    PLANS_OF_ACTION: "Action Plan (Approach)",
    OPENING_DATE: "Start Date",
    CLOSING_DATE: "End Date",
    EXTRA_OPTIONS: "More choices",
    HISTORY: "History",
    HISTORY_COLUMN: {
      DESCRIPTION: "Description",
      NATURE: "Nature",
      STATUS: "Status",
      MODIFIED_DATE: "Modification",
      RESPONSIBLE: "Responsible",
      AUTHOR: "Author",
    },
    ERROR: {
      CODE_FETCH_FAILURE: "Code fetch failure",
      MISSING_DESCRIPTION: "Missing Description.",
      INACTIVE_MISSING_CLOSING_DATE: "The end date must be present",
      FUTURE_DATE: "The date cannot be in the future.",
      REQUIRED_START_DATE: "A start date is mandatory.",
      UNORDERED_DATES: "The start date is after the end date.",
      FAMILY_RISK_AND_ACTIVE: "A family history can not be active.",
    },
    WARNING: {
      REQUIRED_FIELD: "Mandatory field",
    },
    ADD: "Add",
    CANCEL: "Cancel",
    MODIFY: "Edit",
    SAVED_THE: "Saved the",
    BY: "by",
  },
  "code-service": {
    FETCH_FAILURE: "The search code has failed for {{code}}.",
  },
  "code-search": {
    FIND_HEALTH_ELEMENT: "Find an element of care",
  },
  "insurability-search": {
    FIND_INSURABILITY: "Find an insurance",
  },
  "patient-synthesis-controls": {
    GENERAL: "General Synthesis",
  },
  "patient-journal-controls": {
    UPDATED_HEALTH_ELEMENTS: "{{count}} items of care automatically updated.",
    EXPAND: "Unfold the elements of care",
    COLLAPSE: "Fold the elements of care",
    CATEGORY: {
      PROBLEM: "Health problems",
      ARCHIVE: "Archives",
      INACTIVE: "History",
      ALLERGY: "Allergies",
      INTOLERANCE: "Intolerances",
      FAMILY_RISK: "Family history",
      SOCIAL_RISK: "Risk factors social",
      MEDICAL_RISK: "Risk factors medical",
      INACTIVE_SURGICAL: "Past surgical history",
      INACTIVE_MEDICAL: "Medical history",
      RISK: "Risk factors",
      CURRENT_TREATMENTS: "Current treatments",
      DIAGNOSIS: "Diagnostics non pertinents",
      PREVENTION: "Prevention",
      VACCINE: "Vaccines",
    },
    NO_HEALTH_ELEMENT: "NO {{descr}}",
    EDIT: "Edit",
    EDIT_OR_CARE_PATH: "Edit/Path-of-care",
    ARCHIVE: "Archiver",
    DEACTIVATE: "History",
    ACTIVATE: "Enable",
    REMOVE: "Delete",
    ADD: "Add",
    TO_PLAN: "To plan",
    FILTER: "Filter",
    SHOW_RLM: "View the module RLM",
    CANCEL_FILTER: "To cancel the filter",
    NO_ACTION: "No action available",
    PRESET: {
      ALCOHOL: {
        MENU_ITEM: "Alcohol",
        DESCRIPTION: "Alcohol",
      },
      TOBACCO: {
        MENU_ITEM: "Tabac",
        DESCRIPTION: "Smoking",
      },
      DIABETES2: {
        MENU_ITEM: "Journey of care : type 2 Diabetes",
        DESCRIPTION: "Type 2 diabetes",
      },
      PREDIABETES: {
        MENU_ITEM: "Journey of care : Pre-diabetes",
        DESCRIPTION: "Pre-diabetes",
      },
      RENAL_FAILURE: {
        MENU_ITEM: "Route of treatment : renal Failure",
        DESCRIPTION: "Renal failure",
      },
    },
    CREATE_DIAGNOSIS: {
      PROBLEM: "Create a medical problem",
      MEDICAL_ANTECEDENT: "Create a medical history",
      SURGICAL_ANTECEDENT: "Create a history of surgical",
    },
    PREVENTION: {
      CERVIX_UTERIC_CANCER: "Screening for cervical cancer of the uterus",
      COLORECTAL_CANCER: "Detection of colon cancer",
      BREAST_CANCER: "Breast cancer screening",
      PROSTATE_CANCER: "Screening for prostate cancer",
      OSTEOPOROSIS: "Screening for osteoporosis",
    },
    CREATE_SUCCESS: '"{{descr}}" created.',
    CREATE_ELEMENT_SUCCESS: 'Element of care "{{descr}}" created.',
    CREATE_ELEMENT_FAILURE: "The creation of the element of care has failed.",
    EDIT_SUCCESS: '"{{descr}}" modified.',
    ARCHIVE_SUCCESS: '"{{descr}}" checked.',
    DEACTIVATE_SUCCESS: '"{{descr}}" marked as antecedent.',
    ACTIVATE_SUCCESS: '"{{descr}}" marked as active.',
    REMOVE_SUCCESS: '"{{descr}}" deleted.',
    REMOVE_FAILURE: 'The removal of "{{descr}}" has failed.',
    CANNOT_REMOVE_ATTACHED:
      '"{{descr}}" cannot be deleted: it is attached to a contact.',
    HEALTH_ELEMENT: "Element of care",
    NEW_HEALTH_ELEMENT: "Add an element of care",
    CLASSIFICATION: "Classification",
    ATTACHED_N: "Element of care attached to {{n}} sub-contacts.",
    FAILED_TO_ATTACH_N:
      "The association of the element of care has failed for {{n}} sub-contacts.",
  },
  "health-element-tree-service": {
    PREVENTION_CODE_NOT_FOUND: "Codification of prevention not found.",
    AUTO_CREATED_PREVENTIONS: "Preventions are created automatically.",
    NOT_INDICATED_PREVENTIONS: '"{{prevention}}" was set as "not indicated"',
  },
  "patient-journal-content": {
    TYPE_FILTER_PREFIX: "Filter by",
    SERVICE_GROUP_TYPES: {
      PLAN_TEXT: "Plan text",
      PLAN_TREATMENT: "Prescription",
      PLAN_VACCINE: "Vaccine",
      PLAN_INCAPACITY: "ITT",
      PLAN_DOCUMENT: "Documents",
      PLAN_INCAPACITY_MEDICAL: "ITT for doctor council",
      PLAN_INCAPACITY_MEDEX: "ITT generic",
      PLAN_PHYSIOTHERAPY: "Physiotherapy application",
      PLAN_MEDICAL_CARE: "Request infi",
      PLAN_RADIOGRAPHY: "Request RX",
      PLAN_PROCEDURE: "Procedure",
      S_PAIN_SCALE: "Scale of subjective pain",
      O_TEXT: "Objective text",
      O_PAIN_SCALE: "Scale of Objective Pain",
      O_KATZ_SCALE: "Katz Scale",
      O_BIOMETRY: "Biometrics",
    },
    EXPORT_CSV: "Export csv",
    EXPORT_ERROR: "Error during export",
    CONTACTS: "Contacts",
    REFRESH: "Refresh",
    FILTERS: "Filters",
    FILTER: {
      CONTACT: "contacts",
      "PHONE-CONTACT": "notices/miscellaneous",
      LAB: "laboratories",
      REPORT: "reports",
      SPECIALITY: "Filter by specialty",
      DATE: "Filter by date",
      "MULTI-DISCIPLINARY": "contacts multi-discipl.",
      CONSULTATION_DATE: "Filter on a period of care",
    },
    SERVICES: "Services",
    CREATED_AFTER: "Created After",
    CREATED_BEFORE: "Created Before",
    CREATED_BY: "Created By",
    AUTHOR: "Author",
    TEXT: "Description",
    SORT: "Sort",
    CANCEL: "Cancel",
    OK: "OK",
    EXPORT: "Export",
    ALL: "Total",
    SEARCH_CONTACTS: "Search Contacts",
    ANTICHRONOLOGICAL: "Don't sort by date",
    CHRONOLOGICAL: "Sort by date",
    TASKS: "View tasks list",
    TASKS_LATE: "You have tasks exceeded",
    SUMMARY: "Summary",
    SEARCH: "Search",
    CLOSE: "Close",
    CONTACT: "Consultation",
    CONTACT_COUNT: "Contact Count",
    NEW_CONTACT: "New consultation",
    CONTACT_TYPE_OPTIONS: "New contact with another type",
    CURRENT_CONTACT: "Consultation of the day",
    NO_CONTACT: "There is not contact display.",
    EDITING_CONTACT: "Contact being edited",
    REPORT: "Report",
    NEW_REPORT: "New report",
    error: {
      MOTIVATION_AND_ASSESSMENT_ARE_MANDATORY:
        "The pattern and the diagnosis are mandatory to close a consultation.",
      REPORT_MOTIVATION_AND_ASSESSMENT_ARE_MANDATORY:
        "The pattern and the diagnosis are mandatory to generate a report of guard.",
      COULD_NOT_CREATE_CONTACT: "The creation of the contact has failed.",
      COULD_NOT_ATTACH_HEALTH_ELEMENT:
        "The element of care selected could not be attached to the new contact.",
    },
  },
  "patient-dmg": {
    DMG_TITLE: "DMG",
    INSCR: "Sign up for the DMG.",
    INSCR_OK: "You are enrolled in the DMG.",
    DMG_VALIDATE_OK: "Request envoyeé",
    INFO1:
      "The request for a new DMG must be made within 7 days of the associated code.",
    LAST_CONSULTATION_DATE: "Date of last consultation",
    VALIDATE: "Validate",
    DATE: "Date",
    NOMENCLATURE: "Nomenclature",
    STATE_INSCR: "Status of your registration at DMG",
    MISSING_SSIN: "The patient does not'SSIN",
    NO_INSURABILITY: "The patient has no data of insurability",
    NO_MUTUALITY_CODE: "The patient has no code of mutual",
    NO_TITULAIRE_ID: "The patient has no owner ID",
    ACTUAL_STATE: "Current state of the DMG of the patient",
    RESPONSIBLE: "Responsible for : ",
    FROM: "The",
    TO: "the",
    NOTIFY: "Ask the DMG",
    ERR_NOTIFY: "The DMG could not be asked.",
    CONSULT: "Consult",
    REJECT: "Request rejected",
    ACCEPT: "Request accepted",
    FROM_ACC: "from the",
    PAYMENT_OK: "Global medical file already paid for this year",
    PAYMENT_NOK: "The global medical file has not yet been paid for this year",
    YES: "Yes",
    NO: "Non",
    ASSISTANT_ALONE:
      "A wizard can not notify a DMG without a master internship valid",
    ASSITANT_WITHOUT_MASTER_HCP:
      "In order to notify a DMG as a wizard, you must first define your master internship in your msds.",
    DOCTOR_NOT_REGISTERED:
      "In order to notify a DMG, you must first be in order of registration. Please check your entries from your screen eDmg.",
    REFERRAL_HISTORY: "History",
  },
  "patient-hub": {
    UNKNOWN_ALLERGY: "Unknown allergy",
    CDHCPARTY: {
      deptanatomopathology: "anatomopathologie",
      deptanesthesiology: "anesthesiology",
      deptbacteriology: "bacteriology",
      deptcardiology: "cardiology",
      deptdermatology: "dermatology",
      deptdietetics: "dietary",
      deptemergency: "services d'urgence",
      deptgastroenterology: "gastroenterology",
      deptgeneralpractice: "general medicine",
      deptgenetics: "genetic",
      deptgeriatry: "geriatrics",
      deptgynecology: "gynecology",
      depthematology: "hematology",
      deptintensivecare: "intensive care",
      deptkinesitherapy: "physiotherapy",
      deptlaboratory: "Laboratory",
      deptmedicine: "internal medicine",
      deptmolecularbiology: "molecular biology",
      deptneonatalogy: "neonatology",
      deptnephrology: "nephrology",
      deptneurology: "neurologie",
      deptnte: "oto-rhino-laryngologie",
      deptnuclear: "nuclear medicine",
      deptoncology: "oncology",
      deptophtalmology: "ophthalmology",
      deptpediatry: "pediatrics",
      deptpharmacy: "Pharmacy",
      deptphysiotherapy: "physiotherapy",
      deptpneumology: "pneumologie",
      deptpsychiatry: "psychiatry",
      deptradiology: "radiology",
      deptradiotherapy: "radiation therapy",
      "deptrhumatology (deprecated)": "rheumatology",
      deptstomatology: "stomatologie",
      deptsurgery: "surgery",
      depttoxicology: "toxicologie",
      depturology: "urologie",
      orghospital: "hospital",
      orginsurance: "assurance",
      orglaboratory: "independent laboratory",
      orgpublichealth: "public health service",
      persbiologist: "medical laboratory technician",
      persdentist: "dentist",
      persnurse: "nurse",
      persparamedical: "paramedical staff",
      perspharmacist: "pharmacist",
      persphysician: "doctor",
      perssocialworker: "social worker",
      perstechnician: "Technician medical imaging",
      persadministrative: "personnel admistratif",
      persmidwife: "midwife",
      application: "software application",
      deptorthopedy: "orthopedics",
      orgpractice: "cabinet",
      orgpharmacy: "Independent pharmacy",
      deptalgology: "algologie",
      deptcardiacsurgery: "cardiac surgery",
      depthandsurgery: "surgery of the hand",
      deptmaxillofacialsurgery: "chirurgie maxillofaciale",
      deptpediatricsurgery: "pediatric surgery",
      deptplasticandreparatorysurgery: "cosmetic and restorative surgery",
      deptthoracicsurgery: "thoracic surgery",
      deptvascularsurgery: "vascular surgery",
      deptvisceraldigestiveabdominalsurgery:
        "abdominal surgery, digestive and visceral",
      deptdentistry: "dentistry",
      deptdiabetology: "diabetology",
      deptendocrinology: "endocrinology",
      deptoccupationaltherapy: "occupational therapy",
      deptmajorburns: "burn",
      deptinfectiousdisease: "infectious disease",
      deptspeechtherapy: "therapy",
      deptsportsmedecine: "medicine sports",
      deptphysicalmedecine: "physical medicine",
      depttropicalmedecine: "tropical medicine",
      deptneurosurgery: "neurologie",
      deptnutritiondietetics: "dietary nutritional",
      deptobstetrics: "occupational therapy",
      deptchildandadolescentpsychiatry: "psychiatry child and adolescent",
      deptpodiatry: "podiatrie",
      deptpsychology: "psychology",
      deptrevalidation: "rehabilitation",
      deptsenology: "senology",
      deptsocialservice: "service social",
      deptpediatricintensivecare: "pediatric intensive care",
      deptpalliativecare: "palliative care",
      deptrheumatology: "rheumatology",
      persambulancefirstaid: "ambulance premier secours",
      persaudician: "audicien",
      persaudiologist: "audiologue",
      perscaregiver: "caregiver informal",
      persdietician: "dietician",
      perseducator: "educator",
      perslogopedist: "therapist",
      persoccupationaltherapist: "occupational therapist",
      "persorthopedist (deprecated)": "orthopedic",
      persorthoptist: "orthoptiste",
      persoptician: "optician",
      perspharmacyassistant: "pharmacy assistant",
      persphysiotherapist: "physiotherapist",
      perspodologist: "podologue",
      perspracticalnurse: "health care aide",
      perspsychologist: "psychologist",
      orgprimaryhealthcarecenter: "Medical House",
      guardpost: "guard post",
      groupofphysicianssameaddress: "grouping of doctors same address",
      groupofphysiciansdifferentaddress:
        "Grouping of doctors different address",
      groupofnurses: "Consolidation of nursing",
      certificateholder: "Holder of the certificate",
      perstrussmaker: "Bandagiste",
      patient: "Patient",
      persorthotist: "Orthotist/Prosthetist",
      perspatientdriver: "Transporter patient (non-urgent)",
      orgprevention: "Service of prevention",
      perspharmacistclinicalbiologist: "pharmacist-biologist, clinician",
      hub: "hub",
      deptnursing: "nursing",
      persoraldentalhygienist: "Hygienist oral hygiene dental",
      persclinicalpsychologist: "Clinical psychologist",
      persclinicalorthopedagogist: "Resource teacher clinician",
      unknown: "unknown",
    },
    ACCESS: {
      yes: "yes",
      no: "Releasable by the treating physician",
      by_author: "Releasable by the author",
      unknown: "Unknown",
      never: "Ever",
    },
    CDPATIENTWILL: {
      ntbr: "Do not resuscitate",
      bloodtransfusionrefusal: "Refusal of transfusion",
      intubationrefusal: "Refusal of intubation",
      euthanasiarequest: "Request for euthanasia",
      vaccinationrefusal: "Refusal of vaccination",
      organdonationconsent: "Organ donation consent",
      datareuseforclinicalresearchconsent:
        "Reuse of data for clinical research",
      datareuseforclinicaltrialsconsent: "Reuse of data for clinical trials",
      clinicaltrialparticipationconsent:
        "Consent for participation in clinical trials",
      omissionofmedicaldata:
        "Data have been omitted at the request of the patient",
    },
    "CD-PATIENTWILL-HOS": {
      undefined: "?",
      hos0: "No limitation on hospitalisation",
      hos1: "No hospitalisation",
      hos2: "Hospitalisation only in specific situations",
    },
    "CD-PATIENTWILL-RES": {
      undefined: "?",
      dnr0: "No limit on treatment",
      dnr1: "Do not attempt resuscitation",
      dnr2: "Do not attempt resuscitation and do not extend treatment",
      dnr3: "Do not attempt resuscitation and phase out treatment",
    },
    STATE: {
      title: "State",
      LOADING: "Loading",
      COMPLETED: "OK",
      ERROR:
        "An error has occurred. Please try again later or contact support.",
    },
    STATUS: {
      ABORTED: "Aborted",
      ACTIVE: "Active",
      ADDED: "Added",
      ADMINISTRATED: "Administrated",
      CANCELLED: "Cancelled",
      COMPLETED: "Completed",
      CORRECTED: "Corrected",
      DELIVERED: "Delivered",
      SUBSTITUTED: "Substituted",
      INACTIVE: "Inactive",
      PLANNED: "Planned",
      PRESCRIBED: "Prescribed",
      REPORTED: "Reported",
      REFUSED: "Refused",
      SWITCHED: "Switched",
      SUSPENDED: "Suspended",
      STOPPED: "Stopped",
      EXCLUDED: "Excluded",
      NOTPRESENT: "Not Present",
      ORDERED: "Ordered",
      SCHEDULED: "Scheduled",
      TODO: "To Do",
      POSTPONED: "Postponed",
      PENDING: "Pending",
      EXPECTED: "Expected",
      OBTAINED: "Obtained",
      PROPOSED: "Proposed",
      RETRACTED: "Retracted",
    },
    HUB_TITLE: "Hub",
    LOADING: "Checking data, please wait",
    SELECT_A_HUB: "Select a hub to query",
    DATE_FILTER: "Filter by date",
    DATE_FILTER_START: "The",
    DATE_FILTER_END: "the",
    DOCUMENTS: {
      TAB_TITLE: "Documents",
      CONTENT_NAME: "Content",
      AUTHOR: "Author",
      NAME: "Name",
      VALUE: "Value",
      ATTACHMENTS: "Attachments",
      FILE: "File",
      OPEN_FILE: "Open the file",
      IMPORT_PATIENT_FOLDER: "Import in the Log",
      NO_DATA: "No document available for this patient",
    },
    TABS: {
      PREVIEW: "General overview",
      ALLERGIES: "Allergies",
      ADR: "Intolerances",
      RISKS: "Risk factors",
      DIAGNOSIS: "Diagnostics",
      MEDICATIONS: "Medications",
      VACCINES: "Vaccines",
      CONTACTHCPARTY: "Caregivers",
      CONTACTPERSON: "Contacts",
      TREATMENTS: "Treatments",
    },
    SUMEHR: {
      TAB_TITLE: "Sumehrs",
      SUMHER_TITLE: "Sumehrs",
      TITLE: "List of Sumehrs",
      REVOKE: "Revoke sumehr",
    },
    DOCUMENT: {
      TYPE: "Type",
      SERVICE: "Service",
      HOSPITAL: "Hospital",
      DATE: "Date",
      HCP_LASTNAME: "Name of the doctor",
      HCP_FIRSTNAME: "First name of the doctor",
      CONTENT: "Textual content",
      LINKS: "Links",
      LINK: "Link",
      FILES: "Files",
    },
    TRANSACTIONS: {
      DATE: "Date",
      AUTHOR: "Author",
      PROFILE: "Profile",
      SERVICE: "Service",
      SITE: "Site",
      ACCESSIBILITY: "Accessible patient",
      ACCESS_DATE: "Date patient access",
      IMPORT_TRANSACTION: "Import of the transaction : Action required",
      CATEGORIES: "Categories",
      VERSION: "Version",
      VALIDITY: "Validity",
    },
    MEDICATION_SCHEMA: {
      TAB_TITLE: "Scheme of medications",
      SEND_MED_SCHEME: "Send scheme",
      LOADING: "Loading medication schema",
      SYNCHRONIZE: "Synchronization of the schema, please wait",
    },
    VACCINATION: {
      VACCINATION: "Vaccination",
      encounterlocation: "Country where vaccine was administered : ",
    },
    PATIENT_NOTES: {
      TAB_TITLE: "Notes patient",
      SEND_NOTES: "Send note",
    },
    HCP_NOTES: {
      TAB_TITLE: "Journal",
    },
    VACCINATIONS: {
      TAB_TITLE: "Vaccinations",
    },
    LAB_RESULTS: {
      TAB_TITLE: "Laboratory",
    },
    SPECIALITIES: {
      TAB_TITLE: "Others",
    },
    WHATSAPPVIEW: {
      TABLE: "List",
      JOURNAL: "Journal",
    },
    PATIENT: {
      title: "Patient",
      ssin: "SSIN",
      lastName: "Name",
      firstName: "First name",
      sex: "Sex",
      birthDate: "Date of birth",
      wills: "Wills",
      hos: "Hospitalisation",
      res: "Resuscitation",
    },
    GMD_ADMINISTRATION: {
      title: "Administrateur DMG",
      nihii: "INAMI",
      lastName: "Name",
      firstName: "First name",
      date: "Date",
    },
    AUTHOR: {
      title: "Author",
      nihii: "INAMI",
      profile: "Profile",
      lastName: "Name",
      firstName: "First name",
      address: "Address",
      phone: "Phone",
      mobile: "GSM",
      UNKNOWN: "Unknown",
    },
    PERSON: {
      birthdate: "Birthdate",
      ssin: "SSIN",
      sex: "Sex",
      relationship: "Relation",
    },
    WRITER: {
      title: "Editor",
    },
    ERRORS: {
      CANNOT_DECODE_MEDICATION:
        "The patterns of medication are not yet visible within Medispring. We invite you to make on the application your hub.",
      NO_SSIN:
        "The patient does not have the number of a national registry that recorded",
      NO_EHEALTH: "You are not connected to the network, eHealth",
      NO_CONSENT:
        "The patient has not given consent for the sharing of its data",
      NO_THERLINK:
        "You do not have a therapeutic relationship with the patient",
      SERVER_ERROR: "Unable to retrieve the requested resource",
      NO_WORK_ZIP_CODE:
        "Please fill the address of work (Place of work) in your profile.",
      DISABLED: "eHealth hub is disabled for the current connection",
    },
    IMPORT: {
      SUCCESS: "Document imported successfully",
      CANCELED: "Import the transaction cancelled",
      LAST_NAME: "Name",
      FIRST_NAME: "First name",
      INAMI: "RIZIV number",
      NATIONAL: "National number",
      NEW_HCP_LABEL: "Select an author in your list of stakeholders",
      NEW_HCP: "Create a new player",
    },
    DIALOG: {
      AUTHOR_IN_TRANSACTION:
        "Information found on the author in the transaction :",
      REASONS: {
        no_author:
          "The author of the transaction is not found, please specify it.",
        no_match:
          "The originator of the transaction could not be found in Medispring. Select or create a provider.",
        multiple_match:
          "Several service providers have been found for this transaction. Please select a",
      },
    },
    VALIDATE: "Validate",
    CANCEL: "Cancel",
    DESCRIPTION: "Description",
    START: "Beginning",
    END: "Fin",
    STATUS_TITLE: "Status",
    CERTAINTY: "Certainty",
    COMMENT: "Comment",
    POSOLOGY: "Dosage",
    CNKATCCODE: "Code Cnk/Atc",
    TEMPORALITY: "Temporality",
    DATE: "Date",
    INDICATIONS: "Indications",
    SEND_SUMEHR: {
      NEW_SUMEHR: "New Sumehr",
      INFO_1:
        "The information has been automatically completed on the basis of the patient's record.",
      INFO_2: 'Click on "Send" or edit the contents manually.',
      DATE: "Date : ",
      NO_ELEMENT: "No item in the folder",
      ERROR_GET_DATA: "Impossible to get the item in the folder",
      ERROR_SAVE: "The status change could not be saved",
      SEND_SUMEHR: "Send a Sumehr",
      ENTER_PASSWORD:
        "Enter the password to generate and send the Sumehr. This password must be entered by the help desk.",
      BACK: "Return",
      SEND: "Send",
      SEND_OK: "Sumehr sent",
      SEND_ERROR: "Error when sending the Sumehr",
      GENERATION_ERROR: "Error during the generation of the Sumehr",
      INVALID_PASSWORD: "Invalid password",
      MEDICATION: "Treatments",
      WILL: "Wills",
      DOWNLOAD_XML: "Download the XML",
      CONFIDENTIAL: "(Confidential)",
    },
    SEND_DIARY_NOTE: {
      NEW_DIARY_NOTE: "New note",
      DATE: "Date: ",
      BACK: "Return",
      SEND: "Send",
      CHOOSE_DIARY: "Choose a category : ",
      SEND_OK: "Journal note sent",
      SEND_ERROR: "Error sending the log",
      antibiotherapy: "Antibiotic",
      diabetes: "Diabetes",
      medication: "Medication",
      movement: "Movement",
      nutrition: "Nutrition",
      oncology: "Oncology",
      renalinsufficiency: "Renal failure",
      woundcare: "Wound care",
    },
  },
  "sumehr-import": {
    IMPORT_IN_PATIENT_FILE: "Import into the patient's file",
    IMPORT_SUMEHR: "Import Sumehr data",
    SELECT_ALL: "Select all",
    DESELECT_ALL: "Deselect all",
    CANCEL: "Cancel",
    IMPORT: "Import",
    MEDISPRING: "Medispring",
    SUMEHR: "Sumehr",
    STATUS: "Status",
    WILLS: "Wills",
    HEALTH_PROBLEMS: "Health problems",
    CURRENT_TREATMENTS: "Treatments in progress",
    ANTECEDENTS: "Antecedents",
    ALLERGIES: "Allergies",
    INTOLERANCE: "Intolerances",
    RISK_FACTORS: "Risk factors",
    VACCINES: "Vaccines",
    CONTACT_HC_PARTIES: "Caregivers",
    ANTECEDENT_TYPES: {
      MEDICAL: "Medical",
      SURGICAL: "Surgical",
      FAMILY_RISK: "Family",
    },
    RISK_FACTOR_TYPES: {
      MEDICAL: "Medical",
      SOCIAL: "Social",
    },
    VACCINE_TYPES: {
      ADULT: "Adult",
      CHILD: "Infant",
    },
    VACCINE_TIMELINE: {
      DURING_CHILDHOOD: "Childhood",
      DURING_ADULTHOOD: "Adulthood",
    },
    NO_DATA_AVAILABLE: "No data available",
    TREATMENT_TYPES: {
      CHRONIC_TREATMENTS: "Chronic treatments",
      ACUTE_TREATMENTS: "Acute treatments",
    },
    WILLS_STATUSES: {
      authorize: "Accept",
      refuse: "Refuse",
      undefined: "Unspecified",
    },
    SUCCESS: "Sumehr data successfully imported",
    FAILURE: "Failed to import Sumehr data",
  },
  "patient-invoicing": {
    TITLE: "Attestation",
    LOADING_DATA: "Crunching your latest data",
    PATIENT_NO_INSURABILITY:
      "No data evidence of insurability found for the patient.",
    PATIENT_NO_VALID_DATA:
      "The code OA of the patient is empty or invalid, the options eFact and eAttest are disabled.",
    paper: "Paper",
    eattest: "eAttest",
    efact: "eFact",
    payingagency: "Paying agency",
    patient: "Patient",
    mutualfund: "Mutual",
    undefined: "Unknown",
    COBRHA_ERROR: "You cannot perform a search Cobrha for the time being.",
    PATIENT_NO_SSIN:
      "The patient does not SSIN registered : electronic billing is unavailable.",
    UNKNOW_MUT: "Mutual unknown",
    UNKNOW_HCP: "Practitioner unknown",
    PATIENT: "Patient",
    EHEALTH_NOT_CONNECTED: "You are not connected to services ehealth.",
    JUSTIFICATIONS: {
      NONE: "No",
      OTHER: "Other",
      DEATH: "Death, coma",
      FINANCIAL_DISTRESS: "Financial distress",
      BIM: "BIM",
      FORFAIT:
        "Service provided in the context of an agreement providing for the lump-sum payment of certain benefits",
      MENTAL_CENTER:
        "Mental health Centre, family planning, centre for addiction",
      CHILD_OLD_HANDICAP_CARE_CENTER:
        "Establishment of care for children, the elderly or disabled",
      CARE_SERVICE: "Child care in general medicine",
      HOME_PALIATIVE_SERVICE: "Home-based palliative care",
      FORBIDDEN: "Prohibition of charge in third-party payment via eFact",
      CHRONICAL: "Chronic disease",
    },
    ROUNDING: {
      HALF_EURO: "Round up to the half euro",
      EURO_UP: "Rounded to the higher euro",
    },
    PAYMENT_METHOD: {
      CASH: "Cash",
      BANCOTACT: "Bancontact",
      VIREMENT: "Bank transfer",
    },
    INVOICE_CREATION: {
      ERROR: "Error during sending eAttest",
      SUCCESS: "The certificate has been created successfully",
      FAILURE: "The backup of the invoice has failed",
    },
    PREFERENCE_UPDATE_FAILURE: {
      PATIENT: "Error during the update of the preferences of the patient.",
      HCP: "Error when updating your preferences",
    },
    ETAR: {
      SUCCESS: "Data update eTar",
      EHEALTH_CONNECTION_ISSUE: "Service eHealth non connecté",
      MISSING_DATA: "Data eTarification not retrieved",
      ERROR: "Error eTar: {{ error }}",
      JUSTIFICATION: "Justification: {{justification}}",
      ACCEPTED: "Engagement of payment accepted",
      FINANCIAL_CONTRACT: "Contract number: {{object}}",
      NO_FINANCIAL_CONTRACT: "No commitment of payment",
    },
  },
  "patient-invoicing-details": {
    MISSING_EHEALTH_DATA: "Data of insurability eHealth missing.",
    HEALTHCARE_PARTY: "Health care provider",
    SEARCH_CAREGIVER: "Search for a care provider",
    HEALTHCARE_PARTY_ASSISTANT: "Trainee health care provider",
    DONE_UNDER_AUTHORITY:
      "Made by {{ firstName }} {{ lastName}} under the authority of his master internship.",
    SEARCH_FOR_YOUR_MASTER:
      "Look for your supervisor or yourself if you want to charge your name.",
    ASSISTANT_INVOICING:
      "If you do not choose your supervisor, you facturerez in your name.",
    ASSISTANT_INVOICING_HELP:
      "If you cannot find your supervisor, set the in your sheet to the presenter.",
    PRESCRIBER: "Prescriber",
    HISTORY: "History",
    PRESTATION_CODES: "Codes benefits",
    SEARCH_PRESTATION_CODES: "Find the code for consultation",
    NO_HISTORY_YET: "Not yet history",
    PRESTATIONS: "Benefits",
    NO_PRESTATION_CODES_YET: "Still no code in the service.",
    SEARCH_CUSTOM_CODES: "Find the code off INAMI",
    SEARCH_TECHNICAL_CODE: "Look for the code technical act",
    SUPPLEMENTS: "Supplements",
    NO_SUPPLEMENT: "No extra charge",
    EVENING: "Evening",
    WEEKEND: "Weekend",
    NIGHT: "Night",
    GUARD: "Garde (18-21h)",
    CONFIRM: "Validate",
    NEXT: "Following",
    PENDING_NEXT: "To be finalized",
    FAILED_SUPPLEMENT_RETRIEVE:
      "Unable to retrieve supplements. You can't go any further.",
    ONE_CONSULTATION_LIMIT:
      "A single code of consultation can be demonstrated. The previous code is replaced.",
  },
  "patient-invoicing-pricing": {
    PRICE_CHOICE: "Choice of the price",
    HISTORY: "History",
    OFFICIAL_PRICING: "Official price",
    NO_TM_ASKED: "No ticket moderator",
    ROUNDED_PRICE: "Price rounding",
    CUSTOM_PRICE: "Custom price",
    SENDING_METHOD_CHOICE: "Choice of shipping method",
    CODE: "Code",
    ERRORS: "Error(s)",
    DATE: "Date",
    EFACT: "eFact",
    EATTEST: "eAttest",
    ETAR: "will",
    STATUS: "Status",
    PAPER: "Paper",
    HONORAIRE: "Honorary",
    INTERVENTION: "Intervention",
    TICKET_MODERATEUR: "TM",
    ETAR__STATUS: "Status eTar",
    SPECIALIST: "eAttest is not available for the specialists",
    TOTAL: "TOTAL",
    MEDICAL_CARE_CHOICE: "Supported by an organization, third party payer",
    NO_MEDICAL_CARE: "Not supported",
    FULL_MEDICAL_CARE: "All",
    ONLY_TM: "Ticket moderator only",
    ONLY_TM_WARNING: "Copayments only for elements in eFact or eAttest",
    PAYMENT_SUMMARY: "Amount paid by",
    PATIENT_FEE: "The patient",
    MEDICAL_CARE_FEE: "The paying agency",
    EFACT_FEE: "Mutuality - eFact",
    PRINT: "Print the receipt",
    ACCEPTED: "Ok",
    REJECTED: "Pas OK",
    FAILED: "Failed",
    CANCEL: "Cancel",
    BACK: "Return",
    CONFIRM: "Validate",
    NOT_APPLICABLE_WARNING: "Not applicable to any items in eAttest.",
    CHECK_ETAR: "View eTar",
    SELECT_OP: "Please select a paying agency.",
    EFACT_101032_101076_WARNING:
      "A consultation 101032 or 101076 for a patient BIM should be sent in eFact.",
    SELECT_IO_PAYMENT_JUSTIFICATION:
      "Choose a justification of third-party payment",
    SELECT_GMD_MANAGER_INAMI: "Manager of the DMG",
    SEND_DMG_INFOS: "Apply the tariff with DMG",
    SELECT_GMD_MANAGER_INAMI_PLACEHOLDER: "RIZIV number",
    EID_NUMBER: "Identity card number",
    MORE_INFO: "Plus d'infos",
    EID_INFOS: "Data of identity card",
    CARD_NUMBER: "Card number",
    EID_HOUR: "Hour reading of the card",
    DETAILS: "Details",
    RELATED_CODE: "Prestation relative",
    UNITS: "Unit",
    LOCATION: "Place of delivery",
    PRESCRIBER: "Prescriber",
    PRESCRIPTION_DATE: "Date de prescription",
    OVERRIDE_REIMBURSEMENT: "Intervention",
    OVERRIDE_PATIENT_INTERVENTION: "TM",
    OVERRIDE_REIMBURSEMENT_AND_PATIENT_INTERVENTION:
      "Intervention and TM (official value)",
    LONG_DELAY_JUSTIFICATION: "Exercise prescription",
    EATTEST_DIFFERENT_THAN_TODAY:
      "You can't send a eAttest containing a benefit that is not today date",
    EATTEST_MISSING_KHMER_OR_XADES:
      "The answer eAttest only continent not to xades or khmerDocument.",
    EATTEST_INCOHERENT_RESPONSE_CODES:
      "The answer eAttest does not contain the same number codes as those sent.",
    EATTEST_CODE_MAPPING_ERROR:
      "Impossible to link codes eAttest premises and those of the response",
    NO_OP_PROVIDED: "The paying agency is not provided or is invalid.",
    SEND_SUPPLEMENT: "Send supplements to the OA",
    DEROGATION_MAX_NUMBER: "Code prestation identique",
    DEROGATION: {
      NO_DEROGATION: "No exceptions",
      OTHER: "Other cases",
      DEROGATION_MAX_NUMBER: "Way of derogation from the maximum number",
      OTHER_PRESCRIPTION: "Other session/prescription",
      SECOND_PRESTATION_DAY: "2nd delivery same day",
      THIRD_AND_NEXT_PRESTATION_DAY: "3rd or next delivery the same day",
    },
    PRESCRIBER_NORM: "Standard prescriber",
    PRESC_NORM: {
      NONE: "Not the prescriber",
      ONE_PRESCRIBER: "The benefit can be attributed to a prescriber",
      SELF_PRESCRIBER: "The benefits are effectueées for his own patients.",
      ADDED_CODE: "These are benefits added",
      MANY_PRESCRIBERS:
        "The benefit is prescribed by different prescribers. The identification number of one of them is recorded in the zone of the prescriber.",
    },
    SIDE_CHOICE: "Laterality",
    SIDE: {
      NONE: "No",
      LEFT: "Left",
      RIGHT: "Right",
    },
    SELECT_THIRD_PARTY_JUSTIFICATION: "Choose a justification for the 1/3 fee",
    Chimiotherapy: "Ambulant Chemotherapy Treatment",
    ProfessionalDisease: "Professional diseases",
    WorkAccident: "Work accidents",
    Accident: "Accidents - common law (third party liability)",
    Other: "Other accidents",
    OTHER: "Other",
    TREATMENT_REASON: "Reason for Treatment",
    MEDICAL_HOUSE_WARNING:
      "Your patient is affiliated to a medical center for the package of",
  },
  "patient-invoicing-summary": {
    PATIENT_PAID: "Amount paid by the patient",
    PATIENT_REMAINING_AMOUNT: "Remaining amount due by the patient",
    PAYMENT_SUMMARY: "Amount due by",
    TOTAL_SUMMARY: "The total of the amounts for",
    PRINT: "Print",
    FINISH: "Close",
    CLOSE: "Close",
    CANCEL_EATTEST: "Annuler eAttest",
    NOT_APPLICABLE: "Non applicable",
    PATIENT_NOT_FOUND: "The patient was not recovered.",
    ERROR_PRINT_DATE_UPDATED:
      "Unable to update the dates of printing of the certificate.",
    DOCUMENT_TITLE: "Justificatif patient",
    INVOICE_NUMBER: "Number of following",
    CARE_PROVIDER: "Supervising physician",
    INTERNSHIP: "Medical intern",
    GMD_MANAGER: "Manager of the DMG",
    PATIENT: "Patient",
    REFUNDABLE_PRESTATIONS: "Benefits reimbursable",
    TYPE: "Type",
    NC: "N.C.",
    STATUS: "Status",
    DATE: "Date",
    CODE: "Code",
    TOTAL_PRICE: "Honorary brought into account",
    REIMBURSEMENT: "Intervention",
    PATIENT_INTERVENTION: "Ticket moderator",
    CONVENTION_AMOUNT: "Honorary convention",
    DIFF_AND_SUPPLEMENTS: "Differences and supplements",
    NOT_DISCLOSED: "Non-release",
    TOTAL: "Total",
    PAYMENT: "Payment",
    PRINTED_FOR_PATIENT: "The proof has been given to the patient",
  },
  "patient-invoicing-information": {
    BIM: "Patient BIM",
    PAYMENT_BY_IO: "Third Party Payer",
    INSURABILITY_DATE: "Date of insurability",
    YES: "Yes",
    NO: "No",
    DMG: "DMG",
    EID_NUMBER: "Identity card number",
    UNKNOWN: "Unknown",
    DMG_DATA: {
      NO_DMG: "There is no DMG.",
      DMG_UNKNOW: "DMG inconnu.",
    },
  },
  "patient-invoicing-edit": {
    CREDIT_NOTE_TITLE: "Editing credit note",
    DIALOG_TITLE: "Edition of invoice",
    INVOICE_EDITION: "Edition of invoice",
    CREDIT_NOTE_EDITION: "Edition of the credit note",
    PAYMENT_INFORMATIONS: "Payment information",
    PAYMENT_MODE: "Mode of payment",
    CANCEL: "Cancel",
    SET_CREDIT_NOTE: "Establish a credit rating",
    ARCHIVE: "Archiver",
    REACTIVATE: "Reactivate",
    APPLY_CORRECTION: "Correct",
    SAVE: "Save",
    ERROR_INVOICE_MODIFY: "Unable to update the invoice.",
  },
  "patient-invoicing-journal": {
    INVOICING: "Attestations",
    REFRESH: "Update",
    DATE: "Date",
    PRINTED: "Delivered to the patient",
    ATTESTATION_NUMBER: "N° d'attestation",
    TOTAL: "Total",
    TOTAL_PATIENT: "Total patient",
    TOTAL_MUTUALITY: "Total mutuelle",
    TOTAL_OA: "Total paying agency",
    EFACT: "eFact",
    EATTEST: "eAttest",
    PAPER: "Paper",
    PAYED: "Paid",
    PREVIEW: "Overview",
    PAID_AMOUNT: "Amount paid",
    PAID_AMOUNT_LIQUID: "Amount paid cash",
    PAID_AMOUNT_BANCONTACT: "Amount paid debitcard",
    PAID_AMOUNT_VIREMENT: "Amount paid wired",
    CANCEL_EATTEST: "Cancel a eAttest",
    CLOSE: "Close",
    PATIENT: "Patient",
    INVOICING_SUMMARY: "Summary of the certificate",
    INVOICE_LOADING_ERROR:
      "An error occurred during the loading of the certificates",
    INVOICE_TREATMENT_ERROR:
      "An error occurred during the processing of the certificates",
    VIEW_INVOICING: "See the certificate",
    MY_EFACTS: "My eFacts",
    SEARCH_EATTEST_BY_INVOICE: "Search for a certificate by invoice",
    SUPERVISOR: "Internship supervisor",
    INTERN: "Assistant",
    DOCTOR_INVOICES: "My invoices",
    ALL_INVOICES: "All invoices",
    OTHER_DOCTOR_INVOICES: "Other doctor's invoices",
    ALL_ASSISTANTS_INVOICES: "All assistants",
    OTHER_ASSISTANTS_INVOICES: "Choose assistant",
  },
  "patient-pending-invoices": {
    INVOICING: "Pending invoces",
    FETCH_ERROR: "Error while loading",
    INVOICE_ID: "ID",
    INVOICE_DATE: "Date",
    INVOICE_AUTHOR: "Author",
    INVOICE_RECIPIENT: "Patient",
    INVOICE_MEDICAL_LOCATION: "Medical entity",
    ALL: "All invoices",
    OTHER: "Invoices of other practicioners",
    MINE: "My invoices",
    ALL_LOCATIONS: "All entities",
  },
  "patient-invoicing-eattest-cancel": {
    INTRO: "You can cancel an invoice eAttest if",
    PATIENT_ERROR: "You're in the wrong patient",
    CONTRACTOR_ERROR: "You're in the wrong provider",
    ELEMENT_ERROR: "You are incorrect in the items of the invoice",
    DOUBLE_ENCODAGE: "You have sent the invoice several times",
    EFACT_ERROR: "You're in the wrong mode of billing",
    PAPER_ERROR: "The bill has been replaced by a paper invoice",
    SITUATION_ERROR: "The situation does not establish an invoice eAttest",
    CANCEL_AN_EATTEST: "Cancel an invoice eAttest",
    CANCEL_THE_EATTEST: "Cancel the invoice eAttest",
    CLOSE: "Close",
  },
  "patient-invoicing-confirm": {
    TITLE: "Confirm the payment from the patient",
    PAYMENT_BY_PATIENT: "Payment by the patient",
    PAYMENT_MODE: "Mode of payment",
    ARCHIVE: "Archiver",
    BACK: "Cancel",
    CONFIRM: "Confirm",
    REACTIVATE: "Reactivate",
    AMOUNT_PATIENT: "Amount to be paid by the patient",
    RECEIVED_AMOUNT: "Amount received",
    MONEY_BACK: "Amount to make",
    WARNINGS: {
      EATTEST:
        "Attention, it is not possible to cancel an electronic attestation once it has been submitted.",
      INSUFFISANT_AMOUNT_EATTEST:
        "The amount is not sufficient to pay the certificate electronic.",
      INSUFFISANT_AMOUNT:
        "The amount is not enough. The benefit is partially paid. See details below.",
      WARNING_CODE_USED:
        "The following codes have already been used this day for the same patient",
    },
  },
  "invoices-details": {
    ID: "ID",
    DATE: "Date",
    TOTAL: "Total",
    AUTHOR: "Author",
    PATIENT: "Patient",
    SSIN: "National number",
    MUTUALITY: "Mutual",
    INTERVENTION: "Intervention",
    INVOICING_CODES: "Benefits",
    TICKET_MODERATEUR: "TM",
    INVOICE_TO: "Charged",
    INVOICE_TYPE: "Type of invoice",
    INVOICE_NUMBER: "N° of invoice",
    CREDIT_NOTE: "N.C.",
    PAYMENT_TYPE: "Type of payment",
    PAYED: "Paid",
    EDIT: "Edit",
    PAID_AMOUNT: "Amount paid",
    STATUS: "Status",
    ASSISTANT: "Assistant",
  },
  "base-search-combobox": {
    SEARCH_FAILED: "The search failed",
  },
  "hcp-search-combobox": {
    SEARCH: "Search for a speaker",
  },
  "user-search-combobox": {
    SEARCH: "Search for a user",
  },
  "patient-sumehr": {
    SUMEHR_TITLE: "Sumehr",
    WARNING:
      "Disclaimer : The data presented in a SUMEHR can not be regarded as exhaustive. This is in concordance with the principles of the RSW. These data are present for informational purposes only and does not provide a amnèse detailed.",
  },
  "contact-pdf": {
    PATIENT: "Patient",
  },
  "patient-subscription": {
    TITLE: "Management of medical house subscription",
    CREATION: {
      failed: "The creation of subscription failed.",
    },
    CLOSURE: {
      failed: "The closure of subscription failed.",
    },
    CANCEL: {
      failed: "The cancellation of subscription failed.",
    },
  },
  "patient-beoogo": {
    TITLE: "MMF Registration",
    SUBSCRIPTION_DATE: "Subscription Date",
    UNSUBSCRIPTION_DATE: "Unsubscription Date",
    UNSUBSCRIPTION_REASON: "Unsubscription Reason",
    SUBSCRIPTION_TYPE: "Subscription Type",
    SUBSCRIPTION_M: "M",
    SUBSCRIPTION_K: "K",
    SUBSCRIPTION_I: "I",
    TRIAL_PERIOD: "Trial Period",
    SUBMISSION_WARNING:
      "By clicking on Send, the information will be updated in Beoogo",
    SEND_TO_BEOOGO: "Send",
    CANCEL: "Cancel",
    UNSUBSCRIPTION_REASON_1: "Reason 1",
    UNSUBSCRIPTION_REASON_2: "Reason 2",
    UNSUBSCRIPTION_REASON_3: "Reason 3",
    INSURANCE_0: "No Insurance",
    INSURANCE_1: "Insurance not applicable",
    INSURANCE_2: "With Insurance",
    DISPLAY: {
      TITLE: "Display",
      LEGENDS: "Legends",
      DATA: "Data",
      HOLDER: "Holder",
      START_DATE: "Start Date",
      INSURANCE_TYPE: "Insurance Type",
      NOTE: "Note",
      INSURANCE_CODE: "Insurance Code",
      INSURANCE_REFERENCE: "Insurance Reference",
      HOLDER_ID: "Holder ID",
      HOLDER_CODE: "Holder Code",
      PACKAGE_ENABLED_CODES: "Package Enabled Codes",
      STATUS: "Status",
    },
  },
  "patient-covid-vaccination": {
    TITLE: "Status of covid vaccination",
    NOT_AVAILABLE: "Service is not available for your qualification",
    SEND: "Register patient",
    CANCEL: "Close",
    SYNCHRONIZE: "Recovery data",
    NO_DATA: "No data found for this patient",
    DATA: {
      title: "Identification data",
      lastName: "Name",
      firstName: "First name",
      birthDate: "Birthdate",
      ssin: "Ssin",
    },
    VACCINATION: {
      tab: "Schedule(s)",
      title: "Schedule",
      creationDate: "Date of creation",
      desactivationDate: "Date of desactivation",
      deactivationReason: "Reason of desactivation",
      vaccineType: "Type of vaccine",
      status: "Status",
      source: "Source",
      desactivated: "Deactivation of vaccination code",
      activated: "Activation of vaccination code",
      alreadyVaccined: "Patient is already vaccined",
      patientRefusal: "Patient refusal vaccination",
      error: "Error during patient recovery",
      pregnancy: "modify priority for pregnant woman",
      UPDATE: {
        success: "Vaccination has been updated",
      },
      DEACTIVATION: {
        CITIZEN_REFUSE_VACCINATION: "Vaccination refusal",
        CITIZEN_ALREADY_VACCINATED: "Vaccination has already been performed",
        "": "",
        null: "",
        success: "Vaccination is successfully deactivated",
        error: "Error when deactivation of vaccination",
        undefined: "",
      },
      ACTIVATION: {
        success: "Vaccination is successfully activated",
        error: "Error when activation of vaccination",
      },
      STATUS: {
        ACTIVATED: "Activated",
        NOT_ACTIVATED: "Not activated",
        DEACTIVATED: "Deactivated",
        UNKNOWN_SSIN: "Unknown ssin",
        "": "",
        null: "",
        undefined: "",
      },
      SOURCE: {
        RESERVATION_TOOL: "Reservation tool",
        CIN_NIC: "Mutuality",
        GP: "Doctor",
        COBRHA: "Federated entities",
        CITIZEN: "Citizen",
        MEDIPRIO1: "Pregnant",
        "": "",
        null: "",
        undefined: "",
      },
    },
    INJECTION: {
      tab: "Vaccination(s)",
      title: "Vaccination",
      vaccineDescription: "Description",
      vaccinatorTypeDescription: "Type of vaccinator",
      vaccineAdministrationDate: "Date",
      vaccineDosesAdministration: "Dose",
      batchNbr: "Batch n°",
      vaccinet: "Link to vaccinet",
      DOSE_ADMINISTRATION: {
        A: "1/2",
        B: "2/2",
        C: "1/1",
      },
    },
    EVENT: {
      title: "Convocation",
    },
    SCHEDULE: {
      title: "Schedule",
      doseNumber: "Dose number",
      scheduleDate: "Schedule date",
      vaccineManufacturer: "Vaccine manufacturer",
      MODE: {
        LETTER: "Letter",
        SMS: "Sms",
        EMAIL: "Email",
        EBOX: "Ebox",
        "": "",
        null: "",
        undefined: "",
      },
    },
    NOTIFICATION: {
      tab: "Convocation(s)",
      title: "Convocation",
      target: "Target",
      channel: "Channel",
      date: "Send date",
      code: "Vaccination date",
    },
    ERROR: {
      ssin: "Patient doesn't have niss",
    },
    REGISTER: {
      success: "Patient is registered for vaccination",
      warning: "Please note, the patient's niss is not known to the service",
    },
  },
  "contact-display": {
    AUTHOR: "Author",
    INTEGRATOR: "Integrator",
    AT_TIME: "à {{time}}",
    CHANGE_OPENING_DATE: "Change the opening date",
    EDIT: "Edit",
    SAVE: "Save",
    CLOSE: "Close",
    CANCEL: "Cancel",
    DELETE: "Delete",
    LAST_SAVE: "Updated",
    PLACEHOLDER: {
      TRANSACTIONREASON: "Reasons",
      SUBJECTIVE: "Subjective",
      ASSESSMENT: "Diagnostics",
      NOTE: "Notes",
    },
    EDIT_WINDOW_ENDED: "The editing window is exceeded.",
    ADD_SUB_CONTACT: "Add a sub-contact",
    DELETE_CONTACT: "Suppression du contact",
    DELETE_CONTACT_WARNING:
      "No turning back will not be possible and you may lose important data present in the contact.",
    DELETE_THIS_CONTACT: "To delete the contact",
  },
  "contact-type-display": {
    CHANGE_CONTACT_TYPE: "Change the type of contact",
    TYPES: {
      consult: "Consultation",
      labresult: "Laboratory result",
      contactreport: "Report",
      telephonicconsultation: "Notices/Miscellaneous",
      multidisciplinaryconsult: "Multidisciplinary discussion",
      homevisit: "Home visit",
      contactfile: "Contact file",
      electronicconsultation: "Electronic consultation",
      missedappointment: "Missed appointment",
      administrativecontact: "Administrative contact",
    },
  },
  "subcontact-health-elements": {
    HEALTH_ELEMENT_ASSOCIATION_SUCCEEDED:
      "The element of care has been associated with the sub-contact.",
    HEALTH_ELEMENT_ASSOCIATION_FAILED:
      "The association of the element of care to the sub-contact has failed.",
    HEALTH_ELEMENT_DISSOCIATION_SUCCEEDED:
      "The element of care has been separated from the sub-contact.",
    HEALTH_ELEMENT_DISSOCIATION_FAILED:
      "The dissociation of the element of care of the under-contact has failed.",
    LINK_HEALTH_ELEMENT: "Link the sub-contact to an element of care",
  },
  "health-element-popup": {
    NEW_HEALTH_ELEMENT: "New element of care",
    HEALTH_ELEMENT_CREATION_SUCCEEDED: "The element of care has been created.",
    HEALTH_ELEMENT_CREATION_FAILED:
      "The creation of the element of care has failed.",
    SEARCH: "Search",
    CREATE_FROM_SUB_CONTACT: "Create from sub-contact",
  },
  "patient-eid": {
    UPDATE_SUCCESS:
      "Update patient data on the basis of the eID is to be successful.",
    UPDATE_ERROR: "Update patient data on the basis of the eID failed.",
    CREATE_ERROR: "Creation of a patient on the basis of the eID failed.",
    EID_READ_ERROR: "The reading of the eID has failed",
    EID_TITLE: "Select a patient",
    PATIENT_SSIN_ALREADY_EXISTS:
      "A patient with the same national number as that read from the eID has been found.",
    CHOOSE_PATIENT: "Please choose a patient below.",
  },
  "appointment-tags": {
    tags: "Tags",
    IN_WAITING_ROOM: "This in the waiting room",
    CANCELED_BY_PATIENT: "Cancelled by the patient",
    CANCELED_BY_PATIENT_TARDILY: "Cancelled by the patient late",
    CANCELED_BY_DOCTOR: "Cancelled by the doctor",
    DID_NOT_COME: "Not come",
    LATE: "Late",
    BEING_CONSULTED: "In the course of consultations",
    CLOSED: "Ended",
    CONFIRM_DOCTOR: "Confirm doctor",
    CONFIRM_DRIVER: "Confirm driver",
    CONFIRM_PATIENT: "Confirmer patient",
  },
  "patient-events-search": {
    PATIENT_DOES_NOT_EXISTS:
      "This patient doesn't exist or doesn't exist anymore",
    APPOINTMENT_TYPE: "Type of appointment",
    DATE: "Date",
    STARTTIME: "Start time",
    DURATION: "Duration (min)",
    REASON: "Reason",
    STATUS: "Status",
  },
  "ms-calendar": {
    NO_ACCESS_TO_AGENDA: "You do not have access to any calendar.",
    HIDE_SIDEBAR: "Hide the left panel",
    SHOW_SIDEBAR: "View the left panel",
    HIDE_SIDEBAR_R: "Hide the right panel",
    SHOW_SIDEBAR_R: "Display the right panel",
    GUARDPOST_LOAD_AGENDA_ERROR: "Impossible to find the agenda {{id}}",
    SEARCH_PATIENT_EVENTS: "Find an appointment",
    filters: {
      PRINT: "Print",
      DISPONIBILITY: "Availability practitioners",
      MANAGE_CALENDAR_ITEM_TYPES: "Types of APPOINTMENT",
      MANAGE_CALENDAR_ITEM_PLACES: "Places of APPOINTMENT",
      MANAGE_AGENDA: "Management agenda",
      OPEN_KEYWORD_AUTOCOMPLETE: "View the auto-completion of keywords",
      AGENDAS: "Agendas",
      FIRST_NAME: "First name",
      LAST_NAME: "Name",
      DISPLAY_CANCELED_EVENTS: "Show cancelled appointments",
      EVENT_TYPES: "Types of appointments",
      MIN: "min",
      NAME: "Name",
      SYNC: "Retrieve the list of appointments",
    },
    scheduler: {
      TODAY: "Today",
      WEEK: "Week",
      DAY: "Day",
      MY_SCHEDULE: "Mon planning",
      ALL_DAY: "All day",
    },
    "time-table-list": {
      TITLE: "List of schedules",
      AGENDA: "Agenda",
      ADD_TIMETABLE: "Add a schedule",
      NAME: "Name",
      START: "Beginning",
      END: "Fin",
      messages: {
        CREATE_TIMETABLE_SUCCESS: "Creation of the schedule done.",
        CREATE_TIMETABLE_ERROR: "An error occurred when creating the schedule.",
        UPDATE_TIMETABLE_SUCCESS: "Update of the schedule done.",
        UPDATE_TIMETABLE_ERROR:
          "An error occurred during the update of the schedule.",
        DELETE_TIMETABLE_SUCCESS: "Deletion of the schedule done.",
        DELETE_TIMETABLE_ERROR:
          "An error occurred while deleting the schedule.",
      },
    },
    "time-table": {
      TITLE: "Editing a schedule",
      NAME: "Name",
      START: "Beginning",
      END: "Fin",
      ADD_TIMETABLE_ITEM: "Add an item to the schedule",
      DAYS: "Day(s)",
      HOURS: "Time(s)",
      RECURRENCE: "Recurrence",
      EVERY_WEEK: "Every week",
      OTHER: "Other",
      EVENT_TYPE: "Type of appointment",
      EVENT_PLACE: "Venue",
      VISIT: "Visit",
      CONSULTATION: "Consultation",
      SAVE_TIMETABLE: "Save the schedule",
      WARNING: {
        REQUIRED_FIELD: "Mandatory field",
      },
      days: {
        MONDAY: "Monday",
        TUESDAY: "Tuesday",
        WEDNESDAY: "Wednesday",
        THURSDAY: "Thursday",
        FRIDAY: "Friday",
        SATURDAY: "Saturday",
        SUNDAY: "Sunday",
      },
    },
    "calendar-item-type-list": {
      TITLE: "List of types of appointments",
      ADD_CALENDAR_ITEM_TYPE: "Add an appointment type",
      NAME: "Name",
      COLOR: "Color",
      DURATION: "Duration (minutes)",
      MINUTE: "minute",
      MINUTES: "minutes",
      messages: {
        CREATE_CALENDAR_ITEM_TYPE_SUCCESS:
          "Creation of the type of appointment made.",
        CREATE_CALENDAR_ITEM_TYPE_ERROR:
          "An error occurred during the creation of the type of appointment.",
        UPDATE_CALENDAR_ITEM_TYPE_SUCCESS:
          "Update the type of appointment made.",
        UPDATE_CALENDAR_ITEM_TYPE_ERROR:
          "An error occurred during the update of the type of appointment.",
        DELETE_CALENDAR_ITEM_TYPE_SUCCESS:
          "Removal of the type of appointment made.",
        DELETE_CALENDAR_ITEM_TYPE_ERROR:
          "An error occurred during the deletion of the appointment type.",
      },
    },
    "calendar-item-place-list": {
      TITLE: "List of meeting places",
      ADD_CALENDAR_ITEM_PLACE: "Add a place",
      NAME: "Name",
      messages: {
        CREATE_CALENDAR_ITEM_PLACE_SUCCESS:
          "Creation of the venue you performed.",
        CREATE_CALENDAR_ITEM_PLACE_ERROR:
          "An error occurred during the creation of the meeting place.",
        UPDATE_CALENDAR_ITEM_PLACE_SUCCESS:
          "Update to the place of appointment is made.",
        UPDATE_CALENDAR_ITEM_PLACE_ERROR:
          "An error occurred during the update of the place of appointment.",
        DELETE_CALENDAR_ITEM_PLACE_SUCCESS:
          "Removal from the venue you performed.",
        DELETE_CALENDAR_ITEM_PLACE_ERROR:
          "An error occurred during the deletion of the place of appointment.",
      },
    },
    "calendar-item-place": {
      TITLE: "Edition of a place",
      NAME: "Name",
      SAVE_PLACE: "Save the place",
    },
    "agenda-management": {
      TITLE: "Management agenda",
      NAME: "Name",
      RIGHTS: "Rights",
      SAVE_AGENDA: "Back up the agenda",
      rights: {
        AGENDA_READ: "Lecture",
        AGENDA_WRITE: "Scripture",
        AGENDA_ADMINISTRATION: "Administration",
      },
      messages: {
        SAVE_AGENDA_SUCCESS: "Backup access rights to the agenda made.",
        SAVE_AGENDA_ERROR:
          "An error occurred when saving access rights to the calendar.",
      },
    },
    "guardPost-service": {
      NEW_ITEMS_AVAILABLE: "New items are available in the feed 1733",
      UNABLE_TO_DETERMINE_MEDICAL_LOCATION:
        "Impossible to determine your entity, the flow will not be loaded.",
      PROBLEM_ENCRYPTION:
        "There is a problem with your user and the service of the 1733 guard post will soon no longer be available to you. Please contact support to solve the problem.",
      DECRYPTED_CALL_FAILED:
        "There has been error while fetching the 1733 feed. Please update Medispring and contact the support if the error persists.",
    },
    "calendar-event-form": {
      TITLE_CREATE_EVENT: "Creation of an appointment",
      TITLE_EDIT_EVENT: "Editing an appointment",
      TITLE_CREATE_EVENT_FOR_X: "Creating a new appointment for {{practitian}}",
      TITLE_EDIT_EVENT_FOR_X: "Editing an appointment for {{practitian}}",
      EVENT_TITLE: "Title",
      EVENT_TYPE: "Type",
      PATIENT: "Patient",
      CREATE_NEW_PATIENT: "Create a new patient",
      OPEN_PATIENT: "Open the patient's folder",
      CLOSE_PATIENT: "Close",
      CANCEL_PATIENT: "Cancel",
      PATIENT_DOES_NOT_EXISTS: "The patient does not exist or no longer exists",
      IMPORTANT: "Important",
      VISIT_TYPE: "Type of visit",
      VISIT: "Visit",
      CONSULTATION: "Consultation",
      PHONE_NUMBER: "Phone",
      PLACE: "Place",
      MAP: "View on a map",
      GPS: "Send to GPS",
      ADDRESS: "Address",
      EVENT_DATE: "Date",
      START_TIME: "Beginning",
      END_TIME: "Fin",
      DURATION: "Duration (minutes)",
      ALL_DAY: "All day",
      DETAILS: "Motif",
      DELETE_EVENT: "Delete the APPOINTMENT",
      SAVE_EVENT: "Save the RDV",
      SINCE: "since",
      MEDICAL_HOUSE_TITLE: "Medical House",
      flow: {
        REASON: "Motif",
        COMMENT: "Comment",
        PHONE: "Phone",
        TYPE: "Type",
        ADDRESS: "Address",
        GPS_COORDINATES: "GPS coordinates",
        LATITUDE: "Latitude",
        LONGITUDE: "Longitude",
        ACTIVATE_NOTIFICATION_SOUND: "Activate the notification sound",
        DEACTIVATE_NOTIFICATION_SOUND: "Turn off the notification sound",
        LAST_UPDATE_DATE: "Last update",
        MARKED_AS_TREATED: "Flow marqueé as treaty",
      },
      messages: {
        CREATE_EVENT_SUCCESS: "Creation of the appointments made.",
        CREATE_EVENT_ERROR: "An error occurred when creating the appointment.",
        UPDATE_EVENT_SUCCESS: "Update of the appointments made.",
        UPDATE_EVENT_ERROR: "An error occurred while updating the appointment.",
        DELETE_EVENT_SUCCESS: "Delete the appointment you made.",
        DELETE_EVENT_ERROR: "An error occurred when deleting the appointment.",
      },
    },
    tooltip: {
      DATE_AND_DURATION: "Date and time",
      MINUTES: "minutes",
    },
    flow: {
      FLOW: "Flux",
      DISPLAY_ALL_FLOW_ITEMS: "Display all of the elements",
      ERROR_GET: "Error during loading of the flow elements 1733",
      item: {
        CANCEL_ELEMENT: "Cancel the element",
        PROCESSED_BY: "Treaty by",
        CANCELED_BY: "Cancelled by",
        PROCESSED_THE: "the",
        CANCELED_THE: "the",
      },
      delete: {
        TITLE: "Cancel an element from the flow",
        REASON: "Motif",
        NOTE: "Note",
        CONFIRM_CANCELLATION: "Confirm the cancellation",
      },
    },
    "flow-item": {
      VISIT: "Visit",
      CONSULTATION: "Consultation",
      OPINION: "Request for an opinion",
      TESTCOVID19: "Covid-19 test",
      COVID19: "Covid-19",
      UNKNOW: "Unknown",
    },
    "flow-treated-conflict": {
      WARNING_MESSAGE:
        "The message has been processed by another user, please cancel the actions taken for this message to avoid duplicate events.",
      PATIENT_FIRSTNAME: "First name of the patient",
      PATIENT_LASTNAME: "Name of the patient",
      TITLE: "Title",
      STREET: "Street",
      I_UNDERSTAND: "I understood",
    },
    messages: {
      OUT_OF_BUSINESS_HOURS_WARNING:
        "The selected time period is outside of the hours of availability.",
      EVENT_OVERLAPPING_WARNING:
        "The selected time period overlaps with another appointment.",
      NO_PATIENT_LINKED_TO_EVENT: "No patient-related appointments.",
      PATIENT_OR_DETAIL_MANDATORY:
        "It is necessary to inform at a minimum, the patient or the reason.",
      PATIENT_DOES_NOT_EXISTS:
        "The patient associated with the appointments does not exist or no longer exists.",
    },
    actions: {
      ACTIONS: "Actions",
      EDIT: "Edit",
      REMOVE: "Delete",
      ADD: "Add",
      UPDATE: "Update",
      CANCEL: "Cancel",
    },
    NO_ACCESS_TO_EVENTS: "Unable to retrieve events in your calendars",
  },
  "keywords-editor-wrapper": {
    UNSUPPORTED_FORMAT: {
      WARNING: "The format of this document is not supported.",
      CONSEQUENCE: "This can cause a unexpected page.",
    },
  },
  keyword: {
    "keyword-management": {
      ADD_KEYWORD: "Add a key word",
      KEYWORDS: "Keywords",
      SUBWORD: "Word child",
      SHORTCUTS: "Shortcuts",
      VALUES: "Values",
    },
    "keyword-autocomplete": {
      TITLE: "Keywords - auto-completion",
    },
    actions: {
      ACTIONS: "Actions",
      EDIT: "Edit",
      REMOVE: "Delete",
      REMOVE_KEYWORD: "Remove the key word",
      ADD: "Add",
      UPDATE: "Save",
      CANCEL: "Cancel",
      YES: "Yes",
      NO: "Non",
    },
    "toolbar-actions": {
      ADD_SUBWORD: "Add a word child",
      ADD_WORD: "Add a word",
      DELETE: "Delete",
      EDIT: "Edit",
    },
    messages: {
      CREATE_KEYWORD_SUCCESS: "Creation of the keyword done.",
      CREATE_KEYWORD_ERROR: "An error occurred when creating key word.",
      UPDATE_KEYWORD_SUCCESS: "Update of the keyword done.",
      UPDATE_KEYWORD_ERROR:
        "An error occurred during the update of the keyword.",
      DELETE_KEYWORD_REASK:
        "The removal of the keyword will also remove all its shortcuts.",
      DELETE_KEYWORD_SUCCESS: "Deletion of the word-key.",
      DELETE_KEYWORD_ERROR:
        "An error occurred during deletion of the key word.",
    },
  },
  "patient-posology-scheme-print": {
    MED_CHRONIC_DAILY: "Medication chronic daily",
    NAME: "Name",
    REMARK: "Note",
    MED_CACUTE_DAILY: "Medicine temporary daily",
    END_DATE: "End Date",
    MED_OTHER_FREQUENCY: "Medicine with a different frequency",
    POSOLOGY: "Dosage",
    TREATMENTS_OF: "Treatments",
    PAGE: "Page",
  },
  "patient-medication": {
    POSOLOGY: "S/",
    QUANTITY: "Dt/",
    ADMINISTRATION_MODE: "Administration type",
    BOXES: "Box(s)",
    SAMV2_VERSION: "SAMv2 Version",
    pdfElectronic: {
      DATE: "Date",
      DELIVRANCE_DATE: "Date of issue",
      INFOBOTTOM:
        "Attention : No new manuscript of this document will not be taken into account",
      INFOTOP:
        "Please submit this document to your pharmacist to scan the bar code and issue you with the prescribed medications",
      CONTENT: "Content of the electronic prescription",
      INSS: "SSIN",
      PATIENT: "Patient",
      NIHII: "INAMI",
      PRACTITIONER: "Practitioner",
      PROOF: "Evidence of prescription electronics",
    },
    pdfPaper: {
      DATE: "Date",
      DELIVRANCE_DATE: "Deliverable from the above date\nor from the",
      BITRH_DATE: "Date of birth",
      INFOBOTTOM:
        "Attention : No new manuscript of this document will not be taken into account",
      INFOTOP:
        "Please submit this document to your pharmacist to scan the bar code and issue you with the prescribed medications",
      CONTENT: "Content of the electronic prescription",
      INSS: "SSIN",
      PATIENT: "first and last name\nthe beneficiary",
      NIHII: "INAMI",
      PRACTITIONER: "Name and first name of the prescriber",
      PROOF: "Evidence of prescription electronics",
      PRACTITIONER_FILL: "To be filled in by the prescriber",
      VIGNETTE: "Reserved for the thumbnail\npackaging",
      STAMP: "Stamp prescriber's",
      DATE_SIGNATURE: "Date and signature of the prescriber",
      MEDICATION: "PRESCRIPTION DE MEDICAMENTS",
    },
    TITLE: "Current treatments",
    MEDICATION: "Medication",
    HISTORIQUE: "History",
    CHAPITRE_IV: "Chapter IV",
    E_RECIPE: "Order History",
    CLOSE: "Close",
    PRINT_POSOLOGIE: "Print regimen",
    SAVE_POSOLOGIE: "Download dosage regimen",
    CANCEL: "Cancel",
    RESET: "Reset",
    REGISTER: "Save",
    REGISTER_AND_PRESCRIBE: "Save and prescribe",
    CHOOSE_MEDICAMENT: "Choosing a medication",
    CHOOSE_MAGISTRAL_PERSO: "Choose a brilliant personal",
    CHOOSE_DCI: "Choose an INN",
    JEUN: "With an empty stomach",
    MORNING: "Morning",
    "10H": "10h",
    LUNCH: "Noon",
    "16H": "16h",
    EVENING: "Evening",
    NIGHT: "Couch.",
    REPAS: "Meal",
    BEFORE: "Av",
    DURING: "Pd",
    AFTER: "Ap",
    "TOUS LES": "All",
    REMARQUES: "Notes",
    JOURS: "Days",
    CHRONIQUE: "Chronic",
    ONESHOT: "OneShot",
    FREEPOSOLOGY: "Free posology",
    DRUGROUTE: "Drug route",
    FROM: "the",
    TO: "the",
    LINK_HEALTH_ELEM: "Link with the item of care : ",
    PRESCRIPTION_EN_COURS: "Prescription en cours",
    NOTE: "Note",
    TR_DURING: "Current treatment",
    TR_AIGU: "Acute Treatment",
    TR_CHRONIQUE: "Chronic Treatment",
    N_COMP_X_PER_PERIOD: "{{n_comp}} comp {{x_time}}x par {{period}}",
    CBIP_DETAILS: "More details via the cbip",
    CBIP: "CBIP",
    SAVE: "Save",
    SAVE_PRINT: "Save and Print",
    EMPTY: "Order blank",
    ADD_TREATMENT: "Add this treatment",
    ADD_AND_PRESCRIBE_TREATMENT: "Add and prescribe this treatment",
    ADD_ALL_TREATMENT: "Add all the chronic treatments",
    DELETE_TREATMENT: "Remove the processing",
    DELETE_ORDER: "Delete the order",
    PRESCRIBER: "Prescriber",
    ORDER: "Order",
    BEFORE_LUNCH: "before meal",
    DURING_LUNCH: "during the meal",
    AFTER_LUNCH: "after the meal",
    A_JEUN: "fasting",
    A_MORNING: "in the morning",
    A_10H: "at 10am",
    A_LUNCH: "at noon",
    A_16H: "at 16h",
    A_EVENING: "in the evening",
    A_NIGHT: "at bedtime",
    NO_POSOLOGY: "No dosage indicated",
    PRESCRIBE: "Prescribing on the prescription",
    TAKE_DURING_X_DAYS_1: "To take for 1 day",
    TAKE_DURING_X_DAYS_N: "To take over {{n}} days",
    UNITIES: "Units",
    CHECK_REASON:
      "Your patient is allergic (or intolerant) to this class of drug for the following reason :",
    PRESCRIBE_ANYWAY: "Prescribe when same",
    NOT_PRESCRIBE: "Do not prescribe",
    CHAPTER_IV_AVAILABLE: "Reimbursement chapter IV available",
    EDIT_TREATMENT: "Change this treatment",
    ARCHIVE: "Archiver",
    EDITION_OF: "Edition of : ",
    ADD: "Add",
    NOT_ADD: "Do not add",
    ERROR_ATC_CHECK: "Unable to check codes ATC",
    ERROR_UPLOAD: "The sending of the order to failed",
    ERROR_GET_MEDICATION_LIST: "Unable to get the list of medicines.",
    ERROR_GET_MAP_LIST: "Unable to get the list of your masterful.",
    ERROR_GET_PER_LIST: "Unable to get the list of your medications personels.",
    ERROR_GET_MORE_INFO_MED:
      "Impossible to obtain additional information on the drug.",
    ERROR_UPLOAD_ERECEIP: "The prescription could not be sent to Recip-e",
    ERECEIP_CREATIPON_OK: "Prescription sent to Recip-e.",
    DELIVRANCE_DATE: "Date of issue",
    END_THE: "Finished the",
    CHOSE_PRESCRIPTOR: "Please choose a prescriber",
    PRINT_SUCCESS: "The documents have been sent to your printer",
    PRINT_ERROR: "Error during the printing of the documents",
    INVALID_REASON:
      "This treatment could not be imported since the migration. We recommend that you archive this treatment and re-create it.",
    INVALID_REASON_PRESCRIBR:
      "You try to prescribe a treatment invalid (shown in red in the list of treatments). Be sure to archive it and recreate it.",
    ORDONNANCE_N_TOO_LONG:
      "Attention : the order n°{{n}} is long and may be mis-printed.",
    RRSSTATE: {
      R: "Not cheap, with a price supplement.",
      G: "Cheap",
      B: "Not cheap, not extra cost.",
    },
    PRICE_EVALUATION: {
      NOT_CHEAP_WITH_PRICE_SUPPLEMENT: "Not cheap, with a price supplement.",
      CHEAP: "Cheap",
      NOT_CHEAP_NO_PRICE_SUPPLEMENT: "Not cheap, not extra cost.",
    },
    PRINT: "Print",
    ERECIPE_AND_PRINT: "Send via eRecipe and print",
    TREATMENT_HISTORY: "Processing history",
    CANNOT_ADD_WHILE_EDITING:
      "You cannot add a treatment whilst it is being edited",
    EDIT_TREATMENT_IN_PROLONG_MODE:
      "You can only modify the N° of days in the treatment because it is on a prescription",
    START: "Beginning",
    SAVING_MEDICATION: "Saving Medication",
    DONE_SAVING: "Done Saving",
  },
  date: {
    FROM: "The",
    TO: "the",
  },
  "attachment-service": {
    ERROR_OPEN_TAB: "Imposible to recover the file.",
  },
  "healthcare-party-service": {
    ERROR_GET_HCPS: "Unable to retrieve the list of stakeholders",
  },
  "mediction-service": {
    ERROR_NEW_ES:
      "An error occurred during creation of an element of care of the archiving of the treatment.",
    ERROR_SAVE_CONTACT: "An error occurred while saving the contact.",
    ERROR_NEW_CONTACT: "An error occurred while creating a new contact.",
  },
  "filter-date": {
    TITLE: "Filter on a period of care",
    DU: "The",
    AU: "the",
    OK: "Apply the filter",
    CANCEL: "Cancel",
    LAST_6_MONTHS: "The last 6 months",
    LAST_MONTH: "Last month",
    LAST_WEEK: "In the past week",
  },
  "information-update": {
    TITLE: "Update patient information",
    DESC: "New information is available for the patient",
    ACTUAL_TITLE: "Current information",
    NEW_TITLE: "New Information ",
    SSIN: "SSIN",
    NAME: "Name",
    BIRTH_DATE: "Date of Birth",
    DEATH_DATE: "Date of Death",
    CIVIL_STATUS: "Civil Status",
    ADDRESS: "Address",
    OK: "Update Information",
    CANCEL: "Keep Information",
    NATIONALITY: "Nationality",
    CIVILITY: "Civility",
    GENDER: "Gender",
    BIRTHPLACE: "Birth place",
    DEATHPLACE: "Death place",
    "TEMPORARY-ADDRESS": "Temporary address",
    "POST-ADDRESS": "Postal address",
    "DIPLOMATIC-ADDRESS": "Diplomatic address",
    "DIPLOMATIC-POST-ADDRESS": "Diplomatic post address",
    "REFERENCE-ADDRESS": "Reference address",
    "RESIDENTIAL-ADDRESS": "Residential address",
  },
  "service-search": {
    FIND_CODE: "Search for a code",
  },
  "msoap-o": {
    ADD_OBJECTIVE: "Add an entry",
    SELECTION: {
      BIOMETRY: "Biometrics",
    },
    OBJECTIVE_PLACEHOLDER: "Objective",
    BOX: {
      HEIGHT: "Size :",
      WEIGHT: "Weight :",
      BMI: "IMC :",
      HEAD_CIRC: "PC :",
      HIP_CIRC: "PA :",
      BLOOD_PRESSURE: "OF :",
      HEART_RATE: "Pulse :",
      GLYCEMIA: "Glyc :",
      TEMPERATURE: "T° :",
      SATURATION_O2: "SatO² :",
      INR: "INR :",
      PAIN: "Pain scale: level",
    },
    hide: {
      TITLE: "Confirmation",
      CONFIRM: "Are you sure you want to delete this measurement ?",
      YES: "Yes",
      NO: "No",
      HIDE: "Delete this biometric",
    },
  },
  "msoap-p": {
    PLAN_PLACEHOLDER: "Plan d'action",
    ADD_PLAN: "Add an entry",
    NEW_PATIENT_INCAPACITY: "ITT",
    NEW_PATIENT_PHYSIOTHERAPY: "Request physio",
    NEW_PATIENT_MEDICAL_CARES: "Request infi",
    NEW_PATIENT_RX: "Request RX",
    NEW_PATIENT_VACCINE: "Vaccine",
    NEW_PATIENT_PROCEDURE: "Procedure",
    NEW_PATIENT_DOCUMENT: "Documents",
    NEW_PATIENT_MEDICATION: "Prescription",
    "NEW_PATIENT_ECONSULT-DERMATOLOGY": "Teledermatology application",
    FILE: "Attachments",
    ERECEIP_PRESCRIPTION: "Electronic prescribing",
    PAPER_PRESCRIPTION: "Prescription papier",
    TASK: "Task",
    NEW_PATIENT_TASK: "Task",
    TASK_DEADLINE: "dans {{deadline}}",
    TREATMENT: "Treatment",
    SUMMARY: "Summary",
    ERROR_SAVE: "The backup failed",
    PRINT: "Print",
    MODAL_FILE_TITLE: "Attachment",
  },
  "patient-biometry-dialog": {
    TAB: {
      VASCULAR: "TA/pulse",
      WEIGHT_HEIGHT: "Weight/size",
      BLOOD: "Glyc/T°/O²",
    },
    NOTE: "Notes",
    TASKS: "Tasks",
    TASK_PRESET: {
      FREE_TEXT: "(label free)",
      VASCULAR: "control TA/pulse",
      SATURATION: "control saturation",
      HEIGHT_WEIGHT: "control weight/size",
      CRANIAL_PERIMETER: "control head circumference",
      ABDOMINAL_PERIMETER: "control abdominal perimeter",
      GLYCEMIA: "control blood sugar",
      TEMPERATURE: "temperature control",
    },
    CANCEL: "Cancel",
    SAVE: "Save",
    UNKNOWN_GENDER:
      "It is not known show the referential data if the patient's gender is unknown.",
  },
  "patient-incapacity": {
    TITLE: "Certificate of work incapacity",
    TITLE_DIXIT: "Certificate of activity interruption",
    TITLE_MEDICAL_EMP:
      "Certificate of incapacity for work-employee - Confidential",
    TITLE_MEDICAL_IND:
      "Certificate of incapacity for work independent - Confidential",
    TITLE_MEDEX: "Medex medical Certificate",
    GENERIC: "Generic",
    MEDICAL: "To doctor council",
    INCAPACITY: "ITT",
    INCLUDE_MEDICAL: "Include the ITT to doctor board",
    OTHER: "Other",
    DIXIT: "Verifies Said",
    TO: "to",
    MENTION_NOTE:
      "The above-mentioned person has been incapacitated from work following a medical examination",
    template: {
      EMPLOYEE: "Mutual employee",
      INDEPENDANT: "Mutual independent",
      MEDEX: "Medex",
      MEDCONSULT: "Medconsult",
    },
    INCAPACITY_VALUE: "The choice of the disability",
    CHOOSE_INCAPACITY_VALUE:
      "(*Please select or enter at least one disability.)",
    value: {
      WORK: "Work",
      SCHOOL: "Attend the courses",
      NURSERY: "Going to the crib",
      SPORT: "Follow the gymnastics course",
      SWIM: "Follow your swimming lessons",
      EFFORT: "Make violent efforts",
      HOMEQUIT: "Not allowed to leave his home for medical reasons or social",
      OTHER: "Other",
    },
    INCAPACITY_REASON: "Reason of the inability",
    reason: {
      ILLNESS: "Disease",
      SURGERY: "Intervention chirurgicale",
      HOSPITALISATION: "Hospitalisation",
      ACCIDENT: "Accident",
      WORKACCIDENT: "Work Accident",
      FAMILY: "Presence necessary due to the state of health of",
      OCCUPATIONALDISEASE: "Occupational disease",
      PREGNANCY: "Disease due to pregnancy",
      ACCIDENT_OCCURED_ON: "the incident",
      HOSPI_OCCURED_ON: "occurrence the",
      OCCUP_DISEASE_DECLARED_ON: "declared the",
      family: {
        MOTHER: "His mother",
        FATHER: "His father",
        SON: "His son",
        DAUGHTER: "His daughter",
        HUSBAND: "Her husband",
        WIFE: "His wife",
      },
    },
    INCAPACITY_OUTING: "Output",
    outing: {
      ALLOWED: "Authorized",
      FORBIDDEN: "Prohibited",
      NOTRECOMMENDED: "Not recommended",
    },
    date: {
      FROM: "the",
      TO: "the",
      NB_DAYS: "day(s)",
      THAT_IS: "either",
      INCLUDED: "included",
    },
    extension: {
      EXTENSION: "Prolongation",
      BEGINNING: "1st failure",
    },
    INCAPACITY_RESUMPTION: "Reprise",
    resumption: {
      PLANNED_ON: "provided the",
    },
    INCAPACITY_DIAGNOSIS:
      "Diagnosis (symptom, functional disorder, social difficulties)",
    INCAPACITY_JOB: "Profession",
    INCAPACITY_STATUS:
      "Professional Situation at the time of the commencement of the incapacity for work",
    status: {
      WORKER: "Worker",
      EMPLOYEE: "Employee",
      UNEMPLOYED: "Unemployed",
      COMPLSELFEMPLOYED: "Self-complementary",
      INDEPENDANT: "Independent",
      HELPER: "Assisting spouse",
    },
    INCAPACITY_HOSPI_DATE: "Is or will be hospitalized(e) from the",
    INCAPACITY_DELIVERY_DATE:
      "In the case of pregnancy, expected date of delivery",
    HISTORY: "History of disabilities",
    NO_HISTORY: "No history to display",
    error: {
      REQUIRED: "This is a mandatory field",
      POSITIVE_NB: "Minimum value accepted",
    },
    pdf: {
      HEADER:
        "I, the undersigned, Doctor of medicine, certify that I have examined and queried : Mr/Mme <b>{{ patientName }}</b> which is presented(e) to the consultation date",
      HEADER_DIXIT:
        "I, the undersigned, Doctor of medicine, hereby certify to have understood : Mr/Mme <b>{{ patientName }}</b> born(e) {{ patientBirthDate }}, He(she) believes to be incapacitated :",
      INCAPACITY_VALUE: "Inability to",
      INCAPACITY_VALUE_DIXIT: "Who has estimated to be unable to",
      INCAPACITY_REASON: "Because of",
      INCAPACITY_DATES: "For a period of",
    },
    ITT_GENERIC: "ITT generic",
    ITT_MEDICAL: "ITT to doctor board",
    INCAPACITY_MEDICAL: "ITT Med. council",
  },
  "patient-biometry-vascular": {
    SYSTOLIC_BP: "Tension art. sys.",
    DIASTOLIC_BP: "Tension art. dias.",
    HEART_RATE: "Pulse",
    SYSTOLIC_DIASTOLIC_BP: "Tension art. sys. / dias.",
  },
  "patient-biometry-weight-height": {
    WEIGHT: "Weight",
    HEIGHT: "Size",
    HIP_CIRCUMFERENCE: "Perimeter abdo",
    HEAD_CIRCUMFERENCE: "Head circumference",
    BMI: "IMC",
  },
  "patient-biometry-blood": {
    GLYCEMIA: "Blood glucose",
    TEMPERATURE: "T°",
    SATURATIONO2: "Sat O²",
    INR: "INR",
  },
  "patient-biometry-table": {
    DATE: "Date",
    SHOW_VALUES: "The values to display on the graph",
  },
  "patient-msoap-p-services-common": {
    CLOSE: "Close",
    SAVE: "Save",
    PRINT: "Print",
    SAVE_AND_PRINT: "Save and print",
    CANCEL: "Cancel",
    EXTENSION: "Prolongation",
    DATE: "Date",
    OTHER_TREATMENT: "Other treatments",
    MISTER: "Mr",
    MISS: "Ms.",
    MISTER_MISS: "Mr/Mme",
    DIAGNOSIS: "Diagnostic",
    ADD_DIAGNOSIS: "Diagnostic du SOAP",
    HISTORY_OTHER_ITEMS: "et {{ number }} autre(s)",
    CHANGE_LANG: "Change language",
    SEARCH_MAX_REACHED: "You can add more {{ maxNumber }} item(s)",
    sentTo: "Sent to",
    pdf: {
      PATIENT_LASTNAME: "Name",
      PATIENT_FIRSTNAME: "First name",
      PATIENT_BIRTHDATE: "Date of birth",
      PATIENT_SSIN: "N° national",
      PATIENT_INSURABILITY: "Mutual",
    },
    delete: {
      TITLE: "Confirmation",
      CONFIRM: "Are you sure you want to remove this service ?",
      YES: "Yes",
      NO: "No",
    },
  },
  "patient-physiotherapy": {
    TITLE: "Request for the prescription of physiotherapy",
    box: {
      PHYSIOTHERAPY: "Physio",
      SESSIONS: "Sessions",
      FREQUENCY: "salad",
    },
    PHYSIOTHERAPY_SESSIONS: "Sessions",
    PHYSIOTHERAPY_NUMBER_SESSIONS: "session(s) in physiotherapy",
    PHYSIOTHERAPY_FREQUENCY_SESSIONS: "times per",
    PHYSIOTHERAPY_TREATMENT: "Complementary therapies offered",
    treatment: {
      PHYSIOTHERAPY: "Physiotherapy",
      SOPHROLOGY: "Sophrology",
      PRENATALGYM: "Prenatal exercises",
      RESPIRATORY: "Respiratory physiotherapy",
      WALKING: "Gait training",
      LYMPHDRAINAGE: "Drainage lymphatique",
      VESTIBULAR: "Physical therapy vestibular",
      URODYNAMIC: "Physiotherapy, uro-dynamic",
      SPINALMANIP: "Vertebral Manipulation",
      OTHER: "Other treatments",
    },
    PHYSIOTHERAPY_NOTE: "Note",
    note: {
      CHOOSE_S: "Content of S",
      CHOOSE_O: "Content of O",
      S_ADDED: "History",
      O_ADDED: "Clinical examination",
    },
    PHYSIOTHERAPY_EXTENSION: "Prolongation",
    PHYSIOTHERAPY_DATE: "Date",
    PHYSIOTHERAPY_NO_MOVE: "Cannot move for medical reasons or social",
    HISTORY: "History",
    NO_HISTORY: "No history to display",
    history: {
      OTHER_DIAGNOSIS: "et {{ number }} autre(s)",
      NO_DIAGNOSIS: "Diagnosis is not",
      SESSIONS: "session(s)",
    },
    pdf: {
      TITLE: "The Prescription of physiotherapy",
    },
  },
  "patient-medical-cares": {
    TITLE: "Application of nursing",
    box: {
      MEDICAL_CARES: "Infi",
      SESSIONS: "Sessions",
      FREQUENCY: "salad",
    },
    MEDICAL_CARES_NUMBER_SESSIONS: "Number of sessions",
    MEDICAL_CARES_FREQUENCY_SESSIONS: "sessions per",
    MEDICAL_CARES_NOTE: "Additional information",
    MEDICAL_CARES_TREATMENT: "Care",
    treatment: {
      MEDICATION: "Preparation and administration of medications",
      medication: {
        MEDICATION: "Drugs",
        ADMINISTRATION: "Mode d'administration",
        DOSAGE: "Dosage",
        MIXING: "Mixture authorized (if injection of multiple products)",
      },
      WOUNDCARE: "Wound care",
      woundcare: {
        TYPE: "Type of wound",
        ADVICE: "Advice possible",
      },
      HYGIENE: "Toilet and hygiene care",
      COMPRESSION: "Compression therapy",
      compression: {
        TYPE: "Type de compression",
        MODE: "Modality compression",
        TARGET: "Objectives to be achieved",
        STOCKINGS: "The bottom",
        BANDAGE: "Band",
        PLACEMENT: "Placement",
        PLACEMENTREMOVAL: "Placement and removal",
      },
      MATERIALREMOVAL: "Removal of suture material, of wick or catheter",
      removal: {
        TYPE: "Type of care",
        DATE: "Collection Date",
      },
      CATHETERS: "Probes",
      catheters: {
        TYPE: "Type",
        ADVICE: "Advice possible",
        BLADDER: "Monitoring of a urinary catheter at home",
        INOUT: "Sondage in out",
        PUBIC: "Catheter in addition to pubic",
      },
      OSTOMY: "Stoma : care and supervision",
      WEEKLYCALENDAR: "Preparation of the weekly diary",
      DAILYADMINISTRATION:
        "Daily Administration of medications (psychiatric patient)",
      OTHER: "Other care",
    },
    HISTORY: "History",
    NO_HISTORY: "No history to display",
    history: {
      OTHER_TREATMENTS: "et {{ number }} autre(s)",
      NO_TREATMENT: "No treatment",
    },
    pdf: {
      TITLE: "Prescription of nursing care",
    },
  },
  "patient-x-ray": {
    TITLE: "Application of x-ray",
    box: {
      X_RAY: "RX",
    },
    SEARCH: "Reviews",
    search: {
      PLACEHOLDER: "Choose a review",
    },
    CLINICAL_INFO: "Clinical information relevant",
    "clinical-info": {
      CHOOSE_S: "Content of S",
      CHOOSE_O: "Content of O",
      S_ADDED: "History",
      O_ADDED: "Clinical examination",
    },
    DIAGNOSIS: "Explanation of the diagnosis application",
    OTHER_INFO: "Relevant additional information",
    "other-info": {
      ALLERGY: "Allergy",
      DIABETES: "Diabetes",
      PREGNANT: "Pregnancy",
      IMPLANT: "Implant",
      RENALFAILURE: "Renal failure",
      OTHER: "Other",
    },
    PREVIOUS_EXAMS: "Review(s) preceding the application",
    "previous-exams": {
      MRI: "IRM",
      XRAY: "RX",
      CTSCAN: "CT-Scan",
      ECHO: "Echo",
    },
    HISTORY: "History",
    NO_HISTORY: "No history to display",
    history: {
      NO_RADIOGRAPHY: "Not review",
      NO_DIAGNOSIS: "Diagnosis is not",
    },
    pdf: {
      TITLE: "Application form for an exam in medical imaging",
    },
  },
  "patient-procedure": {
    TITLE: "Procedure",
    box: {
      PROCEDURE: "Procedure",
    },
    NOTE: "Note",
    SEARCH: "Procedure",
    search: {
      PLACEHOLDER: "Choose a procedure",
    },
  },
  "magistral-management": {
    MAGISTRAL_MANAGEMENT: "Management of the magistral preparations",
    RESET: "Reset",
    SAVE: "Save",
    DELETE: "Delete",
    EDIT: "Edit",
    EDIT_NOT_MINE: "This unique has been created by another user.",
    PRINT: "Print",
    CLASS: "Class",
    NAME: "Name",
    QUANTITY: "Dt/",
    UNITY: "Unit",
    SHAPE: "Pf/",
    NOTE: "Note",
    FORMULA: "R/",
    POSOLOGY: "S/",
    NEW_CLASS: "New class",
    NEW_CLASS_TITLE: "Create a new class",
    SEARCH: "Find the extemporaneous",
    ERROR_SAVE: "Impossible to save your magistral",
    ERROR_GET: "Impossible to recover your magistral",
    ERROR_GET_PERSONAL: "Unable to retrieve your medications personels",
    actions: {
      REMOVE: "Remove",
      REMOVE_MAGISTRAL: "Remove the magistral",
      CANCEL: "Cancel",
    },
    messages: {
      DELETE_MAGISTRAL_REASK:
        "Are you sure you want to remove this magistral ?",
      DELETE_MAGISTRAL_SUCCESS: "Removal of the magistral performed.",
      DELETE_MAGISTRAL_ERROR: "An error occured while removing the magistral.",
    },
  },
  "patient-history": {
    SORT_TREATMENT: " Sort treatments by :",
    DATE: "Date",
    HEALTH_ELEMENT: "Element of care",
    BEGIN: "Beginning",
    END: "Fin",
    TREATMENT: "Treatment",
    POSOLOGY: "Dosage",
    MOTIF_STOP: "Reason",
    PRESCRIPTOR: "Prescriber",
    CNK: "CNK code",
    UNSPECIFIED: "Not specified",
    BOXES: "{{boxes}} boîtes",
    "date-filter": {
      PERIOD: "Period",
      LAST_6_MONTH: "The last 6 months",
      ALL_TIME: "No filter",
      BEFORE_YEAR: "Avant {{year}}",
    },
    atc_filter: {
      ATC_CLASS: "ATC Class",
      ALL_CLASSES: "All classes",
    },
    "text-filter": {
      SEARCH: "Search",
    },
    MEDICATION_LINES: "Medication lines",
    OUTDATED: "History",
    NOTE: "Note",
    RENEW: "Prescribing of new",
  },
  "soap-templating": {
    PATIENT_ORIENTATION: "Orientation patient",
    TERMINATE: "Custody",
    NO_REFERRING_PHY:
      "The patient has no attending physician is defined in its data sheet.",
    RAPPORT: "Report",
    ERROR_TEMPLATING: "Error generation to the template",
    SERVER_URL: "The url of the server that doesn't",
    SERVER_ERROR: "Internal server error",
    PATIENT_ORIENTATION_OPTIONS: {
      HOME: "Back to home",
      HOSPITALIZATION: "Hospitalisation",
      EMERGENCY: "Send to the emergency department",
      DECEASED: "Deceased Patient",
      REFERRING_PHYSICIAN: "Doctor",
      CONTRACTOR: "Other provider",
    },
    NO_REFERRING_PHY_NIHII:
      "The patient's attending physician does not have an inami number.",
  },
  "templating-view": {
    TERMINATE: "Close",
    TERMINATE_SEND: "Validate and send from eHealth",
    TERMINATE_PRINT: "Print",
    SEND_TO_HEALTHBOX_PDF: "Send from eHealthBox (PDF)",
    SEND_TO_HEALTHBOX_H1: "Send from eHealthBox (H1)",
    SEND_TO_HEALTHBOX_MEDIDOC: "Send from eHealthBox (Medidoc)",
    VALIDATE: "Send",
  },
  "templating-tree-view": {
    child: {
      referring: {
        label: "Recipient of the mail",
        child: {
          lastName: {
            label: "Name of the HCP",
          },
          firstName: {
            label: "First name",
          },
          adresse: {
            label: "Address",
          },
          town: {
            label: "City",
          },
          townCode: {
            label: "Code postal",
          },
        },
      },
      doctor: {
        label: "Doctor",
        child: {
          lastName: {
            label: "Name",
          },
          firstName: {
            label: "Surname",
          },
          adresse: {
            label: "Address",
          },
          houseNumber: {
            label: "House number",
          },
          town: {
            label: "City",
          },
          townCode: {
            label: "Code Postal",
          },
          tel: {
            label: "Phone",
          },
          gsm: {
            label: "GSM",
          },
          mail: {
            label: "eMail",
          },
          inami: {
            label: "Inami",
          },
          bank: {
            label: "Bank",
          },
        },
      },
      patient: {
        label: "Patient",
        child: {
          lastName: {
            label: "Surname",
          },
          firstName: {
            label: "Name",
          },
          gender: {
            label: "Gender",
          },
          dateOfBirth: {
            label: "Date of birth",
          },
          dateOfDeath: {
            label: "Date of death",
          },
          placeOfBirth: {
            label: "Place of birth",
          },
          placeOfDeath: {
            label: "Place of death",
          },
          adresse: {
            label: "Address",
          },
          houseNumber: {
            label: "House number",
          },
          town: {
            label: "City",
          },
          townCode: {
            label: "Code postal",
          },
          tel: {
            label: "Phone",
          },
          gsm: {
            label: "GSM",
          },
          mail: {
            label: "eMail",
          },
          ssin: {
            label: "N° of national register",
          },
          mutualityNum: {
            label: "Number of mutual",
          },
          titularCode1: {
            label: "Code holder mutual 1",
          },
          titularCode2: {
            label: "Code holder mutual 2",
          },
          titularyId: {
            label: "Registration number to the mutual",
          },
        },
      },
      soaps: {
        label: "Contact",
        child: {
          contactDate: {
            label: "Date of Contact",
          },
          motifs: {
            label: "Reasons",
          },
          subjectif: {
            label: "Topic",
          },
          objectif: {
            label: "Objective",
          },
          diagnostic: {
            label: "Diagnostic",
          },
          prescriptions: {
            label: "Plan of action (free text)",
          },
          note: {
            label: "Note",
          },
          itt: {
            label: "Itt",
            child: {
              dixit: {
                label: "He said",
              },
              startDate: {
                label: "Start Date",
              },
              endDate: {
                label: "End Date",
              },
              extension: {
                label: "Prologation",
              },
              resumption: {
                label: "Date of Recovery",
              },
              reason: {
                label: "Motif",
              },
              value: {
                label: "Inability",
              },
              outing: {
                label: "Output",
              },
            },
          },
          kine: {
            label: "Physio",
            child: {
              seanceNumber: {
                label: "Nbe sessions",
              },
              numberByWeek: {
                label: "How many times a week",
              },
              diagnosis: {
                label: "Diagonstic",
              },
              note: {
                label: "Complementary requests",
              },
            },
          },
          rx: {
            label: "Rx",
            child: {
              radiography: {
                label: "Reviews of RX",
              },
              clinicalInfo: {
                label: "Clinical information relevant to RX",
              },
              otherInfo: {
                label: "Additional information relevant",
              },
              otherOtherInfo: {
                label: "Additional information",
              },
              previousExams: {
                label: "Review précedants",
              },
              diagnosis: {
                label: "Diagnostic de RX",
              },
              date: {
                label: "Date de RX",
              },
            },
          },
          infi: {
            label: "Infi",
            child: {
              seanceNumber: {
                label: "Nbe sessions",
              },
              frequency: {
                label: "Frequency",
              },
              treatment: {
                label: "Type of care",
              },
            },
          },
          medication: {
            label: "Medication",
            child: {
              treatment: {
                label: "Dosage",
              },
            },
          },
          biometrie: {
            label: "Biometrie",
            child: {
              weight: {
                label: "Weight",
              },
              height: {
                label: "Size",
              },
              sbp: {
                label: "Systole",
              },
              dbp: {
                label: "Siastole",
              },
              heartrate: {
                label: "Pulse",
              },
              bmi: {
                label: "IMC",
              },
              hipcircumference: {
                label: "Circumference abdominal",
              },
              headcircumference: {
                label: "Circumference craniale",
              },
              glycemia: {
                label: "Blood glucose",
              },
              temperature: {
                label: "Temperature",
              },
              saturationO2: {
                label: "Saturation O2",
              },
              inr: {
                label: "INR",
              },
            },
          },
        },
      },
      summary: {
        label: "Data summary",
        child: {
          problems: {
            label: "Problems",
          },
          administrativeNote: {
            label: "Note administrative",
          },
          medicalBackground: {
            label: "Medical history",
          },
          surgicalHistory: {
            label: "Past surgical history",
          },
          familyHistory: {
            label: "Family history",
          },
          socialRiskFactors: {
            label: "Factors of social risk",
          },
          medicalRiskfactor: {
            label: "Risk factor medical",
          },
          allergy: {
            label: "Allergy",
          },
          intolerances: {
            label: "Intolerances",
          },
          vaccines: {
            label: "Vaccines",
          },
          preventions: {
            label: "Preventions",
          },
          chronicTreatment: {
            label: "Chronic treatment",
          },
          gs: {
            label: "Blood group",
          },
          rh: {
            label: "Rhesus",
          },
          sgs: {
            label: "Under blood group",
          },
          antec: {
            label: "Medical Note : history",
          },
        },
      },
      info: {
        label: "Information",
        child: {
          date: {
            label: "Date",
          },
          hours: {
            label: "Time",
          },
        },
      },
    },
    menu: {
      ADD: "Add",
    },
  },
  "rlm-templating-tree-view": {
    child: {
      administrative: {
        label: "Administrative data",
        child: {
          patientManager: {
            label: "Manager of the journey of care",
          },
          rlmsecretary: {
            label: "Secretary RLM",
          },
          activecarepath: {
            label: "Route of care for active",
          },
          diabetesconvention: {
            label: "Convention diabete",
          },
          typeofpathology: {
            label: "Type of pathology",
          },
          startdate: {
            label: "Start Date",
          },
          enddate: {
            label: "End Date",
          },
          endreason: {
            label: "Reason",
          },
          patientsigndate: {
            label: "Signature patient",
          },
          generalphysiciansigndate: {
            label: "Signature general practitioner",
          },
          specialistphysiciansigndate: {
            label: "Signature medical specialist",
          },
          mutualitysigndate: {
            label: "Signature mutual",
          },
          note: {
            label: "Note administrative",
          },
        },
      },
      healthcareparties: {
        label: "Stakeholders",
        child: {
          healthcareparties: {
            label: "Stakeholders",
          },
        },
      },
      treatments: {
        label: "Treatments",
        child: {
          inclusion: {
            label: "A l'inclusion",
          },
          current: {
            label: "Currently",
          },
        },
      },
      objective: {
        label: "Objective",
        child: {
          objective: {
            label: "Objective",
          },
        },
      },
      prescriptions: {
        label: "Prescriptions",
        child: {
          prescriptions: {
            label: "Prescriptions",
          },
        },
      },
    },
  },
  "patientMail-templating-tree-view": {
    child: {
      patient: {
        label: "Patient",
        child: {
          lastName: {
            label: "Surname",
          },
          firstName: {
            label: "Name",
          },
          adresse: {
            label: "Address",
          },
          houseNumber: {
            label: "Housenumber",
          },
          town: {
            label: "Town",
          },
          townCode: {
            label: "Postal Code",
          },
          age: {
            label: "Age",
          },
        },
      },
      doctor: {
        label: "Doctor",
        child: {
          lastName: {
            label: "Surname",
          },
          firstName: {
            label: "Name",
          },
          adresse: {
            label: "Address",
          },
          houseNumber: {
            label: "House Number",
          },
          town: {
            label: "Town",
          },
          townCode: {
            label: "Postal Code",
          },
          tel: {
            label: "Telephone Number",
          },
          gsm: {
            label: "Mobile Number",
          },
          mail: {
            label: "Email",
          },
          inami: {
            label: "Inami",
          },
          bank: {
            label: "Account Number",
          },
        },
      },
      info: {
        label: "Info",
        child: {
          date: {
            label: "Date",
          },
          hours: {
            label: "Hours",
          },
        },
      },
    },
  },
  "hcp-signature": {
    DOCTOR: "Doctor",
    DOCTOR_ABBR: "Dr.",
    PHONE_ABBR: "Tel.",
    NIHII: "Inami",
    SIGNATURE: "Signature",
    DATE: "Date",
    CBE: "N° BCE",
  },
  "templating-generator": {
    CLOSE: "Close",
    PREVIEW: "Preview",
    EXPORT: "Export",
    SAVE: "Save",
    UPDATE: "Edit",
    BACK: "Return",
    CANCEL: "Cancel",
    EDIT: "Edit",
    REMOVE: "Delete",
    SHOW: "See",
    form: {
      TITLE: "Title",
      GROUP: "Type",
    },
  },
  "templating-management": {
    FIND_BY_NAME: "Find by name",
    TITLE: "Documents type",
    NAME: "Name",
    USER: "User",
    TYPE: "Type",
    GROUP: "Group",
    ADD: "Add a document template",
    IMPORT: "Import documents type",
    EXPORT_ALL: "Export all documents",
    actions: {
      ACTIONS: "Actions",
    },
  },
  "efac-management": {
    ASSISTANTS: "assistants",
    EFAC_TO_BE_SENT: "Invoices to send",
    EFAC_TO_BE_CORRECTED: "Invoices to correct",
    EFAC_SENT: "Lots of bills",
    SELECT_ALL: "Select all",
    UNSELECT_ALL: "All deselect",
    EFAC_LIST: "List of invoices eFact",
    REFRESH: "Update",
    BANK_INFO_MANDATORY: "This is necessary to send batches eFacts",
    SEND_TO_EFACT: "Send to eFact",
    CORRECT_EFACT: "Correct invoices",
    MAINTENANCE: "eFact is temporarily under maintenance",
    INVOICE_DATE: "Billing Date",
    INVOICE_NUMBER: "N° of invoice",
    VIEW_HISTORY: "View the history eFact",
    PATIENT: "Patient",
    MUTUALITY: "Mutual",
    ACCEPTED_AMOUNT: "Amount accepted",
    PAID_AMOUNT: "Amount paid",
    AMOUNT: "Amount",
    TO_BE_SENT: "To send",
    TO_BE_CORRECTED: "To correct",
    TREATED: "Processed",
    ARCHIVED: "Archived",
    ARCHIVED_CODE: "Archived",
    PENDING: "In treatment",
    STATUS: "Status",
    ACCEPTED: "Accepted",
    CANCELED: "Refused",
    LOST: "Lost",
    DATE: "Date",
    CODE: "Code",
    EDIT: "Edit",
    ERRORS: "Errors",
    IO_FEDERATION_CODE: "Number of OA",
    ASSISTANTS_BANK_ACCOUNT: "(assistants bank account)",
    MY_BANK_ACCOUNT: "(my bank account)",
    PMG_BANK_ACCOUNT: "(bank account of the guard post)",
    EXTERNAL_REF: "Item number",
    INVOICE_MONTH: "Billing month",
    NUMERICAL_REF: "Shipment reference",
    EDIT_INVOICING: "Edit the invoice",
    VIEW_INVOICING: "See the invoice",
    REJECTED_PRESTATIONS: "Benefits rejected",
    MISSING_PATIENT: "Impossible de charger le patient",
    ASSISTANT: "Assistant",
    "ASSISTANT-TITLE": "Bill performed by one of your assistants",
    CANNOT_LOAD_EFACT_HCP: "Unable to load invoices related to your caseworker",
    "efac-sent": {
      TITLE: "List of items eFact",
      REFRESH: "Update",
      FETCH_MESSAGES: "Retrieve my messages",
      FILTER: "Filter",
      OA: "OA",
      CONTRACTOR: "Provider",
      REFERENCE: "Reference",
      SENDER_NUMBER: "N° Send",
      INVOICE_MONTH: "Billing month",
      SENT: "Date d'envoi",
      TOTAL_AMOUNT: "Amount",
      STATUS: "Status",
      ACCEPTED_AMOUNT: "Amount accepted",
      REJECTED_AMOUNT: "Declined amount",
      REASON_FOR_REJECTION: "Reason for rejection",
      PAYMENT_REF: "Ref. payment",
      PAYED: "Paid",
      IMPOSSIBLE_TO_RELOAD_BATCH: "Unable to load the invoices sent.",
      HELPDESK_INFO: "Helpdesk info",
      EFACT_MESSAGES_FETCHED_LESS_THAN_ONE_HOUR_AGO:
        "You have already retrieved your messages. Please try again, {{time}}",
      NO_MESSAGES_FOUND:
        "No message has been retrieved. Please try again later.",
    },
  },
  "efact-reset-confirm": {
    WARNING: "Attention !",
    WARNING_TEXT:
      "Reset a message eFact is an irreversible process, which can cause duplicate shipments.",
    RESET_MESSAGE: "Reset the invoices for mailing",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
  },
  "helpdesk-info": {
    HCP_NAME: "Name of the third party invoicing",
    HCP_NIHII: "RIZIV number of third-party billing",
    HCP_PHONE_NUMBER: "Telephone number of the third party invoicing",
    MONTH_AND_YEAR_INVOICING: "The month and year of billing",
    SENT_NUMBER: "Shipment number",
    SENT_REF: "Shipment reference",
    SENT_DATE: "Date d'envoi",
    LAST_RESPONSE_TYPE: "Type of the last response received",
    LAST_RESPONSE_DATE: "Date of the last response received",
    TOTAL_INVOICE_AMOUNT: "Total amount charged",
    SOFTWARE_NAME: "The name of the software",
    TECHNICAL_SHEETS: "Technical files",
    DOWNLOAD: "Download",
    RESET_MESSAGE: "Reset the invoices for mailing",
    CHANGE_STATUS_TITLE:
      "Force the change of status for a shipment with no response",
    MANUAL_STATUS_CHANGE: "Operation manual",
    MANUAL_STATUS_CHANGE_OPERATOR: "Operator who carried out the operation",
    MANUAL_STATUS_CHANGE_DATE: "Date of manual operation",
    MANUAL_STATUS_CHANGE_BUTTON: "Changer le status manuellement",
  },
  "efact-change-status-modal": {
    WRONG_PASSWORD: "Wrong password",
    MESSAGE_NOT_FOUND: "Could not find the corresponding message.",
    NO_INVOICE_IN_MESSAGE: "The batch has no bill associated",
    MISSING_INVOICES:
      "Impossible to find all the invoices associated with the batch",
    UNABLE_TO_UPDATE_INVOICE: "Unable to update the invoice",
    MANUAL_REJECT_INVOICE: "Release manual of the invoice",
    UNKNOWN_STATUS_UPDATE: "Your choice for the change of status is unknown.",
    SUCCESSFUL_BATCH_STATUS_UPDATE:
      "Updating the status of the batch successfully",
    OPERATOR_MODIFICATION_ONLY:
      "This operation is reserved to the operators of the support Medispring when the response of the insurer has been lost.",
    OPERATOR_NAME: "The name of the operator",
    OPERATOR_PASSWORD: "Password",
    VALIDATE_PASSWORD_BUTTON: "Validate the password",
    SELECT_STATUS_CHANGE_CHOICE: "Select the action to perform :",
    STATUS_CHANGE_CHOICE_FULL_SUCCESS: "Put in complete success",
    STATUS_CHANGE_CHOICE_FULL_REJECT: "Put in complete rejection",
    ACKNOWLEDGE_EFACT_CHANGE_ACTION:
      "I am aware of the change of status that I'm going to perform and the impossibility of going back.",
    FORCE_CORRECTION_OVERWRITE:
      "(Strongly not recommended) to Force the creation of NEW invoices correction if lot already rejected",
    VALIDATE_STATUS_CHANGE: "Make the change",
    INVOICE_ALREADY_CORRECTED_IDS: "IDs invoices already corrected",
    INVOICE_CORRECTED_IDS: "IDs invoices corrected",
    INVOICE_FAILED_UPDATE_IDS:
      "IDs invoices in failure (you must réeffectuer the operation)",
    SOME_INVOICES_FAILED_WARNING:
      "The correction of certain invoices and has failed",
  },
  "efac-settings": {
    PARAM_EFAC: "Settings eFact",
    VALIDATE: "Validate",
    NEXT_SENDING_NUMBERS: "Next sending numbers eFact",
    ALERT_EDIT_NUMBERS:
      "Attention : change the numbers to send eFact can create unexpected behaviors. Do not change these if you use other software medical to send bills eFact.",
    MUT_NAME: "Mutual",
    ERROR_CANT_FETCH: "Unable to retrieve the last value used by Medispring.",
    VALUE: "The value",
    ERROR_TOO_SMALL: "is too small.",
    ERROR_CANT_UPDATE: "Cannot update",
    UPDATE: "Update",
  },
  "efac-history": {
    TITLE: "Historical eFacts",
    DATE: "Date",
    INVOICE_NUMBER: "N° of invoice",
    STATUS: "Status",
    LOT_NUMBER: "N° du lot eFact",
    LOADING: "Loading",
  },
  "patient-chapteriv": {
    REFUND_REQUEST: "Refund request : ",
    COMPLETE: "Complete application",
    SEND: "Send",
    OPENING_DATE: "Start Date",
    CLOSING_DATE: "End Date",
    MEDICATIONS: "Medicament(s)",
    PARAGRAPH: "Paragraph",
    QUANTITY: "Quantity",
    EHEALTH_CONNECTION_KO:
      "Log in to eHealth for viewing and creating applications chapter IV.",
    AGREEMENT_CONSULTATION_ERROR:
      "Impossible to consult your requests chapter IV.",
    CURRENT_STATE: "Your agreements",
    NO_ACCORD: "No agreement found",
    IN_TREATMENT: "In treatment",
    ACCEPTED: "Accepted",
    REFUSED: "Refused",
    CANCELED: "Cancelled",
    CONSULT: "Consult",
    MODEL: "Model (Version)",
    STATUS: "Status",
    TRANSACTION_DATE: "Date de transaction",
    QUANTITIES: "Quantities",
    COVERTURE: "Coverage",
    DECISION: "Decision",
    REFERENCE: "Reference",
    VERSES: "Verses",
    REFUSAL_JUSTIFICATION: "Justification of refusal",
    APPENDICES: "Schedule(s)",
    PARAGRAPH_NOT_EXIST:
      "This paragraph no longer seems to exist in the current version of the database, SAM.",
    VERSE_VERSESEQ: "Verset (verseSeq)",
    APPENCIDE_NEEDED_NEWREQUEST:
      "An appendix is necessary for this new application",
    APPENCIDE_NEEDED_PROLONGATION:
      "An appendix is necessary for this extension",
    APPENCIDE_NEEDED_VERSE: "An appendix is necessary for this verse",
    UNCONTINIOUS_ALERT:
      "By selecting a date greater than the end date of the current agreement + 1 day, the only possible extension would be discontinuous. Please confirm the type of extension desired",
    UNCONTINUOUS: "Discontinue",
    CONTINUE: "Continue",
    CANCEL: "Cancel",
    ONE_APPENCIDE_NEEDED_PROLONGATION:
      "appendix is necessary for the extension request",
    ONE_APPENCIDE_NEEDED_NEWREQUEST:
      "appendix is necessary in order to make the new application",
    X_APPENCIDE_NEEDED_PROLONGATION:
      "annexes are required to request the extension",
    X_APPENCIDE_NEEDED_NEWREQUEST:
      "annexes are required to make new application",
    NEWREQUEST: "Request",
    PROLONGATION: "Prolongation",
    ADD_APPENDICES: "Adding a schedule(s)",
    CLOSURE: "Cloture",
    CANCELLATION: "Cancellation",
    SEND_NEWREQUEST: "Send the request",
    SEND_CLOSURE: "Terminate the agreement",
    SEND_EXTENSION: "Extend the agreement",
    SEND_APPENCIDE: "Send",
    SEND_CANCEL: "Cancel the agreement",
    UNEXPECTED_ERROR: "An error occurred",
    NO_TRANSACTION: "An error occurred : no transaction received.",
    SAVE_APPENDICES_ERROR:
      "These annexes have not been able to be saved locally. However, they have been sent successfully but you do not know the view in your agreement in the above courses.",
    ERROR_GET_NOT_SELECTED: "No agreement is selected",
    ERROR_GET_P: "Unable to retrieve paragraphs.",
    ERROR_GET_V: "Unable to retrieve verses.",
    APPENDICE_NOT_USEFUL:
      "If this is not necessary, do not send appendices, in order not to slow down the application process.",
    CLOSE_OK: "Agreement closed with success",
    MAX_SIZE_REACHED:
      "The files you have selected are too large. The maximum size of all files is {{ maxTotalFileSize }} KB gold, your files weigh {{ totalFileSize }} KB.",
    YOU_CAN_0: "You can :",
    YOU_CAN_1:
      "- Perform plueieurs addition of appendices independent to send the files one by one.",
    YOU_CAN_2: "- Reduce the size of your files.",
    MIME_TYPE_ERROR_0: "You have selected files of type {{ mimeTypeErrors }}.",
    MIME_TYPE_ERROR_1:
      "We cannot guarantee that these files will be sent correctly.",
    MIME_TYPE_ERROR_2:
      "We advise you to send only files of type pdf, image (.png, .jpg, ...) and text (.txt).",
    SUPPLEMENTARY_INFO: "Additional information",
    VERSE_TYPE: "Type of verse",
    "request-type": {
      "0": "$ERROR_",
      P: "Prolongation",
      N: "New application",
    },
    ERROR_1:
      "You have checked the verses of the new application and extension at the same time.",
    ERROR_2:
      "You have selected a verse of extension then that you made a new application.",
    ERROR_3:
      "You have selected a verse of the new application so that you made a request for an extension.",
    EXT_WARN_1: "The start date of an extension may not be in the past.",
    EXT_WARN_2:
      "You can't extend an agreement that during the last 3 months of this agreement.",
  },
  "patient-erecipe": {
    E_RECIPE_ID: "Recip-e ID",
    ERR_GET_OPEN_PRESC:
      "The list of requirements that are open on Recip-e could not be obtained.",
    PRESC_NOT_FOUND: "No prescription electronic found",
    TREATMENTS: "Treatments",
    POSOLOGY: "Dosage",
    START_DATE: "Beginning",
    END_DATE: "Fin",
    PRESCRIBER: "Prescriber",
    HCP_NO_PHONE:
      "The commenter prescriber has no phone number (mobile or fixed).",
    NOT_CONNECTED_CANCEL: "Log in to eHealth to cancel your orders Recip-e",
    CANCEL_OK: "Cancellation Message correctly sent to Recip-e",
    ERROR_CANCEL:
      "An error occurred while cancelling your order. This can happen if your order has already been cancelled",
    ERROR_CANCEL_SAVE: "An error occurred while registering the cancellation",
    CANCEL_SUCCESS: "Cancellation is sent to Recip-e",
    CANCEL_BTN: "Cancel the prescription, Recip-e",
    ACTIVE_INGREDIENT: "Active ingredient",
    PRESCRIBED_DATE: "Prescribed by the",
    PRESCRIBE_NEW: "Prescribing of new",
    NOT_SENT: "Order not sent to Recip-e",
  },
  "patient-contact-document-selector": {
    APPENDICE_COMPUTER: "To attach a file present on your computer",
    CHOOSE_DOCUMENT: "Choose files",
    SELECTED_FILES: "Selected files",
    DELETE_SELECTED_FILES: "Delete",
    DOCUMENT_SEQ: "Document seq",
    VERSE_SEQ: "Verse seq",
    FILE_TOO_BIG: "The file is too large. The maximum size is 1000 KB.",
    ERROR_REPORT_GET_FILE:
      "Some document could not be obtained from a contact report.",
    APPENDICES_AVAILABLE_FOR_DL:
      "Appendices-available to download from the website of the NIHDI",
    ONCE_COMPLETED: "Once completed, don't forget to mention the values",
    AND: "and",
    OF_EACH_APPENDICE:
      "each annex added to your application, or adding appendix supplementary.",
    DESCRIPTION: "Description",
    DOWNLOAD: "Download",
  },
  "patient-medication-archive": {
    ALLERGY: "Allergy",
    INTOLERANCE: "Intolerance",
    PATIENT_REQUEST: "Request of the patient",
    OTHER: "Other",
    NOTE: "Note",
    CANCEL: "Cancel",
    ARCHIVE: "Archiver",
  },
  "contactreport-subcontact": {
    PREVIEW_OF: "Overview of",
    PRINT_FULL_REPORT: "Print the full report",
    CONSLUSION: "Conclusion",
    ERROR_UPLOAD: "The document could not be sent to the server.",
    ERROR_SAVE_CONTACT: "Error when saving the report.",
    ERROR_GET: "A document could not be loaded.",
    EXT_NOT_AUTHORIZED:
      "The files {{ext}} are not allowed. Files allowed : {{autorized}}",
    FILE_TOO_BIG:
      "The file {{filename}} is too large. The maximum size allowed is {{size}}",
    ERR_FILE_READ: "Unable to read file",
    FIND_PROCEDURE: "Find a procedure",
    ATTACHMENT: "Attachment",
    PROCEDURE: "Procedure",
    SPECIALITY: "Specialty",
    CHOOSE: "Choose",
    PRINT: "Print",
    DOWNLOAD: "Download",
    DELETE: "Delete",
    TEXT: "Text",
    LINKS: "Links",
    LINK: "Link",
    "new-message": {
      TITLE: "Envoi du rapport via eHealthBox",
    },
    REPORT_S: "Report ",
    DE_: "of ",
    DU_: "the ",
    APPENDICES: "Annexes",
    APPENDICE: "Appendix",
    DOCS_AS_APPENDICE: " documents in the appendix",
    DOC_AS_APPENDICE: " attached document",
    SAVE_REPORT: "Save",
    REPORT_UPDATED_SUCCESS: "Updated report.",
    OPEN_PATIENT: "Open the patient file",
  },
  "text-templating": {
    enums: {
      template_group_type: {
        SOAPS: "soaps",
      },
    },
  },
  "messages-box-manager": {
    NO_ACCESS_TO_ALL_MAIL_FEATURES_IF_NOT_LOGGED_TO_EHEALTH:
      "You do not have access to all the features of the mail module without being connected to services such as eHealth.",
  },
  "messages-box-controls": {
    MANUAL_IMPORT: "Import manuel",
    AUTO_IMPORT: "Automatic Import",
    UPDATE: "Update",
    MARK_READ: "Mark read",
    MARK_UNREAD: "Mark unread",
    CONFIRM_DIALOG: {
      TITLE: "Confirm Deletion",
      MESSAGE: "Do you want to delete this message(s)?",
      YES: "Yes",
      NO: "No",
    },
    DELETE: "Delete",
  },
  "messages-box-filter-selector": {
    UNTREATED: "Unprocessed Messages",
    TREATED: "Messages processed",
    SELECT_ALL: "Select all",
    STATUS: "Status",
    RECIPIENT: "Destinataire",
    OTHER: "Other",
  },
  "messages-box-selector": {
    TITLE: "Boite mail eHealth",
    NEW_MESSAGE: "New message",
    INBOX: "Inbox",
    INBOX_ALL: "All",
    INBOX_USER_ACTION: "Non-treaty",
    OUTBOX: "Messages sent",
    ARCHIVE: "Archives",
    MANUAL_IMPORT: "Import manuel d'un fichier",
  },
  "message-messages-box": {
    NEW_MESSAGE: "New Message",
    SEARCH_INPUT:
      "Research: patient name, date of birth, address, transmitter, specialty, procedure",
    CAPACITY: "Space occupied",
    MESSAGE_QUEUE: "Messages in the queue :",
    FULL_BOX_ERROR:
      "Your inbox is full, delete some messages to continue to receive",
    FULL_BOX_WARNING:
      "Your inbox is soon filled, clear messages in order to avoid a saturation of your box",
    EMPTY_TRASH: "Empty the recycle bin on the eHealthBox",
    ERROR_DELETE_CHUNK: "An error occurred while deleting your messages",
    N_DELETED_MESSAGE: "{{n}} messages have been deleted",
  },
  "editor-message-messages-box": {
    report: "Custody",
    label: {
      TO: "To",
      TO_PLACEHOLDER: "Search people and organisations in your HCP list.",
      SUBJECT: "Object",
      SET_AS_IMPORTANT: "Mark as important",
      ENCRYPTED: "Encrypted",
      METADATA: "Metadata",
      ADD_METADATA: "Add another pair of metadata.",
      CUSTOM_METADATA: "Add custom metadata.",
      ADD_RECIPIENT: "Add a recipient.",
    },
    confirmationType: {
      SEND: "Sending",
      RECEIVE: "Reception",
      READ: "Lecture",
    },
    button: {
      SEND: "Send",
    },
    NO_HCP: "No corresponding HCP was found for the NIHII.",
  },
  "message-list": {
    NO_DATE: "No date",
    MESSAGE_WARNING: "This message requires your attention",
    MESSAGE_ERROR: "Error during automatic processing",
    MESSAGE_SUCCESS: "Automatic processing completed successfully",
    FROM_DISK: "Imported from the disk",
    HAS_ANNEX: "Contains one or several annexes",
    MORE_THAN_ONE_PATIENT: "Several patients",
    NO_PATIENT: "No patient",
    SOURCE_UNKNOWN: "Issuer unknown",
  },
  "patient-medical-note": {
    TITLE: "Medical Note",
    tab: {
      WILL: "Wills",
      BLOOD_TYPE: "Blood group",
      INACTIVE: "History",
    },
    SAVE: "Save",
    CANCEL: "Cancel",
    CLOSE: "Close",
    EDIT: "Change the wishes of the patient",
    LOADING: "Please wait while loading contacts",
  },
  "patient-administrative-note": {
    TITLE: "Note administrative",
    OPEN_PATIENT_NOTE: "Display at the opening of the folder",
    PLACEHOLDER: "Add a note",
    SAVE: "Register",
    CANCEL: "Cancel",
    NOTE_SAVED: "Administrative Note recorded",
    NOTE_SAVE_ERROR: "Administrative note could not be saved",
  },
  "patient-will": {
    DATE: "Date",
    NOTE: "Note",
    EMPTY: "No will has yet been enregitrée for this patient",
    HISTORY: "View the history of this will",
    NO_HISTORY: "No history to display",
    bloodtransfusion: {
      LABEL: "Blood Transfusion",
      AUTHORIZE: "Accept the transfusion",
      REFUSE: "Does not accept the transfusion",
      UNDEFINED: "?",
    },
    intubation: {
      LABEL: "Intubation",
      AUTHORIZE: "Agrees intubation",
      REFUSE: "Does not accept intubation",
      UNDEFINED: "?",
    },
    reanimation: {
      LABEL: "Resuscitation",
      AUTHORIZE: "Accepts the resuscitation",
      REFUSE: "Does not accept resuscitation",
      UNDEFINED: "?",
    },
    organdonation: {
      LABEL: "Don d'organe",
      AUTHORIZE: "Accepts organ donation",
      REFUSE: "Does not accept the donation",
      UNDEFINED: "?",
    },
    vaccination: {
      LABEL: "Vaccination",
      AUTHORIZE: "Accept the vaccination",
      REFUSE: "Does not accept the vaccination",
      UNDEFINED: "?",
    },
    euthanasia: {
      LABEL: "Euthanasia",
      AUTHORIZE: "Accepts euthanasia",
      REFUSE: "N'a pas fait de demande d'euthanasie",
      UNDEFINED: "?",
    },
    datareuseforclinicalresearch: {
      LABEL: "Clinical research",
      AUTHORIZE: "Accepts the use of the data for clinical research",
      REFUSE: "Does not agree",
      UNDEFINED: "?",
    },
    datareuseforclinicaltrials: {
      LABEL: "Data usage for clinical trials",
      AUTHORIZE: "Accepts data usage for clinical trials",
      REFUSE: "Does not agree",
      UNDEFINED: "?",
    },
    clinicaltrialparticipation: {
      LABEL: "Participation in clinical trials",
      AUTHORIZE: "Accepts the participation in clinical trials",
      REFUSE: "Does not accept the participation",
      UNDEFINED: "?",
    },
    hospitalisation: {
      LABEL: "Hospitalisation",
    },
    resuscitation: {
      LABEL: "Resuscitation",
    },
    "MS-FIXME-note": {
      LABEL: "Note",
    },
  },
  "patient-inactive": {
    CONTENT: "History",
  },
  "patient-blood-type": {
    BLOOD_TYPE: "Blood group",
    SUB_BLOOD_TYPE: "Under blood group",
    HISTORY: "View the history of the blood group",
    NO_HISTORY: "No history to display",
  },
  "patients-state": {
    FETCH_FAILED: "The reception of the patient {{id}} ' failed.",
  },
  "patient-journal-service": {
    CONTACT_SUBMISSION_FAILED: "The submission of the contact has failed",
    ATTEMPTED_ICURE_TOPAZ_CONTACT_SUBMISSION:
      "Data created with iCure or Topaz cannot be modified by Medispring at this time.",
  },
  "file-viewer": {
    DISPLAY_IMPOSSIBLE: "This file type can not be displayed",
    DOWNLOAD: "Download the attachment",
  },
  "message-display": {
    DELETED_LAB: "This lab has been removed",
    DELETED_REPPORT: "This report has been deleted",
    SEARCH_PATIENT: "Search for the patient",
    EDIT_FROMHCP: "Edit the sender of a message",
    EDIT_TOHCP: "Edit the recipient of the message",
    RETRY_PROCESSING: "Try again",
    TRY_PROCESSING: "Process the message",
    WAITING_MESSAGE:
      "This message has not yet been processed. Information may be missing.",
    PROCESSING_FAILED: "The message could not be processed automatically.",
    PREVIEW_NOT_AVAILABLE: "Overview not available.",
    CANNOT_HANDLE_FILE: "The file is not interpretable.",
    CANNOT_UPDATE_MESSAGE: "Failure to update message.",
    CANNOT_UPDATE_INFO: "Failure to update the info.",
    HCP_EDITION_MODAL_TITLE: "Edition of the transmitter of the message",
    AUTHOR_UNKNOWN: "Issuer unknown",
    RECIPIENT_UNKNOWN: "Recipient unknown",
    FROM_PREFIX: "To : ",
    ERROR_RECIPIENT: "The determination of the recipient failed.",
    SUBJECT_PREFIX: "Topic : ",
    NO_PREVIEW_MESSAGE: "There are no messages to display.",
    SELECT_MESSAGE: "Please select a message.",
    data: {
      REPLY: "Reply",
      REPLY_ALL: "Respond to all",
      TRANSFER: "Transfer",
      ARCHIVE: "Archiver",
      DELETE: "Delete",
      MARK_READ: "Mark read",
      MARK_UNREAD: "Mark unread",
    },
    annex: {
      DOWNLOAD: "Download",
      PATIENT_LINK: "Associate to a patient",
      AUTHOR_LINK: "To associate with an author",
    },
    HCP_EDITION_MODAL_TITLE_SENDER: "Edition of the transmitter of the message",
    HCP_EDITION_MODAL_TITLE_RECEIVER: "Edition of the recipient of the message",
  },
  "message-display-body": {
    UNSUPPORTED_BODY_TYPE: "Message Format not supported: {{type}}",
    DOWNLOAD: "Download the body of the message",
  },
  "import-result-summary": {
    AUTHOR_UNKNOWN: "Source unknown",
  },
  "contact-task-form": {
    DESCRIPTION: "Description",
    DEADLINE: "Time",
    REQUESTER: "Asked by...",
    OWNER: "To be carried out by...",
    SEARCH_OWNER: "Search for a user",
    CREATE_TASK: "To add a task",
    REMOVE_TASK: "Delete the task",
    VALUE: "Value",
    TYPE: "Type",
  },
  "health-one-import": {
    success: {
      DOCUMENT_SUCCESSFULLY_IMPORTED:
        "The document has been successfully imported.",
      X_MESSAGE_PROCESSED: "{{value}} message traité.",
      X_MESSAGES_PROCESSED: "{{value}} messages traités.",
    },
    error: {
      ERROR_MORE_THAN_ONE_DOCUMENT_IN_MESSGAE:
        "The message has several documents.",
      ERROR_NO_DOCUMENT_IN_MESSGAE: "The message does not have a document.",
      ERROR_DOC_HAS_NO_TYPE: "Impossible to identify the type of the document.",
      ERROR_DOCUMENT_CANT_BE_LINKED_TO_PATIENT:
        "The document may be associated with a patient.",
      ERROR_WHILE_ADDING_CONTACT_TO_PATIENT:
        "Error adding the contact to the patient ({{lastName}} {{firstName}}{{dateOfBirth}}).",
      ERROR_GET_HCP: "Error during the retrieval of the speaker.",
      PATIENT_SSIN_AMBIGUOUS:
        "Several patients are associated to the n° SSIN recovered ({{'ssin}}).",
      PATIENT_NAME_BIRTH_DATE_NOT_FOUND:
        "No patient for the name and the birth date recovered ({{lastName}} {{firstName}} {{dateOfBirth}}).",
      PATIENT_NAME_BIRTH_DATE_AMBIGUOUS:
        "Multiple patients found for the name and the birth date recovered ({{lastName}} {{firstName}} {{dateOfBirth}}).",
      PATIENT_INFORMATION_MISSING:
        "No information on the patient in the document.",
      CONTACT_CREATION_FAILED:
        "Error during creation of the contact ({{lastName}} {{firstName}}{{dateOfBirth}}).",
      PARSING_FAILED:
        "Error while parsing the document ({{lastName}} {{firstName}}{{dateOfBirth}}).",
      NO_AUTHOR:
        "The message does not contain copyright. The laboratory/colleague would have you communicate this information. You also have the possibility to choose manually the author of this information.",
      UNKNOWN: "Unknown error.",
    },
  },
  "pricare-document-import": {
    MEDIDOC_FROM_PRICARE_WITHOUT_CONTENT:
      "Medidoc from Pricare without content",
    UNKNOWN_FILE_NAME_IN_PRICARE: "Unknown filename in Pricare",
  },
  "patient-tasks": {
    TITLE: "Tasks",
    PLACEHOLDER_NO_TASK: "There are no tasks to display.",
    CATEGORY: {
      CURRENT: "Tasks in progress",
      LATE: "Tasks exceeded",
      ENDED: "Task history",
    },
    EDIT: "Edit the task",
    TYPES: {
      DIAGNOSTIC_ACT: "Diagnostic act",
      THERAPEUTIC_ACT: "Therapeutic act",
      VACCINATIONS: "Vaccinations",
      ADMINISTRATIVE_TASK: "Administrative task",
      PREVENTION: "Primary prevention",
      IMAGERY: "Imagery",
      LAB_ANALYSIS: "Lab analysis",
      OPINION_REQUESTS: "Opinion requests",
      EMPTY: "Without type",
      ALL: "All types",
    },
    TABS: {
      OPENED: "Opened tasks",
      CLOSED: "Closed tasks",
    },
    "export-columns": {
      DATE: "Date",
      DESCRIPTION: "The description",
      DEADLINE: "Date limite",
      E_HEALTH: "eHealth",
      TYPE: "Type",
      STATUS: "Status",
      REQUESTER: "Applicant",
    },
  },
  "patient-free-task-dialog": {
    TITLE: "Task",
  },
  "patient-selection": {
    TITLE: "Selection of a patient",
    INFO_FROM_H1:
      "Information found in the file : {{lastName}} {{firstName}} {{dateOfBirth}}",
    CREATE_NEW_PATIENT: "Create a new patient",
    LAST_NAME: "Family name",
    FIRST_NAME: "First name",
    DATE_OF_BIRTH: "Date of birth",
    NO_PATIENT: "No patient",
    NEXT: "Following",
    PATIENT_LINK: "Associate the patient",
    CREATE_FROM_FILE_INFO: "Create from the info of the file",
  },
  "hcparty-selection-dialog": {
    TITLE: "Provider selection",
    FIND_PLACEHOLDER: "Find by name",
    CREATE_NEW: "Create a new provider",
    HCP: "Provider",
    ACCEPT: "Select",
  },
  "ms-mailing": {
    NO_ACCESS_TO_FEATURE_FOR_GUARDPOST:
      "You do not have access to this feature mode GUARDPOST.",
    NO_LOG_EHEALTH: "You are not connected to services such as eHealth.",
  },
  "messages-box-service": {
    NO_LOG_EHEALTH: "You are not connected to services such as eHealth.",
    SEND_SUCCESS: "The sending of the message has been made",
    SEND_FAILED: "The sending of the message failed ",
  },
  "date-period-selector": {
    FROM: "The",
    TO: "The",
    SELECT: "Select",
    PREVIOUS: "Previous",
    NEXT: "Following",
  },
  "task-update-popup": {
    TITLE: "Updating the task : {{label}}",
    NOTE: "Note",
    COMPLETION_DATE: "Date",
    BY_HCP: "By",
    RENEWAL_DATE: "Renewal",
    STATUS: {
      COMPLETED: "Made",
      COMPLETED_RENEW: "Made and to renew",
      CANCELLED: "Not made",
      REFUSED_BY_PATIENT: "Refused by the patient",
    },
    UPDATE_FAILED: "The update task has failed.",
    CANCEL: "Cancel",
    OK: "Validate",
  },
  "ms-html-dialog": {
    SAVE_AS_PDF: "Download PDF",
  },
  "ms-eh-dmg": {
    GET_PATIENT_LIST: "Refer to the lists of your patients",
    GET_DMG_MESSAGES: "Get my messages",
    REQUEST_DMG_LIST: "Ask for a list of my patients",
    REQUEST_DMG_LIST_DATE: "Date of query list",
    CONSULT_DMG_MESSAGES: "View my messages",
    CLEAR_DMG_MESSAGES: "Clear messages",
    CONFIRM: "Confirm",
    REQUESTS_LIST: "List of queries",
    PATIENTS_LIST: "List of patients",
    DATE: "Date",
    REQUEST_DATE: "Date of request",
  },
  "eh-e-dmg": {
    PATIENT_LIST: "List of my patients",
    MESSAGES: "Messages",
  },
  "measure-form": {
    VALUE: "Value",
  },
  "manual-h1-import": {
    HCP: "Speaker",
    PATIENT: "Patient",
    FILE: "File",
    DIALOG_TITLE: "Import manuel d'un fichier Health One",
    IMPORT: "Importer",
  },
  "dmg-patient-list": {
    OA: "OA",
    SSIN: "National number",
    LAST_NAME: "Name",
    FIRST_NAME: "First name",
    PAYMENT: "Payment",
    PAYMENT_DATE: "Date of payment",
    SUB_TOTAL: "Sous total",
    TOTAL: "Total",
    GROUP_DRAG: "Drag a column header and drop it here to group by that column",
    NO_LIST: "There is no list available for the moment",
    EXPORT: "Export Excel",
    EXPORT_FILE_NAME: "liste_de_patient_",
    MUT: "Mutualité",
    BIRTH: "Date of birth",
    PAYMENT_REFERENCE: "Reference",
    START: "Beginning",
    END: "Fin",
  },
  "ms-dmg-update-management": {
    ERROR_GET_GUID: "Impossible to get your lists of renewals and fences",
    NO_MESSAGE_GET: "No message has been retrieved. Please try again later",
    EXTENSION_AND_CLOSURE_LIST: "List of renewals and fences",
    closure: "Closing",
    extension: "Prolongation",
    TYPE: "Type",
    PATIENT: "Patient",
    DATE: "Date of the application",
    PREVIOUS_HCP: "Former owner of the DMG",
    END_OF_PREVIOUS_DMG: "End Date of the old DMG",
    NEW_HCP: "New owner of the DMG",
    BEGIN_OF_NEW_DMG: "Start Date of the new DMG",
  },
  "patient-vaccine": {
    TITLE: "Vaccines under",
    CANCEL: "Cancel",
    PRINT: "Print",
    SAVE: "Save",
    SAVE_REGISTER: "Save + Vaccinnet",
    SAVE_PRINT: "Save and print",
    REGISTRATION_ERROR: "Error: Vaccinnet",
    VACCINNET_NOT_ALLOWED:
      "Vaccinnet registration only allowed for COVID-19 vaccine registration.",
    prescription: {
      CURRENT_VACCINE: "Vaccine in progress",
      DOSE: "Dose",
      dose: {
        UNIQ: "Dose unique",
        FIRST: "1ère dose",
        SECOND: "2ème dose",
        THIRD: "3ème dose",
        FOURTH: "4ème dose",
        RECALL: "Booster Dose",
      },
      RECALL: "Reminder",
      age: {
        ADULT: "Adult",
        CHILD: "Child",
      },
      AGE: "Age",
      NOTE: "Note",
      AUTHOR: "Author",
      BATCH: "Batch",
      ORIGIN: "Origin",
      REGISTRATION: "registration",
    },
    code: {
      DIVERS: "Divers",
      ARCHIVE: "Archived vaccines",
      PNEUMONIA13: "13-valent polysaccharide vaccine against pneumonia",
      OTHER: "Supplementary vaccinations",
    },
    task: {
      TASKS: "Task list vaccines",
      RECALL: "Reminder for",
    },
    box: {
      VACCINE: "Vaccine",
    },
    history: {
      ADULT: "Adult",
      CHILD: "Child",
    },
    DELETE: "Do you want to delete this vaccine?",
  },
  "template-group-type": {
    SOAPS: "SOAPS",
    ADMINISTRATIFS: "Documents administratifs",
    COURRIER: "Email templates",
    "COURRIER PATIENT": "Patient Email templates",
    CONSEILS: "Advice to patients",
    RLM: "RLM",
  },
  download: {
    "word-download": "Download the word document",
    "no-info": "Download the attached file",
  },
  "patient-ritch-text-service": {
    CLOSE: "Close",
  },
  "patient-document-dialog": {
    FAILURE: "Error when saving",
  },
  "patient-document": {
    TITLE: "Documents",
    VALIDATE: "Validation",
    CANCEL: "Cancel",
    PRINT: "Print",
    SEND_TO_EHB: "Send by eHealthBox",
    SEND_TO_HUB: "Send on the HUB",
    SAVE: "Save",
    DOWNLOAD_FOR_PATIENT: "Download medispring scheme",
    EMAIL: "Send via email",
    SUBJECT: "",
    BODY: "",
    SELECT_HCP: "Please, select a recipient:",
    SELECTION: {
      NONE: "None",
      ATTENDING_PHYSICIAN: "Attending practicioner",
      OTHER_HCP: "Other recipient",
      PATIENT: "Patient",
    },
    NEXT: "Validate",
    BACK: "Back",
    UNKNOWN_PAGE: "Error! Unknown dialog page!",
    PLEASE_WAIT: "Templates are loading, please wait ...",
    NO_ATTENDING_PHYSICIAN: "This patient has no attending physician!",
  },
  boolean: {
    TRUE: "Yes",
    FALSE: "No",
  },
  "maintenance-module": {
    SHOULD_FIRST_START_CONFLICT_RESOLUTION:
      "Too many conflicts. Please, first run the maintenance resolving database conflicts.",
    MAINTENANCES: "Maintenances",
    MAINTENANCE_NAME: "Name",
    MAINTENANCE_START_DATE: "Start Date",
    MAINTENANCE_END_DATE: "End Date",
    MAINTENANCE_LOG_DETAILS: "Additional information",
    MAINTENANCE_NEED_PASSWORD:
      "This maintenance requires a password. Please contact support to obtain this password.",
    PASSWORD: "Password",
    INCORRECT_PASSWORD: "Incorrect password",
    STOP_MAINTENANCE: "Stop the maintenance",
    VALIDATE: "Validate",
    YOU: "You",
    WAITING_FOR_STABILISATION:
      "Waiting for the stabilization of your database...",
    STOP_AND_QUIT: "Stop the service and continue to Medispring",
    MAINTENANCE_AVAILABLE: "Available",
    MAINTENANCE_STOPPED: "Stopped",
    MAINTENANCE_FAILED: "Failed",
    RUN_MAINTENANCE: "Apply the maintenance",
    RUN_SELECTED_MAINTENANCES: "Run the maintenance selected",
    MAINTENANCE_HISTORY_UNAVAILABLE:
      "Unable to open the history of this maintenance",
    MAINTENANCE_RESULTS: "Results of the maintenance",
    MAINTENANCE_STATUS: "Status",
    MAINTENANCE_DESCRIPTION: "Maintenance",
    MAINTENANCE_DETAILS: "Details",
    CONTINUE_TO_MEDISPRING: "Continue to Medispring",
    MAINTENANCE_NOT_STARTED: "Not started",
    MAINTENANCE_IN_ERROR: "Error",
    MAINTENANCE_STARTED: "Started",
    MAINTENANCE_SUCCESS: "Success",
    MAINTENANCE_REQUIRED: "Mandatory",
    MAINTENANCE_HAS_BEEN_RUN: "Has been executed",
    MAINTENANCE_START_TIME: "Started at",
    MAINTENANCE_COMPLETION_TIME: "Completed at",
    BACK: "Return",
    INFO_PAUSED_MIGRATION:
      "The migration will be stopped shortly. Please wait. You will be redirected to the homepage.",
    TITLE_PAUSED_MIGRATION: "Migration is being paused",
    INFO_RESUME_MIGRATION:
      "The migration is paused. You can resume the migration now or later by restarting the migration in the maintenance window.",
    INFO_FAILED_MIGRATION_PATIENT:
      "The migration of the following patient failed: ",
    INFO_FAILED_MIGRATION_PATIENT_2:
      "Continuing the migration of the other patients. In case many patients fail to migrate, you can pause the migration and contact the support.",
    INFO_NO_VALID_GROUPID:
      "No valid group id was found. Without a group id, the migration cannot be performed. Please contact support.",
    FAILED_TO_RESOLVE_DB_CONFLICTS: "Failed to resolve database conflicts.",
    INFO_COMPLETED_MIGRATION:
      "Migration of Topaz/iCure data is successfully completed.",
    TITLE_COMPLETED_MIGRATION: "Migration complete",
    INFO_MIGRATION_UNMAPPED_PATIENTS:
      "Some patients could not be migrated. To fix this, please, launch the migration again or contact the support team.",
    INFO_FAILED_MIGRATION:
      "Migration of Topaz/iCure data crashed. Please, contact the support team for assistance.",
    TITLE_FAILED_MIGRATION: "Migration unexpectedly failed",
    INFO_FAILED_TO_LAUNCH:
      "Migration of Topaz/iCure data could not be launched. Please, contact the support team for assistance.",
    TITLE_FAILED_TO_LAUNCH: "Error while launching the migration",
    INFO_MIGRATION_ALREADY_STARTED:
      " has already started the migration. Two users can't perform this migration simultaneously.",
    INFO_MIGRATION_IS_LOCKED: "The migration is locked.",
    PERMANENT_MIGRATION_NOT_ALLOWED:
      "You are not allowed to execute the migration.",
    INFO_MIGRATION_IN_ERROR:
      "The migration cannot be launched because it previously finished in an error state. Please contact support.",
    INFO_MIGRATION_ALREADY_SUCCEEDED:
      "The migration is already successfully finished.",
    MIGRATION_IN_PROGRESS: "Migration in progress ...",
  },
  "printer-service": {
    SENT: "Data sent to the printer.",
    ERROR: "An error is survanue during printing.",
    IMPRESSION: "Impression",
    PRINTER_NOT_FOUND:
      "{{ printerName }} is not available. Please select another printer.",
    CONVERT_ERROR: "Impossible to convert the file for printing",
  },
  rlm: {
    DOCUMENTS: "Documents",
    ADMIN_DATA: "Administrative",
    TREATMENTS: "Treatments",
    HCP: "Stakeholders",
    OBJECTIVES: "Objectives",
    FOLLOW_UP: "Annual follow-up",
    PRESCRIPTIONS: "Prescriptions",
    CAREPATH: "Journey of care",
    RENAL_FAILURE: "Renal failure",
    PREDIABETES: "Pre-diabetes",
    DIABETES2: "Type 2 diabetes",
    CAREPATH_RENAL_FAILURE: "Route of treatment : renal Failure",
    CAREPATH_PREDIABETES: "Journey of care : Pre-diabetes",
    CAREPATH_DIABETES2: "Journey of care : type 2 Diabetes",
    administrative: {
      PATIENT_MANAGER: "Manager of the journey of care",
      PATIENT_MANAGER_DOES_NOT_EXISTS: "Not of manager.",
      RLM_SECRETARY: "Secretary RLM",
      ACTIVE_CAREPATH: "Route of care for active",
      DIABETES_CONVENTION: "Convention diabetes",
      RADIO_YES: "yes",
      RADIO_NO: "non",
      TYPE_OF_PATHOLOGY: "Type of pathology",
      START_DATE: "Start Date",
      END_DATE: "End Date",
      END_REASON: "Reason",
      NOTE: "Note administrative",
      CAREPATH_SIGN_DATES: "Signatures of the journey of care",
      SIGN_DATE: {
        PATIENT: "Patient",
        GENERAL_PHYSICIAN: "General practitioner",
        SPECIALIST_PHYSICIAN: "Specialist doctor",
        MUTUALITY: "Mutual",
      },
    },
    "hcp-form": {
      ADD_HCP: "Add a contributor",
      SPECIALITY: "Speciality",
      DATE: "Date of the next appointment",
      ACTIONS: "Actions",
      actions: {
        DETAIL: "Detail",
        DELETE: "Delete",
        APPOINTMENT: "Appointment",
      },
      APPOINTMENT_SUCCESS: "The update of appointments is well done",
    },
    appointment: {
      DATE: "Date",
      APPOINTMENT_NUM: "Number of RDV",
      NOTE: "Note",
      ADD_APPOINTMENT: "To add an appointment",
    },
    treatment: {
      grid: {
        INCLUSION: "A l'inclusion",
        CURRENTLY: "Currently",
        ADD_TREATMENT: "Add a treatment",
        DESCRIPTION: "Description",
        TYPE: "Type",
        ACTIONS: "Actions",
        EDIT: "Edit",
        DELETE: "Delete",
      },
      dialog: {
        TITLE_ADD: "Add a treatment",
        TITLE_EDIT: "To edit a processing",
        TITLE_EDIT_FROM_REPRISE: "Edit a treatment (from a resume)",
        TYPE: "Type",
        DESCRIPTION: "Description",
      },
    },
    followup: {
      ADD_FOLLOW_UP: "Add a follow-up",
      DESCRIPTION: "Description",
      YEAR_X: "{{value}}° Year",
      COMBO_ANNEE: "Year of follow-up",
      COMBO_GLUCOMETRE: "Type of blood glucose meter",
      EDUCATION: "Education",
      DIETETIC: "Dietary",
      PODIATRIST: "Podologue",
      SAI_EDUC_DATE_PLACE: "Date of establishment",
      SAI_Nom_Educateur: "Name educator",
      COMBO_EDUC_Statut: "Status",
      SAI_EDUC_DATE_Global1: "1° Start",
      SAI_EDUC_DATE_Global2: "2° Start",
      SAI_EDUC_DATE_Global3: "3° Start",
      SAI_EDUC_DATE_Global4: "4° Start",
      SAI_EDUC_DATE_Global5: "5° Start",
      SAI_EDUC_DATE_Rapport1: "Report",
      SAI_EDUC_DATE_Indiv1: "6° Departure",
      SAI_EDUC_DATE_Indiv2: "7° Departure",
      SAI_EDUC_DATE_Indiv3: "8° Start",
      SAI_EDUC_DATE_Indiv4: "9° Departure",
      SAI_EDUC_DATE_Indiv5: "10° Departure",
      SAI_EDUC_DATE_Rapport2: "Report",
      SAI_EDUC_DATE_Rapport3: "Report",
      SAI_EDUC_DATE_Rapport4: "Report",
      SAI_EDUC_DATE_Rapport5: "Report",
      SAI_EDUC_DATE_Rapport6: "Report",
      SAI_EDUC_DATE_Suivi1: "Follow-up 1",
      SAI_EDUC_DATE_Suivi2: "Follow-up 2",
      SAI_EDUC_DATE_Rapport11: "Report",
      SAI_EDUC_DATE_Rapport12: "Report",
      SAI_EDUC_Note: "Note",
      SAI_EDUC_DATE_Compl1: "Complication 1",
      SAI_EDUC_DATE_Compl2: "Complication 2",
      SAI_EDUC_DATE_Compl3: "Complication 3",
      SAI_EDUC_DATE_Compl4: "Complication 4",
      SAI_EDUC_DATE_Rapport7: "Report",
      SAI_EDUC_DATE_Rapport8: "Report",
      SAI_EDUC_DATE_Rapport9: "Report",
      SAI_EDUC_DATE_Rapport10: "Report",
      SAI_DIE_DATE_PLACE: "Date of establishment",
      SAI_Nom_Dietetique: "Name dietary",
      COMBO_DIE_Statut: "Status",
      SAI_DIE_DATE_1: "1° Séance 30'",
      SAI_DIE_DATE_2: "2° Séance 30'",
      SAI_DIE_DATE_Rapport1: "Report",
      SAI_DIE_DATE_Rapport2: "Report",
      SAI_DIE_Note: "Note",
      SAI_POD_DATE_PLACE: "Date of establishment",
      SAI_Nom_Podologue: "Nom podologue",
      COMBO_POD_Statut: "Status",
      SAI_POD_DATE_1: "1° Séance 45'",
      SAI_POD_DATE_2: "2° Séance 45'",
      SAI_POD_DATE_Rapport1: "Report",
      SAI_POD_DATE_Rapport2: "Report",
      SAI_POD_Note: "Note",
      dialog: {
        TITLE: "Annual follow-up diabetes",
        TITLE_DOCUMENT: "Document follow-up",
        ERROR_UPLOAD: "The document could not be sent to the server.",
        EXT_NOT_AUTHORIZED:
          "The files {{ext}} are not allowed. Files allowed : {{autorized}}",
      },
    },
    prescription: {
      grid: {
        ADD_PRESCRIPTION: "Add a prescription",
        DATE: "Date",
        RENEW_DATE: "A renouveler",
        DESCRIPTION: "Prescription",
        NUMBER: "Number(s)",
        COMMENT: "Notes",
        ACTIONS: "Actions",
        EDIT: "Edit",
        DELETE: "Delete",
      },
      dialog: {
        TITLE_ADD: "Add a prescription",
        TITLE_EDIT: "Edit a prescription",
        CHOICE: "Choice of the prescription",
        DATE: "Date prescription",
        RENEW_DATE: "Prescription, to renew the",
        NUMBER: "Number(s) prescribed",
        COMMENT: "Note",
      },
    },
    actions: {
      DELETE_ANNUAL_CONTACT: "Remove the annual follow-up",
      DELETE_DOCUMENT: "To delete the document",
      CANCEL: "Cancel",
      SAVE: "Save",
      SAVE_AND_PRINT: "Save and print",
    },
    message: {
      DELETE_ANNUAL_CONTACT:
        "Are you sure you want to remove the annual follow-up ?",
      DELETE_REPORT_DOCUMENT: "Are you sure you want to delete the document ?",
      CONTACT_SAVED: "Contact saved",
      CONTACT_DELETED: "Deleted Contact",
      ERROR_WHILE_SAVING_CONTACT: "An error occurred when saving the contact",
      ERROR_WHILE_DELETING_CONTACT:
        "An error occurred when deleting the contact",
    },
    "list-code": {
      undefined: "?",
      nodiabetes: "Not diabetes",
      diabetestypeone: "Type I diabetes",
      diabetestypeoneorgan: "Type I diabetes + affect body",
      diabetestypetwo: "Type II diabetes",
      diabetestypetwoandrisk: "Type II diabetes + at least one EN",
      no: "Non",
      low: "Faible (>60 ml/min/1.73m²)",
      moderatetostrict: "Moderate-to-severe (<60ml/min/1.73m2)",
      moved: "Moved",
      convention: "Convention",
      deceased: "Passed away(e)",
      offcriteria: "Out criteria",
      notcompleted: "Non-led",
      nonrespectobligation: "Non respect obligation",
      refusesfollowup: "Refuses the follow-up",
      year: "{{value}}° Year",
      ACCUCHEK_AVIVA: "Accu-chek Aviva",
      ACCUCHEK_COMPACT: "Accu-chek Compact",
      ACCUCHEK_COMPAC: "Accu-chek Compact +",
      ACCUCHEK_MOBILE: "Accu-chek Mobile",
      ACCUCHEK_NANO: "Accu-chek Nano",
      ACCUCHE: "Accu-chek*",
      BGSTAR: "BG star",
      BREEZE2: "Breeze 2",
      CONTOUR: "Contour",
      CONTOUR_XT: "Contour XT",
      FINE_TOUCH: "Fine touch",
      FREESTYLE: "Freestyle",
      GLUCOMEN_GM: "Glucomen GM",
      GLUCOMEN_LX: "Glucomen LX",
      GLUCOME: "Glucomen*",
      ONETOUCH_ULTRA: "One Touch Ultra",
      ONETOUCH_ULTRA2: "One Touch Ultra 2",
      ONETOUCH_ULTRA_EASY: "One Touch Ultra Easy",
      ONETOUCH_ULTRA_VERIO: "One Touch Ultra Verio",
      VITA: "Life",
      ONETOUCH_ULTR: "One Touch Ultra*",
      PRECISION_EXCEED: "Precision Exceed",
      GLUCOMEN_AREO: "Glucomen Areo",
      FOLLOWED: "Already followed",
      WAITING: "Waiting",
      MESSAGE: "Message",
      REFUSAL: "Refusal",
      VIA_RML: "Via le RML",
      dietetic: "Session dietary",
      initialeducation: "Session education starting",
      followupeducation: "Session education follow-up",
      complementaryeducation: "Session complementary education",
      podiatrist: "R / podologue",
      glucometer: "Meter",
      teststrips: "Tigettes",
      oralantidiabetic: "Oral anti-diabetic agents",
      incretinomimetics: "Incrétinomimétiques",
      "1insulininjection": "1 injection d'insuline",
      "2insulininjection": "2 injections d'insuline",
    },
  },
  "file-vignettes": {
    SELECT_FILE: "Choose this file",
  },
  "file-vignette": {
    DOCUMENT: "Document",
  },
  "hcp-selector": {
    NIHII: "INAMI",
    SSIN: "SSIN",
    LAST_NAME: "Name",
    FIRST_NAME: "First name",
    SPECIALITY: "Specialty",
    COORDINATE: "Contact details",
    NOTES: "Notes",
    LOADING: "Loading...",
    CREATE: "Create the provider",
  },
  "version-info": {
    RELEASE: "Update",
    RELEASE_DATE: "Date of the update",
    BACK: "Return",
  },
  "scan-import-service": {
    ERROR_SEARCH_AND_IMPORT: "Error during search and import",
  },
  "progress-bar": {
    ESTIMATED_TIME_LEFT: "Estimated time left",
  },
  "import-task-row": {
    IMPORT_OF_FILES: "Import of files",
    IMPORT_COMPLETE: "Import complete",
    IMPORT_WILL_NOT_STOP_IF_YOU_NAVIGATE_TO_OTHER_PAGE:
      "The import will not stop if you move away from this page",
  },
  "scan-file-list": {
    FILE_NAME: "Name of file",
    IMPORT_STATUS: "Import status",
    PATIENT: "Patient",
    CONTACT_DATE: "Contact date",
    RESPONSIBLE: "Responsible",
    CONCLUSION: "Conclusion",
  },
  "scan-file-row": {
    READY_FOR_IMPORT: "Ready for import",
    IMPORTED: "Imported",
    MISSING_INFO: "Missing info",
    DUPLICATE: "Duplicate",
    IMPORTING: "Importing",
    FAILED: "Failed",
    SELECT_PATIENT: "Choose a patient",
    CANCEL: "Cancel",
    SAVE: "Save",
    SELECT_RESPONSIBLE: "Select a responsible",
    BASED_ON_NAME_FILE: "Based on the name of the file",
  },
  "patients-duplicates": {
    TITLE: "List of patients in double",
    BY_SSIN: "Duplicates based on'SSIN",
    BY_NAME: "Duplicates based on the name",
    SSIN: "SSIN:",
    MERGE: "Merge",
    NO_DUPLICATE: "Mark as not duplicates",
    NO_SSIN_DUPLICATES_MESSAGE: "There are no duplicates by'SSIN",
    NO_NAME_DUPLICATES_MESSAGE: "There are no duplicates by name",
    WARNING:
      "Attention: Please ensure that no other action is performed on patients fused",
    MERGED: "Merged",
    NOT_DUPLICATE: "Non-duplicates",
    MERGE_ERROR_PATIENT_OPENED: "Please close a tab{{tabName}}' to merge",
    VIEW_MERGED_PROFILE: "Access to the merged profile",
    MARKED_NO_DUPLICATE: "Patients marked as not duplicated",
  },
  scales: {
    pain: {
      TITLE: "Scale of pain",
      QUESTION: "On a scale of a, what is the pain level of the patient?",
      CANCEL: "Cancel",
      PROCESS: "Complete the evaluation",
      ANSWER_COMMENTS: {
        "0": "No pain",
        "1": "Pain very slight",
        "2": "Mild pain",
        "3": "Mild pain",
        "4": "Pain average",
        "5": "Pain average",
        "6": "Pain average",
        "7": "Severe pain",
        "8": "Severe pain",
        "9": "Very severe pain",
        "10": "Unbearable pain",
      },
    },
    katz: {
      TITLE: "Katz Scale",
      CANCEL: "Cancel",
      NEXT: "Following",
      PREV: "Return",
      FINISH: "Complete the evaluation",
      PRINT: "Print",
      FORM: "Form",
      RESULTS: "Results",
      SCORE_KATZ: "Katz Score",
      CONCLUSION: "Conclusion",
      questions: {
        wash_yourself: {
          question: "Wash",
          answers: {
            "0": "it is capable to wash off completely without any help",
            "1": "need a partial assistance to wash in the top or the bottom of the belt",
            "2": "need a partial assistance to wash both above and below the belt",
            "3": "must be fully assisted to wash both above and below the belt",
          },
        },
        get_dressed: {
          question: "Dress",
          answers: {
            "0": "is able to dress and undress completely without any help",
            "1": "needs partial assistance for dressing above or below the belt (without taking account of the laces)",
            "2": "need partial assistance with dressing both above and below the belt",
            "3": "must be fully assisted to dress both above and below the belt ",
          },
        },
        transfer_relocation: {
          question: "Transfer and travel",
          answers: {
            "0": "is self-sufficient in the transfer and moves entirely independently, without any auxiliary unit(s) mechanical(s), or using third-party",
            "1": "is autonomous to the transfer and its movements through the use of auxiliary mechanical (lever handle(s), wheelchair, ...)",
            "2": "absolutely need the assistance of a third party to at least one of the transfers and/or travel",
            "3": "is bedridden or in a wheelchair and completely dependent on others to move",
          },
        },
        go_toilet: {
          question: "Go to the toilet",
          answers: {
            "0": "is able to go alone to the toilet, dressing or wipe",
            "1": "need help for one of three items : to move or dress or wipe",
            "2": "need help for two of the three items : move and/or dressing and/or wipe",
            "3": "need help for all three items : move and dress and wipe",
          },
        },
        continence: {
          question: "Continence",
          answers: {
            "0": "is continent for urine and feces",
            "1": "is accidentally incontinent for urine or stool (urinary catheter or anus artificial included)",
            "2": "is incontinent for urine (including exercises of urine) or stool",
            "3": "is incontinent for urine and feces",
          },
        },
        incontinence: {
          question:
            "Incontinence combined: a combination of incontinence of urine and night-time incontinence of urine occasionally during the day has been observed in the beneficiary",
          answers: {
            "0": "yes",
            "1": "non",
          },
        },
        eat: {
          question: "Eat",
          answers: {
            "0": "is able to eat and drink on his own",
            "1": "is in need of help prior to eat or drink",
            "2": "needs partial assistance for eating and drinking",
            "3": "the patient is totally dependent for eating and drinking",
          },
        },
        is_dementia: {
          question:
            "The patient has been diagnosed as suffering from dementia following a diagnostic work-up specialist",
          answers: {
            "0": "yes",
            "1": "non",
          },
        },
      },
      results: {
        forfait_c: "Package C",
        forfait_b: "Package B",
        forfait_a: "Package Has",
        toilets0: "Toilet 0x/week",
        toilets2: "Toilet 2x/week",
        toilets7: "Toilet 7x/week",
        incontinence: {
          true: "With incontinence combined",
          false: "Without incontinence combined",
        },
        dementia: {
          true: "With dementia",
          false: "Without dementia",
        },
        is_dementia: {
          true: "With dementia",
          false: "Without dementia",
        },
      },
      DOC_FOOTER: "Acknowledgement",
    },
  },
  "guardPost-mode": {
    UNDEFINED_GUARDPOST_MEDICAL_LOCATION:
      "The information of your entity are not available or have not been selected properly. Please log in again in order to choose the information of your entity, or to contact the Helpdesk.",
    INVALID_GUARDPOST_MEDICAL_LOCATION:
      "The information of your entity are not full. Name: {{name}} and Inami: {{nihii}}. Please contact the Helpdesk",
  },
  "printer-management": {
    PRESCRIPTION: "Prescription",
    ITT: "ITT",
    DOCUMENT: "Document",
    PRINTER: "Imprimante",
    FORMAT: "Format",
    TITLE: "Impression",
    ALWAYS_REQUEST_PRINTER: "Ask every time",
  },
  "security-management": {
    TITLE: "Security",
    TEXT: "Automatic disconnection after inactivity of:",
    MINUTES: "minutes.",
  },
  "idle-popup": {
    TITLE: "Automatic disconnection",
    CONTENT:
      "You have been inactive on Medispring for {{timeout}} minutes. You will be disconnected in a moment.",
  },
  "print-document-dialog": {
    CANCEL: "Cancel",
    FORMAT: "Format",
    PRINTER: "Imprimante",
    PRINT: "Print",
    SAVE_MY_CHOICE: "To back up my choice",
    ALWAYS_CHANGE_LATER: "You can always change your selection in tools, Print",
  },
  "document-types": {
    ITT: "ITT",
    PRESCRIPTION: "Prescription",
  },
  SUMEHR: {
    COMMENT: "Sumehr created by Medispring.",
    SECRET_ELEMENT:
      "Some of the items in the folder do not appear as private, or not relevant.",
    EMPTY_CATEGORY: "The class {{c}} has no element in the folder.",
    EMPTY_CATEGORIES: "Categories {{c}} does not have an item in the folder.",
  },
  "versions-info": {
    BACK: "Return",
    RELEASE: "Update",
    RELEASE_DATE: "Date of the update",
  },
  "patient-conflicts": {
    MANAGE_CONFLICTS: "Manage conflict",
    FIRST_NAME: "First name",
    LAST_NAME: "Family name",
    CHRONICAL_DISEASE: "$ERROR_",
    RESPONSIBLE: "$ERROR_",
    AUTHOR: "$ERROR_",
    END_OF_LIFE: "$ERROR_",
    SSIN: "SSIN",
    CIVILITY: "Civility",
    GENDER: "Gender",
    MAIDEN_NAME: "Name of girl",
    SPOUSE_NAME: "Name of spouse",
    PARTNER_NAME: "The name of the spouse",
    PERSONAL_STATUS: "Personal status",
    DATE_OF_BIRTH: "Date of birth",
    DATE_OF_DEATH: "Date of death",
    PLACE_OF_BIRTH: "Place of birth",
    PLACE_OF_DEATH: "Place of death",
    EDUCATION: "Education",
    PROFESSION: "Profession",
    NOTES: "Notes",
    NATIONALITY: "Nationality",
    PICTURE: "Image",
    EXTERNAL_ID: "External id",
    ALIAS: "Other",
    ADMINISTRATIVE_NOTE: "Note administrative",
    WARNING: "Attention",
    NEXT: "Following",
    EDIT_CONFLICTS: "Edit conflict",
    DEACTIVATION_REASON: "Deactivation reason",
    SAVE: "Save",
    CANCEL: "Cancel",
    ADDRESSES: {
      STREET: "Adresse [{{ number }}] > Rue",
      CITY: "Adresse [{{ number }}] > Ville",
      COUNTRY: "Adresse [{{ number }}] > Pays",
      DESCR: "Adresse [{{ number }}] > Description",
      HOUSE_NUMBER: "Address [{{ number }}] > Number",
      POSTAL_NUMBER: "Adresse [{{ number }}] > Code postal",
      POSTBOX_NUMBER: "Address [{{ number }}] > Box",
    },
    INSURABILITIES: {
      END_DATE: "Insurability [{{ number }}] > End of validity",
      IDENTIFICATION_NUMBER:
        "Insurability [{{ number }}] > identification Number",
      DENTAL: "Insurability [{{ number }}] > Dental",
      AMBULATORY: "Insurability [{{ number }}] > Outpatient",
      HOSPITALISATION: "Insurability [{{ number }}] > Hospitalization",
      TITULARY_ID: "Insurability [{{ number }}] > ID holder",
    },
    PATIENT_HCP: {
      REFERRAL: "Stakeholders [{{ number }}] > Reference",
      REFERRAL_PERIODS_END_DATE: "Intervenants [{{ number }}] > Date de fin",
      REFERRAL_PERIODS_COMMENT: "Intervenants [{{ number }}] > Commentaire",
    },
    PARTNERSHIPS: {
      TYPE: "Partenariat [{{ number }}] > Type",
      STATUS: "Partenariat [{{ number }}] > Statut",
      PARTNERSHIP_DESCRIPTION: "Partenariat [{{ number }}] > Description",
    },
    MEDICAL_HOUSE_CONTRACTS: {
      CHANGE_TYPE: "Medical House [{{ number }}] > Change the type",
      CHANGED_BY: "Medical House [{{ number }}] > Changed by",
      END_OF_CONTRACT: "Medical House [{{ number }}] > End of contract",
      END_OF_COVERAGE: "Medical House [{{ number }}] > End of coverage",
      END_OF_SUSPENSION: "Medical House [{{ number }}] > End of suspension",
      FORCED_SUSPENSION: "Medical House [{{ number }}] > forced Suspension",
      GP: "Medical House [{{ number }}] > GP",
      HCP_ID: "Medical House [{{ number }}] > The contributor Id",
      KINE: "Medical House [{{ number }}] > Physio",
      NO_GP: "Medical House [{{ number }}] > Not GP",
      NO_KINE: "Medical House [{{ number }}] > No physio",
      NO_NURSE: "Medical House [{{ number }}] > 't nurse",
      NURSE: "Medical House [{{ number }}] > Nurse",
      START_OF_CONTRACT:
        "Medical House [{{ number }}] > the Beginning of the contract",
      START_OF_COVERAGE: "Medical House [{{ number }}] > coverage Start",
      START_OF_SUSPENSION:
        "Medical House [{{ number }}] > the Beginning of the suspension",
      SUSPENSION_REASON:
        "Medical House [{{ number }}] > cause of the suspension",
      SUSPENSION_SOURCE: "Medical House [{{ number }}] > Source suspension",
      VALID_FROM: "Medical House [{{ number }}] > Valid",
      VALID_TO: "Medical House [{{ number }}] > Valid",
    },
    FINANCIAL_INFO: {
      NAME: "Financial information [{{ number }}] > Name",
      KEY: "Financial information [{{ number }}] > Key",
      BIC: "Financial information [{{ number }}] > BIC",
      PROXY_BANK_ACCOUNT: "Financial information [{{ number }}] > Proxy IBAN",
      PROXY_BIC: "Financial information [{{ number }}] > Proxy BIC",
    },
  },
  "scan-import-management": {
    TITLE: "Scan import",
    LAST_IMPORT: "Last import",
    SEARCH_AND_IMPORT: "Search and import",
    PATH: "Path",
    NO_PATH_SET: "No path set",
    EDIT: "Edit",
    NO_IMPORT_YET: "No import yet",
    FILES_RETRIEVED_FROM_DISK: "Files retrieved from the disk",
    MODIFY_RESPONSIBLE: "Modify responsible",
    DO_NOT_IMPORT: "Do not import",
    IMPORT: "Import",
    MISSING_PATIENT: "Missing patient",
    READY_FOR_IMPORT: "Ready for import",
    DUPLICATES_AND_FAILED: "Filed and doubles",
    NO_FILES_TO_IMPORT: "No files to import",
    ONLY_AVAILABLE_ON_DESKTOP_APP: "Only available on the desktop app",
    MODIFY_DATE: "Change the date",
    SELECT_DATE: "Choose the date",
  },
  "patients-export": {
    EXCEL_NAME: "ListeDesPatients.xlsx",
    EXCEL_GEN_0:
      "Generation of Excel file in progress. Please wait, this may take a lot of time.",
    EXCEL_GEN_100: "Generation of the Excel file 100%",
    EXCEL_GEN_ERR: "Error during the generation of the Excel file.",
    fields: {
      FIRST_NAME: "First name",
      LAST_NAME: "Last name",
      DATE_OF_BIRTH: "Date of birth",
      SSIN: "National number",
      ADDRESS: "Address",
      CONTACT: "Contact",
      GENDER: "Genre",
      AGE: "Age",
    },
    TITLE: "Export type",
    EXPORT: "Export",
    CANCEL: "Cancel",
    FORMAT: "Format",
    MULTIPLE_EXCEL: {
      LABEL: "Export the list of selected patients",
      DESCRIPTION:
        "The list of patients you have selected will be exported in an Excel spreadsheet.",
      FORMAT: "Excel (XSLX)",
    },
    MULTIPLE_PMF: {
      LABEL: "Export selected patients' records",
      DESCRIPTION:
        "Each patient's file will be saved in a file in the folder of your choice.",
      FORMAT: "PMF",
    },
    SINGLE_CSV: {
      LABEL: "Export the patient's contact list",
      DESCRIPTION:
        "The patient's contact list will be saved in a file where the information is separated by commas.",
      FORMAT: "CSV",
    },
    SINGLE_PMF: {
      LABEL: "Export the patient's records",
      DESCRIPTION:
        "The patient's file will be saved in a file in the folder of your choice.",
      FORMAT: "PMF",
    },
    SINGLE_SELECTED_EXPORT: "Export the selected patient",
    MULTIPLE_SELECTED_EXPORT: "Export your selected patients",
    ALL_EXPORT: "Export all your patients",
    SINGLE_SELECTED_EXCEL: {
      LABEL: "Export to Excel format",
      DESCRIPTION: "The patient will be exported to an Excel table.",
    },
    SINGLE_SELECTED_JSON: {
      LABEL: "Export Json",
      DESCRIPTION:
        "The decrypted patient data will be exported in Json format.",
    },
    SINGLE_SELECTED_PMF: {
      LABEL: "Export PMF",
      DESCRIPTION: "The patient will be exported in PMF format.",
    },
    MULTIPLE_SELECTED_EXCEL: {
      LABEL: "Export to Excel format",
      DESCRIPTION: "The patient list will be exported to an Excel table.",
    },
    MULTIPLE_SELECTED_JSON: {
      LABEL: "Export Json",
      DESCRIPTION:
        "The list of all your decrypted data will be exported in Json format.",
      WARNING:
        "Warning: This can take up a lot of space on your hard drive. Be sure to free up enough space.",
    },
    MULTIPLE_SELECTED_PMF: {
      LABEL: "Export PMF",
      DESCRIPTION:
        "The list of all patients will be exported in PMF format to a .zip file.",
      ZIP_NAME: "Export-multiple-PMF.zip",
    },
    SUCCESS: "Data exported successfully.",
    FAILURE: "Errors encountered while exporting.",
    EXPORT_STARTED: "An export of patient records is in progress.",
    EXPORT_FINISHED: "Finished exporting patient records.",
    LOGS_DIALOG: {
      TITLE: "Summary of errors",
      BTN_CLOSE_LABEL: "OK",
      DOWNLOAD_LOGS: "Download all logs",
    },
  },
  "efac-to-be-sent": {
    BATCH_ALREADY_SENT_ERROR:
      "a batch has already been sent today to the OA {{ioCode}}",
    NO_FINANCIAL_INFO_FOR_ASSISTANTS:
      "Shared bank account of the assistants is unknown",
    COLLECTIVE_SETTING_WHILE_PRACTICIAN:
      "Individual payment is not allowed for this medical location.",
    INDIVIDUAL_SETTING_WHILE_GUARDPOST:
      "Collective payment is not allowed for this medical location.",
  },
  exports: {
    groups: {
      CONTACT: "Contact",
      SUB_CONTACT: "Sous contact",
      HEALTH_ELEMENT: "Élément de soin",
      PLAN_OF_ACTION: "Plan d'action",
      SERVICE: "Service",
    },
    fields: {
      CONTACT_ID: "Contact ID",
      CONTACT_TYPE: "Contact Type",
      CONTACT_SPECIALITY: "Contact Specialty",
      CONTACT_DATE: "Contact Date",
      CONTACT_AUTHOR_ID: "Contact Author ID",
      CONTACT_AUTHOR_NAME: "Contact Author Name",
      CONTACT_INTEGRATOR_ID: "Contact Integrator ID",
      CONTACT_INTEGRATOR_NAME: "Contact Integrator Name",
      CONTACT_INTEGRATION_DATE: "Contact Integration Date",
      SUB_CONTACT_ID: "Sub Contact ID",
      SUB_CONTACT_HE_NAMES: "Sub Contact Elements of care",
      HEALTH_ELEMENT_NAME: "Name (Elements of care)",
      PLAN_OF_ACTION_NAME: "Name (Action plan)",
      SERVICE_ID: "Service ID",
      SERVICE_NAME: "Service Name",
      SERVICE_VALUE: "Service Value",
      SERVICE_UNIT: "Service Unit",
      SERVICE_TAGS: "Service Tags",
      SERVICE_CREATION_DATE: "Service Creation Date",
    },
    values: {
      CONTACT: {
        types: {
          consult: "Consultation",
          labresult: "Laboratory result",
          contactreport: "Report",
          telephonicconsultation: "Reviews / Miscellaneous",
          multidisciplinaryconsult: "Multidisciplinary consultation",
          homevisit: "Home visit",
        },
        specialities: {
          ADMINISTRATION: "Administration",
          CARE_GIVER: "Caregiver",
          ANATOMOPATHOLOGY: "Pathology",
          ANESTHESIOLOGY: "Anesthesiology",
          SOCIAL_WORKER: "Social worker",
          PHARMA_ASSISTANT: "Pharmaceutical technical assistant",
          AUDIOLOGIST: "Audiologist",
          BACTERIOLOGY: "Bacteriology",
          TRUSS_ORTHOTIST: "Truss-orthotist",
          CLINICAL_BIOLOGY: "Clinical biology",
          CARDIOLOGY: "Cardiology",
          GENERAL_SURGERY: "General surgery",
          MAXILLOFACIAL_SURGERY: "Maxillofacial Surgery",
          PLASTIC_SURGERY: "Plastic surgery",
          STOMATOLOGICAL_SURGERY: "Stomatological surgery",
          CARDIOVASCULAR_SURGERY: "Cardio-vascular surgery",
          ABDOMINAL_SURGERY: "Abdominal surgery",
          DENTISTRY: "Dentistry",
          DERMATOLOGY: "Dermatology",
          DIETETIC: "Dietetic",
          ENDOCRINOLOGY: "Endocrinology",
          OCCUPATIONAL_THERAPIST: "Occupational therapist",
          GASTROENTEROLOGY: "Gastroenterology",
          GENETIC: "Genetic",
          GERIATRICS: "Geriatrics",
          GYNECOLOGY: "Obstetric gynecology",
          HEMATOLOGY: "Hematology",
          NURSE: "Nurse",
          NURSE_EDUCATOR: "Nurse Educator",
          PHYSIOTHERAPY: "Physiotherapy",
          SPEECH: "Speech therapy",
          ACUTE_MEDICINE: "Acute Medicine",
          GENERAL_MEDICINE: "General Medicine",
          persphysician: "General Medicine",
          INTERNAL_MEDICINE: "Internal Medicine",
          NUCLEAR_MEDICINE: "Nuclear Medicine",
          PHYSICAL_MEDICINE: "Physical Medicine / Physiotherapy",
          EXPERTISE_MEDICINE: "Expertise medicine",
          INSURANCE_MEDICINE: "Insurance medicine",
          NEONATOLOGY: "Neonatology",
          NEPHROLOGY: "Nephrology",
          NEUROSURGERY: "Neurosurgery",
          NEUROLOGY: "Neurology",
          NEUROPEDIATRICS: "Neuropediatrics",
          NEUROPSYCHIATRY: "Neuropsychiatry",
          NUTRITIONIST: "Nutritionist",
          ONCOLOGY: "Oncology",
          OPHTHALMOLOGY: "Ophtalmology",
          ORTHOPEDICS: "Orthopedics",
          ORTHOPTISTE: "Orthoptist",
          OTORHINOLARYNGOLOGY: "Oto-Rhino-Laryngology",
          PEDIATRICS: "Pediatrics",
          PHARMACOLOGY: "Pharmacology",
          RESPIRATORY: "Pneumology",
          PODIATRIST: "Podiatrist",
          PUBLIC_HEALTH_PREVENTION: "Public Health Prevention",
          PROSTHETIST: "Prothesist",
          PSYCHIATRY: "Psychiatry",
          MEDICAL_PSYCHOLOGY: "Medical Psychology",
          RADIODIAGNOSTICS: "Radiodiagnostics / Medical Imaging",
          RADIOTHERAPY: "Radiotherapy",
          RESUSCITATION: "Resuscitation",
          RHEUMATOLOGY: "Rheumatology",
          MIDWIFE: "Midwife",
          STOMATOLOGY: "Stomatology",
          EMERGENCY: "Emergency",
          UROLOGY: "Urology",
          TOXICOLOGY: "Toxicology",
          TRANSPORT: "Transport of sick",
          INFECTIOLOGY: "Infectiology",
        },
      },
      SERVICE: {
        types: {
          PLAN_TEXT: "Action plan",
          PLAN_MEDICATION: "Treatment",
        },
      },
    },
  },
  "patients-mailing-dialog": {
    EMAIL: "Email",
    PAPER: "Paper",
    PLACE_DOCUMENT_AS_ATTACH:
      "Place the document as an attachment to the email",
    SEND_CHANNEL: "Send channel",
    DOCUMENT: "Document",
    PRINT_FOR_PATIENTS_WITHOUT_ADDRESS:
      "Also print patients without an address",
    PRINTER: "Printer",
    CANCEL: "Cancel",
    PRINT: "Print",
    SEND_EMAIL: "Send emails",
    EMAIL_BODY: "Email body",
    EXPORT_CSV: "Export Excel",
    FAILED_SENDING_EMAIL_TO: "Failed to send email to {{name}}",
    FAILED_PRINTING_DOCUMENT_FOR: "Failed to print document for {{name}}",
    BACK: "Retour",
    SUBJECT: "Subject",
    FROM: "From",
    NAME: "name",
  },
  "calendar-datepicker": {
    DATE_NOT_VALID: "The date that you entered is not valid.",
  },
  "partial-date": {
    UNKNOWN: "date unknown",
  },
  "select-date-dialog": {
    SAVE: "Save",
    CANCEL: "Cancel",
  },
  "access-journal": {
    DATE: "Date",
    USER: "User",
    ROLES: "Roles",
    PATIENT_FILE: "Patient File",
    ACCESS_TYPE: "Type",
    type: {
      USER_ACCESS: "Patient access",
      USER_LOGIN: "Connections",
      ALL: "All",
    },
    FILTER_BY_USER: "Filter by user",
  },
  "address-book": {
    SEARCH_GLOBAL_DIRECTORY: "Add a recipient from the global directory",
    SEARCH_COBRHA: "Cobrha search",
    SEARCH_BY_NAME:
      "Type 2 letters followed by * to search for a name or enter a NIHII/SSIN/CBE/EHP.",
    SEARCH_BY_QUALITY: "Search a type. Default: physician",
    CANCEL: "Cancel",
    OK: "Add",
    LABELS: {
      NAME: "Name",
      NIHII_NISS_CBE_EHP: "Inami/SSIN/CBE/EHP",
      SPECIALTY: "Specialty",
      QUALITY: "Quality",
    },
    SELECTED_RECIPIENTS: "Selected recipients:",
    ADD_IN_LOCAL_ADDRESS_BOOK: "Add recipients to the address book",
  },
  "e-health-box-errors-by-code": {
    UNKNOWN: "Unknown error.",
    "403_COULD_NOT_RETRIEVE_ETK":
      "One or more of the recipients do not have a valid certificate. This can happen if their certificate has expired or one of the recipients you entered does not exist.",
    "801": "The message exceeds the maximum authorized size.",
    "802": "The specified Identifier is invalid; please verify the ID.",
    "803":
      "The specified quality is invalid; please verify that quality is a quality recognized by the system.",
    "804":
      "The specified type is invalid; please verify that the type is a type recognized by the system.",
    "805":
      "The specified subtype is invalid; please verify that subtype is recognized by the system.",
    "810":
      "The specified BoxId is invalid; please verify the data and that you can access it.",
    "814": "You are not authorized to publish to this Quality.",
    "816":
      "The digest of the streamed document does not match the supplied one.",
    "826": "One or more recipients have an Out-Of Office active.",
    "700": "Unknown technical error ",
    "701": "Business validation error ",
    "702": "Duplicate publication id ",
    "703": "One or more recipients are invalid ",
    "806":
      "The specified MessageID is invalid; please verify that the Source and the MessageID are correct and that you can access it.",
    "807":
      "EndIndex must be larger or equal to StartIndex; please correct StartIndex and EndIndex.",
    "808":
      "A maximum of 100 messages can be returned by request; please correct StartIndex and EndIndex.",
    "809":
      "The specified MessageID is invalid; please verify that the MessageID is correct and that you are the sender.",
    "812":
      "You cannot move a message from your Inbox to your Sent box (even via recycle bin) and vice versa.",
    "813":
      "Not all messages were moved successfully. Please verify for each message that the Source and the MessageID are correct. Also pay attention that a message in the recycle bin which was moved from the Inbox cannot be restored back to the Sent box and vice versa.",
    "815":
      "Not all messages were deleted successfully. Please verify for each message that the Source and MessageId are correct.",
    "820":
      "The period 01/07/2012 to 12/07/2012 is invalid because it overlaps another period.",
    "821": "The end of the period cannot be further than a year in the future.",
    "822": "The start date cannot be after the end date.",
    "823": "The start date cannot be in the past. ",
    "824": "One or more substitutes cannot be chosen because they are absent.",
    "825": "The number of substitutes may not exceed 5.",
    "827":
      "One or more substitutes are unknown or not correct, please correct them.",
    "828": "The user is unknown or not correct, please correct him.",
    "829": "A valid substitute is a person, not an organization.",
    "830": "A person cannot be substitute for himself.",
    "840": "One or more OoOId are invalid.",
  },
  application: {
    UNKNOWN_ERROR: "An unknown error has occured.",
  },
  prescriptions: {
    SAMV2: "SAMv2",
    tab: {
      MEDICATION: "Medication",
      TREATMENT_HISTORY: "Treatment history",
      CHAPTER_IV: "Chapter IV",
    },
    SEARCH: {
      RESULTS: "Results",
      LABEL: "R/",
      ALTERNATIVES: {
        LABEL: "Alternatives",
        CHEAPEST: "Less expensive",
        ALL_PRICES: "All",
        ALL_PHARMACEUTICAL_FORMS: "All",
        VMP_GROUP_NAME: "Missing group name",
      },
      CHOOSE_MEDICAMENT: "Choosing a medication",
      SEARCH_PLACEHOLDER: "Search",
      PRICE_EVALUATION: {
        RECOMMENDED: "Cheap",
        ALTERNATIVE: "Reimbursable",
      },
      LISTING_HEADERS: {
        NAME: "Name",
        STRENGTH: "Strength",
        PACK_SIZE: "Pack size",
        PHARMACEUTICAL_FORM: "Pharmaceutical form",
        PUBLIC_PRICE: "Price",
        CO_PAYMENT: "Co-payment",
        PUBLIC_PRICE_INDEX: "Price index",
        PRICE_INDEX_SAMV2: "Index",
        CO_PAYMENT_INDEX: "Co-payment index",
        PRESCRIPTION: "Prescription",
        REIMBURSEMENT: "Reimbursement",
        COMMERCIALIZATION: "Commercialization",
        DELIVERY_MODUS: "Delivery Modus",
      },
      PRESCRIPTION_REQUIRED: "R",
      RECOMMANDATION_EXPLANATION: {
        END_COMMERCIALIZATION: "Prescribable up to {{date}}.",
        START_COMMERCIALIZATION: "Prescribable from {{date}}.",
        END_REIMBURSEMENT: "Reimbursed until {{date}}.",
        REASON: "Reason:",
        IMPACT: "Impact:",
        ADDITIONAL_INFO: "Additional info:",
        UNAVAILABLE: "Unavailable:",
        ANCIENT_NAMES: "Previous names:",
      },
    },
    TYPES: {
      SPECIALTY: "Specialty",
      ATC: "ATC",
      MAP: "Masterful",
      DCI: "DCI",
      NON_MEDICINAL_PRODUCT: "Non Medicinal",
      PRIVATE: "Prive",
    },
    ATC: {
      LISTING_TITLE: "ATC Classes",
      VALIDATE: "Validate",
    },
    PHARMACEUTICAL_COMPOUNDING: {
      LISTING_HEADERS: {
        NAME: "Name",
        FORMULA: "Formula",
        FORM: "Form",
        QUANTITY: "Quantity",
        CLASS: "Class",
      },
      NAME: "Name",
      FORMULA: "R/",
      FORM: "Pf/",
      QUANTITY: "Dt/",
      UNIT: "Unit",
    },
    SAVE: "Register",
    CANCEL: "Close",
    PRESCRIBE: "Prescribe",
    SELECT: "Select",
    ADVERSE_EFFECTS_REPORTING: "Report adverse drug reactions",
    posology: {
      POSOLOGY: "S/",
      BT: "Bt",
      QUANTITY: "Dt/",
      PHARMACEUTICAL_FORM: "Form",
      JEUN: "With an empty stomach",
      MORNING: "Morning",
      "10H": "10h",
      LUNCH: "Noon",
      "16H": "16h",
      EVENING: "Evening",
      NIGHT: "Couch.",
      REPAS: "Meal",
      BEFORE: "Av",
      DURING: "Pd",
      AFTER: "Ap",
      "TOUS LES": "All",
      JOURS: "days",
      CHRONIQUE: "Chronic",
      FOR: "for",
      periodType: {
        day: "Jours",
        week: "Semaines",
        month: "Mois",
        year: "Années",
      },
      UNITIES: "Units",
    },
    remark: {
      notes: {
        DIABETES_CARE_PATH: "Diabetes care path",
        RENAL_INSUFFICIANCE_CARE_PATH: "Renal insufficiency care path",
        DIABETES_CONVENTION: "Diabetes Convention",
        THIRD_PARTY_CHARGE: "Third party charge applicable",
        THIRD_PARTY_NO_CHARGE: "Third party paying not applicable",
        ON_DEMAND: "On demand",
        NO_IMPROVEMENTS_AFTER_DAYS: "If no improvement after days",
      },
      REMARK: "Remarks",
      NOTE: "Note",
    },
    PRESCRIPTION: "Prescription",
    ACUTE: "Acute",
    CHRONIC: "Chronic",
    ACTIONS: {
      EDIT: "Edit",
      STOP: "Stop",
      HISTORY: "History",
      NOTICE: "Notice",
      RCP: "RCP",
      CBIP: "CBIP",
    },
    POSOLOGY_LISTING: {
      JEUN: "am",
      MORNING: "8",
      "10H": "10",
      LUNCH: "12",
      "16H": "16",
      EVENING: "20",
      NIGHT: "22",
    },
    ADDITIONAL_INFO: {
      CATEGORY: "Category",
      WADA: "WADA (World Anti-Doping Agency)",
      ADDITIONAL_MONITORING: "Additional Monitoring",
      RMA: "RMA (Risk Minimization Activities)",
      DHPC: "DHPC (Dear Healthcare Professional Communication)",
      CBIP: "CBIP",
      NOTICE: "Notice",
      RCP: "RCP",
      UNAVAILABLE: "Unavailable",
      MOTIVE: "Motive",
      IMPACT: "Impact",
      EXPIRED: "Expired",
    },
    PRESCRIPTIONS_LISTING: {
      LABEL: "Prescriptions",
      PRACTITIONER: "Practitioner",
      DESCRIPTION: "R/",
      POSOLOGY: "S/",
      QUANTITY: "Dt/",
      UNITIES: "Units",
      FORM: "Pf/",
      END_VALIDITY: "Completion date",
      POSOLOGY_DESCRIPTION: {
        REGIMEN: {
          A_JEUN: "fasting",
          A_MORNING: "in the morning",
          A_10H: "at 10am",
          A_LUNCH: "at noon",
          A_16H: "at 16h",
          A_EVENING: "in the evening",
          A_NIGHT: "at bedtime",
        },
        MEAL: {
          BEFORE: "before meals",
          DURING: "during the meal",
          AFTER: "after the meal",
        },
        FREQUENCY: {
          EVERY_ONE: {
            DAY: "every day",
            WEEK: "every week",
            MONTH: "every month",
            YEAR: "each year",
          },
          EVERY_N: {
            DAY: "all {{n}} days",
            WEEK: "all {{n}} weeks",
            MONTH: "all {{n}} months",
            YEAR: "all {{n}} years",
          },
        },
      },
    },
    DCI: {
      ALTERNATIVES: "Specialty Alternatives",
    },
    STOP_MEDICATION_TREATMENT: {
      TITLE: "Stop a treatment",
      MOTIVE: "Motive",
      COMMENT: "Comment",
      OPTIONAL: "Optional",
      SAVE: "Register",
      CANCEL: "Cancel",
      UNDO: "Reactivate",
      REASONS: {
        ALLERGY: "Allergy",
        INTOLERANCE: "Intolerance",
        UNEXPECTED_SIDE_EFFECTS: "Unexpected side effects",
        INEFFICIENT: "Inefficient",
        REFUSAL_PATIENT: "Patient refusal",
        END_OF_TREATMENT: "End of treatment",
        PACKAGE_CHANGE: "Change of packaging",
        OTHER: "Other",
      },
    },
    SUSPEND_MEDICATION_TREATMENT: {
      TITLE: "Suspend a treatment",
      MOTIVE: "Reason",
      COMMENT: "Comment",
      OPTIONAL: "Optional",
      SAVE: "Register",
      CANCEL: "Cancel",
      START_DATE: "Start of suspension",
      END_DATE: "End of suspension",
      ADD: "Add",
      SUSPENSIONS: "Suspensions",
    },
    history: {
      types: {
        PRESCRIPTION: "Prescriptions",
        TREATMENT: "Specialities",
      },
    },
    treatmentHistory: {
      groups: {
        HISTORY: "History",
        UNSPECIFIED: "Not specified",
      },
      DATE: "Date",
      HEALTH_ELEMENT: "Element of care",
      BEGIN: "Beginning",
      END: "End",
      TREATMENT: "Treatment",
      POSOLOGY: "Dosage",
      MOTIF_STOP: "Reason",
      PRESCRIPTOR: "Prescriber",
      CNK: "CNK code",
      UNSPECIFIED: "Not specified",
      BOXES: "{{boxes}} boxes",
      dateFilter: {
        PERIOD: "Period",
        LAST_MONTHS: "The last {{months}} months",
        INDEFINITE: "No filter",
        BEFORE_YEAR: "Before {{year}}",
      },
      atc_filter: {
        ATC_CLASS: "ATC Class",
        ALL_CLASSES: "All classes",
      },
      "text-filter": {
        SEARCH: "Search",
      },
      MEDICATION_LINES: "Medication lines",
      OUTDATED: "History",
      NOTE: "Note",
      CHRONICAL: "Chronic disease",
    },
    RECIPE: {
      NOT_APPLICABLE: "not applicable",
      NO_PHONE_WARNING:
        "Attention, the precription will not be electronic! To send an ePrescription, you must enter a telephone number and a type of telephone in your file. Modify your file by clicking on your initials at the bottom left of the screen",
      NO_UNIQUE_CODE:
        "Attention, the medication does not contain a unique identifier",
      ERROR_UPLOAD: "The prescription could not be sent to Recip-e",
      SUCCESSFUL_UPLOAD: "Prescription sent to Recip-e.",
    },
    PDF_PRESCRIPTION: {
      EXPIRATION_DATE: "Expiration date",
      SSIN: "SSIN",
      NIHII: "N° INAMI",
      BOXES: "Box(es)",
      UNITIES: "Units",
      QUANTITY: "Dt/",
      SHAPE: "Pf/",
      FORMULA: "R/",
      POSOLOGY: "S/",
      PRINT_ERROR: "Error during the printing of the documents",
      ONLINE: {
        PROOF: "PROOF OF ELECTRONIC PRESCRIPTION",
        INFO_TOP:
          "Please present this document to your pharmacist to scan the barcode and dispense the prescribed medication.",
        PRACTITIONER: "Prescriber",
        PATIENT: "Beneficiary",
        CONTENT: "Content of the electronic prescription",
        INFO_BOTTOM:
          "Warning: No handwritten addition to this document will be taken into account",
        DATE: "Date",
      },
      OFFLINE: {
        PRACTITIONER: "Name and surname of the prescriber",
        BIRTH_DATE: "Date of Birth",
        PRACTITIONER_FILL: "TO BE COMPLETED BY THE PRESCRIBER:",
        PATIENT: "Name and surname of the beneficiary",
        VIGNETTE: "Reserved for the packaging sticker",
        STAMP: "Prescriber's stamp",
        DATE_SIGNATURE: "Date and signature of the prescriber",
        MEDICATION: "PRESCRIPTION OF APPLICABLE MEDICINES FROM 1 November 2019",
      },
    },
    NON_MEDICINAL_PRODUCTS: {
      LISTING_HEADERS: {
        NAME: "Name",
        PRODUCER: "Producer",
      },
    },
  },
  prescriptionHistory: {
    TITLE: "Ordonnances",
    headers: {
      START: "Prescribed: ",
      END: "End date: ",
      PRESCRIBER: "Prescriber: ",
      ERECIPE: "eRecipe: ",
    },
    QUANTITY: "Dt/",
    CNK: "CNK: ",
    REPRESCRIBE: "Represcribe",
    SPECIALITY_FILTER: "Filter by speciality",
    NOT_SENT: "Not sent",
    MEDICATION_UNAVAILABLE:
      "The drug name is no longer available, please modify it from the list of drugs",
    CHECK_AVAILABILITY: "Dispo",
  },
  filters: {
    START_DATE: "Start Date",
    END_DATE: "End Date",
  },
  PRIVATE_MEDICATION: {
    NAME: "Nom",
  },
  "private-medication-management": {
    TITLE: "Add private medication",
    NAME: "Name",
    DOSAGE: "Dosage",
    PHARMACEUTICALFORM: "Form",
    PACKSIZE: "Pack size",
    RESET: "Reset",
    SAVE: "Save",
    EDIT: "Submit",
    EDIT_NOT_MINE: "This unique has been created by another user.",
    ERROR_SAVE: "Impossible to save your private medication",
    SAVE_SUCCESS: "Private medication saved successfully",
    EDIT_SUCCESS: "Private medication modified successfully",
    ERROR_GET: "Private medication could not be loaded",
  },
  "patient-hub-ms": {
    legend: "Legend",
    "hub-chron-med": "Chronic medication",
    "hub-sus-med": "Suspended medication",
    "hub-med-mag": "Compound prescription",
    "hub-med-dci": "DCI prescription",
    "hub-one-shot": "One shot medication",
    "endDate-short": "End",
    comment: "Comment",
    "start-date-short": "Start",
    mom_after_short: "Af",
    mom_during_short: "Dur",
    mom_before_short: "Be",
    freq: "Frequence",
    medic: "Drug",
    dmi: "Dmi",
    hub: "Hub",
    morning: "Lunch",
    midday: "Breakfast",
    evening: "Diner",
    version: "Version",
    accessDate: "Access date",
    patientAccess: "Patient access",
    lastUpdate: "Last update",
    profil: "Profile",
    author: "Author",
    weekDay_1: "Monday",
    weekDay_2: "Tuesday",
    weekDay_3: "Wednesday",
    weekDay_4: "Thursday",
    weekDay_5: "Friday",
    weekDay_6: "Saturday",
    weekDay_7: "Sunday",
    weeknr: "Week #",
    day_0: "Sunday",
    day_1: "Monday",
    day_2: "Tuesday",
    day_3: "Wednesday",
    day_4: "Thursday",
    day_5: "Friday",
    day_6: "Saturday",
    day_7: "Sunday",
    daynr: "Day #",
    monday: "monday",
    tuesday: "tuesday",
    wednesday: "wednesday",
    thursday: "thursday",
    friday: "friday",
    saturday: "saturday",
    sunday: "sunday",
    ms_afterbreakfast: "After breakfast",
    ms_afterdinner: "After dinner",
    ms_afterlunch: "After lunch",
    ms_beforebreakfast: "Before breakfast",
    ms_beforedinner: "Before dinner",
    ms_beforelunch: "Before lunch",
    ms_duringbreakfast: "During breakfast",
    ms_duringdinner: "During dinner",
    ms_duringlunch: "During lunch",
    ms_betweenbreakfastandlunch: "Between breakfast and lunch",
    ms_betweendinnerandsleep: "Between dinner and sleep",
    ms_betweenlunchanddinner: "Between lunch and dinner",
    ms_betweenmeals: "Between meals",
    ms_evening: "Evening",
    ms_morning: "Morning",
    ms_night: "Night",
    ms_thehourofsleep: "Before bed",
    ms_afterwakingup: "After waking up",
    ms_at: "at",
    "hub-upl-fil": "Upload file on hubs",
    "hub-down-fil-pat": "Import file into patient folder",
    "hub-doc-title": "Document title",
    "hub-doc-type": "Document type",
    "hub-medic-susp-res": "Suspension reason",
    "hub-med-trt-date": "Treatment date",
    "hub-med-sus-date": "Suspension date",
    "hub-med-use": "Medication use",
    des: "Description",
    pos: "Posology",
    suspension: "Suspension",
    ms_day: "Day",
    "ms-export-failure": "Error during synchronization",
    "ms-export-success": "Your schema is synchronised",
    "ms-known-posology": "Known posology",
    "error-loading":
      "A problem occurred during loading of the medicationscheme, please retry.",
    retry: "Retry",
    "modif-date": "Modificationdate",
    drugroute: "Drugroute",
    "administration-unit": "Administrationunit",
    "instructions-for-patient": "Instructions for patient",
    compound: "Compound prescription",
    "medication-use": "Medication use",
    "day-number": "Day number",
    "up-new": "New local",
    "up-update": "Updated local",
    "down-new": "New vault",
    "down-update": "Updated vault",
    deleted: "Deleted",
    stopped: "Stopped",
    equal: "Equal",
    errors: "Errors in medicationline",
    invalid: "Invalid",
    "error-uploading":
      "En error occurred during upload of the medicationscheme.",
    close: "Close",
    "treatment-suspension": "Treatment suspension",
    begincondition: "Begin condition",
    endcondition: "End reason",
    "unit-route": "Unit/Route",
    "begin-end": "Begin/End",
    action: "Action",
    select: "Select",
    include: "Add",
    exclude: "Remove",
    status: "Status",
    "new-line": "New medication line",
    "updated-line": "Update medication line",
    "to-vault": "upload to vault",
    "to-local": "create local",
    identical: "Medication line is identical local = vault",
    "deleted-vault": "Medication line is deleted on vault",
    "stopped-line": "Medication line is stopped",
    "error-title": "Errors in vault scheme",
    "value-error": "There are errors in some medication lines",
    "remove-vault-scheme": "Delete medicationscheme from vault",
    "error-list": "Overview of the errors found",
    "correct-list": "Overview of the correct lines in this scheme",
    "acknowledge-errors": "Continue with only the correct lines in this scheme",
    "removed-from-vault": "Deleted from vault-scheme",
  },
  "flatrate-settings": {
    title: "Healthcares' flat rates input",
    warning: "Please input rates for the last 24 months",
    deleteBtn: "Delete rate",
    addTarificationBtn: "Add rate",
    TAB: {
      doctor: "General practice rates",
      physio: "Physiotherapist rates",
      nurse: "Nursing rates",
      ptd: "Diabetes pre-care pathways rates",
    },
    COLUMN: {
      code: "Nomenclature",
      type: "Description",
      startDate: "Start date",
      price: "Rate",
    },
    TYPE: {
      "109616": "General practice in medical houses",
      "409614": "Nursing in medical houses",
      "509611": "Physiotherapist in medical houses",
      "109594": "Diabetes pre-care pathways",
    },
    "TARIF-DIALOG": {
      add: "Add",
      cancel: "Cancel",
      title: "New rate input",
      startDate: "Start date",
      amount: "Rate",
    },
  },
  pleaseWait: "Please wait",
  manuallyForced: "Manually forced",
  FRI: {
    MENU: {
      title: "Facturation au forfait",
      mda: "Vérifications MDA",
      mhcOverview: "Aperçu contrat MM",
      insurabilityOverview: "Aperçu assurabilité",
      invoicesToCorrect: "Factures à corriger",
      invoicesToSend: "Factures à envoyer",
      pastInvoices: "Factures passées",
      sentBatches: "Envois en cours",
      rejectedBatches: "Envois rejetés",
      acceptedBatches: "Envois acceptés",
      archivedBatches: "Envois archivés",
      importTestPatients: "Import test patients",
      getTestPatients: "Get test patients",
      deleteTestPatients: "Delete test patients",
      generateTestInvoicingData: "Generate test data",
      generatePastInvoicingData: "Generate past data",
      getInvoicingData: "Get invoicing data",
      sendTestPastBatches: "Send past batches (TEST)",
      sendPastBatches: "Send past batches (PROD)",
      deleteInvoicingData: "Delete invoicing data",
    },
    "PAGE-TITLE": {
      mda: "Vérification des données du membre (MDA)",
      "mhc-overview": "Aperçu des abonnements Maison Médicale",
      "insurability-overview": "Aperçu des contrats d'assurabilité",
      "invoices-to-correct": "Factures à corriger",
      "invoices-to-send": "Factures à envoyer",
      "generate-past-invoices":
        "Générer des factures dans le passé / récupération",
      "sent-batches": "Envois en cours",
      "rejected-batches": "Envois rejetés",
      "accepted-batches": "Envois acceptés",
      "archived-batches": "Envois archivés",
      "import-test-patients": "Importer des patients de TEST",
      "get-test-patients": "Récupérer les patients de TEST",
      "delete-test-patients": "Supprimer les patients de TEST",
      "generate-past-invoicing-data":
        "Générer des données de facturation passées",
      "get-invoicing-data": "Récupérer les données de facturation",
      "create-test-batches": "Créer des batches de TEST",
      "send-test-batches": "Envoyer des batches du passé (TEST)",
      "send-past-batches": "Envoyer des batches du passé (PROD)",
      "delete-invoicing-data": "Supprimer les données de facturation",
    },
    LOADING: {
      gettingPatients: "Obtention de la patientelle",
      deletingPatients: "Suppression de la patientelle",
      importingPatients: "Import patient",
      gettingMh: "Obtention de la Maison Médicale",
      gettingEfactResponses: "Obtention des réponses Efact",
      confirmingEfactTacks: "Confirmation Tacks Efact",
      confirmingEfactMessages: "Confirmation Messages Efact",
      gettingRawInvoicingMessages: "Obtention de l'historique de facturation",
      deleteingInvoicingData: "Suppression des données de facturation",
      gettingMhValorisations: "Obtention des valorisation INAMI",
      gettingTarifications: "Obtention des tarifications",
      gettingMdaRequests: "Obtention des requêtes MDA",
      gettingMdaResponses: "Obtention des réponses MDA",
      gettingInsurances: "Obtention des assurances",
      gettingInsurancesOas: "Obtention des organismes assureurs",
      gettingPatientsInvoicingAttributes:
        "Obtention des attributs de facturation",
      creatingInvoices: "Création des factures",
      savingInvoicingData: "Enregistrement de la facturation",
      gettingInvoicingData: "Obtention des données de facturation",
      creatingTestBatch: "Création d'un batch de TEST",
      sendingTestBatch: "Simulation (TEST) d'envoi de batch",
      creatingBatches: "Création des envois",
      batchSentForOa: "Envoi effectué pour l'OA ",
      processingBatchAnswer: "Traitement des réponses de l'OA ",
      savingMdaRequests: "Enregistrement des requêtes MDA",
      savingMdaResponses: "Enregistrement des réponses MDA",
      sendingMdaRequests: "Envoi des requêtes MDA",
      analyzingMdaAnswers: "Analyse des réponses MDA",
      updatingPatsInformation: "Mise à jour des données du membre",
      finalizingProcess: "Finalisation du processus",
    },
    MISC: {
      run: "Run",
      xlsExport: "Export Excel",
    },
    "BATCH-DETAILS": {
      batchDetails: "Détails de l'envoi",
      batchType: "Type d'envoi",
      type: "Type",
      batch: "Envoi",
      context: "Contexte",
      charged: "Facturé",
      accepted: "Accepté",
      rejected: "Refusé",
      payment: "Paiement",
      rejectionReason: "Raison du rejet",
      noTechnicalDetailsFound: "Aucun détail technique n'a pu être trouvé",
      moreThan5pcErrors: "Plus de 5% d'erreurs",
      errorsInPreliminaryControl: "Erreurs lors du contrôle préliminaire",
    },
    GENERAL: {
      oa: "OA",
      ins: "Assurance",
      oaVerbose: "Organisme assureur",
      ref: "Référence",
      month: "Mois",
      invoicedMonth: "Mois facturé",
      sentOn: "Envoyé le",
      amount: "Montant",
      amounts: "Montants",
      chargedAmount: "Montant facturé",
      chargedOaAmount: "Montant OA",
      acceptedAmount: "Montant accepté",
      rejectedAmount: "Montant refusé",
      status: "Statut",
      actions: "Actions",
      getBatchesAnswers: "Récupérer les réponses",
      search: "Saisissez votre recherche",
      invoices: "Factures",
      reason: "Raison",
      bankAccount: "Compte bancaire",
      paymentReference: "Référence de paiement",
      flagAllInvoicesAsCorrected: "Tout marquer comme corrigé",
      flagAllInvoicesAsLost: "Tout marquer comme perdu",
      ssin: "NISS",
      ssinVerbose: "Numéro de registre national",
      lastName: "Nom",
      firstName: "Prénom",
      invoiceNumber: "N° facture",
      patient: "Patient",
      patientSingularOrPlural: "Patient(s)",
      generateMyInvoices: "Générer mes factures",
      sendMyInvoices: "Envoyer mes factures",
      codeVerbose: "Code / nomenclature",
      flagAsAccepted: "Marquer comme accepté",
      flagAsPartiallyAccepted: "Marquer comme partiellement accepté",
      flagAsRejected: "Marquer comme refusé",
      flagAsArchived: "Archiver les données",
      flagAsUnarchived: "Désarchiver les données",
      flagAsCorrected: "Marquer comme corrigé",
      flagAsLost: "Marquer comme perdu",
      close: "Fermer",
      patientAmount: "Montant patient",
      additionalAmount: "Montant supplémentaire",
      total: "Total",
      batchsTotal: "Total de l'envoi",
      monthlyTotals: "Totaux mensuel",
      sendBatchAgain: "Renvoyer cet envoi",
      message: "Message",
      feedback: "Feedback",
      downloadTechnicalDetails: "Télécharger les détails techniques",
      technicalDetails: "Détails techniques",
      nihiiVerbose: "Numéro INAMI",
      notVerified: "Non-vérifié",
      yes: "Oui",
      no: "Non",
      save: "Enregistrer",
      saveAndInvoice: "Enregistrer et facturer",
      date: "Date",
    },
    "INVOICES-SUMMARY": {
      noInvoicesToSend: "Aucune facture à envoyer",
      dialogTitle: "Récapitulatif avant envoi",
    },
    "MY-PROFILE": {
      flatrateTarifications: "Tarifications forfaitaires",
      "contactPerson-invoicingResponsible":
        "Personne de contact / responsable facturation",
      "contactPerson-invoicingResponsible-required":
        "Le responsable de facturation est requis",
    },
    ERRORS: {
      dialogTitle: "Informations manquantes",
      intro1:
        "Attention, les informations suivantes manquent dans votre fiche utilisateur.",
      intro2:
        "Veuillez s'il vous plait les renseigner avant d'utiliser votre module de facturation.",
      "hcp.nihii": "Numéro INAMI",
      "hcp.cbe": "Numéro d'immatriculation à la BCE",
      "hcp.bankAccount": "Compte bancaire (IBAN)",
      "hcp.bic": "Compte bancaire (BIC)",
      "hcp.contactPersonHcpId": "Responsable de la facturation",
      "hcpContactPerson.firstName": "Prénom du responsable de la facturation",
      "hcpContactPerson.lastName": "Nom du responsable de la facturation",
      "hcpContactPerson.ssin":
        "Numéro de registre national du responsable de la facturation",
      "reimbursement.gp": "Tarification des prestations de médecine générale",
      "reimbursement.kine": "Tarification des prestations de kinésithérapie",
      "reimbursement.nurse": "Tarification des prestations de soins infirmiers",
      "reimbursement.ptd":
        "Tarification des prestations du prétrajet de soin diabète",
      noPatientsFoundForMda: "Aucun patient n'a pu être trouvé",
      requestMessageNotCreated: "Erreur lors de la sauvegarde des requêtes MDA",
      mdaRequestNotSent: "Erreur lors de l'envoi des requêtes MDA",
      technicalErrorWithOa: "Erreur technique chez l'OA",
      ssinNotFound: "Numéro de registre national inconnu",
      dataCouldNotBeVerified: "Les données n’ont pas pu être vérifiées",
      unknownInsuranceRegistrationNumber:
        "Numéro de contrat d'assurance inconnu chez l'OA",
      callToBackendFailed: "Erreur technique chez l'OA (appel backend)",
      proceedWithMdaFirst:
        "Veuillez s'il vous plait finaliser la vérification des données du Membre (MDA) en amont de la facturation.",
    },
    MDA: {
      month_01: "Janvier",
      month_02: "Février",
      month_03: "Mars",
      month_04: "Avril",
      month_05: "Mai",
      month_06: "Juin",
      month_07: "Juillet",
      month_08: "Août",
      month_09: "Septembre",
      month_10: "Octobre",
      month_11: "Novembre",
      month_12: "Décembre",
      memberDataRequest: "Envoi de la demande",
      requestStatus: "Statut de la demande",
      requestResults: "Résultats de la demande",
      invoicesCreation: "Création des factures",
      verifyMemberData: "Vérifier les données du membre",
      verifyData: "Vérifier les données",
      membershipReference: "Numéro de contrat d'assurance",
      noKnownOa: "Pas d'organisme assureur actuellement recensé",
      errorInResponse: "Erreur dans la réponse",
      responseObtained: "Réponse obtenue",
      beingTreated: "En cours de traitement",
      mdaRequestSent: "Envoi des requêtes MDA effectué",
      pleaseVerifyMemberDataToBegin:
        "Veuillez s'il vous plait commencer par vérifier les données de vos membres.",
      bypassMdaResponses: "Outrepasser le délai d'attente",
      patientHasNoMhc:
        "Le patient ne dispose d'aucun contrat avec une Maison Médicale",
      patientIsNowInMhc: "Le patient est désormais dans la Maison Médicale",
      patientHasNoOnGoingMhc:
        "Le patient ne dispose d'aucun contrat en cours / valide avec votre Maison Médicale",
      patientHasNoValidIns:
        "Le patient ne dispose d'aucune assurance en cours de validité",
      patientHasNoIns: "Le patient n'est pas assuré",
      patientIsInOtherMhc: "Le patient a changé de maison médicale",
      invoicable: "Facturable",
      flagAsInvoicable: "Marquer comme facturable",
      flagAsNotInvoicable: "Marquer comme non-facturable",
    },
    "PAST-INVOICES": {
      firstInsurability: "Première assurabilité connue",
      lastInsurability: "Dernière assurabilité connue",
      noValidInsurability: "Aucune / non-assuré",
      mhcShort: "Contrat MM",
      ongoing: "En cours",
      ongoingSince: "En cours depuis le",
      stoppedSince: "Clôturé depuis le",
      noContract: "Aucun contract",
      workWithThisPatient: "Travailler avec ce patient",
      gpOneLetterAbbreviation: "M",
      kineOneLetterAbbreviation: "K",
      nurseOneLetterAbbreviation: "I",
      backToList: "Retourner à la liste",
      listingNotaBene:
        "Seuls les patients actifs et disposant / ayant disposé d'un contract seront (peut-être) facturables / récupérables.",
      verify: "Vérifier",
      insured: "Assuré",
      gotInvoiced: "Fût facturé",
      manually: "manuellement",
      automatically: "automatiquement",
      alreadyInOrder: "D'ores et déjà en ordre",
      createInvoice: "Créer la facture",
      create: "Créer",
      notInOrder: "Pas en ordre",
      noModificationMade: "Aucune modification effectuée",
      invoiceCreated: "Facture créée",
      invoiceAlreadyCreated: "Facture déjà créée",
    },
  },
  "cert-management": {
    connectAsPmg: "Connecting as guard post",
    TAB: {
      current: "Current certificate",
      outdated: "Outdated certificate",
    },
    GRID: {
      title: "Uploaded certificate",
      uploadBtn: "Upload certificate",
      CELL: {
        name: "Name of the certificate",
        endOfValidity: "End of validity",
        active: "Active",
        password: "Password",
        asPmg: "Connecting as guard post",
      },
      BTN: {
        delete: "Delete",
        modify: "Modify",
        active: "Activate",
      },
    },
    DIALOG: {
      MODIFICATION: {
        title: "Modification of ehealth password",
        password: "Password",
        BTN: {
          cancel: "Cancel",
          modify: "Modify",
        },
      },
    },
    ERROR: {
      password: "The password certificate is missing",
      encrypt: "Error when encrypting password",
      decrypt: "Error when decrypting password",
    },
  },
};
