import { useNavigate } from "react-router-dom";

import MobileButton from "./MobileButton";
import ModalSheetNavigationButton from "./ModalSheetNavigationButton";

interface Props {
  back?: boolean;
}
const HomeButton = ({ back }: Props) => {
  const navigate = useNavigate();
  function showNavigation() {
    document.querySelector(".content")?.classList.toggle("sidebar-active");
  }

  function goBack(){
    navigate("..",{relative:"path"});
  }

  function onClick(isHome: boolean) {
    isHome ? showNavigation() : goBack();
  }

  return (
    <MobileButton visible={back} onPress={() => onClick(!back)}>
      <ModalSheetNavigationButton back={back} />
    </MobileButton>
  );
};
export default HomeButton;
