import React from "react";
import styles from "./Typography.styles";

interface Props {
  children: JSX.Element | string;
  color?: string;
  align?: "left" | "center" | "right";
  //style?: TextStyle;
}

type TypeStyle = keyof typeof styles;
type TypeSet = Record<TypeStyle, Function>;

const Typography: Partial<TypeSet> = {};

Object.entries(styles).forEach(([key, s]) => {
  Typography[key as TypeStyle] = ({ children, color, align }: Props) => (
    <span style={{ ...s, color, textAlign: align ?? "left" }} className={key}>
      {children}
    </span>
  );
});

export default Typography as NonNullable<TypeSet & Props>;
