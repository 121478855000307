import { XHR } from "@icure/api";
import Header = XHR.Header;

interface SMSMessage {
  message: string;
}
interface EmailMessage {
  from: string;
  html: string;
  subject: string;
}
interface AuthenticationProcessBody {
  "g-recaptcha-response": string;
  firstName: string;
  lastName: string;
  from: string | undefined;
  email: string | undefined;
  mobilePhone: string | undefined;
  hcpId: string | undefined;
}

export class MessageGatewayApi {
  constructor(
    msgGtwUrl: string,
    specId: string,
    username?: string,
    password?: string,
    fetchImpl: (
      input: RequestInfo,
      init?: RequestInit
    ) => Promise<Response> = fetch
  ) {
    this.fetchImpl = fetchImpl;
    this.msgGtwUrl = msgGtwUrl;
    this.specId = specId;
    this.authHeader =
      !!username && !!password
        ? new Header(
            "Authorization",
            "Basic " + Buffer.from(`${username}:${password}`).toString("base64")
          )
        : null;
    this.headers = [new Header("Content-Type", "application/json")];
  }

  private readonly fetchImpl?: (
    input: RequestInfo,
    init?: RequestInit
  ) => Promise<Response>;

  private readonly msgGtwUrl: string;
  private readonly specId: string;
  private readonly authHeader: XHR.Header | null;
  private readonly headers: Header[];

  async sendEmail(
    recipientEmail: string,
    email: EmailMessage
  ): Promise<XHR.Data | null> {
    if (this.authHeader == null) return null;
    const res = await XHR.sendCommand(
      "POST",
      `${this.msgGtwUrl}/${this.specId}/email/to/${recipientEmail}`,
      this.headers.concat([this.authHeader]),
      email,
      this.fetchImpl
    );

    if (res.statusCode < 400) return res;
    else {
      throw new Error(
        `Message Gateway returned error response ${res.statusCode}`
      );
    }
  }

  async sendSMS(
    recipientMobileNumber: string,
    sms: SMSMessage
  ): Promise<XHR.Data | null> {
    if (this.authHeader == null) return null;
    const res = await XHR.sendCommand(
      "POST",
      `${this.msgGtwUrl}/${this.specId}/sms/to/${recipientMobileNumber}`,
      this.headers.concat([this.authHeader]),
      sms,
      this.fetchImpl
    );

    if (res.statusCode < 400) return res;
    else {
      throw new Error(
        `Message Gateway returned error response ${res.statusCode}`
      );
    }
  }

  async startProcess(
    processId: string,
    requestId: string,
    processBody: AuthenticationProcessBody
  ): Promise<XHR.Data | null> {
    const res = await XHR.sendCommand(
      "POST",
      `${this.msgGtwUrl}/ms/process/${processId}/${requestId}`,
      this.headers,
      processBody,
      this.fetchImpl,
      "text/plain"
    );

    if (res.statusCode < 400) return res;
    else {
      throw new Error(
        `Message Gateway returned error response ${res.statusCode}`
      );
    }
  }

  async validateProcess(
    requestId: string,
    validationCode: string
  ): Promise<XHR.Data | null> {
    const res = await XHR.sendCommand(
      "GET",
      `${this.msgGtwUrl}/ms/process/validate/${requestId}-${validationCode}`,
      [],
      undefined,
      this.fetchImpl
    );

    if (res.statusCode < 400) return res;
    else {
      throw new Error(
        `Message Gateway returned error response ${res.statusCode}`
      );
    }
  }
}
