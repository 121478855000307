import Avatar from "../../ui/components/Avatar/Avatar";
import AvatarGroup from "../../ui/components/Avatar/AvatarGroup";
import {
  AgendaItemModel,
  AgendaItemStatusEnum,
} from "../../models/AgendaItemModel";
import { HCPModel, isHCPModel } from "../../models/HCPModel";
import {
  formatDateAndTime,
  formatDateAsMonthAndYear,
  formatName,
} from "../../utils/formatting";
import styled from "styled-components";
import Icon from "../../ui/components/Icon";
import { Icons } from "../../ui/assets";
import { useNavigate } from "react-router-dom";
import Button from "../../ui/components/Button";
import dayjs from "dayjs";
import { Color, Tints } from "../../ui/tokens";

export const AgendaItemRenderer = {
  iconRight: (item: AgendaItemModel) => (
    <AvatarGroup persons={item.participants} />
  ),
  icon: (item: AgendaItemModel) => (
    <Avatar
      square
      icon={
        <Icon
          asset={
            item.type === "FORM"
              ? Icons.icons.form
              : item.status === AgendaItemStatusEnum.PROPOSED
              ? Icons.specialties.heart
              : Icons.icons.clock_xs
          }
          color={Color.WHITE}
        />
      }
      tint={
        item.type === "FORM"
          ? Tints.FORM
          : item.status === AgendaItemStatusEnum.PROPOSED
          ? Tints.PREVENTION
          : Tints.APPOINTMENT
      }
    />
  ),

  title: (item: AgendaItemModel) => formatDateAndTime(item.start),
  subtitle: (item: AgendaItemModel) =>
    formatName(item.participants?.find((p) => isHCPModel(p))!),
  details: (item: AgendaItemModel) => item.description ?? "",
};

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

//?.find((p) => isHCPModel(p))}

/*icon: (item: AgendaItemModel) => <Avatar large square icon={item.start ?
      <Icon asset={Icons.icons.calendar_xs} color="#FFF"/>
        /*<DateContainer>
          
          <Typography.strong >{getDate(item.start)}</Typography.strong>
          <Typography.xsmall>{getMonth(item.start).toUpperCase()}</Typography.xsmall>

        </DateContainer>
      : <Typography.small>...</Typography.small>}
      label={item.start ? getDate(item.start) : "..."}
      */
