const hideOptions = (event: MouseEvent) => {
  const options = document.querySelector(".options");

  if (event.target && options?.contains(event.target as Node)) {
    return;
  }
  event.stopPropagation();
  document.removeEventListener("click", hideOptions, { capture: true });
  options?.classList.remove("active");
};

const showOptions = () => {
  const options = document.querySelector(".options");
  options?.classList.add("active");
  setTimeout(() => {
    document.addEventListener("click", hideOptions, { capture: true });
  }, 10);
};

export default function useOptions() {
  return { show: showOptions, hide: hideOptions };
}
