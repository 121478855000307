import { Provider } from "react-redux";
import store from "../../store";
import Main from "./Main";
import ToastProvider from "../../ui/components/Toast/ToastProvider";
import ToastList from "../../ui/components/Toast/ToastList";
import { ApolloProvider } from "@apollo/client";
import client from "../../connectors/awell/clients/awellOrchestrationGraphQlClient";
import ModalProvider from "../../ui/components/ModalSheet/ModalProvider";
import ModalSheet from "../../ui/components/ModalSheet/ModalSheet";
import PWAPrompt from 'react-ios-pwa-prompt'

const EzoApp = () => {
  return (
    <Provider store={store}>
    
      <ApolloProvider client={client}>
        <ModalProvider>
          <ToastProvider>
            <Main />
            <ToastList />
            <ModalSheet />
          </ToastProvider>
        </ModalProvider>
        {/*}
        <PWAPrompt timesToShow={2}
        delay={300}
        copyTitle="Ajouter à l'écran d'accueil"
        copyDescription="Installez cette application sur votre écran d’accueil afin de recevoir des notification en cas de mise-à-jour et accédez à vos données de santé encore plus facilement"
        copySubtitle={window.location.origin + window.location.pathname}
        copyShareStep="Tapez sur l’icône *Partager* au bas de cet écran."
        copyAddToHomeScreenStep="Choisissez *sur l'écran d'accueil*"
        />
        */}
        
      </ApolloProvider>
   </Provider>
  );
};

export default EzoApp;
