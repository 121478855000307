import styled from "styled-components";
import Typography from "../Typography/Typography";
import { Color, Size } from "../../tokens";

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${Size.S}px;
  justify-content: space-between;
  flex: 1;
  margin-top: ${Size.M}px;
  padding-left: ${Size.XS}px;
`;

interface Props {
  title?: string;
  icon?: any;
}

const SectionHeading = ({ title, icon }: Props) => {
  return (
    <Heading className="heading">
      {title && (
        <div style={{ flex: 1 }}>
          <Typography.h3 color={Color.NEUTRAL_800}>{title}</Typography.h3>
        </div>
      )}
      {icon}
    </Heading>
  );
};

export default SectionHeading;
