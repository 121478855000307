import { ApolloClient, InMemoryCache } from "@apollo/client";
const API_URL = "https://api.sandbox.awellhealth.com/orchestration/m2m/graphql";
const API_KEY = "wBgnMtwxAFqLNGKzhpmlA0EtssDio8x1";
const client = new ApolloClient({
  uri: API_URL,
  headers: { apiKey: API_KEY },
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          patients: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            // Concatenate the incoming list items with
            // the existing list items.
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            merge(existing = [], incoming) {
              const updatedResponse = {
                patients: [...(incoming.patients || [])],
                pagination: incoming.pagination,
                sorting: incoming.sorting,
              };

              return updatedResponse;
            },
          },
        },
      },
    },
  }),
});

export default client;
