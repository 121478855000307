import { UserCredentials } from "../models/UserCredentials";
import { healthDataApi } from "./healthdata";
import { ResourceType } from "../types/ResourceType";
import {
  ConnectorOptions,
  ConnectorProvider,
} from "../connectors/ConnectorProvider";
import {
  connectionError,
  connectionInitialised,
  connectionSuccess,
  setCredentials,
} from "../features/login/AuthSlice";

enum DeviceStorageKeys {
  CREDENTIALS = "credentials",
}

export const authenticationApi = healthDataApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({


    // todo: add some way to return the logged in patient
    connect: builder.mutation<ConnectorOptions[], UserCredentials>({
      queryFn: async (credentials, api) => {
        console.log(credentials)
        const connections = await ConnectorProvider.getAvailableProviders(
          credentials
        );




        if (connections.length > 0) {
          api.dispatch(setCredentials({credentials:credentials,info:connections.find(c => c.info)?.info}));
        }

        connections.forEach((connection) => {
          const connector = connection.connector!;
          api.dispatch(connectionInitialised(connection));
          return ConnectorProvider.addConnectorWithOptions(
            connector,
            connection
          )
            .then((c) => {
              api.dispatch(
                connectionSuccess({...connection,info:c.patient})
              );
            })
            .catch((e) => {
              api.dispatch(connectionError({...connection,error:e}));
            });
        });

        return { data: connections };
      },
    }),

    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    logout: builder.mutation<string, void>({
      queryFn: async () => {
        // temporary
        const items = await localStorage.getAllKeys();
        for (const item of items) {
          if (item === DeviceStorageKeys.CREDENTIALS) {
            // console.log('remove', item);
            await localStorage.removeItem(item);
          }
        }
        return {
          data: "ok",
        };
      },
      invalidatesTags: [ResourceType.PROVIDERS],
    }),
  }),
});

export const { useLogoutMutation, useConnectMutation } = authenticationApi;
