import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next'
// @ts-ignore
import Logo from './app/ui/assets/navigation/logo.svg'
import { useLocation } from 'react-router-dom';
//import { Languages } from './services/translations';

export default function Header (){

  const location = useLocation();
  const shouldShowHeader = !location.pathname.startsWith('/app');

 

    const {t,i18n} = useTranslation();
    return (
      shouldShowHeader ?
    <header className="wrap">
    <div className="company">
      <img alt="Medispring logo" src={Logo} className="logo"/>
      <div className="name">
          <span>{t("UI.HEADER.APP_NAME")}</span>
          <span>{t("UI.HEADER.POWERED_BY")}</span>
      </div>  
  </div>

  {
  // Language switcher, currently disabled until we support information text in more languages
  /*
  <select className="languageSelector" name='language' onChange={e=>{i18n.changeLanguage(e.target.value); dayjs.locale(e.target.value)}}>
    {Object.entries(Languages).map(([name,code])=><option key={code} value={code}>{name}</option>)}
    </select>
    */}
    
  </header>

  :<></>
    )
}