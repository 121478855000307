import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import styled from "styled-components";
import { Breakpoint, Easing, Size } from "../../tokens";
import NavBar from "./NavBar";
import { useFlowContext } from "./FlowContext";

const Container = styled.div`
  width: 100%;
  height: calc(100% - ${Size.XXL}px);
  overflow: hidden;
  position: absolute;
  margin-top: ${Size.XXL}px;
`;

const Screen = styled.div<{ position: number }>`
  width: 100%;
  flex-shrink: 0;
  opacity: 0;
  position: absolute;
  overflow-y: auto;
  height: 100%;

  transform: translateX(${(props) => props.position * 100}%);

  padding: ${Size.M}px;

  box-sizing: border-box;
  filter: blur(2px);
  transition: transform ${Easing.SCREEN}, opacity 0.3s, blur 0.3s;

  @media (min-width: ${Breakpoint.MOBILE}px) {
    transition: none;
  }

  &.active {
    opacity: 1;
    filter: blur(0px);
  }
`;

interface Props {
  index: number;
}

interface ScreenFlowProps {
  children: Array<ReactElement | undefined | boolean>;
  activeIndex?: number;
  persistNavBar?: boolean;
  navBarBackground?: string;
  title?: string;
  leftButtons?: ReactElement[] | ReactElement;
  rightButtons?: ReactElement[] | ReactElement;
}


export interface FlowScreenProps {
  index?: number;
  name:string
}

const ScreenFlow = ({
  children,
  activeIndex = 0,
  title,
  leftButtons,
  rightButtons,
  persistNavBar,
}: ScreenFlowProps) => {
  const container = useRef<HTMLDivElement>(null);

  const [previousIndex, setPreviousIndex] = useState<number | undefined>(
    activeIndex
  );

  const { setActiveScreen, getActions} = useFlowContext(); // Access the context

  
  const [right, setRight] = useState<React.ReactNode | React.ReactNode[]>([]);


  useEffect(() => {
    if (activeIndex !== undefined) {
      container.current
        ?.querySelector(".active")!
        .scroll({ behavior: "auto", top: 0 });

      setPreviousIndex(activeIndex);
      setActiveScreen && setActiveScreen(activeIndex);
      const right = getActions((children[activeIndex]! as ReactElement).props.name);
      setRight(right);
    }

  }, [activeIndex]);

  return (
    <>
    
      <NavBar
        title={title}
        left={leftButtons}
        right={right}
        active={activeIndex}
        showTitle={persistNavBar}
      />

      <Container ref={container}>
        {children?.map((child, index) => {
          
          return (
            <Screen
              position={index > activeIndex ? 1 : index < activeIndex ? -1 : 0}
              key={index}
              className={`${index === activeIndex ? "active" : ""}`}
            >
              {child}
              
            </Screen>
          );
        })}
      </Container>
    </>
  );

  /*<Button title='Ceci est un très très très long texte pour vopir si ça va mieux qoioioz  kdhkdjd'/> */
};

export default ScreenFlow;
