import { useSelector } from "react-redux";
import {
  selectResourcesOfType,
  useGetResourceByIdQuery,
  useGetResourcesQuery,
} from "../api/healthdata";
import { RootState } from "../store";
import { ResourceType } from "../types/ResourceType";
export default function useResource<U>(
  type: ResourceType,
  id?: string,
  skip?: boolean
) {
  const resources = useSelector((state: RootState) =>
    selectResourcesOfType(state, type)
  );
  const r = resources?.find((resource) => resource.id === id);

  const { data, isLoading, error, refetch } = useGetResourceByIdQuery(
    {
      id,
      type,
    },
    { skip: skip || !id || !!r }
  );

  if (r) {
    return {
      data: r as U,
      isLoading: false,
      error: undefined,
      refetch: () => {},
    };
  }

  return { data: data as U, isLoading, error, refetch };
}
