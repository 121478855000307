import styled, { keyframes, css } from "styled-components";
import { SHIMMER_DURATION } from "./ShimmerBlock";
import { Color, Size } from "../../tokens";

function randomWidth(min: number = 0, max: number = 100) {
  return min + Math.random() * (max - min);
}

const shimmer = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

interface ListItemShimmerProps {
  delay: number;
}

const StyledItem = styled.div<ListItemShimmerProps>`
  display: flex;
  /* Add other item styles from Shimmer.styles */
  flex-direction: row;
  width: 100%,
  align-items: center;
opacity: 0;
  animation-name: ${shimmer};
  animation-iteration-count: infinite;
  ${(props) => css`
    animation-delay: ${props.delay * 600}ms;
    animation-duration: ${SHIMMER_DURATION * 2}ms;
  `}
`;

const StyledAvatar = styled.div`
  /* Add avatarL styles from Shimmer.styles */
  border-radius: ${Size.S}px;
  background-color: ${Color.NEUTRAL_200};
  height: ${Size.XXL}px;
  width: ${Size.XXL}px;

  margin: ${Size.S}px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  /* Add other content styles from Shimmer.styles */
`;

const StyledHeadline = styled.div<ItemProps>`
  /* Add headline styles from Shimmer.styles */
  ${(props) => css`
    width: ${props.width}%;
  `}

  border-radius: ${Size.S}px;
  background-color: ${Color.NEUTRAL_200};
  height: ${Size.L}px;
  margin: ${Size.S}px 0;
`;

const StyledSmall = styled.div<ItemProps>`
  /* Add small styles from Shimmer.styles */
  ${(props) => css`
    width: ${props.width}%;
  `}
  border-radius: ${Size.XS}px;
  background-color: ${Color.NEUTRAL_200};
  height: ${Size.M}px;
`;

interface ItemProps {
  width?: number;
}

export default function ListItemShimmer({ delay = 0 }: ListItemShimmerProps) {
  return (
    <StyledItem delay={delay}>
      <StyledAvatar />
      <StyledContent>
        <StyledHeadline width={randomWidth(75)} />
        <StyledSmall width={randomWidth(50, 80)} />
      </StyledContent>
    </StyledItem>
  );
}
