import { use } from "i18next";
import { useCancelAppointmentMutation } from "../connectors/medispring/api/medispring";
import Alert from "../ui/components/Alert";
import { useToast } from "../ui/components/Toast/useToast";
import { AgendaItemModel } from "../models/AgendaItemModel";
import { useNavigate } from "react-router-dom";

interface ConfirmationOptions {
  confirmationTitle?: string;
  confirmationMessage?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  alertTitle?: string;
  alertMessage?: string;

  data?: any;
}

export default function useConfirmation(options: ConfirmationOptions) {
  const addToast = useToast();
  const navigate = useNavigate();

  const confirm = async () => {
    Alert.close();
    if (options.onConfirm) options.onConfirm();
    navigate(-1);
    addToast({
      active: true,
      title: options.confirmationTitle,
      message: options.confirmationMessage,
    });
  };

  return () =>
    Alert.show(
      options.alertTitle ?? "Are you sure?",
      options.alertMessage ?? "",
      [
        { label: "No", action: Alert.close },
        {
          label: "Yes",
          type: "primary",
          action: confirm,
        },
      ]
    );
}
