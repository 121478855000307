import dayjs from "dayjs";
import { DocumentResource, DocumentType } from "../../models/DocumentResource";
import { Activity, Message } from "./types/generated/api.types";
import { API_TIME_FORMAT } from "../medispring/api/constants";
import {
  AgendaItemModel,
  AgendaItemStatusEnum,
} from "../../models/AgendaItemModel";

export function awellActivityToDocumentResource(
  activity: Activity
): DocumentResource {
  return {
    id: activity.id,
    title: activity.object.name ?? "New message",
    type: DocumentType.MESSAGE,
    hcp: { id: "chirec", firstName: "Chirec", lastName: "Hôpital Delta" },
    issued: parseInt(dayjs(activity.date).format(API_TIME_FORMAT)),
    commented: parseInt(dayjs(activity.date).format(API_TIME_FORMAT)),
  };
}

export function awellActivityToAgendaItemModel(
  activity: Activity
): AgendaItemModel {
  return {
    id: activity.id,
    title: activity.object.name ?? "New form",
    hcp: { id: "chirec", firstName: "Chirec", lastName: "Hôpital Delta" },
    status: AgendaItemStatusEnum.PROPOSED,
    start: parseInt(dayjs(activity.date).format(API_TIME_FORMAT)),
    end: parseInt(dayjs(activity.date).format(API_TIME_FORMAT)),
    type: "FORM",
  };
}
